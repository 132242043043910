// import axios from 'axios';
import axios from "../utils/axios.jsx";
import { localStorageUtils } from "../utils";

export const createCMSData = async (userId, Savedata, files) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    files.forEach(file => {
      data.append(file.name, file);
    });
    const response = await axios.post("/api/retailer/cms/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCMSData = async (userId, retailerId) => {
  try {
    const response = await axios.get(
      "/api/retailer/cms/get/?menuId=" + userId + "&retailerId=" + retailerId
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCMSComponents = async (
  id,
  isSelect,
  page,
  search,
  partner_id
) => {
  try {
    let fetchUrl = "/api/cms-v2/component/list/?1=1";

    if (id) {
      fetchUrl = fetchUrl + "&id=" + id;
    }

    if (isSelect) {
      fetchUrl = fetchUrl + "&is_select=1";
    }

    if (page) {
      fetchUrl = fetchUrl + "&page=" + page;
    }

    if (search) {
      fetchUrl = fetchUrl + "&search=" + search;
    }

    if (partner_id) {
      fetchUrl = fetchUrl + "&partner_id=" + partner_id;
    }

    const response = await axios.get(fetchUrl);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteComponent = async (userId, componentid) => {
  try {
    let data = new FormData();
    if (userId) data.append("userid", userId);

    if (componentid) data.append("componentid", componentid);

    const response = await axios.post("/api/cms-v2/component/delete/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteBanner = async (userId, bannerid) => {
  try {
    let data = new FormData();
    if (userId) data.append("userid", userId);

    if (bannerid) data.append("banner", bannerid);

    const response = await axios.post("/api/cms-v2/banners/delete/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePartnerPage = async (userId, bannerid) => {
  try {
    let data = new FormData();
    if (userId) data.append("userid", userId);

    if (bannerid) data.append("pageid", bannerid);

    const response = await axios.post(
      "/api/cms-v2/partner-pages/delete/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAdminPage = async (userId, bannerid) => {
  try {
    let data = new FormData();
    if (userId) data.append("userid", userId);

    if (bannerid) data.append("pageid", bannerid);

    const response = await axios.post("/api/cms-v2/pages/delete/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveComponentData = async (userId, Savedata, files) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    if (Savedata.id) {
      data.append("id", Savedata.id);
    }

    data.append("key", Savedata.keyid);
    data.append("name", Savedata.name);
    data.append("description", Savedata.description);
    data.append("form", JSON.stringify(Savedata.form));
    data.append("active", Savedata.is_active);
    data.append("partner_id", Savedata.partner_id);

    if (Savedata.file_size) {
      data.append("file_size", Savedata.file_size);
    }
    if (Savedata.original_file_name) {
      data.append("original_file_name", Savedata.original_file_name);
    }
    if (Savedata.asset_id) {
      data.append("asset_id", Savedata.asset_id);
    }
    if (files) {
      files.forEach(file => {
        data.append(file.name, file);
      });
    }

    const response = await axios.post("/api/cms-v2/component/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const savePartnerPageService = async (
  userId,
  step,
  Savedata,
  partner_id,
  files
) => {
  try {
    // let data = {
    //     partner_id: partner_id,
    //     userid: userId,
    //     id: Savedata.id,
    //     step: step,
    //     industry: Savedata.industry,
    //     slug: Savedata.slug,
    //     name: Savedata.name,
    //     description: Savedata.description,
    //     active: Savedata.is_active,
    //     sections: Savedata.sections,
    //     data: Savedata.submission
    // }

    let data = new FormData();
    data.append("userid", userId);
    data.append("partner_id", partner_id);
    data.append("step", step);
    if (Savedata.id) {
      data.append("id", Savedata.id);
    }

    data.append("industry", Savedata.industry);
    data.append("slug", Savedata.slug);
    data.append("name", Savedata.name);
    data.append("description", Savedata.description);
    data.append("active", Savedata.is_active);
    data.append("is_landing_page", Savedata.is_landing_page);
    data.append("sections", JSON.stringify(Savedata.sections));
    data.append("data", JSON.stringify(Savedata.submission));

    files.forEach(file => {
      data.append(file.name, file);
    });

    const response = await axios.post(
      "/api/cms-v2/partner-pages/create/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveBasicDetails = async (
  userId,
  step,
  Savedata,
  partnerid,
  files
) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("partnerid", partnerid);
    data.append("step", step);
    if (Savedata.id) {
      data.append("id", Savedata.id);
    }

    data.append("industry", JSON.stringify(Savedata.industry));
    data.append("slug", Savedata.slug);
    data.append("name", Savedata.name);
    data.append("description", Savedata.description);
    data.append("active", Savedata.is_active);
    data.append("is_landing_page", Savedata.is_landing_page);
    data.append("is_system_page", Savedata.is_system_page);

    data.append("sections", JSON.stringify(Savedata.sections));

    if (Savedata.submission)
      data.append("data", JSON.stringify(Savedata.submission));

    files.forEach(file => {
      data.append(file.name, file);
    });

    const response = await axios.post("/api/cms-v2/pages/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPartnerCMSPages = async (partner_id, id) => {
  try {
    let fetchUrl = "/api/cms-v2/partner-pages/list/?1=1";
    if (partner_id) {
      fetchUrl = fetchUrl + "&partner_id=" + partner_id;
    }
    if (id) {
      fetchUrl = fetchUrl + "&id=" + id;
    }
    const response = await axios.get(fetchUrl);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCMSPages = async (id, partnerId, onlyActive, page, search) => {
  try {
    let fetchUrl = "/api/cms-v2/pages/list/?1=1";
    if (id) {
      fetchUrl = fetchUrl + "&id=" + id;
    }
    if (partnerId) {
      fetchUrl = fetchUrl + "&partner_id=" + partnerId;
    }
    if (page) {
      fetchUrl = fetchUrl + "&page=" + page;
    }
    if (onlyActive) {
      fetchUrl = fetchUrl + "&is_active=1";
    }
    if (search) {
      fetchUrl = fetchUrl + "&search=" + search;
    }
    const response = await axios.get(fetchUrl);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPartnerPages = async (id, page, search) => {
  try {
    let fetchUrl =
      "/api/cms-v2/partner-pages/list/?partner_id=" + id + "&page=" + page;
    if (search) {
      fetchUrl = fetchUrl + "&search=" + search;
    }
    const response = await axios.get(fetchUrl);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCMSMenu = async (Partnerid, type) => {
  try {
    const response = await axios.get(
      "/api/cms-v2/menu/get/?partnerid=" + Partnerid + "&type=" + type
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveCMSMenu = async (Savedata, partnerId, menuType) => {
  try {
    let data = new FormData();
    data.append("data", JSON.stringify(Savedata));
    data.append("menutype", menuType);
    data.append("partnerid", partnerId);
    const response = await axios.post("/api/cms-v2/menu/Create/", data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const copyPagePartner = async (pageId, partnerId) => {
  try {
    let data = new FormData();
    data.append("pages", JSON.stringify(pageId));
    data.append("partnerid", partnerId);
    const response = await axios.post("/api/cms-v2/pages/copy/", data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAssignPartnerPages = async partnerId => {
  try {
    const response = await axios.get(
      "/api/cms-v2/partner/assign/pages/?partnerid=" + partnerId
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveBanner = async (userId, Savedata, files) => {
  try {
    let data = new FormData();

    data.append("userid", userId);

    if (Savedata.id) {
      data.append("id", Savedata.id);
    }

    data.append("name", Savedata.name);
    data.append("description", Savedata.description);
    data.append("pages", JSON.stringify(Savedata.pages));

    if (Savedata.is_show_title)
      data.append("show_title", Savedata.is_show_title);

    if (Savedata.is_show_description)
      data.append("show_description", Savedata.is_show_description);

    if (Savedata.is_active) data.append("active", Savedata.is_active);

    data.append("partner_id", Savedata.partner_id);

    if (Savedata.file_size) {
      data.append("file_size", Savedata.file_size);
    }

    if (Savedata.original_file_name) {
      data.append("original_file_name", Savedata.original_file_name);
    }

    if (Savedata.asset_id) {
      data.append("asset_id", Savedata.asset_id);
    }

    if (files) {
      files.forEach(file => {
        data.append(file.name, file);
      });
    }

    const response = await axios.post("/api/cms-v2/banners/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIndustryPageGroup = async () => {
  try {
    let fetchUrl = "/api/cms-v2/industry/pages/groups/";
    const response = await axios.get(fetchUrl);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBanners = async (partnerId, id) => {
  try {
    let fetchUrl = "/api/cms-v2/banners/list/";
    if (id) {
      fetchUrl = fetchUrl + "?id=" + id;
    } else if (partnerId) {
      fetchUrl = fetchUrl + "?partner_id=" + partnerId;
    }

    const response = await axios.get(fetchUrl);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBannerDetails = async id => {
  try {
    let fetchUrl = "/api/cms-v2/banners/list/";
    if (id) {
      fetchUrl = fetchUrl + "?id=" + id;
    }

    const response = await axios.get(fetchUrl);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const cloneCmsPages = async (Savedata, partnerId, menuType) => {
  try {
    let data = new FormData();
    data.append("data", JSON.stringify(Savedata));
    data.append("menutype", menuType);
    data.append("partnerid", partnerId);
    const response = await axios.post("/api/cms-v2/clone/pages/", data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const clonePartnerPages = async (Savedata, partnerId) => {
  try {
    let data = new FormData();
    data.append("data", JSON.stringify(Savedata));
    data.append("partnerid", partnerId);
    const response = await axios.post("/api/cms-v2/clone/partnerpages/", data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const clonePackage = async Savedata => {
  try {
    let data = new FormData();
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post(
      "/api/cms-v2/industry-metadata/clone/",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPartnerData = async page => {
  try {
    const response = await axios.get(
      "/api/partner/list/?page=" + String(page),
      null,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
