import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Slide from "@material-ui/core/Slide";
import BannerList from "./banner-list";
import BannerView from "./banner-view";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      ViewId: ""
    };
  }

  componentWillReceiveProps(nextProps) {}

  toggleFormView = visible => {
    this.setState({
      isAddNew: visible,
      ViewId: ""
    });
  };

  handleEdit = id => {
    this.setState({
      isAddNew: true,
      ViewId: id
    });
  };

  render() {
    return this.state.isAddNew
      ? this.renderComponent()
      : this.renderComponentsList();
  }

  renderComponent = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <BannerView
            componentid={this.state.ViewId}
            addNew={this.toggleFormView}
          />
        </GridItem>
      </GridContainer>
    );
  };

  renderComponentsList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <BannerList addNew={this.toggleFormView} edit={this.handleEdit} />
        </GridItem>
      </GridContainer>
    );
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserId: state.authState.user.user
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Banner);
