import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from "../../components/ImageUpload";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { validationUtils } from "../../../utils";
import Ajv from "ajv";

class BannerView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ajv: {},
      isAddNew: false,
      formBuilderLayout: true,
      banner_data: {
        name: "",
        description: "",
        id: null,
        is_deleted: false,
        is_active: false,
        templateId: null,
        form: [],
        image: null,
        original_file_name: "",
        pages: "",
        is_show_title: false,
        is_show_description: false
      },
      files: [],
      required: {
        name: "",
        description: "",
        pages: ""
      },
      NotificationData: [],
      error: null,
      openDeleteDialog: false,
      NotificationId: null,
      NotificationValue: null,
      selectedUAIndexes: [],
      mappingData: {
        notifications: null,
        description: "",
        is_active: true
      },
      templateList: [],
      saveRequested: false
    };
    this.handelImageRemove = this.handelImageRemove.bind(this);
    this.handelImageChange = this.handelImageChange.bind(this);
    this.FormBuilderModeToggle = this.FormBuilderModeToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);

    const ajvObject = new Ajv({ allErrors: true, verbose: true });
    this.setState({
      ...this.state,
      ajv: ajvObject
    });
  }

  FormBuilderModeToggle() {
    this.setState({
      ...this.state,
      formBuilderLayout: !this.state.formBuilderLayout
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && !_.isEqual(this.props, nextProps)) {
      if (this.state.saveRequested) {
        if (!nextProps.error) {
          this.setState(
            () => ({
              saveRequested: false
            }),
            function() {
              this.props.addNew(false);
            }
          );
        }
      }

      if (
        nextProps.component &&
        !_.isEqual(nextProps.component, this.props.component)
      ) {
        this.setEditData(nextProps.component);
      }

      if (nextProps.isDeleted) {
        const { banner_data } = this.state;
        delete banner_data.image;
        this.setState(
          {
            banner_data: {
              ...this.state.banner_data,
              image: null
            }
          },
          () => {
            this.props.actions.denyRemoveImageAction();
          }
        );
      }
    }
  }

  setEditData(cmpnn) {
    this.setState(
      () => ({
        banner_data: {
          name: cmpnn.name,
          description: cmpnn.description,
          id: cmpnn.id,
          is_active: cmpnn.is_active,
          form: cmpnn.form,
          image: cmpnn.preview_image,
          pages: cmpnn.pages,
          is_show_title: cmpnn.is_show_title,
          is_show_description: cmpnn.is_show_description
        }
      }),
      function() {}
    );
  }

  componentDidMount() {
    // this.props.actions.getPartnerPages(this.props.partnerid);
    this.props.actions.CMSMenuList(
      this.props.authUser.partner_id,
      "CMS",
      false
    );
    if (this.props.componentid) {
      this.props.actions.getCMSBannerDetail(this.props.componentid);
    } else {
      this.props.actions.addingNewPage();
    }
  }

  componentWillMount() {}

  handelImageRemove = () => {
    let { image, original_file_name } = this.state.banner_data;
    let { files } = this.state;

    if (image instanceof Blob || typeof image == "string") {
      let index = files.findIndex(x => x.name == original_file_name);
      files.splice(index, 1);
      this.setState({
        banner_data: {
          ...this.state.banner_data,
          image: null
        },
        files: files
      });
    } else if (image instanceof Object) {
      this.props.actions.removeImageAction(image.id);
    }
  };

  handelIsAddNew = () => {
    this.setState({
      banner_data: {
        name: "",
        description: "",
        image: null,
        original_file_name: "",
        file_size: "",
        is_active: false,
        is_deleted: false,
        pages: "",
        is_show_title: false,
        is_show_description: false
      },
      files: [],
      required: {
        name: "",
        description: "",
        is_active: "",
        pages: ""
      },
      isEdit: false
    });
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;

    this.setState({
      required: reqData
    });

    this.setState({
      banner_data: {
        ...this.state.banner_data,
        [key]: event.target.value
      }
    });
  };

  handleToggleStatus = key => event => {
    this.setState({
      banner_data: {
        ...this.state.banner_data,
        [key]: event.target.checked
      }
    });
  };

  saveComponent = () => {
    if (this.validateIsRequired()) {
      let savingData = this.state.banner_data;
      savingData.partner_id = this.props.partnerid;

      this.setState({
        saveRequested: true
      });

      this.props.actions.saveBanners(
        this.props.UserId,
        savingData,
        this.state.files
      );
    }
  };

  handelEdit = id => {};

  handleFormChange(form) {
    this.setState({
      banner_data: {
        ...this.state.banner_data,
        form: form.components
      }
    });
  }

  handelImageChange = file => {
    if (file instanceof Blob) {
      let { banner_data, files } = this.state;
      let original_name = file.name;
      let fileSize = file.size;
      banner_data["image"] = file;
      banner_data["file_size"] = fileSize;
      banner_data["original_file_name"] = original_name;
      files.push(file);
      this.setState({
        banner_data: banner_data,
        files: files
      });
    } else {
      let { banner_data } = this.state;
      banner_data.image = file.url;
      if (file.is_asset) {
        banner_data.asset_id = file.id;
      }
      banner_data.file_size = file.file_size;
      banner_data.original_file_name = file.original_file_name;
      this.setState({
        ...this.state,
        banner_data: banner_data
      });
    }
  };

  CancelAddEdit = () => {
    this.props.addNew(false);
  };

  render() {
    return this.renderForm();
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.banner_data.hasOwnProperty("name") ||
      (this.state.banner_data.name.trim() === "" || reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.banner_data.hasOwnProperty("description") ||
      (this.state.banner_data.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  handleChange(form) {
    this.setState({
      ...this.state,
      banner_data: {
        ...this.state.banner_data,
        form: form
      }
    });
  }

  handleSelectChange = key => event => {
    var validData = true;
    if (!event.target.value) {
      validData = false;
    }

    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({
      ...this.state,
      required: reqData
    });

    this.setState({
      ...this.state,
      banner_data: {
        ...this.state.banner_data,
        [key]: event.target.value
      }
    });
  };

  renderForm = () => {
    const { classes } = this.props;
    const {
      name,
      description,
      is_active,
      id,
      image,
      form,
      is_show_title,
      is_show_description,
      pages
    } = this.state.banner_data;
    const { required } = this.state;
    let formBuilder = { display: "form", components: form };
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Title *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange(
                        "name",
                        validationUtils.validationTypes.name
                      )
                    }}
                    id={"name_"}
                  />

                  <CustomInput
                    success={required.description === "success"}
                    error={required.description === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: this.handleTextChange("description", "")
                    }}
                  />

                  <FormControl
                    fullWidth
                    style={customInputStyle.formControl}
                    name="industry"
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="Pages *"
                    >
                      Pages *
                    </InputLabel>
                    <Select
                      success={required.pages}
                      error={required.pages === false}
                      style={{ marginTop: "0px" }}
                      value={pages ? pages : []}
                      multiple
                      onChange={this.handleSelectChange("pages")}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Pages
                      </MenuItem>
                      {_.map(this.props.list, ({ id, name }, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={id}
                            key={index + "_" + id}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <br />

                  <h6>Banner Image</h6>
                  <ImageUpload
                    addButtonProps={{
                      round: true
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true
                    }}
                    imgUrl={
                      image instanceof Object
                        ? image instanceof Blob
                          ? image
                          : image.url
                        : image
                    }
                    onimageRemove={this.handelImageRemove}
                    onChange={this.handelImageChange}
                  />

                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={is_active}
                        onClick={this.handleToggleStatus("is_active")}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={is_show_title}
                        onClick={this.handleToggleStatus("is_show_title")}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Show title on Banner"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={is_show_description}
                        onClick={this.handleToggleStatus("is_show_description")}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Show description on Banner"
                  />

                  <br />

                  <Button color="info" onClick={this.saveComponent.bind(this)}>
                    Save Changes
                  </Button>

                  <Button onClick={this.CancelAddEdit}>Cancel</Button>

                  <br />

                  {this.props.error && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.props.error}</code>
                    </FormLabel>
                  )}

                  {(this.props.saving || this.props.loading) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.saving} text="Saving.." />
                  <Loader loading={this.props.loading} text="Loading.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  // list: state.cmsReducer.get_CMS_Pages.list,
  saving: state.cmsReducer.create_CMS_banner.saving,
  error: state.cmsReducer.create_CMS_banner.error,
  partnerid: state.authState.user.partner_id,
  loading: state.cmsReducer.banner_detail.loading,
  error: state.cmsReducer.banner_detail.error,
  component: state.cmsReducer.banner_detail.banner,
  UserId: state.authState.user.pk,
  authUser: state.authState.user,
  isDeleted: state.appMenuReducer.removeImage.isDeleted,
  list: state.partnerMenuReducer.get_cms_menu_list.list
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BannerView);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
