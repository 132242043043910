// import axios from 'axios';
import axios from "../utils/axios.jsx";
export const getSegmentsList = async (
  Savedata,
  industryId,
  templateid,
  page,
  search
) => {
  try {
    let fetchUrl = "/api/segments/getall/";
    if (industryId) {
      fetchUrl = fetchUrl + "?industryid=" + industryId;
    } else if (templateid) {
      fetchUrl = fetchUrl + "?templateid=" + templateid;
    }
    if (page) {
      fetchUrl = fetchUrl + "?page=" + page;
    }
    if (search) {
      fetchUrl = fetchUrl + "&search=" + search;
    }
    const response = await axios.get(fetchUrl);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveSegments = async (userId, Savedata, partnerId) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("partnerid", partnerId);
    data.append("file", Savedata.image);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("/api/segments/create/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteSegments = async (userId, segment) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("segment", segment);

    const response = await axios.post("/api/segments/delete/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const mapClassSegment = async (userId, mapData) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(mapData));

    const response = await axios.post("/api/classes/segment/create/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const mapServiceSegment = async (userId, mapData) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(mapData));

    const response = await axios.post("/api/segment/service/attach/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const unassignedWholesalorList = async (
  partnerid,
  role,
  page,
  search
) => {
  try {
    let url =
      "/api/retailers/unassigned-wholesaler/list/?partnerid=" + partnerid;
    if (role) {
      url = url + "&role=" + role;
    }
    if (page) {
      url = url + "&page=" + page;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveRetailersWholesaler = async (data, partnerid) => {
  try {
    let postdata = {};
    postdata.partnerid = partnerid;
    postdata.data = data;
    let postData = new FormData();
    // postData.append("partnerid", partnerid)
    postData.append("data", JSON.stringify(postdata));

    const response = await axios.post(
      "/api/retailers/wholesalor/save/",
      postData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPartnersIndustrySegment = async (partnerid) => {
  try {
    let fetchUrl = "/api/partner/industry/segment/get/";
    if (partnerid) {
      fetchUrl = fetchUrl + "?partnerid=" + partnerid;
    }
    const response = await axios.get(fetchUrl);
    return response.data;
  } catch (error) {
    throw error;
  }
};
