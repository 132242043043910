import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TextField from "@material-ui/core/TextField";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import InputLabel from "@material-ui/core/InputLabel";
import VariantSelector from "./product-variant-selector";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

class ProductVariant extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            variants: [],
            variant_list: [],
            variantValues: [],
            productTemplates: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && !_.isEqual(this.props, nextProps)) {

            if (
                nextProps.productTemplates &&
                !_.isEqual(this.props.productTemplates, nextProps.productTemplates)
            ) {
                this.setData(nextProps.productTemplates);
            }

            if (nextProps.list) {
                this.setState({
                    variant_list: nextProps.list
                });
            }

            if (nextProps.data && nextProps.data.variants) {
                this.setState({
                    variants: nextProps.data.variants
                });
            }

            if (nextProps.data && nextProps.data.variantValues) {
                this.setState({
                    variantValues: nextProps.data.variantValues
                });
            }
        }
    }

    componentDidMount() {
        this.refreshPartnerProductVariant()
        this.props.actions.getAllProductTemplates(
            this.props.partner_id
        );
    }

    setData(data) {

        this.setState(() => ({
            productTemplates: data,
        }));
    }

    refreshPartnerProductVariant = () => {
        if (this.props.partner_id)
            this.props.actions.getProductVariants(this.props.partner_id, null, null, true);
    }

    handleAddVariantRow = () => {
        this.props.handleAddVariantRow()
    }

    handleVariantRemove = (index) => {
        this.props.handleVariantRemove(index)
    }

    handleVariantChange = (index, value) => {
        this.props.handleVariantChange(index, value)
    }

    handleVariantValueChange = (index, value) => {
        this.props.handleVariantValueChange(index, value)
    }

    render() {
        const { classes } = this.props;
        let prodVarOptions = [];
        if (this.state.productTemplates && this.state.productTemplates.length > 0) {
            let res = this.state.productTemplates.filter(
                cls => cls.id != this.props.data.id
            );
            for (let i = 0; i < res.length; i++) {
              let obj = res[i];
              let var_index = this.state.variants.findIndex(x=>x.id==obj.id)
              if (var_index >-1){
                continue;  
              }else{
                prodVarOptions.push(res[i]);      
              }
            }
        }
        return (
            <GridContainer direction="row"
                justify="center"
                alignItems="center" >
                <GridItem lg={10} md={10}>
                    <form>
                        <GridContainer className="grid-container-control">
                            <GridItem lg={12} md={12} className="griditem-control">

                                <GridItem lg={12} md={12} className="griditem-control">
                                    <InputLabel className={classes.label + " "}>
                                        Add Variant Values
                                </InputLabel>
                                    <br />
                                    <VariantSelector
                                        variantMaster={this.props.variantMaster}
                                        variantList={this.props.variantsList}
                                        data={this.state.variantValues}
                                        handleAddVariantRow={this.handleAddVariantRow}
                                        handleVariantRemove={this.handleVariantRemove}
                                        handleVariantChange={this.handleVariantChange}
                                        handleVariantValueChange={this.handleVariantValueChange}
                                    />
                                </GridItem>

                                <br />
                                <br />

                                <InputLabel className={classes.label + " "}>
                                    Choose products with common variation
                                </InputLabel>
                                <br />
                                <Autocomplete
                                    options={prodVarOptions}
                                    value={this.state.variants || []}
                                    getOptionLabel={option => (option.name ? option.name : "")}
                                    filterSelectedOptions
                                    name="brand"
                                    multiple
                                    onChange={(event, value) =>
                                        this.handleAutoCompleteChange("variants", value)
                                    }
                                    classes={{
                                        root: "custom-autocomplete",
                                        inputRoot: "custom-autocomplete-input-root",
                                        tag: "custom-autocomplete-tag"
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select product with similar variant set"
                                        />
                                    )}
                                />
                            </GridItem>
                        </GridContainer>
                    </form>
                </GridItem>
            </GridContainer>
        );
    }


    handleAutoCompleteChange = (name, value) => {
        this.props.onVariantGroupChange(value);
    };
}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
    PartnerId: state.authState.user.partner_id,
    UserId: state.authState.user.user,

    productTemplates: state.brandState.productTemplate.products,
    loading: state.productVariantState.list_product_variant.loading,
    error: state.productVariantState.list_product_variant.error,
    list: state.productVariantState.list_product_variant.variants,

});

export default compose(
    withStyles({
        ...regularFormsStyle,
        ...extendedTablesStyle,
        ...validationFormsStyle
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ProductVariant);
