import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "../../style/app.css";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

import { AgGridReact } from "ag-grid-react";

let AggridApi;
let stateTemp;
let disableSelected = false;

class IndustryPages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userGroupId: "",
      data: {},
      menus: [],
      selectedMenu: [],
      columnDefs: [
        {
          headerName: "Industry / Pages",
          field: "name",
          width: 400,
          resizable: true,
          cellRenderer: "group",
          checkboxSelection: true
        }
      ],
      context: { componentParent: this },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      domLayout: "autoHeight",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      getNodeChildDetails: function(data) {
        if (data && data.pages && data.pages.length > 0) {
          let children = [];
          children = data.pages;
          return {
            group: true,
            children: children,
            expanded: data.open
          };
        } else {
          return null;
        }
      },
      isRowSelectable: function(node) {
        if (!disableSelected) {
          return true;
        }

        if (!node || !node.data || !node.data.id) {
          return true;
        }

        if (!stateTemp) {
          return true;
        }

        if (stateTemp.indexOf(node.data.id) > -1) {
          return false;
        }

        return true;
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(this.props.appMenus, nextProps.appMenus) ||
      !_.isEqual(this.props.selected_items, nextProps.selected_items)
    ) {
      if (this.props.type !== nextProps.type) {
        this.refreshList(nextProps.type);
      }

      if (nextProps && nextProps.appMenus) {
        this.setState(
          {
            ...this.state,
            menus: nextProps.appMenus
          },
          () => {
            this.gridApi.setRowData(this.state.menus);
            if (
              this.props.selected_items &&
              this.props.selected_items.length > 0
            ) {
              let selected_items = this.props.selected_items;
              this.gridApi.forEachNode(function(node) {
                if (selected_items.indexOf(node.data.id) > -1) {
                  node.setSelected(true);
                } else {
                  node.setSelected(false);
                }
              });
            }
          }
        );
      }
    }

    if (nextProps.selected_items) stateTemp = nextProps.selected_items;

    disableSelected = this.props.disableSelected;
  }

  refreshList() {
    this.props.actions.getIndustryPagesGroupList();
  }

  componentDidMount() {
    this.refreshList();
  }

  render() {
    return <div>{this.renderOrderTable()}</div>;
  }

  renderOrderTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <p
                className={
                  classes.cardIconTitle + " wholesalor-hierarchy-title"
                }
              >
                Pages
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <div>
                  <div
                    id="myGrid"
                    style={{
                      width: "100%"
                    }}
                    className="ag-theme-material"
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      defaultColDef={this.state.defaultColDef}
                      // rowData={this.state.menus}
                      showToolPanel={true}
                      onGridReady={param => this.onGridReady(param)}
                      onSelectionChanged={this.onSelectionChanged.bind(this)}
                      statusBar={this.state.statusBar}
                      rowSelection="multiple"
                      sideBar={this.state.sideBar}
                      domLayout={this.state.domLayout}
                      getNodeChildDetails={this.state.getNodeChildDetails}
                      groupSelectsChildren={true}
                      groupSelectsFiltered={true}
                      pagination={true}
                      isRowSelectable={this.state.isRowSelectable}
                      paginationAutoPageSize={true}
                    />
                  </div>
                </div>
                {this.props.loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    let selectedIds = [];
    if (selectedRows) {
      selectedIds = _.flatMap(selectedRows, data => {
        return data.id;
      });
      this.props.setSelectedItems(selectedIds);
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  authUser: state.authState.user,
  appMenus: state.cmsReducer.get_industry_page_groups.list,
  loading: state.cmsReducer.get_industry_page_groups.loading,
  error: state.cmsReducer.get_industry_page_groups.error
});

IndustryPages.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func,
  save: PropTypes.func,
  type: PropTypes.string.isRequired
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(IndustryPages);
