import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  
  payment_report: {
    loading: false,
    error: null,
    data: null
  },
  order_report: {
    loading: false,
    error: null,
    data: null
  },
  settlement_report: {
    loading: false,
    error: null,
    data: null
  },
  download_report: {
    loading: false,
    error: null,
    data: null
  },
  options_report: {
    loading: false,
    error: null,
    data: null
  },
};

const reportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SETTLEMENT_REPORT_REQUEST:
      return {
        ...state,
        settlement_report: {
          loading: true,
          error: null,
          data: null
        }
      };
    case actionTypes.SETTLEMENT_REPORT_FAILURE:
      return {
        ...state,
        settlement_report: {
          loading: false,
          error: action.payload,
          data: null
        }
      };
    case actionTypes.SETTLEMENT_REPORT_SUCCESS:
      return {
        ...state,
        settlement_report: {
          loading: false,
          error: null,
          data: action.payload
        }
      };

    case actionTypes.PAYMENT_REPORT_REQUEST:
      return {
        ...state,
        payment_report: {
          loading: true,
          error: null,
          data: null
        }
      };
    case actionTypes.PAYMENT_REPORT_FAILURE:
      return {
        ...state,
        payment_report: {
          loading: false,
          error: action.payload,
          data: null
        }
      };
    case actionTypes.PAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        payment_report: {
          loading: false,
          error: null,
          data: action.payload
        }
      };
    case actionTypes.ORDER_REPORT_REQUEST:
      return {
        ...state,
        order_report: {
          loading: true,
          error: null,
          data: null
        }
      };
    case actionTypes.ORDER_REPORT_FAILURE:
      return {
        ...state,
        order_report: {
          loading: false,
          error: action.payload,
          data: null
        }
      };
    case actionTypes.ORDER_REPORT_SUCCESS:
      return {
        ...state,
        order_report: {
          loading: false,
          error: null,
          data: action.payload
        }
      };
      case actionTypes.DOWNLOAD_REPORT_REQUEST:
        return {
          ...state,
          download_report: {
            loading: true,
            error: null,
            data: null
          }
        };
      case actionTypes.DOWNLOAD_REPORT_FAILURE:
        return {
          ...state,
          download_report: {
            loading: false,
            error: action.payload,
            data: null
          }
        };
      case actionTypes.DOWNLOAD_REPORT_SUCCESS:
        return {
          ...state,
          download_report: {
            loading: false,
            error: null,
            data: action.payload
          }
        };
        case actionTypes.REPORT_OPTION_REQUEST:
          return {
            ...state,
            options_report: {
              loading: true,
              error: null,
              data: null
            }
          };
        case actionTypes.REPORT_OPTION_FAILURE:
          return {
            ...state,
            options_report: {
              loading: false,
              error: action.payload,
              data: null
            }
          };
        case actionTypes.REPORT_OPTION_SUCCESS:
          return {
            ...state,
            options_report: {
              loading: false,
              error: null,
              data: action.payload
            }
          };
    default:
      return state;
  }
};

export default reportReducer;
