import * as productsAssignmentsService from "../../services/productsAssignmentsService";
import * as actionTypes from "./actionTypes";

export const saveProductAssignment = (
  data,
  userId,
  partnerId
) => async dispatch => {
  dispatch(createProductAssignmentRequest());
  try {
    const success = await productsAssignmentsService.saveProductAssignment(
      data,
      userId,
      partnerId
    );
    if (success.status && success.status === "1") {
      dispatch(createProductAssignmentSuccess(success.data));
    } else {
      dispatch(createProductAssignmentFailure(success.error));
    }
  } catch (error) {
    dispatch(createProductAssignmentFailure(error.message));
  }
};

export const getProductAssignmentList = (
  userId,
  islist = null,
  page = 0,
  searchtext = null
) => async dispatch => {
  dispatch(getProductAssignmentListRequest());
  dispatch(resetMapOfferProductWindow());
  try {
    const success = await productsAssignmentsService.getProductAssignmentList(
      userId,
      islist,
      page,
      searchtext
    );
    if (success.status && success.status === "1") {
      dispatch(getProductAssignmentListSuccess(success.data));
    }
  } catch (error) {
    dispatch(getProductAssignmentListFailure(error.message));
  }
};

const createProductAssignmentSuccess = data => {
  return {
    type: actionTypes.SAVE_PRODUCT_ASSIGNMENTS_SUCCESS,
    payload: data
  };
};

const createProductAssignmentFailure = error => {
  return {
    type: actionTypes.SAVE_PRODUCT_ASSIGNMENTS_FAILURE,
    payload: error
  };
};

const createProductAssignmentRequest = () => {
  return {
    type: actionTypes.SAVE_PRODUCT_ASSIGNMENTS_REQUEST
  };
};

const resetMapOfferProductWindow = () => {
  return {
    type: actionTypes.MAP_OFFER_PRODUCT_COMPLETED
  };
};

const getProductAssignmentListSuccess = data => {
  return {
    type: actionTypes.GET_PRODUCT_ASSIGNMENTS_SUCCESS,
    payload: data
  };
};

const getProductAssignmentListFailure = error => {
  return {
    type: actionTypes.GET_PRODUCT_ASSIGNMENTS_FAILURE,
    payload: error
  };
};

const getProductAssignmentListRequest = () => {
  return {
    type: actionTypes.GET_PRODUCT_ASSIGNMENTS_REQUEST
  };
};

export const saveProduct = (data, userId, files) => async dispatch => {
  dispatch(createProductAssignmentRequest());
  try {
    const success = await productsAssignmentsService.saveProduct(
      data,
      userId,
      files
    );
    if (success.status && success.status === "1") {
      dispatch(createProductAssignmentSuccess(success.data));
    } else {
      dispatch(createProductAssignmentFailure(success.error));
    }
  } catch (error) {
    dispatch(createProductAssignmentFailure(error.message));
  }
};

export const getProductByIdAction = productid => async dispatch => {
  dispatch(getProductByIdRequest());
  try {
    const success = await productsAssignmentsService.getProductById(productid);
    if (success.status && success.status === "1") {
      dispatch(getProductByIdSuccess(success.data));
    } else {
      dispatch(getProductByIdFailure(success.error));
    }
  } catch (error) {
    dispatch(getProductByIdFailure(error.message));
  }
};

const getProductByIdSuccess = data => {
  return {
    type: actionTypes.GET_PRODUCT_BYID_SUCCESS,
    payload: data
  };
};

const getProductByIdFailure = error => {
  return {
    type: actionTypes.GET_PRODUCT_BYID_FAILURE,
    payload: error
  };
};

const getProductByIdRequest = () => {
  return {
    type: actionTypes.GET_PRODUCT_BYID_REQUEST
  };
};

export const getRetailerRelatedBrandAction = partnerid => async dispatch => {
  dispatch(getRetailerRelatedBrandRequest());
  try {
    const success = await productsAssignmentsService.getRetailerRelatedBrand(
      partnerid
    );
    if (success.status && success.status === "1") {
      dispatch(getRetailerRelatedBrandSuccess(success.data));
    } else {
      dispatch(getRetailerRelatedBrandFailure(success.error));
    }
  } catch (error) {
    dispatch(getRetailerRelatedBrandSuccess(error.message));
  }
};

const getRetailerRelatedBrandSuccess = data => {
  return {
    type: actionTypes.GET_RETAILERS_BRAND_SUCCESS,
    payload: data
  };
};

const getRetailerRelatedBrandFailure = error => {
  return {
    type: actionTypes.GET_RETAILERS_BRAND_FAILURE,
    payload: error
  };
};

const getRetailerRelatedBrandRequest = () => {
  return {
    type: actionTypes.GET_RETAILERS_BRAND_REQUEST
  };
};

export const getBrandProductByIdAction = partnerid => async dispatch => {
  dispatch(getBrandProductByIdRequest());
  try {
    const success = await productsAssignmentsService.getBrandProductById(
      partnerid
    );
    if (success.status && success.status === "1") {
      dispatch(getBrandProductByIdSuccess(success.data));
    } else {
      dispatch(getBrandProductByIdFailure(success.error));
    }
  } catch (error) {
    dispatch(getBrandProductByIdSuccess(error.message));
  }
};

const getBrandProductByIdSuccess = data => {
  return {
    type: actionTypes.GET_BRAND_PRODUCT_BYID_SUCCESS,
    payload: data
  };
};

const getBrandProductByIdFailure = error => {
  return {
    type: actionTypes.GET_BRAND_PRODUCT_BYID_FAILURE,
    payload: error
  };
};

const getBrandProductByIdRequest = () => {
  return {
    type: actionTypes.GET_BRAND_PRODUCT_BYID_REQUEST
  };
};

export const getProductTemplateListAction = (
  page = 0,
  searchparams = null
) => async dispatch => {
  dispatch(getProductTemplateListRequest());
  try {
    const success = await productsAssignmentsService.getProductTemplateList(
      page,
      searchparams
    );
    if (success.status && success.status === "1") {
      dispatch(getProductTemplateListSuccess(success.data));
    } else {
      dispatch(getProductTemplateListFailure(success.error));
    }
  } catch (error) {
    dispatch(getProductTemplateListSuccess(error.message));
  }
};

const getProductTemplateListSuccess = data => {
  return {
    type: actionTypes.PRODUCT_TEMPLATE_LIST_SUCCESS,
    payload: data
  };
};

const getProductTemplateListFailure = error => {
  return {
    type: actionTypes.PRODUCT_TEMPLATE_LIST_FAILURE,
    payload: error
  };
};

const getProductTemplateListRequest = () => {
  return {
    type: actionTypes.PRODUCT_TEMPLATE_LIST_REQUEST
  };
};

export const markProductActiveAction = (
  productid,
  userid
) => async dispatch => {
  dispatch(markProductActiveRequest());
  try {
    const success = await productsAssignmentsService.markProductActive(
      productid,
      userid
    );
    if (success.status && success.status === "1") {
      dispatch(markProductActiveSuccess(success.data));
    } else {
      dispatch(markProductActiveFailure(success.error));
    }
  } catch (error) {
    dispatch(markProductActiveFailure(error.message));
  }
};

const markProductActiveSuccess = data => {
  return {
    type: actionTypes.MARK_PRODUCT_ACTIVE_SUCCESS,
    payload: data
  };
};

const markProductActiveFailure = error => {
  return {
    type: actionTypes.MARK_PRODUCT_ACTIVE_FAILURE,
    payload: error
  };
};

const markProductActiveRequest = () => {
  return {
    type: actionTypes.MARK_PRODUCT_ACTIVE_REQUEST
  };
};

export const deleteProductAction = (
  productid,
) => async dispatch => {
  dispatch(deleteProductsRequest());
  try {
    const success = await productsAssignmentsService.deleteProducts(
      productid
    );
    if (success.status && success.status === "1") {
      dispatch(deleteProductsSuccess(success.data));
    } else {
      dispatch(deleteProductsFailure(success.error));
    }
  } catch (error) {
    dispatch(deleteProductsFailure(error.message));
  }
};

const deleteProductsSuccess = data => {
  return {
    type: actionTypes.DELETE_PRODUCT_SUCCESS,
    payload: data
  };
};

const deleteProductsFailure = error => {
  return {
    type: actionTypes.DELETE_PRODUCT_FAILURE,
    payload: error
  };
};

const deleteProductsRequest = () => {
  return {
    type: actionTypes.DELETE_PRODUCT_REQUEST
  };
};
