import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { validationUtils } from "../../utils";

// @material-ui/core components/Dialog
import Slide from "@material-ui/core/Slide";

import ReactTooltip from "react-tooltip";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class AdChannel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      addChannelData: {
        name: "",
        descriptions: "",
        is_deleted: false,
        is_active: false
      },
      required: {
        name: "",
        descriptions: "",
        is_active: ""
      },
      OfferData: null,
      error: null,
      openDeleteDialog: false,
      OfferId: null,
      OfferValue: null,
      selectedUAIndexes: [],
      mappingData: {
        offers: null,
        descriptions: "",
        is_active: true
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const OfferData = nextProps.List;
        this.setState(() => ({
          OfferData: OfferData
        }));
      }

      if (nextProps.Error && nextProps.Error.trim() !== "") {
        this.setState(() => ({
          error: nextProps.Error,
          isAddNew: true
        }));
      }
      if (nextProps.isSaved) {
        this.setState(() => ({
          isAddNew: false,
          isEdit: true
        }));
      }
      if (nextProps.isDeleted) {
        this.setState(() => ({
          openDeleteDialog: false
        }));
      }
    }
  }

  componentDidMount() {
    this.props.actions.getOfferTypeList(this.props.UserId);
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      addChannelData: {
        name: "",
        descriptions: "",
        is_active: false,
        is_deleted: false
      }
    });
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });
    this.setState({
      addChannelData: {
        ...this.state.addChannelData,
        [key]: event.target.value
      }
    });
  };

  handleToggleStatus = curObj => event => {
    this.setState({
      addChannelData: {
        ...this.state.addChannelData,
        is_active: event.target.checked
      }
    });
  };

  saveOfferType = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addChannelData;
      this.props.actions.saveOfferType(this.props.UserId, savingData);
    }
  };

  handelEdit = index => {
    const { isEdit, OfferData } = this.state;
    const data = OfferData;
    if (data[index]) {
      this.setState({
        addChannelData: {
          name: data[index].name,
          descriptions: data[index].descriptions,
          index: index,
          id: data[index].id,
          is_active: data[index].is_active,
          is_deleted: false
        },
        isAddNew: true,
        isEdit: true
      });
    }
  };

  render() {
    return this.state.isAddNew
      ? this.renderOfferType()
      : this.renderOfferTypeTable();
  }
  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addChannelData.hasOwnProperty("name") ||
      (this.state.addChannelData.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addChannelData.hasOwnProperty("descriptions") ||
      (this.state.addChannelData.descriptions.trim() === "" ||
        reqData["descriptions"] === "error")
    ) {
      reqData["descriptions"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  renderOfferType = () => {
    const { classes } = this.props;
    const { name, descriptions, is_active } = this.state.addChannelData;
    const { required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <CustomInput
                    success={required.descriptions === "success"}
                    error={required.descriptions === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: descriptions,
                      onChange: this.handleTextChange("descriptions", "")
                    }}
                    id={"description_"}
                  />
                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus(is_active)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />
                  <br />
                  {this.props.Error && this.props.Error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button color="info" onClick={this.saveOfferType}>
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {this.props.isSaving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.isSaving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
  renderOfferTypeTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.Loading}
              color="success"
              onClick={this.handelIsAddNew}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} /> New
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>View AD Channel below</p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <Table
                  tableHead={["Name", "Description", "Status", "Action"]}
                  tableData={this.getTableData()}
                  tableShopping
                  customHeadCellClasses={[
                    classes.tdName,
                    classes.descriptions,
                    classes.left
                  ]}
                  // customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                  // customCellClasses={[
                  //     classes.customFont,
                  //     classes.tdName,
                  //     classes.customFont,
                  //     classes.tdNumber,
                  //     classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                  //     classes.tdNumber
                  // ]}
                  // customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                />
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  getTableData = () => {
    const { OfferData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(OfferData, ({ id, name, descriptions, is_active }, index) => {
      returnData.push([
        <span>
          {
            <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
              {name}
            </a>
          }
        </span>,
        <span>{descriptions}</span>,
        <span>{is_active ? "Active" : "Inactive"}</span>,
        <span>
          <ReactTooltip id="editClass">
            <span>Edit Notification Type</span>
          </ReactTooltip>
          <Button
            color="success"
            data-tip
            data-for="editClass"
            className={classes.actionButton}
            onClick={() => this.handelEdit(index)}
          >
            <CreateIcon className={classes.icon} />
          </Button>
        </span>
      ]);
    });
    return returnData;
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.OfferTypeReducer.list,
  Loading: state.OfferTypeReducer.loading,
  ListError: state.OfferTypeReducer.error,
  isSaved: state.OfferTypeReducer.isSaved,
  isDeleted: state.OfferTypeReducer.isDeleted,
  UserId: state.authState.user.user,
  isSaving: state.OfferTypeReducer.isSaving
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AdChannel);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
