import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";

// core components
import { Autocomplete } from "@material-ui/lab";
import { Grid, InputLabel, FormControl, Tooltip, TextField } from "@material-ui/core";
import { HelpOutlineRounded, Add as AddIcon, Clear as ClearIcon } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import ReactTooltip from "react-tooltip";

import "antd/dist/antd.css";
import { Popconfirm } from "antd";

const attributeTableRow = {
  id: "",
  name: "",
  attribute_value: ""
};

class ProductCategoryAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      categorylist: [],
      segmentlist: [],
      classlist: [],
      attributeRow: {
        ...attributeTableRow
      },
      columns: [
        {
          title: "name",
          dataIndex: "name",
          width: "30%",
          editable: true
        },
        {
          title: "Value",
          dataIndex: "attribute_value",
          width: "30%",
          editable: true
        },
        {
          title: "operation",
          dataIndex: "operation",
          render: (text, record, index) =>
            this.state.data.attributes.length >= 1 ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => this.handleDelete(record, index)}
              >
                <a>Delete</a>
              </Popconfirm>
            ) : null
        }
      ]
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        this.setState({
          data: nextProps.data
        });
        if (!_.isEqual(this.props.ClassList, nextProps.ClassList)) {
          this.setState({
            categorylist: nextProps.ClassList
          });
        }
        if (nextProps.SegmentList) {
          this.setState({
            segmentlist: nextProps.SegmentList
          });
        }
      }
    }
  }

  componentDidMount() {
    this.props.actions.getPartnersIndustrySegment(this.props.PartnerId);
    this.props.actions.getClassList(this.props.PartnerId);
    this.props.actions.getCategoryListAction(this.props.PartnerId);
    if (this.props.data) {
      this.setState({
        data: this.props.data
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { categorylist, segmentlist } = this.state;
    const { categories, segments } = this.state.data;
    return (
      <Grid>
        <GridContainer>
          <GridItem lg={1} md={1} />
          <GridItem lg={10} md={10} sm={12} xs={12}>
            <form>
              <GridContainer className="grid-container-control">
                <GridItem lg={12} md={12} className="griditem-control">
                  <InputLabel
                    className={classes.label + " "}
                    labelText="Classes"
                  >
                    Classes
                    <Tooltip
                      className="control-tooltip"
                      title="Please select or Add new Classes"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>
                  <br />
                  <Autocomplete
                    multiple
                    options={categorylist ? categorylist : []}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    value={categories && categories.length ? categories : []}
                    onChange={(event, value) =>
                      this.handleChange("categories", value)
                    }
                    classes={{
                      root: "custom-autocomplete",
                      inputRoot: "custom-autocomplete-input-root",
                      tag: "custom-autocomplete-tag"
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Classes"
                      />
                    )}
                  />
                </GridItem>
                <GridItem lg={12} md={12} className="griditem-control">
                  <InputLabel
                    className={classes.label + " "}
                    labelText="Segments"
                  >
                    Segments
                    <Tooltip
                      className="control-tooltip"
                      title="Please Select Segments"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>
                  <br />
                  <Autocomplete
                    multiple
                    options={segmentlist ? segmentlist : []}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    value={segments && segments.length ? segments : []}
                    onChange={(event, value) =>
                      this.handleChange("segments", value)
                    }
                    classes={{
                      root: "custom-autocomplete",
                      inputRoot: "custom-autocomplete-input-root",
                      tag: "custom-autocomplete-tag"
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Segment"
                      />
                    )}
                  />
                </GridItem>
                <GridItem lg={12} md={12} className="griditem-control">
                  <div>
                    <Button
                      color="success"
                      data-tip
                      data-for="editInd"
                      className={classes.actionButton}
                      style={{ float: "right", margin: "0px" }}
                      onClick={() => this.handelNewRow()}
                    >
                      <AddIcon className={classes.icons} /> New Attribute
                    </Button>
                  </div>
                  <Table
                    tableHead={["Name", "Value"]}
                    tableData={this.getProductTableData()}
                    tableShopping
                  />
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
          <GridItem lg={1} md={1} />
        </GridContainer>
      </Grid>
    );
  }

  handelNewRow = () => {
    let { attributes } = this.state.data;
    let attributesobj = { ...attributeTableRow };
    attributes.push(attributesobj);
    this.setState({
      data: {
        ...this.state.data,
        attributes: attributes
      }
    });
  };

  removeRow = index => {
    let { attributes } = this.state.data;
    attributes.splice(index, 1);
    this.setState({
      data: {
        ...this.state.data,
        attributes: attributes
      }
    });
  };

  handleDelete = index => {
    const dataSource = [...this.state.data.attributes];
    let list = dataSource.splice(index, 1);
    this.props.setProductData("attributes", list);
    // this.setState({
    //   dataSource: dataSource.filter(item => item.key !== key),
    // });
  };

  handleAdd = () => {
    const { count, data } = this.state;
    const { attributes } = this.state.data;
    const newData = {
      id: "",
      name: "Name",
      attribute_value: "Value"
    };
    this.props.setProductData("attributes", [...attributes, newData]);
    // this.setState({
    //     dataSource: [...attributes, newData],
    //     count: count + 1,
    // });
  };

  handleSave = row => {
    const newData = [...this.state.data.attributes];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.props.setProductData("attributes", newData);
    // this.setState({
    //     dataSource: newData,
    // });
  };

  getProductTableData = () => {
    const { attributes } = this.state.data;
    const { classes } = this.props;
    var returnData = [];
    _.map(attributes, ({ id, name, attribute_value }, index) => {
      returnData.push([
        <span>
          <FormControl fullWidth className={classes.selectFormControl}>
            <CustomInput
              labelText="Enter Attribute Name"
              classes="table-field"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "text",
                value: name,
                name: "name",
                onChange: this.handleTextChange(index)
              }}
              id={"name_"}
            />
          </FormControl>
        </span>,
        <span>
          <FormControl fullWidth className={classes.selectFormControl}>
            <CustomInput
              labelText="Enter Attribute Value *"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "text",
                value: attribute_value,
                name: "attribute_value",
                onChange: this.handleTextChange(index)
              }}
              id={"name_"}
            />
          </FormControl>
        </span>,
        <span>
          <ReactTooltip id="deleteRow">
            <span>Delete Row</span>
          </ReactTooltip>
          <Button
            color="danger"
            data-tip
            data-for="deleteInd"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.removeRow(index)}
          >
            <ClearIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>
        </span>
      ]);
    });
    return returnData;
  };

  handleCategoryChange(value) {
    this.props.setProductData("categories", value);
    console.log(`Selected: ${value}`);
  }

  handleChange(name, value) {
    this.props.setProductData(name, value);
    console.log(`Selected: ${value}`);
  }

  handleTextChange = index => event => {
    let { attributes } = this.state.data;
    attributes[index][event.target.name] = event.target.value;
    this.props.setProductData("attributes", attributes);
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  ClassList: state.classReducer.list,
  SegmentList: state.SegmentsReducer.partnerIndustrySegment.list,
  UserId: state.authState.user.user,
  PartnerId: state.authState.user.partner_id
});

ProductCategoryAttribute.propTypes = {
  closePromotion: PropTypes.func.isRequired,
  changeSelectedTab: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductCategoryAttribute);
