import axios from "../utils/axios.jsx";

export const settlementReport = async (userId, startDate, endDate, status) => {
  try {
    let data = new FormData();
    data.append("partner_key", userId);
    data.append("start_date", startDate);
    data.append("end_date", endDate);
    data.append("status", status);
    const response = await axios.post(
      "/api/retailer/report/settlement-detail/",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const paymentReport = async (userId, startDate, endDate, status) => {
  try {
    let data = new FormData();
    data.append("partner_key", userId);
    data.append("start_date", startDate);
    data.append("end_date", endDate);
    data.append("status", status);
    const response = await axios.post(
      "/api/retailer/report/payment-detail/",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const orderReport = async (userId, startDate, endDate, status) => {
  try {
    let data = new FormData();
    data.append("partner_key", userId);
    data.append("start_date", startDate);
    data.append("end_date", endDate);
    data.append("status", status);
    const response = await axios.post(
      "/api/retailer/report/order-detail/",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const partnerReportOptions = async (partner_type) => {
  try {
    let data = new FormData();
    data.append("partner_role", partner_type);
    
    const response = await axios.post(
      "/api/partner/partner-report/options",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadProductReport = async (report_type,partner_id) => {
  try {
    let data = new FormData();
    data.append("partner_id", partner_id);
    data.append("report_type",report_type)
    
    const response = await axios.post(
      "/api/partner/partner-report/export",
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};