import * as businessTypeServices from "../../services/IndustriesServices";
import * as actionTypes from "./actionTypes";

export const getIndustriesTypeList = (id, type) => async dispatch => {
  dispatch(getIndustriesRequest());
  try {
    const success = await businessTypeServices.getIndustriesTypeList(id, type);
    dispatch(getIndustriesSuccess(success.data));
  } catch (error) {
    dispatch(getIndustriesFailure(error.message));
  }
};

export const saveIndustriesType = (id, data) => async dispatch => {
  dispatch(saveIndustriesRequest());
  try {
    const success = await businessTypeServices.saveIndustriesType(id, data);
    if (success.status && success.status === "1") {
      dispatch(saveIndustriesSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(saveIndustriesFailure(success.error));
    } else {
      dispatch(saveIndustriesFailure("Some Error Occured.!!!"));
    }
  } catch (error) {
    dispatch(saveIndustriesFailure(error.message));
  }
};

export const deleteIndustries = (id, class_id) => async dispatch => {
  dispatch(deleteIndustriesRequest());
  try {
    const success = await businessTypeServices.deleteIndustries(id, class_id);
    dispatch(deleteIndustriesSuccess(success.data, class_id));
  } catch (error) {
    dispatch(deleteIndustriesFailure(error.message));
  }
};

export const mapIndustriesToSegment = (id, mapData) => async dispatch => {
  dispatch(saveIndustrieSegmentRequest());
  try {
    const success = await businessTypeServices.mapIndustriesToSegment(
      id,
      mapData
    );
    dispatch(saveIndustriesSuccess(success.data));
  } catch (error) {
    dispatch(saveIndustrieSegmentFailure(error.message));
  }
};

export const afertMapIndustries = () => async dispatch => {
  dispatch(afertMapIndustriesRequest());
};
const afertMapIndustriesRequest = () => {
  return {
    type: actionTypes.AFTER_MAP_INDUSTRIES_SEGMET_SUCCESS
  };
};

const afterSaveIndustrieSegmentequest = () => {
  return {
    type: actionTypes.AFTER_MAP_CLASS_UA_SUCCESS
  };
};

const saveIndustrieSegmentRequest = () => {
  return {
    type: actionTypes.MAP_INDUSTRIES_SEGMET_REQUEST
  };
};
const saveIndustrieSegmentSuccess = data => {
  return {
    type: actionTypes.MAP_INDUSTRIES_SEGMET_SUCCESS,
    payload: data
  };
};
const saveIndustrieSegmentFailure = error => {
  return {
    type: actionTypes.MAP_INDUSTRIES_SEGMET_FAILURE,
    payload: error
  };
};

const saveIndustriesRequest = () => {
  return {
    type: actionTypes.SAVE_INDUSTRIES_REQUEST
  };
};

const saveIndustriesSuccess = data => {
  return {
    type: actionTypes.SAVE_INDUSTRIES_SUCCESS,
    payload: data
  };
};

const saveIndustriesFailure = error => {
  return {
    type: actionTypes.SAVE_INDUSTRIES_FAILURE,
    payload: error
  };
};

const deleteIndustriesRequest = () => {
  return {
    type: actionTypes.DELETE_INDUSTRIES_REQUEST
  };
};

const deleteIndustriesSuccess = (data, class_id) => {
  return {
    type: actionTypes.DELETE_INDUSTRIES_SUCCESS,
    payload: data,
    class_id: class_id
  };
};

const deleteIndustriesFailure = error => {
  return {
    type: actionTypes.DELETE_INDUSTRIES_FAILURE,
    payload: error
  };
};

const getIndustriesRequest = () => {
  return {
    type: actionTypes.GET_INDUSTRIES_REQUEST
  };
};

const getIndustriesSuccess = data => {
  return {
    type: actionTypes.GET_INDUSTRIES_SUCCESS,
    payload: data
  };
};

const getIndustriesFailure = error => {
  return {
    type: actionTypes.GET_INDUSTRIES_FAILURE,
    payload: error
  };
};
