import React, { Component } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";
function dateFormatter(params) {
  if (params) {
    return moment(params).format("MM/DD/YYYY");
  } else {
    return "";
  }
}
class AddressActionButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  invokeParentMethod() {
    const { data } = this.props;

    return (
      <div
        style={{
          lineHeight: "60px"
        }}
      >
        {
          <span
            onClick={() => this.handleEdit(this.props.rowIndex, 1)}
            title="Edit"
          >
            <Edit className="grid_action_btn" />
          </span>
        }
        {
          <span
            onClick={() => this.handleDelete(this.props.rowIndex, 1)}
            title="Edit"
          >
            <DeleteIcon className="grid_action_btn" />
          </span>
        }
      </div>
    );
  }

  handleEdit(index) {
    this.props.context.componentParent.handelEdit(index);
  }

  handleDelete(index) {
    this.props.context.componentParent.handelDelete(index);
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default AddressActionButton;
