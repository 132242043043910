import React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import { rolesNames } from "../../../constants";
// core components
// import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from "@material-ui/lab/Autocomplete";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TextField from "@material-ui/core/TextField";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import InputLabel from "@material-ui/core/InputLabel";
import ImageUpload from "../../components/ImageUpload.jsx";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineRounded from "@material-ui/icons/HelpOutlineRounded";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
class ProductInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductTypeData: [],
      data: {},
      categorylist: [],
      product_template: [],
      brandlist: [],
      imageObj: {
        id: "",
        image: null,
        imagePreviewUrl: null
      },
      isBrandSelected: false,
      isBrandSet: false,
      setDescription: true,
      error: null,
      segmentlist: [],
      classList: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.ProductTemplateById &&
        !_.isEqual(
          this.props.ProductTemplateById,
          nextProps.ProductTemplateById
        )
      ) {
      }
      if (nextProps.data) {
        let data = nextProps.data;
        if (!nextProps.editState && data.images.length == 0) {
          data.images.push(this.state.imageObj);
        }

        if (nextProps.valueSetCheck && this.state.setDescription) {
          const content = htmlToDraft(data.description);
          const contentState = ContentState.createFromBlockArray(
            content.contentBlocks,
            content.entityMap
          );
          const editorState = EditorState.createWithContent(contentState);
          this.setState(
            {
              editorState: editorState,
              data: data,
              setDescription: false,
              isBrandSet: true
            },
            () => {
              if (
                rolesNames.RoleMaster.ADMIN &&
                data.brand &&
                data.brand.id &&
                this.state.isBrandSet
              ) {
                this.props.actions.getCategoryListAction(data.brand.id);
                this.setState({
                  isBrandSet: false
                });
              }
            }
          );
        } else {
          this.setState(
            {
              data: data
            },
            () => {
              if (
                rolesNames.RoleMaster.ADMIN &&
                data.brand &&
                data.brand.id &&
                this.state.isBrandSet
              ) {
                this.props.actions.getCategoryListAction(data.brand.id);
                this.setState({
                  isBrandSet: false
                });
              }
            }
          );
        }
        if (nextProps.SegmentList) {
          this.setState({
            segmentlist: nextProps.SegmentList
          });
        }
        if (nextProps.ClassList) {
          this.setState({
            classList: nextProps.ClassList
          });
        }
      }

      if (nextProps.productTemplates) {
        this.setState({
          product_template: nextProps.productTemplates
        });
      }

      if (nextProps.Brands) {
        let brandlist = [...nextProps.Brands];
        console.log(brandlist);
        if (nextProps.Role == rolesNames.RoleMaster.BRAND) {
          brandlist = brandlist.filter(x => x.id == nextProps.PartnerId);
        }
        this.setState(
          {
            brandlist: brandlist
          },
          () => {
            if (
              nextProps.Role == rolesNames.RoleMaster.BRAND &&
              this.state.brandlist.length > 0 &&
              !this.state.isBrandSelected
            ) {
              let { brandlist } = this.state;
              let index = brandlist.findIndex(x => x.id == nextProps.PartnerId);
              let brand = [];
              brand.push(brandlist[index]);
              this.props.setProductData(
                "brand",
                index > -1 ? brandlist[index] : {}
              );
              this.setState({
                isBrandSelected: true
              });
            }
          }
        );
      }
      if (nextProps.List && nextProps.List.length) {
        const ProductTypeData = nextProps.List;
        this.setState(() => ({
          ProductTypeData: ProductTypeData
        }));
      }
      if (!_.isEqual(this.props.CategoryList, nextProps.CategoryList)) {
        this.setState({
          categorylist: nextProps.CategoryList
        });
      }

      if (nextProps.isImageDeleted) {
        const { images } = this.state.data;
        let index = images.findIndex(x => x.id == nextProps.selectedImageId);
        images.splice(index, 1);
        this.setState(
          {
            data: {
              ...this.state.data,
              images: images
            }
          },
          () => {
            this.props.actions.denyRemoveImageAction();
          }
        );
      }
    }
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      if (data.images.length == 0) {
        data.images.push(this.state.imageObj);
      }
      const content = htmlToDraft(data.description);
      const contentState = ContentState.createFromBlockArray(
        content.contentBlocks,
        content.entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState: editorState,
        data: data
      });
    }
    this.props.actions.getProductTemplateListAction();
    this.props.actions.getBrand(rolesNames.RoleMaster.BRAND, true);
    this.props.actions.getProductCMSList();
    this.props.actions.getCategoryListAction(this.props.PartnerId);
    // this.props.actions.getSegmentsList(this.props.UserId, null, "9");
  }

  render() {
    const { editorState, segmentlist, classList } = this.state;
    const { classes } = this.props;
    const {
      name,
      mrp,
      images,
      is_consumable,
      brand,
      template,
      producttype,
      categories,
      segments
    } = this.state.data;
    let segmentsList = segmentlist.filter(x =>
      segments.findIndex(y => y.id == x.id) > -1 ? x : ""
    );
    let classesList = classList.filter(x =>
      this.state.data.classes.findIndex(y => y.id == x.id) > -1 ? x : ""
    );
    return (
      <GridContainer>
        <GridItem lg={1} md={1} />
        <GridItem lg={10} md={10}>
          <form>
            <GridContainer className="grid-container-control">
              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "}>
                  Brand
                  <Tooltip
                    className="control-tooltip"
                    title="Please select Brand"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <br />
                <Autocomplete
                  disabled={this.props.edistate || this.props.Role == "BR"}
                  options={this.state.brandlist}
                  value={brand ? brand : {}}
                  getOptionLabel={option => (option.name ? option.name : "")}
                  filterSelectedOptions
                  name="brand"
                  onChange={(event, value) =>
                    this.handleBrandChange("brand", value)
                  }
                  classes={{
                    root: "custom-autocomplete",
                    inputRoot: "custom-autocomplete-input-root",
                    tag: "custom-autocomplete-tag"
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Please select Brand"
                    />
                  )}
                />
              </GridItem>
              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "}>
                  Template
                  <Tooltip
                    className="control-tooltip"
                    title="Please select Brand"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <br />
                <Autocomplete
                  disabled={this.props.editState}
                  options={this.state.product_template}
                  value={template ? template : []}
                  getOptionLabel={option => (option.name ? option.name : "")}
                  filterSelectedOptions
                  name="template"
                  onChange={(event, value) =>
                    this.handleBrandChange("template", value)
                  }
                  classes={{
                    root: "custom-autocomplete",
                    inputRoot: "custom-autocomplete-input-root",
                    tag: "custom-autocomplete-tag"
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Please select Template"
                    />
                  )}
                />
              </GridItem>

              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "}>
                  Segment
                  <Tooltip
                    className="control-tooltip"
                    title="Please select or Add new Segment"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <br />
                <Autocomplete
                  multiple
                  options={segmentlist}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  value={
                    segmentsList && segmentsList.length > 0 ? segmentsList : []
                  }
                  onChange={(event, value) =>
                    this.handleBrandChange("segments", value)
                  }
                  classes={{
                    root: "custom-autocomplete",
                    inputRoot: "custom-autocomplete-input-root",
                    tag: "custom-autocomplete-tag"
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Segment"
                    />
                  )}
                />
              </GridItem>

              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "}>
                  Classes
                  <Tooltip
                    className="control-tooltip"
                    title="Please select classes"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <br />
                <Autocomplete
                  multiple
                  options={classList}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  value={
                    classesList && classesList.length > 0 ? classesList : []
                  }
                  onChange={(event, value) =>
                    this.handleBrandChange("classes", value)
                  }
                  classes={{
                    root: "custom-autocomplete",
                    inputRoot: "custom-autocomplete-input-root",
                    tag: "custom-autocomplete-tag"
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Classes"
                    />
                  )}
                />
              </GridItem>

              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "}>
                  Name
                  <Tooltip
                    className="control-tooltip"
                    title="Please select Name"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <TextField
                  value={name ? name : ""}
                  variant="outlined"
                  name="name"
                  fullWidth
                  placeholder="Name"
                  classes={{
                    root: "attribute-txtbox"
                  }}
                  error={this.state.error}
                  helperText={this.state.error ? this.state.error : ""}
                  onChange={this.handleTextChange()}
                />
              </GridItem>
              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "}>
                  SRP
                  <Tooltip
                    className="control-tooltip"
                    title="Please select SRP"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <TextField
                  value={mrp ? mrp : ""}
                  variant="outlined"
                  fullWidth
                  placeholder="SRP"
                  name="mrp"
                  classes={{
                    root: "attribute-txtbox"
                  }}
                  onChange={this.handleTextChange()}
                />
              </GridItem>
              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "}>
                  Category
                </InputLabel>
                <Autocomplete
                  multiple
                  options={
                    this.state.categorylist ? this.state.categorylist : []
                  }
                  value={categories ? categories : []}
                  getOptionLabel={option => (option.name ? option.name : "")}
                  filterSelectedOptions
                  name="categories"
                  onChange={(event, value) =>
                    this.handleBrandChange("categories", value)
                  }
                  classes={{
                    root: "custom-autocomplete",
                    inputRoot: "custom-autocomplete-input-root",
                    tag: "custom-autocomplete-tag"
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Choose Category"
                    />
                  )}
                />
                {/* <Select
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={categories ? categories : []}
                                    classes={{
                                        root: 'select-control-root'
                                    }}
                                    onChange={this.handleSelectChange('categories', "")}
                                >
                                    <MenuItem
                                        disabled
                                        classes={{
                                            root: classes.selectMenuItem
                                        }}
                                    >
                                        Choose Category
                                    </MenuItem>
                                    {
                                        _.map(this.state.categorylist, ({ id, name }, index) => {
                                            return (
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                    value={id}
                                                    key={index + '_' + id}
                                                >
                                                    {name}
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select> */}
              </GridItem>
              <GridItem lg={6} md={6} className="griditem-control">
                <Checkbox
                  className="checkbox-control"
                  tabIndex={-1}
                  checked={is_consumable ? true : false}
                  onClick={this.handleToggleStatus()}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                  name="is_consumable"
                />
                <div className={classes.label} style={{ marginTop: "25px" }}>
                  <span>Consumable</span>
                  <Tooltip
                    className="control-tooltip"
                    title="Inventory is not managed for consumable products"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </div>
              </GridItem>

              <GridItem lg={12} md={12} className="griditem-control">
                <InputLabel className={classes.label + " "}>Image</InputLabel>
                <br />
                {_.map(images, ({ id, image }, index) => {
                  return (
                    <ImageUpload
                      key={index}
                      ref={"image_" + index}
                      addButtonProps={{
                        round: true
                      }}
                      changeButtonProps={{
                        color: "rose",
                        round: true
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true
                      }}
                      imgUrl={
                        image instanceof Object
                          ? image instanceof Blob
                            ? image
                            : image.url
                          : image
                      }
                      onComponentCreate={this.setImageUploadState(index)}
                      onimageRemove={() => this.handelImageRemove(index)}
                      onChange={file => this.handelImageChange(file, id)}
                    />
                  );
                })}
              </GridItem>
              <GridItem lg={12} md={12} className="griditem-control">
                <InputLabel className={classes.label + " "}>
                  Description
                  <Tooltip
                    className="control-tooltip"
                    title="Add Description for product"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <br />
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapper"
                  editorClassName="editor"
                  onEditorStateChange={this.onEditorStateChange}
                />
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
        <GridItem lg={1} md={1} />
      </GridContainer>
    );
  }

  getLabel(option) {
    console.log(option);
    return;
  }

  setValue(option, value) {
    if (option.id == value.id) {
      return option;
    }
    return;
  }

  setImageUploadState(index) {
    let ref_key = "image_" + index;
    const { data } = this.state;
    if (this.refs && this.refs[ref_key]) {
      const imageUpload = this.refs[ref_key];
      if (this.props.editState) {
        if (
          data.images[index].image == undefined &&
          data.images[index].image == ""
        ) {
          imageUpload.refs.fileInput.value = null;
          imageUpload.setState({
            file: null,
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
          });
        } else {
          if (imageUpload.refs && imageUpload.refs.fileInput) {
            imageUpload.refs.fileInput.value = "";
            imageUpload.setState({
              file: data.images[index].image
                ? data.images[index].image instanceof Object
                  ? data.images[index].image.secure_url
                  : data.images[index].image
                : null,
              imagePreviewUrl: data.images[index].image
                ? data.images[index].image instanceof Object
                  ? data.images[index].image.secure_url
                  : data.images[index].image
                : this.props.avatar
                ? defaultAvatar
                : defaultImage
            });
          }
        }
      }
    }
  }

  handleChange = (name, value) => {
    this.props.setProductData(name, value.id);
  };

  handleBrandChange = (name, value) => {
    console.log("name", name);
    console.log("value", value);
    let isbrandset = false;
    if (name == "brand") {
      isbrandset = true;
    }
    this.setState(
      {
        isBrandSet: isbrandset
      },
      () => {
        if (name == "template") {
          this.props.actions.getSegmentsList(null, null, value.id);
          this.props.actions.getClassList(
            this.props.PartnerId,
            null,
            null,
            0,
            null,
            value.id
          );
        }
        this.props.setProductData(name, value);
      }
    );
  };
  handleSegmentChange = (name, value) => {
    this.props.setProductData(name, value);
  };

  handleSelectChange = (key, type) => event => {
    let { ProductTypeData } = this.state;
    let currentParent = ProductTypeData.findIndex(
      p => p.id == event.target.value
    );
    this.props.setProductData("producttype", ProductTypeData[currentParent].id);
  };

  handleTextChange = () => event => {
    if (
      event.target.name == "name" &&
      event.target.value &&
      event.target.value.length > 255
    ) {
      this.setState({
        error: "The length of name should be less than 255 character"
      });
      return;
    } else {
      if (
        event.target.name == "name" &&
        event.target.value &&
        event.target.value.length <= 255
      ) {
        this.setState({
          error: null
        });
      }
    }
    this.props.setProductData(event.target.name, event.target.value);
  };

  handleToggleStatus = () => event => {
    let value = event.target.checked;
    this.props.setProductData(event.target.name, value);
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.setProductData("description", value);
  };

  handelImageChange = (file, id) => {
    if (file instanceof Blob) {
      let { imageObj, data } = this.state;
      let clonnedObj = _.clone(imageObj);
      clonnedObj.image = file;
      clonnedObj.imagePreviewUrl = file;
      clonnedObj.file_size = file.size;
      clonnedObj.original_file_name = file.name;
      let imageIndex = data.images.length - 1;
      data.images.splice(imageIndex, 0, clonnedObj);
      this.setState({
        data: data
      });
      // reader.onloadend = () => {

      // };
      // reader.readAsDataURL(file);
    } else {
      let { imageObj, data } = this.state;
      let clonnedObj = _.clone(imageObj);
      clonnedObj.image = file.url;
      clonnedObj.imagePreviewUrl = file.url;
      if (file.is_asset) {
        clonnedObj.asset_id = file.id;
      }
      clonnedObj.file_size = file.file_size;
      clonnedObj.original_file_name = file.original_file_name;
      let imageIndex = data.images.length - 1;
      data.images.splice(imageIndex, 0, clonnedObj);
      this.setState({
        data: data
      });
    }
  };

  handelImageRemove = index => {
    let { images } = this.state.data;
    let selectedImage = images[index];

    if (selectedImage instanceof Object && selectedImage.id !== "") {
      this.props.actions.removeImageAction(selectedImage.id);
    } else {
      images.splice(index, 1);
      this.setState({
        data: {
          ...this.state.data,
          images: images
        }
      });
    }
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  CategoryList: state.categoryReducer.categorydata.data,
  Brands: state.brandState.list,
  PartnerId: state.authState.user.partner_id,
  UserId: state.authState.user.user,
  ProductData: state.productsAssignmentsState.brand_product.data,
  Role: state.authState.user.role,
  productTemplates: state.productsAssignmentsState.product_template.data,
  List: state.productCMSReducer.get.list,
  ListError: state.productCMSReducer.get.error,
  ProductTemplateById: state.brandState.productTemplateById.data,
  isImageDeleted: state.appMenuReducer.removeImage.isDeleted,
  selectedImageId: state.appMenuReducer.removeImage.data,
  SegmentList: state.SegmentsReducer.list,
  ClassList: state.classReducer.list,
  ClassLoading: state.classReducer.loadingData
});

ProductInfo.propTypes = {
  data: PropTypes.object.isRequired,
  editState: PropTypes.bool.isRequired
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductInfo);
