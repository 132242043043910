import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../redux/actions";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _ from "lodash";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";
import ChangePassword from "../../src/views/UserAuth/ChangePassword";
var ps;

class RouteMiddleware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      landingPage: null,
      ChangePassword: {
        open: false,
        userid: null
      },
      isFirstLogin: false
    };
  }
  componentDidMount() { }

  componentWillReceiveProps(nextProps) {
    let landingPage = '/dashboard'
    if (nextProps && nextProps.appMenus && nextProps.appMenus.length > 0) {
      let sortedData = _.orderBy(nextProps.appMenus, 'sort_order', 'asc')

      if (sortedData[0] && sortedData[0].views && sortedData[0].views.length > 0) {
        landingPage = sortedData[0].views[0].path
      } else
        ///this required as above condition is for nesting menus
        landingPage = sortedData[0].path
      this.setState({
        landingPage: landingPage
      })
    }
    // this.props.actions.onNavigateTo(landingPage)
    if (nextProps.User_Id.status === 0 && nextProps.User_Id.Impersonate === false) {

      this.setState({
        ChangePassword: {
          ...this.state.ChangePassword,
          open: true,
          userid: nextProps.User_Id.pk
        }
      });
    } else {
      this.setState(
        {
          ChangePassword: {
            ...this.state.ChangePassword,
            open: false,
            userid: nextProps.User_Id.pk
          },
          isFirstLogin: false
        },
        () => { }
      );
    }
  }

  changePasswordModelClose = () => {
    this.setState({
      ...this.state,
      ChangePassword: {
        ...this.state.ChangePassword,
        open: false
      }
    });
    this.props.actions.signOutUser();
  };

  render() {
    return (
      <div>
        {this.state.ChangePassword.open ? (
          <ChangePassword
            open={this.state.ChangePassword.open}
            userid={this.state.ChangePassword.userid}
            onClose={this.changePasswordModelClose}
            isFirstLogin={this.state.isFirstLogin}
            landingPage={this.state.landingPage}
          />
        ) : (
          this.navigateToLandingPage()
        )}
      </div>
    );
  }

  navigateToLandingPage() {
    const { landingPage } = this.state
    if (landingPage) {
      this.props.actions.onNavigateTo(landingPage)
    }
    else {
      this.props.actions.getUsersMenuListAction(this.props.authUser.pk, this.props.authUser.role);
    }
  }
}

RouteMiddleware.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authUser: state.authState.user,
  authenticated: state.authState.authenticated,
  menuList: state.partnerMenuReducer.get_partner_menu_list,
  appMenus: state.appMenuReducer.menulist,
  User_Id: state.authState.user
});

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});
export default compose(
  withStyles(appStyle),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RouteMiddleware);
