// import axios from 'axios';
import axios from "../utils/axios.jsx";
export const saveProductAttributes = async (Savedata, userId) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post(
      "/api/products/savelocalattribute/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
