import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import AssetManagementComponent from "../components/assetManagementComponent.jsx";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// @material-ui/core components/Dialog

import Slide from "@material-ui/core/Slide";

import "../../assets/scss/wrapper-bootstrap.min.scss";
import "../../assets/scss/wrapper-component-settings.scss";
import "../../assets/scss/formio.full.min.css";
import "../../assets/scss/formio-material-support.css";
import { Form } from "react-formio";
import { validationUtils } from "../../utils";
import config from "../../constants/config";
import Alert from "@material-ui/lab/Alert";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class CMSpages extends React.Component {
  constructor(props) {
    super(props);
    this.onSaveChanges = this.onSaveChanges.bind(this);
    this.onChangeFormValue = this.onChangeFormValue.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getSelectedImage = this.getSelectedImage.bind(this);

    this.state = {
      files: [],
      openAssetModal: false,
      isAddNew: false,
      CMSForm: {},
      form: {},
      menuId: "",
      formValue: null,
      assetImageUpload: false,
      handleImageUpload: false,
      menuname: "",
      add_CMS_data: {
        id: "",
        retaileId: "",
        display_name: "",
        menu_master_id: "",
        value: "",
        name: "",
        menu_display_name: "",
        is_active: true,
        id: ""
      },
      required: {
        display_name: "",
        name: ""
      },
      asset_image_data: {
        name: "",
        originalName: "",
        size: "",
        url: "",
        storage: "",
        type: ""
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    let a = config;

    if (nextProps.menuList) {
      if (
        nextProps.menuList &&
        nextProps.menuList.list &&
        nextProps.menuList.list[0] &&
        nextProps.menuList
      ) {
        this.renderForm(nextProps.menuList);
      }
    }

    if (
      nextProps.location.search &&
      nextProps.location.search != this.props.location.search
    ) {
      this.getCMSDataQuueeryString();
    }
    if (
      nextProps.menuData &&
      !_.isEqual(nextProps.menuData, this.props.menuData) &&
      nextProps.isSaved === false
    ) {
      this.setState(
        {
          openAssetModal: false,
          isAddNew: false,
          menuId: "",
          formValue: null,
          assetImageUpload: false,
          handleImageUpload: false,
          menuname: "",
          add_CMS_data: {
            id: "",
            retaileId: "",
            display_name: "",
            menu_master_id: "",
            value: "",
            menu_display_name: "",
            is_active: true,
            id: ""
          },
          required: {
            display_name: "",
            name: ""
          },
          asset_image_data: {
            name: "",
            originalName: "",
            size: "",
            url: "",
            storage: "",
            type: ""
          }
        },
        function () {
          this.setFormValue(nextProps.menuData);
        }
      );
    }
    if (
      nextProps.menuData &&
      !_.isEqual(nextProps.menuData, this.props.menuData) &&
      nextProps.isSaved === true
    ) {
      this.getCMSDataQuueeryString();
    }

    //ifalert("ttt")
  }

  setFormValue(menuData) {
    if (this.props.isSaved) {
      this.setState({
        add_CMS_data: {
          id: menuData.id
        }
      });
    } else {
      this.setState(
        {
          ...this.state,
          add_CMS_data: {
            ...this.state.add_CMS_data,
            id: menuData.id,
            display_name: menuData.display_name,
            is_active: menuData.is_active
          },
          formValue: { data: menuData.value }
        },
        () => {
          //  alert(menuData.value)
          console.log(this.state.formValue);
        }
      );
    }
  }

  getCMSDataQuueeryString() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let entityId = params.get("id");
    this.props.actions.getCMSData(entityId, this.props.authUser.partner_id);
  }

  renderForm(formdata) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let entityId = params.get("id");
    let id = "";
    let displayname = "";
    if (this.props.menuData) {
      id = this.props.menuData.id;
      if (id) {
        displayname = this.props.menuData.display_name;
      } else {
        displayname = "";
      }
    }
    for (var i = 0; i < formdata.list.length; i++) {
      if (entityId == formdata.list[i].id) {
        if (formdata.list[i]) {
          for (var j in formdata.list[i].form) {
            if (formdata.list[i].form[j].components)
              for (var cmp in formdata.list[i].form[j].components) {
                if (formdata.list[i].form[j].components[cmp].data) {
                  formdata.list[i].form[j].components[cmp].data.url =
                    config.env.API_URL +
                    "api/retailer/menu/list/?retailer=" +
                    this.props.authUser.partner_id +
                    "&type=CMS";
                } else {
                  for (var cmp2 in formdata.list[i].form[j].components[cmp]
                    .components) {
                    if (
                      formdata.list[i].form[j].components[cmp].components[cmp2]
                        .data
                    ) {
                      formdata.list[i].form[j].components[cmp].components[
                        cmp2
                      ].data.url =
                        config.env.API_URL +
                        "api/retailer/menu/list/?retailer=" +
                        this.props.authUser.partner_id +
                        "&type=CMS";
                    }
                  }
                }
              }
          }
        }

        this.setState({
          form: { display: "form", components: formdata.list[i].form },
          menuId: entityId,
          add_CMS_data: {
            ...this.state.add_CMS_data,
            name: formdata.list[i].name,
            menuname: formdata.list[i].name
          }
        });
        return;
      }
    }
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    // if (!this.state.add_CMS_data.hasOwnProperty("display_name") || (this.state.add_CMS_data.display_name.trim() === "" || reqData["display_name"] === "error")) {
    //     reqData["display_name"] = "error";
    //     isValid = false;
    // }

    this.setState({ required: reqData });
    return isValid;
  }

  onSaveChanges(data) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let entityId = params.get("id");
    let _name = params.get("name");
    const { menuId, menuname } = this.state;
    const {
      display_name,
      is_active,
      value,
      name,
      id
    } = this.state.add_CMS_data;
    let cms_data = value;
    if (cms_data.upload_image) {
      delete cms_data.upload_image;
    }

    this.setState(
      {
        add_CMS_data: {
          retaileId: this.props.authUser.partner_id,
          menu_master_id: menuId,
          value: cms_data,
          display_name: display_name,
          is_active: is_active,
          name: name,
          id: id
        },
        formValue: { data: cms_data }
      },
      () => {
        let { value } = this.state.add_CMS_data;
        let files = this.selectFilesFromComponent(value);
        if (this.validateIsRequired()) {
          if (!name) {
            this.setState(
              {
                ...this.state,
                add_CMS_data: {
                  ...this.state.add_CMS_data,
                  menu_master_id: entityId,
                  name: _name
                }
              },
              () => {
                console.log(this.state.add_CMS_data);
                this.props.actions.createCMSData(
                  this.props.UserId,
                  this.state.add_CMS_data,
                  this.state.files
                );
              }
            );
          } else {
            console.log(this.state.add_CMS_data);
            this.props.actions.createCMSData(
              this.props.UserId,
              this.state.add_CMS_data,
              this.state.files
            );
          }
        }
      }
    );
  }

  selectFilesFromComponent(values) {
    if (values instanceof Array) {
      values.forEach(x => {
        this.selectFilesFromComponent(x);
      });
    } else if (values instanceof Object) {
      let keys = Object.keys(values);
      keys.forEach(x => {
        if (~x.indexOf("_image")) {
          this.selectFilesFromComponent(values[x]);
        } else {
          if (x == "url" && typeof values[x] == "string") {
            let file = this.b64toBlob(values[x]);
            if (file && file instanceof Blob) {
              file.name = values["name"];
              let { files } = this.state;
              files.push(file);
              this.setState({
                files: files
              });
            }
            values[x] = file;
          }
          this.selectFilesFromComponent(values[x]);
        }
      });
    }
    return values;
  }

  componentDidMount() {
    if (this.props.authUser) {
      this.getCMSDataQuueeryString();
      // this.props.actions.partenrMenuList(this.props.authUser.partner_id);
    }
  }

  b64toBlob(dataURI) {
    try {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/jpeg" });
    } catch (err) {
      return dataURI;
    }
  }

  onChangeFormValue(data) {
    // let { files } = this.state
    // if (data && data.changed && data.changed.component && data.changed.component.type && data.changed.component.type == 'file') {
    //     let value = data.changed.value
    //     value.forEach(x=>{
    //         if(x.storage == 'base64') {
    //             let fileObj= this.b64toBlob(x.url)
    //             fileObj.name = x.name
    //             files.push(fileObj)
    //             this.setState({
    //                 files: files
    //             })
    //             x.url = fileObj
    //         }
    //     })
    //     data.changed.value = value
    // }
    let assetImageUpload = this.state.assetImageUpload;
    let handleImageUpload = this.state.handleImageUpload;
    if (this.state.assetImageUpload) {
      this.setState({
        ...this.state,
        add_CMS_data: {
          ...this.state.add_CMS_data,
          value: data.data
        },
        handleImageUpload: true,
        assetImageUpload: false
      });
    }
    if (handleImageUpload && assetImageUpload === false) {
      let bannerCardLength =
        data && data.data && data.data.banners && data.data.banners.length
          ? data.data.banners.length
          : 0;
      if (bannerCardLength > 0) {
        let imagedata = data.data;
        imagedata.banners[bannerCardLength - 1].banner_image.push(
          this.state.asset_image_data
        );
        data.data = imagedata;
        this.setState({
          ...this.state,
          add_CMS_data: {
            ...this.state.add_CMS_data,
            value: data.data
          },
          handleImageUpload: false
        });
      }
      let cardlength = data.data.cards.length;
      if (cardlength > 0) {
        let imagedata = data.data;
        imagedata.cards[cardlength - 1].card_image.push(
          this.state.asset_image_data
        );
        data.data = imagedata;
        this.setState({
          ...this.state,
          add_CMS_data: {
            ...this.state.add_CMS_data,
            value: data.data
          },
          handleImageUpload: false
        });
      }
      // let imagedata = data.data
      // imagedata.cards[cardlength - 1].card_image.push(this.state.asset_image_data)
      // data.data = imagedata
      // this.setState({
      //     ...this.state,
      //     add_CMS_data: {
      //         ...this.state.add_CMS_data,
      //         value: data.data
      //     },
      //     handleImageUpload: false
      // })
    } else {
      this.setState({
        ...this.state,
        add_CMS_data: {
          ...this.state.add_CMS_data,
          value: data.data
        }
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { form, required } = this.state;
    const {
      display_name,
      is_active,
      name,
      key,
      description
    } = this.state.add_CMS_data;

    return (
      <GridContainer>
        {this.state.openAssetModal ? (
          <AssetManagementComponent
            open={this.state.openAssetModal}
            close={this.closeModal}
            onSelection={this.getSelectedImage}
          />
        ) : (
          ""
        )}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Content Management
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <CustomInput
                  success={required.name === "success"}
                  error={required.name === "error"}
                  labelText=""
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: name,
                    onChange: this.handleTextChange("name", "")
                  }}
                />

                <div className="bootstrap-wrapper">
                  <Form
                    src={form}
                    onChange={this.onChangeFormValue}
                    submission={this.state.formValue}
                    onCustomEvent={customEvent => {
                      this.onCustomEvent(customEvent);
                    }}
                  />
                </div>
                <br />
                <Button color="info" onClick={this.onSaveChanges}>
                  {" "}
                  Save Changes
                </Button>
                <br />
                {this.props.Createdata && (
                  // <FormLabel className={classes.labelLeftHorizontal}>
                  <Alert severity="success">{this.props.Createdata}</Alert>
                )
                  // </FormLabel>
                }
                {this.props.CreateLoading && (
                  <span>
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />

                    <Loader
                      loading={this.props.CreateLoading}
                      text="Saving.."
                    />
                  </span>
                )}
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  onCustomEvent = e => {
    console.log(e);
    this.setState({
      openAssetModal: true
    });
  };
  closeModal() {
    this.setState({
      openAssetModal: false
    });
  }

  getSelectedImage(data) {
    let { value } = this.state.add_CMS_data;
    let asset_object = {};
    asset_object.storage = "asset";
    asset_object.type = "image/jpg";
    asset_object.size = data.file_size;
    asset_object.name = data.name;
    asset_object.url = data.url;
    asset_object.asset_id = data.id;
    asset_object.originalName = data.original_file_name;
    value["upload_image"] = asset_object;
    let asset_image_data = {};
    // asset_image_data.assets = true
    asset_image_data.name = data.name;
    asset_image_data.originalName = data.original_file_name;
    asset_image_data.size = data.file_size;
    asset_image_data.url = data.url;
    asset_image_data.asset_id = data.id;
    asset_image_data.storage = "asset";
    asset_image_data.type = "image/jpg";
    // value.cards.push(asset_object)
    this.setState(
      {
        ...this.state,
        add_CMS_data: {
          ...this.state.add_CMS_data,
          value: value
        },
        assetImageUpload: true,
        asset_image_data: asset_image_data
      },
      () => {
        this.setFormValue(this.state.add_CMS_data);
        this.closeModal();
      }
    );
  }

  handleToggleStatus = curObj => event => {
    this.setState({
      add_CMS_data: {
        ...this.state.add_CMS_data,
        is_active: event.target.checked
      }
    });
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      add_CMS_data: {
        ...this.state.add_CMS_data,
        [key]: event.target.value
      }
    });
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  menuList: state.partnerMenuReducer.get_partner_menu_list,
  menuData: state.CMSContentReducer.get_CMS_Content.list,
  Loading: state.CMSContentReducer.get_CMS_Content.loading,
  error: state.CMSContentReducer.get_CMS_Content.error,
  Createdata: state.CMSContentReducer.create_CMS_Content.successmsg,
  CreateLoading: state.CMSContentReducer.create_CMS_Content.loading,
  Createerror: state.CMSContentReducer.create_CMS_Content.error,
  UserId: state.authState.user.pk,
  authUser: state.authState.user,
  isSaved: state.CMSContentReducer.create_CMS_Content.isSaved
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.RETAILER ||
  authUser.role === rolesNames.RoleMaster.BRAND ||
  authUser.role === rolesNames.RoleMaster.WHOLESALER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CMSpages);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
