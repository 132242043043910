import * as SegmentService from "../../services/SegmentService";
import * as actionTypes from "./actionTypes";

export const getSegmentsList = (
  id,
  industryId,
  page = 0,
  searchtext = null
) => async dispatch => {
  dispatch(getSegmentRequest());
  try {
    const success = await SegmentService.getSegmentsList(
      id,
      industryId,
      null,
      page,
      searchtext
    );
    dispatch(getSegmentSuccess(success.data));
  } catch (error) {
    dispatch(getSegmentFailure(error.message));
  }
};

export const saveSegments = (id, data, partnerId) => async dispatch => {
  dispatch(saveSegmentRequest());
  try {
    const success = await SegmentService.saveSegments(id, data, partnerId);
    dispatch(saveSegmentSuccess(success.data));
  } catch (error) {
    dispatch(saveSegmentFailure(error.message));
  }
};

export const deleteSegments = (id, segmentId) => async dispatch => {
  dispatch(deleteSegmentRequest());
  try {
    const success = await SegmentService.deleteSegments(id, segmentId);
    dispatch(deleteSegmentSuccess(success.data, segmentId));
  } catch (error) {
    dispatch(deleteSegmentFailure(error.message));
  }
};

export const mapClassSegment = (id, mapData) => async dispatch => {
  dispatch(saveClassSegmentRequest());
  try {
    const success = await SegmentService.mapClassSegment(id, mapData);
    dispatch(saveSegmentSuccess(success.data));
  } catch (error) {
    dispatch(saveClassSegmentFailure(error.message));
  }
};

export const mapServiceSegment = (id, mapData) => async dispatch => {
  dispatch(saveServiceSegmentRequest());
  try {
    const success = await SegmentService.mapServiceSegment(id, mapData);
    dispatch(saveServiceSegmentSuccess(success.data));
  } catch (error) {
    dispatch(saveServiceSegmentFailure(error.message));
  }
};

export const afertMapClassSegment = () => async dispatch => {
  dispatch(afterSaveClassClassSegmentRequest());
};

const afterSaveClassClassSegmentRequest = () => {
  return {
    type: actionTypes.AFTER_MAP_CLASS_SEGMENT_SUCCESS
  };
};

const saveSegmentRequest = () => {
  return {
    type: actionTypes.SAVE_SEGMENT_REQUEST
  };
};

const saveSegmentSuccess = data => {
  return {
    type: actionTypes.SAVE_SEGMENT_SUCCESS,
    payload: data
  };
};

const saveSegmentFailure = error => {
  return {
    type: actionTypes.SAVE_SEGMENT_FAILURE,
    payload: error
  };
};

const deleteSegmentRequest = () => {
  return {
    type: actionTypes.DELETE_SEGMENT_REQUEST
  };
};

const deleteSegmentSuccess = (data, Id) => {
  return {
    type: actionTypes.DELETE_SEGMENT_SUCCESS,
    payload: data,
    segmentId: Id
  };
};

const deleteSegmentFailure = error => {
  return {
    type: actionTypes.DELETE_SEGMENT_FAILURE,
    payload: error
  };
};

const getSegmentRequest = () => {
  return {
    type: actionTypes.GET_SEGMENT_REQUEST
  };
};

const getSegmentSuccess = data => {
  return {
    type: actionTypes.GET_SEGMENT_SUCCESS,
    payload: data
  };
};

const getSegmentFailure = error => {
  return {
    type: actionTypes.GET_SEGMENT_FAILURE,
    payload: error
  };
};

const saveClassSegmentRequest = () => {
  return {
    type: actionTypes.MAP_CLASS_SEGMENT_REQUEST
  };
};

const saveClassSegmentSuccess = data => {
  return {
    type: actionTypes.MAP_CLASS_SEGMENT_SUCCESS,
    payload: data
  };
};

const saveClassSegmentFailure = error => {
  return {
    type: actionTypes.MAP_CLASS_SEGMENT_FAILURE,
    payload: error
  };
};

const saveServiceSegmentRequest = () => {
  return {
    type: actionTypes.MAP_SERVICE_SEGMENT_REQUEST
  };
};

const saveServiceSegmentSuccess = data => {
  return {
    type: actionTypes.MAP_SERVICE_SEGMENT_SUCCESS,
    payload: data
  };
};

const saveServiceSegmentFailure = error => {
  return {
    type: actionTypes.MAP_SERVICE_SEGMENT_FAILURE,
    payload: error
  };
};

export const getPartnersIndustrySegment = (partnerid) => async dispatch => {
  dispatch(getPartnersIndustrySegmentRequest());
  try {
    const success = await SegmentService.getPartnersIndustrySegment(partnerid);
    dispatch(getPartnersIndustrySegmentSuccess(success.data));
  } catch (error) {
    dispatch(getPartnersIndustrySegmentFailure(error.message));
  }
};

const getPartnersIndustrySegmentRequest = () => {
  return {
    type: actionTypes.GET_PARTNERS_INDUSTRY_SEGMENT_REQUEST
  };
};

const getPartnersIndustrySegmentSuccess = data => {
  return {
    type: actionTypes.GET_PARTNERS_INDUSTRY_SEGMENT_SUCCESS,
    payload: data
  };
};

const getPartnersIndustrySegmentFailure = error => {
  return {
    type: actionTypes.GET_PARTNERS_INDUSTRY_SEGMENT_FAILURE,
    payload: error
  };
};
