import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import cx from "classnames";
import { LoadingOverlay, Loader } from "react-overlay-loader";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FormLabel from "@material-ui/core/FormLabel";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedFormStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import ProductInfo from "./components/ProductInfo";
import ProductVariant from "./components/ProductVariant";
import ProductAttributes from "./components/ProductAttributes";
import Button from "components/CustomButtons/Button.jsx";

const Style = {
  pagination: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#666666",
    float: "right"
  },
  questionHead: {
    fontWeight: "500"
  }
};

const initialState = {
  id: "",
  name: "",
  description: "",
  disclaimer: "",
  template: {},
  is_consumable: false,
  is_template: false,
  images: [],
  attributes: [],
  segments: [],
  classes: [],
  categories: [],
  offers: [],
  brand: {},
  mrp: 0,
  producttype: "",
  variants: [],
  variantValues: [],
  msrp: 0,
  discount: "",
  editorState: ""
};

var wizardHeaders = ["Basic Info", "Variant Details"];

class ProductWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      header: ["Basic Info", "Variant Details"],
      addProduct: {
        ...initialState,
        partner_id: this.props.PartnerId
      },
      variantSearch: '',
      selectedTab: 0,
      setEditData: true,
      pagination: {
        total_page: 1,
        current_page: 1
      },
      isEditMode: false,
      isCloneMode: false,
      isValueSetCheck: false,
      editDataSet: false,
      clickaction: 0,
      isSaveNext: false,
      isSaveClose: false
    };
    this.clickPreviousBtn = this.clickPreviousBtn.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.setProductData = this.setProductData.bind(this);
    this.setIntialValue = this.setIntialValue.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.savedTemplate &&
        !_.isEqual(nextProps.savedTemplate, this.props.savedTemplate)
      ) {
        let data = this.modifyTemplateData(nextProps.savedTemplate);
        if (this.state.clickaction == 1) {
          this.clickNextBtn(data);
        }
        if (this.state.clickaction == 2) {
          this.props.close();
        }
      }

      let error = "";
      if (nextProps.error) {
        error = nextProps.error;
      }
      this.setState({
        error: error
      });

      if (
        nextProps.ProductTemplateById &&
        !_.isEqual(
          this.props.ProductTemplateById,
          nextProps.ProductTemplateById
        )
      ) {
        let { addProduct } = this.state;
        let header = _.clone(wizardHeaders);
        let product = nextProps.ProductTemplateById;
        let wizardheader = product.attribute_groups;
        for (let tempid in wizardheader) {
          let headername = wizardheader[tempid].name;
          let index = header.indexOf(headername);
          if (index == -1) {
            header.push(headername);
          }
        }
        addProduct.name = product.name;
        addProduct.description = product.description;
        addProduct.is_consumable = product.is_consumable;
        addProduct.attribute_groups = product.attribute_groups;
        addProduct.classes = product.classes;
        addProduct.segments = product.segments;
        addProduct.variants = product.variants;
        addProduct.variantValues = product.variantValues;
        addProduct.producttype = product.producttype
          ? product.producttype.id
          : 0;

        this.setState({
          ...this.state,
          header: header,
          addProduct: addProduct,
          isValueSetCheck: true,
          pagination: {
            ...this.state.pagination,
            total_page: header.length
          }
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.data) {
      let { header, isValueSetCheck } = this.state;
      let { data } = this.props;

      if (this.props.isEdit) {
        const { attribute_groups } = data.template;
        for (let tempid in attribute_groups) {
          let headername = attribute_groups[tempid].name;
          let index = header.indexOf(headername);
          if (index == -1) {
            header.push(headername);
          }
        }
        isValueSetCheck = true;
      }

      this.setState({
        addProduct: this.props.data,
        isEditMode: this.props.isEdit,
        isCloneMode: this.props.isClone,
        header: header,
        isValueSetCheck: isValueSetCheck
      }, () => {
        this.setPaginationValues();
      });
      if (this.props.isClone) {
        this.setState({
          addProduct: {
            ...this.props.data,
            id: ""
          }
        })
      }

      if (this.props.data.template && this.props.data.template.id) {
        this.props.actions.getSegmentsList(null, null, this.props.data.template.id);
        this.props.actions.getClassList(this.props.PartnerId, null, null, 0, null, this.props.data.template.id);
      }
    }

    this.props.actions.getVariants(null, this.state.variantSearch, true);
  }

  modifyTemplateData(product) {
    let data = {};
    data["id"] = product.id;
    data["name"] = product.name;
    data["description"] = product.description;
    data["mrp"] = product.msrp ? product.msrp : 0;
    data["brand"] = product.brand;
    if (product.is_consumable == 1) {
      product.is_consumable = true;
    } else {
      product.is_consumable = false;
    }
    data["is_consumable"] = product.is_consumable;
    let images = [];
    let imageObj = {
      id: "",
      image: "",
      imagePreviewUrl: ""
    };
    for (let i in product.images) {
      let new_image = {
        ...imageObj
      };
      new_image.id = product.images[i].id;
      new_image.image = product.images[i].url;
      new_image.imagePreviewUrl = product.images[i].url;
      images.push(new_image);
    }
    images.push(imageObj);
    data["images"] = images;
    data["partner_id"] = this.props.partnerId;
    data["offers"] = product.offers;
    data["categories"] = product.categories;
    data["classes"] = product.classes;
    data["attributes"] = product.attributes;
    data["is_template"] = product.is_template;
    data["template"] = product.template;
    data["variants"] = product.variants;
    data["variantValues"] = product.variantValues;
    data["producttype"] = product.producttype ? product.producttype.id : "";
    data["attribute_groups"] = this.state.isEditMode
      ? product.attribute_groups
      : product.template.attribute_groups;
    data["segments"] = product.segments;
    return data;
  }

  setPaginationValues(selectedTab = 0) {
    const { header } = this.state;
    let totalpagecount = header.length;
    this.setState({
      pagination: {
        ...this.state.pagination,
        total_page: totalpagecount,
        current_page: 1
      },
      selectedTab: selectedTab
    });
  }

  render() {
    const { current_page, total_page } = this.state.pagination;
    const { classes } = this.props;
    const { header } = this.state;
    return (
      <Grid>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <LoadingOverlay>
              <Card>
                <CardHeader>
                  <div
                    style={{
                      color: "black",
                      fontWeight: "500",
                      float: "left",
                      fontSize: "16px",
                      lineHeight: "18px"
                    }}
                  >
                    {this.state.isCloneMode
                      ? `Clone: ${this.state &&
                      this.state.addProduct &&
                      this.state.addProduct.name}` :
                      this.state.isEditMode
                        ? `Edit: ${this.state &&
                        this.state.addProduct &&
                        this.state.addProduct.name}`
                        : "New Product"}
                  </div>
                  <div style={Style.pagination}>
                    Page: {current_page}/{total_page}
                  </div>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                          {_.map(header, (model, index) => (
                            <GridItem
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              key={index}
                              style={{
                                textAlign: "center"
                              }}
                            >
                              <div
                                value={index}
                                onClick={() => this.handleChange(index)}
                                className={cx("wizard-tab", {
                                  "wizard-selected-tab":
                                    this.state.selectedTab == index
                                })}
                              >
                                {model}
                              </div>
                            </GridItem>
                          ))}
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Divider variant="middle" />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        {this.showSelectedTabContent()}
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                  {this.state.error && this.state.error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}
                  <div
                    style={{
                      float: "right"
                    }}
                  >
                    {current_page > 1 ? (
                      <Button
                        className="wizard-btn"
                        onClick={() => this.clickPreviousBtn()}
                      >
                        Previous
                      </Button>
                    ) : (
                      ""
                    )}
                    {current_page < total_page ? (
                      <Button
                        className="wizard-btn"
                        onClick={() => this.handleSaveNext()}
                      >
                        {this.state.isCloneMode
                          ? "Save & Next"
                          :
                          this.state.isEditMode
                            ? "Update & Next"
                            : "Save & Next"}
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      className="wizard-btn"
                      onClick={() => this.handleSaveClose()}
                    >
                      {this.state.isCloneMode
                        ? "Save & Close"
                        :
                        this.state.isEditMode
                          ? "Update & Close"
                          : "Save & Close"}
                    </Button>
                    <Button
                      className="wizard-btn"
                      onClick={() => this.closeWizard()}
                    >
                      Close
                    </Button>
                  </div>
                </CardBody>
              </Card>
              {this.props.Loading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-12px"
                  }}
                />
              )}
              <Loader loading={this.props.isSaving} text="Saving..." />
            </LoadingOverlay>
          </GridItem>
        </GridContainer>
      </Grid>
    );
  }

  onVariantGroupChange = (values) => {
    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variants: values
      }
    });
  }

  handleAddVariantRow = () => {
    let variantValues = this.state.addProduct.variantValues;

    if (!variantValues) {
      variantValues = new Array();
    }

    variantValues.push({
      variant_id: '',
      value_id: ''
    });

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: variantValues
      }
    });
  };

  handleVariantRemove = (index) => {
    let variantValues = this.state.addProduct.variantValues;
    variantValues.splice(index, 1);

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: variantValues
      }
    });
  }

  handleVariantChange = (index, value) => {
    if (!this.state.addProduct || !this.state.addProduct.variantValues) return;

    let existingValues = this.state.addProduct.variantValues;
    existingValues[index]["variant_id"] = value

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: existingValues
      }
    })
  }

  handleVariantValueChange = (index, value) => {
    if (!this.state.addProduct || !this.state.addProduct.variantValues) return;

    let existingValues = this.state.addProduct.variantValues;
    existingValues[index]["value_id"] = value

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: existingValues
      }
    })
  }

  showSelectedTabContent() {
    const { selectedTab, addProduct, header } = this.state;
    if (selectedTab === 0) {
      return (
        <div>
          <ProductInfo
            data={addProduct}
            editState={this.state.isEditMode}
            setProductData={this.setProductData}
            setIntialValue={this.setIntialValue}
            valueSetCheck={this.state.isValueSetCheck}
          />
        </div>
      );
    }
    else if (selectedTab === 1) {
      return (
        <ProductVariant
          variantMaster={this.props.variant_list}
          data={addProduct}
          partner_id={addProduct && addProduct.brand ? addProduct.brand.id : ''}
          onVariantGroupChange={this.onVariantGroupChange}
          handleAddVariantRow={this.handleAddVariantRow}
          handleVariantRemove={this.handleVariantRemove}
          handleVariantChange={this.handleVariantChange}
          handleVariantValueChange={this.handleVariantValueChange}
        />
      )
    }
    else {
      let selectedTabName = header[selectedTab];
      return (
        <div>
          <ProductAttributes
            data={addProduct}
            editState={this.state.isEditMode}
            setProductData={this.setProductData}
            setIntialValue={this.setIntialValue}
            valueSetCheck={this.state.isValueSetCheck}
            selectedHeader={selectedTabName}
          />
        </div>
      );
    }
  }

  setProductData(key, value) {
    let header = _.clone(wizardHeaders);
    let { addProduct } = this.state;
    if (key == "template") {
      if (value !== null) {
        this.setState({
          addProduct: {
            ...this.state.addProduct,
            [key]: value
          }
        }, () => {
          this.props.actions.getProductTemplateByIdAction(value.id);
        });
      }
      else {
        addProduct.name = "";
        addProduct.description = "";
        addProduct.is_consumable = "";
        addProduct.attribute_groups = [];
        header = ["Basic Info"];
        addProduct.template = value;
        this.setState({
          header: header,
          addProduct: addProduct,
          isValueSetCheck: true
        }, () => {
          this.setPaginationValues(this.state.selectedTab);
        });
      }
    }
    else {
      this.setState({
        addProduct: {
          ...this.state.addProduct,
          [key]: value
        }
      });
    }
  }

  setIntialValue() {
    this.setState({
      isValueSetCheck: false
    });
  }

  clickPreviousBtn() {
    let { current_page } = this.state.pagination;
    let new_page = current_page - 1;
    let selected_tab = this.state.selectedTab - 1;
    if (new_page >= 1) {
      this.setState({
        ...this.state,
        selectedTab: selected_tab,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    }
  }

  clickNextBtn(data) {

    let { current_page, total_page } = this.state.pagination;
    let new_page = current_page + 1;
    let selected_tab = this.state.selectedTab + 1;
    if (new_page <= total_page) {
      this.setState({
        ...this.state,
        addProduct: data,
        selectedTab: selected_tab,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    }
  }

  closeWizard = () => {
    this.props.close();
  };

  handleChange(value) {
    this.setState({
      selectedTab: value,
      pagination: {
        ...this.state.pagination,
        current_page: value + 1
      }
    });
  }

  handleSaveClose = () => {
    this.setState({
      clickaction: 2
    }, () => {
      let data = this.state.addProduct;
      if (data.is_consumable) {
        data.is_consumable = 1;
      } else {
        data.is_consumable = 0;
      }
      data.partner_id = this.props.PartnerId;

      let checkvalidation = this.validateData();
      if (checkvalidation.length === 0) {
        var files = [];
        if (data.images && data.images.length > 0) {
          for (var i in data.images) {
            if (data.images[i] && data.images[i].image instanceof Blob) {
              files.push(data.images[i].image);
            }
          }
        }
        this.props.actions.saveProductTemplateAction(
          data,
          this.props.UserId,
          files
        );
      } else {
        this.setState({
          error: checkvalidation.toString()
        });
      }
    }
    );
  };

  validateData() {
    const {
      name,
      description,
      template,
      mrp,
      brand,
      images,
      producttype
    } = this.state.addProduct;
    let error = 0;
    let msg = [];

    if (name == "" || name == null || name.length < 0) {
      error = 1;
      msg.push("Please enter the name of the product");
    }

    if (mrp == "" || mrp == null || mrp == 0) {
      error = 1;
      msg.push("Please enter the Srp of the product");
    }

    if (description == "" || description == null || description.length < 0) {
      error = 1;
      msg.push("Please enter the description of the product");
    }

    if (template == null || template.length < 0) {
      error = 1;
      msg.push("Please select the template of the product");
    }

    if (brand == null || brand == "" || _.isEmpty(brand)) {
      error = 1;
      msg.push("Please select the brand of the product");
    }

    if (images == null || images.length < 1) {
      error = 1;
      msg.push("Please enter the images of the product");
    }

    return msg;
  }

  handleSaveNext = () => {
    this.setState(
      {
        clickaction: 1
      },
      () => {
        let data = this.state.addProduct;
        if (data.is_consumable) {
          data.is_consumable = 1;
        } else {
          data.is_consumable = 0;
        }
        data.partner_id = this.props.PartnerId;

        let checkvalidation = this.validateData();
        if (checkvalidation.length === 0) {
          var files = [];
          if (data.images && data.images.length > 0) {
            for (var i in data.images) {
              if (data.images[i] && data.images[i].image instanceof Blob) {
                files.push(data.images[i].image);
              }
            }
          }
          this.props.actions.saveProductTemplateAction(
            data,
            this.props.UserId,
            files
          );
        } else {
          this.setState({
            error: checkvalidation.toString()
          });
        }
      }
    );
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  Loading: state.productsAssignmentsState.loading,
  ListError: state.productsAssignmentsState.error,
  PartnerId: state.authState.user.partner_id,
  UserId: state.authState.user.pk,
  error: state.brandState.product_template.error,
  Loading: state.brandState.product_template.loading,
  savedTemplate: state.brandState.product_template.data,
  ProductTemplateById: state.brandState.productTemplateById.data,

  variant_list_error: state.variantReducer.list_variant.error,
  variant_list: state.variantReducer.list_variant.variants,

});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...extendedFormStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductWizard);
