import axios from "../utils/axios.jsx";

export const getUsersMenuList = async (userId, type) => {
  try {
    let fetchUrl = "/api/admin/menu/list/?1=1";
    if (userId) {
      fetchUrl = fetchUrl + "&userid=" + userId;
    }
    if (type) {
      fetchUrl = fetchUrl + "&type=" + type;
    }

    const response = await axios.get(fetchUrl, null, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeImage = async entityid => {
  try {
    const response = await axios.get(
      "/api/partner/entity-image/delete/?entity_id=" + entityid,
      null,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
