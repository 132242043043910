import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ValuesList from "./values-list";
import ValuesView from "./values-view";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class VariantValues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      ViewId: "",
      variant_id: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && !_.isEqual(nextProps, this.props)) {

      if (nextProps.variant_id) {
        this.setState({
          variant_id: nextProps.variant_id
        })
      }

    }
  }

  componentDidMount() {
    this.setState({
      variant_id: this.props.variant_id
    })
  }

  toggleValueFormView = visible => {
    this.setState({
      isAddNew: visible,
      ViewId: ""
    });
  };

  handleEdit = id => {
    this.setState({
      isAddNew: true,
      ViewId: id
    });
  };

  render() {

    return this.state.isAddNew
      ? this.renderComponent()
      : this.renderComponentsList();
  }

  renderComponent = () => {
    console.log("variant-value-view variant_id", this.state.variant_id);
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ValuesView
            variant_id={this.state.variant_id}
            value_id={this.state.ViewId}
            addNewValue={this.toggleValueFormView}
          />
        </GridItem>
      </GridContainer>
    );
  };

  renderComponentsList = () => {
    console.log("variant-value-list variant_id", this.state.variant_id);
    return (
      <GridContainer>
        <GridItem xs={12}>
          <ValuesList variant_id={this.state.variant_id} addNewValue={this.toggleValueFormView} edit={this.handleEdit} />
        </GridItem>
      </GridContainer>
    );
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserId: state.authState.user.user
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(VariantValues);
