// import axios from 'axios';
import { updateTypeKey, config } from "../constants";
import axios from "../utils/axios.jsx";
import { localStorageUtils } from "../utils";
export const saveReatilerAboutUs = async (Savedata, id) => {
  try {
    let data = new FormData();
    data.append("data", JSON.stringify(Savedata));
    data.append("retailer", id);
    data.append("_repository_id", config._repository_id);
    data.append("_branch_id", config._branch_id);
    data.append("_type", config._type);
    data.append("key", updateTypeKey.updateAboutUS);
    const response = await axios.post("/api/retailer/update/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const publishRetailerSite = async (id, mode) => {
  try {
    let data = new FormData();
    data.append("retailer", id);
    data.append("_repository_id", config._repository_id);
    data.append("_branch_id", config._branch_id);
    data.append("_type", config._type);
    data.append("key", updateTypeKey.updateAboutUS);
    data.append("mode", mode);
    const response = await axios.post("/api/retailer/publish/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReatilerData = async id => {
  try {
    let data = new FormData();
    data.append("retailer", id);
    data.append("_repository_id", config._repository_id);
    data.append("_branch_id", config._branch_id);
    data.append("_type", config._type);
    data.append("key", updateTypeKey.updateAboutUS);
    const response = await axios.post("/api/retailer/list/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const savePartnerData = async (
  Savedata,
  id,
  websiteData,
  selectedTab,
  partner_api_key,
  files
) => {
  try {
    let data = new FormData();
    data.append("data", JSON.stringify(Savedata));
    data.append("websiteData", JSON.stringify(websiteData));
    data.append("retailer", id);
    data.append("_repository_id", config._repository_id);
    data.append("_branch_id", config._branch_id);
    data.append("_type", config._type);
    data.append("key", updateTypeKey.updateAboutUS);
    data.append("selectedTab", selectedTab);
    data.append("partner_api_key", partner_api_key);
    if (files) {
      files.forEach(file => {
        data.append(file.name, file);
      });
    }

    // data.append("files", files)
    const response = await axios.post("/api/partner/update/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPartnerData = async (page, searchparams) => {
  try {
    let url = "/api/partner/list/?page=" + String(page);
    if (searchparams) {
      url = url + "&search=" + searchparams;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBrandRelatedPartnerData = async id => {
  try {
    let data = new FormData();
    data.append("userid", id);

    const response = await axios.post(
      "/api/brand/retailer-list/",
      data,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const approveSettlemts = async partner_id => {
  try {
    let data = new FormData();
    data.append("partner_id", partner_id);

    const response = await axios.post(
      "/api/retailer/settlement-approve/",
      data,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRetailerSettlemts = async partner_id => {
  try {
    let data = new FormData();
    data.append("partner_id", partner_id);

    const response = await axios.post(
      "/api/retailer/settlement/",
      data,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSegmentsImages = async () => {
  try {
    //let data = new FormData();

    const response = await axios.post(
      "/api/segment/images/",
      null,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBrandProductTemplate = async productKey => {
  try {
    let data = new FormData();
    data.append("data", JSON.stringify(productKey));
    const response = await axios.post(
      "/api/brands/producttemplate/getall/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllRetailerProductTemplatesSelectedMapping = async brandid => {
  try {
    const response = await axios.get(
      "/api/producttemplate/get/wholesaler/brands/categorised/list/?id=" +
        brandid
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "An error has occured while getting list of Product Templates"
      );
    }
  } catch (error) {
    throw error;
  }
};

export const getPartnerDetail = async partner_id => {
  try {
    const response = await axios.get(
      "/api/partner/partnerdetail/?id=" + partner_id
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "An error has occured while getting list of Product Templates"
      );
    }
  } catch (error) {
    throw error;
  }
};

export const clonePartnerSite = async (
  partner_id,
  copyBy_partnerId,
  config
) => {
  try {
    let data = new FormData();
    data.append("partnerid", partner_id);
    data.append("copyBy_partnerId", JSON.stringify(copyBy_partnerId));
    data.append("config", JSON.stringify(config));

    // data.append("files", files)
    const response = await axios.post("/api/cms-v2/clone/partnersite/", data);

    return response.data;
  } catch (error) {
    throw error;
  }
};
