import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Slide from "@material-ui/core/Slide";
import AgGrid from "../../../components/AgGrid/AgGrid.jsx";
import ComponentEditAction from "../../../components/AgGrid/AgGridActionButton";

// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import FormLabel from "@material-ui/core/FormLabel";
import { validationUtils } from "../../../utils";

import * as services from "../../../services/industry-metadata";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class PartnerMetaDataList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false
      },
      isAddNew: false,
      error: null,
      openDeleteDialog: false,
      id: null,
      NotificationId: null,
      NotificationValue: null,
      selectedUAIndexes: [],
      mappingData: {
        notifications: null,
        description: "",
        is_active: true
      },
      componentList: [],
      columnDefs: [
        {
          headerName: "id",
          field: "id",
          width: 100,
          resizable: true,
          sortable: false
        },
        {
          headerName: "NAME",
          field: "name",
          resizable: true,
          sortable: true,
          suppressSizeToFit: true
          // filter: "agTextColumnFilter"
        },
        {
          headerName: "PARTNER TYPE",
          field: "partner_type",
          resizable: true,
          sortable: true,
          width: 100
          // filter: "agTextColumnFilter"
        },
        {
          headerName: "ACTIVE",
          field: "is_active",
          resizable: true,
          sortable: true,
          width: 100
        },
        {
          headerName: "ACTIONS",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ],
      required_clone_package: {
        industry: "",
        name: ""
      },
      clone_package: {
        industry: null,
        name: "",
        id: null
      }
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && !_.isEqual(nextProps, this.props)) {
      if (nextProps.deleteError) {
        this.setState({
          error: nextProps.deleteError
        });
      }

      if (
        this.state.openDeleteDialog &&
        !nextProps.deleting &&
        !nextProps.deleteError
      ) {
        this.setState(
          {
            openDeleteDialog: false
          },
          () => {
            if (this.props.user && this.props.user.role !== "AD") {
              this.props.actions.getPartnerPages(
                this.props.user.partner_id,
                this.state.pagination.page
              );
            } else {
              this.props.actions.getCMSPages(
                null,
                null,
                this.state.pagination.page
              );
            }
          }
        );
      }

      if (
        this.state.openCopyDilog &&
        nextProps &&
        nextProps.clone_package_isSaved
      ) {
        this.setState(
          {
            openCopyDilog: false
          },
          () => {
            this.refreshList();
          }
        );
      }
    }
  }

  refreshList() {
    this.setState({
      loading: true
    });

    services
      .getMetaData(
        null,
        null,
        false,
        this.state.pagination.page,
        this.state.searchtext
      )
      .then(data => {
        console.log("Meta Data List", data);
        if (data && data.status === "1") {
          this.setState({
            componentList: data.data.results,
            pagination: {
              page: data.data.current,
              next: data.data.next,
              prev: data.data.prev,
              totalpages: data.data.total_pages,
              trecords: data.data.total_records,
              pagesize: data.data.page_size
            }
          });
        } else {
          this.setState({
            error: "An error has occured while fetching record"
          });
        }
      })
      .catch(reason => {
        this.setState({
          error: "An error has occured while fetching record"
        });

        console.error("Meta Data List api call error", reason);
      })
      .finally(info => {
        this.setState({
          loading: false
        });
        console.log(info);
      });
  }

  componentDidMount() {
    this.refreshList();

    if (this.props.user && this.props.user.role === "AD") {
      this.props.actions.getIndustriesTypeList(this.props.UserId, "list");
    }
  }

  handleAddNew = () => {
    this.props.addNew(true);
  };

  handelEdit = id => {
    const { componentList } = this.state;
    const data = componentList;
    let index = data.findIndex(p => p.id === id);
    if (data[index]) {
      this.props.edit(id);
    }
  };

  handleCopy = id => {
    const { componentList } = this.state;
    let index = componentList.findIndex(x => x.id == id);

    //   this.props.pageClone(true, index)
    this.setState(
      {
        openCopyDilog: true,
        clone_package: {
          ...this.state.clone_package,
          name: componentList[index].name,
          id: componentList[index].id
        }
      },
      () => {
        // this.props.actions.getBusinessType(this.props.UserId);
      }
    );
  };

  handleTextChange_copy = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required_clone_package;
    reqData[key] = validData;
    this.setState({ required_clone_package: reqData });

    this.setState({
      clone_package: {
        ...this.state.clone_package,
        [key]: event.target.value
      }
    });
  };

  handleSelectChange = key => event => {
    var validData = true;
    if (!event.target.value) {
      validData = false;
    }

    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({
      ...this.state,
      required: reqData
    });

    this.setState({
      ...this.state,
      formUpdate: this.state.formUpdate,
      cms_pages: {
        ...this.state.cms_pages,
        [key]: event.target.value
      }
    });
  };

  onHanndelClose = () => {
    this.setState({
      openCopyDilog: false
    });
  };

  onSave_Copymenu = () => {
    this.props.actions.clonePackage(this.state.clone_package);
  };

  copyMenuDilog() {
    const { classes } = this.props;
    const { required_clone_package } = this.state;
    let { name, industry } = this.state.clone_package;

    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.openCopyDilog}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.onHanndelClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle} style={{ width: "500px" }}>
            Clone Package{" "}
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <LoadingOverlay>
            <DialogContentText>
              {this.props.user.role == "AD" && (
                <FormControl
                  fullWidth
                  style={customInputStyle.formControl}
                  name="industry"
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.labelRoot + " "}
                    labelText="Industry*"
                  >
                    Industry *
                  </InputLabel>
                  <Select
                    success={required_clone_package.industry}
                    error={required_clone_package.industry === false}
                    style={{ marginTop: "0px" }}
                    value={industry ? industry : []}
                    multiple
                    onChange={this.handleTextChange_copy("industry")}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choose Industry
                    </MenuItem>
                    {_.map(this.props.industriesList, ({ id, name }, index) => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={id}
                          key={index + "_" + id}
                        >
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <CustomInput
                labelText=""
                success={required_clone_package.name === "success"}
                error={required_clone_package.name === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  value: name,
                  onChange: this.handleTextChange_copy("name", "")
                }}
              />
            </DialogContentText>

            {this.props.clone_package_loading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader
              loading={this.props.clone_package_loading}
              text="saving.."
            />
            <br />
            {this.props.clone_package_error && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code>{this.props.clone_package_error}</code>
              </FormLabel>
            )}
          </LoadingOverlay>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.onSave_Copymenu()} color="primary" simple>
            Save
          </Button>
          <Button onClick={() => this.onHanndelClose()} color="danger" simple>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  deletePage = () => {
    this.setState({
      deleting: true
    });

    services
      .deleteMetaData(this.props.UserId, this.state.id)
      .then(data => {
        console.log("Meta Data Deleted", data);
        if (data && data.status === "1") {
          this.refreshList();
        } else {
          this.setState({
            error: "An error has occured while deleting record"
          });
        }
      })
      .catch(reason => {
        this.setState({
          error: "An error has occured while deleting record"
        });

        console.error("Meta Data delete api call error", reason);
      })
      .finally(info => {
        console.log(info);
        this.setState({
          id: "",
          openDeleteDialog: false,
          deleting: false
        });
      });
  };

  deleteDialog = id => {
    this.setState({
      openDeleteDialog: !!id,
      id: id,
      error: null
    });
  };

  deleteDialogComponent = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        disableBackdropClick={true}
        onClose={() => this.closeIndustriesComponentDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              Are you sure you want to delete?
              <br />
              {this.state.error && (
                <FormLabel className={classes.labelLeftHorizontal}>
                  <code>{this.state.error}</code>
                </FormLabel>
              )}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.deletePage()}
              >
                Delete
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.deleteDialog("")}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
            {this.state.deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.state.deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  };

  renderNotificationTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.deleteDialogComponent()}
        {this.copyMenuDilog()}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  View, edit or delete Industry Package below.
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <AgGrid
                  rowData={this.state.componentList}
                  columnDefs={this.state.columnDefs}
                  handleCopy={this.handleCopy}
                  handelEdit={this.handelEdit}
                  handleDelete={this.deleteDialog}
                  AgGridActionButton={ComponentEditAction}
                  update={this.updateGridData}
                  isServerPagination={true}
                  pagination={this.state.pagination}
                  loading={this.state.loading}
                  search={this.searchText}
                  new={this.handleAddNew}
                  isAddBtn={true}
                />
                <Loader loading={this.state.loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: page
        }
      },
      () => {
        this.refreshList();
      }
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param,
        pagination: {
          ...this.state.pagination,
          page: 1
        }
      },
      () => {
        this.refreshList();
      }
    );
  }

  render() {
    return this.renderNotificationTable();
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  user: state.authState.user,
  UserId: state.authState.user.user,
  industriesList: state.industriesReducer.list,

  clone_package_loading: state.cmsReducer.clone_package.saving,
  clone_package_error: state.cmsReducer.clone_package.error,
  clone_package_isSaved: state.cmsReducer.clone_package.isSaved
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PartnerMetaDataList);
