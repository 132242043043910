import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Slide from "@material-ui/core/Slide";
import AgGrid from "../../../components/AgGrid/AgGrid.jsx";
import ComponentEditAction from "../../../components/AgGrid/AgGridActionButton";

// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControl from "@material-ui/core/FormControl";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import FormLabel from "@material-ui/core/FormLabel";
import { validationUtils } from "../../../utils";
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class CMSPagesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false
      },
      isAddNew: false,
      error: null,
      openDeleteDialog: false,
      deletePageId: null,
      NotificationId: null,
      NotificationValue: null,
      selectedUAIndexes: [],
      mappingData: {
        notifications: null,
        description: "",
        is_active: true
      },
      componentList: [],
      columnDefs: [
        {
          headerName: "id",
          field: "id",
          width: 150,
          resizable: true,
          sortable: false
        },
        {
          headerName: "NAME",
          field: "name",
          width: 200,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter"
        },
        {
          headerName: "SLUG",
          field: "slug",
          width: 200,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter"
        },
        {
          headerName: "ACTIVE",
          field: "is_active",
          width: 120,
          resizable: true,
          sortable: true
        },
        {
          headerName: "HOME",
          field: "is_landing_page",
          width: 120,
          resizable: true,
          sortable: true
        },

        {
          headerName: "ACTIONS",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ],
      required_copy_menu: {
        industry: "",
        menu_name: ""
      },
      copy_menu: {
        industry: null,
        menu_name: "",
        page_id: null,
        slug: ""
      }
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.list) {
      this.setState({
        componentList: nextProps.list,
        pagination: nextProps.pagination
      });
    }

    if (nextProps && !_.isEqual(nextProps, this.props)) {
      if (nextProps.deleteError) {
        this.setState({
          error: nextProps.deleteError
        });
      }

      if (
        this.state.openDeleteDialog &&
        !nextProps.deleting &&
        !nextProps.deleteError
      ) {
        this.setState(
          {
            openDeleteDialog: false
          },
          () => {
            if (this.props.user && this.props.user.role !== "AD") {
              this.props.actions.getPartnerPages(
                this.props.user.partner_id,
                this.state.pagination.page
              );
            } else {
              this.props.actions.getCMSPages(
                null,
                null,
                this.state.pagination.page
              );
            }
          }
        );
      }
    }
    if (nextProps && nextProps.clone_page_isSaved) {
      this.setState(
        {
          openCopyDilog: false
        },
        () => {
          if (this.props.user && this.props.user.role !== "AD") {
            this.props.actions.getPartnerPages(
              this.props.user.partner_id,
              this.state.pagination.page
            );
          } else {
            this.props.actions.getCMSPages(
              null,
              null,
              this.state.pagination.page
            );
          }
        }
      );
    }
  }

  componentDidMount() {
    if (this.props.user && this.props.user.role !== "AD") {
      this.props.actions.getPartnerPages(
        this.props.user.partner_id,
        this.state.pagination.page
      );
    } else {
      this.props.actions.getCMSPages(
        null,
        null,
        false,
        this.state.pagination.page
      );
    }
    if (this.props.user && this.props.user.role === "AD") {
      this.props.actions.getIndustriesTypeList(this.props.UserId, "list");
    }
  }

  handleAddNew = () => {
    this.props.addNew(true);
  };

  handelEdit = id => {
    const { componentList } = this.state;
    const data = componentList;
    let index = data.findIndex(p => p.id === id);
    if (data[index]) {
      this.props.edit(id);
    }
  };

  handleCopy = id => {
    const { componentList } = this.state;
    let index = componentList.findIndex(x => x.id == id);

    //   this.props.pageClone(true, index)
    this.setState(
      {
        openCopyDilog: true,
        copy_menu: {
          ...this.state.copy_menu,
          name: componentList[index].name,
          page_id: componentList[index].id,
          slug: this.string_to_slug(componentList[index].name)
          // indu: MenuList[index].businessTypeId
        }
      },
      () => {
        this.props.actions.getBusinessType(this.props.UserId);
      }
    );
  };

  handleTextChange_copy = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required_copy_menu;
    reqData[key] = validData;
    this.setState({ required_copy_menu: reqData });

    let new_slug = this.state.copy_menu.slug;

    if (key === "name") {
      new_slug = this.string_to_slug(event.target.value);
      reqData["slug"] = "success";
    }

    this.setState({
      copy_menu: {
        ...this.state.copy_menu,
        [key]: event.target.value,
        slug: new_slug
      }
    });
  };

  handleSelectChange = key => event => {
    var validData = true;
    if (!event.target.value) {
      validData = false;
    }

    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({
      ...this.state,
      required: reqData
    });

    this.setState({
      ...this.state,
      formUpdate: this.state.formUpdate,
      cms_pages: {
        ...this.state.cms_pages,
        [key]: event.target.value
      }
    });
  };
  onHanndelClose = () => {
    this.setState({
      openCopyDilog: false
    });
  };
  onSave_Copymenu = () => {
    if (this.props.user.role == "AD") {
      this.props.actions.saveClonePages(this.state.copy_menu);
    } else {
      this.props.actions.clonePartnerPages(
        this.state.copy_menu,
        this.props.user.partner_id
      );
    }
  };
  string_to_slug = str => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-") // collapse dashes
      .replace(/^-+/, ""); // trim - from start of text
    // .replace(/-+$/, ""); // trim - from end of text

    return str;
  };
  copyMenuDilog() {
    const { classes } = this.props;
    const { required_copy_menu } = this.state;
    let { name, industry } = this.state.copy_menu;

    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.openCopyDilog}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.onHanndelClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle} style={{ width: "500px" }}>
            Clone Pages{" "}
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <LoadingOverlay>
            <DialogContentText>
              {this.props.user.role == "AD" && (
                <FormControl
                  fullWidth
                  style={customInputStyle.formControl}
                  name="industry"
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.labelRoot + " "}
                    labelText="Industry*"
                  >
                    Industry *
                  </InputLabel>
                  <Select
                    success={required_copy_menu.industry}
                    error={required_copy_menu.industry === false}
                    style={{ marginTop: "0px" }}
                    value={industry ? industry : []}
                    multiple
                    onChange={this.handleTextChange_copy("industry")}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choose Industry
                    </MenuItem>
                    {_.map(this.props.industriesList, ({ id, name }, index) => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={id}
                          key={index + "_" + id}
                        >
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <CustomInput
                labelText=""
                success={required_copy_menu.name === "success"}
                error={required_copy_menu.name === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  value: name,
                  onChange: this.handleTextChange_copy("name", "")
                }}
              />
            </DialogContentText>

            {this.props.copy_isloading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.copy_isloading} text="saving.." />
            <br />
            {this.props.copy_ListError &&
              this.props.copy_ListError.trim() !== "" && (
                <FormLabel className={classes.labelLeftHorizontal}>
                  <code>{this.props.copy_ListError}</code>
                </FormLabel>
              )}
          </LoadingOverlay>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.onSave_Copymenu()} color="danger" simple>
            Save
          </Button>
          <Button onClick={() => this.onHanndelClose()} color="danger" simple>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  deletePage = () => {
    if (this.props.user.role == "AD") {
      this.props.actions.deleteAdminPage(
        this.props.UserId,
        this.state.deletePageId
      );
    } else {
      this.props.actions.deletePartnerPage(
        this.props.UserId,
        this.state.deletePageId
      );
    }
  };

  deleteDialog = Id => {
    this.setState({
      openDeleteDialog: !!Id,
      deletePageId: Id,
      error: null
    });
  };

  deleteDialogComponent = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        disableBackdropClick={true}
        onClose={() => this.closeIndustriesComponentDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              Are you sure you want to delete?
              <br />
              {this.state.error && (
                <FormLabel className={classes.labelLeftHorizontal}>
                  <code>{this.state.error}</code>
                </FormLabel>
              )}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.deletePage()}
              >
                Delete
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.deleteDialog("")}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
            {this.props.deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  };

  renderNotificationTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.deleteDialogComponent()}
        {this.copyMenuDilog()}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  View, edit or delete Pages below.
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.componentList}
                columnDefs={this.state.columnDefs}
                handleCopy={this.handleCopy}
                handelEdit={this.handelEdit}
                handleDelete={this.deleteDialog}
                AgGridActionButton={ComponentEditAction}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                loading={this.props.loading}
                search={this.searchText}
                new={this.handleAddNew}
                isAddBtn={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    if (this.props.user && this.props.user.role !== "AD") {
      this.props.actions.getPartnerPages(
        this.props.user.partner_id,
        page,
        this.state.searchtext
      );
    } else {
      this.props.actions.getCMSPages(
        null,
        null,
        false,
        page,
        this.state.searchtext
      );
    }
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        if (this.props.user && this.props.user.role !== "AD") {
          this.props.actions.getPartnerPages(
            this.props.user.partner_id,
            page,
            param
          );
        } else {
          this.props.actions.getCMSPages(null, null, false, page, param);
        }
      }
    );
  }

  render() {
    return this.renderNotificationTable();
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  user: state.authState.user,
  UserId: state.authState.user.user,
  loading: state.cmsReducer.get_CMS_Pages.loading,
  error: state.cmsReducer.get_CMS_Pages.error,
  list: state.cmsReducer.get_CMS_Pages.list,

  deleting: state.cmsReducer.delete_CMS_Page.deleting,
  deleteError: state.cmsReducer.delete_CMS_Page.error,

  clone_page_loading: state.cmsReducer.clone_page.loading,
  clone_page_error: state.cmsReducer.clone_page.error,
  clone_page_list: state.cmsReducer.clone_page.list,
  clone_page_isSaved: state.cmsReducer.clone_page.isSaved,

  industriesList: state.industriesReducer.list,
  pagination: state.cmsReducer.get_CMS_Pages.pagination
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CMSPagesList);
