import axios from "../utils/axios.jsx";

export const getSavedBatchProductList = async partnerid => {
  try {
    const response = await axios.get(
      "/api/retailer/get-batch-product/get/?partnerid=" + partnerid
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const getCategoryById = async (id) => {
//     try {
//         const response = await axios.get('/api/partner/category-by-id/get/?categoryid=' + id);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// }

export const batchProductCreate = async (data, userId, partnerId) => {
  try {
    let form = new FormData();
    form.append("partner_id", JSON.stringify(partnerId));
    form.append("user_id", JSON.stringify(userId));
    form.append("product_template_id", JSON.stringify(data));
    const response = await axios.post(
      "/api/retailer/create-batch-product/",
      form
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const getCategoryProductMapping = async (categoryid, partnerid) => {
//     try {
//         const response = await axios.get('/api/partner/category-product-mapping/get/?categoryid=' + categoryid+'&partnerid='+partnerid);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// }

// export const mapProductWithCategory = async (userId, mapdata) => {
//     try {
//         let data = new FormData();
//         data.append("userid", userId)
//         data.append('data', JSON.stringify(mapdata));
//         const response = await axios.post('/api/partner/category-product-mapping/save/', data);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// }

// export const getBrandCategoryProductHierarchy = async (partnerid) => {
//     try {
//         const response = await axios.get('/api/partner/brand-category-product/get/?partnerid='+partnerid);
//         return response.data;
//     } catch (error) {
// 		throw error;
// 	}
// }
