import * as classService from "../../services/classService";
import * as actionTypes from "./actionTypes";

export const getClassList = (
  partnerid,
  classType,
  isGetAllList,
  page = 0,
  searchtext = null,
  prodTempId = null
) => async dispatch => {
  dispatch(getClassRequest());
  try {
    const success = await classService.getclassList(
      partnerid,
      classType,
      isGetAllList,
      page,
      searchtext,
      prodTempId
    );
    dispatch(getClassSuccess(success.data));
  } catch (error) {
    dispatch(getClassFailure(error.message));
  }
};
export const saveClass = (id, data, partnerid) => async dispatch => {
  dispatch(saveClassRequest());
  try {
    const success = await classService.saveclass(id, data, partnerid);
    dispatch(saveClassSuccess(success.data));
  } catch (error) {
    dispatch(saveClassFailure(error.message));
  }
};

export const deleteClass = (id, class_id) => async dispatch => {
  dispatch(deleteClassRequest());
  try {
    const success = await classService.deleteSegments(id, class_id);
    dispatch(deleteClassSuccess(success.data, class_id));
  } catch (error) {
    dispatch(deleteClassFailure(error.message));
  }
};

export const mapClassUA = (id, mapData) => async dispatch => {
  dispatch(saveClassUARequest());
  try {
    const success = await classService.mapClassUA(id, mapData);
    dispatch(saveClassUASuccess(success.data));
  } catch (error) {
    dispatch(saveClassUAFailure(error.message));
  }
};

export const afertMapClassUA = () => async dispatch => {
  dispatch(afterSaveClassUARequest());
};

const afterSaveClassUARequest = () => {
  return {
    type: actionTypes.AFTER_MAP_CLASS_UA_SUCCESS
  };
};

const saveClassUARequest = () => {
  return {
    type: actionTypes.MAP_CLASS_UA_REQUEST
  };
};
const saveClassUASuccess = data => {
  return {
    type: actionTypes.MAP_CLASS_UA_SUCCESS,
    payload: data
  };
};
const saveClassUAFailure = error => {
  return {
    type: actionTypes.MAP_CLASS_UA_FAILURE,
    payload: error
  };
};

const saveClassRequest = () => {
  return {
    type: actionTypes.SAVE_CLASS_REQUEST
  };
};

const saveClassSuccess = data => {
  return {
    type: actionTypes.SAVE_CLASS_SUCCESS,
    payload: data
  };
};

const saveClassFailure = error => {
  return {
    type: actionTypes.SAVE_CLASS_FAILURE,
    payload: error
  };
};

const deleteClassRequest = () => {
  return {
    type: actionTypes.DELETE_CLASS_REQUEST
  };
};

const deleteClassSuccess = (data, class_id) => {
  return {
    type: actionTypes.DELETE_CLASS_SUCCESS,
    payload: data,
    class_id: class_id
  };
};

const deleteClassFailure = error => {
  return {
    type: actionTypes.DELETE_CLASS_FAILURE,
    payload: error
  };
};

const getClassRequest = () => {
  return {
    type: actionTypes.GET_CLASS_REQUEST
  };
};

const getClassSuccess = data => {
  return {
    type: actionTypes.GET_CLASS_SUCCESS,
    payload: data
  };
};

const getClassFailure = error => {
  return {
    type: actionTypes.GET_CLASS_FAILURE,
    payload: error
  };
};

export const saveCategoyAction = (id, data, partnerid) => async dispatch => {
  dispatch(saveCategoyRequest());
  try {
    const success = await classService.saveCategoy(id, data, partnerid);
    dispatch(saveCategoySuccess(success.data));
  } catch (error) {
    dispatch(saveCategoyFailure(error.message));
  }
};

const saveCategoyRequest = () => {
  return {
    type: actionTypes.SAVE_CATEGORY_REQUEST
  };
};

const saveCategoySuccess = data => {
  return {
    type: actionTypes.SAVE_CATEGORY_SUCCESS,
    payload: data
  };
};

const saveCategoyFailure = error => {
  return {
    type: actionTypes.SAVE_CATEGORY_FAILURE,
    payload: error
  };
};

export const getClassByIdAction = id => async dispatch => {
  dispatch(getClassByIdRequest());
  try {
    const success = await classService.getClassById(id);
    dispatch(getClassByIdSuccess(success.data));
  } catch (error) {
    dispatch(getClassByIdFailure(error.message));
  }
};

const getClassByIdRequest = () => {
  return {
    type: actionTypes.GET_CLASS_BYID_REQUEST
  };
};

const getClassByIdSuccess = data => {
  return {
    type: actionTypes.GET_CLASS_BYID_SUCCESS,
    payload: data
  };
};

const getClassByIdFailure = error => {
  return {
    type: actionTypes.GET_CLASS_BYID_FAILURE,
    payload: error
  };
};

export const getPartnersClassesActions = (partnerid) => async dispatch => {
  dispatch(getPartnersClassesRequest());
  try {
    const success = await classService.getPartnersClasses(partnerid);
    dispatch(getPartnersClassesSuccess(success.data));
  } catch (error) {
    dispatch(getPartnersClassesFailure(error.message));
  }
};

const getPartnersClassesRequest = () => {
  return {
    type: actionTypes.GET_PARTNER_CLASSES_REQUEST
  };
};

const getPartnersClassesSuccess = data => {
  return {
    type: actionTypes.GET_PARTNER_CLASSES_SUCCESS,
    payload: data
  };
};

const getPartnersClassesFailure = error => {
  return {
    type: actionTypes.GET_PARTNER_CLASSES_FAILURE,
    payload: error
  };
};
