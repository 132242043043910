import * as variantService from "../../services/variantService";
import * as actionTypes from "./actionTypes";


const getVariantRequest = () => {
    return {
        type: actionTypes.GET_VARIANT_REQUEST
    };
};

const getVariantSuccess = data => {
    return {
        type: actionTypes.GET_VARIANT_SUCCESS,
        payload: data
    };
};

const getVariantFailure = error => {
    return {
        type: actionTypes.GET_VARIANT_FAILURE,
        payload: error
    };
};


export const getVariants = (page = 0, searchtext = null, is_active = false) => async dispatch => {
    dispatch(getVariantRequest());
    try {

        const success = await variantService.getVariants(
            page,
            searchtext,
            is_active
        );

        if (success && success.status) {
            dispatch(getVariantSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(getVariantFailure(success.error));
            }
            else {
                dispatch(getVariantFailure("An error has occured while loading variant details. Please try refreshing page."));
            }
        }
    } catch (error) {
        dispatch(getVariantFailure("An error has occured while loading variant details. Please try refreshing page."));
        console.error("Product Variant Load Error", error);
    }
};


const getVariantDetailsRequest = () => {
    return {
        type: actionTypes.GET_VARIANT_DETAIL_REQUEST
    };
};

const getVariantDetailsSuccess = data => {
    return {
        type: actionTypes.GET_VARIANT_DETAIL_SUCCESS,
        payload: data
    };
};

const getVariantDetailsFailure = error => {
    return {
        type: actionTypes.GET_VARIANT_DETAIL_FAILURE,
        payload: error
    };
};


export const getVariantDetails = (variant_id) => async dispatch => {
    dispatch(getVariantDetailsRequest());
    try {

        const success = await variantService.getVariant(variant_id);

        if (success && success.status) {
            dispatch(getVariantDetailsSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(getVariantDetailsFailure(success.error));
            }
            else {
                dispatch(getVariantDetailsFailure("An error has occured while loading variant details. Please try refreshing page."));
            }
        }
    } catch (error) {
        dispatch(getVariantDetailsFailure("An error has occured while loading variant details. Please try refreshing page."));
        console.error("Variant Load Error", error);
    }
};



const saveVariantRequest = () => {
    return {
        type: actionTypes.CREATE_VARIANT_REQUEST
    };
};

const saveVariantSuccess = data => {
    return {
        type: actionTypes.CREATE_VARIANT_SUCCESS,
        payload: data
    };
};

const saveVariantFailure = error => {
    return {
        type: actionTypes.CREATE_VARIANT_FAILURE,
        payload: error
    };
};


export const saveVariant = (user_id, data) => async dispatch => {
    dispatch(saveVariantRequest());
    try {
        const success = await variantService.saveVariantService(user_id, data);

        if (success && success.status) {
            dispatch(saveVariantSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(saveVariantFailure(success.error));
            }
            else {
                dispatch(saveVariantFailure("An error has occured while saving variant details. Please try after some time."));
            }
        }
    } catch (error) {
        dispatch(saveVariantFailure("An error has occured while loading variant details. Please try refreshing page."));
        console.error("Variant Load Error", error);
    }
};



const deleteVariantRequest = () => {
    return {
        type: actionTypes.DELETE_VARIANT_REQUEST
    };
};

const deleteVariantSuccess = (data, id) => {
    return {
        type: actionTypes.DELETE_VARIANT_SUCCESS,
        payload: data,
        id: id
    };
};

const deleteVariantFailure = error => {
    return {
        type: actionTypes.DELETE_VARIANT_FAILURE,
        payload: error
    };
};

export const deleteVariant = (id) => async dispatch => {
    dispatch(deleteVariantRequest());
    try {
        const success = await variantService.deleteVariant(id);

        if (success && success.status) {
            dispatch(deleteVariantSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(deleteVariantFailure(success.error));
            }
            else {
                dispatch(deleteVariantFailure("An error has occured while deleting variant. Please try after some time."));
            }
        }
    } catch (error) {
        dispatch(deleteVariantFailure("An error has occured while deleting variant. Please try after some time."));
        console.error("Variant Load Error", error);
    }
};




////// Variant Values


const getVariantValueRequest = () => {
    return {
        type: actionTypes.GET_VARIANT_VALUE_REQUEST
    };
};

const getVariantValueSuccess = data => {
    return {
        type: actionTypes.GET_VARIANT_VALUE_SUCCESS,
        payload: data
    };
};

const getVariantValueFailure = error => {
    return {
        type: actionTypes.GET_VARIANT_VALUE_FAILURE,
        payload: error
    };
};


export const getVariantValues = (page = 0, searchtext = null, is_active = false, variant_id) => async dispatch => {
    dispatch(getVariantValueRequest());
    try {

        const success = await variantService.getVariantValues(
            page,
            searchtext,
            is_active,
            variant_id
        );

        if (success && success.status) {
            dispatch(getVariantValueSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(getVariantValueFailure(success.error));
            }
            else {
                dispatch(getVariantValueFailure("An error has occured while loading variant details. Please try refreshing page."));
            }
        }
    } catch (error) {
        dispatch(getVariantValueFailure("An error has occured while loading variant details. Please try refreshing page."));
        console.error("Product Variant Load Error", error);
    }
};


const getVariantValueDetailsRequest = () => {
    return {
        type: actionTypes.GET_VARIANT_VALUE_DETAIL_REQUEST
    };
};

const getVariantValueDetailsSuccess = data => {
    return {
        type: actionTypes.GET_VARIANT_VALUE_DETAIL_SUCCESS,
        payload: data
    };
};

const getVariantValueDetailsFailure = error => {
    return {
        type: actionTypes.GET_VARIANT_VALUE_DETAIL_FAILURE,
        payload: error
    };
};


export const getVariantValueDetails = (variant_id, value_id) => async dispatch => {
    dispatch(getVariantValueDetailsRequest());
    try {

        const success = await variantService.getVariantValue(variant_id, value_id);

        if (success && success.status) {
            dispatch(getVariantValueDetailsSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(getVariantValueDetailsFailure(success.error));
            }
            else {
                dispatch(getVariantValueDetailsFailure("An error has occured while loading variant details. Please try refreshing page."));
            }
        }
    } catch (error) {
        dispatch(getVariantValueDetailsFailure("An error has occured while loading variant details. Please try refreshing page."));
        console.error("Variant Load Error", error);
    }
};



const saveVariantValueRequest = () => {
    return {
        type: actionTypes.CREATE_VARIANT_VALUE_REQUEST
    };
};

const saveVariantValueSuccess = data => {
    return {
        type: actionTypes.CREATE_VARIANT_VALUE_SUCCESS,
        payload: data
    };
};

const saveVariantValueFailure = error => {
    return {
        type: actionTypes.CREATE_VARIANT_VALUE_FAILURE,
        payload: error
    };
};


export const saveVariantValue = (user_id, data) => async dispatch => {
    dispatch(saveVariantValueRequest());
    try {
        const success = await variantService.saveVariantValueService(user_id, data);

        if (success && success.status) {
            dispatch(saveVariantValueSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(saveVariantValueFailure(success.error));
            }
            else {
                dispatch(saveVariantValueFailure("An error has occured while saving variant details. Please try after some time."));
            }
        }
    } catch (error) {
        dispatch(saveVariantValueFailure("An error has occured while loading variant details. Please try refreshing page."));
        console.error("Variant Load Error", error);
    }
};



const deleteVariantValueRequest = () => {
    return {
        type: actionTypes.DELETE_VARIANT_VALUE_REQUEST
    };
};

const deleteVariantValueSuccess = (data, id) => {
    return {
        type: actionTypes.DELETE_VARIANT_VALUE_SUCCESS,
        payload: data,
        id: id
    };
};

const deleteVariantValueFailure = error => {
    return {
        type: actionTypes.DELETE_VARIANT_VALUE_FAILURE,
        payload: error
    };
};

export const deleteVariantValue = (id) => async dispatch => {
    dispatch(deleteVariantValueRequest());
    try {
        const success = await variantService.deleteVariantValue(id);

        if (success && success.status) {
            dispatch(deleteVariantValueSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(deleteVariantValueFailure(success.error));
            }
            else {
                dispatch(deleteVariantValueFailure("An error has occured while deleting variant. Please try after some time."));
            }
        }
    } catch (error) {
        dispatch(deleteVariantValueFailure("An error has occured while deleting variant. Please try after some time."));
        console.error("Variant Load Error", error);
    }
};
