export const GET_PRODUCT_TEMPLATE_REQUEST = "get_product_template_request";
export const GET_PRODUCT_TEMPLATE_FAILURE = "get_product_template_failure";
export const GET_PRODUCT_TEMPLATE_SUCCESS = "get_product_template_success";

export const GET_CATEGORISED_PRODUCT_TEMPLATE_REQUEST =
  "get_categorised_product_template_request";
export const GET_CATEGORISED_PRODUCT_TEMPLATE_FAILURE =
  "get_categorised_product_template_failure";
export const GET_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS =
  "get_categorised_product_template_success";

export const CREATE_PRODUCT_TEMPLATE_REQUEST =
  "create_product_template_request";
export const CREATE_PRODUCT_TEMPLATE_FAILURE =
  "create_product_template_failure";
export const CREATE_PRODUCT_TEMPLATE_SUCCESS =
  "create_product_template_success";

export const GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_REQUEST =
  "get_brands_categorised_product_template_request";
export const GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_FAILURE =
  "get_brands_categorised_product_template_failure";
export const GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS =
  "get_brands_categorised_product_template_success";

export const GET_RBRANDS_CATEGORISED_PRODUCT_TEMPLATE_REQUEST =
  "get_rbrands_categorised_product_template_request";
export const GET_RBRANDS_CATEGORISED_PRODUCT_TEMPLATE_FAILURE =
  "get_rbrands_categorised_product_template_failure";
export const GET_RBRANDS_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS =
  "get_rbrands_categorised_product_template_success";

export const SAVE_PRODUCT_TEMPLATE_REQUEST = "save_product_template_request";
export const SAVE_PRODUCT_TEMPLATE_FAILURE = "save_product_template_failure";
export const SAVE_PRODUCT_TEMPLATE_SUCCESS = "save_product_template_success";

export const PRODUCT_TEMPLATE_BYID_REQUEST = "product_template_byid_request";
export const PRODUCT_TEMPLATE_BYID_FAILURE = "product_template_byid_failure";
export const PRODUCT_TEMPLATE_BYID_SUCCESS = "product_template_byid_success";

export const PUBLISH_BRAND_PRODUCT_REQUEST = "publish_brand_product_request";
export const PUBLISH_BRAND_PRODUCT_FAILURE = "publish_brand_product_failure";
export const PUBLISH_BRAND_PRODUCT_SUCCESS = "publish_brand_product_success";
