export const GET_BUSINESS_TYPE_REQUEST = "get_business_type_request";
export const GET_BUSINESS_TYPE_FAILURE = "get_business_type_failure";
export const GET_BUSINESS_TYPE_SUCCESS = "get_business_type_success";

export const SAVE_BUSINESS_TYPE_REQUEST = "save_business_type_request";
export const SAVE_BUSINESS_TYPE_FAILURE = "save_business_type_failure";
export const SAVE_BUSINESS_TYPE_SUCCESS = "save_business_type_success";

export const DELETE_BUSINESS_TYPE_REQUEST = "delete_business_type_request";
export const DELETE_BUSINESS_TYPE_FAILURE = "delete_business_type_failure";
export const DELETE_BUSINESS_TYPE_SUCCESS = "delete_business_type_success";

export const MAP_BUSINESS_INDUSTRIES_REQUEST =
  "map_business_industries_request";
export const MAP_BUSINESS_INDUSTRIES_FAILURE =
  "map_business_industries_failure";
export const MAP_BUSINESS_INDUSTRIES_SUCCESS =
  "map_business_industries_success";

export const AFTER_MAP_BUSINESS_INDUSTRIES_SUCCESS =
  "after_map_business_industries_success";

export const GET_BUSINESS_TYPE_BYID_REQUEST = "get_business_type_byid_request";
export const GET_BUSINESS_TYPE_BYID_FAILURE = "get_business_type_byid_failure";
export const GET_BUSINESS_TYPE_BYID_SUCCESS = "get_business_type_byid_success";

export const GET_ECONOMIC_SECTORS_INDUSTRIES_REQUEST =
  "get_economic_sectors_industries_request";
export const GET_ECONOMIC_SECTORS_INDUSTRIES_FAILURE =
  "get_economic_sectors_industries_failure";
export const GET_ECONOMIC_SECTORS_INDUSTRIES_SUCCESS =
  "get_economic_sectors_industries_success";

export const GET_INDUSTRIES_METADATA_REQUEST =
  "GET_INDUSTRIES_METADATA_REQUEST";
export const GET_INDUSTRIES_METADATA_FAILURE =
  "GET_INDUSTRIES_METADATA_FAILURE";
export const GET_INDUSTRIES_METADATA_SUCCESS =
  "GET_INDUSTRIES_METADATA_SUCCESS";
