import React, { Component } from "react";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";

class NetStockActionBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  invokeParentMethod() {
    const { data } = this.props;
    return (
      <div
        style={{
          "line-height": "60px"
        }}
      >
        <span
          span
          onClick={() => this.performAction(data.id, 0)}
          title="View Move Stock Entry"
        >
          <ViewHeadlineIcon />
        </span>
      </div>
    );
  }

  performAction(id, value) {
    switch (value) {
      case 0:
        this.props.context.componentParent.openStockMoveDetailModel(id);
        break;
    }
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default NetStockActionBtn;
