// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const createTagType = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("api/tagstype/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTagTypeList = async () => {
  try {
    const response = await axios.get("/api/tagstype/getall/");
    return response.data;
  } catch (error) {
    throw error;
  }
};
