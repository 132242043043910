import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  list: null,
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  isSaving: false,
  isDeleted: false,
  isMapSaved: false
};

const tagsTypeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_TAGSTYPE_REQUEST:
      return {
        ...state,
        list: null,
        loadingData: true,
        error: null,
        isSaved: false
      };
    case actionTypes.GET_TAGSTYPE_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false
      };
    case actionTypes.GET_TAGSTYPE_SUCCESS:
      return {
        ...state,
        loadingData: false,
        error: null,
        list: action.payload,
        isSaved: false
      };
    case actionTypes.CREATE_TAGSTYPE_REQUEST:
      return {
        ...state,
        loadingData: true,
        error: null,
        isSaved: false
      };
    case actionTypes.CREATE_TAGSTYPE_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false
      };
    case actionTypes.CREATE_TAGSTYPE_SUCCESS:
      var data = state.list;
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data[index] = action.payload;
      } else {
        data.push(action.payload);
      }
      return {
        ...state,
        loading: false,
        error: null,
        list: data,
        isSaved: true,
        isMapSaved: true
      };
    case actionTypes.DELETE_INDUSTRIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        isDeleted: false
      };
    case actionTypes.DELETE_INDUSTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        isDeleted: false
      };
    case actionTypes.DELETE_INDUSTRIES_SUCCESS:
      var data = state.list;
      let segmentIndex = data.findIndex(o => o.id === action.class_id);
      data.splice(segmentIndex, 1);
      return {
        ...state,
        loading: false,
        error: null,
        list: data,
        isSaved: false,
        isDeleted: true
      };
    default:
      return state;
  }
};

export default tagsTypeReducer;
