import * as actionTypes from "./actionTypes";
import * as customPageService from "../../services/customPageService";

export const createPageAction = (
  userid,
  partnerid,
  data,
  files
) => async dispatch => {
  dispatch(createPageRequest());
  try {
    const success = await customPageService.createPage(
      userid,
      partnerid,
      data,
      files
    );
    if (success.status === "1") {
      dispatch(createPageSuccess(success.data));
    } else {
      dispatch(createPageFailure(success.error));
    }
  } catch (error) {
    dispatch(createPageFailure(error.message));
  }
};

const createPageRequest = () => {
  return {
    type: actionTypes.CREATE_PAGE_REQUEST
  };
};

const createPageSuccess = data => {
  return {
    type: actionTypes.CREATE_PAGE_SUCCESS,
    payload: data
  };
};

const createPageFailure = error => {
  return {
    type: actionTypes.CREATE_PAGE_FAILURE,
    payload: error
  };
};

export const getPageListAction = partnerid => async dispatch => {
  dispatch(getPageListRequest());
  try {
    var success = await customPageService.getPageList(partnerid);
    if (success.status && success.status === "1") {
      dispatch(getPageListSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getPageListFailure(success.error));
    } else {
      dispatch(getPageListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getPageListFailure(error.message));
  }
};

const getPageListRequest = () => {
  return {
    type: actionTypes.GET_PAGE_LIST_REQUEST
  };
};

const getPageListSuccess = data => {
  return {
    type: actionTypes.GET_PAGE_LIST_SUCCESS,
    payload: data
  };
};

const getPageListFailure = error => {
  return {
    type: actionTypes.GET_PAGE_LIST_FAILURE,
    payload: error
  };
};

export const getPageByIdAction = partnerid => async dispatch => {
  dispatch(getPageByIdRequest());
  try {
    var success = await customPageService.getPageById(partnerid);
    if (success.status && success.status === "1") {
      dispatch(getPageByIdSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getPageByIdFailure(success.error));
    } else {
      dispatch(getPageByIdFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getPageByIdFailure(error.message));
  }
};

const getPageByIdRequest = () => {
  return {
    type: actionTypes.GET_PAGE_BYID_REQUEST
  };
};

const getPageByIdSuccess = data => {
  return {
    type: actionTypes.GET_PAGE_BYID_SUCCESS,
    payload: data
  };
};

const getPageByIdFailure = error => {
  return {
    type: actionTypes.GET_PAGE_BYID_FAILURE,
    payload: error
  };
};
