import * as dashboardServices from "../../services/dashboardServices";
import * as actionTypes from "./actionTypes";

export const getDashboardDataAction = partnerid => async dispatch => {
  dispatch(dashboardDataRequest());
  try {
    let successnew = await dashboardServices.getDashboardData(partnerid);
    if (successnew.status && successnew.status === "1") {
      dispatch(dashboardDataSuccess(successnew.data));
    } else {
      dispatch(dashboardDataFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(dashboardDataFailure(error.message));
  }
};

const dashboardDataRequest = () => {
  return {
    type: actionTypes.GET_DASHBOARD_DATA_REQUEST
  };
};

const dashboardDataSuccess = data => {
  return {
    type: actionTypes.GET_DASHBOARD_DATA_SUCCESS,
    payload: data
  };
};

const dashboardDataFailure = error => {
  return {
    type: actionTypes.GET_DASHBOARD_DATA_FAILURE,
    payload: error
  };
};
