import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  grid: {
    marginRight: "-15px",
    marginLeft: "-15px",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "0px",
      marginLeft: "0px"
    }
  }
}));

function GridContainer({ ...props }) {
  const { classes, children, className, ...rest } = props;
  const cls = useStyles();
  return (
    <Grid container {...rest} className={cls.grid + " " + className}>
      {children}
    </Grid>
  );
}

GridContainer.defaultProps = {
  className: ""
};

GridContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  className: PropTypes.string
};

export default GridContainer;
