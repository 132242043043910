import React, { Component } from "react";
import Edit from "@material-ui/icons/Edit";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import moment from "moment";
function dateFormatter(params) {
  if (params) {
    return moment(params).format("MM/DD/YYYY");
  } else {
    return "";
  }
}
class AddressActionButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  invokeParentMethod() {
    const { data } = this.props;
    const { is_Stock } = this.props.context.componentParent.props;
    return (
      <div
        style={{
          lineHeight: "60px"
        }}
      >
        {
          <span
            title="View or edit mapping with Attribute Group"
            onClick={() => this.handelAdd(this.props.rowIndex)}
          >
            <AccessTimeIcon className="grid_action_btn" />
          </span>
        }
        {
          <span
            onClick={() => this.performAction(this.props.rowIndex, 1)}
            title="Edit"
          >
            <Edit className="grid_action_btn" />
          </span>
        }
      </div>
    );
  }

  handelAdd(index) {
    this.props.context.componentParent.handelAdd(index);
  }

  performAction(id, value) {
    switch (value) {
      case 0:
        this.props.context.componentParent.openClassComponentDialog(id);
        break;
      case 1:
        this.props.context.componentParent.handelEdit(id);
        break;
      case 2:
        this.props.context.componentParent.map(id);
        break;
      case 3:
        this.props.context.componentParent.view(id);
        break;
    }
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default AddressActionButton;
