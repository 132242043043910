import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";

import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import Table from "components/Table/Table.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { validationUtils } from "../../utils";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

class SegmentProductDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      name: "",
      image: "",
      segmentName: "",
      file: null,
      tableData: [],
      required: {
        name: "",
        segmentName: ""
      }
    };
  }

  componentDidMount() {
    if (
      this.props.List &&
      this.props.List.length > 0 &&
      this.props.List[0]["segmentProductDetails"]
    ) {
      const modelTypeData = this.props.List[0]["segmentProductDetails"];
      var result = Object.keys(modelTypeData).map(function(key) {
        return modelTypeData[key];
      });
      this.setState(() => ({
        tableData: result
      }));
    } else {
      this.props.actions.getRetailer(this.props.UserKey);
    }
    if (
      this.props.List &&
      this.props.List.length > 0 &&
      this.props.List[0]["segmentsDetailsList"]
    ) {
      const segments = this.props.List[0]["segmentsDetailsList"];
      var resultsegments = Object.keys(segments).map(function(key) {
        return segments[key];
      });
      this.setState({
        segments: resultsegments
      });
    }
  }

  saveReatiler = () => {
    var reqData = this.state["required"];
    var isValid = true;
    if (this.state.name.trim() === "" || reqData["name"] === "error") {
      reqData["name"] = "error";
      isValid = false;
    }
    if (
      this.state.segmentName.trim() === "" ||
      reqData["segmentName"] === "error"
    ) {
      reqData["segmentName"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    if (isValid) {
      const savingData = this.state.tableData;
      const { name, segmentName, imagePreviewUrl, file } = this.state;
      var result = Object.keys(savingData).map(function(key) {
        return savingData[key];
      });
      var insertData = {
        name: name,
        segmentName: segmentName
      };
      if (imagePreviewUrl) {
        insertData["imagePreviewUrl"] = imagePreviewUrl;
        insertData["file"] = file;
      }
      result.push(insertData);
      var data = {
        segmentProductDetails: result
      };
      this.props.actions.saveRetailer(data, this.props.UserKey);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        nextProps.List[0]["segmentsDetailsList"]
      ) {
        const segments = nextProps.List[0]["segmentsDetailsList"];
        var resultsegments = Object.keys(segments).map(function(key) {
          return segments[key];
        });
        this.setState({
          segments: resultsegments
        });
      }
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        nextProps.List[0]["segmentProductDetails"] &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const modelTypeData = nextProps.List[0]["segmentProductDetails"];
        var result = Object.keys(modelTypeData).map(function(key) {
          return modelTypeData[key];
        });
        this.setState(() => ({
          tableData: result
        }));
      }
      if (nextProps.isSaved) {
        this.saveChanges();
      }
    }
  }

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({
      [key]: event.target.value,
      required: reqData
    });
  };

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      name: "",
      imagePreviewUrl: "",
      file: null,
      required: {
        name: ""
      }
    });
  };

  handelImageRemove = () => {
    var currState = this.state;
    currState["file"] = null;
    delete currState.imagePreviewUrl;
    this.setState({
      currState
    });
  };
  handelImageChange = file => {
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  saveChanges = () => {
    const { isAddNew } = this.state;
    if (isAddNew) {
      this.handelIsAddNew();
    }
  };

  getTableData = () => {
    const { tableData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(tableData, ({ name, segmentName, imagePreviewUrl, image }, index) => {
      returnData.push([
        <div className={classes.imgContainer} style={{ width: "80px" }}>
          <img
            src={image ? image.secure_url : imagePreviewUrl}
            alt="..."
            className={classes.img}
            style={{ height: "70px", width: "auto" }}
          />
        </div>,
        <span>
          {
            //eslint-disable-next-line
                        <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
              {name}
            </a>
          }
        </span>,
        <span>{segmentName}</span>,
        <Button simple className={classes.actionButton}>
          <Close className={classes.icon} />
        </Button>
      ]);
    });
    return returnData;
  };

  renderModelTypeTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.loadingGetData}
              color="success"
              onClick={this.handelIsAddNew}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} /> Segment Product Detail
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Segment Product Details Table
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <Table
                  tableHead={["", "Name", "Segment", ""]}
                  tableData={this.getTableData()}
                  tableShopping
                  customHeadCellClasses={[
                    classes.center,
                    classes.description,
                    classes.description,
                    classes.right,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                  customCellClasses={[
                    classes.tdName,
                    classes.customFont,
                    classes.customFont,
                    classes.tdNumber,
                    classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                    classes.tdNumber
                  ]}
                  customClassesForCells={[1, 2, 3, 4, 5, 6]}
                />
                {(this.props.Loading || this.props.loadingGetData) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.loadingGetData} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderAddModelTypeMember = () => {
    const { classes } = this.props;
    const { name, required, image, segmentName } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="Segment Detail *"
                    >
                      Segment Detail *
                    </InputLabel>
                    <Select
                      style={{ marginTop: "0px" }}
                      value={segmentName}
                      onChange={this.handleTextChange("segmentName", "")}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Segment
                      </MenuItem>
                      {_.map(this.state.segments, ({ name }, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={name}
                            key={index + "_" + name}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <br />
                  <legend>Portrait</legend>
                  <ImageUpload
                    addButtonProps={{
                      round: true
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true
                    }}
                    imgUrl={image ? image.secure_url : null}
                    onimageRemove={this.handelImageRemove}
                    onChange={this.handelImageChange}
                  />
                  <br />
                  <br />
                  <br />
                  <Button color="info" onClick={this.saveReatiler}>
                    Save Changes
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {(this.props.Loading || this.props.loadingGetData) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    return this.state.isAddNew
      ? this.renderAddModelTypeMember()
      : this.renderModelTypeTable();
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.retailerState.list,
  Loading: state.retailerState.loading,
  ListError: state.retailerState.error,
  loadingGetData: state.retailerState.loadingData,
  isSaved: state.retailerState.isSaved,
  UserKey: state.authState.user.Token
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...customInputStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SegmentProductDetailsPage);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(SegmentProductDetailsPage);
