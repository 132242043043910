export const CREATE_TAGSTYPE_REQUEST = "create_tagstype_request";
export const CREATE_TAGSTYPE_FAILURE = "create_tagstype_failure";
export const CREATE_TAGSTYPE_SUCCESS = "create_tagstype_success";

export const GET_TAGSTYPE_REQUEST = "get_tagstype_request";
export const GET_TAGSTYPE_FAILURE = "get_tagstype_failure";
export const GET_TAGSTYPE_SUCCESS = "get_tagstype_success";

export const DELETE_TAGSTYPE_REQUEST = "delete_tagstype_request";
export const DELETE_TAGSTYPE_FAILURE = "delete_tagstype_failure";
export const DELETE_TAGSTYPE_SUCCESS = "delete_tagstype_success";
