import * as productAttributeService from "../../services/productAttributeService";
import * as productsAssignmentsService from "../../services/productsAssignmentsService";
import * as actionTypes from "./actionTypes";

export const saveProductAttributes = (data, UserId) => async dispatch => {
  dispatch(createProductAttributeRequest());
  try {
    const success = await productAttributeService.saveProductAttributes(
      data,
      UserId
    );
    if (success.status && success.status === "1") {
      dispatch(createProductAttributeSuccess(success.data));
    }
  } catch (error) {
    dispatch(createProductAttributeFailure(error.message));
  }
};

export const getProductAttributeAssignmentList = (
  userId,
  islist = null
) => async dispatch => {
  dispatch(getProductAttributeRequest());
  try {
    const success = await productsAssignmentsService.getProductAssignmentList(
      userId,
      islist
    );
    if (success.status && success.status === "1") {
      dispatch(getProductAttributeSuccess(success.data));
    }
  } catch (error) {
    dispatch(getProductAttributeFailure(error.message));
  }
};

export const afterSaveProductAssignment = () => async dispatch => {
  dispatch(afterSaveProductAssignmentSuccess());
};

const afterSaveProductAssignmentSuccess = () => {
  return {
    type: actionTypes.AFTER_SAVE_PRODUCT_ATTRIBUTES_SUCCESS
  };
};

const createProductAttributeSuccess = data => {
  return {
    type: actionTypes.SAVE_PRODUCT_ATTRIBUTES_SUCCESS,
    payload: data
  };
};

const createProductAttributeFailure = error => {
  return {
    type: actionTypes.SAVE_PRODUCT_ATTRIBUTES_FAILURE,
    payload: error
  };
};

const createProductAttributeRequest = () => {
  return {
    type: actionTypes.SAVE_PRODUCT_ATTRIBUTES_REQUEST
  };
};
const getProductAttributeSuccess = data => {
  return {
    type: actionTypes.GET_PRODUCT_ATTRIBUTES_SUCCESS,
    payload: data
  };
};

const getProductAttributeFailure = error => {
  return {
    type: actionTypes.GET_PRODUCT_ATTRIBUTES_FAILURE,
    payload: error
  };
};

const getProductAttributeRequest = () => {
  return {
    type: actionTypes.GET_PRODUCT_ATTRIBUTES_REQUEST
  };
};

export const getAttributesByProductIdAction = userId => async dispatch => {
  dispatch(getAttributesByProductIdRequest());
  try {
    const success = await productsAssignmentsService.getAttributesByProductId(
      userId
    );
    if (success.status && success.status === "1") {
      dispatch(getAttributesByProductIdSuccess(success.data));
    }
  } catch (error) {
    dispatch(getAttributesByProductIdFailure(error.message));
  }
};

const getAttributesByProductIdRequest = () => {
  return {
    type: actionTypes.GET_ATTRIBUTE_BY_PRODUCT_ID_REQUEST
  };
};
const getAttributesByProductIdSuccess = data => {
  return {
    type: actionTypes.GET_ATTRIBUTE_BY_PRODUCT_ID_SUCCESS,
    payload: data
  };
};

const getAttributesByProductIdFailure = error => {
  return {
    type: actionTypes.GET_ATTRIBUTE_BY_PRODUCT_ID_FAILURE,
    payload: error
  };
};
