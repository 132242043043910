export const GET_CLASS_REQUEST = "get_class_request";
export const GET_CLASS_FAILURE = "get_class_failure";
export const GET_CLASS_SUCCESS = "get_class_success";

export const SAVE_CLASS_REQUEST = "save_class_request";
export const SAVE_CLASS_FAILURE = "save_class_failure";
export const SAVE_CLASS_SUCCESS = "save_class_success";

export const DELETE_CLASS_REQUEST = "delete_class_request";
export const DELETE_CLASS_FAILURE = "delete_class_failure";
export const DELETE_CLASS_SUCCESS = "delete_class_success";

export const MAP_CLASS_PT_REQUEST = "map_class_pt_request";
export const MAP_CLASS_PT_FAILURE = "map_class_pt_failure";
export const MAP_CLASS_PT_SUCCESS = "map_class_pt_success";

export const MAP_CLASS_UA_REQUEST = "map_class_UA_request";
export const MAP_CLASS_UA_FAILURE = "map_class_UA_failure";
export const MAP_CLASS_UA_SUCCESS = "map_class_UA_success";

export const AFTER_MAP_CLASS_UA_SUCCESS = "after_map_class_UA_success";

export const SAVE_CATEGORY_REQUEST = "save_category_request";
export const SAVE_CATEGORY_FAILURE = "save_category_failure";
export const SAVE_CATEGORY_SUCCESS = "save_category_success";

export const GET_CLASS_BYID_REQUEST = "get_class_byid_request";
export const GET_CLASS_BYID_FAILURE = "get_class_byid_failure";
export const GET_CLASS_BYID_SUCCESS = "get_class_byid_success";

export const GET_PARTNER_CLASSES_REQUEST = "GET_PARTNER_CLASSES_REQUEST";
export const GET_PARTNER_CLASSES_SUCCESS = "GET_PARTNER_CLASSES_SUCCESS";
export const GET_PARTNER_CLASSES_FAILURE = "GET_PARTNER_CLASSES_FAILURE";