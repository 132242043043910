import * as brandServices from "../../services/brandService";
import * as actionTypes from "./actionTypes";

export const createBrand = brandData => async dispatch => {
  dispatch(createBrandRequest());
  try {
    const success = await brandServices.saveBrandData(brandData);
    if (success.status === "1") {
      dispatch(createBrandSuccess(success.data));
    } else {
      dispatch(createBrandFailure(success.error));
    }
  } catch (error) {
    dispatch(createBrandFailure(error.message));
  }
};

export const getBrand = (role, isList = false) => async dispatch => {
  dispatch(getBrandRequest());
  try {
    var success = await brandServices.getBrandData(role, isList);

    if (success.status && success.status === "1") {
      dispatch(getBrandSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getBrandFailure(success.error));
    } else {
      dispatch(getBrandFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getBrandFailure(error.message));
  }
};

export const getALLBrand = (role, isList = false) => async dispatch => {
  dispatch(getBrandRequest());
  try {
    var success = await brandServices.getBrandData(role, isList);
    if (success.status && success.status === "1") {
      dispatch(getAllBrandSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getBrandFailure(success.error));
    } else {
      dispatch(getBrandFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getBrandFailure(error.message));
  }
};

export const deleteUesr = api_key => async dispatch => {
  dispatch(deleteUserRequest());
  try {
    const success = await brandServices.deleteUesr(api_key);
    dispatch(deleteUserSuccess(success.data, api_key));
  } catch (error) {
    dispatch(deleteUserFailure(error.message));
  }
};

export const saveBrandProductTemplates = (
  userID,
  partnerID,
  data
) => async dispatch => {
  dispatch(saveBrandProductTemplatesRequest());
  try {
    const response = await brandServices.saveBrandProductTemplates(
      userID,
      partnerID,
      data
    );
    if (response.status === "1") {
      dispatch(saveBrandProductTemplatesSuccess(response.data));
    } else {
      dispatch(saveBrandProductTemplatesFailure(response.error));
    }
  } catch (error) {
    dispatch(saveBrandProductTemplatesFailure(error.message));
  }
};

export const getAssignedProductTemplates = data => async dispatch => {
  dispatch(getAssignedProductTemplatesRequest());
  dispatch(SaveOffers());
  try {
    const response = await brandServices.getAssignedProductTemplates(data);
    if (response.status === "1") {
      dispatch(getAssignedProductTemplatesSuccess(response.data));
    } else {
      dispatch(getAssignedProductTemplatesFailure(response.error));
    }
  } catch (error) {
    dispatch(getAssignedProductTemplatesFailure(error.message));
  }
};

export const getUniversalAttributes = () => async dispatch => {
  dispatch(getUniversalAttributeRequest());
  try {
    const response = await brandServices.getUniversalAttributes();
    if (response.status === "1") {
      dispatch(getUniversalAttributeSuccess(response.data));
    } else {
      dispatch(getUniversalAttributeFailure(response.error));
    }
  } catch (error) {
    dispatch(getUniversalAttributeFailure(error.message));
  }
};

export const createUniversalAttributes = (userid, data) => async dispatch => {
  dispatch(createUniversalAttributeRequest());
  try {
    const response = await brandServices.createUniversalAttributes(
      userid,
      data
    );
    if (response.status === "1") {
      dispatch(createUniversalAttributeSuccess(response.data));
    } else {
      dispatch(createUniversalAttributeFailure(response.error));
    }
  } catch (error) {
    dispatch(createUniversalAttributeFailure(error.message));
  }
};

export const getAttributeGroups = (
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getAttributeGroupRequest());
  try {
    const response = await brandServices.getAttributeGroups(page, search);
    if (response.status === "1") {
      dispatch(getAttributeGroupSuccess(response.data));
    } else {
      dispatch(getAttributeGroupFailure(response.error));
    }
  } catch (error) {
    dispatch(getAttributeGroupFailure(error.message));
  }
};

export const createAttributeGroup = (userid, data) => async dispatch => {
  dispatch(createAttributeGroupRequest());
  try {
    const response = await brandServices.createAttributeGroup(userid, data);
    if (response.status === "1") {
      dispatch(createAttributeGroupSuccess(response.data));
    } else {
      dispatch(createAttributeGroupFailure(response.error));
    }
  } catch (error) {
    dispatch(createAttributeGroupFailure(error.message));
  }
};

export const mapWholesalerBrand = (id, mapData, role) => async dispatch => {
  dispatch(createMapWholesalerBrandRequest());
  // dispatch(createBrandRequest())
  try {
    const success = await brandServices.mapWholesalerBrand(id, mapData, role);
    dispatch(createBrandSuccess(success.data));
  } catch (error) {
    dispatch(createMapWholesalerBrandFailure(error.message));
  }
};

export const getAllMapBrandsCategorisedProductTemplates = partnerid => async dispatch => {
  dispatch(getAllMapBrandsCategorisedProductTemplatesRequest());
  try {
    //     dispatch(getAllMapBrandsCategorisedProductTemplatesSuccess(data));
    // const response = await brandServices.getAllMapBrandsCategorisedProductTemplates(partnerid);
    // if (response.status === "1") {
    //     dispatch(getAllMapBrandsCategorisedProductTemplatesSuccess(data));
    // } else {
    //     dispatch(getAllMapBrandsCategorisedProductTemplatesFailure(response.error));
    // }
  } catch (error) {
    dispatch(getAllMapBrandsCategorisedProductTemplatesFailure(error.message));
  }
};

export const getAllMapWholesalerCategorisedProductTemplates = partnerid => async dispatch => {
  dispatch(getAllMapBrandsCategorisedProductTemplatesRequest());
  try {
    const response = await brandServices.getAllMapWholesalerCategorisedProductTemplates(
      partnerid
    );
    if (response.status === "1") {
      dispatch(
        getAllMapBrandsCategorisedProductTemplatesSuccess(response.data)
      );
    } else {
      dispatch(
        getAllMapBrandsCategorisedProductTemplatesFailure(response.error)
      );
    }
  } catch (error) {
    dispatch(getAllMapBrandsCategorisedProductTemplatesFailure(error.message));
  }
};

export const getAllMapWholesalerCategorisedProductTemplatesSelected = partnerid => async dispatch => {
  dispatch(getAllMapBrandsCategorisedProductTemplatesSelectedRequest());
  try {
    const response = await brandServices.getAllMapWholesalerCategorisedProductTemplatesSelected(
      partnerid
    );
    if (response.status === "1") {
      dispatch(
        getAllMapBrandsCategorisedProductTemplatesSelectedSuccess(response.data)
      );
    } else {
      dispatch(
        getAllMapBrandsCategorisedProductTemplatesSelectedFailure(
          response.error
        )
      );
    }
  } catch (error) {
    dispatch(
      getAllMapBrandsCategorisedProductTemplatesSelectedFailure(error.message)
    );
  }
};

export const getAllMapRetailerCategorisedProductTemplates = partnerid => async dispatch => {
  dispatch(getAllMapRBrandsCategorisedProductTemplatesRequest());
  try {
    const response = await brandServices.getAllMapRetailerCategorisedProductTemplates(
      partnerid
    );
    if (response.status === "1") {
      dispatch(
        getAllMapRBrandsCategorisedProductTemplatesSuccess(response.data)
      );
    } else {
      dispatch(
        getAllMapRBrandsCategorisedProductTemplatesFailure(response.error)
      );
    }
  } catch (error) {
    dispatch(getAllMapRBrandsCategorisedProductTemplatesFailure(error.message));
  }
};

export const getAllMapRetailerCategorisedProductTemplatesSelected = (partnerid,source) => async dispatch => {
  dispatch(getAllMapRBrandsCategorisedProductTemplatesSelectedRequest());
  try {
    const response = await brandServices.getAllMapRetailerCategorisedProductTemplatesSelected(
      partnerid,source
    );
    if (response.status === "1") {
      dispatch(
        getAllMapRBrandsCategorisedProductTemplatesSelectedSuccess(
          response.data
        )
      );
    } else {
      dispatch(
        getAllMapRBrandsCategorisedProductTemplatesSelectedFailure(
          response.error
        )
      );
    }
  } catch (error) {
    dispatch(
      getAllMapRBrandsCategorisedProductTemplatesSelectedFailure(error.message)
    );
  }
};

export const mapRetailerBrand = (id, mapData) => async dispatch => {
  dispatch(createMapRetailerBrandRequest());
  try {
    const success = await brandServices.mapRetailerBrand(id, mapData);
    dispatch(createBrandSuccess(success.data));
  } catch (error) {
    dispatch(createMapRetailerBrandFailure(error.message));
  }
};

const createMapRetailerBrandRequest = () => {
  return {
    type: actionTypes.MAP_RETAILER_BRAND_REQUEST
  };
};

const createMapRetailerBrandSuccess = data => {
  return {
    type: actionTypes.MAP_RETAILER_BRAND_SUCCESS,
    payload: data
  };
};

const createMapRetailerBrandFailure = error => {
  return {
    type: actionTypes.MAP_RETAILER_BRAND_FAILURE,
    payload: error
  };
};

const createMapWholesalerBrandRequest = () => {
  return {
    type: actionTypes.MAP_WHOLESALER_BRAND_REQUEST
  };
};

const createMapWholesalerBrandSuccess = data => {
  return {
    type: actionTypes.MAP_WHOLESALER_BRAND_SUCCESS,
    payload: data
  };
};

const createMapWholesalerBrandFailure = error => {
  return {
    type: actionTypes.MAP_WHOLESALER_BRAND_FAILURE,
    payload: error
  };
};

const getAllBrandSuccess = data => {
  return {
    type: actionTypes.SAVE_WHOLESALER_BRAND_SUCCESS,
    payload: data
  };
};

const createBrandRequest = () => {
  return {
    type: actionTypes.CREATE_BRAND_REQUEST
  };
};

const createBrandSuccess = data => {
  return {
    type: actionTypes.CREATE_BRAND_SUCCESS,
    payload: data
  };
};

const createBrandFailure = error => {
  return {
    type: actionTypes.CREATE_BRAND_FAILURE,
    payload: error
  };
};

const getBrandRequest = () => {
  return {
    type: actionTypes.GET_BRAND_REQUEST
  };
};

const getBrandSuccess = data => {
  return {
    type: actionTypes.GET_BRAND_SUCCESS,
    payload: data
  };
};

const getBrandFailure = error => {
  return {
    type: actionTypes.GET_BRAND_FAILURE,
    payload: error
  };
};

const deleteUserRequest = () => {
  return {
    type: actionTypes.DELETE_USER_REQUEST
  };
};

const deleteUserSuccess = (data, api_key) => {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    payload: data,
    user_Id: api_key
  };
};

const deleteUserFailure = error => {
  return {
    type: actionTypes.DELETE_USER_FAILURE,
    payload: error
  };
};

const saveBrandProductTemplatesRequest = () => {
  return {
    type: actionTypes.SAVE_BRAND_PRODUCT_TEMPLATES_REQUEST
  };
};

const saveBrandProductTemplatesSuccess = data => {
  return {
    type: actionTypes.SAVE_BRAND_PRODUCT_TEMPLATES_SUCCESS,
    payload: data
  };
};

const saveBrandProductTemplatesFailure = error => {
  return {
    type: actionTypes.SAVE_BRAND_PRODUCT_TEMPLATES_FAILURE,
    payload: error
  };
};

const getAssignedProductTemplatesRequest = () => {
  return {
    type: actionTypes.GET_BRAND_PRODUCT_TEMPLATES_REQUEST
  };
};

const getAssignedProductTemplatesSuccess = data => {
  return {
    type: actionTypes.GET_BRAND_PRODUCT_TEMPLATES_SUCCESS,
    payload: data
  };
};

const SaveOffers = () => {
  return {
    type: actionTypes.REMOVE_SAVED_DATA
  };
};

const getAssignedProductTemplatesFailure = error => {
  return {
    type: actionTypes.GET_BRAND_PRODUCT_TEMPLATES_FAILURE,
    payload: error
  };
};

const createUniversalAttributeRequest = () => {
  return {
    type: actionTypes.CREATE_UNIVERSAL_ATTRIBUTE_REQUEST
  };
};

const createUniversalAttributeSuccess = data => {
  return {
    type: actionTypes.CREATE_UNIVERSAL_ATTRIBUTE_SUCCESS,
    payload: data
  };
};

const createUniversalAttributeFailure = error => {
  return {
    type: actionTypes.CREATE_UNIVERSAL_ATTRIBUTE_FAILURE,
    payload: error
  };
};

const getUniversalAttributeRequest = () => {
  return {
    type: actionTypes.GET_UNIVERSAL_ATTRIBUTE_REQUEST
  };
};

const getUniversalAttributeSuccess = data => {
  return {
    type: actionTypes.GET_UNIVERSAL_ATTRIBUTE_SUCCESS,
    payload: data
  };
};

const getUniversalAttributeFailure = error => {
  return {
    type: actionTypes.GET_UNIVERSAL_ATTRIBUTE_FAILURE,
    payload: error
  };
};

const createAttributeGroupRequest = () => {
  return {
    type: actionTypes.CREATE_ATTRIBUTE_GROUP_REQUEST
  };
};

const createAttributeGroupSuccess = data => {
  return {
    type: actionTypes.CREATE_ATTRIBUTE_GROUP_SUCCESS,
    payload: data
  };
};

const createAttributeGroupFailure = error => {
  return {
    type: actionTypes.CREATE_ATTRIBUTE_GROUP_FAILURE,
    payload: error
  };
};

const getAttributeGroupRequest = () => {
  return {
    type: actionTypes.GET_ATTRIBUTE_GROUP_REQUEST
  };
};

const getAttributeGroupSuccess = data => {
  return {
    type: actionTypes.GET_ATTRIBUTE_GROUP_SUCCESS,
    payload: data
  };
};

const getAttributeGroupFailure = error => {
  return {
    type: actionTypes.GET_ATTRIBUTE_GROUP_FAILURE,
    payload: error
  };
};

const getAllMapBrandsCategorisedProductTemplatesRequest = () => {
  return {
    type: actionTypes.GET_WHOLESALER_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_REQUEST
  };
};

const getAllMapBrandsCategorisedProductTemplatesSuccess = data => {
  return {
    type:
      actionTypes.GET_WHOLESALER_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS,
    payload: data
  };
};

const getAllMapBrandsCategorisedProductTemplatesFailure = error => {
  return {
    type: actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_FAILURE,
    payload: error
  };
};

const getAllMapRBrandsCategorisedProductTemplatesRequest = () => {
  return {
    type: actionTypes.GET_RBRANDS_CATEGORISED_PRODUCT_TEMPLATE_REQUEST
  };
};

const getAllMapRBrandsCategorisedProductTemplatesSuccess = data => {
  return {
    type: actionTypes.GET_RBRANDS_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS,
    payload: data
  };
};

const getAllMapRBrandsCategorisedProductTemplatesFailure = error => {
  return {
    type: actionTypes.GET_RBRANDS_CATEGORISED_PRODUCT_TEMPLATE_FAILURE,
    payload: error
  };
};

const getAllMapBrandsCategorisedProductTemplatesSelectedRequest = () => {
  return {
    type: actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_REQUEST
  };
};

const getAllMapBrandsCategorisedProductTemplatesSelectedSuccess = data => {
  return {
    type: actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_SUCCESS,
    payload: data
  };
};

const getAllMapBrandsCategorisedProductTemplatesSelectedFailure = error => {
  return {
    type: actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_FAILURE,
    payload: error
  };
};

const getAllMapRBrandsCategorisedProductTemplatesSelectedRequest = () => {
  return {
    type: actionTypes.GET_RETAILER_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_REQUEST
  };
};

const getAllMapRBrandsCategorisedProductTemplatesSelectedSuccess = data => {
  return {
    type:
      actionTypes.GET_RETAILER_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_SUCCESS,
    payload: data
  };
};

const getAllMapRBrandsCategorisedProductTemplatesSelectedFailure = error => {
  return {
    type:
      actionTypes.GET_RETAILER_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_FAILURE,
    payload: error
  };
};

export const SaveAttributeGroupAction = (userid, data) => async dispatch => {
  dispatch(saveAttributeGroupRequest());
  try {
    const response = await brandServices.saveAttributeGroup(userid, data);
    if (response.status === "1") {
      dispatch(saveAttributeGroupSuccess(response.data));
    } else {
      dispatch(saveAttributeGroupFailure(response.error));
    }
  } catch (error) {
    dispatch(saveAttributeGroupFailure(error.message));
  }
};

const saveAttributeGroupRequest = () => {
  return {
    type: actionTypes.SAVE_ATTRIBUTE_GROUP_REQUEST
  };
};

const saveAttributeGroupSuccess = data => {
  return {
    type: actionTypes.SAVE_ATTRIBUTE_GROUP_SUCCESS,
    payload: data
  };
};

const saveAttributeGroupFailure = error => {
  return {
    type: actionTypes.SAVE_ATTRIBUTE_GROUP_FAILURE,
    payload: error
  };
};

export const getRelatedPartnerAction = (role, partnerid) => async dispatch => {
  dispatch(getRelatedPartnerRequest());
  try {
    const response = await brandServices.getRelatedPartner(role, partnerid);
    if (response.status === "1") {
      dispatch(getRelatedPartnerSuccess(response.data));
    } else {
      dispatch(getRelatedPartnerFailure(response.error));
    }
  } catch (error) {
    dispatch(getRelatedPartnerFailure(error.message));
  }
};

const getRelatedPartnerRequest = () => {
  return {
    type: actionTypes.GET_RELATED_PARTNER_REQUEST
  };
};

const getRelatedPartnerSuccess = data => {
  return {
    type: actionTypes.GET_RELATED_PARTNER_SUCCESS,
    payload: data
  };
};

const getRelatedPartnerFailure = error => {
  return {
    type: actionTypes.GET_RELATED_PARTNER_FAILURE,
    payload: error
  };
};

export const getAttributeGroupByIdAction = attributegroupid => async dispatch => {
  dispatch(getAttributeGroupByIdRequest());
  try {
    const response = await brandServices.getAttributeGroupById(
      attributegroupid
    );
    if (response.status === "1") {
      dispatch(getAttributeGroupByIdSuccess(response.data));
    } else {
      dispatch(getAttributeGroupByIdFailure(response.error));
    }
  } catch (error) {
    dispatch(getAttributeGroupByIdFailure(error.message));
  }
};

const getAttributeGroupByIdRequest = () => {
  return {
    type: actionTypes.GET_ATTRIBUTE_GROUP_BYID_REQUEST
  };
};

const getAttributeGroupByIdSuccess = data => {
  return {
    type: actionTypes.GET_ATTRIBUTE_GROUP_BYID_SUCCESS,
    payload: data
  };
};

const getAttributeGroupByIdFailure = error => {
  return {
    type: actionTypes.GET_ATTRIBUTE_GROUP_BYID_FAILURE,
    payload: error
  };
};

export const deleteAttributeByIdAction = attributeid => async dispatch => {
  dispatch(deleteAttributeByIdRequest());
  try {
    const response = await brandServices.deleteAttributeById(attributeid);
    if (response.status === "1") {
      dispatch(deleteAttributeByIdSuccess(response.data));
    } else {
      dispatch(deleteAttributeByIdFailure(response.error));
    }
  } catch (error) {
    dispatch(deleteAttributeByIdFailure(error.message));
  }
};

const deleteAttributeByIdRequest = () => {
  return {
    type: actionTypes.DELETE_ATTRIBUTE_REQUEST
  };
};

const deleteAttributeByIdSuccess = data => {
  return {
    type: actionTypes.DELETE_ATTRIBUTE_SUCCESS,
    payload: data
  };
};

const deleteAttributeByIdFailure = error => {
  return {
    type: actionTypes.DELETE_ATTRIBUTE_FAILURE,
    payload: error
  };
};
