import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  get_CMS_Content: {
    list: [],
    loading: true,
    error: null
  },
  create_CMS_Content: {
    saving: false,
    error: null,
    isSaved: false,
    loading: false
  },
  cms_menu: {
    list: [],
    loading: true,
    error: null
  },
  add_cms_menu: {
    isSaved: false,
    loading: false,
    error: null,
    msg: ""
  }
};

const menuMasterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CREATE_CONTENT_REQUEST:
      return {
        ...state,
        create_CMS_Content: {
          saving: true,
          error: null,
          isSaved: false,
          loading: true
        }
      };

    case actionTypes.CREATE_CONTENT_FAILURE:
      return {
        ...state,
        create_CMS_Content: {
          saving: false,
          error: action.payload,
          isSaved: false,
          loading: false
        }
      };

    case actionTypes.CREATE_CONTENT_SUCCESS:
      return {
        ...state,
        create_CMS_Content: {
          saving: false,
          error: null,
          isSaved: true,
          loading: false,
          successmsg: action.payload.data
        },
        get_CMS_Content: {
          // list: {
          //     id: action.payload.id
          // }
          list: action.payload.id
        }
      };

    case actionTypes.GET_CONTENT_REQUEST:
      return {
        ...state,
        get_CMS_Content: {
          saving: false,
          error: null,
          isSaved: false
        },
        get_menu_master: {
          list: [],
          loading: true,
          error: null
        }
      };
    case actionTypes.GET_CONTENT_FAILURE:
      return {
        ...state,
        get_CMS_Content: {
          list: [],
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_CONTENT_SUCCESS:
      return {
        ...state,
        get_CMS_Content: {
          ...state.get_CMS_Content,
          list: action.payload,
          loading: false,
          error: null,
          isSaved: false
        },
        create_CMS_Content: {
          ...state.create_CMS_Content,
          isSaved: false
        }
      };

    case actionTypes.GET_CMS_MENU_REQUEST:
      return {
        ...state,
        get_CMS_Content: {
          saving: false,
          error: null,
          isSaved: false
        },
        cms_menu: {
          list: [],
          loading: true,
          error: null
        },
        add_cms_menu: {
          isSaved: false,
          loading: false,
          error: null,
          msg: ""
        }
      };
    case actionTypes.GET_CMS_MENU_FAILURE:
      return {
        ...state,
        cms_menu: {
          list: [],
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_CMS_MENU_SUCCESS:
      return {
        ...state,
        cms_menu: {
          list: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.SAVE_CMS_MENU_REQUEST:
      return {
        ...state,

        add_cms_menu: {
          isSaved: false,
          loading: true,
          error: null,
          msg: ""
        }
      };
    case actionTypes.SAVE_CMS_MENU_FAILURE:
      return {
        ...state,
        add_cms_menu: {
          isSaved: false,
          loading: false,
          error: action.payload,
          msg: ""
        }
      };
    case actionTypes.SAVE_CMS_MENU_SUCCESS:
      return {
        ...state,
        add_cms_menu: {
          isSaved: true,
          loading: false,
          error: null,
          msg: action.payload
        },
        cms_menu: {
          list: action.payload,
          loading: false,
          error: null
        }
      };

    default:
      return state;
  }
};

export default menuMasterReducer;
