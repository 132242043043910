export const CREATE_PRODUCT_TYPE_REQUEST = "create_PRODUCT_TYPE_request";
export const CREATE_PRODUCT_TYPE_FAILURE = "create_PRODUCT_TYPE_failure";
export const CREATE_PRODUCT_TYPE_SUCCESS = "create_PRODUCT_TYPE_success";
export const CREATE_PRODUCT_TYPE_RESETSTATE = "create_PRODUCT_TYPE_resetstate";

export const GET_PRODUCT_TYPE_REQUEST = "get_PRODUCT_TYPE_request";
export const GET_PRODUCT_TYPE_FAILURE = "get_PRODUCT_TYPE_failure";
export const GET_PRODUCT_TYPE_SUCCESS = "get_PRODUCT_TYPE_success";

export const DELETE_PRODUCT_TYPE_REQUEST = "delete_PRODUCT_TYPE_request";
export const DELETE_PRODUCT_TYPE_FAILURE = "delete_PRODUCT_TYPE_failure";
export const DELETE_PRODUCT_TYPE_SUCCESS = "delete_PRODUCT_TYPE_success";
