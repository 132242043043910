// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const createShipping = async (userId, Savedata, partnerId) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    data.append("partnerid", partnerId);
    const response = await axios.post("api/retailer/shipping/add", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getShippingList = async () => {
  try {
    const response = await axios.get("/api/retailer/shipping/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};
