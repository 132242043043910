import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import basicsStyle from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import ProductTemplateWizard from "./ProductTemplateWizard.jsx";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import AdminProductTemplateAction from "../../components/AgGrid/AdminProductTemplateAction";


const productInitialState = {
  id: "",
  name: "",
  description: "",
  disclaimer: "",
  is_consumable: false,
  attributes: [],
  segments: [],
  categories: [],
  classes: [],
  offers: [],
  brands: [],
  attribute_groups: [],
  mrp: "",
  msrp: 0,
  producttype: "",
  variantValues: [],
  is_template: true,
  discount: "",
  editorState: "",
  loading: false
};

class ProductTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false
      },
      loading: false,
      addProductTemplate: {
        ...productInitialState
      },
      isAddNew: false,
      isEdit: false,
      productTemplates: [],
      ProductTypeData: [],
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 750,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        // {
        //     headerName: "Description",
        //     field: "description",
        //     width: 450,
        //     resizable: true,
        //     sortable: true,
        //     filter: false
        // },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ]
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentDidMount() {
    this.props.actions.getProductTemplateListAction(this.state.pagination.page);
    // this.props.actions.getAllProductTemplates(this.props.PartnerId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.productTemplates &&
        !_.isEqual(this.props.productTemplates, nextProps.productTemplates)
      ) {
        this.setData(nextProps.productTemplates, nextProps.pagination);
        // this.setState({
        //     productTemplates: nextProps.productTemplates
        // });
      }
      if (nextProps.producttemplatebyid) {
        this.setState({
          addProductTemplate: nextProps.producttemplatebyid
        });
      }

      let loading = false;
      if (nextProps.Loading) {
        loading = nextProps.Loading;
      }

      if (nextProps.ProductTemplateByIdLoader) {
        loading = nextProps.ProductTemplateByIdLoader;
      }

      this.setState({
        loading: loading
      });

      if (
        nextProps.ProductTemplateById &&
        !_.isEqual(
          this.props.ProductTemplateById,
          nextProps.ProductTemplateById
        )
      ) {
        let product = nextProps.ProductTemplateById;
        let data = {};
        data["id"] = product.id;
        data["name"] = product.name;
        data["description"] = product.description;
        data["mrp"] = product.msrp;
        data["brands"] = product.brands;
        if (product.is_consumable == 1) {
          product.is_consumable = true;
        } else {
          product.is_consumable = false;
        }
        data["is_consumable"] = product.is_consumable;
        data["is_template"] = product.is_template;
        data["attribute_groups"] = product.attribute_groups;
        data["partner_id"] = nextProps.partnerId;
        data["offers"] = product.offers;
        data["categories"] = product.categories;
        data["classes"] = product.classes;
        data["attributes"] = product.attributes;
        data["segments"] = product.segments;
        data["producttype"] = product.producttype ? product.producttype.id : "";
        data["variantValues"] = product.variantValues;
        this.setState({
          isAddNew: true,
          isEdit: true,
          addProductTemplate: data
        });
      }
    }
  }

  setData(data, pagination) {
    // this.setState({
    //     productTemplates: nextProps.productTemplates
    // });

    this.setState(() => ({
      productTemplates: data,
      pagination: {
        ...pagination
      }
    }));
  }

  render() {
    return this.state.isAddNew ? (
      <ProductTemplateWizard
        data={this.state.addProductTemplate}
        isEdit={this.state.isEdit}
        close={this.handleCloseNew}
      />
    ) : (
      this.renderProductsTable()
    );
  }

  renderProductsTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem lg={12} />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Product Templates
                </p>
                {/* <p>
                                    <Button
                                        disabled={this.state.loading}
                                        color="success"
                                        onClick={this.handelIsAddNew}
                                        className={classes.marginRight + " grid-header-button"}>
                                        <AddIcon className="grid-header-button-icon" />New
                                    </Button>

                                </p> */}
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.productTemplates}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                loading={this.state.loading}
                AgGridActionButton={AdminProductTemplateAction}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                search={this.searchText}
                new={this.handelIsAddNew}
                isAddBtn={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getProductTemplateListAction(
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getProductTemplateListAction(page, param);
      }
    );
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew
    });
  };

  handelEdit = templateid => {
    this.props.actions.getProductTemplateByIdAction(templateid);
  };

  handleCloseNew = () => {
    this.setState(
      {
        addProductTemplate: {
          ...productInitialState,
          categories: [],
          classes: [],
          segments: [],
          partner_id: this.props.partner_id
        },
        isAddNew: false,
        isEdit: false
      },
      () => {
        this.props.actions.getProductTemplateListAction(
          this.state.pagination.page
        );
        // this.props.actions.getAllProductTemplates(this.props.PartnerId);
      }
    );
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  User: state.authState.user.pk,
  PartnerId: state.authState.user.partner_id,
  UserId: state.authState.user.pk,
  ProductTemplateById: state.brandState.productTemplateById.data,
  ProductTemplateByIdLoader: state.brandState.productTemplateById.loading,
  productTemplates: state.productsAssignmentsState.product_template.data,
  pagination: state.productsAssignmentsState.product_template.pagination,
  Loading: state.productsAssignmentsState.product_template.loading
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.ADMIN || rolesNames.RoleMaster.BRAND;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...dashboardStyle,
    ...userProfileStyles,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductTemplate);
