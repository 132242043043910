// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const createUserGroup = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("/api/user/usergroup/save/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserGroupList = async (id, page, search) => {
  try {
    let url = "/api/user/usergroups/getall/?partnerid=" + id + "&page=" + page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveUserGroupMenuMapping = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    console.log(Savedata);
    const response = await axios.post(
      "/api/user/usergroup-menu-mapping/save/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserGroupMenuMappingList = async id => {
  try {
    const response = await axios.get(
      "/api/user/usergroup-menu-mapping/get/?groupid=" + id
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
