import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import cx from "classnames";
import { Divider } from "@material-ui/core";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import * as routesNames from "../../constants/routes";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import Slide from "@material-ui/core/Slide";
import ChangePassword from "../UserAuth/ChangePassword.jsx";
import RetailerSettlement from "./retailer-settlement";

import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import { validationUtils } from "../../utils";
import MenuComponent from "../components/MenuComponent";
import CloneSiteComponent from "./Component/CloneSiteComponent";
import partnerAgGridActionsButton from "./partnerAgGridActionsButton";

import ImageUpload from "../components/ImageUpload";
import CMSPages from "./Component/CMSPages.jsx";
import Address from "./Component/Address";

import MenuListGrid from "components/MenuList/menu-list-grid";
import IndustryPages from "components/IndustryPages/industry-pages-grid";
import CMSComponents from "components/CMSComponentCards/cms-component-card";
import MetadataHeader from "components/CMSHeader/cms-header";
import MetadataFooter from "components/CMSFooter/cms-footer";
import LanguageListGrid from "components/LangaugeList/language-list-grid";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const roles = [
  {
    name: "Retailer",
    key: "RE"
  },
  {
    name: "Brand",
    key: "BR"
  },
  {
    name: "Wholesaler",
    key: "WS"
  }
];

const rols_title = {
  BR: "Brand",
  WS: "wholesaler",
  RE: "Retailer"
};
const role_color = {
  BR: "#4caf50",
  WS: "#948d8d",
  RE: "'#00acc1''"
};
function getRole(role) {
  if (role == "RE") {
    return "Retailer";
  } else if (role == "BR") {
    return "Brand";
  } else if (role == "WS") {
    return "WholeSaler";
  }
}

const wizardHeaders = [
  "Partners Detail",
  "Languages",
  "Portal Menu",
  "CMS Pages",
  "Components",
  "Header",
  "Footer",
  "Address & Hours"
];

const paginationInitialState = {
  total_page: 8,
  current_page: 1
};

const Style = {
  pagination: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#666666",
    float: "right"
  },
  questionHead: {
    fontWeight: "500"
  }
};

const statusList = [
  { key: 0, name: "Partner Record Created" },
  { key: 1, name: "Hero Assigned" },
  { key: 2, name: "Notified" },
  { key: 3, name: "Approved" },
  { key: 4, name: "CREATE DNS" },
  { key: 5, name: "DNS CREATED" },
  { key: 6, name: "DNS CREATION ERROR" }
];

const partnerStatus = {
  0: "Created",
  1: "Hero Assigned",
  2: "Notified",
  3: "Approved",
  4: "CREATE DNS",
  5: "DNS CREATED",
  6: "DNS CREATION ERROR"
};

class CreateWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.signinUser = this.signinUser.bind(this);
    this.handelSettlementOpen = this.handelSettlementOpen.bind(this);
    this.clickPreviousBtn = this.clickPreviousBtn.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handelImageChange = this.handelImageChange.bind(this);
    this.handelImageRemove = this.handelImageRemove.bind(this);
    this.handelAssignCMSPages = this.handelAssignCMSPages.bind(this);
    this.closeisAssignCMSPagesDialog = this.closeisAssignCMSPagesDialog.bind(
      this
    );
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
    this.state = {
      searchtext: null,
      isSaveClose: false,
      aggrid_pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      isActiveConfirmation: false,
      isActiveCheck: false,
      businessNameError: null,
      files: [],
      isAddNew: false,
      isEdit: false,
      editIndex: -1,
      editParnterData: {},
      entityData: null,
      selectedAPIKey: null,
      partnerId: null,
      menu: [],
      changepasswordmodal: false,
      settlementModel: false,
      isAssignCMSPages: false,
      website: {
        partnerNumber: "",
        businessName: "",
        contactFirstName: "",
        contactLastName: "",
        contactPhone: "",
        contactEmail: "",
        businessURL: "",
        userName: "",
        tags: [],
        status: 0,
        businessTypeId: null,
        industryId: [],
        metadataId: "",
        role: "RE",
        image: "",
        file: null,
        businessURL: "",
        addressList: [],
        is_active: false,
        slug: "",
        languages: [],
        headers: [],
        footers: [],
        pages: [],
        menu: [],
        components: []
      },
      languages: [],
      headers: [],
      footers: [],
      pages: [],
      menu: [],
      components: [],
      required: {
        partnerNumber: "",
        businessName: "",
        contactFirstName: "",
        contactLastName: "",
        contactPhone: "",
        contactEmail: "",
        businessURL: "",
        userName: "",
        role: ""
      },
      isMenuMappingOpen: false,
      tableData: [],
      error: "",
      data: [0, 1],
      tagList: [],
      businessTypeList: [],
      businessIndustryList: [],
      columnDefs: [
        {
          headerName: "",
          field: "image",
          width: 80,
          resizable: true,
          sortable: true,
          cellRenderer: function (params) {
            if (params.value) {
              return (
                '<img src="' +
                params.value +
                '" style="vertical-align: bottom;" width="100%" height="100%">'
              );
            }
          },
          filter: false
        },
        {
          headerName: "Type",
          field: "role",
          width: 85,
          resizable: true,
          sortable: true,
          filter: false,
          cellRenderer: function (params) {
            return (
              '<span class="partner-role" style=background:' +
              role_color[params.value] +
              ">" +
              getRole(params.value) +
              "</span>"
            );
          }
        },
        {
          headerName: "Name",
          field: "name",
          width: 180,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Website",
          field: "deploy_url",
          width: 180,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Test URL",
          field: "test_deploy_url",
          width: 180,
          resizable: true,
          sortable: true,
          hide: true,
          filter: "agTextColumnFilter",
          filterParams: {
            defaultToNothingSelected: true
          }
        },
        {
          headerName: "Status",
          field: "is_active",
          width: 85,
          resizable: true,
          sortable: true,
          filter: "agSetColumnFilter",
          cellRenderer: function (params) {
            if (params && params.value) {
              return '<span class="partner-role" style=background:#4caf50>Active</span>';
            } else {
              return '<span class="partner-role" style=background:#948d8d>Inactive</span>';
            }
          }
        },

        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 230,
          filter: false
        }
      ],
      pagination: {
        ...paginationInitialState
      },
      selectedTab: 0,
      partner_api_key: null,
      clone_site: {
        partnerId: ""
      },
      metaDataList: []
    };
  }

  componentDidMount() { }

  componentWillMount() {
    this.props.actions.getTagsList(this.props.UserId);
    this.props.actions.getBusinessType(this.props.UserId);

    if (this.props.authUser.role !== "AD") {
      let partnerid = this.props.authUser.partner_id;
      if (partnerid) {
        this.handelEdit(partnerid);
      }
    } else {
      this.props.actions.getPartners(this.state.aggrid_pagination.page);
    }
  }

  clickPreviousBtn() {
    let { current_page } = this.state.pagination;
    let new_page = current_page - 1;
    let selected_tab = this.state.selectedTab - 1;
    if (new_page >= 1) {
      this.setState({
        ...this.state,
        selectedTab: selected_tab,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    }
  }

  clickNextBtn() {
    let { current_page } = this.state.pagination;
    let new_page = current_page + 1;
    let selected_tab = this.state.selectedTab + 1;
    if (new_page >= 1) {
      this.setState({
        ...this.state,
        selectedTab: selected_tab,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    }
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;

    if (
      !this.state.website.hasOwnProperty("businessName") ||
      (this.state.website.businessName.trim() === "" ||
        reqData["businessName"] === "error")
    ) {
      reqData["businessName"] = "error";
      isValid = false;
    }

    if (
      !this.state.website.hasOwnProperty("contactFirstName") ||
      (this.state.website.contactFirstName.trim() === "" ||
        reqData["contactFirstName"] === "error")
    ) {
      reqData["contactFirstName"] = "error";
      isValid = false;
    }

    if (
      !this.state.website.hasOwnProperty("contactLastName") ||
      (this.state.website.contactLastName.trim() === "" ||
        reqData["contactLastName"] === "error")
    ) {
      reqData["contactLastName"] = "error";
      isValid = false;
    }

    if (
      !this.state.website.hasOwnProperty("contactPhone") ||
      (this.state.website.contactPhone.trim() === "" ||
        reqData["contactPhone"] === "error")
    ) {
      reqData["contactPhone"] = "error";
      isValid = false;
    }

    if (
      !this.state.website.hasOwnProperty("contactEmail") ||
      (this.state.website.contactEmail.trim() === "" ||
        reqData["contactEmail"] === "error")
    ) {
      reqData["contactEmail"] = "error";
      isValid = false;
    }

    if (
      !this.state.website.hasOwnProperty("businessURL") ||
      (this.state.website.businessURL.trim() === "" ||
        reqData["businessURL"] === "error")
    ) {
      reqData["businessURL"] = "error";
      isValid = false;
    }

    if (
      !this.state.website.hasOwnProperty("userName") ||
      (this.state.website.userName.trim() === "" ||
        reqData["userName"] === "error")
    ) {
      reqData["userName"] = "error";
      isValid = false;
    }
    if (
      !this.state.website.hasOwnProperty("industryId") ||
      reqData["industryId"] === "error"
    ) {
      reqData["industryId"] = "error";
    }
    if (
      !this.state.website.hasOwnProperty("businessTypeId") ||
      reqData["businessTypeId"] === "error"
    ) {
      reqData["businessTypeId"] = "error";
    }

    let industry_found = 0;
    let economic_sector_found = 0;
    if (this.state.website.businessTypeId) {
      economic_sector_found = 1;
    }
    if (this.state.website.industryId) {
      industry_found = 1;
    }
    if (industry_found === 0) {
      isValid = false;
    }
    if (economic_sector_found === 0) {
      isValid = false;
    }

    this.setState({ required: reqData });
    return isValid;
  }

  setPaginationValues() {
    let totalpagecount = wizardHeaders.length;
    this.setState({
      pagination: {
        ...this.state.pagination,
        total_page: totalpagecount,
        current_page: 1
      },
      selectedTab: 0
    });
  }

  handelImageChange = file => {
    if (file instanceof Blob) {
      let { website, files } = this.state;
      let original_name = file.name;
      let fileSize = file.size;
      website["image"] = file;
      website["file_size"] = fileSize;
      website["original_file_name"] = original_name;
      files.push(file);
      this.setState({
        website: website,
        files: files
      });
    } else {
      let { website } = this.state;
      website.image = file.url;
      if (file.is_asset) {
        website.asset_id = file.id;
      }
      website.file_size = file.file_size;
      website.original_file_name = file.original_file_name;
      this.setState({
        ...this.state,
        website: website
      });
    }
  };

  handelImageRemove = () => {
    let { image, original_file_name } = this.state.website;
    let { files } = this.state;

    if (image instanceof Blob || typeof image == "string") {
      let index = files.findIndex(x => x.name == original_file_name);
      files.splice(index, 1);
      this.setState({
        website: {
          ...this.state.website,
          image: null
        },
        files: files
      });
    } else if (image instanceof Object) {
      this.props.actions.removeImageAction(image.id);
    }
  };

  isValidForm = () => {
    return this.validateIsRequired();
  };

  saveClose = () => {
    this.setState({
      isSaveClose: true
    }, this.saveReatiler())
  }

  saveReatiler = () => {
    if (this.validateIsRequired()) {
      var website = this.state.website;
      const { selectedTab, partner_api_key, files } = this.state;
      website.title = website.businessName;
      var contact = [
        {
          contactName: website.contactFirstName + " " + website.contactLastName,
          contactPhone: website.contactPhone
        }
      ];
      website["contacts"] = contact;
      this.props.actions.savePartner(
        website,
        selectedTab,
        partner_api_key,
        files
      );
    }
  };

  handleMultiple = event => {
    // this.state.website.tags = event.target.value
    this.setState({
      website: {
        ...this.state.website,
        tags: event.target.value
      }
    });
  };

  handleStatus = event => {
    this.setState({
      website: {
        ...this.state.website,
        status: event.target.value
      }
    });
  };

  updateAndClose = () => {
    this.setState({
      isSaveClose: true
    }, this.updateWebsite())
  }

  updateWebsite = () => {
    if (this.state.selectedTab === 1 && this.props.authUser.role !== "AD") {
      this.props.history.push("/dashboard");
      return;
    }

    if (this.validateIsRequired()) {
      const {
        website,
        editIndex,
        selectedTab,
        partner_api_key,
        editParnterData,
        files
      } = this.state;
      var savingData = {};
      savingData = _.cloneDeep(editParnterData);

      if (savingData) {
        savingData.data.partnerNumber = website.partnerNumber;
        savingData.data.businessName = website.businessName;
        savingData.data.contactFirstName = website.contactFirstName;
        savingData.data.contactLastName = website.contactLastName;
        savingData.data.contactPhone = website.contactPhone;
        savingData.data.contactEmail = website.contactEmail;
        savingData.data.businessURL = website.businessURL;
        savingData.data.userName = website.userName;
        savingData.data.title = website.businessName;
        savingData.data.tags = website.tags;
        savingData.data.status = website.status;
        savingData.data.businessTypeId = website.businessTypeId;
        savingData.data.image = website.image;
        savingData.data.addressList = website.addressList;
        savingData.data.is_active = website.is_active;
        savingData.data.slug = website.slug;
        savingData.data.metadata_id = website.metadataId;
        savingData.data.headers = website.headers;
        savingData.data.footers = website.footers;
        savingData.data.pages = website.pages;
        savingData.data.menus = website.menus;
        savingData.data.components = website.components;
        savingData.data.languages = website.languages;
        var contact = [
          {
            contactName:
              website.contactFirstName + " " + website.contactLastName,
            contactPhone: website.contactPhone
          }
        ];
        savingData.data["contacts"] = contact;
      } 
      this.props.actions.updatePartner(
        savingData.data,
        website.partnerNumber,
        website,
        selectedTab,
        partner_api_key,
        files
      );
    }
  };

  componentWillReceiveProps(nextProps) {
     
    if (nextProps) {
      if (nextProps.List && !_.isEqual(this.props.List, nextProps.List)) {
        this.setPartnerList(nextProps.List, nextProps.pagination);
      }
      if (nextProps.businessIndustryList) {
        this.setState({
          businessIndustryList: nextProps.businessIndustryList
        });
      }

      if (nextProps.meta_data_list) {
        this.setState({
          metaDataList: nextProps.meta_data_list
        });
      }

      if (nextProps.PartnerError && nextProps.PartnerError.trim() !== "") {
        this.setState(() => ({
          error: nextProps.PartnerError
        }));
      }
      if (nextProps.TagList) {
        this.setState({
          tagList: nextProps.TagList
        });
      }
      if (!nextProps.passwordChange) {
        this.setState({
          changepasswordmodal: false
        });
      }
      if (nextProps.isSaved && (this.state.isAddNew || this.state.isEdit)) {
        if (this.state.selectedTab < 7) {



          if (this.state.isSaveClose) {
            this.setStateAddNew(false);
          }
          else {

            if (nextProps.Partner.pk) {
              this.props.actions.getPartnerDetail(nextProps.Partner.pk);
            }

            this.setState({
              ...this.state,
              website: {
                ...this.state.website
              },
              selectedTab: this.state.selectedTab + 1,
              pagination: {
                ...this.state.pagination,
                current_page: this.state.pagination.current_page + 1
              },
              edit_partner_id: nextProps.Partner.pk,
              partner_api_key:
                nextProps.Partner &&
                nextProps.Partner.fields &&
                nextProps.Partner.fields.api_key
            });
          }
        } else {
          this.setStateAddNew(false);
        }
      }
      if (nextProps.businessTypeList) {
        let list = nextProps.businessTypeList;
        this.setState({
          businessTypeList: list
        });
      }

      if (
        (this.state.isAddNew || this.state.isEdit) &&
        nextProps.partnerDetail &&
        nextProps.partnerDetail.length > 0 &&
        !_.isEqual(this.props.partnerDetail, nextProps.partnerDetail)
      ) {
        this.setEditData(nextProps.partnerDetail[0]);
      }

      if (nextProps.isImageDeleted) {
        if (this.state.selectedTab == 0) {
          const { website } = this.state;
          delete website.image;
          this.setState(
            {
              website: {
                ...this.state.website,
                image: null
              }
            },
            () => {
              this.props.actions.denyRemoveImageAction();
            }
          );
        } else {
          let { addressList } = this.state.website;
          let index = addressList.findIndex(
            x => x.image.id == nextProps.selectedImage
          );
          addressList[index].image = null;
          this.setState({
            website: {
              ...this.state.website,
              addressList: addressList
            }
          });
        }
      }
    }
  }

  setPartnerList = (data, pagination) => {
    let PartnerData = data;
    for (var i in PartnerData) {
      if (PartnerData[i].approval_status == 1) {
        PartnerData[i].approval_status = "Active";
      } else {
        PartnerData[i].approval_status = "Inactive";
      }
    }
    this.setState(() => ({
      tableData: PartnerData,
      aggrid_pagination: pagination
    }));
  };

  setBusinessType = businessTypeId => {
    if (businessTypeId !== null && this.state.isEdit) {
      this.props.actions.getEconomicSectorsIndustries(businessTypeId);
    }
  };

  setMetaData = industry_ids => {
    if (industry_ids !== null && industry_ids.length > 0) {
      this.props.actions.getIndustriesPartnerThemes(industry_ids);
    }
  };

  setEditData = editData => {
    let tagsIds = [];
    let businessId = "";
    let industryId = [];

    for (var i = 0; i < editData.tagsList.length; i++) {
      for (var j = 0; j < editData.tagsList[i].tagsType.length; j++) {
        tagsIds.push(editData.tagsList[i].tagsType[j].id);
      }
    }

    if (editData && editData.businessType) {
      for (var i = 0; i < editData.businessType.length; i++) {
        for (var j = 0; j < editData.businessType[i].businessType.length; j++) {
          businessId = editData.businessType[i].businessType[j].id;
        }
      }
    }

    if (editData && editData.industry) {
      for (var i = 0; i < editData.industry.length; i++) {
        industryId.push(editData.industry[i].industry);
      }
    }

    this.setBusinessType(businessId);

    this.setMetaData(industryId);
    var data = {
      partnerNumber: editData.api_key,
      businessName: editData.data.businessName,
      contactFirstName: editData.data.contactFirstName,
      contactLastName: editData.data.contactLastName,
      contactPhone: editData.data.contactPhone,
      contactEmail: editData.data.contactEmail,
      businessURL: editData.deploy_url,
      test_site_url: editData.test_deploy_url,
      userName: editData.data.userName,
      tags: tagsIds,
      status: editData.status,
      businessTypeId: businessId,
      industryId: industryId,
      role: editData.role,
      approval_status: editData.approval_status,
      image: editData.image,
      addressList: editData.addressList ? editData.addressList : [],
      is_active: editData.is_active,
      slug: editData.data.slug,
      metadataId: editData.industry_metadata,
      headers: editData.headers,
      footers: editData.footers,
      pages: editData.pages,
      menus: editData.menus,
      components: editData.components,
      languages: editData.languages
    };
    this.setState({
      website: data,
      isEdit: true,
      editParnterData: editData,
      headers: editData.headers,
      footers: editData.footers,
      pages: editData.pages,
      menus: editData.menus,
      components: editData.components,
      languages: editData.languages
    });
  };

  addEditPartnerMapping = (webSiteId, role, clickedAction) => {
    let { tableData } = this.state;
    const index = tableData.findIndex(x => x.id == webSiteId);
    let name = "";
    if (index > -1) {
      name = tableData[index].business_name;
    }
    let entityType = "";
    if (role == "RE") {
      window.sessionStorage.setItem("Retailer_Name", name);
      entityType = "retailer";
    } else if (role == "WS") {
      window.sessionStorage.setItem("Wholesaler_Name", name);
      entityType = "wholesaler";
    }

    this.props.actions.onNavigateTo(
      routesNames.PARTNERMAPPING +
      "?entityType=" +
      btoa(entityType) +
      "&id=" +
      btoa(webSiteId) +
      "&name=" +
      btoa(name) +
      "&action=" +
      btoa(clickedAction)
    );
  };

  mapPartnerServices = id => {
    this.props.actions.onNavigateTo(routesNames.PARTNERSERVICE + "?id=" + id);
  };

  editProductTemplateMapping = (webSiteId, role) => {
    if (role == "RE") {
      let name = "";
      for (let i = 0; i < this.state.tableData.length; i++) {
        if (webSiteId === this.state.tableData[i].id) {
          name = this.state.tableData[i].data["businessName"];
        }
      }
      window.sessionStorage.setItem("Retailer_Name", name);
      this.props.actions.onNavigateTo(
        routesNames.PRODUCTTEMPLATESRETAILERCOMPONENT +
        "?entityType=retailer&id=" +
        webSiteId
      );
    } else if (role == "BR") {
      let name = "";
      for (let i = 0; i < this.state.tableData.length; i++) {
        if (webSiteId === this.state.tableData[i].id) {
          name = this.state.tableData[i]["name"];
        }
      }
      window.sessionStorage.setItem("Brand_Name", name);
      this.props.actions.onNavigateTo(
        routesNames.PRODUCTTEMPLATECOMPONENT +
        "?entityType=brand&id=" +
        webSiteId
      );
    } else if (role == "WS") {
      let name = "";
      for (let i = 0; i < this.state.tableData.length; i++) {
        if (webSiteId === this.state.tableData[i].id) {
          name = this.state.tableData[i]["name"];
        }
      }
      window.sessionStorage.setItem("Wholesaler_Name", name);
      this.props.actions.onNavigateTo(
        routesNames.PRODUCTTEMPLATESWHOLESALESALERCOMPONENT +
        "?entityType=wholeSaler&id=" +
        webSiteId
      );
    }
  };

  editRetailerWholesalerMapping = webSiteId => {
    let name = "";
    for (let i = 0; i < this.state.tableData.length; i++) {
      if (webSiteId === this.state.tableData[i].id) {
        name = this.state.tableData[i].data["businessName"];
      }
    }
    window.sessionStorage.setItem("Retailer_Name", name);
    this.props.actions.onNavigateTo(
      routesNames.RETAILERWHOLESALERCOMPONENT +
      "?entityType=retailer&id=" +
      webSiteId
    );
  };

  string_to_slug = str => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-") // collapse dashes
      .replace(/^-+/, ""); // trim - from start of text
    // .replace(/-+$/, ""); // trim - from end of text

    return str;
  };

  handleTextChange = (key, type) => event => {
    if (
      key == "businessName" &&
      event.target.value &&
      event.target.value.length > 255
    ) {
      this.setState({
        businessNameError:
          "The length of Business Name should be less than 255 character"
      });
      return;
    } else if (
      key == "businessName" &&
      event.target.value &&
      event.target.value.length <= 255
    ) {
      this.setState({
        businessNameError: null
      });
    }
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    let new_slug = this.state.website.slug;

    if (!this.state.website.id && key === "businessName") {
      new_slug = this.string_to_slug(event.target.value);
      reqData["slug"] = "success";
    }
    if (key === "slug") new_slug = this.string_to_slug(event.target.value);

    var website = this.state.website;
    website[key] = event.target.value;
    website.slug = new_slug;

    this.setState({ website: website });
    if (key === "businessTypeId") {
      this.props.actions.getEconomicSectorsIndustries(event.target.value);
    }

    if (key === "industryId") {
      this.props.actions.getIndustriesPartnerThemes(
        event.target.value,
        this.state.website.role
      );
    }

    if (key === "role") {
      this.props.actions.getIndustriesPartnerThemes(
        this.state.website.industryId,
        event.target.value
      );
    }

    if (key == "contactEmail") {
      website["userName"] = event.target.value;
      this.setState({ website: website });
    }
  };

  handelEdit = id => {
    this.props.actions.getPartnerDetail(id);
    var index = this.state.tableData
      ? this.state.tableData.findIndex(item => item.id === id)
      : -1;
    this.setState({
      ...this.state,
      isEdit: true,
      editIndex: index,
      selectedTab: 0,
      edit_partner_id: id
    });
  };

  handelSettlementOpen = (id, api_key) => {
    this.setState({
      settlementModel: true,
      partnerId: id,
      selectedAPIKey: api_key
    });
  };

  handelPasswordChange = (id, api_key) => {
    this.setState({
      changepasswordmodal: true,
      partnerId: id,
      selectedAPIKey: api_key
    });
  };

  handleSitePublish = partner_id => {
    this.props.actions.publishRetailerSite(partner_id, 0);
  };

  handelIsAddNew = isAddNew => {
    if (this.props.authUser.role !== "AD") {
      this.props.history.push("/dashboard");
    } else {
      this.setState(
        {
          isEdit: isAddNew,
          isAddNew: isAddNew
        },
        () => {
          this.setStateAddNew(isAddNew);
          this.props.actions.getUpadtepartnerdetail();
        }
      );
    }
  };

  setStateAddNew(isAddNew) {
    this.setState(
      {
        isSaveClose: false,
        selectedTab: 0,
        pagination: {
          ...this.state.pagination,
          current_page: 1
        },
        isEdit: false,
        edit_partner_id: "",
        isAddNew: isAddNew,
        isEdit: false,
        editIndex: -1,
        partner_api_key: null,
        selectedTab: 0,
        website: {
          partnerNumber: "",
          businessName: "",
          contactFirstName: "",
          contactLastName: "",
          contactPhone: "",
          contactEmail: "",
          businessURL: "",
          test_site_url: "",
          userName: "",
          tags: [],
          status: 0,
          addressList: [],
          approval_status: 0,
          metadataId: "",
          headers: [],
          footers: [],
          pages: [],
          menu: [],
          components: [],
          languages: []
        },
        headers: [],
        footers: [],
        pages: [],
        menu: [],
        components: [],
        languages: [],
        error: "",
        required: {
          partnerNumber: "",
          businessName: "",
          contactFirstName: "",
          contactLastName: "",
          contactPhone: "",
          contactEmail: "",
          businessURL: "",
          userName: ""
        }
      },
      () => {
        this.props.actions.getPartners(this.state.aggrid_pagination.page);
      }
    );
  }

  OpenMenuMappingDialog = (id, data, menu) => {
    this.setState(() => ({
      entityData: data,
      menu: menu,
      isMenuMappingOpen: true
    }));
  };

  closeMenuMappingDialog = () => {
    this.setState(() => ({
      isMenuMappingOpen: false
    }));
  };

  saveMenuMappingHandler = () => { };

  menuMappingDialog = () => {
    const { classes } = this.props;
    let title = "";
    if (this.state.entityData != null) {
      const { name, description } = this.state.entityData;
      title =
        "Assign menus to " +
        this.state.entityData.contactFirstName +
        " " +
        this.state.entityData.contactLastName;
    }
    return (
      <Dialog
        open={this.state.isMenuMappingOpen}
        disableBackdropClick={true}
        onClose={() => this.openModalDialog("openDialog", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <b>{title}</b>
        </DialogTitle>

        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              {this.state.MappingError && (
                <SnackbarContent
                  message={<span>this.state.MappingError</span>}
                  color="danger"
                />
              )}

              <MenuComponent
                open={this.state.isMenuMappingOpen}
                onClose={this.closeMenuMappingDialog}
                entityData={this.state.entityData}
                menu={this.state.menu}
                selectedClassIndexes={this.state.selectedClassesIndex}
              />
            </DialogContentText>
            {this.props.MappingSave && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.MappingSave} text="Saving.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  };

  handelCloneSite = id => {
    this.props.actions.getPartnersList();
    this.setState({
      openCopyDilog: true,
      ...this.state.clone_site,

      clone_site: {
        partnerId: id
      }
    });
  };
  handelCloneSiteDilogClose = () => {
    this.setState({
      openCopyDilog: false
    });
  };
  cloneSiteDilog = () => {
    const { classes } = this.props;
    const { partnerId } = this.state.clone_site;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.openCopyDilog}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.onHanndelClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle} style={{ width: "500px" }}>
            Clone Sites{" "}
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <DialogContentText>
            <CloneSiteComponent
              open={this.state.openCopyDilog}
              onClose={this.handelCloneSiteDilogClose}
              partnerList={this.state.tableData}
              partnerId={partnerId}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };
  retailerPasswordModelClose = () => {
    this.setState({
      partnerId: null,
      settlementModel: false
    });
  };

  changePasswordModelClose = () => {
    this.setState({
      partnerId: null,
      changepasswordmodal: false
    });
  };

  signinUser = partner_email_id => {
    this.props.actions.signInUser(
      partner_email_id,
      "",
      true,
      this.props.authUser.user
    );
  };

  handelAssignCMSPages(id, api_key) {
    this.setState({
      isAssignCMSPages: true,
      partnerId: id
    });
  }

  closeisAssignCMSPagesDialog() {
    this.setState({
      isAssignCMSPages: false
    });
  }
  AssignCMSPagesToPartnerDialog() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.isAssignCMSPages}
        disableBackdropClick={true}
        onClose={() => this.openModalDialog("openDialog", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <b>CMS Pages</b>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <CMSPages
              // open={this.state.isMenuMappingOpen}
              onClose={this.closeisAssignCMSPagesDialog}
              partnerId={this.state.partnerId}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
  renderStaffTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.menuMappingDialog()}
        {this.cloneSiteDilog()}
        {this.state.isAssignCMSPages && this.AssignCMSPagesToPartnerDialog()}
        {this.state.changepasswordmodal && (
          <ChangePassword
            open={this.state.changepasswordmodal}
            userid={this.state.partnerId}
            onClose={this.changePasswordModelClose}
            isAdminChangingPartnerPassword={true}
            api_key_partner={this.state.selectedAPIKey}
          />
        )}

        {this.state.settlementModel && (
          <RetailerSettlement
            open={this.state.settlementModel}
            partner={this.state.partnerId}
            onClose={this.retailerPasswordModelClose}
            isAdminChangingPartnerPassword={true}
            api_key_partner={this.state.selectedAPIKey}
          />
        )}

        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  View, edit or delete Partners below.
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <form autoComplete="off">
                  <AgGrid
                    isServerPagination={true}
                    pagination={this.state.aggrid_pagination}
                    rowData={this.state.tableData}
                    columnDefs={this.state.columnDefs}
                    signinUser={this.signinUser}
                    handelSettlementOpen={this.handelSettlementOpen}
                    editBrandTemplateMapping={this.addEditPartnerMapping}
                    editRetailerWholesalerMapping={
                      this.editRetailerWholesalerMapping
                    }
                    editProductTemplateMapping={this.editProductTemplateMapping}
                    handelEdit={this.handelEdit}
                    mapPartnerServices={this.mapPartnerServices}
                    handelPasswordChange={this.handelPasswordChange}
                    handleSitePublish={this.handleSitePublish}
                    AgGridActionButton={partnerAgGridActionsButton}
                    handelAssignCMSPages={this.handelAssignCMSPages}
                    update={this.updateGridData}
                    handelCloneSite={this.handelCloneSite}
                    search={this.searchText}
                    new={this.handelIsAddNew}
                    isAddBtn={true}
                  />
                </form>
                {(this.props.Loading || this.props.PartnerSaving) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader
                  loading={
                    this.props.Loading || this.props.partnerDetailLoading
                  }
                  text="Loading.."
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getPartners(page, this.state.searchtext);
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getPartners(page, param);
      }
    );
  }

  setIndustry(key, value) { }

  renderAddStaffMember = () => {
    const { classes } = this.props;
    const {
      partnerNumber,
      businessName,
      contactFirstName,
      contactLastName,
      contactPhone,
      contactEmail,
      businessURL,
      userName,
      tags,
      image,
      status,
      approval_status,
      test_deploy_url,
      is_active,
      slug,
      metadataId
    } = this.state.website;

    const url = image && image.secure_url ? image.secure_url : "";

    const { isEdit, required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Fill in fields below, then click 'Save and Next' button to spawn
                new or edit an existing Partner.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                {this.ActivePartnerDialog()}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Partner Type *
                    </InputLabel>
                    <Select
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={
                        this.state.website.role ? this.state.website.role : null
                      }
                      classes={{
                        root: "select-control-root"
                      }}
                      onChange={this.handleTextChange("role", "")}
                      disabled={isEdit}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Partner Type *
                      </MenuItem>
                      {_.map(roles, ({ key, name }, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={key}
                            key={index + "_" + key}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Economic Sector *
                    </InputLabel>
                    <Select
                      variant="outlined"
                      style={{ width: "100%" }}
                      success={required.businessTypeId === "success"}
                      error={required.businessTypeId === "error"}
                      value={
                        this.state.website.businessTypeId
                          ? this.state.website.businessTypeId
                          : null
                      }
                      onChange={this.handleTextChange("businessTypeId", "")}
                      classes={{
                        root: "select-control-root"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Economic Sector
                      </MenuItem>
                      {_.map(
                        this.state.businessTypeList,
                        ({ id, name }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                              key={index + "_" + id}
                            >
                              {name}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Industry *
                    </InputLabel>

                    <Select
                      variant="outlined"
                      style={{ width: "100%" }}
                      multiple
                      success={required.industryId === "success"}
                      error={required.industryId === "error"}
                      value={
                        this.state.website && this.state.website.industryId
                          ? this.state.website.industryId
                          : []
                      }
                      onChange={this.handleTextChange("industryId", "")}
                      classes={{
                        root: "select-control-root"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Industry
                      </MenuItem>
                      {_.map(
                        this.state.businessIndustryList,
                        ({ id, name }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                              key={index + "_" + id}
                            >
                              {name}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Package
                    </InputLabel>

                    <Select
                      variant="outlined"
                      style={{ width: "100%" }}
                      success={required.metaDataId === "success"}
                      error={required.metaDataId === "error"}
                      value={metadataId}
                      disabled={this.state.isEdit}
                      onChange={this.handleTextChange("metadataId", "")}
                      classes={{
                        root: "select-control-root"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Package
                      </MenuItem>
                      {_.map(this.state.metaDataList, ({ id, name }, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={id}
                            key={id}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Business Name *
                    </InputLabel>

                    <br />
                    <TextField
                      value={businessName}
                      success={required.businessName === "success"}
                      error={
                        required.businessName === "error" ||
                        this.state.businessNameError
                      }
                      helperText={
                        this.state.businessNameError
                          ? this.state.businessNameError
                          : ""
                      }
                      variant="outlined"
                      fullWidth
                      classes={{
                        root: "attribute-txtbox"
                      }}
                      onChange={this.handleTextChange("businessName", "")}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Slug
                    </InputLabel>

                    <br />
                    <TextField
                      value={slug}
                      success={required.slug === "success"}
                      error={required.sug === "error"}
                      variant="outlined"
                      fullWidth
                      classes={{
                        root: "attribute-txtbox"
                      }}
                      onChange={this.handleTextChange(
                        "slug",
                        validationUtils.validationTypes.email
                      )}
                    />
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Contact First Name *
                    </InputLabel>

                    <br />
                    <TextField
                      value={contactFirstName}
                      success={required.contactFirstName === "success"}
                      error={required.contactFirstName === "error"}
                      variant="outlined"
                      fullWidth
                      classes={{
                        root: "attribute-txtbox"
                      }}
                      onChange={this.handleTextChange("contactFirstName", "")}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Contact Last Name *
                    </InputLabel>

                    <br />
                    <TextField
                      value={contactLastName}
                      success={required.contactLastName === "success"}
                      error={required.contactLastName === "error"}
                      variant="outlined"
                      fullWidth
                      classes={{
                        root: "attribute-txtbox"
                      }}
                      onChange={this.handleTextChange("contactLastName", "")}
                    />
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Contact Phone *
                    </InputLabel>

                    <br />
                    <TextField
                      value={contactPhone}
                      success={required.contactPhone === "success"}
                      error={required.contactPhone === "error"}
                      variant="outlined"
                      fullWidth
                      classes={{
                        root: "attribute-txtbox"
                      }}
                      onChange={this.handleTextChange(
                        "contactPhone",
                        validationUtils.validationTypes.mobileNumber
                      )}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Contact Email *
                    </InputLabel>

                    <br />
                    <TextField
                      value={contactEmail}
                      success={required.contactEmail === "success"}
                      error={required.contactEmail === "error"}
                      variant="outlined"
                      fullWidth
                      classes={{
                        root: "attribute-txtbox"
                      }}
                      onChange={this.handleTextChange(
                        "contactEmail",
                        validationUtils.validationTypes.email
                      )}
                    />
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Business URL *
                    </InputLabel>

                    <br />
                    <TextField
                      value={businessURL}
                      success={required.businessURL === "success"}
                      error={required.businessURL === "error"}
                      variant="outlined"
                      fullWidth
                      // disabled={this.state.isEdit || approval_status === 1}
                      classes={{
                        root: "attribute-txtbox"
                      }}
                      onChange={this.handleTextChange(
                        "businessURL",
                        validationUtils.validationTypes.url
                      )}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      User Name (must be an email address) *
                    </InputLabel>

                    <br />
                    <TextField
                      value={userName}
                      success={required.userName === "success"}
                      error={required.userName === "error"}
                      variant="outlined"
                      fullWidth
                      classes={{
                        root: "attribute-txtbox"
                      }}
                      onChange={this.handleTextChange(
                        "userName",
                        validationUtils.validationTypes.email
                      )}
                    />
                  </GridItem>
                </GridContainer>

                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Tags
                    </InputLabel>
                    <Select
                      variant="outlined"
                      style={{ width: "100%" }}
                      multiple
                      value={tags}
                      onChange={this.handleMultiple}
                      classes={{
                        root: "select-control-root"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Select Tag
                      </MenuItem>
                      {_.map(this.state.tagList, ({ id, name }, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={id}
                            key={index + "_" + id}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    {this.props.authUser.role === "AD" && (
                      <>
                        <InputLabel className={classes.label + " "}>
                          Status
                        </InputLabel>
                        <Select
                          variant="outlined"
                          style={{ width: "100%" }}
                          value={status}
                          onChange={this.handleStatus}
                          classes={{
                            root: "select-control-root"
                          }}
                          disabled={!isEdit}
                        >
                          {_.map(statusList, ({ key, name }, index) => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={key}
                                key={index + "_" + key}
                              >
                                {name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </>
                    )}
                  </GridItem>
                </GridContainer>

                <br />

                <GridContainer>
                  {test_deploy_url && (
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <InputLabel className={classes.label + " "}>
                        Test Site URL
                      </InputLabel>
                      &nbsp;&nbsp; <span>{test_deploy_url}</span>
                    </GridItem>
                  )}

                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <InputLabel className={classes.label + " "}>
                      Approval Status
                    </InputLabel>
                    &nbsp;&nbsp;
                    <span>
                      {approval_status === 1 ? "Approved" : "Pending"}
                    </span>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <ImageUpload
                      addButtonProps={{
                        round: true
                      }}
                      changeButtonProps={{
                        color: "rose",
                        round: true
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true
                      }}
                      imgUrl={
                        image instanceof Object
                          ? image instanceof Blob
                            ? image
                            : image.url
                          : image
                      }
                      onimageRemove={this.handelImageRemove}
                      onChange={this.handelImageChange}
                    />
                  </GridItem>

                  <GridItem lg={6} xs={12} sm={12} md={12}>
                    <br />
                    <Checkbox
                      className="checkbox-control"
                      tabIndex={-1}
                      checked={is_active ? true : false}
                      onClick={this.handleToggleStatus()}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      name="is_active"
                    />
                    <div className={classes.label} style={{ marginTop: "7px" }}>
                      <span>Active</span>
                    </div>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  handleAddHoursList = (index, value) => {
    const { addressList } = this.state.website;
    addressList[index].hours = value;
    this.setState({
      ...this.state,
      website: {
        ...this.state.website,
        addressList: addressList
      }
    });
  };

  handleToggleStatus = () => event => {
    let value = event.target.checked;

    this.setState({
      isActiveConfirmation: true,
      isActiveCheck: event.target.checked
    });
  };

  ActivePartnerDialog() {
    const { classes } = this.props;
    const { isActiveConfirmation, isActiveCheck } = this.state;
    return (
      <Dialog
        open={isActiveConfirmation}
        disableBackdropClick={true}
        onClose={() => this.closeDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            <div>
              Are you sure you want to mark partner as{" "}
              {isActiveCheck ? "Active" : "Inactive"}?
            </div>
          </DialogContentText>
          <DialogActions>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              color="primary"
              onClick={() => this.markActive()}
            >
              Yes
            </Button>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              onClick={() => this.closeDialog()}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  markActive() {
    const { isActiveCheck } = this.state;
    this.setState({
      ...this.state,
      website: {
        ...this.state.website,
        is_active: isActiveCheck
      },
      isActiveConfirmation: false,
      isActiveCheck: false
    });
  }

  closeDialog() {
    this.setState({
      isActiveConfirmation: false,
      isActiveCheck: false
    });
  }

  setSelectedItems(ids) {
    this.setState({
      website: {
        ...this.state.website,
        menus: ids
      }
    });
  }

  setSelectedPages(ids) {
    this.setState({
      website: {
        ...this.state.website,
        pages: ids
      }
    });
  }

  setSelectedComponents(ids) {
    this.setState({
      website: {
        ...this.state.website,
        components: ids
      },
      components: ids
    });
  }

  setSelectedHeaders(headers) {
    this.setState({
      website: {
        ...this.state.website,
        headers: headers
      },
      headers: headers
    });
  }

  setSelectedFooters(links) {
    this.setState({
      website: {
        ...this.state.website,
        footers: links
      },
      footers: links
    });
  }

  setSelectedLanguages(links) {
    this.setState({
      website: {
        ...this.state.website,
        languages: links
      },
      //languages: links
    });
  }

  showSelectedTabContent() {
    const { selectedTab, addProduct, website } = this.state;
    switch (selectedTab) {
      case 0:
        return <div>{this.renderAddStaffMember()}</div>;
      case 1:
        return (
          <div>
            <LanguageListGrid
              setSelectedItems={links => this.setSelectedLanguages(links)}
              selected_items={this.state.languages}

            />
          </div>
        );


      case 2:
        return (
          <div>
            <MenuListGrid
              type={website.role}
              setSelectedItems={ids => this.setSelectedItems(ids)}
              selected_items={this.state.menus}
            />
          </div>
        );

      case 3:
        return (
          <div>
            <IndustryPages
              setSelectedItems={ids => this.setSelectedPages(ids)}
              selected_items={this.state.pages}
              disableSelected={true}
            />
          </div>
        );

      case 4:
        return (
          <div>
            <CMSComponents
              setSelectedItems={ids => this.setSelectedComponents(ids)}
              selected_items={this.state.components}
              disableSelected={true}
            />
          </div>
        );

      case 5:
        return (
          <div>
            <MetadataHeader
              setSelectedItems={headers => this.setSelectedHeaders(headers)}
              headers={this.state.headers}
              partner_id={this.state.edit_partner_id}
              source={"PARTNER"}
            />
          </div>
        );

      case 6:
        return (
          <div>
            <MetadataFooter
              setSelectedItems={links => this.setSelectedFooters(links)}
              footers={this.state.footers}
              partner_id={this.state.edit_partner_id}
            //source={"PARTNER"}
            />
          </div>
        );

      case 7:
        return (
          <div>
            <Address
              list={this.state.website.addressList}
              isAdd={this.state.isAddressAdd}
              onHandleAdd={this.handleAddress}
              addHoursList={this.handleAddHoursList}
              handleFiles={this.handleFiles}
            />
          </div>
        );
    }
  }

  handleFiles(data, action) {
    let { files } = this.state;
    if (action == 0) {
      let index = files.findIndex(x => x.name == data.name);
      files.splice(index, 1);
    } else {
      files.push(data);
    }
    this.setState({
      files: files
    });
  }

  handleChange(value) {
    this.setState({
      selectedTab: value,
      pagination: {
        ...this.state.pagination,
        current_page: value + 1
      }
    });
  }

  handleAddress = (list, index) => {
    const { addressList } = this.state.website;
    if (index == null) {
      addressList.push(list);
    } else {
      addressList[index] = list;
    }
    this.setState({
      website: {
        ...this.state.website,
        addressList: addressList
      },
      isAddressAdd: false
    });
  };

  wizardUI = () => {
    const { current_page, total_page } = this.state.pagination;
    const { classes } = this.props;

    return (
      <Grid>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <LoadingOverlay>
              <Card>
                <CardHeader>
                  <div
                    style={{
                      color: "black",
                      fontWeight: "500",
                      float: "left",
                      fontSize: "16px",
                      lineHeight: "18px"
                    }}
                  >
                    <span>View or edit details.</span>
                  </div>
                  <div style={Style.pagination}>
                    Page: {current_page}/{total_page}
                  </div>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                          {_.map(wizardHeaders, (model, index) => (
                            <GridItem
                              xs={6}
                              sm={4}
                              md={3}
                              lg={3}
                              key={index}
                              style={{
                                textAlign: "center"
                              }}
                            >
                              <div
                                value={index}
                                onClick={() => this.handleChange(index)}
                                className={cx("wizard-tab", {
                                  "wizard-selected-tab":
                                    this.state.selectedTab == index
                                })}
                              >
                                {model}
                              </div>
                            </GridItem>
                          ))}
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Divider variant="middle" />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        {this.showSelectedTabContent()}
                      </GridItem>
                    </GridItem>
                  </GridContainer>

                  {this.state.error && this.state.error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <div
                    style={{
                      float: "right"
                    }}
                  >
                    {current_page > 1 ? (
                      <Button
                        className="wizard-btn"
                        onClick={() => this.clickPreviousBtn()}
                      >
                        Previous
                      </Button>
                    ) : (
                      ""
                    )}

                    {current_page < total_page ? (
                      <Button
                        className="wizard-btn"
                        onClick={() => this.clickNextBtn()}
                      >
                        Next
                      </Button>
                    ) : (
                      ""
                    )}

                    {this.state.isEdit ? (

                      <>

                        {current_page < total_page &&
                          <Button
                            color="info"
                            disabled={!this.isValidForm}
                            onClick={this.updateWebsite}
                          >
                            {"Save & Next"}
                          </Button>
                        }

                        <Button
                          color="info"
                          disabled={!this.isValidForm}
                          onClick={this.updateAndClose}
                        >
                          {"Save & close"}
                        </Button>

                      </>
                    ) : (

                      <>

                        {current_page < total_page &&
                          <Button
                            color="info"
                            disabled={!this.isValidForm}
                            onClick={this.saveReatiler}
                          >
                            {"Save & Next"}
                          </Button>
                        }

                        <Button
                          color="info"
                          disabled={!this.isValidForm}
                          onClick={this.saveClose}
                        >
                          {"Save & close"}
                        </Button>

                      </>
                    )}

                    <Button
                      className="wizard-btn"
                      onClick={() => this.handelIsAddNew(false)}
                    >
                      Close
                    </Button>
                  </div>
                </CardBody>
              </Card>
              {(this.props.Loading || this.props.PartnerSaving) && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-12px"
                  }}
                />
              )}
              <Loader
                loading={
                  this.props.PartnerSaving || this.props.partnerDetailLoading
                }
                text=""
              />
            </LoadingOverlay>
          </GridItem>
        </GridContainer>
      </Grid>
    );
  };
  render() {
    return this.state.isAddNew || this.state.isEdit
      ? this.wizardUI()
      : this.renderStaffTable();
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  authUser: state.authState.user,
  passwordChange: state.authState.passwordchangepending,
  List: state.partnerSate.list,
  Loading: state.partnerSate.loading,
  ListError: state.partnerSate.error,
  Partner: state.partnerSate.addingPartner.partner,
  PartnerSaving: state.partnerSate.addingPartner.loading,
  isSaved: state.partnerSate.addingPartner.isSaved,
  PartnerError: state.partnerSate.addingPartner.error,
  TagList: state.tagsReducer.list,
  businessTypeList: state.businessTypeReducer.list,
  partnerDetail: state.partnerSate.partnerDetail.list,
  partnerDetailLoading: state.partnerSate.partnerDetail.loading,
  partnerDetailError: state.partnerSate.partnerDetail.error,
  cloneSiteList: state.partnerSate.cloneSite.list,
  cloneSiteDetail: state.partnerSate.cloneSite.list,
  cloneSiteDetailLoading: state.partnerSate.cloneSite.loading,
  cloneSiteDetailError: state.partnerSate.cloneSite.error,
  isImageDeleted: state.appMenuReducer.removeImage.isDeleted,
  selectedImage: state.appMenuReducer.removeImage.data,
  pagination: state.partnerSate.pagination,
  businessIndustryList:
    state.businessTypeReducer.economic_sector_industries.data,
  meta_data_list: state.businessTypeReducer.industry_meta_data.data
});

const condition = authUser =>
  true || authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateWebsite);
