import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  create: {
    error: null,
    saving: false,
    isSaved: true
  },
  get: {
    list: [],
    loading: false,
    error: null
  }
};

const productCMSReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_TYPE_REQUEST:
      return {
        ...state,
        get: {
          list: [],
          loading: true,
          error: null
        },
        create: {
          error: null,
          saving: false,
          isSaved: true
        }
      };
    case actionTypes.GET_PRODUCT_TYPE_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          error: action.payload
        },
        create: {
          error: null,
          saving: false,
          isSaved: true
        }
      };
    case actionTypes.GET_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        get: {
          list: action.payload,
          loading: false,
          error: null
        },
        create: {
          error: null,
          saving: false,
          isSaved: true
        }
      };
    case actionTypes.CREATE_PRODUCT_TYPE_REQUEST:
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          error: null
        },
        create: {
          error: null,
          saving: true,
          isSaved: false
        }
      };
    case actionTypes.CREATE_PRODUCT_TYPE_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          error: null
        },
        create: {
          error: action.payload,
          saving: false,
          isSaved: true
        }
      };
    case actionTypes.CREATE_PRODUCT_TYPE_SUCCESS:
      var data = state.get.list;
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data[index] = action.payload;
      } else {
        data.push(action.payload);
      }
      return {
        ...state,
        create: {
          error: null,
          saving: false,
          isSaved: true
        },
        get: {
          list: data,
          loading: false,
          error: null
        }
      };
    case actionTypes.CREATE_PRODUCT_TYPE_RESETSTATE:
      return {
        ...state,
        create: {
          error: null,
          saving: false,
          isSaved: true
        }
      };
    default:
      return state;
  }
};

export default productCMSReducer;
