import React, { Component } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Edit from "@material-ui/icons/Edit";

class campaignActionButtonsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlay: 0
    };
  }
  invokeParentMethod() {
    const { data } = this.props;
    return (
      <div
        style={{
          "line-height": "60px"
        }}
      >
        <span span onClick={() => this.performAction(data.id, 4)} title="Edit">
          <Edit className="grid_action_btn" />
        </span>
        {data && data.play == 0 ? (
          <span onClick={() => this.performAction(data.id, 1)} title="Pause">
            <PauseIcon className="grid_action_btn" />
          </span>
        ) : (
          <span
            span
            onClick={() => this.performAction(data.id, 0)}
            title="Play"
          >
            <PlayArrowIcon className="grid_action_btn" />
          </span>
        )}
        {data && data.is_active == false && data.status == 0 ? (
          <span onClick={() => this.performAction(data.id, 3)} title="Publish">
            <i
              className="fa fa-upload grid_action_btn"
              aria-hidden="true"
              style={{ fontSize: "24px" }}
            />
          </span>
        ) : (
          ""
        )}

        <span onClick={() => this.performAction(data.id, 2)} title="Delete">
          <DeleteIcon className="grid_action_btn" />
        </span>
      </div>
    );
  }

  performAction(id, value) {
    switch (value) {
      case 0:
        this.props.context.componentParent.performAction(id, value);
        break;
      case 1:
        this.props.context.componentParent.performAction(id, value);
        break;
      case 2:
        this.props.context.componentParent.handelDelete(id);
        break;
      case 3:
        this.props.context.componentParent.publish(id);
        break;
      case 4:
        this.props.context.componentParent.handelEdit(id);
        break;
    }
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default campaignActionButtonsComponent;
