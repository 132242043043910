// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const createOffers = async (userId, Savedata, files) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    files.forEach(file => {
      data.append(file.name, file);
    });
    const response = await axios.post("api/partner/offers/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOffersList = async (partnerid, page, search) => {
  try {
    let url =
      "/api/partner/offers/list/?partnerid=" + partnerid + "&page=" + page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOfferById = async offerid => {
  try {
    const response = await axios.get(
      "/api/partner/offer/get/?offerid=" + offerid
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOfferType = async () => {
  try {
    const response = await axios.get("/api/admin/offertype/list/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const mapOfferProducts = async (user_id, offer_id, product_ids) => {
  try {
    let data = new FormData();
    data.append("offer_id", offer_id);
    data.append("userid", user_id);
    data.append("data", JSON.stringify(product_ids));
    const response = await axios.post(
      "api/partner/offers/attachproduct/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
