import * as orderService from "../../services/orderService";
import * as actionTypes from "./actionTypes";

export const getOrderList = (
  retailer_id,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getOrderRequest());
  try {
    var success = await orderService.getRetailerOrder(
      retailer_id,
      page,
      search
    );
    if (success.status && success.status === "1") {
      dispatch(getOrderSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getOrderFailure(success.error));
    } else {
      dispatch(getOrderFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getOrderFailure(error.message));
  }
};

const getOrderRequest = () => {
  return {
    type: actionTypes.GET_ORDER_REQUEST
  };
};

const getOrderSuccess = data => {
  return {
    type: actionTypes.GET_ORDER_SUCCESS,
    payload: data
  };
};

const getOrderFailure = error => {
  return {
    type: actionTypes.GET_ORDER_FAILURE,
    payload: error
  };
};

export const getOrderDetailes = order_id => async dispatch => {
  dispatch(getOrderDetailRequest());
  try {
    var success = await orderService.getRetailerOrderDetails(order_id);
    if (success.status && success.status === "1") {
      dispatch(getOrderDetailSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getOrderDetailFailure(success.error));
    } else {
      dispatch(getOrderDetailFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getOrderDetailFailure(error.message));
  }
};

const getOrderDetailRequest = () => {
  return {
    type: actionTypes.GET_ORDER_DETAIL_REQUEST
  };
};

const getOrderDetailSuccess = data => {
  return {
    type: actionTypes.GET_ORDER_DETAIL_SUCCESS,
    payload: data
  };
};

const getOrderDetailFailure = error => {
  return {
    type: actionTypes.GET_ORDER_DETAIL_FAILURE,
    payload: error
  };
};

export const changeOrderStatus = (
  order_id,
  status,
  userId
) => async dispatch => {
  dispatch(changeOrderStatusRequest());
  try {
    var success = await orderService.changeOrderStatus(
      order_id,
      status,
      userId
    );
    if (success.status && success.status === "1") {
      dispatch(changeOrderStatusSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(changeOrderStatusFailure(success.error));
    } else {
      dispatch(changeOrderStatusFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(changeOrderStatus(error.message));
  }
};

const changeOrderStatusRequest = () => {
  return {
    type: actionTypes.CHANGE_ORDER_STATUS_REQUEST
  };
};

const changeOrderStatusSuccess = data => {
  return {
    type: actionTypes.CHANGE_ORDER_STATUS_SUCCESS,
    payload: data
  };
};

const changeOrderStatusFailure = error => {
  return {
    type: actionTypes.CHANGE_ORDER_STATUS_FAILURE,
    payload: error
  };
};
