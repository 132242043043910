import React from "react";
import { useTheme, fade, makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import SettingsIcon from "@material-ui/icons/Settings";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import Autocomplete from "@material-ui/lab/Autocomplete";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import InputBase from "@material-ui/core/InputBase";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    fontSize: 13,
    width: "100%",
    textAlign: "left",
    paddingBottom: 8,
    color: "#586069",
    fontWeight: 600,
    "&:hover,&:focus": {
      color: "#0366d6"
    },
    "& span": {
      width: "100%"
    },
    "& svg": {
      width: 16,
      height: 16
    }
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: ".15em 4px",
    fontWeight: 600,
    lineHeight: "15px",
    borderRadius: 2
  },
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "#f6f8fa"
  },
  header: {
    borderBottom: "1px solid #e1e4e8",
    padding: "8px 10px",
    fontWeight: 600
  },
  inputBase: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent"
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover
    }
  },
  popperDisablePortal: {
    position: "relative"
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2
  },
  text: {
    flexGrow: 1
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18
  }
}));

export default function CustomAutoComplete(props) {
  const classes = useStyles();
  const fieldArray = props.mappingfield;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const [pendingValue, setPendingValue] = React.useState(null);
  const theme = useTheme();

  const handleClick = event => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    setValue(pendingValue);
    props.onChange(pendingValue, props.index);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "github-label" : undefined;

  const handleOnChange = newValue => {
    setPendingValue(newValue);
  };

  const setValuesFunction = () => {
    try {
      if (props.selectedPath) {
        if (props.multiple) {
          if (!Array.isArray(props.selectedPath)) {
            let values = [];

            let selectedValue = _.filter(fieldArray, function(str, i) {
              return str.jsonpath === props.selectedPath;
            });

            if (selectedValue && selectedValue.length > 0) {
              values.push(selectedValue[0]);
            }

            setValue(values);
            setPendingValue(values);
          } else {
            let selectedValue = _.filter(fieldArray, function(str, i) {
              return props.selectedPath.indexOf(str.jsonpath) !== -1;
            });

            let values = [];
            for (let i = 0; i < selectedValue.length; i++) {
              values.push(selectedValue[i]);
            }

            setValue(values);
            setPendingValue(values);
          }
        } else {
          let selectedValue = _.filter(fieldArray, function(str, i) {
            return str.jsonpath === props.selectedPath;
          });
          if (selectedValue) {
            setValue(selectedValue[0]);
            setPendingValue(selectedValue[0]);
          }
        }
      }
    } catch (ex) {
      console.error("Autocomplete set value error", ex);
    }
  };

  React.useEffect(() => {
    setValuesFunction();
  }, [props]);

  return (
    <React.Fragment>
      {fieldArray && fieldArray.length > 0 && (
        <>
          {" "}
          <div className={classes.root}>
            <div
              // disableRipple
              className={"alpha"}
              // aria-describedby={id}
              // onClick={handleClick}
            >
              {props.multiple ? (
                value && value.length > 0 ? (
                  <List
                    style={{ width: "100%", cursor: "pointer", padding: 0 }}
                    onClick={handleClick}
                  >
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-wifi"
                        primary={
                          <Typography variant="button" display="block">
                            Mappings
                          </Typography>
                        }
                        secondary={
                          props.multiple &&
                          value &&
                          value.map(mappingData => (
                            <Typography variant="caption" display="block">
                              {mappingData.title}
                            </Typography>
                          ))
                        }
                      />
                      <ListItemSecondaryAction>
                        <SettingsIcon edge="end" />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                ) : (
                  <List
                    style={{ width: "100%", cursor: "pointer", padding: 0 }}
                    onClick={handleClick}
                  >
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-wifi"
                        primary={
                          <Typography variant="button" display="block">
                            Not Mapped
                          </Typography>
                        }
                        secondary={
                          <Typography variant="caption" display="block">
                            Click to map items to this field
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <SettingsIcon edge="end" />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                )
              ) : value ? (
                // <div>{value.title} <br /> </div>
                <List
                  style={{ width: "100%", cursor: "pointer", padding: 0 }}
                  onClick={handleClick}
                >
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary={
                        <Typography variant="button" display="block">
                          {value.title}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="caption" display="block">
                          {value.parent} : {value.pathlabel}{" "}
                          {value.pathlabel && "/"} {value.title}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <SettingsIcon edge="end" />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              ) : (
                <List
                  style={{ width: "100%", cursor: "pointer", padding: 0 }}
                  onClick={handleClick}
                >
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary={
                        <Typography variant="button" display="block">
                          Not Mapped
                        </Typography>
                      }
                      secondary={
                        <Typography variant="caption" display="block">
                          Click to map items to this field
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <SettingsIcon edge="end" />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              )}

              {/* <SettingsIcon /> */}
            </div>
          </div>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            className={classes.popper}
          >
            <div className={classes.header}>
              Map this Row with any field from list below
            </div>
            <Autocomplete
              open
              onClose={handleClose}
              multiple={props.multiple}
              classes={{
                paper: classes.paper,
                option: classes.option,
                popperDisablePortal: classes.popperDisablePortal
              }}
              value={pendingValue || []}
              onChange={(event, newValue) => {
                handleOnChange(newValue);
              }}
              disablePortal
              disableCloseOnSelect
              renderTags={() => null}
              noOptionsText="Mappings Not Available"
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <DoneIcon
                    className={classes.iconSelected}
                    style={{ visibility: selected ? "visible" : "hidden" }}
                  />
                  {/* <span className={classes.color} style={{ backgroundColor: option.color }} /> */}
                  <div className={classes.text}>
                    {option.title}
                    <br />
                    <small> {option.pathlabel} </small>
                  </div>
                  <CloseIcon
                    className={classes.close}
                    style={{ visibility: selected ? "visible" : "hidden" }}
                  />
                </React.Fragment>
              )}
              // options={fieldArray.sort((a, b) => -b.parent.localeCompare(a.parent))}
              options={[...fieldArray].sort((a, b) => {
                // Display the selected labels first.
                if (props.multiple && value && Array.isArray(value)) {
                  let ai = value.indexOf(a);
                  ai = ai === -1 ? value.length + fieldArray.indexOf(a) : ai;
                  let bi = value.indexOf(b);
                  bi = bi === -1 ? value.length + fieldArray.indexOf(b) : bi;
                  return ai - bi;
                } else {
                  if (value && value.jsonpath) {
                    if (
                      a.jsonpath != value.jsonpath &&
                      b.jsonpath == value.jsonpath
                    )
                      return 1;

                    if (
                      a.jsonpath == value.jsonpath &&
                      b.jsonpath != value.jsonpath
                    )
                      return -1;
                  }

                  return -b.parent.localeCompare(a.parent);
                }
              })}
              groupBy={option => option.parent}
              getOptionLabel={option => {
                let OptionLabel = option.parent;
                if (option.title) {
                  OptionLabel = OptionLabel + ": ";
                  if (option.pathlabel)
                    OptionLabel = OptionLabel + option.pathlabel + "/";

                  OptionLabel = OptionLabel + option.title;
                }
                return OptionLabel;
              }}
              renderInput={params => (
                <InputBase
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  className={classes.inputBase}
                />
              )}
            />
          </Popper>
        </>
      )}
    </React.Fragment>
  );
}
