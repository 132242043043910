import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
// @material-ui/icons
//import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import ForgotPassword from "../UserAuth/ForgotPassword";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password: "",
      alert: null,
      forgotPassword: {
        open: false
      }
    };
  }

  handleTextChange = key => event => {
    this.setState({ [key]: event.target.value });
  };

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.ListError && nextProps.ListError.trim() !== "") {
        this.autoCloseAlert();
      }
    }
  }
  onSave = event => {
    event.preventDefault();
    this.props.actions.signInUser(this.state.email, this.state.password);
  };
  signinUser = () => {
    this.props.actions.signInUser(this.state.email, this.state.password);
  };
  openForgotPasswordModel = () => {
    this.setState({
      ...this.state,
      forgotPassword: {
        ...this.state.forgotPassword,
        open: true
      }
    });
  };

  autoCloseAlert() {
    Swal.fire({
      title: "Invalid Credentials!",
      type: "error",
      timer: 3000
    }).then(result => {});
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  forgotPasswordModelClose = () => {
    this.setState({
      ...this.state,
      forgotPassword: {
        ...this.state.forgotPassword,
        open: false
      }
    });
  };
  render() {
    const { classes } = this.props;
    const { email, password, forgotPassword } = this.state;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          {this.state.alert}

          <ForgotPassword
            open={forgotPassword.open}
            onClose={this.forgotPasswordModelClose}
          />
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.onSave}>
              <LoadingOverlay>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="info"
                  >
                    <h4
                      className={classes.cardTitle}
                      style={{ fontWeight: 500 }}
                    >
                      Team Local
                    </h4>
                    <div className={classes.socialLine}>
                      {[
                        "fab fa-facebook-square",
                        "fab fa-twitter",
                        "fab fa-google-plus"
                      ].map((prop, key) => {
                        return (
                          <Button
                            color="transparent"
                            justIcon
                            key={key}
                            className={classes.customButtonClass}
                          >
                            <i className={prop} />
                          </Button>
                        );
                      })}
                    </div>
                  </CardHeader>
                  <CardBody>
                    {/* <CustomInput
                    labelText="First Name.."
                    id="firstname"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  /> */}
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        value: email,
                        onChange: this.handleTextChange("email")
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: "password",
                        value: password,
                        onChange: this.handleTextChange("password")
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <GridContainer>
                      <GridItem lg={12} md={12}>
                        {" "}
                        <Button color="info" block onClick={this.signinUser}>
                          Login
                        </Button>
                      </GridItem>
                      <GridItem lg={12} md={12}>
                        {" "}
                        <Button
                          color="info"
                          simple
                          size="lg"
                          block
                          onClick={this.openForgotPasswordModel}
                        >
                          Forgot Password?
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                </Card>
                {(this.props.Loading || this.props.loadingGetData) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Verifying.." />
              </LoadingOverlay>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  Loading: state.authState.loginPending,
  ListError: state.authState.loginError
});

export default compose(
  withStyles(loginPageStyle),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoginPage);

//export default withStyles(loginPageStyle)(LoginPage);
