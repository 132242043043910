import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "../../style/app.css";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import moment from "moment";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

import AgGrid from "../../components/AgGrid/AgGrid.jsx";

function dateFormatter(params) {
  if (params.value) {
    return moment(params.value).format("MM/DD/YYYY h:mm a");
  } else {
    return "";
  }
}

class PaymentDetailReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: moment().format("MM/DD/YYYY"),
      end_date: moment().format("MM/DD/YYYY"),
      status: "",
      Payments: [],
      payment_state: [
        {
          name: "All",
          key: "ALL"
        },
        {
          name: "Initiated",
          key: "INITIATED"
        },
        {
          name: "Completed",
          key: "PAYMENT_DONE"
        }
      ],
      order_state: [
        {
          key: 0,
          name: "INITIATED"
        },
        {
          key: 1,
          name: "PLACED"
        },
        {
          key: 2,
          name: "SHIPPING REQUESTED"
        },
        {
          key: 3,
          name: "SHIPPING APPROVED"
        },
        {
          key: 4,
          name: "PACKED"
        },
        {
          key: 5,
          name: "DISPATCHED"
        },
        {
          key: 6,
          name: "DELIVERED"
        },
        {
          key: 7,
          name: "CANCELLED"
        },
        {
          key: 8,
          name: "RETURNED"
        }
      ],
      columnDefs: [
        // {
        //     headerName: "",
        //     field: "value",
        //     cellRenderer: "gridActionComponent",
        //     colId: "params",
        //     width: 120,
        //     resizable: false,
        //     sortable: false,
        // },
        {
          headerName: "Payment Id",
          field: "payment_id",
          sortable: true,
          resizable: true,
          // valueFormatter: dateFormatter,
          width: 170
        },
        {
          headerName: "Payment Status",
          field: "transaction_status_name",
          sortable: true,
          resizable: true,
          width: 150
        },
        {
          headerName: "Payment Mode",
          field: "order.payment_mode",
          sortable: true,
          resizable: true,
          width: 150
        },
        {
          headerName: "Amount",
          field: "transaction_amnt",
          sortable: true,
          resizable: true,
          width: 150
        },
        {
          headerName: "Order Number",
          field: "order.order_number",
          width: 180,
          resizable: true
        },
        {
          headerName: "Order Status",
          field: "order.status_name",
          sortable: true,
          resizable: true,
          width: 150
        },
        {
          headerName: "Customer Name",
          field: "order.delivery_address.name",
          width: 200,
          resizable: true
        },
        {
          headerName: "Phone",
          field: "order.delivery_address.phone",
          sortable: true,
          resizable: true,
          width: 150
        },
        {
          headerName: "Address",
          field: "order.delivery_address.address",
          width: 200,
          resizable: true
        },
        {
          headerName: "Email",
          field: "order.user.email",
          width: 200,
          resizable: true
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      context: { componentParent: this },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      domLayout: "autoHeight",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
    this.getReportData = this.getReportData.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.List) {
        const Payments = nextProps.List;
        Payments.forEach(element => {
          if (this.state.payment_state) {
            let index = this.state.payment_state.findIndex(
              x => x.key == element.transaction_status
            );
            if (
              index != -1 &&
              this.state.payment_state[index] &&
              this.state.payment_state[index].name
            ) {
              element.transaction_status_name = this.state.payment_state[
                index
              ].name;
            }
            let orderIndex = this.state.order_state.findIndex(
              x => x.key == element.order.status
            );
            if (
              orderIndex != -1 &&
              this.state.order_state[orderIndex] &&
              this.state.order_state[orderIndex].name
            ) {
              element.order.status_name = this.state.order_state[
                orderIndex
              ].name;
            }
          }
        });
        this.setState(() => ({
          Payments: Payments
        }));
      }
    }
  }

  componentDidMount() {
    let index = this.state.payment_state.findIndex(x => x.name == "All");
    let status = this.state.payment_state[index].key;
    this.setState(
      {
        status: status
      },
      () => {
        this.props.actions.paymentReportAction(
          this.props.User,
          moment(this.state.start_date),
          moment(this.state.end_date),
          this.state.status
        );
      }
    );
  }

  render() {
    return <div>{this.renderOrderTable()}</div>;
  }

  renderOrderTable = () => {
    const { classes } = this.props;
    const { start_date, end_date } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Payments
              </p>
            </CardHeader>
            <CardBody>
              <Grid>
                <GridContainer>
                  <GridItem lg={3} md={3}>
                    <FormControl fullWidth>
                      <InputLabel className={classes.label + " "}>
                        Start Date *
                      </InputLabel>
                      {/* <br />
                                            <br /> */}

                      <Datetime
                        timeFormat={false}
                        inputProps={{
                          placeholder: "Start Date",
                          value: start_date,
                          style: { marginTop: "40px" }
                        }}
                        value={start_date}
                        onChange={this.handleStartDateChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem lg={3} md={3}>
                    <FormControl fullWidth>
                      <InputLabel className={classes.label + " "}>
                        End Date *
                      </InputLabel>
                      {/* <br />
                                            <br /> */}
                      <Datetime
                        timeFormat={false}
                        inputProps={{
                          placeholder: "End Date",
                          value: end_date,
                          style: { marginTop: "40px" }
                        }}
                        onChange={this.handleEndDateChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem lg={3} md={3}>
                    <FormControl
                      fullWidth
                      style={customInputStyle.formControl}
                      style={{ marginTop: "27px" }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.labelRoot + " "}
                        labelText="Change Status *"
                      >
                        Change Status *
                      </InputLabel>

                      <Select
                        value={this.state.status}
                        name="product_template"
                        onChange={this.handleTextChange("payment_state", "")}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Choose Product
                        </MenuItem>
                        {_.map(
                          this.state.payment_state,
                          ({ key, name }, index) => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={key}
                                key={key}
                              >
                                {name}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem lg={3} md={3}>
                    <Button
                      color="info"
                      onClick={this.getReportData}
                      style={{
                        marginTop: "35px"
                      }}
                    >
                      Go
                    </Button>
                  </GridItem>
                </GridContainer>
              </Grid>

              <div>
                <div
                  id="myGrid"
                  style={{
                    width: "100%"
                  }}
                  className="ag-theme-material"
                >
                  <AgGrid
                    rowData={this.state.Payments}
                    columnDefs={this.state.columnDefs}
                    Loading={this.props.Loading}
                  />
                  {/* <AgGridReact
                                            gridOptions={this.gridOptions}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.Payments}
                                            showToolPanel={true}

                                            context={this.state.context}
                                            animateRows={true}
                                            frameworkComponents={this.state.frameworkComponents}

                                            // floatingFilter={true}
                                            statusBar={this.state.statusBar}
                                            groupSelectsChildren={true}
                                            sideBar={this.state.sideBar}
                                            pagination={true}
                                            paginationPageSize={50}
                                            rowMultiSelectWithClick={true}
                                            domLayout={this.state.domLayout}
                                        /> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  handleStartDateChange(event) {
    console.log(event);
    this.setState({
      start_date: moment(event).format("MM/DD/YYYY")
    });
  }

  handleEndDateChange(event) {
    console.log(event);
    this.setState({
      end_date: moment(event).format("MM/DD/YYYY")
    });
  }

  handleTextChange = (key, type) => event => {
    this.setState({
      status: event.target.value
    });
  };

  getReportData() {
    this.props.actions.paymentReportAction(
      this.props.User,
      moment(this.state.start_date),
      moment(this.state.end_date),
      this.state.status
    );
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  User: state.authState.user.id,
  List: state.reportReducer.payment_report.data,
  Loading: state.reportReducer.payment_report.loading,
  Error: state.reportReducer.payment_report.error
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PaymentDetailReport);
