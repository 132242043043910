export const ASSET_LIST_REQUEST = "asset_list_request";
export const ASSET_LIST_FAILURE = "asset_list_failure";
export const ASSET_LIST_SUCCESS = "asset_list_success";

export const DELETE_REQUEST = "delete_request";
export const DELETE_FAILURE = "delete_failure";
export const DELETE_SUCCESS = "delete_success";

export const CREATE_FOLDER_REQUEST = "create_folder_request";
export const CREATE_FOLDER_FAILURE = "create_folder_failure";
export const CREATE_FOLDER_SUCCESS = "create_folder_success";

export const UPLOAD_FILE_REQUEST = "upload_file_request";
export const UPLOAD_FILE_FAILURE = "upload_file_failure";
export const UPLOAD_FILE_SUCCESS = "upload_file_success";
