import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { ActionCreators } from "../../redux/actions";
import * as routesNames from "../../constants/routes";

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      if (this.props.authenticated) {
        if (condition && !condition(this.props.authUser)) {
          this.props.actions.onNavigateTo(routesNames.LANDING);
        }
      } else if (
        this.props.location &&
        this.props.location.pathname !== routesNames.LANDING
      ) {
        this.props.actions.onNavigateTo(routesNames.LOGIN);
      }
    }

    render() {
      if (
        this.props.location &&
        this.props.location.pathname !== routesNames.LANDING
      ) {
        return this.props.authenticated ? <Component /> : null;
      } else {
        return <Component />;
      }
    }
  }

  const mapStateToProps = state => ({
    authUser: state.authState.user,
    authenticated: state.authState.authenticated
  });

  const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
  });

  return compose(
    withRouter,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(WithAuthorization);
};

export default withAuthorization;
