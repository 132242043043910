import React from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, withStyles, FormLabel } from "@material-ui/core";
import AgGrid from "../../../components/AgGrid/AgGrid.jsx";
import { ActionCreators } from "../../../redux/actions";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

class PartnerEventMapping extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            list: [],
            columnDefs: [
                {
                    headerName: "Partner",
                    field: "name",
                    width: 270,
                    resizable: true,
                    sortable: true,
                    filter: "agTextColumnFilter",
                    checkboxSelection: true,
                }
            ],
        }
        this.eventRefs = React.createRef()
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        this.props.actions.getEventPartnersAction(this.props.event, this.props.PartnerId)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.savemapping && !_.isEqual(nextProps.savemapping, this.props.savemapping)) {
            this.setState({
                open: false,
            }, () => {
                this.props.actions.getEventPartnersAction(this.props.event, this.props.PartnerId)
            })
        }

        if (nextProps.mapping && !_.isEqual(nextProps.mapping, this.props.mapping)) {
            this.setState({
                list: nextProps.mapping
            }, () => {
                let { list } = this.state
                let selectionIds = list.filter(x => x.selection).map(x => x.id)
                this.eventRefs.current.setSelections(
                    selectionIds,
                    "OTHER"
                );
            })
        }
    }

    closeModal = () => {
        this.props.close()
    }

    openModal() {
        let self = this
        let data = this.eventRefs.current.agGridApi.getSelectedRows();
        let selection = data.map(x => x.id)
        this.setState({
            open: true
        }, () => {
            self.eventRefs.current.setSelections(
                selection,
                "OTHER"
            );
        });
    }

    handleModalClose = () => {
        let self = this
        let data = this.eventRefs.current.agGridApi.getSelectedRows();
        let selection = data.map(x => x.id)
        this.setState({
            open: false
        }, () => {
            self.eventRefs.current.setSelections(
                selection,
                "OTHER"
            );
        });
    }

    saveMapping = () => {
        let data = this.eventRefs.current.agGridApi.getSelectedRows();
        let { list } = this.state
        list.forEach((x) => {
            if (data.findIndex(d => d.id === x.id) > -1) {
                x.selection = true
            } else {
                x.selection = false
            }
        })
        this.props.save(list, this.props.event);
    }

    renderConfirmation() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to save the changes you have made???
                        {this.props.error && this.props.error.trim() !== "" && (
                            <FormLabel className={classes.labelLeftHorizontal}>
                                <code>{this.props.error}</code>
                            </FormLabel>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={this.saveMapping}
                        autoFocus
                    >
                        Yes
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => this.handleModalClose()}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        return (
            <>
                {this.renderConfirmation()}
                <AgGrid
                    ref={this.eventRefs}
                    rowData={this.state.list}
                    columnDefs={this.state.columnDefs}
                    loading={this.props.loading}
                    rowSelection='multiple'
                />
                <div style={{ float: 'right' }}>
                    <Button
                        color="primary"
                        onClick={() => this.openModal()}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => this.closeModal()}
                        color="primary"
                    >
                        Close
                    </Button>
                </div>
            </>
        );
    }

}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
    UserId: state.authState.user.pk,
    PartnerId: state.authState.user.partner_id,
    mapping: state.eventState.event_partner.data,
    loading: state.eventState.event_partner.loading,
    error: state.eventState.event_partner.error,
    savemapping: state.eventState.create_event_partner.data,
    saving: state.eventState.create_event_partner.loading,
    mappingerror: state.eventState.create_event_partner.error,
});


export default compose(
    withRouter,
    withStyles({
        ...validationFormsStyle,
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(PartnerEventMapping);