import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { TextField, Checkbox, Tooltip } from "@material-ui/core";
import { HelpOutlineRounded, Check } from "@material-ui/icons";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import InputLabel from "@material-ui/core/InputLabel";
import VariantSelector from "./product-variant-selector";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

class ProductVariant extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            variants: [],
            variant_list: [],
            variantValues: [],
            assignedProducts: [],
        };
    }

    componentWillReceiveProps(nextProps) {

        console.log("this.props.data", this.props.data)
        console.log("this.props.templateCheck", this.props.templateCheck)

        if (nextProps && !_.isEqual(this.props, nextProps)) {

            if (
                nextProps.assignProductList &&
                nextProps.assignProductList.length > 0
            ) {
                this.setState({
                    assignedProducts: nextProps.assignProductList
                });
            }

            if (nextProps.list) {
                this.setState({
                    variant_list: nextProps.list
                });
            }

            if (nextProps.data && nextProps.data.variants) {
                this.setState({
                    variants: nextProps.data.variants
                });
            }

            if (nextProps.data && nextProps.data.variantValues) {
                this.setState({
                    variantValues: nextProps.data.variantValues
                });
            }
        }
    }

    componentDidMount() {
        this.refreshPartnerProductVariant()
    }

    refreshPartnerProductVariant = () => {
        if (this.props.PartnerId)
            this.props.actions.getProductAssignmentList(
                this.props.PartnerId
            );
    }

    handleAddVariantRow = () => {
        this.props.handleAddVariantRow()
    }

    handleVariantRemove = (index) => {
        this.props.handleVariantRemove(index)
    }

    handleVariantChange = (index, value) => {
        this.props.handleVariantChange(index, value)
    }

    handleVariantValueChange = (index, value) => {
        this.props.handleVariantValueChange(index, value)
    }

    handleToggleStatus = () => event => {
        this.props.setProductData(event.target.name, event.target.checked);
    };

    render() {
        const { classes } = this.props;
        const { is_default_variant_product } = this.props.data
        let prodVarOptions = []
        if (this.state.assignedProducts && this.state.assignedProducts.length > 0) {
            let res = this.props.assignProductList.filter(
                cls => cls.id != this.props.data.id
            );
            for (let i = 0; i < res.length; i++) {
              let obj = res[i];
              let var_index = this.state.variants.findIndex(x=>x.id==obj.id)
              if (var_index >-1){
                continue;  
              }else{
                prodVarOptions.push(res[i]);      
              }
            }
        }
        return (
            <GridContainer direction="row"
                justify="center"
                alignItems="center" >
                <GridItem lg={10} md={10}>
                    <form>
                        <GridContainer className="grid-container-control">
                            <GridItem lg={12} md={12} className="griditem-control">
                                <InputLabel className={classes.label + " "}>
                                    Variant Values
                                </InputLabel>
                                <br />
                                <VariantSelector
                                    variantMaster={this.props.variantMaster}
                                    variantList={this.props.variantsList}
                                    data={this.state.variantValues}
                                    isProductSelected={this.props.isProductSelected}
                                    handleAddVariantRow={this.handleAddVariantRow}
                                    handleVariantRemove={this.handleVariantRemove}
                                    handleVariantChange={this.handleVariantChange}
                                    handleVariantValueChange={this.handleVariantValueChange}
                                />
                            </GridItem>
                            {
                                !this.props.isProductSelected &&
                                <>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <GridItem lg={12} md={12} className="griditem-control">
                                        <InputLabel className={classes.label + " "}>
                                            Choose products with common variation
                                        </InputLabel>
                                        <br />
                                        <Autocomplete
                                            options={prodVarOptions}
                                            value={this.state.variants || []}
                                            getOptionLabel={option => (option.name ? option.name : "")}
                                            filterSelectedOptions
                                            name="brand"
                                            multiple
                                            onChange={(event, value) =>
                                                this.handleAutoCompleteChange("variants", value)
                                            }
                                            classes={{
                                                root: "custom-autocomplete",
                                                inputRoot: "custom-autocomplete-input-root",
                                                tag: "custom-autocomplete-tag"
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Select product with similar variant set"  /// RaviB Product Variant
                                                />
                                            )}
                                        />
                                    </GridItem>

                                </>

                            }
                            <GridItem lg={6} md={6} className="griditem-control">
                                <Checkbox
                                    className="checkbox-control"
                                    tabIndex={-1}
                                    checked={is_default_variant_product ? true : false}
                                    onClick={this.handleToggleStatus()}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                    name="is_default_variant_product"
                                />
                                {/* {this.setImageUploadState()} */}
                                <div className={classes.label}>
                                    <span>Default Variant</span>
                                    <Tooltip
                                        className="control-tooltip"
                                        title="Check if the product is already in use"
                                    >
                                        <HelpOutlineRounded />
                                    </Tooltip>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </form>
                </GridItem>
            </GridContainer>
        );
    }


    handleAutoCompleteChange = (name, value) => {
        this.props.onVariantGroupChange(value);
    };
}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({

    authUser: state.authState.user,

    PartnerId: state.authState.user.partner_id,
    UserId: state.authState.user.user,

    assignProductList: state.productsAssignmentsState.list,

    loading: state.productVariantState.list_product_variant.loading,
    error: state.productVariantState.list_product_variant.error,
    list: state.productVariantState.list_product_variant.variants,

});

export default compose(
    withStyles({
        ...regularFormsStyle,
        ...extendedTablesStyle,
        ...validationFormsStyle
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ProductVariant);
