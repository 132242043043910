import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  get_menu_master: {
    list: [],
    loading: true,
    error: null
  },
  create_menu_master: {
    saving: false,
    error: null,
    isSaved: false
  },
  save_mapping_menu_master: {
    mapping_saved: false
  },
  copy_menu: {
    list: [],
    loading: false,
    error: null
  }
};

const menuMasterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CREATE_MENU_MASTER_REQUEST:
      return {
        ...state,
        create_menu_master: {
          saving: true,
          error: null,
          isSaved: false
        }
      };

    case actionTypes.CREATE_MENU_MASTER_FAILURE:
      return {
        ...state,
        create_menu_master: {
          saving: false,
          error: action.payload,
          isSaved: false
        }
      };

    case actionTypes.CREATE_MENU_MASTER_SUCCESS:
      var data = state.get_menu_master.list;
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data[index] = action.payload;
      } else {
        //data.push(action.payload);
        data.splice(0, 0, action.payload);
      }

      return {
        ...state,
        create_menu_master: {
          saving: false,
          error: null,
          isSaved: true
        },
        get_menu_master: {
          list: data
        }
      };

    case actionTypes.GET_MENU_MASTER_REQUEST:
      return {
        ...state,
        create_menu_master: {
          saving: false,
          error: null,
          isSaved: false
        },
        get_menu_master: {
          list: [],
          loading: true,
          error: null
        }
      };
    case actionTypes.GET_MENU_MASTER_FAILURE:
      return {
        ...state,
        get_menu_master: {
          list: [],
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_MENU_MASTER_SUCCESS:
      return {
        ...state,
        get_menu_master: {
          list: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.COPY_MENU_REQUEST:
      return {
        ...state,
        copy_menu: {
          isSaved: false,
          loading: true,
          error: null
        }
      };

    case actionTypes.COPY_MENU_FAILURE:
      return {
        ...state,
        copy_menu: {
          isSaved: false,
          loading: false,
          error: action.payload
        }
      };

    case actionTypes.COPY_MENU_SUCCESS:
      var data = state.get_menu_master.list;
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data[index] = action.payload;
      } else {
        //data.push(action.payload);
        data.splice(0, 0, action.payload);
      }

      return {
        ...state,
        create_menu_master: {
          saving: false,
          error: null,
          isSaved: true
        },
        get_menu_master: {
          list: data
        },
        copy_menu: {
          isSaved: true,
          loading: false,
          error: null
        }
      };

    case actionTypes.COPY_MENU_UPDATE:
      return {
        ...state,
        copy_menu: {
          isSaved: false,
          loading: false,
          error: null
        }
      };

    default:
      return state;
  }
};

export default menuMasterReducer;
