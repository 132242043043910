import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  list: null,
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  isSaving: false,
  isdeleted: false,
  productById: {
    data: null,
    error: null,
    loading: false
  },
  brands: {
    data: null,
    error: null,
    loading: false
  },
  brand_product: {
    data: null,
    error: null,
    loading: false
  },
  product_template: {
    data: null,
    error: null,
    loading: false,
    pagination: {
      page: "1",
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  },
  pagination: {
    page: "1",
    next: null,
    prev: null,
    totalpages: 1,
    trecords: 0,
    pagesize: 10
  }
};

const productsAssignmentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PRODUCT_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        isSaving: true
      };
    case actionTypes.SAVE_PRODUCT_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaving: false
      };
    case actionTypes.SAVE_PRODUCT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isSaved: true,
        isSaving: false
      };

    case actionTypes.GET_PRODUCT_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        isSaving: true,
        list: null,
        pagination: {
          page: "1",
          next: null,
          prev: null,
          totalpages: 1,
          trecords: 0,
          pagesize: 10
        }
      };
    case actionTypes.GET_PRODUCT_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaving: false,
        list: null
      };
    case actionTypes.GET_PRODUCT_ASSIGNMENTS_SUCCESS:
      let pagination = _.clone(state.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        loading: false,
        error: null,
        isSaved: true,
        list: sdata,
        isSaving: false,
        pagination: {
          ...pagination
        }
      };
    case actionTypes.GET_PRODUCT_BYID_REQUEST:
      return {
        ...state,
        isSaved: false,
        productById: {
          data: null,
          error: null,
          loading: true
        }
      };
    case actionTypes.GET_PRODUCT_BYID_FAILURE:
      return {
        ...state,
        isSaved: false,
        productById: {
          data: null,
          error: action.payload,
          loading: false
        }
      };
    case actionTypes.GET_PRODUCT_BYID_SUCCESS:
      return {
        ...state,
        isSaved: false,
        productById: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case actionTypes.AFTER_SAVE_PRODUCT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        isSaved: false
      };

    case actionTypes.GET_RETAILERS_BRAND_REQUEST:
      return {
        ...state,
        isSaved: false,
        brands: {
          data: null,
          error: null,
          loading: true
        }
      };

    case actionTypes.GET_RETAILERS_BRAND_FAILURE:
      return {
        ...state,
        isSaved: false,
        brands: {
          data: null,
          error: action.payload,
          loading: false
        }
      };
    case actionTypes.GET_RETAILERS_BRAND_SUCCESS:
      return {
        ...state,
        isSaved: false,
        brands: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case actionTypes.GET_BRAND_PRODUCT_BYID_REQUEST:
      return {
        ...state,
        isSaved: false,
        brand_product: {
          data: null,
          error: null,
          loading: true
        }
      };

    case actionTypes.GET_BRAND_PRODUCT_BYID_FAILURE:
      return {
        ...state,
        isSaved: false,
        brand_product: {
          data: null,
          error: action.payload,
          loading: false
        }
      };
    case actionTypes.GET_BRAND_PRODUCT_BYID_SUCCESS:
      return {
        ...state,
        isSaved: false,
        brand_product: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    case actionTypes.PRODUCT_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        product_template: {
          data: null,
          error: null,
          loading: true,
          pagination: {
            page: "1",
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        }
      };

    case actionTypes.PRODUCT_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        product_template: {
          data: null,
          error: action.payload,
          loading: false
        }
      };
    case actionTypes.PRODUCT_TEMPLATE_LIST_SUCCESS:
      let t_pagination = _.clone(state.product_template.pagination);
      let sptdata = action.payload;
      if (sptdata && sptdata.results) {
        t_pagination.page = sptdata.current;
        t_pagination.next = sptdata.next;
        t_pagination.prev = sptdata.prev;
        t_pagination.totalpage = sptdata.total_pages;
        t_pagination.totalrecords = sptdata.total_records;
        t_pagination.pagesize = sptdata.page_size;
        sptdata = sptdata.results;
      }
      return {
        ...state,
        product_template: {
          data: sptdata,
          error: null,
          loading: false,
          pagination: {
            ...t_pagination
          }
        }
      };
    case actionTypes.MARK_PRODUCT_ACTIVE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true
      };

    case actionTypes.MARK_PRODUCT_ACTIVE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case actionTypes.MARK_PRODUCT_ACTIVE_SUCCESS:
      var productList = _.clone(state.list);
      var index = productList.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        productList[index] = action.payload;
      } else {
        productList.push(action.payload);
      }
      return {
        ...state,
        list: productList,
        error: null,
        loading: false
      };
    case actionTypes.DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        isdeleted: false,
      };

    case actionTypes.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isdeleted: false,
      };
    case actionTypes.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        isdeleted: true,
      };
    default:
      return state;
  }
};
export default productsAssignmentsReducer;
