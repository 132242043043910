import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  list: null,
  loading: false,
  error: null,

  addingPartner: {
    loading: false,
    error: null,
    partner: null,
    isSaved: false
  },

  brandPartner: {
    loading: false,
    error: null,
    list: null
  },

  partnerDetail: {
    loading: false,
    error: null,
    list: null
  },

  pagination: {
    page: "1",
    next: null,
    prev: null,
    totalpages: 1,
    trecords: 0,
    pagesize: 10
  },

  cloneSite: {
    loading: false,
    error: null,
    list: null,
    isSaved: false
  },

  publish: {
    isSaving: false,
    isSavingSuccess: false,
    error: null,
    success: null
  }
};

const partnerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RETAILER_PUBLISH_REQUEST:
      const { id } = action.payload;
      const clonedPartnerArray = JSON.parse(JSON.stringify(state.list || []));
      if (state && state.list) {
        const indexPartnerArray = clonedPartnerArray.findIndex(
          partner => partner.id === id
        );

        if (indexPartnerArray > -1) {
          clonedPartnerArray[indexPartnerArray].publishing = true;
          clonedPartnerArray[indexPartnerArray].publishError = null;
        }
      }

      return {
        ...state,
        list: clonedPartnerArray,
        publish: {
          isSaving: true,
          isSavingSuccess: false,
          error: null,
          success: null
        }
      };
    case actionTypes.RETAILER_PUBLISH_FAILURE:
      const retailerPublishFailureId = action.payload.id;
      const retailerPublishFailureData = action.payload.data;

      const clonedPartnerFailureArray = JSON.parse(
        JSON.stringify(state.list || [])
      );
      if (state && state.list) {
        const indexPartnerArray = clonedPartnerFailureArray.findIndex(
          partner => partner.id === retailerPublishFailureId
        );

        if (indexPartnerArray > -1) {
          clonedPartnerFailureArray[indexPartnerArray].publishing = false;
          clonedPartnerFailureArray[
            indexPartnerArray
          ].publishError = retailerPublishFailureData;
        }
      }

      return {
        ...state,
        list: clonedPartnerFailureArray,
        publish: {
          isSaving: false,
          isSavingSuccess: false,
          error: action.payload,
          success: null
        }
      };
    case actionTypes.RETAILER_PUBLISH_SUCCESS:
      const publishDataSuccessId = action.payload.id;

      const clonedPartnerSuccessArray = JSON.parse(
        JSON.stringify(state.list || [])
      );
      if (state && state.list) {
        const indexPartnerArray = clonedPartnerSuccessArray.findIndex(
          partner => partner.id === publishDataSuccessId
        );

        if (indexPartnerArray > -1) {
          clonedPartnerSuccessArray[indexPartnerArray].publishing = false;
          clonedPartnerSuccessArray[indexPartnerArray].publishError = null;
        }
      }

      return {
        ...state,
        list: clonedPartnerSuccessArray,
        publish: {
          isSaving: false,
          isSavingSuccess: true,
          error: null,
          success: action.payload
        }
      };

    case actionTypes.SAVE_PARTNER_REQUEST:
      return {
        ...state,
        addingPartner: {
          ...state.addingPartner,
          loading: true,
          error: null,
          partner: null,
          isSaved: false
        },
        partnerDetail: {
          loading: false,
          error: null,
          list: null
        }
      };
    case actionTypes.SAVE_PARTNER_FAILURE:
      return {
        ...state,
        addingPartner: {
          ...state.addingPartner,
          loading: false,
          error: action.payload,
          partner: null,
          isSaved: false
        },
        partnerDetail: {
          loading: false,
          error: null,
          list: null
        }
      };
    case actionTypes.SAVE_PARTNER_SUCCESS:
      return {
        ...state,
        // list: [...state.list, action.payload],
        // list: action.payload,
        addingPartner: {
          ...state.addingPartner,
          loading: false,
          error: null,
          partner: action.payload,
          isSaved: true
        },
        partnerDetail: {
          loading: false,
          error: null,
          list: null
        }
      };

    case actionTypes.UPDATE_PARTNER_REQUEST:
      return {
        ...state,
        addingPartner: {
          ...state.addingPartner,
          loading: true,
          error: null,
          partner: null
        },
        partnerDetail: {
          loading: false,
          error: null,
          list: null
        }
      };
    case actionTypes.UPDATE_PARTNER_FAILURE:
      return {
        ...state,
        addingPartner: {
          ...state.addingPartner,
          loading: false,
          error: action.payload,
          partner: null
        },
        partnerDetail: {
          loading: false,
          error: null,
          list: null
        }
      };
    case actionTypes.UPDATE_PARTNER_SUCCESS:
      const updatedPartner = action.payload.updatedPartner;
      const clonedArray = _.clone(state.list);
      const index = clonedArray.findIndex(
        partnerObject => partnerObject.api_key === action.payload.id
      );
      if (index > -1) {
        clonedArray.splice(index, 1);
      }
      clonedArray.unshift(updatedPartner);
      // clonedArray[index] = updatedPartner;
      // let list = []
      // list.push(updatedPartner)
      // for (let i = 0; i < clonedArray.length < 0; i++) {
      //     if (i !== index) {
      //         list.push(clonedArray[i])
      //     }
      // }
      return {
        ...state,
        list: clonedArray,
        addingPartner: {
          ...state.addingPartner,
          loading: false,
          error: null,
          partner: action.payload.updatedPartner,
          isSaved: true
        },
        partnerDetail: {
          loading: false,
          error: null,
          list: action.payload.updatedPartner
        }
      };

    case actionTypes.GET_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
        list: null,
        error: null,
        pagination: {
          page: "1",
          next: null,
          prev: null,
          totalpages: 1,
          trecords: 0,
          pagesize: 10
        }
      };
    case actionTypes.GET_PARTNER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        partnerDetail: {
          loading: false,
          error: null,
          list: null
        }
      };
    case actionTypes.GET_PARTNER_SUCCESS:
      let pagination = _.clone(state.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        list: sdata,
        loading: false,
        error: null,
        addingPartner: {
          loading: false
        },
        partnerDetail: {
          loading: false,
          error: null,
          list: null
        },
        pagination: {
          ...pagination
        }
      };
    case actionTypes.GET_BRAND_PARTNER_REQUEST:
      return {
        ...state,
        brandPartner: {
          ...state.brandPartner,
          loading: true,
          list: null,
          error: null
        },
        partnerDetail: {
          loading: false,
          error: null,
          list: null
        }
      };
    case actionTypes.GET_BRAND_PARTNER_FAILURE:
      return {
        ...state,
        brandPartner: {
          ...state.brandPartner,
          loading: false,
          list: null,
          error: action.payload
        },
        partnerDetail: {
          loading: false,
          error: null,
          list: null
        }
      };
    case actionTypes.GET_BRAND_PARTNER_SUCCESS:
      return {
        ...state,
        brandPartner: {
          ...state.brandPartner,
          loading: false,
          list: action.payload,
          error: null
        },
        partnerDetail: {
          loading: false,
          error: null,
          list: null
        }
      };

    case actionTypes.GET_PARTNER_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDetail: {
          ...state.partnerDetail,
          loading: false,
          list: action.payload,
          error: null
        }
      };

    case actionTypes.GET_PARTNER_DETAIL_REQUEST:
      return {
        ...state,
        partnerDetail: {
          ...state.partnerDetail,
          loading: true,

          error: null
        },
        addingPartner: {
          ...state.partnerDetail,
          isSaved: false
        }
      };
    case actionTypes.GET_PARTNER_DETAIL_FAILURE:
      return {
        ...state,
        partnerDetail: {
          ...state.partnerDetail,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.UPDATE_PARTNER_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDetail: {
          ...state.partnerDetail,
          loading: false,
          error: null,
          list: null
        }
      };

    // case actionTypes.CLONE_PARTNER_SITE_REQUEST:
    //     return {
    //         ...state,
    //         cloneSite: {
    //             ...state.cloneSite,
    //             loading: true,
    //             error: null,
    //             list: null,
    //             isSaved: false
    //         }
    //     };
    // case actionTypes.CLONE_PARTNER_SITE_FAILURE:
    //     return {
    //         ...state,
    //         cloneSite: {
    //             ...state.cloneSite,
    //             loading: false,
    //             error: action.payload,
    //             list: null,
    //             isSaved: false
    //         }
    //     };
    // case actionTypes.CLONE_PARTNER_SITE_SUCCESS:
    //
    //     return {
    //         ...state,
    //         cloneSite: {
    //             ...state.cloneSite,
    //             loading: false,
    //             error: null,
    //             list: null,
    //             isSaved: true
    //         }
    //     }

    default:
      return state;
  }
};

export default partnerReducer;
