import React, { Component } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import List from "@material-ui/icons/List";
import moment from "moment";
function dateFormatter(params) {
  if (params) {
    return moment(params).format("MM/DD/YYYY");
  } else {
    return "";
  }
}
class UserAction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  invokeParentMethod() {
    const { data } = this.props;
    const { is_Stock } = this.props.context.componentParent.props;
    return (
      <div
        style={{
          lineHeight: "60px"
        }}
      >
        <span onClick={() => this.performAction(data.id, 0)} title="Edit">
          <Edit className="grid_action_btn" />
        </span>
        <span
          title="Show Menu List"
          onClick={() => this.performAction(data.id, 3)}
        >
          <List className="grid_action_btn" />
        </span>
        <span
          onClick={() => this.performAction(data.id, 2)}
          title="View or edit mapping with Industries"
        >
          <List className="grid_action_btn" />
        </span>
        <span onClick={() => this.performAction(data.id, 1)} title="Delete">
          <DeleteIcon className="grid_action_btn" />
        </span>
      </div>
    );
  }

  performAction(id, value) {
    switch (value) {
      case 0:
        this.props.context.componentParent.handelEdit(id);
        break;
      case 1:
        this.props.context.componentParent.delete(id, 1);
        break;
      case 2:
        this.props.context.componentParent.map(id);
        break;
      case 3:
        this.props.context.componentParent.view(id);
        break;
    }
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default UserAction;
