import React, { Component } from "react";
import Person from "@material-ui/icons/Person";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import List from "@material-ui/icons/List";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import CircularProgress from "@material-ui/core/CircularProgress";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PublishIcon from "@material-ui/icons/Publish";
const Style = {
  icon: {
    color: "#615f5f",
    fontSize: "20px",
    cursor: "pointer"
  }
};

class partnerAgGridActionsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  invokeParentMethod() {
    const { data } = this.props;

    return (
      <div
        style={{
          "line-height": "60px"
        }}
      >
        {data && data.email && (
          <span
            span
            onClick={() => this.signinUser(data.email, 0)}
            title="Log in as Partner "
            style={{ "padding-right": "4px" }}
          >
            <Person style={Style.icon} />
          </span>
        )}

        <span
          onClick={() => this.handelEdit(data.id)}
          title="Edit Details"
          style={{ "padding-right": "4px" }}
        >
          <CreateIcon style={Style.icon} />
        </span>

        <span
          onClick={() => window.open("https://" + data.deploy_url, "_blank")}
          title="View Website"
          style={{ "padding-right": "4px" }}
        >
          <VisibilityIcon style={Style.icon} />
        </span>

        <span
          onClick={() => this.mapPartnerServices(data.id, data.api_key)}
          title="Assign Services"
          style={{ "padding-right": "4px" }}
        >
          <PhonelinkSetupIcon style={Style.icon} />
        </span>

        {/* <span
          onClick={() => this.handelCloneSite(data.id, data.api_key)}
          title="Clone site"
          style={{ "padding-right": "4px" }}
        >
          <FileCopyIcon style={Style.icon} />
        </span> */}

        {data.publishing ? (
          <span
            onClick={() => this.handleSitePublish(data.id)}
            title="Publishing partner data, please wait."
            style={{ "padding-right": "4px" }}
          >
            <CircularProgress style={Style.icon} size={20} />
          </span>
        ) : (
          <span
            onClick={() => this.handleSitePublish(data.id)}
            title="Publish Partner data"
            style={{ "padding-right": "4px" }}
          >
            <PublishIcon style={Style.icon} />
          </span>
        )}

        <span
          onClick={() => this.handelPasswordChange(data.id, data.api_key)}
          title="Change Password"
          style={{ "padding-right": "4px" }}
        >
          <VpnKeyOutlinedIcon style={Style.icon} />
        </span>

        {data && data.role && data.role == "RE" && (
          <span
            span
            onClick={() => this.handelSettlementOpen(data.id, data.api_key)}
            title="View Pending Settlement"
            style={{ "padding-right": "4px" }}
          >
            <MonetizationOnIcon style={Style.icon} />
          </span>
        )}

        {data && data.role && (data.role == "RE" || data.role == "WS") && (
          <span
            onClick={() =>
              this.editBrandTemplateMapping(data.id, data.role, "brand")
            }
            title="View or edit mapping with Brands"
            style={{ "padding-right": "4px" }}
          >
            {/* <List style={Style.icon} /> */}
            <FormatBoldIcon style={Style.icon} />
          </span>
        )}

        {data && data.role && data.role == "RE" && (
          <span
            span
            onClick={() =>
              this.editRetailerWholesalerMapping(
                data.id,
                data.role,
                "wholesaler"
              )
            }
            title="View or edit mapping with Wholesaler"
          >
            <List style={Style.icon} />
          </span>
        )}
      </div>
    );
  }

  performAction(id, value) {
    switch (value) {
      case 0:
        this.props.context.componentParent.signinUser(id);
        break;
    }
  }
  signinUser(email) {
    this.props.context.componentParent.signinUser(email);
  }
  handelSettlementOpen(id, api_key) {
    this.props.context.componentParent.handelSettlementOpen(id, api_key);
  }
  editBrandTemplateMapping(id, role, action) {
    // this.props.context.componentParent.editBrandTemplateMapping(id, role);
    // let action = 'brand'
    this.props.context.componentParent.editBrandTemplateMapping(
      id,
      role,
      action
    );
  }

  editRetailerWholesalerMapping(id, role, action) {
    // this.props.context.componentParent.editRetailerWholesalerMapping(id);
    // let action = 'wholesaler'
    this.props.context.componentParent.editBrandTemplateMapping(
      id,
      role,
      action
    );
  }
  editProductTemplateMapping(id, role) {
    this.props.context.componentParent.editRetailerWholesalerMapping(id);
    // this.props.context.componentParent.editProductTemplateMapping(id, role)
  }
  handelEdit(id) {
    this.props.context.componentParent.handelEdit(id);
  }

  handleSitePublish(id) {
    this.props.context.componentParent.handleSitePublish(id);
  }

  handelPasswordChange(id, api_key) {
    this.props.context.componentParent.handelPasswordChange(id, api_key);
  }
  handelAssignPages(id, api_key) {
    this.props.context.componentParent.handelAssignCMSPages(id, api_key);
  }

  handelCloneSite(id, api_key) {
    this.props.context.componentParent.handelCloneSite(id, api_key);
  }

  handelCloneSite(id, api_key) {
    this.props.context.componentParent.handelCloneSite(id, api_key);
  }

  mapPartnerServices(id) {
    this.props.context.componentParent.mapPartnerServices(id);
  }
  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default partnerAgGridActionsButton;
