// import axios from 'axios';
import axios from "../utils/axios.jsx";
export const getoffertypeList = async id => {
  try {
    const response = await axios.get("/api/admin/offertype/list/");
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveoffertype = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("/api/admin/offertype/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
