import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";

// core components
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Table from "components/Table/Table.jsx";

const Style = {
  cardTitle: {
    "font-size": "x-large",
    "font-weight": "normal"
  },
  title: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "bold",
    "font-size": "36px",
    "line-height": "42px",
    "text-align": "center",
    color: "#666666"
  },
  titlePara: {
    "text-align": "center"
  },
  tabHeader: {
    color: "#919699"
  },
  selectedTabHeader: {
    color: "#F44336"
  },
  tabHeaderDiv: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "14px",
    "line-height": "16px",
    display: "flex",
    "align-items": "center",
    "text-align": "center",
    "letter-spacing": "0.75px",
    "text-transform": "uppercase",
    display: "inline-block",
    padding: "15px",
    cursor: "pointer",
    width: "16.66%"
  },
  questionHead: {
    "font-weight": "500"
  },
  pagination: {
    "font-weight": "normal",
    "font-size": "14px",
    "line-height": "20px",
    color: "#666666"
  }
};

const initialState = {
  total_page: 1,
  current_page: 1
};

class PartnerTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        ...initialState
      },
      campaignData: {},
      retailers: [],
      retailersList: []
    };
    this.clickNextBtn = this.clickNextBtn.bind(this);
    this.clickPreviousBtn = this.clickPreviousBtn.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.saveCampign = this.saveCampign.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.retailers) {
        this.setState({
          retailersList: nextProps.retailers
        });
      }
      if (!_.isEqual(this.props, nextProps)) {
        if (!_.isEqual(this.props.data, nextProps.data)) {
          this.setState({
            campaignData: nextProps.data
          });
        }
      }
    }
  }

  componentDidMount() {
    this.props.actions.getBrandRelatedPartners(this.props.PartnerId);
    if (this.props.data) {
      this.setState({
        campaignData: this.props.data
      });
    }
  }

  render() {
    const { current_page, total_page } = this.state.pagination;
    return (
      <Grid>
        <GridContainer>
          <GridItem lg={2} md={2} />
          <GridItem lg={8} md={8} sm={12} xs={12}>
            <GridContainer>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <div style={Style.pagination}>
                  Page: {current_page}/{total_page}
                </div>
                {this.showPartnerTabControlBasedOnPage()}
              </GridItem>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <GridContainer>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button onClick={this.clickPreviousBtn}>Previous</Button>
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button onClick={this.clickNextBtn}>Next</Button>
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button
                      disabled={this.props.isSaving}
                      onClick={() => this.saveCampign(1)}
                      style={{
                        "margin-left": "10px"
                      }}
                    >
                      Save & Close
                    </Button>
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button
                      onClick={this.closeWizard}
                      style={{
                        "margin-left": "10px"
                      }}
                    >
                      Close
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem lg={2} md={2} />
        </GridContainer>
      </Grid>
    );
  }

  closeWizard() {
    this.props.closePromotion();
  }

  clickNextBtn() {
    let { current_page, total_page } = this.state.pagination;
    let new_page = current_page + 1;
    if (new_page <= total_page) {
      this.setState({
        ...this.state,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    } else {
      this.props.changeSelectedTab(1);
    }
  }

  clickPreviousBtn() {
    let { current_page } = this.state.pagination;
    let new_page = current_page - 1;
    if (new_page >= 1) {
      this.setState({
        ...this.state,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    } else {
      this.props.changeSelectedTab(-1);
    }
  }

  getPrimaryTabTableData = () => {
    const { classes } = this.props;
    var returnData = [];

    const { retailersList } = this.state;

    _.map(retailersList, (partner, index) => {
      returnData.push([
        <Checkbox
          tabIndex={-1}
          onClick={() => this.handleRetailerCheckbox(partner.id)}
          checked={this.handleCheckRetailers(partner.id)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot
          }}
        />,
        <span style={{ minWidth: "40%" }}>{partner.name}</span>,

        <span style={{ minWidth: "40%" }}>
          {partner.status == 0 ? "Allowed" : "Blocked"}
        </span>
      ]);
    });

    return returnData;
  };

  showPartnerTabControlBasedOnPage() {
    const { classes } = this.props;
    const { current_page } = this.state.pagination;
    const { retailersList } = this.state;
    switch (current_page) {
      case 1:
        return (
          <GridContainer>
            <div>
              <div className="campaign-info-label">
                <div style={Style.questionHead}>
                  Select Partner you would like to include in this Campaign.
                </div>
              </div>
              <br />
              <GridContainer>
                <Table
                  tableShopping
                  tableHead={[
                    // "Sort #",
                    "",
                    "Partner",
                    "Access"
                  ]}
                  tableData={this.getPrimaryTabTableData()}
                />

                {/* 
                                {
                                    _.map(retailersList, (partner, index) => (
                                        <GridItem Lg={4} md={4} sm={6} xs={12} >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={() => this.handleRetailerCheckbox(partner.id)}
                                                        checked={this.handleCheckRetailers(partner.id)}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}
                                                    />
                                                }
                                                classes={{ label: classes.label }}
                                                label={partner.name}
                                            />
                                        </GridItem>
                                    ))
                                } */}
              </GridContainer>
            </div>
          </GridContainer>
        );
        break;
    }
  }

  saveCampign = filter => {
    this.props.save(filter);
  };

  handleCheckRetailers(id) {
    const { retailers } = this.state.campaignData;
    let return_type = false;
    if (retailers.indexOf(id) !== -1) {
      return_type = true;
    }
    return return_type;
  }
  handleRetailerCheckbox = id => {
    const { retailers } = this.state.campaignData;
    let retailer_index = retailers.indexOf(id);
    if (retailer_index == -1) {
      retailers.push(id);
    } else {
      retailers.splice(retailer_index, 1);
    }
    this.setState({
      campaignData: {
        ...this.state.campaignData,
        retailers: retailers
      }
    });
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  retailers: state.partnerSate.brandPartner.list,
  channels: state.campignManagementReducer.channels.list,
  channelDistribution: state.campignManagementReducer.channeldistribution.list,
  List: state.campignManagementReducer.list,
  Loading: state.campignManagementReducer.loading,
  ListError: state.campignManagementReducer.error,
  isSaved: state.campignManagementReducer.isSaved,
  PartnerId: state.authState.user.partner_id,
  User: state.authState.user,
  UserId: state.authState.user.user,
  playPause: state.campignManagementReducer.playPause,
  deleteCampaign: state.campignManagementReducer.deleteCampaign,
  isSaving: state.campignManagementReducer.isSaving
});

PartnerTab.propTypes = {
  closePromotion: PropTypes.func.isRequired,
  changeSelectedTab: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setPromotionData: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PartnerTab);
