import React from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import _ from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import { rolesNames } from "../../constants";

import * as routesNames from "../../constants/routes";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { LoadingOverlay, Loader } from "react-overlay-loader";

let AggridApi;

let agGridViewApi = null;
let agGridEditApi = null;

class RetailerWholesalerMappingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Retailer_name: "",
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          sortable: true,
          filter: true,
          hide: true
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          filter: true,
          checkboxSelection: true,
          width: 200,
          suppressSizeToFit: true
        },
        {
          headerName: "Description",
          field: "description",
          sortable: true,
          filter: true
        },
        {
          headerName: "Active",
          field: "is_active",
          sortable: true,
          filter: true,
          width: 100,
          suppressSizeToFit: true
        }
      ],

      viewColumnDefs: [
        {
          headerName: "ID",
          field: "id",
          sortable: true,
          filter: true,
          hide: true
        },

        {
          headerName: "Name",
          field: "name",
          sortable: true,
          filter: true,
          width: 200,
          suppressSizeToFit: true
        },
        {
          headerName: "Description",
          field: "description",
          sortable: true,
          filter: true,
          width: 320
        },
        {
          headerName: "Active",
          field: "is_active",
          sortable: true,
          filter: true,
          width: 200,
          suppressSizeToFit: true
        }
      ],

      rowData: null,
      rows: [],
      rowEdit: [],
      updateMapping: false,
      wholesaler: null,
      domLayout: "autoHeight",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      },
      mappingData: {
        partner_id: null,
        partners_id: []
      },
      entityData: [],
      partnersIs: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (nextProps.List && nextProps.List.length) {
          const classData = nextProps.List;
          const arr = [];
          const arrData = [];
          const partnersIs = [];
          let search = window.location.search;
          let params = new URLSearchParams(search);
          let entityId = params.get("id");
          let Index = classData.findIndex(o => o.id == entityId);
          arr.push(classData[Index]);
          for (var i = 0; i < arr[0].partners.length; i++) {
            if (arr[0].partners[i].partners.role == "WS") {
              arrData.push(arr[0].partners[i].partners);
              partnersIs.push(arr[0].partners[i].partners.id);
            }
          }

          this.setState(() => ({
            rows: arrData,
            partnersIs: partnersIs
          }));

          if (agGridViewApi) agGridViewApi.sizeColumnsToFit();
        }
        if (nextProps.partners) {
          this.setState(
            {
              rowEdit: nextProps.partners ? nextProps.partners.data : []
            },
            () => {
              if (this.state.rowEdit && this.state.rowEdit.length > 0) {
                this.setSelectedIndexes(this.state.rowEdit);
                if (agGridEditApi) agGridEditApi.sizeColumnsToFit();
              }
            }
          );
        }

        if (agGridEditApi) agGridEditApi.sizeColumnsToFit();
      }
      if (nextProps.Error && nextProps.Error.trim() !== "") {
        this.setState(() => ({
          error: nextProps.Error,
          isAddNew: true
        }));
      }
      if (nextProps.isSaved) {
        this.handleCancelMode();
      }
    }
    let Retailer_name = window.sessionStorage.getItem("Retailer_Name");
    this.setState({
      Retailer_name: Retailer_name
    });
  }

  componentDidMount() {
    if (this.props.List && this.props.List.length) {
      this.setState({
        classData: this.props.List
      });
    }
    this.props.actions.getBrand(rolesNames.RoleMaster.RETAILER);
  }

  setSelectedIndexes = products => {
    this.onGridRowSelected();

    if (this.state.rowEdit) {
      return;
    }
  };

  onGridRowSelected() {
    const componentThis = this;
    agGridEditApi.forEachNode(function(node) {
      if (node.data) {
        if (componentThis.state.partnersIs.indexOf(node.data.id) > -1) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      }
    });
  }

  onGridViewReady = params => {
    this.gridApi = params.api;
    agGridViewApi = _.clone(params.api, true);
  };
  onGridEditReady = params => {
    this.gridApi = params.api;
    agGridEditApi = _.clone(params.api, true);
  };

  closeDialog = () => {
    this.props.onClose();
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    AggridApi = _.clone(this.gridApi, true);
  };
  handleCancel = () => {
    this.props.actions.onNavigateTo(routesNames.CREATEWEBSITE);
  };

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    var length = this.state.mappingData.partners_id.length;
    var i = 0;
    const componentThis = this;
    for (i = 0; i < length; i++) {
      componentThis.state.mappingData.partners_id.pop();
    }
    selectedRows.forEach(function(selectedRow, index) {
      componentThis.state.mappingData.partners_id.push(selectedRow.id);
    });
  }
  handleMode = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let entityId = params.get("id");
    this.props.actions.getRelatedPartnerAction(
      rolesNames.RoleMaster.WHOLESALER,
      entityId
    );
    this.setState(
      {
        updateMapping: !this.state.updateMapping
      },
      () => {
        if (agGridEditApi) agGridEditApi.sizeColumnsToFit();
      }
    );
  };

  handleCancelMode = () => {
    this.props.actions.getBrand(rolesNames.RoleMaster.RETAILER);
    this.setState(
      {
        updateMapping: !this.state.updateMapping
      },
      () => {
        if (agGridViewApi) agGridViewApi.sizeColumnsToFit();
      }
    );
  };

  handleSave = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let entityId = params.get("id");
    this.state.mappingData.partner_id = parseInt(entityId);
    this.props.actions.mapWholesalerBrand(
      this.props.UserId.pk,
      this.state.mappingData,
      rolesNames.RoleMaster.WHOLESALER
    );
  };

  renderBrand = () => {
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  View, edit or assign new Wholesaler to{" "}
                  {this.state.Retailer_name}.
                </p>
                <p>
                  <Button
                    color="success"
                    onClick={() => this.handleMode()}
                    className={classes.marginRight + " grid-header-button"}
                  >
                    <AddIcon className="grid-header-button-icon" /> Manage
                    Assigned Wholesaler
                  </Button>
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <div>
                  <div
                    id="myGrid"
                    style={{
                      width: "100%"
                    }}
                    className="ag-theme-material"
                  >
                    <AgGridReact
                      columnDefs={this.state.viewColumnDefs}
                      rowSelection="multiple"
                      defaultColDef={this.state.defaultColDef}
                      statusBar={this.state.statusBar}
                      showToolPanel={true}
                      // floatingFilter={true}
                      sideBar={this.state.sideBar}
                      onSelectionChanged={this.onSelectionChanged.bind(this)}
                      pagination={true}
                      paginationAutoPageSize={true}
                      rowData={this.state.rows}
                      onGridReady={this.onGridViewReady}
                      domLayout={this.state.domLayout}
                    />
                  </div>
                </div>
                <div>
                  <Button onClick={() => this.handleCancel()}>Cancel</Button>
                </div>
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderUpdateBrand = () => {
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <p
                className={
                  classes.cardIconTitle + " wholesalor-hierarchy-title"
                }
              >
                View, edit or assign new Wholesaler to{" "}
                {this.state.Retailer_name}.
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <div>
                  <div
                    id="myGrid"
                    style={{
                      width: "100%"
                    }}
                    className="ag-theme-material"
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowSelection="multiple"
                      defaultColDef={this.state.defaultColDef}
                      statusBar={this.state.statusBar}
                      showToolPanel={true}
                      // floatingFilter={true}
                      sideBar={this.state.sideBar}
                      onSelectionChanged={this.onSelectionChanged.bind(this)}
                      pagination={true}
                      paginationAutoPageSize={true}
                      rowData={this.state.rowEdit ? this.state.rowEdit : []}
                      onGridReady={this.onGridEditReady}
                      domLayout={this.state.domLayout}
                    />
                  </div>
                </div>
                <div>
                  <Button color="info" onClick={() => this.handleSave()}>
                    Save Changes
                  </Button>
                  <Button onClick={() => this.handleCancelMode()}>
                    Cancel
                  </Button>
                </div>
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    return (
      <div>
        {" "}
        {this.state.updateMapping
          ? this.renderUpdateBrand()
          : this.renderBrand()}
      </div>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.brandState.list,
  Loading: state.brandState.loadingData,
  ListError: state.brandState.error,
  isSaved: state.brandState.isSaved,
  isDeleted: state.classReducer.isDeleted,
  UserId: state.authState.user,
  brandList: state.brandState.map_wholesaler_brand,
  isMapSaved: state.industriesReducer.isMapSaved,
  partners: state.brandState.partner_partners
});

RetailerWholesalerMappingComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  entityData: PropTypes.object,
  selectedIndexes: PropTypes.array.isRequired
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RetailerWholesalerMappingComponent);
