import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  get: {
    order: [],
    loading: false,
    loaderror: null,
    detail: {
      order: {},
      loading: false,
      loaderror: null
    },
    pagination: {
      page: 1,
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  }
};

const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDER_REQUEST:
      return {
        ...state,
        get: {
          order: [],
          loading: true,
          loaderror: null,
          detail: {
            order: {},
            loading: false,
            loaderror: null
          },
          pagination: {
            page: 1,
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        }
      };
    case actionTypes.GET_ORDER_FAILURE:
      return {
        ...state,
        get: {
          loading: false,
          loaderror: action.payload,
          detail: {
            order: {},
            loading: false,
            loaderror: null
          }
        }
      };
    case actionTypes.GET_ORDER_SUCCESS:
      let pagination = _.clone(state.get.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        get: {
          order: sdata,
          loading: false,
          loaderror: null,
          detail: {
            order: {},
            loading: false,
            loaderror: null
          },
          pagination: {
            ...pagination
          }
        }
      };
    case actionTypes.GET_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        get: {
          ...state.get,
          detail: {
            order: {},
            loading: true,
            loaderror: null
          }
        }
      };
    case actionTypes.GET_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          detail: {
            ...state.get.detail,
            loading: false,
            loaderror: action.payload
          }
        }
      };
    case actionTypes.GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          detail: {
            order: action.payload,
            loading: false,
            loaderror: null
          }
        }
      };

    case actionTypes.CHANGE_ORDER_STATUS_REQUEST:
      return {
        ...state,
        get: {
          ...state.get,
          detail: {
            ...state.get.detail,
            loading: true,
            loaderror: null
          }
        }
      };
    case actionTypes.CHANGE_ORDER_STATUS_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          detail: {
            ...state.get.detail,
            loading: false,
            loaderror: action.payload
          }
        }
      };
    case actionTypes.CHANGE_ORDER_STATUS_SUCCESS:
      let orderList = _.clone(state.get.order);
      let index = orderList.findIndex(x => x.id === action.payload.id);
      if (index > -1) {
        orderList[index] = action.payload;
      }
      return {
        ...state,
        get: {
          ...state.get,
          order: orderList,
          loading: false,
          loaderror: null,
          detail: {
            order: action.payload,
            loading: false,
            loaderror: null
          }
        }
      };
    default:
      return state;
  }
};

export default orderReducer;
