// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const createProductCMS = async (
  name,
  template,
  parent,
  id,
  industry
) => {
  try {
    let data = new FormData();
    data.append("name", name);
    data.append("parent", parent ? parent.id : null);
    data.append("template_config", JSON.stringify(template));
    data.append("industry_id", industry ? industry.id : null);
    let url = "api/producttype/create/";
    let response = {};
    if (id) {
      url = "api/producttype/update/" + id + "/";
      response = await axios.put(url, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    } else {
      response = await axios.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductCMSList = async () => {
  try {
    const response = await axios.get("/api/producttype/list/");
    return response.data;
  } catch (error) {
    throw error;
  }
};
