import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  list: null,
  menulist: null,
  loading: false,
  error: null,
  isRemoveImage: false,
  removeImage: {
    data: null,
    loading: false,
    error: null,
    isDeleted: false
  },
  package: {
    menu: null,
    error: null,
    loading: false,
  }
};

const appMenuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.MENU_LIST_REQUEST:
      return {
        ...state,
        menulist: null,
        loading: true,
        error: null,
        removeImage: {
          ...state.removeImage,
          loading: false,
          error: null,
          isDeleted: false
        }
      };

    case actionTypes.MENU_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        menulist: null,
        removeImage: {
          ...state.removeImage,
          loading: false,
          error: null,
          isDeleted: false
        }
      };

    case actionTypes.MENU_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        menulist: action.payload,
        removeImage: {
          ...state.removeImage,
          loading: false,
          error: null,
          isDeleted: false
        }
      };

    case actionTypes.PACKAGE_MENU_LIST_REQUEST:
      return {
        ...state,
        package: {
          ...state.package,
          menu: [],
          error: null,
          loading: true,
        }
      };

    case actionTypes.PACKAGE_MENU_LIST_FAILURE:
      return {
        ...state,
        package: {
          ...state.package,
          error: action.payload,
          loading: false,
        }
      };

    case actionTypes.PACKAGE_MENU_LIST_SUCCESS:
      return {
        ...state,
        package: {
          ...state.package,
          menu: action.payload,
          loading: false,
        }
      };
    case actionTypes.USER_BASED_MENU_LIST_REQUEST:
      return {
        ...state,
        list: null,
        loading: true,
        error: null,
        removeImage: {
          ...state.removeImage,
          loading: false,
          error: null,
          isDeleted: false
        }
      };

    case actionTypes.USER_BASED_MENU_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        list: null,
        removeImage: {
          ...state.removeImage,
          loading: false,
          error: null,
          isDeleted: false
        }
      };

    case actionTypes.USER_BASED_MENU_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        list: action.payload,
        removeImage: {
          ...state.removeImage,
          loading: false,
          error: null,
          isDeleted: false
        }
      };

    case actionTypes.IMAGE_REMOVE_REQUEST:
      return {
        ...state,
        removeImage: {
          data: action.payload,
          loading: false,
          error: null,
          isDeleted: false
        },
        isRemoveImage: true
      };

    case actionTypes.DENY_IMAGE_REMOVE_REQUEST:
      return {
        ...state,
        removeImage: {
          data: null,
          loading: false,
          error: null,
          isDeleted: false
        },
        isRemoveImage: false
      };

    case actionTypes.CONFIRM_IMAGE_REMOVE_REQUEST:
      return {
        ...state,
        removeImage: {
          ...state.removeImage,
          loading: true,
          error: null,
          isDeleted: false
        }
      };

    case actionTypes.CONFIRM_IMAGE_REMOVE_FAILURE:
      return {
        ...state,
        removeImage: {
          ...state.removeImage,
          loading: false,
          error: action.payload,
          isDeleted: false
        }
      };

    case actionTypes.CONFIRM_IMAGE_REMOVE_SUCCESS:
      return {
        ...state,
        removeImage: {
          ...state.removeImage,
          loading: false,
          error: null,
          isDeleted: true
        },
        isRemoveImage: true
      };

    default:
      return state;
  }
};

export default appMenuReducer;
