import * as retailerServices from "../../services/SegmentService";
import * as actionTypes from "./actionTypes";

export const getSegmentsList = (
  id,
  industryId,
  templateid,
  page = 0,
  searchtext = null
) => async dispatch => {
  dispatch(getSegmentRequest());
  try {
    const success = await retailerServices.getSegmentsList(
      id,
      industryId,
      templateid,
      page,
      searchtext
    );
    dispatch(getSegmentSuccess(success.data));
  } catch (error) {
    dispatch(getSegmentFailure(error.message));
  }
};

const saveSegmentRequest = () => {
  return {
    type: actionTypes.SAVE_SEGMENT_REQUEST
  };
};

const saveSegmentSuccess = data => {
  return {
    type: actionTypes.SAVE_SEGMENT_SUCCESS,
    payload: data
  };
};

const saveSegmentFailure = error => {
  return {
    type: actionTypes.SAVE_SEGMENT_FAILURE,
    payload: error
  };
};

const deleteSegmentRequest = () => {
  return {
    type: actionTypes.DELETE_SEGMENT_REQUEST
  };
};

const deleteSegmentSuccess = (data, Id) => {
  return {
    type: actionTypes.DELETE_SEGMENT_SUCCESS,
    payload: data,
    segmentId: Id
  };
};

const deleteSegmentFailure = error => {
  return {
    type: actionTypes.DELETE_SEGMENT_FAILURE,
    payload: error
  };
};

const getSegmentRequest = () => {
  return {
    type: actionTypes.GET_SEGMENT_REQUEST
  };
};

const getSegmentSuccess = data => {
  return {
    type: actionTypes.GET_SEGMENT_SUCCESS,
    payload: data
  };
};

const getSegmentFailure = error => {
  return {
    type: actionTypes.GET_SEGMENT_FAILURE,
    payload: error
  };
};

export const unassignedWholesalorListAction = id => async dispatch => {
  dispatch(unassignedWholesalorListRequest());
  try {
    const success = await retailerServices.unassignedWholesalorList(id);
    dispatch(unassignedWholesalorListSuccess(success.data));
  } catch (error) {
    dispatch(unassignedWholesalorListFailure(error.message));
  }
};

const unassignedWholesalorListRequest = () => {
  return {
    type: actionTypes.LIST_UNASSIGNED_WHOLESALER_REQUEST
  };
};

const unassignedWholesalorListSuccess = data => {
  return {
    type: actionTypes.LIST_UNASSIGNED_WHOLESALER_SUCCESS,
    payload: data
  };
};

const unassignedWholesalorListFailure = error => {
  return {
    type: actionTypes.LIST_UNASSIGNED_WHOLESALER_FAILURE,
    payload: error
  };
};

export const saveRetailerWholesalerMappingAction = (
  data,
  partnerid
) => async dispatch => {
  dispatch(saveRetailerWholesalerMappingRequest());
  try {
    const success = await retailerServices.saveRetailersWholesaler(
      data,
      partnerid
    );
    dispatch(saveRetailerWholesalerMappingSuccess(success.data));
  } catch (error) {
    dispatch(saveRetailerWholesalerMappingFailure(error.message));
  }
};

const saveRetailerWholesalerMappingRequest = () => {
  return {
    type: actionTypes.SAVE_RETAILER_WHOLESALER_MAPPING_REQUEST
  };
};

const saveRetailerWholesalerMappingSuccess = data => {
  return {
    type: actionTypes.SAVE_RETAILER_WHOLESALER_MAPPING_SUCCESS,
    payload: data
  };
};

const saveRetailerWholesalerMappingFailure = error => {
  return {
    type: actionTypes.SAVE_RETAILER_WHOLESALER_MAPPING_FAILURE,
    payload: error
  };
};
