import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@material-ui/core/FormLabel";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { validationUtils } from "../../utils";

// @material-ui/core components/Dialog
import Slide from "@material-ui/core/Slide";

import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import NotificationTypeAction from "../../components/AgGrid/NotificationTypeAction";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class NotificationType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      isAddNew: false,
      addNotificationData: {
        name: "",
        description: "",
        id: null,
        is_deleted: false,
        is_active: false,
        templateId: null
      },
      required: {
        name: "",
        description: "",
        is_active: ""
      },
      NotificationData: [],
      error: null,
      openDeleteDialog: false,
      NotificationId: null,
      NotificationValue: null,
      selectedUAIndexes: [],
      mappingData: {
        notifications: null,
        description: "",
        is_active: true
      },
      templateList: [],
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          width: 150,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter",
        },
        {
          headerName: "NAME",
          field: "name",
          width: 200,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter"
        },
        {
          headerName: "DESCRIPTION",
          field: "description",
          width: 200,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter"
        },
        {
          headerName: "STATUS",
          field: "is_active",
          width: 200,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter",
          // filterParams: {
          //     defaultToNothingSelected: true,
          // }
        },

        {
          headerName: "ACTIONS",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 240,
          filter: false
        }
      ]
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      let NotificationData = [];
      if (nextProps.List && nextProps.List.length) {
        NotificationData = nextProps.List;
      }
      this.setState(() => ({
        NotificationData: NotificationData,
        pagination: nextProps.pagination
      }));

      if (nextProps.Error && nextProps.Error.trim() !== "") {
        this.setState(() => ({
          error: nextProps.Error,
          isAddNew: true
        }));
      }
      if (nextProps.isSaved) {
        this.setState(
          {
            isAddNew: false,
            isEdit: false
          },
          () => {
            this.props.actions.getNotificationList(this.state.pagination.page);
          }
        );
      }
      if (nextProps.isDeleted) {
        this.setState(() => ({
          openDeleteDialog: false
        }));
      }
      if (nextProps.templateList) {
        this.setState({
          templateList: nextProps.templateList
        });
      }
    }
  }

  componentDidMount() {
    this.props.actions.getNotificationList(this.state.pagination.page);
  }

  componentWillMount() {
    this.props.actions.getTemplateList(this.props.UserId);
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      addNotificationData: {
        name: "",
        description: "",
        is_active: false,
        is_deleted: false
      },
      required: {
        name: "",
        description: "",
        is_active: ""
      },
      isEdit: false
    });
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });
    this.setState({
      addNotificationData: {
        ...this.state.addNotificationData,
        [key]: event.target.value
      }
    });
  };

  handleToggleStatus = curObj => event => {
    this.setState({
      addNotificationData: {
        ...this.state.addNotificationData,
        is_active: event.target.checked
      }
    });
  };

  saveNotification = () => {
    if (this.validateIsRequired()) {
      let savingData = this.state.addNotificationData;
      savingData.template = "";
      if (!this.state.isEdit) {
        let NotificationData = _.cloneDeep(this.state.NotificationData);
        // NotificationData.push(savingData)
        this.setState({
          NotificationData: NotificationData
        });
      }
      this.props.actions.saveNotification(this.props.UserId, savingData);
    }
  };

  handelEdit = id => {
    // this.props.actions.getTemplateList(this.props.UserId);
    const { isEdit, NotificationData } = this.state;
    const data = NotificationData;
    let index = data.findIndex(p => p.id === id);
    if (data[index]) {
      this.setState({
        addNotificationData: {
          name: data[index].name,
          templateId: data[index].template.id,
          description: data[index].description,
          index: index,
          id: data[index].id,
          is_active: data[index].is_active,
          is_deleted: false
        },
        isAddNew: true,
        isEdit: true
      });
    }
  };

  render() {
    return (
      // this.renderNotificationTable()
      this.state.isAddNew
        ? this.renderNotification()
        : this.renderNotificationTable()
    );
  }
  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addNotificationData.hasOwnProperty("name") ||
      (this.state.addNotificationData.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addNotificationData.hasOwnProperty("description") ||
      (this.state.addNotificationData.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  renderNotification = () => {
    const { classes } = this.props;
    const { name, description, is_active, id } = this.state.addNotificationData;
    const { required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange(
                        "name",
                        validationUtils.validationTypes.name
                      )
                    }}
                    id={"name_"}
                  />

                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="Template *"
                    >
                      Template Type
                    </InputLabel>
                    <Select
                      // success={required.segmentName === "success"}
                      // error={required.segmentName === "error"}
                      style={{ marginTop: "0px" }}
                      value={id}
                      onChange={this.handleTextChange("templateId", "")}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Template Type
                      </MenuItem>
                      {_.map(this.state.templateList, ({ id, name }, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={id}
                            key={index + "_" + id}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <CustomInput
                    success={required.description === "success"}
                    error={required.description === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: this.handleTextChange("description", "")
                    }}
                    id={"description_"}
                  />
                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus(is_active)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />
                  <br />
                  {this.props.Error && this.props.Error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button
                    color="info"
                    onClick={this.saveNotification.bind(this)}
                  >
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {this.props.isSaving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.isSaving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
  renderNotificationTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  View, edit or delete Notification Type below.
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.NotificationData}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                AgGridActionButton={NotificationTypeAction}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                loading={this.props.Loading}
                search={this.searchText}
                new={this.handelIsAddNew}
                isAddBtn={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getNotificationList(page, this.state.searchtext);
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getNotificationList(page, this.state.searchtext);
      }
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  templateList: state.templateReducer.list,
  List: state.NotificationtypeReducer.list,
  Loading: state.NotificationtypeReducer.loading,
  ListError: state.NotificationtypeReducer.error,
  isSaved: state.NotificationtypeReducer.isSaved,
  isDeleted: state.NotificationtypeReducer.isDeleted,
  UserId: state.authState.user.user,
  isSaving: state.NotificationtypeReducer.isSaving,
  pagination: state.NotificationtypeReducer.pagination
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NotificationType);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
