import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  get_message: {
    list: null,
    loading: false,
    error: null
  }
};

const messageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_MESSAGE_REQUEST:
      return {
        ...state,
        get_message: {
          list: null,
          loading: true,
          error: null
        }
      };
    case actionTypes.GET_MESSAGE_FAILURE:
      return {
        ...state,
        get_message: {
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_MESSAGE_SUCCESS:
      return {
        ...state,
        get_message: {
          list: action.payload,
          loading: false,
          error: null
        }
      };
    default:
      return state;
  }
};

export default messageReducer;
