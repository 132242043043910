import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Slide from "@material-ui/core/Slide";
import PartnerCampaignList from "./PartnerCampaignList";
import PartnerCampaignView from "./PartnerCampaignView";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class PartnerCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      ViewId: ""
    };

    this.closePromotionWizard = this.closePromotionWizard.bind(this);
  }

  componentWillReceiveProps(nextProps) {}

  toggleFormView = visible => {
    this.setState({
      isAddNew: visible,
      ViewId: ""
    });
  };

  handleEdit = id => {
    console.log("Campaign id", id);
    this.setState({
      isAddNew: true,
      ViewId: id
    });
  };

  render() {
    return (
      <>
        {this.state.isAddNew
          ? this.renderComponentsView()
          : this.renderComponentsList()}
      </>
    );
  }

  closePromotionWizard() {
    this.setState({
      ...this.state,
      isAddNew: false,
      ViewId: ""
    });
  }

  renderComponentsView = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <PartnerCampaignView
            isEditMode={true}
            selectedCampaignId={this.state.ViewId}
            closePromotionWizard={this.closePromotionWizard}
          />
        </GridItem>
      </GridContainer>
    );
  };

  renderComponentsList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <PartnerCampaignList
            addNew={this.toggleFormView}
            handleEdit={this.handleEdit}
          />
        </GridItem>
      </GridContainer>
    );
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserId: state.authState.user.user
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PartnerCampaign);
