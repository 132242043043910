export const GET_STOCK_LOCATION_REQUEST = "get_stock_location_request";
export const GET_STOCK_LOCATION_FAILURE = "get_stock_location_failure";
export const GET_STOCK_LOCATION_SUCCESS = "get_stock_location_success";

export const GET_STOCK_LOCATION_BYID_REQUEST =
  "get_stock_location_byid_request";
export const GET_STOCK_LOCATION_BYID_FAILURE =
  "get_stock_location_byid_failure";
export const GET_STOCK_LOCATION_BYID_SUCCESS =
  "get_stock_location_byid_success";

export const DELETE_STOCK_LOCATION_REQUEST = "delete_stock_location_request";
export const DELETE_STOCK_LOCATION_FAILURE = "delete_stock_location_failure";
export const DELETE_STOCK_LOCATION_SUCCESS = "delete_stock_location_success";

export const CREATE_STOCK_LOCATION_REQUEST =
  "create_stock_location_byid_request";
export const CREATE_STOCK_LOCATION_FAILURE =
  "create_stock_location_byid_failure";
export const CREATE_STOCK_LOCATION_SUCCESS =
  "create_stock_location_byid_success";

export const ADD_STOCK_REQUEST = "add_stock_request";
export const ADD_STOCK_FAILURE = "add_stock_failure";
export const ADD_STOCK_SUCCESS = "add_stock_success";

export const STOCK_LIST_REQUEST = "stock_list_request";
export const STOCK_LIST_FAILURE = "stock_list_failure";
export const STOCK_LIST_SUCCESS = "stock_list_success";

export const STOCK_BYID_REQUEST = "stock_byid_request";
export const STOCK_BYID_FAILURE = "stock_byid_failure";
export const STOCK_BYID_SUCCESS = "stock_byid_success";

export const GET_PRODUCTS_NETSTOCK_REQUEST = "get_products_netstock_request";
export const GET_PRODUCTS_NETSTOCK_FAILURE = "get_products_netstock_failure";
export const GET_PRODUCTS_NETSTOCK_SUCCESS = "get_products_netstock_success";

export const GET_PRODUCTS_STOCKMOVEDATA_REQUEST =
  "get_products_stockmovedata_request";
export const GET_PRODUCTS_STOCKMOVEDATA_FAILURE =
  "get_products_stockmovedata_failure";
export const GET_PRODUCTS_STOCKMOVEDATA_SUCCESS =
  "get_products_stockmovedata_success";
