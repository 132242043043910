export const PAYMENT_REPORT_REQUEST = "payment_report_request";
export const PAYMENT_REPORT_FAILURE = "payment_report_failure";
export const PAYMENT_REPORT_SUCCESS = "payment_report_success";

export const SETTLEMENT_REPORT_REQUEST = "settlement_report_request";
export const SETTLEMENT_REPORT_FAILURE = "settlement_report_failure";
export const SETTLEMENT_REPORT_SUCCESS = "settlement_report_success";

export const ORDER_REPORT_REQUEST = "order_report_request";
export const ORDER_REPORT_FAILURE = "order_report_failure";
export const ORDER_REPORT_SUCCESS = "order_report_success";

export const REPORT_OPTION_REQUEST = "report_option_request";
export const REPORT_OPTION_FAILURE = "report_option_failure";
export const REPORT_OPTION_SUCCESS = "report_option_success";

export const DOWNLOAD_REPORT_REQUEST = "download_report_request";
export const DOWNLOAD_REPORT_FAILURE = "download_report_failure";
export const DOWNLOAD_REPORT_SUCCESS = "download_report_success";
