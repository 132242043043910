import axios from "../utils/axios.jsx";
import { localStorageUtils } from "../utils";

export const createFolder = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("/api/admin/asset/create/folder/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadFile = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    data.append("file", Savedata.document_object);
    const response = await axios.post("/api/admin/asset/upload/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAssetList = async (userID, folderId, type, page) => {
  try {
    let url =
      "/api/admin/asset/get/?userid=" +
      userID +
      "&folderid=" +
      folderId +
      "&type=" +
      type +
      "&page=" +
      page;

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFileFolder = async (userID, id, type) => {
  try {
    let data = new FormData();
    data.append("userid", userID);
    data.append("id", id);
    data.append("type", type);
    const response = await axios.post(
      "/api/admin/asset-folder/delete/",
      data,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
