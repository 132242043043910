import axios from "../utils/axios.jsx";
export const getclassList = async (
  partnerid,
  classType,
  isGetAllList,
  page,
  searchtext,
  prodTempId
) => {
  try {
    let _classType = null;
    if (classType) {
      _classType = classType;
    } else {
      _classType = 1;
    }
    let url =
      "/api/classes/getall/?partnerid=" +
      partnerid +
      "&class_type=" +
      _classType;

    if (isGetAllList) {
      url = url + "&is_get_all=" + isGetAllList;
    }

    if (page) {
      url = url + "&page=" + page;
    }
    if (searchtext) {
      url = url + "&search=" + searchtext;
    }

    if (prodTempId) {
      url = url + "&prodtempid=" + prodTempId;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveclass = async (userId, Savedata, partnerId) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("partnerid", partnerId);
    data.append("file", Savedata.image);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("/api/classes/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClassById = async id => {
  try {
    const response = await axios.get(
      "/api/classes-by-id/get/?id=" + id,
      null,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteclass = async (userId, segment) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("segment", segment);

    const response = await axios.post("/api/classes/delete/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteSegments = async (userId, class_id) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("classes ", class_id);

    const response = await axios.post("/api/classes/delete/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const mapClassProductTemplate = async (userId, mapData) => {
// 	try {
// 		let data = new FormData();
// 		data.append("userid", userId)
// 		data.append("data", JSON.stringify(mapData));

// 		const response = await axios.post('/api/classes/producttemplate/create/', data);
// 		return response.data;
// 	} catch (error) {
// 		throw error;
// 	}
// }

export const mapClassUA = async (userId, mapData) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(mapData));

    const response = await axios.post(
      "/api/classes/attributegroup/create/",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveCategoy = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("/api/partner/categories/save/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRetailerClassProductHierarchy = async (
  partnerid,
  page,
  search
) => {
  try {
    let url =
      "/api/partner/class-product/get/?partnerid=" +
      partnerid +
      "&page=" +
      page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClassProductTemplateHierarchy = async (
  partnerid,
  page,
  search
) => {
  try {
    if (!partnerid) {
      partnerid = "";
    }
    let url =
      "/api/partner/class-product-template/get/?partnerid=" +
      partnerid +
      "&page=" +
      page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPartnersClasses = async (partnerid) => {
  try {
    const response = await axios.get(
      "/api/partner/classes/get/?partnerid=" + partnerid,
      null,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
