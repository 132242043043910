import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "../../style/app.css";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "components/CustomButtons/Button.jsx";
import pricingStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/pricingStyle.jsx";

let AggridApi;

class CMSComponents extends React.Component {
  constructor(props) {
    super(props);
    this.toggleComponent = this.toggleComponent.bind(this);
    this.state = {
      userGroupId: "",
      data: {},
      menus: [],
      columnDefs: [
        {
          headerName: "Id",
          field: "id",
          width: 100,
          resizable: true,
          checkboxSelection: true
        },
        {
          headerName: "Key",
          field: "key",
          width: 200,
          resizable: true
        },
        {
          headerName: "Name",
          field: "name",
          width: 200,
          resizable: true
        }
      ],
      context: { componentParent: this },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      domLayout: "autoHeight",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      getNodeChildDetails: function(data) {
        if (data && data.pages && data.pages.length > 0) {
          let children = [];
          children = data.pages;
          return {
            group: true,
            children: children,
            expanded: data.open
          };
        } else {
          return null;
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.appMenus) {
      this.setState(
        {
          ...this.state,
          menus: nextProps.appMenus
        },
        () => {}
      );
    }
  }

  refreshList() {
    this.props.actions.getCMSComponents();
  }

  componentDidMount() {
    this.refreshList();
  }

  render() {
    return <div>{this.renderOrderTable()}</div>;
  }

  isSelected(id) {
    if (this.props.selected_items && this.props.selected_items.length > 0) {
      if (this.props.selected_items.indexOf(id) > -1) {
        return true;
      }
    }

    return false;
  }

  toggleComponent(id) {
    let selectedItems = this.props.selected_items;
    if (this.props.selected_items && this.props.selected_items.length > 0) {
      let indexOfItem = selectedItems.indexOf(id);

      if (indexOfItem > -1) {
        selectedItems.splice(indexOfItem, 1);
      } else {
        selectedItems.push(id);
      }
    } else {
      selectedItems = new Array();
      selectedItems.push(id);
    }

    this.props.setSelectedItems(selectedItems);
  }

  renderOrderTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <p
                className={
                  classes.cardIconTitle + " wholesalor-hierarchy-title"
                }
                style={{ color: "black" }}
              >
                Components
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <GridContainer>
                  {_.map(
                    this.state.menus,
                    ({ id, name, key, description, preview_image }, index) => {
                      return (
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                          <Card pricing>
                            <CardBody pricing>
                              <h6
                                className={`${classes.cardDescription} ${
                                  classes.marginBottom20
                                }`}
                              >
                                {key}
                              </h6>
                              <div style={{ height: "100px" }}>
                                {preview_image && preview_image.url && (
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      margin: "auto"
                                    }}
                                    src={preview_image.url}
                                  />
                                )}
                              </div>
                              <h4
                                className={`${classes.cardTitle} ${
                                  classes.marginTop30
                                }`}
                              >
                                {name}
                              </h4>
                              <p className={classes.cardDescription}>
                                {description}
                              </p>

                              {this.isSelected(id) ? (
                                <Button
                                  size="sm"
                                  round
                                  color="rose"
                                  onClick={() => this.toggleComponent(id)}
                                  disabled={this.props.disableSelected}
                                >
                                  {this.props.disableSelected
                                    ? "Already Selected"
                                    : "Remove Component"}
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  round
                                  color="primary"
                                  onClick={() => this.toggleComponent(id)}
                                >
                                  Add Component
                                </Button>
                              )}
                            </CardBody>
                          </Card>
                        </GridItem>
                      );
                    }
                  )}
                </GridContainer>

                {this.props.loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    let selectedIds = [];
    if (selectedRows) {
      selectedIds = _.flatMap(selectedRows, data => {
        return data.id;
      });
      this.props.setSelectedItems(selectedIds);
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  authUser: state.authState.user,
  appMenus: state.cmsReducer.get_CMS_Components.list,
  loading: state.cmsReducer.get_CMS_Components.loading,
  error: state.cmsReducer.get_CMS_Components.error
});

CMSComponents.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func,
  save: PropTypes.func,
  type: PropTypes.string.isRequired
};

export default compose(
  withStyles(pricingStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CMSComponents);
