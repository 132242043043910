import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";

// core components
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import axios from "../../../../utils/axios.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const Style = {
  cardTitle: {
    "font-size": "x-large",
    "font-weight": "normal"
  },
  title: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "bold",
    "font-size": "36px",
    "line-height": "42px",
    "text-align": "center",
    color: "#666666"
  },
  titlePara: {
    "text-align": "center"
  },
  tabHeader: {
    color: "#919699"
  },
  selectedTabHeader: {
    color: "#F44336"
  },
  tabHeaderDiv: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "14px",
    "line-height": "16px",
    display: "flex",
    "align-items": "center",
    "text-align": "center",
    "letter-spacing": "0.75px",
    "text-transform": "uppercase",
    display: "inline-block",
    padding: "15px",
    cursor: "pointer",
    width: "16.66%"
  },
  questionHead: {
    "font-weight": "500"
  },
  pagination: {
    "font-weight": "normal",
    "font-size": "14px",
    "line-height": "20px",
    color: "#666666"
  }
};

const initialState = {
  total_page: 1,
  current_page: 1
};

class CampaignInfoTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogState: false,
      pagination: {
        ...initialState
      },
      campaignData: {}
    };
    this.clickPreviousBtn = this.clickPreviousBtn.bind(this);
    this.clickNextBtn = this.clickNextBtn.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.saveCampign = this.saveCampign.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (!_.isEqual(this.props.data, nextProps.data)) {
          this.setState({
            campaignData: nextProps.data
          });
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        campaignData: this.props.data
      });
    }
  }

  render() {
    const { data } = this.props;
    const { current_page, total_page } = this.state.pagination;
    return (
      <>
        {this.confirmDialog()}
        <Grid>
          <GridContainer>
            <GridItem lg={2} md={2} />
            <GridItem lg={8} md={8} sm={12} xs={12}>
              <GridContainer>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  {this.showCampignInfoControlsBasedOnPage()}
                </GridItem>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  <GridContainer>
                    <GridItem lg={3} md={3} sm={3} xs={3}>
                      {current_page > 1 ? (
                        <Button onClick={this.clickPreviousBtn}>
                          Previous
                        </Button>
                      ) : (
                        ""
                      )}
                    </GridItem>
                    <GridItem lg={3} md={3} sm={3} xs={3}>
                      <Button onClick={this.clickNextBtn}>Next</Button>
                    </GridItem>

                    <GridItem lg={3} md={3} sm={3} xs={3}>
                      <Button
                        onClick={this.closeWizard}
                        style={{
                          "margin-left": "10px"
                        }}
                      >
                        Close
                      </Button>
                    </GridItem>
                    <GridItem lg={3} md={3} sm={3} xs={3}>
                      <div style={Style.pagination}>
                        {current_page}/{total_page}
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem lg={2} md={2} />
          </GridContainer>
        </Grid>
      </>
    );
  }

  closeWizard() {
    this.props.closePromotion();
  }

  clickNextBtn() {
    let { current_page, total_page } = this.state.pagination;
    let new_page = current_page + 1;
    if (new_page <= total_page) {
      this.setState({
        ...this.state,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    } else {
      this.props.changeSelectedTab(1);
    }
  }

  clickPreviousBtn() {
    let { current_page } = this.state.pagination;
    let new_page = current_page - 1;
    if (new_page >= 1) {
      this.setState({
        ...this.state,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    }
  }

  handleStatusChange = (key, type) => async event => {
    try {
      let data = new FormData();
      data.append("partner_id", this.props.PartnerId);
      data.append("campaign_id", this.state.campaignData.id);
      data.append("status", event.target.value);

      const response = await axios.post("/api/retailer/campaign/status/", data);
      if (response.data && response.data.status === "1") {
        this.setState({
          ...this.state,
          dialogState: true,
          campaignData: {
            ...this.state.campaignData,
            partner_approved: event.target.value
          }
        });
      }
    } catch (error) {
      console.log("Status change error", error);
    }
  };

  dialogState = state => {
    this.setState({
      dialogState: state
    });
  };

  confirmDialog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.dialogState}
        disableBackdropClick={false}
        onClose={() => this.dialogState(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>Campaign status updated. </DialogContentText>
          <DialogActions>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              onClick={() => this.dialogState(false)}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  };

  showCampignInfoControlsBasedOnPage() {
    console.log("this.state.campaignData", this.state.campaignData);
    const {
      name,
      start_date,
      end_date,
      navigation_url,
      required,
      brand,
      partner_approved
    } = this.state.campaignData;
    const { classes } = this.props;
    const { current_page } = this.state.pagination;
    switch (current_page) {
      case 1:
        return (
          <GridContainer>
            <br />
            <GridItem lg={3} md={3} sm={3} xs={3}>
              <b>Campaign Name:</b>
            </GridItem>
            <GridItem lg={9} md={9} sm={9} xs={9}>
              <b>{name}</b>
            </GridItem>

            {brand && (
              <>
                <GridItem lg={3} md={3} sm={3} xs={3}>
                  <b>Brand Name:</b>
                </GridItem>
                <GridItem lg={9} md={9} sm={9} xs={9}>
                  <b>{brand}</b>
                </GridItem>
              </>
            )}
            <br />
            <GridItem lg={3} md={3} sm={3} xs={3}>
              <b>Start Date:</b>
            </GridItem>
            <GridItem lg={9} md={9} sm={9} xs={9}>
              <b>{start_date}</b>
            </GridItem>

            <br />
            <GridItem lg={3} md={3} sm={3} xs={3}>
              <b>End Date:</b>
            </GridItem>
            <GridItem lg={9} md={9} sm={9} xs={9}>
              <b>{end_date}</b>
            </GridItem>
            <br />
            <GridItem lg={3} md={3} sm={3} xs={3}>
              <b>Navigation Url:</b>
            </GridItem>
            <GridItem lg={9} md={9} sm={9} xs={9}>
              <b>{navigation_url}</b>
            </GridItem>

            <br />
            <br />

            <GridItem lg={3} md={3} sm={3} xs={3}>
              <b>Status:</b>
            </GridItem>
            <GridItem lg={3} md={6} sm={9} xs={9}>
              <FormControl fullWidth>
                <Select
                  defaultValue={1}
                  style={{ marginTop: "0px" }}
                  value={partner_approved}
                  onChange={this.handleStatusChange()}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Change Status
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={1}
                    key={1}
                  >
                    Approve
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={0}
                    key={0}
                  >
                    Reject
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
        );
        break;
    }
  }

  handleTextChange = (key, type) => event => {
    this.props.setPromotionData(key, event.target.value);
  };

  handleStartDateChange = event => {
    this.props.setPromotionData(
      "start_date",
      moment(event).format("MM/DD/YYYY")
    );
  };

  handleEndDateChange = event => {
    this.props.setPromotionData("end_date", moment(event).format("MM/DD/YYYY"));
  };
  saveCampign = filter => {
    this.props.save(filter);
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  retailers: state.partnerSate.brandPartner.list,
  channels: state.campignManagementReducer.channels.list,
  channelDistribution: state.campignManagementReducer.channeldistribution.list,
  List: state.campignManagementReducer.list,
  Loading: state.campignManagementReducer.loading,
  ListError: state.campignManagementReducer.error,
  isSaved: state.campignManagementReducer.isSaved,
  PartnerId: state.authState.user.partner_id,
  User: state.authState.user,
  UserId: state.authState.user.user,
  playPause: state.campignManagementReducer.playPause,
  deleteCampaign: state.campignManagementReducer.deleteCampaign,
  isSaving: state.campignManagementReducer.isSaving
});

CampaignInfoTab.propTypes = {
  closePromotion: PropTypes.func.isRequired,
  changeSelectedTab: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setPromotionData: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CampaignInfoTab);
