// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const getIndustriesTypeList = async (id, type) => {
  try {
    let url = "/api/industry/getall/";
    if (type && type === "list") {
      url = url + "?type=list";
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveIndustriesType = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("/api/industry/create/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteIndustries = async (userId, segment) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("industry", segment);

    const response = await axios.post("/api/industry/delete/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const mapIndustriesToSegment = async (userId, mapData) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(mapData));

    const response = await axios.post("/api/industry/segment/mapping/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
