import * as messageServices from "../../services/messageServices";
import * as actionTypes from "./actionTypes";

export const getMessageList = apikey => async dispatch => {
  dispatch(getMessageListRequest());
  try {
    var success = await messageServices.getMessageList(apikey);
    if (success.status && success.status === "1") {
      dispatch(getMessageListSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getMessageListFailure(success.error));
    } else {
      dispatch(getMessageListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getMessageListFailure(error.message));
  }
};

const getMessageListRequest = () => {
  return {
    type: actionTypes.GET_MESSAGE_REQUEST
  };
};

const getMessageListSuccess = data => {
  return {
    type: actionTypes.GET_MESSAGE_SUCCESS,
    payload: data
  };
};

const getMessageListFailure = error => {
  return {
    type: actionTypes.GET_MESSAGE_FAILURE,
    payload: error
  };
};
