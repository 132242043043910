import * as productServices from "../../services/productServices";
import * as actionTypes from "./actionTypes";

export const getAllProductTemplates = (
  partnerid,
  page = 0,
  searchtext = null
) => async dispatch => {
  dispatch(getAllProductTemplatesRequest());
  try {
    const response = await productServices.getAllProductTemplates(
      partnerid,
      page,
      searchtext
    );
    if (response.status === "1") {
      dispatch(getAllProductTemplatesSuccess(response.data));
    } else {
      dispatch(getAllProductTemplatesFailure(response.error));
    }
  } catch (error) {
    dispatch(getAllProductTemplatesFailure(error.message));
  }
};

export const createProductTemplates = (
  userID,
  data,
  partnerID
) => async dispatch => {
  dispatch(createProductTemplatesRequest());
  try {
    const response = await productServices.createProductTemplates(
      userID,
      data,
      partnerID
    );
    if (response.status === "1") {
      dispatch(createProductTemplatesSuccess(response.data));
    } else {
      dispatch(createProductTemplatesFailure(response.error));
    }
  } catch (error) {
    dispatch(createProductTemplatesFailure(error.message));
  }
};

export const saveUniversalAttribute = (userID, data) => async dispatch => {
  dispatch(createProductTemplatesRequest());
  try {
    const response = await productServices.saveUniversalAttribute(userID, data);
    if (response.status === "1") {
      dispatch(createProductTemplatesSuccess(response.data));
    } else {
      dispatch(createProductTemplatesFailure(response.error));
    }
  } catch (error) {
    dispatch(createProductTemplatesFailure(error.message));
  }
};

export const getAllCategorisedProductTemplates = () => async dispatch => {
  dispatch(getAllCategorisedProductTemplatesRequest());
  try {
    const response = await productServices.getAllCategorisedProductTemplates();
    if (response.status === "1") {
      dispatch(getAllCategorisedProductTemplatesSuccess(response.data));
    } else {
      dispatch(getAllCategorisedProductTemplatesFailure(response.error));
    }
  } catch (error) {
    dispatch(getAllCategorisedProductTemplatesFailure(error.message));
  }
};

export const getAllBrandsCategorisedProductTemplates = partnerid => async dispatch => {
  dispatch(getAllBrandsCategorisedProductTemplatesRequest());
  try {
    const response = await productServices.getAllBrandsCategorisedProductTemplates(
      partnerid
    );
    if (response.status === "1") {
      dispatch(getAllBrandsCategorisedProductTemplatesSuccess(response.data));
    } else {
      dispatch(getAllBrandsCategorisedProductTemplatesFailure(response.error));
    }
  } catch (error) {
    dispatch(getAllBrandsCategorisedProductTemplatesFailure(error.message));
  }
};

//// Save Mapping of class and product template
export const mapClassProductTemplate = (id, mapData) => async dispatch => {
  dispatch(saveClassPtRequest());
  try {
    const success = await productServices.mapClassProductTemplate(id, mapData);
    if (success.status === "1") {
      dispatch(createProductTemplatesSuccess(success.data));
      dispatch(saveClassPtSuccess(success.data));
    } else {
      dispatch(
        saveClassPtFailure("Unable to map classes with product template")
      );
    }
  } catch (error) {
    dispatch(saveClassPtFailure(error.message));
  }
};

const getAllProductTemplatesRequest = () => {
  return {
    type: actionTypes.GET_PRODUCT_TEMPLATE_REQUEST
  };
};

const getAllProductTemplatesSuccess = data => {
  return {
    type: actionTypes.GET_PRODUCT_TEMPLATE_SUCCESS,
    payload: data
  };
};

const getAllProductTemplatesFailure = error => {
  return {
    type: actionTypes.GET_PRODUCT_TEMPLATE_FAILURE,
    payload: error
  };
};

const createProductTemplatesRequest = () => {
  return {
    type: actionTypes.CREATE_PRODUCT_TEMPLATE_REQUEST
  };
};

const createProductTemplatesSuccess = data => {
  return {
    type: actionTypes.CREATE_PRODUCT_TEMPLATE_SUCCESS,
    payload: data
  };
};

const createProductTemplatesFailure = error => {
  return {
    type: actionTypes.CREATE_PRODUCT_TEMPLATE_FAILURE,
    payload: error
  };
};

const saveClassPtRequest = () => {
  return {
    type: actionTypes.MAP_CLASS_PT_REQUEST
  };
};

const saveClassPtSuccess = data => {
  return {
    type: actionTypes.MAP_CLASS_PT_SUCCESS,
    payload: data
  };
};

const saveClassPtFailure = error => {
  return {
    type: actionTypes.MAP_CLASS_PT_FAILURE,
    payload: error
  };
};

const getAllCategorisedProductTemplatesRequest = () => {
  return {
    type: actionTypes.GET_CATEGORISED_PRODUCT_TEMPLATE_REQUEST
  };
};

const getAllCategorisedProductTemplatesSuccess = data => {
  return {
    type: actionTypes.GET_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS,
    payload: data
  };
};

const getAllCategorisedProductTemplatesFailure = error => {
  return {
    type: actionTypes.GET_CATEGORISED_PRODUCT_TEMPLATE_FAILURE,
    payload: error
  };
};

const getAllBrandsCategorisedProductTemplatesRequest = () => {
  return {
    type: actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_REQUEST
  };
};

const getAllBrandsCategorisedProductTemplatesSuccess = data => {
  return {
    type: actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS,
    payload: data
  };
};

const getAllBrandsCategorisedProductTemplatesFailure = error => {
  return {
    type: actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_FAILURE,
    payload: error
  };
};

export const saveProductTemplateAction = (
  userID,
  data,
  files
) => async dispatch => {
  dispatch(saveProductTemplatesRequest());
  try {
    const response = await productServices.saveProductTemplate(
      userID,
      data,
      files
    );
    if (response.status === "1") {
      dispatch(saveProductTemplatesSuccess(response.data));
    } else {
      dispatch(saveProductTemplatesFailure(response.error));
    }
  } catch (error) {
    dispatch(saveProductTemplatesFailure(error.message));
  }
};
const saveProductTemplatesRequest = () => {
  return {
    type: actionTypes.SAVE_PRODUCT_TEMPLATE_REQUEST
  };
};

const saveProductTemplatesSuccess = data => {
  return {
    type: actionTypes.SAVE_PRODUCT_TEMPLATE_SUCCESS,
    payload: data
  };
};

const saveProductTemplatesFailure = error => {
  return {
    type: actionTypes.SAVE_PRODUCT_TEMPLATE_FAILURE,
    payload: error
  };
};

export const getProductTemplateByIdAction = id => async dispatch => {
  dispatch(getProductTemplateByIdRequest());
  try {
    const response = await productServices.getProductTemplateById(id);
    if (response.status === "1") {
      dispatch(getProductTemplateByIdSuccess(response.data));
    } else {
      dispatch(getProductTemplateByIdFailure(response.error));
    }
  } catch (error) {
    dispatch(getProductTemplateByIdFailure(error.message));
  }
};

const getProductTemplateByIdRequest = () => {
  return {
    type: actionTypes.PRODUCT_TEMPLATE_BYID_REQUEST
  };
};

const getProductTemplateByIdSuccess = data => {
  return {
    type: actionTypes.PRODUCT_TEMPLATE_BYID_SUCCESS,
    payload: data
  };
};

const getProductTemplateByIdFailure = error => {
  return {
    type: actionTypes.PRODUCT_TEMPLATE_BYID_FAILURE,
    payload: error
  };
};

export const publishProductAction = (
  id,
  partnerid,
  userid
) => async dispatch => {
  dispatch(publishProductRequest());
  try {
    const response = await productServices.publishProduct(
      id,
      partnerid,
      userid
    );
    if (response.status === "1") {
      dispatch(publishProductSuccess(response.data));
    } else {
      dispatch(publishProductFailure(response.error));
    }
  } catch (error) {
    dispatch(publishProductFailure(error.message));
  }
};

const publishProductRequest = () => {
  return {
    type: actionTypes.PUBLISH_BRAND_PRODUCT_REQUEST
  };
};

const publishProductSuccess = data => {
  return {
    type: actionTypes.PUBLISH_BRAND_PRODUCT_SUCCESS,
    payload: data
  };
};

const publishProductFailure = error => {
  return {
    type: actionTypes.PUBLISH_BRAND_PRODUCT_FAILURE,
    payload: error
  };
};
