import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  list: null,
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  isSaving: false,
  isDeleted: false,
  isMapSaved: false
};

const classReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_TEMPLATES_REQUEST:
      return {
        ...state,
        list: null,
        loadingData: true,
        error: null,
        isSaved: false
      };
    case actionTypes.GET_TEMPLATES_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false
      };
    case actionTypes.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        loadingData: false,
        error: null,
        list: action.payload,
        isSaved: false
      };
    case actionTypes.SAVE_TEMPLATES_REQUEST:
      return {
        ...state,
        loadingData: true,
        error: null,
        isSaved: false,
        isSaving: true
      };
    case actionTypes.SAVE_TEMPLATES_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false,
        isSaving: false
      };
    case actionTypes.SAVE_TEMPLATES_SUCCESS:
      var data = state.list;
      if (data != null) {
        var index = data.findIndex(p => p.id === action.payload.id);
        if (index > -1) {
          data[index] = action.payload;
        } else {
          data.push(action.payload);
        }
      } else {
        data.push(action.payload);
      }
      return {
        ...state,
        loadingData: false,
        error: null,
        list: data,
        isSaved: true,
        isMapSaved: true,
        isSaving: false
      };

    default:
      return state;
  }
};

export default classReducer;
