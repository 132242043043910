import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  list: [],
  loading: false,
  error: null,
  isSaved: false,
  pagination: {
    page: 1,
    next: null,
    prev: null,
    totalpages: 1,
    trecords: 0,
    pagesize: 10
  }
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_USERSLIST_REQUEST:
      return {
        ...state,
        list: null,
        loading: true,
        error: null,
        isSaved: false,
        pagination: {
          page: 1,
          next: null,
          prev: null,
          totalpages: 1,
          trecords: 0,
          pagesize: 10
        }
      };
    case actionTypes.GET_USERSLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        list: null,
        isSaved: false
      };
    case actionTypes.GET_USERSLIST_SUCCESS:
      let pagination = _.clone(state.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        loading: false,
        error: null,
        list: sdata,
        isSaved: false,
        pagination: {
          ...pagination
        }
      };
    case actionTypes.CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false
      };
    case actionTypes.CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false
      };
    case actionTypes.CREATE_USER_SUCCESS:
      var data = state.list;
      let list = [];
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data[index] = action.payload;
      } else {
        list.push(action.payload);
        for (let i = 0; i < data.length; i++) {
          list.push(data[i]);
        }
        // data.push(action.payload);
      }
      return {
        ...state,
        loading: false,
        error: null,
        list: list,
        isSaved: true
      };
    default:
      return state;
  }
};

export default usersReducer;
