import axios from "../utils/axios.jsx";
export const savePassword = async (userId, password, api_key, loginuserid) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("password", password);
    data.append("login_user_id", loginuserid);
    data.append("api_key", api_key);
    const response = await axios.post("api/user/changepassword/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendForgotPasswordLink = async email => {
  try {
    let data = new FormData();

    data.append("email", email);
    const response = await axios.post("api/user/forgotpassword/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
