import React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import { rolesNames } from "../../../constants";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import InputLabel from "@material-ui/core/InputLabel";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineRounded from "@material-ui/icons/HelpOutlineRounded";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

class ProductInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductTypeData: [],
      data: {},
      product_template: [],
      brandlist: [],
      isBrandSelected: false,
      error: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.data) {
        let data = nextProps.data;

        if (nextProps.editState && !nextProps.valueSetCheck) {
          const content = htmlToDraft(data.description);
          const contentState = ContentState.createFromBlockArray(
            content.contentBlocks,
            content.entityMap
          );
          const editorState = EditorState.createWithContent(contentState);
          this.setState({
            editorState: editorState,
            data: data
          });
        } else {
          this.setState({
            data: data
          });
        }
      }
      if (nextProps.Brands) {
        let brandlist = [...nextProps.Brands.brand];
        console.log(brandlist);
        if (nextProps.Role == rolesNames.RoleMaster.BRAND) {
          brandlist = brandlist.filter(x => x.id == nextProps.PartnerId);
        }
        this.setState(
          {
            brandlist: brandlist
          },
          () => {
            if (
              nextProps.Role == rolesNames.RoleMaster.BRAND &&
              this.state.brandlist.length > 0 &&
              !nextProps.valueSetCheck
            ) {
              let { brandlist } = this.state;
              let index = brandlist.findIndex(x => x.id == nextProps.PartnerId);
              let brands = [];
              brands.push(brandlist[index]);
              this.props.setProductData("brands", index > -1 ? brands : []);
            }
          }
        );
      }

      if (nextProps.List && nextProps.List.length) {
        const ProductTypeData = nextProps.List;
        this.setState(() => ({
          ProductTypeData: ProductTypeData
        }));
      }
    }
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      const content = htmlToDraft(data.description);
      const contentState = ContentState.createFromBlockArray(
        content.contentBlocks,
        content.entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState: editorState,
        data: data
      });
    }
    this.props.actions.getALLBrand(rolesNames.RoleMaster.BRAND);
    this.props.actions.getProductCMSList();
  }

  render() {
    const { editorState } = this.state;
    const { classes } = this.props;
    const { name, is_consumable, producttype } = this.state.data;
    return (
      <GridContainer>
        <GridItem lg={1} md={1} />
        <GridItem lg={10} md={10}>
          <form>
            <GridContainer className="grid-container-control">
              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "}>
                  Name
                  <Tooltip
                    className="control-tooltip"
                    title="Please select Name"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <TextField
                  value={name ? name : ""}
                  variant="outlined"
                  name="name"
                  fullWidth
                  placeholder="Name"
                  classes={{
                    root: "attribute-txtbox"
                  }}
                  onChange={this.handleTextChange()}
                  error={this.state.error}
                  helperText={this.state.error ? this.state.error : ""}
                />
              </GridItem>
              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "}>
                  Product Type
                </InputLabel>
                <Select
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={producttype ? producttype : 0}
                  classes={{
                    root: "select-control-root"
                  }}
                  onChange={this.handleSelectChange("parent", "")}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Product Type
                  </MenuItem>
                  {_.map(this.state.ProductTypeData, ({ id, name }, index) => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={id}
                        key={index + "_" + id}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </GridItem>
              <GridItem lg={6} md={6} className="griditem-control">
                <Checkbox
                  className="checkbox-control"
                  tabIndex={-1}
                  checked={is_consumable ? true : false}
                  onClick={this.handleToggleStatus()}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                  name="is_consumable"
                />
                <div className={classes.label} style={{ marginTop: "7px" }}>
                  <span>Consumable</span>
                  <Tooltip
                    className="control-tooltip"
                    title="Inventory is not managed for consumable products"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </div>
              </GridItem>
              <GridItem lg={12} md={12} className="griditem-control">
                <InputLabel className={classes.label + " "}>
                  Description
                  <Tooltip
                    className="control-tooltip"
                    title="Add Description for product"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <br />
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapper"
                  editorClassName="editor"
                  onEditorStateChange={this.onEditorStateChange}
                />
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
        <GridItem lg={1} md={1} />
      </GridContainer>
    );
  }

  getLabel(option) {
    console.log(option);
    return;
  }

  setValue(option, value) {
    if (option.id == value.id) {
      return option;
    }
    return;
  }

  handleChange = (name, value) => {
    this.props.setProductData(name, value.id);
  };

  handleBrandChange = (name, value) => {
    this.props.setProductData(name, value);
  };

  handleSelectChange = (key, type) => event => {
    let currentParent = this.state.ProductTypeData.findIndex(
      p => p.id == event.target.value
    );
    this.props.setProductData(
      "producttype",
      this.state.ProductTypeData[currentParent].id
    );
  };

  handleTextChange = () => event => {
    if (
      event.target.name == "name" &&
      event.target.value &&
      event.target.value.length > 255
    ) {
      this.setState({
        error: "The length of name should be less than 255 character"
      });
      return;
    } else {
      if (
        event.target.name == "name" &&
        event.target.value &&
        event.target.value.length <= 255
      ) {
        this.setState({
          error: null
        });
      }
    }
    this.props.setProductData(event.target.name, event.target.value);
  };

  handleToggleStatus = () => event => {
    let value = event.target.checked;
    this.props.setProductData(event.target.name, value);
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.setProductData("description", value);
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  Brands: state.brandState.map_wholesaler_brand,
  PartnerId: state.authState.user.partner_id,
  UserId: state.authState.user.user,
  ProductTemplates:
    state.brandState.map_Retailer_brand_producttemplate_Selected,
  ProductData: state.productsAssignmentsState.brand_product.data,
  Role: state.authState.user.role,
  List: state.productCMSReducer.get.list,
  ListError: state.productCMSReducer.get.error
});

ProductInfo.propTypes = {
  data: PropTypes.object.isRequired,
  editState: PropTypes.bool.isRequired
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductInfo);
