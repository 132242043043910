import * as categoryServices from "../../services/categoryServices";
import * as classServices from "../../services/classService";
import * as actionTypes from "./actionTypes";

export const saveCategoryAction = (userid, data) => async dispatch => {
  dispatch(saveCategoryRequest());
  try {
    let successnew = await categoryServices.saveCategory(userid, data);

    if (successnew.status && successnew.status === "1") {
      dispatch(saveCategorySuccess(successnew.data));
    } else {
      dispatch(saveCategoryFailure(successnew.error));
    }
  } catch (error) {
    dispatch(saveCategoryFailure(error.message));
  }
};

const saveCategoryRequest = () => {
  return {
    type: actionTypes.CREATE_CATEGORY_REQUEST
  };
};

const saveCategorySuccess = data => {
  return {
    type: actionTypes.CREATE_CATEGORY_SUCCESS,
    payload: data
  };
};

const saveCategoryFailure = error => {
  return {
    type: actionTypes.CREATE_CATEGORY_FAILURE,
    payload: error
  };
};

export const getCategoryListAction = (
  partnerid,
  excludedid = null
) => async dispatch => {
  dispatch(getCategoryListRequest());
  try {
    var success = await categoryServices.getCategoryList(partnerid, excludedid);
    if (success.status && success.status === "1") {
      dispatch(getCategoryListSuccess(success.data));
    } else {
      dispatch(getCategoryListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getCategoryListFailure(error.message));
  }
};

const getCategoryListRequest = () => {
  return {
    type: actionTypes.GET_CATEGORY_REQUEST
  };
};

const getCategoryListSuccess = data => {
  return {
    type: actionTypes.GET_CATEGORY_SUCCESS,
    payload: data
  };
};

const getCategoryListFailure = error => {
  return {
    type: actionTypes.GET_CATEGORY_FAILURE,
    payload: error
  };
};

export const getCategoryByIdAction = id => async dispatch => {
  dispatch(getCategoryByIdRequest());
  try {
    var success = await categoryServices.getCategoryById(id);
    if (success.status && success.status === "1") {
      dispatch(getCategoryByIdSuccess(success.data));
    } else {
      dispatch(getCategoryByIdFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getCategoryByIdFailure(error.message));
  }
};

const getCategoryByIdRequest = () => {
  return {
    type: actionTypes.GET_CATEGORY_BYID_REQUEST
  };
};

const getCategoryByIdSuccess = data => {
  return {
    type: actionTypes.GET_CATEGORY_BYID_SUCCESS,
    payload: data
  };
};

const getCategoryByIdFailure = error => {
  return {
    type: actionTypes.GET_CATEGORY_BYID_FAILURE,
    payload: error
  };
};

export const getCategoryProductMappingAction = (
  categoryid,
  partnerid
) => async dispatch => {
  dispatch(getCategoryProductMappingRequest());
  try {
    var success = await categoryServices.getCategoryProductMapping(
      categoryid,
      partnerid
    );
    if (success.status && success.status === "1") {
      dispatch(getCategoryProductMappingSuccess(success.data));
    } else {
      dispatch(getCategoryProductMappingFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getCategoryProductMappingFailure(error.message));
  }
};

const getCategoryProductMappingRequest = () => {
  return {
    type: actionTypes.GET_CATEGORY_PRODUCT_MAPPING_REQUEST
  };
};

const getCategoryProductMappingSuccess = data => {
  return {
    type: actionTypes.GET_CATEGORY_PRODUCT_MAPPING_SUCCESS,
    payload: data
  };
};

const getCategoryProductMappingFailure = error => {
  return {
    type: actionTypes.GET_CATEGORY_PRODUCT_MAPPING_FAILURE,
    payload: error
  };
};

export const mapProductWithCategoryAction = (
  userid,
  data
) => async dispatch => {
  dispatch(mapProductWithCategoryRequest());
  try {
    var success = await categoryServices.mapProductWithCategory(userid, data);
    if (success.status && success.status === "1") {
      dispatch(mapProductWithCategorySuccess(success.data));
    } else {
      dispatch(mapProductWithCategoryFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(mapProductWithCategoryFailure(error.message));
  }
};

const mapProductWithCategoryRequest = () => {
  return {
    type: actionTypes.MAP_PRODUCT_WITH_CATEGORY_REQUEST
  };
};

const mapProductWithCategorySuccess = data => {
  return {
    type: actionTypes.MAP_PRODUCT_WITH_CATEGORY_SUCCESS,
    payload: data
  };
};

const mapProductWithCategoryFailure = error => {
  return {
    type: actionTypes.MAP_PRODUCT_WITH_CATEGORY_FAILURE,
    payload: error
  };
};

export const getBrandCategoryProductHierarchyAction = (
  partnerid,
  selectedPartnerId = 0,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getBrandCategoryProductHierarchyRequest());
  try {
    var success = await categoryServices.getBrandCategoryProductHierarchy(
      partnerid,
      selectedPartnerId,
      page,
      search
    );
    if (success.status && success.status === "1") {
      dispatch(getBrandCategoryProductHierarchySuccess(success.data));
    } else {
      dispatch(getBrandCategoryProductHierarchyFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getBrandCategoryProductHierarchyFailure(error.message));
  }
};

const getBrandCategoryProductHierarchyRequest = () => {
  return {
    type: actionTypes.GET_BRAND_CATEGORY_PRODUCT_REQUEST
  };
};

const getBrandCategoryProductHierarchySuccess = data => {
  return {
    type: actionTypes.GET_BRAND_CATEGORY_PRODUCT_SUCCESS,
    payload: data
  };
};

const getBrandCategoryProductHierarchyFailure = error => {
  return {
    type: actionTypes.GET_BRAND_CATEGORY_PRODUCT_FAILURE,
    payload: error
  };
};

export const getWholesalorBrandCategoryProductHierarchyAction = (
  partnerid,
  ismapview = null,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getWholesalorBrandCategoryProductHierarchyRequest());
  try {
    var success = await categoryServices.getWholesalorBrandCategoryProductHierarchy(
      partnerid,
      ismapview,
      page,
      search
    );
    if (success.status && success.status === "1") {
      dispatch(getWholesalorBrandCategoryProductHierarchySuccess(success.data));
    } else {
      dispatch(
        getWholesalorBrandCategoryProductHierarchyFailure("Some Error Occured.")
      );
    }
  } catch (error) {
    dispatch(getWholesalorBrandCategoryProductHierarchyFailure(error.message));
  }
};

const getWholesalorBrandCategoryProductHierarchyRequest = () => {
  return {
    type: actionTypes.GET_WHOLESALOR_BRAND_CATEGORY_PRODUCT_REQUEST
  };
};

const getWholesalorBrandCategoryProductHierarchySuccess = data => {
  return {
    type: actionTypes.GET_WHOLESALOR_BRAND_CATEGORY_PRODUCT_SUCCESS,
    payload: data
  };
};

const getWholesalorBrandCategoryProductHierarchyFailure = error => {
  return {
    type: actionTypes.GET_WHOLESALOR_BRAND_CATEGORY_PRODUCT_FAILURE,
    payload: error
  };
};

export const getRetailerClassProductHierarchy = (
  partnerid,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getRetailerClassProductHierarchyRequest());
  try {
    var success = await classServices.getRetailerClassProductHierarchy(
      partnerid,
      page,
      search
    );
    if (success.status && success.status === "1") {
      dispatch(getRetailerClassProductHierarchySuccess(success.data));
    } else {
      dispatch(getRetailerClassProductHierarchyFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getRetailerClassProductHierarchyFailure(error.message));
  }
};

const getRetailerClassProductHierarchyRequest = () => {
  return {
    type: actionTypes.GET_RETAILER_CLASS_PRODUCT_REQUEST
  };
};

const getRetailerClassProductHierarchySuccess = data => {
  return {
    type: actionTypes.GET_RETAILER_CLASS_PRODUCT_SUCCESS,
    payload: data
  };
};

const getRetailerClassProductHierarchyFailure = error => {
  return {
    type: actionTypes.GET_RETAILER_CLASS_PRODUCT_FAILURE,
    payload: error
  };
};

export const getClassProductTemplateHierarchy = (
  partnerid,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getRetailerClassProductTemplateHierarchyRequest());
  try {
    var success = await classServices.getClassProductTemplateHierarchy(
      partnerid,
      page,
      search
    );
    if (success.status && success.status === "1") {
      dispatch(getRetailerClassProductTemplateHierarchySuccess(success.data));
    } else {
      dispatch(
        getRetailerClassProductTemplateHierarchyFailure("Some Error Occured.")
      );
    }
  } catch (error) {
    dispatch(getRetailerClassProductTemplateHierarchyFailure(error.message));
  }
};

const getRetailerClassProductTemplateHierarchyRequest = () => {
  return {
    type: actionTypes.GET_RETAILER_CLASS_PRODUCT_TEMPLATE_REQUEST
  };
};

const getRetailerClassProductTemplateHierarchySuccess = data => {
  return {
    type: actionTypes.GET_RETAILER_CLASS_PRODUCT_TEMPLATE_SUCCESS,
    payload: data
  };
};

const getRetailerClassProductTemplateHierarchyFailure = error => {
  return {
    type: actionTypes.GET_RETAILER_CLASS_PRODUCT_TEMPLATE_FAILURE,
    payload: error
  };
};

export const changeCategorySaveCheck = () => {
  return {
    type: actionTypes.CHANGE_CATEGORY_SAVE_CHECK
  };
};

export const getPartnerCategoryHierarchyAction = (
  partnerid,
  selectedPartnerId = 0,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getPartnerCategoryHierarchyRequest());
  try {
    var success = await categoryServices.getPartnerCategoryHierarchy(
      partnerid,
      selectedPartnerId,
      page,
      search
    );
    if (success.status && success.status === "1") {
      dispatch(getPartnerCategoryHierarchySuccess(success.data));
    } else {
      dispatch(getPartnerCategoryHierarchyFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getPartnerCategoryHierarchyFailure(error.message));
  }
};

const getPartnerCategoryHierarchyRequest = () => {
  return {
    type: actionTypes.GET_PARTNER_CATEGORY_HIERARCHY_REQUEST
  };
};

const getPartnerCategoryHierarchySuccess = data => {
  return {
    type: actionTypes.GET_PARTNER_CATEGORY_HIERARCHY_SUCCESS,
    payload: data
  };
};

const getPartnerCategoryHierarchyFailure = error => {
  return {
    type: actionTypes.GET_PARTNER_CATEGORY_HIERARCHY_FAILURE,
    payload: error
  };
};

export const deleteCategoryAction = (id, partnerid) => async dispatch => {
  dispatch(deleteCategoryRequest());
  try {
    var success = await categoryServices.deleteCategory(id, partnerid);
    if (success.status && success.status === "1") {
      dispatch(deleteCategorySuccess(success.data, id));
    } else {
      dispatch(deleteCategoryFailure(success.error));
    }
  } catch (error) {
    dispatch(deleteCategoryFailure(error.message));
  }
};

const deleteCategoryRequest = () => {
  return {
    type: actionTypes.DELETE_CATEGORY_REQUEST,
  };
};

const deleteCategorySuccess = data => {
  return {
    type: actionTypes.DELETE_CATEGORY_SUCCESS,
    payload: data
  };
};

const deleteCategoryFailure = error => {
  return {
    type: actionTypes.DELETE_CATEGORY_FAILURE,
    payload: error
  };
};
