import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { validationUtils } from "../../utils";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",

      modalForgotpassword: true,
      error: false,
      required: {
        email: ""
      },
      message: ""
    };
  }
  handelDailogTextChange = (key, type) => event => {
    var isValid = true;
    if (!validationUtils.validateData(type, event.target.value)) {
      isValid = "error";
    }
    var reqData = this.state.required;
    reqData[key] = isValid;
    this.setState({ required: reqData });
    let keys = event.target.id;
    this.setState({
      [keys]: event.target.value,
      isError: !isValid
    });
  };
  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;

    if (
      !this.state.hasOwnProperty("email") ||
      (this.state.email.trim() === "" || reqData["email"] === "error")
    ) {
      reqData["email"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  componentDidMount() {}

  saveReatiler = () => {
    const { email } = this.state;

    if (this.validateIsRequired()) {
      this.props.actions.sendForgotlink(email);
    } else {
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.open !== this.state.open) {
        this.setState({
          open: nextProps.open
        });

        if (nextProps.list) {
          if (nextProps.list.data) {
            this.setState({
              message: "",
              email: ""
            });
          }
        }
      } else {
        this.setState({
          open: nextProps.open
        });

        if (nextProps.list) {
          if (nextProps.list.data) {
            this.setState({
              message: nextProps.list.data,
              email: ""
            });
          }
        }
      }
    }
  }
  render() {
    const { classes, open, onClose, userid } = this.props;
    const { isError, email, modalForgotpassword, required } = this.state;
    return (
      <GridContainer>
        <Dialog
          open={open}
          disableBackdropClick={modalForgotpassword}
          onClose={() => onClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Forgot
            Password&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <CustomInput
                success={required.email === "success"}
                error={required.email === "error"}
                labelText="Email *"
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "email",
                  value: email,
                  onChange: this.handelDailogTextChange(
                    "email",
                    validationUtils.validationTypes.email
                  )
                }}
              />
              <br />

              <span
                style={{
                  color: "#0d770d",
                  "font-size": "14px",
                  "padding-top": "15px",
                  margin: "105px"
                }}
              >
                {this.state.message}
              </span>
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.saveReatiler()}
                color="primary"
              >
                Get Reset Link
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => onClose()}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </GridContainer>
    );
  }
}

ForgotPassword.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  list: state.changePasswordReducer.list,
  error: state.changePasswordReducer.error,
  loader: state.changePasswordReducer.loader
});

// const condition = (authUser) =>
//     authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  // withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...dashboardStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ForgotPassword);
