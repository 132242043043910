import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { validationUtils } from "../../utils";

import Slide from "@material-ui/core/Slide";
import ReactTooltip from "react-tooltip";

import extendedFormStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
// import JSONInput from 'react-json-editor-ajrm';
// import locale from 'react-json-editor-ajrm/locale/en';
import CustomAutoComplete from "../../components/AutoComplete";
import ProductTypeGridLayout from "./components/grid-layout";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import InputLabel from "@material-ui/core/InputLabel";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const defaultProperties = {
  share: {
    visible: true
  },
  review: {
    visible: true
  },
  carousel: {
    visible: true
  },
  primary_tab: {
    visible: true,
    rows: []
  },
  secondary_tab: {
    visible: true,
    rows: []
  },
  add_to_cart: {
    visible: true
  },
  brand_title: {
    visible: true
  },
  size_picker: {
    visible: false
  },
  color_picker: {
    visible: false
  },
  product_title: {
    visible: true
  },
  product_offers: {
    visible: true
  },
  delivery_estimate: {
    visible: false
  }
};

const mappingFields = [
  { title: "Name", jsonpath: "$.name", pathlabel: "", parent: "Product" },
  {
    title: "Description",
    jsonpath: "$.description",
    pathlabel: "",
    parent: "Product"
  },
  {
    title: "Disclaimer",
    jsonpath: "$.disclaimer",
    pathlabel: "",
    parent: "Product"
  },
  { title: "Used", jsonpath: "$.is_used", pathlabel: "", parent: "Product" },
  {
    title: "Consumable",
    jsonpath: "$.is_consumable",
    pathlabel: "",
    parent: "Product"
  },
  { title: "MSRP", jsonpath: "$.msrp", pathlabel: "", parent: "Product" },

  {
    title: "Name",
    jsonpath: "$.product_template.name",
    pathlabel: "",
    parent: "Template"
  },
  {
    title: "Description",
    jsonpath: "$.product_template.description",
    pathlabel: "",
    parent: "Template"
  },
  {
    title: "Consumable",
    jsonpath: "$.product_template.is_consumable",
    pathlabel: "",
    parent: "Template"
  },
  {
    title: "Product's Segments",
    jsonpath: "$.product_template..segments..name",
    pathlabel: "All Product's Segments",
    parent: "Segment"
  },
  // { title: "Product's Classes", jsonpath: '$.product_template..classes..name', pathlabel: "All Product's Classes", parent: 'Class' },
  {
    title: "Product's Attibute Groups",
    jsonpath: "$.product_template..classes..attribute_groups[*]",
    pathlabel: "All Product's Attribute Groups",
    parent: "Attribute Group"
  }
];

class ProductType extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleJsonObectChange = this.handleJsonObectChange.bind(this);
    this.handleViewModeChange = this.handleViewModeChange.bind(this);
    this.state = {
      isAddNew: false,
      addProductTypeTypeData: {
        id: "",
        name: "",
        parent: "",
        template_config: defaultProperties,
        industry: ""
      },
      view_mode: "html",
      required: {
        name: "",
        industry: "",
        template_config: {
          primary_tab: {
            rows: []
          },
          secondary_tab: {
            tabs: []
          }
        },
        primary_tab: []
      },
      primary_tab_add_row_count: 0,
      ProductTypeData: null,
      error: null,
      openDeleteDialog: false,
      BusinessTypeId: null,
      entityData: null,
      isIndustriesComponentOpen: false,
      BusinessTypeValue: null,
      selectedITIndexes: [],
      tagTypeList: [],
      defaultProperty: defaultProperties,
      mappingfield: mappingFields,
      segmentList: [],
      classList: [],
      industryList: [],
      currentStep: 0
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (nextProps.isSaved && !nextProps.SaveError) {
          this.setState(() => ({
            ...this.state,
            isAddNew: false,
            addProductTypeTypeData: {
              id: "",
              name: "",
              parent: "",
              template_config: {},
              industry: ""
            }
          }));
        }

        if (nextProps.List && nextProps.List.length) {
          const ProductTypeData = nextProps.List;
          this.setState(() => ({
            ProductTypeData: ProductTypeData
          }));
        }

        if (nextProps.ListError && nextProps.ListError.trim() !== "") {
          this.setState(() => ({
            error: nextProps.ListError,
            isAddNew: true
          }));
        }

        if (
          nextProps.SegmentList &&
          nextProps.SegmentList.length &&
          !_.isEqual(this.state.segmentList, nextProps.SegmentList)
        ) {
          this.setState(
            {
              segmentList: nextProps.SegmentList
            },
            () => {
              this.compileSegmentMenu();
            }
          );
        }

        if (
          nextProps.ClassList &&
          nextProps.ClassList.length &&
          !_.isEqual(this.state.classList, nextProps.ClassList)
        ) {
          this.setState(
            {
              classList: nextProps.ClassList
            },
            () => {
              this.compileClassMenu();
            }
          );
        }
      }
    }
  }

  compileClassMenu = () => {
    if (!this.state.classList || this.state.classList.length === 0) {
      return;
    }

    let mapFieldArray = this.state.mappingfield;

    let attributeGroups = _.flatten(
      this.state.classList.map(a => a.attribute_groups)
    );
    let attributes = _.flatten(attributeGroups.map(a => a.attributes));

    let uniq_ag = _.uniqBy(attributes, function(o) {
      return o.id;
    });

    for (let k = 0; k < uniq_ag.length; k++) {
      let currentAg = uniq_ag[k];

      mapFieldArray.push({
        title: currentAg.name,
        jsonpath:
          "$.product_template..classes..attribute_groups[?(@.id==" +
          currentAg.id +
          ")]",
        pathlabel: "Attribute Group",
        parent: "Attribute Group"
      });
    }

    let universal_attributes = _.flatten(
      uniq_ag.map(a => a.universal_attributes)
    );

    let uniq_ua = _.uniqBy(universal_attributes, function(o) {
      return o.id;
    });

    for (let i = 0; i < uniq_ua.length; i++) {
      let currentUA = uniq_ua[i];

      mapFieldArray.push({
        title: currentUA.name,
        jsonpath:
          "$.product_template..attribute_value[?(@.universal_attribute_id==" +
          currentUA.id +
          ")].value",
        pathlabel: "Universal Attribute",
        parent: "Universal Attribute"
      });
    }

    let mapFieldUniArray = _.uniqWith(mapFieldArray, _.isEqual);

    this.setState({
      mappingfield: mapFieldUniArray
    });
  };

  compileSegmentMenu = () => {
    if (!this.state.segmentList || this.state.segmentList.length === 0) {
      return;
    }

    let mapFieldArray = this.state.mappingfield;

    for (let i = 0; i < this.state.segmentList.length; i++) {
      mapFieldArray.push({
        title: this.state.segmentList[i].name,
        jsonpath:
          "$.product_template..segments[?(@.id==" +
          this.state.segmentList[i].id +
          ")].name",
        pathlabel: this.state.segmentList[i].name,
        parent: "Segment"
      });
    }

    this.setState({
      mappingfield: mapFieldArray
    });
  };

  componentWillMount() {
    this.props.actions.getProductCMSList();
    this.props.actions.getSegmentsList(this.props.UserId);
    this.props.actions.getClassList(this.props.PartnerId);
  }

  componentDidMount() {
    // this.props.actions.getIndustriesTypeList(this.props.UserId);
  }

  handelIsAddNew = () => {
    this.props.actions.resetCreateState();

    let configClone = _.cloneDeep(this.state.defaultProperty);

    this.setState(
      {
        isAddNew: !this.state.isAddNew,
        currentStep: 0,
        addProductTypeTypeData: {
          id: "",
          name: "",
          parent: "",
          template_config: configClone
        }
      },
      () => {
        console.log(
          "template_config",
          this.state.addProductTypeTypeData.template_config
        );
      }
    );
  };

  handleSelectChange = key => event => {
    let currentParent = this.state.industryList.findIndex(
      p => p.id === event.target.value
    );
    var validData = true;
    if (!event.target.value) {
      validData = false;
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState(
      {
        addProductTypeTypeData: {
          ...this.state.addProductTypeTypeData,
          [key]: this.state.industryList[currentParent]
        }
      },
      () => {
        let segments = this.state.industryList[currentParent].xref_segments.map(
          a => a.segments
        );

        let _flat = _.flatten(segments);

        this.setState(
          {
            segmentList: _flat
          },
          () => {
            this.compileMappingMenu();
          }
        );
      }
    );

    // this.props.actions.getSegmentsList(this.props.UserId, event.target.value);
  };

  handleTextChange = (key, type) => event => {
    var validData = true;
    if (!event.target.value) {
      validData = false;
    }

    if (event.target.value.trim().length > 100) {
      return;
    }

    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        [key]: event.target.value
      }
    });
  };

  saveBusinessType = () => {
    if (this.validateIsRequired()) {
      this.props.actions.createProductCMS(
        this.state.addProductTypeTypeData.name,
        this.state.addProductTypeTypeData.template_config,
        this.state.addProductTypeTypeData.parent,
        this.state.addProductTypeTypeData.id,
        this.state.addProductTypeTypeData.industry
      );
    }
  };

  handelEdit = index => {
    const { isEdit } = this.state;
    const data = _.cloneDeep(this.state.ProductTypeData[index]);
    // const data = productcms;
    if (data) {
      this.setState({
        currentStep: 0,
        addProductTypeTypeData: {
          id: data.id,
          name: data.name,
          parent: data.parent,
          template_config: data.template_config
        },
        isAddNew: true,
        isEdit: true
      });

      return;
    }
  };

  render() {
    return this.state.isAddNew
      ? this.renderProductTypeType()
      : this.renderProductTypeTypeTable();
  }

  validateIsRequired() {
    var reqData = this.state.required;
    var isValid = true;
    if (
      !this.state.addProductTypeTypeData.hasOwnProperty("name") ||
      (this.state.addProductTypeTypeData.name.trim() === "" ||
        reqData["name"] === false)
    ) {
      reqData["name"] = false;
      isValid = false;
    }

    // if (
    //     !this.state.addProductTypeTypeData.hasOwnProperty("industry")
    //     ||
    //     (
    //         !this.state.addProductTypeTypeData.industry
    //         ||
    //         reqData["industry"] === false
    //     )
    // ) {
    //     reqData["industry"] = false;
    //     isValid = false;
    // }

    this.setState({ required: reqData });
    return isValid;
  }

  handleJsonObectChange = obj => {
    if (!obj.error) {
      this.setState({
        addProductTypeTypeData: {
          ...this.state.addProductTypeTypeData,
          template_config: obj.jsObject
        }
      });
    }
  };

  handleViewModeChange = () => {
    if (this.state.view_mode === "html") {
      this.setState({
        view_mode: "json"
      });
    } else {
      this.setState({
        view_mode: "html"
      });
    }
  };

  getFieldChecked = key => {
    try {
      return (
        this.state.addProductTypeTypeData.template_config[key].visible === true
      );
    } catch (e) {
      console.error("Field Setting exception", e);
      return true;
    }
  };

  handleToggle = key => {
    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          [key]: {
            ...this.state.addProductTypeTypeData.template_config[key],
            visible: !(
              this.state.addProductTypeTypeData.template_config[key] &&
              this.state.addProductTypeTypeData.template_config[key].visible
            )
          }
        }
      }
    });
  };

  handleSecondaryTabDataChange = (key, type, parent, index) => event => {
    let secondary_tab_data = this.state.addProductTypeTypeData.template_config
      .secondary_tab.tabs;
    secondary_tab_data[index][parent][key] = event.target.value;

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          secondary_tab: {
            ...this.state.addProductTypeTypeData.template_config.secondary_tab,
            tabs: secondary_tab_data
          }
        }
      }
    });
  };

  handleSecondaryTabRowChange = (key, type, index) => event => {
    let validData = true;
    if (type && event.target.value) {
      if (!validationUtils.validateData(type, event.target.value)) {
        validData = false;
      }
    }

    let secondary_tab_data = this.state.addProductTypeTypeData.template_config
      .secondary_tab.tabs;
    if (!validData) {
      secondary_tab_data[index][key] = secondary_tab_data[index][key];
    } else {
      secondary_tab_data[index][key] = event.target.value;
    }

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          secondary_tab: {
            ...this.state.addProductTypeTypeData.template_config.secondary_tab,
            tabs: secondary_tab_data
          }
        }
      }
    });
  };

  handleSecondaryTabRowTypeChange = index => event => {
    let secondary_tab_data = this.state.addProductTypeTypeData.template_config
      .secondary_tab.tabs;
    secondary_tab_data[index]["data"]["type"] = event.target.value;

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          secondary_tab: {
            ...this.state.addProductTypeTypeData.template_config.secondary_tab,
            tabs: secondary_tab_data
          }
        }
      }
    });
  };

  handleSecondaryTabRowRemove = index => {
    let primary_tab_data = this.state.addProductTypeTypeData.template_config
      .secondary_tab.tabs;

    primary_tab_data.splice(index, 1);

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          secondary_tab: {
            ...this.state.addProductTypeTypeData.template_config.secondary_tab,
            tabs: primary_tab_data
          }
        }
      }
    });
  };

  handleSecondaryGridDataChange = (
    key,
    type,
    parent,
    index,
    value,
    rowIndex
  ) => {
    let tab_data = this.state.addProductTypeTypeData.template_config
      .secondary_tab.tabs;
    tab_data[rowIndex].data.rows[index][parent][key] = value;

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          secondary_tab: {
            ...this.state.addProductTypeTypeData.template_config.secondary_tab,
            tabs: tab_data
          }
        }
      }
    });
  };

  handleSecondaryGridTabRowChange = (key, type, index, value, rowIndex) => {
    let validData = true;
    if (type && value) {
      if (!validationUtils.validateData(type, value)) {
        validData = false;
      }
    }

    let tab_data = this.state.addProductTypeTypeData.template_config
      .secondary_tab.tabs;
    if (!validData) {
      tab_data[rowIndex].data.rows[index][key] =
        tab_data[rowIndex].data.rows[index][key];
    } else {
      tab_data[rowIndex].data.rows[index][key] = value;
    }

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          secondary_tab: {
            ...this.state.addProductTypeTypeData.template_config.secondary_tab,
            tabs: tab_data
          }
        }
      }
    });
  };

  handleSecondaryTabRowMappingFieldChange = (newval, index) => {
    console.log("newval", newval);
    let tab_data = this.state.addProductTypeTypeData.template_config
      .secondary_tab.tabs;

    if (Array.isArray(newval)) {
      tab_data[index]["data"]["jsonpath"] = [];
      for (let i = 0; i < newval.length; i++) {
        tab_data[index]["data"]["jsonpath"].push(newval[i].jsonpath);
      }
    } else {
      tab_data[index]["data"]["jsonpath"] = newval ? newval.jsonpath : "";
    }

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          secondary_tab: {
            ...this.state.addProductTypeTypeData.template_config.secondary_tab,
            tabs: tab_data
          }
        }
      }
    });
  };

  handleSecondaryGridTabRowMappingFieldChange = (newval, index, rowIndex) => {
    let tab_data = this.state.addProductTypeTypeData.template_config
      .secondary_tab.tabs;

    if (Array.isArray(newval)) {
      tab_data[rowIndex].data.rows[index]["data"]["jsonpath"] = [];
      for (let i = 0; i < newval.length; i++) {
        tab_data[rowIndex].data.rows[index]["data"]["jsonpath"].push(
          newval[i].jsonpath
        );
      }
    } else {
      tab_data[rowIndex].data.rows[index]["data"]["jsonpath"] = newval
        ? newval.jsonpath
        : "";
    }

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          secondary_tab: {
            ...this.state.addProductTypeTypeData.template_config.secondary_tab,
            tabs: tab_data
          }
        }
      }
    });
  };

  handleSecondaryGridPrimaryTabAddRow = rowIndex => {
    let tab_data = this.state.addProductTypeTypeData.template_config
      .secondary_tab.tabs;
    if (!tab_data[rowIndex].data.rows) {
      tab_data[rowIndex].data.rows = new Array();
    }

    tab_data[rowIndex].data.rows.push({
      sort_order: "",
      label: "",
      data: {
        value: "",
        jsonpath: ""
      }
    });

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          secondary_tab: {
            ...this.state.addProductTypeTypeData.template_config.secondary_tab,
            tabs: tab_data
          }
        }
      }
    });
  };

  handleSecondaryGridTabRowRemove = (index, rowIndex) => {
    let tab_data = this.state.addProductTypeTypeData.template_config
      .secondary_tab.tabs;

    tab_data[rowIndex].data.rows.splice(index, 1);

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          secondary_tab: {
            ...this.state.addProductTypeTypeData.template_config.secondary_tab,
            tabs: tab_data
          }
        }
      }
    });
  };

  GetMappingField = type => {
    let retArray;
    if (type === "attribute-group") {
      retArray = _.filter(this.state.mappingfield, function(o) {
        return o.parent === "Attribute Group";
      });
    } else {
      retArray = this.state.mappingfield;
    }

    return retArray;
  };

  getSecondaryTabTableData = () => {
    const { required } = this.state;
    let { tabs } =
      this.state.addProductTypeTypeData &&
      this.state.addProductTypeTypeData.template_config &&
      this.state.addProductTypeTypeData.template_config.secondary_tab
        ? this.state.addProductTypeTypeData.template_config.secondary_tab
        : [];
    const { classes } = this.props;

    let sorted_row = tabs; // _.sortBy(rows, 'sort_order')
    var returnData = [];

    _.map(sorted_row, (data, index) => {
      returnData.push([
        <CustomInput
          formControlProps={{
            fullWidth: true,
            style: { width: "50px" }
          }}
          inputProps={{
            type: "text",
            value: data.sort_order,
            onChange: this.handleSecondaryTabRowChange(
              "sort_order",
              "number",
              index
            )
          }}
          id={"primary_tab_sort_order_" + index}
        />,
        <CustomInput
          // success={required.primary_tab[index] && required.primary_tab[index].label === "success"}
          // error={required.primary_tab[index] && required[index].label === "error"}
          formControlProps={{
            fullWidth: true,
            style: { width: "100px" }
          }}
          inputProps={{
            type: "text",
            value: data.label,
            onChange: this.handleSecondaryTabRowChange("label", "", index)
          }}
          id={"primary_tab_label_" + index}
        />,
        <CustomInput
          // success={required.primary_tab[index] && required.primary_tab[index].label === "success"}
          // error={required.primary_tab[index] && required[index].label === "error"}
          formControlProps={{
            fullWidth: true,
            style: { width: "150px" }
          }}
          inputProps={{
            type: "text",
            value: data.data.value,
            onChange: this.handleSecondaryTabDataChange(
              "value",
              "",
              "data",
              index
            )
          }}
          id={"primary_details_tab_" + index}
        />,
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          autoWidth={false}
          value={data.data.type}
          defaultValue={"html"}
          onChange={this.handleSecondaryTabRowTypeChange(index)}
          inputProps={{
            name: "simpleSelect_" + index,
            id: "simple-select_" + index
          }}
        >
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value="html"
          >
            Text
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value="attribute-group"
          >
            Attributes
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value="grid"
          >
            Grid
          </MenuItem>
        </Select>,
        <div>
          {data.data.type !== "grid" ? (
            <CustomAutoComplete
              mappingfield={this.GetMappingField(data.data.type)}
              selectedValue={data.data}
              multiple={data.data.type === "attribute-group"}
              index={index}
              selectedPath={data.data.jsonpath}
              onChange={this.handleSecondaryTabRowMappingFieldChange}
            />
          ) : (
            <List style={{ width: "100%", cursor: "pointer", padding: 0 }}>
              <ListItem>
                <ListItemText
                  id={"switch-list-label-wifi-" + index}
                  primary={
                    <Typography variant="button" display="block">
                      {data.data.rows && data.data.rows.length > 0
                        ? "" + data.data.rows.length + " rows created"
                        : "Add rows below"}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" display="block">
                      See rows details in below list
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <SettingsIcon edge="end" />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          )}
        </div>,
        <span>
          <ReactTooltip id="remove">
            <span>Remove this row</span>
          </ReactTooltip>
          <Button
            color="success"
            data-tip
            data-for="remove"
            className={classes.actionButton}
            onClick={() => this.handleSecondaryTabRowRemove(index)}
          >
            <ClearIcon className={classes.icon} />
          </Button>
        </span>
      ]);

      if (data.data.type === "grid") {
        returnData.push({
          purchase: true,
          colspan: "1",
          col: {
            colspan: 4,
            text: (
              <div style={{ paddingTop: 8, paddingBottom: 10 }}>
                {" "}
                <ProductTypeGridLayout
                  mappingfield={this.state.mappingfield}
                  rows={data.data.rows}
                  handlePrimaryTabDataChange={
                    this.handleSecondaryGridDataChange
                  }
                  handlePrimaryTabRowChange={
                    this.handleSecondaryGridTabRowChange
                  }
                  handlePrimaryTabRowMappingFieldChange={
                    this.handleSecondaryGridTabRowMappingFieldChange
                  }
                  handleAddPrimaryTabRow={
                    this.handleSecondaryGridPrimaryTabAddRow
                  }
                  handlePrimaryTabRowRemove={
                    this.handleSecondaryGridTabRowRemove
                  }
                  secondaryRowIndex={index}
                />
              </div>
            )
          }
        });
      }
    });

    return returnData;
  };

  handleAddSecondaryTabRow = () => {
    let secondary_tab_data = this.state.addProductTypeTypeData.template_config
      .secondary_tab.tabs;
    if (!secondary_tab_data) {
      secondary_tab_data = new Array();
    }

    secondary_tab_data.push({
      sort_order: "",
      label: "",
      data: {
        value: "",
        jsonpath: ""
      }
    });

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          secondary_tab: {
            ...this.state.addProductTypeTypeData.template_config.secondary_tab,
            tabs: secondary_tab_data
          }
        }
      }
    });
  };

  getSecondaryTab = () => {
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ float: "right" }}>
            <Button
              size="sm"
              color="info"
              onClick={() => this.handleAddSecondaryTabRow(1)}
            >
              Add Tabs
            </Button>
          </div>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Table
            tableShopping
            tableHead={["Sort #", "Label", "Default Value", "Mapping Field"]}
            tableData={this.getSecondaryTabTableData()}
          />
        </GridItem>
      </GridContainer>
    );
  };

  handleAddPrimaryTabRow = () => {
    let primary_tab_data = this.state.addProductTypeTypeData.template_config
      .primary_tab.rows;
    if (!primary_tab_data) {
      primary_tab_data = new Array();
    }

    primary_tab_data.push({
      sort_order: "",
      label: "",
      data: {
        value: "",
        jsonpath: ""
      }
    });

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          primary_tab: {
            ...this.state.addProductTypeTypeData.template_config.primary_tab,
            rows: primary_tab_data
          }
        }
      }
    });
  };

  handlePrimaryTabRowRemove = index => {
    let primary_tab_data = this.state.addProductTypeTypeData.template_config
      .primary_tab.rows;

    primary_tab_data.splice(index, 1);

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          primary_tab: {
            ...this.state.addProductTypeTypeData.template_config.primary_tab,
            rows: primary_tab_data
          }
        }
      }
    });
  };

  handlePrimaryTabDataChange = (key, type, parent, index, value) => {
    let primary_tab_data = this.state.addProductTypeTypeData.template_config
      .primary_tab.rows;
    primary_tab_data[index][parent][key] = value;

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          primary_tab: {
            ...this.state.addProductTypeTypeData.template_config.primary_tab,
            rows: primary_tab_data
          }
        }
      }
    });
  };

  handlePrimaryTabRowChange = (key, type, index, value) => {
    let validData = true;

    if (type && value) {
      if (!validationUtils.validateData(type, value)) {
        validData = false;
      }
    }

    let primary_tab_data = this.state.addProductTypeTypeData.template_config
      .primary_tab.rows;

    if (!validData) {
      primary_tab_data[index][key] = primary_tab_data[index][key];
    } else {
      primary_tab_data[index][key] = value;
    }

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          primary_tab: {
            ...this.state.addProductTypeTypeData.template_config.primary_tab,
            rows: primary_tab_data
          }
        }
      }
    });
  };

  handlePrimaryTabRowMappingFieldChange = (newval, index) => {
    let primary_tab_data = this.state.addProductTypeTypeData.template_config
      .primary_tab.rows;

    console.log("newval", newval);
    if (Array.isArray(newval)) {
      primary_tab_data[index]["data"]["jsonpath"] = [];
      for (let i = 0; i < newval.length; i++) {
        primary_tab_data[index]["data"]["jsonpath"].push(newval[i].jsonpath);
      }
    } else {
      primary_tab_data[index]["data"]["jsonpath"] = newval
        ? newval.jsonpath
        : "";
    }

    this.setState({
      addProductTypeTypeData: {
        ...this.state.addProductTypeTypeData,
        template_config: {
          ...this.state.addProductTypeTypeData.template_config,
          primary_tab: {
            ...this.state.addProductTypeTypeData.template_config.primary_tab,
            rows: primary_tab_data
          }
        }
      }
    });
  };

  getPrimaryTab = () => {
    return (
      <ProductTypeGridLayout
        mappingfield={this.state.mappingfield}
        rows={
          this.state.addProductTypeTypeData.template_config.primary_tab.rows
        }
        handlePrimaryTabDataChange={this.handlePrimaryTabDataChange}
        handlePrimaryTabRowChange={this.handlePrimaryTabRowChange}
        handlePrimaryTabRowMappingFieldChange={
          this.handlePrimaryTabRowMappingFieldChange
        }
        handleAddPrimaryTabRow={this.handleAddPrimaryTabRow}
        handlePrimaryTabRowRemove={this.handlePrimaryTabRowRemove}
      />
    );
  };

  getModelTabs = () => {
    let tabs = [];

    tabs.push({
      tabButton: "Fields",
      tabContent: this.renderPrimaryTab()
    });

    if (
      this.state.addProductTypeTypeData.template_config["primary_tab"] &&
      this.state.addProductTypeTypeData.template_config["primary_tab"]
        .visible === true
    ) {
      tabs.push({
        tabButton: "Primary Attributes",
        tabContent: this.getPrimaryTab()
      });
    }

    if (
      this.state.addProductTypeTypeData.template_config["secondary_tab"] &&
      this.state.addProductTypeTypeData.template_config["secondary_tab"]
        .visible === true
    ) {
      tabs.push({
        tabButton: "Tab",
        tabContent: this.getSecondaryTab()
      });
    }
    return tabs;
  };

  setActiveTab = active => {
    this.setState({
      currentStep: active
    });
  };

  //// An important place to do a level of check for performance issue
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  renderHtmlViewMode = () => {
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <NavPills
          // horizontal={{
          //     tabsGrid: { xs: 12, sm: 12, md: 3, lg: 3 },
          //     contentGrid: { xs: 12, sm: 8, md: 9, lg: 9 }
          // }}
          // color="rose"
          setActive={this.setActiveTab}
          active={this.state.currentStep}
          tabs={this.getModelTabs()}
        />
      </GridItem>
    );
  };

  renderProductTypeType = () => {
    const { classes } = this.props;
    const {
      name,
      parent,
      template_config,
      industry
    } = this.state.addProductTypeTypeData;
    const { required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name}
                    error={required.name === false}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <br />

                  {false && (
                    <FormControl
                      fullWidth
                      style={customInputStyle.formControl}
                      name="industry"
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.labelRoot + " "}
                        labelText="Industry*"
                      >
                        Industry *
                      </InputLabel>
                      <Select
                        success={required.industry}
                        error={required.industry === false}
                        style={{ marginTop: "0px" }}
                        value={
                          this.state.addProductTypeTypeData.industry
                            ? this.state.addProductTypeTypeData.industry.id
                            : null
                        }
                        onChange={this.handleSelectChange("industry")}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Choose Industry
                        </MenuItem>
                        {_.map(
                          this.state.industryList,
                          ({ id, name }, index) => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={id}
                                key={index + "_" + id}
                              >
                                {name}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  )}

                  <br />
                  <br />
                  <span>Template:</span>
                  <div
                    style={{
                      float: "right",
                      cursor: "pointer",
                      textDecoration: "underline"
                    }}
                    onClick={this.handleViewModeChange}
                  >
                    {this.state.view_mode === "html"
                      ? "Switch to JSON View"
                      : "Switch to HTML View"}
                  </div>

                  {this.renderHtmlViewMode()
                  // this.state.view_mode === "html" ?
                  //     this.renderHtmlViewMode()
                  //     : <JSONInput
                  //         id='a_unique_id'
                  //         placeholder={template_config}
                  //         // colors={'darktheme'}
                  //         onChange={this.handleJsonObectChange}
                  //         locale={locale}
                  //         height='550px'
                  //         width='100%'
                  //     />
                  }

                  <br />
                  {this.props.SaveError && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.props.SaveError}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button color="info" onClick={this.saveBusinessType}>
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {this.props.Saving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Saving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderProductTypeTypeTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.Loading}
              color="success"
              onClick={this.handelIsAddNew}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} /> New
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>Product Theme.</p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <Table
                  tableHead={[
                    "Name"
                    // "Industry",
                  ]}
                  tableData={this.getTableData()}
                  tableShopping
                />
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  getTableData = () => {
    const { attributeGroups } = [];
    const { ProductTypeData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(ProductTypeData, ({ id, name, parent, industry }, index) => {
      returnData.push([
        <span>{name}</span>,
        // <span>{industry ? industry.name : ""}</span>,
        <span>
          <ReactTooltip id="edit">
            <span>Edit Product Theme</span>
          </ReactTooltip>
          <Button
            color="success"
            data-tip
            data-for="edit"
            className={classes.actionButton}
            onClick={() => this.handelEdit(index)}
          >
            <CreateIcon className={classes.icon} />
          </Button>
        </span>
      ]);
    });
    return returnData;
  };

  renderPrimaryTab = () => {
    const { classes } = this.props;
    return (
      <Table
        tableShopping
        tableHead={["Field", "Visible"]}
        tableData={[
          [
            "Product Title",
            <Checkbox
              checked={this.getFieldChecked("product_title") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("product_title")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ],
          [
            "Brand Title",
            <Checkbox
              checked={this.getFieldChecked("brand_title") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("brand_title")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ],
          [
            "Review",
            <Checkbox
              checked={this.getFieldChecked("review") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("review")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ],
          [
            "Share",
            <Checkbox
              checked={this.getFieldChecked("share") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("share")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ],
          [
            "Image",
            <Checkbox
              checked={this.getFieldChecked("carousel") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("carousel")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ],
          [
            "Primary Attributes",
            <Checkbox
              checked={this.getFieldChecked("primary_tab") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("primary_tab")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ],
          [
            "Add to cart",
            <Checkbox
              checked={this.getFieldChecked("add_to_cart") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("add_to_cart")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ],
          [
            "Size Picker",
            <Checkbox
              checked={this.getFieldChecked("size_picker") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("size_picker")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ],
          [
            "Color Picker",
            <Checkbox
              checked={this.getFieldChecked("color_picker") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("color_picker")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ],
          [
            "Offer",
            <Checkbox
              checked={this.getFieldChecked("product_offers") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("product_offers")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ],
          [
            "Tabs",
            <Checkbox
              checked={this.getFieldChecked("secondary_tab") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("secondary_tab")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ],
          [
            "Delivery",
            <Checkbox
              checked={this.getFieldChecked("delivery_estimate") ? true : false}
              tabIndex={-1}
              onClick={() => this.handleToggle("delivery_estimate")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          ]
        ]}
      />
    );
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.productCMSReducer.get.list,
  Loading: state.productCMSReducer.get.loading,
  ListError: state.productCMSReducer.get.error,

  Saving: state.productCMSReducer.create.saving,
  SaveError: state.productCMSReducer.create.error,
  isSaved: state.productCMSReducer.create.isSaved,
  UserId: state.authState.user.user,
  PartnerId: state.authState.user.partner_id,

  SegmentList: state.SegmentsReducer.list,
  SegmentLoading: state.SegmentsReducer.loading,
  SegmentError: state.SegmentsReducer.error,

  ClassList: state.classReducer.list,
  ClassLoading: state.classReducer.loading,
  ClassListError: state.classReducer.error
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.ADMIN ||
  rolesNames.RoleMaster.WHOLESALER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...extendedFormStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductType);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
