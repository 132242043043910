import React from 'react';
import { compose } from 'recompose';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AgGrid from "../../../components/AgGrid/AgGrid.jsx";
import EventTypeAction from "../../../components/AgGrid/EventTypeAction.jsx";
import { ActionCreators } from "../../../redux/actions";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class ListEventType extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            columnDefs: [
                {
                    headerName: "Name",
                    field: "name",
                    width: 270,
                    resizable: true,
                    sortable: true,
                    filter: "agTextColumnFilter"
                },
                {
                    headerName: "Description",
                    field: "description",
                    width: 180,
                    resizable: true,
                    sortable: true,
                    filter: "agTextColumnFilter"
                },
                {
                    headerName: "Status",
                    field: "is_active",
                    width: 250,
                    resizable: true,
                    sortable: true,
                    filter: "agTextColumnFilter",
                    cellRenderer: function (params) {
                        return params.data.is_active ? 'Active' : 'Inactive'
                    }
                },

                {
                    headerName: "Actions",
                    field: "value",
                    cellRenderer: "AgGridActionButton",
                    colId: "params",
                    width: 200,
                    filter: false
                }
            ],
            isAddNew: false,
            isEdit: false,
        }
        this.handelIsAddNew = this.handelIsAddNew.bind(this);
        this.handelEdit = this.handelEdit.bind(this);
        this.handelDelete = this.handelDelete.bind(this);
    }

    componentDidMount() {
        this.props.actions.getEventTypeAction()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.eventypes) {
            this.setState({
                list: nextProps.eventypes
            })
        }
    }

    handelEdit(id) {
        this.props.edit(id)
    }

    handelDelete(id) {
        this.props.delete(id)
    }

    handelIsAddNew() {
        this.props.new(true)
    }

    render() {
        return (
            <AgGrid
                rowData={this.state.list}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                handelDelete={this.handelDelete}
                loading={this.props.loading}
                AgGridActionButton={EventTypeAction}
                new={this.handelIsAddNew}
                isAddBtn={true}
            />
        );
    }

    handleChange = (key) => event => {
        this.setState({
            ...this.state,
            addEventType: {
                ...this.state.addEventType,
                [key]: event.target.value
            }
        })
    }
}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
    UserId: state.authState.user.pk,
    User: state.authState.user,
    eventypes: state.eventState.event_type.data,
    loading: state.eventState.event_type.loading,
    error: state.eventState.event_type.error,
});


export default compose(
    withStyles({
        ...extendedTablesStyle,
        ...customInputStyle,
        ...regularFormsStyle,
        ...validationFormsStyle,
    }),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ListEventType);