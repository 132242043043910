// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const getAllProductTemplates = async (partnerid, page, searchtext) => {
  try {
    let url = "api/product/template/getall/?partnerid=" + partnerid;
    if (page) {
      url = url + "&page=" + page;
    }

    if (searchtext) {
      url = url + "&search=" + searchtext;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllCategorisedProductTemplates = async () => {
  try {
    const response = await axios.get("api/producttemplate/categorised/list/");
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "An error has occured while getting list of Product Templates"
      );
    }
  } catch (error) {
    throw error;
  }
};

export const getAllBrandsCategorisedProductTemplates = async brandid => {
  try {
    const response = await axios.get(
      "api/producttemplate/brands/categorised/list/?id=" + brandid
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "An error has occured while getting list of Product Templates"
      );
    }
  } catch (error) {
    throw error;
  }
};

export const createProductTemplates = async (userID, data, partnerID) => {
  try {
    let form_data = new FormData();
    form_data.append("data", JSON.stringify(data));
    form_data.append("userid", userID);
    form_data.append("partnerid", partnerID);
    const response = await axios.post(
      "api/product/template/savetemplate/",
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveUniversalAttribute = async (userID, data) => {
  try {
    let form_data = new FormData();
    form_data.append("data", JSON.stringify(data));
    form_data.append("userid", userID);
    const response = await axios.post(
      "api/product/template/savexrefunivattrib/",
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const mapClassProductTemplate = async (userId, mapData) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(mapData));

    const response = await axios.post(
      "/api/classes/producttemplate/create/",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveProductTemplate = async (Savedata, userId, files) => {
  try {
    let data = new FormData();
    data.append("data", JSON.stringify(Savedata));
    data.append("userid", userId);
    if (files) {
      files.forEach(file => {
        data.append(file.name, file);
      });
    }
    const response = await axios.post(
      "/api/partner/product-template/save/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductTemplateById = async productid => {
  try {
    let data = new FormData();
    const response = await axios.get(
      "/api/partner/product-template/get/?productid=" + productid
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const publishProduct = async (productid, partnerid, userid) => {
  try {
    const response = await axios.get(
      "/api/partner/product-template/publish/?productid=" +
        productid +
        "&partnerid=" +
        partnerid +
        "&userid=" +
        userid,
      null,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
