import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";

// core components
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Datetime from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";

import moment from "moment";

const Style = {
  cardTitle: {
    "font-size": "x-large",
    "font-weight": "normal"
  },
  title: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "bold",
    "font-size": "36px",
    "line-height": "42px",
    "text-align": "center",
    color: "#666666"
  },
  titlePara: {
    "text-align": "center"
  },
  tabHeader: {
    color: "#919699"
  },
  selectedTabHeader: {
    color: "#F44336"
  },
  tabHeaderDiv: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "14px",
    "line-height": "16px",
    display: "flex",
    "align-items": "center",
    "text-align": "center",
    "letter-spacing": "0.75px",
    "text-transform": "uppercase",
    display: "inline-block",
    padding: "15px",
    cursor: "pointer",
    width: "16.66%"
  },
  questionHead: {
    "font-weight": "500"
  },
  pagination: {
    "font-weight": "normal",
    "font-size": "14px",
    "line-height": "20px",
    color: "#666666"
  }
};

const initialState = {
  total_page: 3,
  current_page: 1
};

class CampaignInfoTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        ...initialState
      },
      campaignData: {},
      error: null
    };
    this.clickPreviousBtn = this.clickPreviousBtn.bind(this);
    this.clickNextBtn = this.clickNextBtn.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.saveCampign = this.saveCampign.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (!_.isEqual(this.props.data, nextProps.data)) {
          this.setState({
            campaignData: nextProps.data
          });
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        campaignData: this.props.data
      });
    }
  }

  render() {
    const { data, classes } = this.props;
    const { current_page, total_page } = this.state.pagination;
    return (
      <Grid>
        <GridContainer>
          <GridItem lg={2} md={2} />
          <GridItem lg={8} md={8} sm={12} xs={12}>
            <GridContainer>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <div style={Style.pagination}>
                  Page: {current_page}/{total_page}
                </div>
                {this.showCampignInfoControlsBasedOnPage()}
                {this.state.error && this.state.error.trim() !== "" && (
                  <FormLabel className={classes.labelLeftHorizontal}>
                    <code>{this.state.error}</code>
                  </FormLabel>
                )}
              </GridItem>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <GridContainer>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    {current_page > 1 ? (
                      <Button onClick={this.clickPreviousBtn}>Previous</Button>
                    ) : (
                      ""
                    )}
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button onClick={this.clickNextBtn}>Next</Button>
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button
                      disabled={this.props.isSaving}
                      onClick={() => this.saveCampign(1)}
                      style={{
                        "margin-left": "10px"
                      }}
                    >
                      Save & Close
                    </Button>
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button
                      onClick={this.closeWizard}
                      style={{
                        "margin-left": "10px"
                      }}
                    >
                      Close
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem lg={2} md={2} />
        </GridContainer>
      </Grid>
    );
  }

  closeWizard() {
    this.props.closePromotion();
  }
  clickNextBtn() {
    let { current_page, total_page } = this.state.pagination;
    let new_page = current_page + 1;
    if (new_page <= total_page) {
      this.setState({
        ...this.state,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    } else {
      this.props.changeSelectedTab(1);
    }
  }

  clickPreviousBtn() {
    let { current_page } = this.state.pagination;
    let new_page = current_page - 1;
    if (new_page >= 1) {
      this.setState({
        ...this.state,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    }
  }

  showCampignInfoControlsBasedOnPage() {
    const {
      name,
      start_date,
      end_date,
      navigation_url,
      required
    } = this.state.campaignData;
    console.log("this.state.campaignData", this.state.campaignData);
    const { classes } = this.props;
    const { current_page } = this.state.pagination;
    switch (current_page) {
      case 1:
        return (
          <GridContainer>
            <div>
              <div className="campaign-info-label" style={Style.questionHead}>
                What would you like to name this Campaign?
              </div>
              <CustomInput
                // success={required.name === "success"}
                // error={required.name === "error"}
                labelText="Name *"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  value: name,
                  onChange: this.handleTextChange("name", "")
                }}
                id={"name_"}
              />
            </div>
          </GridContainer>
        );
        break;
      case 2:
        return (
          <GridContainer>
            <div>
              <div className="campaign-info-label" style={Style.questionHead}>
                When does this Campaign Start and End?
              </div>
              <GridContainer>
                <GridItem lg={6} md={6} sm={12} xs={12}>
                  <br />
                  <InputLabel className={classes.label + " "}>
                    Start Date *
                  </InputLabel>
                  <Datetime
                    timeFormat={false}
                    inputProps={{
                      placeholder: "Start Date",
                      value: start_date
                    }}
                    value={start_date}
                    onChange={this.handleStartDateChange}
                  />
                </GridItem>
                <GridItem lg={6} md={6} sm={12} xs={12}>
                  <br />
                  <InputLabel className={classes.label + " "}>
                    End Date *
                  </InputLabel>
                  <Datetime
                    timeFormat={false}
                    inputProps={{
                      placeholder: "End Date",
                      value: end_date
                    }}
                    onChange={this.handleEndDateChange}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </GridContainer>
        );
        break;
      case 3:
        return (
          <GridContainer>
            <div>
              <div className="campaign-info-label" style={Style.questionHead}>
                Add Navigation Url for this Campaign.
              </div>
              <CustomInput
                // success={required.name === "success"}
                // error={required.name === "error"}
                labelText="Navigation Url *"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  value: navigation_url,
                  onChange: this.handleTextChange("navigation_url", "")
                }}
                id={"nav_"}
              />
            </div>
          </GridContainer>
        );
        break;
    }
  }

  handleTextChange = (key, type) => event => {
    this.props.setPromotionData(key, event.target.value);
  };

  handleStartDateChange = event => {
    let today_date = moment().format("MM/DD/YYYY");
    let selected_date = moment(event).format("MM/DD/YYYY");
    let error = null;
    if (!this.props.editState && moment(selected_date).isBefore(today_date)) {
      error = "Start Date Can't be less than today's date";
    }

    this.setState(
      {
        error: error
      },
      () => {
        this.props.setPromotionData("start_date", selected_date);
      }
    );
  };

  handleEndDateChange = event => {
    let { start_date } = this.state.campaignData;
    let selected_date = moment(event).format("MM/DD/YYYY");

    let error = null;
    if (moment(selected_date).isBefore(start_date)) {
      error = "End Date Can't be less than start date";
    }
    this.setState(
      {
        error: error
      },
      () => {
        this.props.setPromotionData("end_date", selected_date);
      }
    );
  };
  saveCampign = filter => {
    this.props.save(filter);
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  retailers: state.partnerSate.brandPartner.list,
  channels: state.campignManagementReducer.channels.list,
  channelDistribution: state.campignManagementReducer.channeldistribution.list,
  List: state.campignManagementReducer.list,
  Loading: state.campignManagementReducer.loading,
  ListError: state.campignManagementReducer.error,
  isSaved: state.campignManagementReducer.isSaved,
  PartnerId: state.authState.user.partner_id,
  User: state.authState.user,
  UserId: state.authState.user.user,
  playPause: state.campignManagementReducer.playPause,
  deleteCampaign: state.campignManagementReducer.deleteCampaign,
  isSaving: state.campignManagementReducer.isSaving
});

CampaignInfoTab.propTypes = {
  closePromotion: PropTypes.func.isRequired,
  changeSelectedTab: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setPromotionData: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CampaignInfoTab);
