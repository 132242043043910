import * as NotificationtypeService from "../../services/NotificationtypeService";
import * as actionTypes from "./actionTypes";

export const getNotificationList = (
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getNotificationRequest());
  try {
    const success = await NotificationtypeService.getnotificationList(
      page,
      search
    );
    if (success.data && success.data.status == "1") {
      dispatch(getNotificationSuccess(success.data.data));
    } else {
      dispatch(getNotificationFailure("Failed to get details from server."));
    }
  } catch (error) {
    dispatch(getNotificationFailure(error.message));
  }
};
export const saveNotification = (id, data) => async dispatch => {
  dispatch(saveNotificationRequest());
  try {
    const success = await NotificationtypeService.savenotification(id, data);
    dispatch(saveNotificationSuccess(success.data));
  } catch (error) {
    dispatch(saveNotificationFailure(error.message));
  }
};

const saveNotificationRequest = () => {
  return {
    type: actionTypes.SAVE_NOTIFICATION_REQUEST
  };
};

const saveNotificationSuccess = data => {
  return {
    type: actionTypes.SAVE_NOTIFICATION_SUCCESS,
    payload: data
  };
};

const saveNotificationFailure = error => {
  return {
    type: actionTypes.SAVE_NOTIFICATION_FAILURE,
    payload: error
  };
};

const getNotificationRequest = () => {
  return {
    type: actionTypes.GET_NOTIFICATION_REQUEST
  };
};

const getNotificationSuccess = data => {
  return {
    type: actionTypes.GET_NOTIFICATION_SUCCESS,
    payload: data
  };
};

const getNotificationFailure = error => {
  return {
    type: actionTypes.GET_NOTIFICATION_FAILURE,
    payload: error
  };
};
