import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withAuthorization from "../Session/withAuthorization";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { rolesNames } from "../../constants";
import { validationUtils } from "../../utils";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import ProductAttributeAction from "../../../src/components/AgGrid/ProductAttributeAction";

class ProductAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      changeProduct: false,
      isEdit: false,
      required: {
        title: "",
        subhead: ""
      },
      newAttributeModal: false,
      addNew: true,
      attributeData: {
        userid: "",
        product: "",
        id: null,
        name: "",
        description: "",
        index: null,
        sort_order: "",
        attribute_group: "",
        attribute_value: ""
      },
      productList: [],
      product: "",
      isError: false,
      localAttibute: [],
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 250,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        // {
        //     headerName: "Sort Order",
        //     field: "sort_order",
        //     width: 200,
        //     resizable: true,
        //     sortable: true,
        //     filter: "agTextColumnFilter"
        // },
        {
          headerName: "description",
          field: "description",
          width: 250,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Attribute Value",
          field: "attribute_value",
          width: 250,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ]
    };
  }

  componentDidMount() {
    let isList = true;
    this.props.actions.getProductAttributeAssignmentList(
      this.props.PartnerId,
      isList
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.assignProductList &&
        nextProps.assignProductList.length > 0 &&
        !_.isEqual(this.props.assignProductList, nextProps.assignProductList)
      ) {
        const productListData = nextProps.assignProductList;
        this.setState(
          {
            productList: productListData,
            attributeData: {
              ...this.state.attributeData,
              product:
                productListData && productListData[0] && productListData[0].id
            },
            product: productListData[0].id
          },
          () => {
            const { productList } = this.state;
            if (!this.state.changeProduct && productList) {
              let selectedProduct =
                productList && productList[0] && productList[0].id;
              this.props.actions.getAttributesByProductIdAction(
                this.state.product
              );
            }
          }
        );
      }
      if (
        nextProps.AttributeList &&
        !_.isEqual(this.props.AttributeList, nextProps.AttributeList)
      ) {
        this.setState({
          ...this.state,
          localAttibute: nextProps.AttributeList
        });
      }
      if (nextProps.isSaved) {
        this.setState(() => ({
          isEdit: false,
          addNew: true,
          attributeData: {
            ...this.state.attributeData,
            name: "",
            description: "",
            index: null,
            sort_order: "",
            attribute_group: "",
            attribute_value: ""
          },
          newAttributeModal: false
        }));
      }
      let error = "";
      let loading = false;

      if (nextProps.ListError) {
        error = nextProps.ListError;
      }

      if (nextProps.Loading) {
        loading = nextProps.Loading;
      }

      this.setState({
        error: error,
        loading: loading
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.createAttributeModal()}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" icon>
              <div>
                <br />
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Local Attributes
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    style={{ marginTop: "14px" }}
                  >
                    <InputLabel
                      htmlFor="condition-select"
                      className={classes.selectLabel}
                      style={{ fontSize: "14px" }}
                    >
                      Product
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      name="product"
                      value={this.state.product}
                      onChange={this.handleCondition}
                      inputProps={{
                        name: "product",
                        id: "Products-select"
                      }}
                      style={{ fontSize: "14px" }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Products
                      </MenuItem>
                      {_.map(
                        this.state.productList,
                        ({ product_template, id, name }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                            >
                              {name}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>

                  <br />
                  <br />
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <Card
                        style={{
                          border: "1px solid #D1D1D1",
                          boxShadow: "2px 2px 8px rgba(140, 140, 140, 0.25)",
                          borderRadius: "6px"
                        }}
                      >
                        <CardHeader color="primary" icon>
                          <div>
                            <p
                              className={
                                classes.cardIconTitle + " grid-header-title"
                              }
                            >
                              Product Attributes
                            </p>
                            <p>
                              <Button
                                color="success"
                                size="sm"
                                onClick={() =>
                                  this.openModalDialog(
                                    "newAttributeModal",
                                    true
                                  )
                                }
                                className={
                                  classes.marginRight + " grid-header-button"
                                }
                              >
                                <AddIcon className="grid-header-button-icon" />{" "}
                                new
                              </Button>
                            </p>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <AgGrid
                            rowData={this.state.localAttibute}
                            columnDefs={this.state.columnDefs}
                            handelEdit={this.handellocalAttibuteEdit}
                            AgGridActionButton={ProductAttributeAction}
                            loading={this.state.loading}
                          />
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <br />
                  <Loader loading={this.state.loading} text="Loading.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  handleCondition = event => {
    this.setState(
      {
        ...this.state,
        attributeData: {
          ...this.state.attributeData,
          product: event.target.value
        },
        [event.target.name]: event.target.value,
        changeProduct: false
      },
      () => {
        this.props.actions.getAttributesByProductIdAction(this.state.product);
      }
    );
  };

  openModalDialog = (modalName, isOpen) => {
    this.setState({ [modalName]: isOpen });
  };

  closeModal() {
    this.setState({
      isEdit: false,
      addNew: true,
      attributeData: {
        ...this.state.attributeData,
        name: "",
        description: "",
        index: null,
        sort_order: "",
        attribute_group: "",
        attribute_value: ""
      },
      newAttributeModal: false
    });
  }

  createAttributeModal() {
    const { classes } = this.props;
    const { attributeData, isError, deletedItemName } = this.state;
    return (
      <Dialog
        open={this.state.newAttributeModal}
        disableBackdropClick={true}
        onClose={() => this.closeModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {this.state.addNew ? "Add New Attributes" : "Update Attributes"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CustomInput
              success={attributeData.name !== ""}
              error={attributeData.name === ""}
              labelText="Name *"
              id="name"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "text",
                value: attributeData.name,
                onChange: this.handelDailogTextChange(
                  "name",
                  "attributeData",
                  ""
                )
              }}
            />
            <CustomInput
              success={attributeData.attribute_value !== ""}
              error={attributeData.attribute_value === "" || isError}
              labelText="Value *"
              id="attribute_value"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "text",
                value: attributeData.attribute_value,
                onChange: this.handelDailogTextChange(
                  "attribute_value",
                  "attributeData",
                  ""
                )
              }}
            />

            <CustomInput
              success={attributeData.sort_order !== ""}
              error={attributeData.sort_order === "" || isError}
              labelText="Sort Order *"
              id="sort_order"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "number",
                value: attributeData.sort_order,
                onChange: this.handelDailogTextChange(
                  "sort_order",
                  "attributeData",
                  validationUtils.validationTypes.number
                )
              }}
            />

            <CustomInput
              success={attributeData.description !== ""}
              error={attributeData.description === "" || isError}
              labelText="Description *"
              id="description"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "text",
                value: attributeData.description,
                onChange: this.handelDailogTextChange(
                  "description",
                  "attributeData",
                  ""
                )
              }}
            />
            <br />
            {this.state.error && this.state.error.trim() !== "" && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code>{this.state.error}</code>
              </FormLabel>
            )}
            <br />
            {this.state.Loading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.state.Loading} text="Saving.." />
          </DialogContentText>
          <DialogActions>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              onClick={() => this.saveAttribute()}
              color="primary"
            >
              {this.state.addNew ? "Add" : "Update"}
            </Button>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              onClick={() => this.closeModal()}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  saveAttribute = () => {
    let validationMsg = this.validateData();
    if (validationMsg.length == 0) {
      this.props.actions.saveProductAttributes(
        this.state.attributeData,
        this.props.UserId
      );
    } else {
      this.setState({
        error: validationMsg.toString()
      });
    }
  };

  validateData() {
    const {
      name,
      description,
      attribute_value,
      sort_order,
      product
    } = this.state.attributeData;
    let error = 0;
    let msg = [];

    if (name == "" || name == null || name.length < 0) {
      error = 1;
      msg.push("Please enter the name of the attribute");
    }

    if (
      attribute_value == "" ||
      attribute_value == null ||
      attribute_value == 0
    ) {
      error = 1;
      msg.push("Please enter the value of the attribute");
    }

    if (description == "" || description == null || description.length < 0) {
      error = 1;
      msg.push("Please enter the description of the attribute");
    }

    if (sort_order == null || sort_order < 1) {
      error = 1;
      msg.push("Please enter the sort order of the attribute");
    }

    if (product == "" || product == null) {
      error = 1;
      msg.push("Please select the product of the attribute");
    }

    return msg;
  }

  handelDailogTextChange = (key, object, type) => event => {
    var isValid = true;
    if (!validationUtils.validateData(type, event.target.value)) {
      isValid = false;
    }
    if (key === "sort_order") {
      if (isNaN(event.target.value) === false) {
        var data = this.state[object];
        data[key] = event.target.value;
        this.setState({
          [object]: data,
          isError: !isValid
        });
      }
    } else {
      var data = this.state[object];
      data[key] = event.target.value;
      this.setState({
        [object]: data,
        isError: !isValid
      });
    }
  };

  handellocalAttibuteEdit = id => {
    const data = this.state.localAttibute;
    let index = data.findIndex(a => a.id == id);
    if (data[index]) {
      this.setState({
        ...this.state,
        attributeData: {
          ...this.state.attributeData,
          name: data[index].name,
          attribute_value: data[index].attribute_value,
          index: index,
          id: data[index].id,
          sort_order: data[index].sort_order,
          attribute_group: "",
          description: data[index].description
        },
        addNew: false,
        isEdit: true
      });
      this.openModalDialog("newAttributeModal", true);
    }
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  AttributeList: state.productAttributesReducer.attributes,
  Loading: state.productAttributesReducer.loading,
  ListError: state.productAttributesReducer.error,
  isSaved: state.productAttributesReducer.isSaved,
  assignProductList: state.productAttributesReducer.list,
  UserId: state.authState.user.pk,
  PartnerId: state.authState.user.partner_id
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...dashboardStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductAttributes);
