export const GET_OFFERS_REQUEST = "get_offers_request";
export const GET_OFFERS_FAILURE = "get_offers_failure";
export const GET_OFFERS_SUCCESS = "get_offers_success";

export const CREATE_OFFERS_REQUEST = "create_offers_request";
export const CREATE_OFFERS_FAILURE = "create_offers_failure";
export const CREATE_OFFERS_SUCCESS = "create_offers_success";

export const GET_OFFERS_TYPE_REQUEST = "get_offers_type_request";
export const GET_OFFERS_TYPE_FAILURE = "get_offers_type_failure";
export const GET_OFFERS_TYPE_SUCCESS = "get_offers_type_success";

export const SAVE_OFFERS_TYPE_REQUEST = "save_offers_type_request";
export const SAVE_OFFERS_TYPE_FAILURE = "save_offers_type_failure";
export const SAVE_OFFERS_TYPE_SUCCESS = "save_offers_type_success";

export const MAP_OFFER_PRODUCT_REQUEST = "map_offer_product_request";
export const MAP_OFFER_PRODUCT_COMPLETED = "map_offer_product_completed";
export const MAP_OFFER_PRODUCT_FAILURE = "map_offer_product_failure";
export const MAP_OFFER_PRODUCT_SUCCESS = "map_offer_product_success";

export const REMOVE_SAVED_DATA = "remove_saved_data";

export const GET_OFFER_BYID_REQUEST = "get_offer_byid_request";
export const GET_OFFER_BYID_FAILURE = "get_offer_byid_failure";
export const GET_OFFER_BYID_SUCCESS = "get_offer_byid_success";
