import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as routesNames from "../../constants/routes";

import {
  DashboardPage,
  LoginPage,
  NotFoundPage,
  AdminLayout,
  AuthLayout,
  CreateWebsite,
  ServicePage,
  Segments,
  BrandsPage,
  ProductPage,
  BrandDetailsPage,
  SegmentProductDetailsPage,
  SegmentDetailsPage,
  BrandAssignmentPage,
  CreateBrandsPage,
  CreateProductsPage,
  CreateAttributesPage,
  CreateProductTemplatePage,
  ProductAttributes,
  UniversalAttributePage,
  AttributeGroupsPage,
  TemplateUniversalAttributesPage,
  ProductAssignmentPage,
  Class,
  WholeSaler,
  BusinessType,
  IndustriesPage,
  ProductTemplateComponent,
  BrandComponent,
  ProductTemplatesWholesalesalerComponent,
  RetailerBrandmapComponent,
  RetailerProductTemplateMapping,
  retailerwholesalercomponent,
  ProductTemplatesRetailerComponent,
  TagsType,
  Tags,
  MenuMaster,
  Service,
  CMS,
  Message,
  Offers,
  NotificationType,
  OfferType,
  Shipping,
  AdManager,
  RetailerOrder,
  RetailerOrderDetails,
  AdChannel,
  ProductType,
  AssetManager,
  PaymentMethod,
  Template,
  OrderDetailReport,
  PaymentDetailReport,
  SettlementReport,
  ChangePasswordPage,
  Settings,
  UserGroup,
  Users,
  RouteMiddleware,
  SSOValidate,
  StockLocation,
  StockManagement,
  NetStockPage,
  ProductTemplate,
  AdminProductTemplateWizard,
  Category,
  WholesalorHierarchy,
  ClassProductHeirarchy,
  ClassProductTemplateHeirarchy,
  PartnerPartnersMapping,
  PartnerService,
  CustomPages,
  CMSComponents,
  CMSPageBuilder,
  CMSHeader,
  CMSFooter,
  Banner,
  PartnerCampaign,
  BrandManagement,
  PartnerImport,
  PartnerMetaData,
  ClonePartners,
  Variants,
  ProductVariantGroup,
  RetailerProductReport,
  EventType,
  Events,
  BrandProductImport,
  CategoryHierarchy
} from "../../views";

const Routes = (
  <Switch>
    <Route exact path={routesNames.DASHBOARD} component={DashboardPage} />
    <Route
      exact
      path={routesNames.ROUTEMIDDLEWARE}
      component={RouteMiddleware}
    />
    <Route exact path={routesNames.SSOMIDDLEWARE} component={SSOValidate} />
    <Route exact path={routesNames.AdminLayout} component={AdminLayout} />
    <Route path={routesNames.LOGIN} component={LoginPage} />
    <Route
      path={routesNames.CHANGEPASSWORDPAGE}
      component={ChangePasswordPage}
    />
    <Route path={routesNames.CREATEWEBSITE} component={CreateWebsite} />
    <Route path={routesNames.SERVICES} component={ServicePage} />
    <Route path={routesNames.SEGMENTS} component={Segments} />
    <Route path={routesNames.PRODUCTPAGE} component={ProductPage} />
    <Route path={routesNames.BRANDS} component={BrandsPage} />
    <Route path={routesNames.BRANDDETAILS} component={BrandDetailsPage} />
    <Route
      path={routesNames.SEGMENTPRODUCTDETAILS}
      component={SegmentProductDetailsPage}
    />
    <Route path={routesNames.SEGMENTSDETAILS} component={SegmentDetailsPage} />
    <Route
      path={routesNames.PRODDUCTASSIGNMENTS}
      component={ProductAssignmentPage}
    />
    <Route
      path={routesNames.BRANDASSIGNMENTS}
      component={BrandAssignmentPage}
    />
    <Route path={routesNames.CREATEBRANDS} component={CreateBrandsPage} />
    <Route path={routesNames.WHOLESALER} component={WholeSaler} />
    <Route path={routesNames.CREATEPRODUCTS} component={CreateProductsPage} />
    <Route
      path={routesNames.CREATEATTRIBUTES}
      component={CreateAttributesPage}
    />
    <Route
      path={routesNames.CREATE_PRODUCT_TEMPLATE}
      component={CreateProductTemplatePage}
    />
    <Route path={routesNames.PRODUCTATTRIBUTES} component={ProductAttributes} />
    <Route
      path={routesNames.UNIVERSALATTRIBUTES}
      component={UniversalAttributePage}
    />
    <Route
      path={routesNames.ATTRIBUTE_GROUPS}
      component={AttributeGroupsPage}
    />
    <Route path={routesNames.BUSINESSTYPE} component={BusinessType} />
    <Route path={routesNames.INDUSTRIESPAGE} component={IndustriesPage} />
    <Route
      path={routesNames.TEMPLATE_ATTRIBUTE_VALUE}
      component={TemplateUniversalAttributesPage}
    />
    <Route path={routesNames.CLASS} component={Class} />
    <Route
      path={routesNames.PRODUCTTEMPLATECOMPONENT}
      component={ProductTemplateComponent}
    />
    <Route path={routesNames.BRANDCOMPONENT} component={BrandComponent} />
    <Route
      path={routesNames.RBMAPCOMPONENT}
      component={RetailerBrandmapComponent}
    />
    {/* <Route path={routesNames.LISTOFWEBSITE} component={ListWebsite} /> */}
    <Route path={routesNames.AuthLayout} component={AuthLayout} />
    <Route
      path={routesNames.PRODUCTTEMPLATESWHOLESALESALERCOMPONENT}
      component={ProductTemplatesWholesalesalerComponent}
    />
    <Route
      path={routesNames.RETAILERWHOLESALERCOMPONENT}
      component={retailerwholesalercomponent}
    />
    <Route path={routesNames.TAGSTYPE} component={TagsType} />
    <Route path={routesNames.TAGS} component={Tags} />
    <Route
      path={routesNames.PRODUCTTEMPLATESRETAILERCOMPONENT}
      component={ProductTemplatesRetailerComponent}
    />
    <Route
      path={routesNames.RETAILERPRODUCTTEMPLATEMAPING}
      component={RetailerProductTemplateMapping}
    />
    <Route path={routesNames.MENUMASTER} component={MenuMaster} />
    <Route path={routesNames.CMS} component={CMS} />
    <Route path={routesNames.SERVICE} component={Service} />
    <Route path={routesNames.Message} component={Message} />
    <Route path={routesNames.NOTIFICATIONTYPE} component={NotificationType} />
    <Route path={routesNames.PARTNERBANNERS} component={Banner} />
    <Route path={routesNames.CMSCOMPONENT} component={CMSComponents} />
    <Route path={routesNames.CMSPAGEBUILDER} component={CMSPageBuilder} />
    <Route path={routesNames.CMSMETADATA} component={PartnerMetaData} />
    <Route path={routesNames.OFFERTYPE} component={OfferType} />
    <Route path={routesNames.Offers} component={Offers} />
    <Route path={routesNames.SHIPPINGMETHODS} component={Shipping} />
    <Route path={routesNames.ADMANAGER} component={AdManager} />
    <Route path={routesNames.ADCHANNEL} component={AdChannel} />
    <Route path={routesNames.ORDER} component={RetailerOrder} />
    <Route path={routesNames.ORDERDETAILS} component={RetailerOrderDetails} />
    <Route path={routesNames.ASSETMANAGER} component={AssetManager} />
    <Route path={routesNames.PAYMENTMETHOD} component={PaymentMethod} />
    <Route path={routesNames.PRODUCTCMS} component={ProductType} />
    <Route path={routesNames.PARTNERIMPORT} component={PartnerImport} />
    <Route path={routesNames.BRANDPRODUCTIMPORT} component={BrandProductImport} />
    <Route path={routesNames.PRODUCTIMPORT} component={BrandProductImport} />
    <Route
      path={routesNames.PAYMENTDETAILREPORT}
      component={PaymentDetailReport}
    />
    <Route path={routesNames.SETTLEMENTREPORT} component={SettlementReport} />
    <Route path={routesNames.PARTNERREPORT} component={RetailerProductReport} />
    <Route path={routesNames.ORDERDETAILREPORT} component={OrderDetailReport} />
    <Route path={routesNames.SETTINGS} component={Settings} />
    <Route path={routesNames.TEMPLATE} component={Template} />
    <Route path={routesNames.USERGROUP} component={UserGroup} />
    <Route path={routesNames.USERS} component={Users} />
    <Route path={routesNames.STOCKMANAGEMENT} component={StockManagement} />
    <Route path={routesNames.STOCKLOCATION} component={StockLocation} />
    <Route path={routesNames.NETSTOCK} component={NetStockPage} />
    <Route path={routesNames.PRODUCTTEMPLATE} component={ProductTemplate} />
    <Route
      path={routesNames.PRODUCTTEMPLATEPATH}
      component={AdminProductTemplateWizard}
    />
    <Route path={routesNames.CATEGORY} component={Category} />
    <Route path={routesNames.BRANDCATEGORYPRODUCT} component={CategoryHierarchy} />
    <Route
      path={routesNames.WHOLESALORHIERARCHY}
      component={WholesalorHierarchy}
    />
    <Route
      path={routesNames.CLASSPRODUCTHEIRARCHY}
      component={ClassProductHeirarchy}
    />
    <Route
      path={routesNames.CLASSPRODUCTTEMPLATEHEIRARCHY}
      component={ClassProductTemplateHeirarchy}
    />
    <Route
      path={routesNames.PARTNERMAPPING}
      component={PartnerPartnersMapping}
    />
    <Route path={routesNames.PARTNERSERVICE} component={PartnerService} />
    <Route path={routesNames.CUSTOMPAGES} component={CustomPages} />
    <Route path={routesNames.CMSHEADER} component={CMSHeader} />
    <Route path={routesNames.CMSFOOTER} component={CMSFooter} />
    <Route path={routesNames.PARTNERCAMPAIGN} component={PartnerCampaign} />
    <Route
      path={routesNames.PARTNERBRANDCAMPAIGN}
      component={BrandManagement}
    />
    <Route path={routesNames.CLONEPARTNERS} component={ClonePartners} />
    <Route path={routesNames.VARIANTS} component={Variants} />
    <Route path={routesNames.PRODUCTVARIANTS} component={ProductVariantGroup} />
    <Route path={routesNames.EVENTS} component={Events} />
    <Route path={routesNames.EVENTTYPE} component={EventType} />
    <Redirect from="/" to={routesNames.LOGIN} />
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
