// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const getMessageList = async retailer_key => {
  try {
    const response = await axios.get(
      "/api/public/notification/list/?apikey=" + retailer_key
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
