import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import "antd/dist/antd.css";
// import { Select, Table, Input, Button, Popconfirm, Form } from 'antd';
// import { Select, Input, Popconfirm, Form } from 'antd';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
const { Option } = Select;
const weekDay = [
  {
    day: "Sunday",
    sort_name: "sun",
    value: 0
  },
  {
    day: "Monday",
    sort_name: "sun",
    value: 1
  },
  {
    day: "Tuesday",
    sort_name: "sun",
    value: 2
  },
  {
    day: "Wednesday",
    sort_name: "sun",
    value: 3
  },
  {
    day: "Thursday",
    sort_name: "sun",
    value: 4
  },
  {
    day: "Friday",
    sort_name: "sun",
    value: 5
  },
  {
    day: "Saturday",
    sort_name: "sun",
    value: 6
  }
];

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

class Hours extends React.Component {
  constructor(props) {
    super(props);
    // this.handleChange=this.handleChange.bind(this)
    this.state = {
      directors_array: [
        "director-0",
        "director-1",
        "director-2",
        "director-3",
        "director-4",
        "director-5",
        "director-6"
      ],
      weekData: [
        {
          day: "Sunday",
          start_time: "10:00",
          end_time: "12:00"
        },
        {
          day: "Monday",
          start_time: "10:00",
          end_time: "12:00"
        },
        {
          day: "Tuesday",
          start_time: "10:00",
          end_time: "12:00"
        },
        {
          day: "Wednesday",
          start_time: "10:00",
          end_time: "12:00"
        },
        {
          day: "Thursday",
          start_time: "10:00",
          end_time: "12:00"
        },
        {
          day: "Friday",
          start_time: "10:00",
          end_time: "12:00"
        },
        {
          day: "Saturday",
          start_time: "10:00",
          end_time: "12:00"
        }
      ]
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.hourList && nextProps.hourList.length > 0) {
        this.setState({
          weekData: nextProps.hourList
        });
      }
    }
  }

  componentDidMount() {}
  appendInput_director() {
    var newInput = `director-${this.state.directors_array.length}`;
    this.setState(prevState => ({
      directors_array: prevState.directors_array.concat([newInput])
    }));
  }
  removeInput_director(index) {
    var newInput = `director-${this.state.directors_array.length}`;
    const { directors_array } = this.state;
    directors_array.splice(index, 1);
    this.setState(prevState => ({
      directors_array: directors_array
    }));
  }

  handleChange = (index, key) => event => {
    const { weekData } = this.state;
    weekData[index][key] = event.target.value;
    this.setState({
      weekData: weekData
    });
  };

  onhandleHours = () => {
    this.props.onHandleAdd(this.state.weekData, this.props.index);
  };

  handelCloseHoursComponent = () => {
    this.props.onClose();
  };
  render() {
    const { classes } = this.props;
    const { weekData } = this.state;

    return (
      <div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.props.isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handelCloseHoursComponent()}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle} style={{ width: "500px" }}>
              Hours
            </h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <LoadingOverlay>
              <DialogContentText>
                {this.state.directors_array.map((input, index) => (
                  <span>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={4}>
                        <Select
                          variant="outlined"
                          style={{ width: "100%" }}
                          value={"this.state.website.role"}
                          classes={{
                            root: "select-control-root"
                          }}
                          size="small"
                          value={index}
                          disabled={true}
                          // onChange={this.handleTextChange('role', "")}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Day
                          </MenuItem>
                          {_.map(
                            weekDay,
                            ({ day, sort_name, value }, index) => {
                              return (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={index}
                                  key={index + "_" + sort_name}
                                >
                                  {day}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={4}>
                        <TextField
                          variant="outlined"
                          required
                          id="startTime"
                          type="time"
                          size="small"
                          defaultValue="10:00"
                          value={weekData[index].start_time}
                          fullWidth
                          classes={{
                            root: "attribute-txtbox"
                          }}
                          onChange={this.handleChange(index, "start_time")}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={4}>
                        <TextField
                          variant="outlined"
                          required
                          id="startTime"
                          type="time"
                          size="small"
                          defaultValue="10:00"
                          fullWidth
                          value={weekData[index].end_time}
                          classes={{
                            root: "attribute-txtbox"
                          }}
                          onChange={this.handleChange(index, "end_time")}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={12} lg={3}>

                            {
                                this.state.directors_array.length && (


                                    <span>
                                        {this.state.directors_array.length != 0 && <CancelPresentationIcon
                                            color="secondary"
                                            style={{ fontSize: '32px' }}
                                            onClick={() => this.removeInput_director(index)}
                                        />
                                        }
                                        {this.state.directors_array.length == index + 1 && <AddBoxIcon
                                            color="primary"
                                            style={{ fontSize: '32px' }}
                                            onClick={() => this.appendInput_director()}
                                        />}
                                    </span>
                                )
                            }


                        </GridItem> */}
                    </GridContainer>
                    <br />
                  </span>
                ))}
              </DialogContentText>

              {this.props.Deleting && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-12px"
                  }}
                />
              )}
              <Loader loading={this.props.Deleting} text="Deleting.." />
            </LoadingOverlay>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => this.onhandleHours()} color="danger" simple>
              Add
            </Button>
            <Button
              onClick={() => this.handelCloseHoursComponent()}
              color="danger"
              simple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  ClassList: state.classReducer.list,
  // ClassList: state.categoryReducer.categorydata.data,
  UserId: state.authState.user.user,
  PartnerId: state.authState.user.partner_id
});

Hours.propTypes = {
  index: PropTypes.string,
  onHandleAdd: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hourList: PropTypes.array.isRequired
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Hours);
