import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import { Dialog, Icon, withStyles, DialogActions, Select, InputLabel, CircularProgress, FormLabel, Grid, DialogContent, DialogContentText, DialogTitle, TextField, MenuItem } from "@material-ui/core";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import Autocomplete from "@material-ui/lab/Autocomplete";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withAuthorization from "../../Session/withAuthorization";
import { rolesNames } from "../../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { validationUtils } from "../../../utils";
import ImageUpload from "../../components/ImageUpload";
import BrandCategoryActionButton from "../../../components/AgGrid/BrandCategoryActionButton";
import AgGrid from "components/AgGrid/AgGrid.jsx";

const initialCategory = {
    name: "",
    description: "",
    image: "",
    parent_category: "",
    products: [],
    brand: {}
};

class CategoryHierarchy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isInitialSet: true,
            searchtext: null,
            pagination: {
                page: "1",
                totalpage: "1",
                next: false,
                prev: false,
                pagesize: 25
            },
            loading: false,
            categoryProductsLoading: false,
            categoryProducts: [],
            brandcategorylist: [],
            selectedClassIndexes: [],
            selectedEntity: [],
            modalOpen: false,
            productTemplates: [],
            brandlist: [],
            selectedCategory: {},
            isAddNew: false,
            addCategory: {
                ...initialCategory
            },
            required: {
                name: "",
                description: ""
            },
            categoryData: null,
            error: null,
            columnDefs: [
                {
                    headerName: "Name",
                    field: "name",
                    width: 350,
                    resizable: true,
                    sortable: true,
                    filter: "agTextColumnFilter"
                },
                {
                    headerName: "Description",
                    field: "description",
                    width: 450,
                    resizable: true,
                    sortable: true,
                    filter: false
                },
                {
                    headerName: "Actions",
                    field: "value",
                    cellRenderer: "AgGridActionButton",
                    colId: "params",
                    width: 200,
                    filter: false
                }
            ],
            domLayout: "autoHeight",
            defaultColDef: {
                sortable: true,
                resizable: true,
                filter: true
            },
            treeViewCol: [
                {
                    headerName: "Brand / Category / Product",
                    field: "name",
                    minWidth: 900,
                    width: 900,
                    resizable: true,
                    showRowGroup: true,
                    cellRenderer: "agGroupCellRenderer",
                    cellRendererParams: {
                        suppressCount: true,
                        checkbox: true,
                        innerRenderer: "checkboxCellRenderer",
                        suppressDoubleClickExpand: true,
                        suppressEnterExpand: true
                    }
                }
            ],
            treeViewColAdmin: [
                {
                    headerName: "Brand / Category / Product",
                    field: "name",
                    width: 750,
                    resizable: true,
                    flex: 1,
                    sortable: true,
                    filter: "agTextColumnFilter",
                    cellRenderer: "agGroupCellRenderer",
                    cellRendererParams: {
                        innerRenderer: "checkboxCellRenderer",
                        suppressDoubleClickExpand: true,
                        suppressEnterExpand: true
                    }
                },
                {
                    headerName: "Actions",
                    field: "value",
                    cellRenderer: "AgGridActionButton",
                    colId: "params",
                    width: 200,
                    filter: false
                }
            ],
            groupDefaultExpanded: 1,
            context: { categoryGrid: this },
            frameworkComponents: {
                AgGridActionButton: BrandCategoryActionButton
            },
            hierarchicalView: function (data) {
                if (data && data.children && data.children.length > 0) {
                    return {
                        group: true,
                        children: data.children,
                        expanded: data.open
                    };
                } else {
                    return null;
                }
            }
        };
        this.handelEdit = this.handelEdit.bind(this);
        this.view = this.view.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.openModal = this.openModal.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.createProduct = this.createProduct.bind(this);
        this.handleIconLabelClick = this.handleIconLabelClick.bind(this);
        this.searchText = this.searchText.bind(this);
        this.updateGridData = this.updateGridData.bind(this);
        this.gridRefs = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            if (
                nextProps.List &&
                nextProps.List.length > 0 &&
                !_.isEqual(this.props.List, nextProps.List)
            ) {
                const categoryData = nextProps.List;
                this.setState({
                    categoryData: categoryData
                });
            }

            if (nextProps.isSaved) {
                this.setState(
                    {
                        isAddNew: false,
                        isEdit: false
                    },
                    () => {
                        this.props.actions.changeCategorySaveCheck();
                        this.props.actions.getBrandCategoryProductHierarchyAction(
                            this.props.PartnerId,
                            0,
                            this.state.pagination.page
                        );
                    }
                );
            }
            let loading = false;
            if (nextProps.Loading) {
                loading = nextProps.Loading;
            }
            if (nextProps.isLoading) {
                loading = nextProps.isLoading;
            }
            this.setState({
                loading: loading
            });
            if (
                nextProps.CategoryById &&
                !_.isEqual(this.props.CategoryById, nextProps.CategoryById)
            ) {
                this.setState(
                    {
                        addCategory: nextProps.CategoryById,
                        isAddNew: true,
                        isEdit: true
                    },
                    () => {
                        let partnerid = this.props.PartnerId;
                        if (this.props.authUser.role === "BR") {
                            partnerid = this.props.PartnerId;
                        } else {
                            partnerid = this.state.addCategory.brand.id;
                        }
                        this.props.actions.getCategoryListAction(
                            partnerid,
                            this.state.addCategory.id
                        );
                    }
                );
            }
            let error = "";
            if (nextProps.ListError) {
                error = nextProps.ListError;
            }
            this.setState({
                error: error
            });

            if (
                (this.props.authUser.role === "BR" ||
                    this.props.authUser.role === "AD") &&
                nextProps.productTemplates &&
                nextProps.productTemplates.length > 0
            ) {
                this.setState({
                    productTemplates: nextProps.productTemplates
                });
            }

            if (nextProps.batchSaved) {
                this.setState({
                    modalOpen: false
                });
            }

            if (
                this.props.authUser.role === "RE" &&
                nextProps.assignProductList &&
                nextProps.assignProductList.length > 0
            ) {
                this.setState({
                    productTemplates: nextProps.assignProductList
                });
            }
            if (nextProps.Brands) {
                let brandlist = [...nextProps.Brands];
                this.setState({
                    brandlist: brandlist
                });
            }

            if (nextProps.categorylist) {
                this.setState({
                    brandcategorylist: nextProps.categorylist,
                    pagination: nextProps.pagination
                });
            }

            if (nextProps.categoryProducts) {
                this.setState({
                    categoryProducts: nextProps.categoryProducts
                });
            }
            if (
                !nextProps.savedTemplatesLoading &&
                nextProps.product_template_list &&
                nextProps.product_template_list.length > 0 &&
                nextProps.categorylist &&
                !nextProps.batchSaved &&
                !nextProps.batchSaving
            ) {
                this.setState(
                    {
                        selectedEntity: nextProps.product_template_list
                    },
                    () => {
                        this.gridRefs.current.setSelections(
                            this.state.selectedEntity,
                            "BRAND_PUSH"
                        );
                    }
                );

            }

            this.setState({
                categoryProductsLoading: nextProps.categoryProductsLoading,
                categoryProducts: nextProps.categoryProducts
                    ? nextProps.categoryProducts
                    : []
            });

            if (nextProps.isDeleted) {
                const { addCategory } = this.state;
                delete addCategory.image;
                this.setState(
                    {
                        addCategory: {
                            ...this.state.addCategory,
                            image: null
                        }
                    },
                    () => {
                        this.props.actions.denyRemoveImageAction();
                    }
                );
            }
        }
    }

    componentDidMount() {

        if (this.props.authUser.role === "BR") {
            this.props.actions.getAllProductTemplates(this.props.PartnerId);
        }
        this.props.actions.getBrandCategoryProductHierarchyAction(
            this.props.PartnerId,
            0,
            this.state.pagination.page
        );
    }

    componentWillMount() {
        this.props.actions.getSavedBatchProductList(this.props.PartnerId);
    }

    handelIsAddNew = () => {
        if (this.props.authUser.role === "AD") {
            this.props.actions.getBrand(rolesNames.RoleMaster.BRAND, true);
            this.props.actions.getAllProductTemplates(this.props.PartnerId);
        }
        this.setState(
            {
                isAddNew: !this.state.isAddNew,
                addCategory: {
                    name: "",
                    description: "",
                    image: ""
                }
            },
            () => {
                if (this.props.authUser.role === "BR") {
                    this.props.actions.getCategoryListAction(this.props.PartnerId);
                }
            }
        );
    };

    view = () => { };
    edit = data => {
        if (this.props.authUser.role === "AD") {
            this.props.actions.getALLBrand(rolesNames.RoleMaster.BRAND);
            this.props.actions.getAllProductTemplates(this.props.PartnerId);
        }
        this.props.actions.getCategoryByIdAction(data);
    };

    delete = () => { };

    closeCreateComponent = () => {
        this.setState(
            {
                isAddNew: false,
                isEdit: false
            },
            () => {
                this.props.actions.getBrandCategoryProductHierarchyAction(
                    this.props.PartnerId,
                    0,
                    this.state.pagination.page
                );
            }
        );
    };

    handleTextChange = (key, type) => event => {
        var validData = "success";
        if (!validationUtils.validateData(type, event.target.value)) {
            validData = "error";
        }
        var reqData = this.state.required;
        reqData[key] = validData;
        this.setState({ required: reqData });

        this.setState({
            addCategory: {
                ...this.state.addCategory,
                [key]: event.target.value
            }
        });
    };

    handleChange = (key, type) => event => {
        this.setState({
            addCategory: {
                ...this.state.addCategory,
                [key]: event.target.value
            }
        });
    };

    handleProductChange = (key, value) => {
        if (key == "brand") {
            if (value && value.id) {
                this.props.actions.getCategoryListAction(value.id);
            }
        }

        this.setState({
            addCategory: {
                ...this.state.addCategory,
                [key]: value
            }
        });
    };

    saveCategory = () => {
        let validation = this.validateIsRequired();
        if (validation.length == 0) {
            let savingData = this.state.addCategory;
            savingData.partner_id = this.props.PartnerId;
            this.props.actions.saveCategoryAction(this.props.UserId, savingData);
        } else {
            this.setState({
                error: validation.toString()
            });
        }
    };

    getGridSelectedRow() {
        let selectedRows = this.gridRefs.current.agGridApi.getSelectedRows();
        let selectedEntity = []
        selectedRows.forEach(function (selectedRow) {
            if (selectedRow.role === 'PR') {
                selectedEntity.push(selectedRow.id);
            }
        });
        return selectedEntity
    }
    openModal() {
        let selections = this.getGridSelectedRow();
        this.setState({
            modalOpen: true,
            selectedEntity: selections
        }, () => {
            this.gridRefs.current.setSelections(this.state.selectedEntity, 'BRAND_PUSH');
        });
    }
    handelEdit(id) {
        this.props.actions.getCategoryByIdAction(id);
    }
    handleModalClose() {
        this.setState({
            modalOpen: false,
            selectedEntity: []
        });
    }

    createProduct() {
        this.props.actions.batchProductCreate(
            this.state.selectedEntity,
            this.props.UserId,
            this.props.PartnerId
        );
    }

    render() {
        return this.state.isAddNew
            ? this.renderCategory()
            : this.renderCategoryTable();
    }

    renderCategoryTable = () => {
        const { classes, authRole } = this.props;
        const {
            brandcategorylist,
            categoryProducts,
            categoryProductsLoading
        } = this.state;
        return (
            <GridContainer>
                <Dialog
                    open={this.state.modalOpen}
                    onClose={this.handleModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to create the products?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            style={{ backgroundColor: "#248f24" }}
                            onClick={this.createProduct}
                            autoFocus
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={this.handleModalClose}
                            style={{ backgroundColor: "#999" }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <GridItem lg={12} />
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <div>
                                <p className={classes.cardIconTitle + " grid-header-title"}>
                                    Category
                                </p>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <AgGrid
                                ref={this.gridRefs}
                                rowData={this.state.brandcategorylist}
                                columnDefs={
                                    this.props.authRole === "RE"
                                        ? this.state.treeViewCol
                                        : this.state.treeViewColAdmin
                                }
                                handelEdit={this.edit}
                                AgGridActionButton={
                                    this.props.authRole === "RE"
                                        ? null
                                        : BrandCategoryActionButton
                                }
                                update={this.updateGridData}
                                isServerPagination={true}
                                suppressRowClickSelection={true}
                                groupSuppressAutoColumn={true}
                                pagination={this.state.pagination}
                                loading={this.state.loading}
                                search={this.searchText}
                                new={this.handelIsAddNew}
                                isAddBtn={
                                    authRole != rolesNames.RoleMaster.RETAILER ? true : false
                                }
                                isHierarchyView={true}
                                getNodeChildDetails={this.state.hierarchicalView}

                                rowSelection="multiple"
                                groupSelectsChildren={true}
                                isInitialSet={this.state.isInitialSet}
                            />

                        </CardBody>
                        <CardBody>
                            {this.props.authRole === "RE" && (
                                <div style={{ float: "right" }}>
                                    <Button
                                        color="info"
                                        style={{ backgroundColor: "#248f24" }}
                                        onClick={this.openModal}
                                    >
                                        Add Selected Product
                                    </Button>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    getSelectedNodes(filter = null) {
        let selectedRows = this.gridRefs.current.agGridApi.getSelectedNodes();
        let selectednodes = [];
        selectednodes = this.getAllNestedNodeWithParent(
            selectedRows,
            selectednodes
        );
        if (filter) {
            selectednodes = selectednodes.filter(x => x.role === filter);
        }
        return selectednodes;
    }

    getAllNestedNodeWithParent(nodes, selectedEntity) {
        const componentThis = this;
        if (_.isArray(nodes)) {
            nodes.forEach(function (selectedRow, index) {
                if (
                    selectedRow.data.role == "BR" ||
                    selectedRow.data.role == "WS" ||
                    selectedRow.data.role == "PR" ||
                    selectedRow.data.role == "CAT" ||
                    selectedRow.data.role == undefined
                ) {
                    let data = {
                        id: "",
                        role: "PR"
                    };
                    data.id = selectedRow.data.id;
                    data.role = selectedRow.data.role ? selectedRow.data.role : "PR";
                    if (
                        selectedEntity.findIndex(
                            x => x.id == data.id && x.role == data.role
                        ) == -1
                    ) {
                        selectedEntity.push(data);
                    }
                }
                if (selectedRow.parent) {
                    componentThis.getAllNestedNodeWithParent(
                        selectedRow.parent,
                        selectedEntity
                    );
                }
            });
        }
        if (_.isObject(nodes)) {
            if (
                nodes.data &&
                (nodes.data.role == "BR" ||
                    nodes.data.role == "CAT" ||
                    nodes.data.role == "WS" ||
                    nodes.data.role == "PR" ||
                    nodes.data.role == undefined)
            ) {
                let data = {
                    id: "",
                    role: "PR"
                };
                data.id = nodes.data.id;
                data.role = nodes.data.role ? nodes.data.role : "PR";
                if (
                    selectedEntity.findIndex(
                        x => x.id == data.id && x.role == data.role
                    ) == -1
                ) {
                    selectedEntity.push(data);
                }
            }
            if (nodes.parent) {
                componentThis.getAllNestedNodeWithParent(nodes.parent, selectedEntity);
            }
        }

        return selectedEntity;
    }

    saveNextPagePopup(page) {
        let seletedentities = this.getSelectedNodes();
        if (seletedentities && seletedentities.length) {
            this.setState(
                {
                    nextpage: page,
                    isSaveNextModal: true
                },
                () => {
                    this.gridRefs.current.setSelections(
                        seletedentities,
                        "partner_mapping"
                    );
                }
            );
        } else {
            this.updateGridData(page);
        }
    }

    updateGridData(page) {
        this.props.actions.getBrandCategoryProductHierarchyAction(
            this.props.PartnerId,
            0,
            page,
            this.state.searchtext
        );
    }

    searchText(params) {
        this.setState(
            {
                searchtext: params
            },
            () => {
                let page = 1;
                this.props.actions.getBrandCategoryProductHierarchyAction(
                    this.props.PartnerId,
                    0,
                    page,
                    this.state.searchtext
                );
            }
        );
    }

    getNodeChildDetails(data) {
        if (data && data.children && data.children.length > 0) {

            return {
                group: true,
                children: data.children,
                expanded: data.open
            };
        } else {
            return null;
        }
    }

    handleIconLabelClick(node) {
        if (node.role == "CAT") {
            this.props.actions.getCategoryProductMappingAction(
                node.id,
                this.props.PartnerId
            );
        }
    }

    validateIsRequired() {
        const { name, description, image, brand } = this.state.addCategory;
        let msg = [];
        let error = 0;

        if (name == "" || name == null || name.length < 0) {
            error = 1;
            msg.push("Please enter the name of the Category");
        }

        if (image == null || image.length < 1) {
            error = 1;
            msg.push("Please add the image for the Category");
        }

        if (this.props.authUser.role == rolesNames.RoleMaster.ADMIN) {
            if (brand == "" || brand == null || brand.length < 0) {
                error = 1;
                msg.push("Please select the brand of the Category");
            }
        }
        return msg;
    }

    handelImageChange = file => {
        if (file instanceof Blob) {
            let reader = new FileReader();
            let { addCategory } = this.state;
            let original_name = file.name;
            let fileSize = file.size;
            addCategory["image"] = file;

            addCategory["file_size"] = fileSize;
            addCategory["original_file_name"] = original_name;
            this.setState({
                addCategory: addCategory
            });
        } else {
            let { addCategory } = this.state;
            addCategory.image = file.url;
            if (file.is_asset) {
                addCategory.asset_id = file.id;
            }
            addCategory.file_size = file.file_size;
            addCategory.original_file_name = file.original_file_name;
            this.setState({
                ...this.state,
                addCategory: addCategory
            });
        }
    };

    handelImageRemove = () => {
        let { image } = this.state.addCategory;

        if (image instanceof Blob) {
            this.setState({
                addCategory: {
                    ...this.state.addCategory,
                    image: null
                }
            });
        } else if (image instanceof Object) {
            this.props.actions.removeImageAction(image.id, 1);
        }
    };


    renderCategory = () => {
        const { classes } = this.props;
        const {
            name,
            description,
            parent_category,
            products,
            brand,
            image
        } = this.state.addCategory;
        const { required } = this.state;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
                            </CardIcon>
                            <p className={classes.cardIconTitle}>
                                Make edits to the fields below, then save changes.
                            </p>
                        </CardHeader>
                        <CardBody>
                            <LoadingOverlay>
                                <Grid>
                                    <form encType="multipart/form-data" action="">
                                        <GridContainer>
                                            {this.props.authUser.role ==
                                                rolesNames.RoleMaster.ADMIN ? (
                                                <GridItem lg={6} className="griditem-control">
                                                    <InputLabel className={classes.label + " "}>
                                                        Brands
                                                    </InputLabel>
                                                    <Autocomplete
                                                        disabled={this.state.isEdit}
                                                        options={this.state.brandlist}
                                                        getOptionLabel={option => option.name}
                                                        filterSelectedOptions
                                                        value={brand ? brand : {}}
                                                        onChange={(event, value) =>
                                                            this.handleProductChange("brand", value)
                                                        }
                                                        classes={{
                                                            root: "custom-autocomplete",
                                                            inputRoot: "custom-autocomplete-input-root",
                                                            tag: "custom-autocomplete-tag"
                                                        }}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Brand"
                                                            />
                                                        )}
                                                    />
                                                </GridItem>
                                            ) : (
                                                ""
                                            )}
                                            <GridItem lg={6} className="griditem-control">
                                                <InputLabel className={classes.label + " "}>
                                                    Name
                                                </InputLabel>
                                                <TextField
                                                    value={name ? name : ""}
                                                    variant="outlined"
                                                    name="name"
                                                    fullWidth
                                                    placeholder="Name"
                                                    classes={{
                                                        root: "attribute-txtbox"
                                                    }}
                                                    onChange={this.handleTextChange("name", "")}
                                                />
                                            </GridItem>
                                            <GridItem lg={6} className="griditem-control">
                                                <InputLabel className={classes.label + " "}>
                                                    Parent Category
                                                </InputLabel>
                                                <br />
                                                <Select
                                                    variant="outlined"
                                                    style={{ width: "100%" }}
                                                    value={parent_category ? parent_category : 0}
                                                    classes={{
                                                        root: "select-control-root"
                                                    }}
                                                    onChange={this.handleChange("parent_category", "")}
                                                >
                                                    <MenuItem
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                        style={{
                                                            opacity: "0.5"
                                                        }}
                                                        value={null}
                                                    >
                                                        ---- Clear Selection ----
                                                    </MenuItem>
                                                    {_.map(
                                                        this.state.categoryData,
                                                        ({ id, name }, index) => {
                                                            return (
                                                                <MenuItem
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value={id}
                                                                    key={index + "_" + id}
                                                                >
                                                                    {name}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </GridItem>
                                            <GridItem lg={12} className="griditem-control">
                                                <InputLabel className={classes.label + " "}>
                                                    Description
                                                </InputLabel>
                                                <TextField
                                                    value={description ? description : ""}
                                                    variant="outlined"
                                                    name="description"
                                                    fullWidth
                                                    placeholder="Description"
                                                    classes={{
                                                        root: "attribute-txtbox"
                                                    }}
                                                    onChange={this.handleTextChange("description", "")}
                                                />
                                            </GridItem>
                                            <GridItem className="griditem-control">
                                                <InputLabel className={classes.label + " "}>
                                                    Image
                                                </InputLabel>
                                                <br />
                                                <ImageUpload
                                                    addButtonProps={{
                                                        round: true
                                                    }}
                                                    changeButtonProps={{
                                                        color: "rose",
                                                        round: true
                                                    }}
                                                    removeButtonProps={{
                                                        color: "danger",
                                                        round: true
                                                    }}
                                                    multiple={true} //// true or false, it will simply hide + button to add more image
                                                    imgUrl={
                                                        image instanceof Object
                                                            ? image instanceof Blob
                                                                ? image
                                                                : image.url
                                                            : image
                                                    }
                                                    onimageRemove={this.handelImageRemove}
                                                    onChange={this.handelImageChange}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        {this.state.error && this.state.error.trim() !== "" && (
                                            <FormLabel className={classes.labelLeftHorizontal}>
                                                <code>{this.state.error}</code>
                                            </FormLabel>
                                        )}

                                        <br />
                                        <Button color="info" onClick={this.saveCategory}>
                                            {this.state.isEdit ? "Update Changes" : "Save Changes"}
                                        </Button>
                                        <Button onClick={this.closeCreateComponent}>Cancel</Button>
                                        {this.state.loading && (
                                            <CircularProgress
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: "-25px",
                                                    marginLeft: "-12px"
                                                }}
                                            />
                                        )}
                                        <Loader loading={this.state.loading} text="Saving.." />
                                    </form>
                                </Grid>
                            </LoadingOverlay>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };
}
const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
    List: state.categoryReducer.categorydata.data,
    Loading: state.categoryReducer.brandCategoryProductHierarchy.loading,
    savedTemplatesLoading: state.batchProductCreationReducer.loading,
    product_template_list:
        state.batchProductCreationReducer.product_template_list,
    ListError: state.categoryReducer.categorydata.error,
    UserId: state.authState.user.pk,
    PartnerId: state.authState.user.partner_id,
    CategoryById: state.categoryReducer.categoryById.data,
    assignProductList: state.productsAssignmentsState.list,
    productTemplates: state.brandState.productTemplate.products,
    authUser: state.authState.user,
    authRole: state.authState.user.role,
    batchSaved: state.batchProductCreationReducer.saved,
    batchSaving: state.batchProductCreationReducer.saving,
    Brands: state.brandState.list,
    categorylist: state.categoryReducer.brandCategoryProductHierarchy.data,
    categoryProducts: state.categoryReducer.categoryProductMappingData.data,
    pagination: state.categoryReducer.brandCategoryProductHierarchy.pagination,
    categoryProductsLoading:
        state.categoryReducer.categoryProductMappingData.loading,
    isSaved: state.categoryReducer.categorydata.isSaved,
    isLoading: state.categoryReducer.categorydata.loading,
    isDeleted: state.appMenuReducer.removeImage.isDeleted
});

const condition = authUser =>
    authUser.role === rolesNames.RoleMaster.RETAILER ||
    rolesNames.RoleMaster.BRAND ||
    rolesNames.RoleMaster.ADMIN;

export default compose(
    withAuthorization(condition),
    withStyles({
        ...regularFormsStyle,
        ...extendedTablesStyle,
        ...validationFormsStyle
    }),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(CategoryHierarchy);
