import axios from "../utils/axios.jsx";

export const getCategoryList = async (partnerid, excludeitem) => {
  try {
    const response = await axios.get(
      "/api/partner/category/list/?partnerid=" +
      partnerid +
      "&excludeitem=" +
      excludeitem
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoryById = async id => {
  try {
    const response = await axios.get(
      "/api/partner/category-by-id/get/?categoryid=" + id
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCategory = async (id, partnerid) => {
  try {
    const response = await axios.get(
      "/api/partner/category/delete/?categoryid=" + id + "&partnerid=" + partnerid
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveCategory = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("file", Savedata.image);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("/api/partner/category/save/", data, {
      "content-type": "multipart/form-data"
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoryProductMapping = async (categoryid, partnerid) => {
  try {
    const response = await axios.get(
      "/api/partner/category-product-mapping/get/?categoryid=" +
      categoryid +
      "&partnerid=" +
      partnerid
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const mapProductWithCategory = async (userId, mapdata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(mapdata));
    const response = await axios.post(
      "/api/partner/category-product-mapping/save/",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBrandCategoryProductHierarchy = async (
  partnerid,
  selectedPartnerId,
  page,
  search
) => {
  try {
    let url =
      "/api/partner/brand-category-product/get/?partnerid=" +
      partnerid +
      "&partnersid=" +
      selectedPartnerId;
    if (page) {
      url = url + "&page=" + page;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getWholesalorBrandCategoryProductHierarchy = async (
  partnerid,
  ismapview,
  page,
  search
) => {
  try {
    let url =
      "/api/partner/wholesalor-brand-product/get/?partnerid=" +
      partnerid +
      "&isMapView=" +
      ismapview;
    if (page) {
      url = url + "&page=" + page;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getPartnerCategoryHierarchy = async (
  partnerid,
  selectedPartnerId,
  page,
  search
) => {
  try {
    let url =
      "/api/partner/category-hierarchy/get/?partnerid=" +
      partnerid +
      "&partnersid=" +
      selectedPartnerId;
    if (page) {
      url = url + "&page=" + page;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
