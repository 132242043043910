export const CREATE_PAYMENT_METHOD_REQUEST = "create_payment_method_request";
export const CREATE_PAYMENT_METHOD_FAILURE = "create_payment_method_failure";
export const CREATE_PAYMENT_METHOD_SUCCESS = "create_payment_method_success";

export const GET_PAYMENT_METHOD_REQUEST = "get_payment_method_request";
export const GET_PAYMENT_METHOD_FAILURE = "get_payment_method_failure";
export const GET_PAYMENT_METHOD_SUCCESS = "get_payment_method_success";

export const GET_PAYMENT_CRED_REQUEST = "get_payment_cred_request";
export const GET_PAYMENT_CRED_FAILURE = "get_payment_cred_failure";
export const GET_PAYMENT_CRED_SUCCESS = "get_payment_cred_success";
