import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { validationUtils } from "../../../utils";

// @material-ui/core components/Dialog

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
// import TextField from '@material-ui/core/TextField';
import TextField from "@material-ui/core/TextField";
// interface FilmOptionType {
//     name: string;
//     year: number;
// }
class AddMenuForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.state = {
      isAddNew: false,
      cmsMenuList: [],
      menuKey: null,
      menuLink: {
        url: "",
        name: "",
        description: "",
        sort_order: "",
        status: true,
        key: null,
        issubmenu: false
      },
      required: {
        name: "",
        url: "",
        description: "",
        sort_order: "",
        status: ""
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        loading: nextProps.PartnerSaving
      });
    }
    if (
      nextProps &&
      nextProps.menuList &&
      nextProps.menuList.list &&
      nextProps.menuList.list.length > 0
    ) {
      this.setUrlList(nextProps.menuList.list);
    }

    if (nextProps && nextProps.formData) {
      this.setEditData(nextProps.formData);
    } else {
      this.setAddNew();
    }
    if (nextProps && nextProps.isSaved) {
      this.props.onClose();
    }
  }

  setAddNew = () => {
    this.setState({
      menuLink: {
        url: "",
        name: "",
        description: "",
        sort_order: 9999,
        status: true,
        key: null,
        issubmenu: false
      }
    });
  };
  setEditData = data => {
    this.setState({
      menuLink: {
        url: data.url,
        name: data.name,
        description: data.description,
        sort_order: data.sort_order,
        status: data.status,
        key: data.key,
        issubmenu: data.issubmenu
      }
    });
  };
  setUrlList = list => {
    let productsClasses = list.filter(element => element.url != "");
    this.setState({
      cmsMenuList: productsClasses
    });
  };

  handleToggleStatus = curObj => event => {
    this.setState({
      addAddress: {
        ...this.state.addAddress,
        is_pickup: event.target.checked
      }
    });
  };
  handelIsAddNew = () => {
    this.props.onClose();
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });
    this.setState({
      menuLink: {
        ...this.state.menuLink,
        [key]: event.target.value
      }
    });
  };

  render() {
    return this.renderClass();
  }
  saveHandler = () => {
    this.props.onSave(this.state.menuLink);
  };
  componentDidMount() {
    this.props.actions.CMSMenuList(this.props.authUser.partner_id, "CMS");
  }
  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.menuLink.hasOwnProperty("name") ||
      (this.state.menuLink.name.trim() === "" || reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  onSelectChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      menuLink: {
        ...this.state.menuLink,
        [key]: event.target.value,
        key: event.currentTarget.title,
        name: event.currentTarget.accessKey
      }
    });
  };

  handleToggleStatus = key => event => {
    this.setState({
      menuLink: {
        ...this.state.menuLink,
        [key]: event.target.checked
      }
    });
  };

  renderClass = () => {
    const { classes } = this.props;
    const {
      name,
      url,
      description,
      sort_order,
      status,
      key,
      issubmenu
    } = this.state.menuLink;
    const { isEdit, required, cmsMenuList } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <LoadingOverlay>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <InputLabel className={classes.label + " "}>url</InputLabel>
                <Select
                  variant="outlined"
                  style={{ width: "100%" }}
                  success={required.url === "success"}
                  error={required.url === "error"}
                  value={url ? url : null}
                  classes={{
                    root: "select-control-root"
                  }}
                  onChange={this.onSelectChange("url", "")}
                  name="slturl"
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    URL
                  </MenuItem>
                  {_.map(
                    cmsMenuList,
                    ({ url, name, id, key, slug, is_system_page }, index) => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={url}
                          id={is_system_page ? "yes" : "no"}
                          title={slug}
                          accessKey={name}
                        >
                          {name}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <InputLabel className={classes.label + " "}>Title</InputLabel>
                <br />
                <TextField
                  value={name}
                  success={required.name === "success"}
                  error={required.name === "error"}
                  variant="outlined"
                  fullWidth
                  classes={{
                    root: "attribute-txtbox"
                  }}
                  onChange={this.handleTextChange("name", "")}
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={status}
                      onClick={this.handleToggleStatus("status")}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{ label: classes.label }}
                  label="Active"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={issubmenu}
                      onClick={this.handleToggleStatus("issubmenu")}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      disabled={key ? false : true}
                    />
                  }
                  classes={{ label: classes.label }}
                  label='Group by "More"'
                />
              </GridItem>
            </GridContainer>
            {this.props.loading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.loading} text="" />
            <Button color="info" onClick={() => this.saveHandler()}>
              Save
            </Button>
            <Button onClick={() => this.handelIsAddNew(false)}>Cancel</Button>
            {(this.props.Loading || this.props.PartnerSaving) && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader
              loading={
                this.props.PartnerSaving || this.props.partnerDetailLoading
              }
              text=""
            />
          </LoadingOverlay>
        </GridItem>
      </GridContainer>
    );
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  authUser: state.authState.user,
  menuList: state.partnerMenuReducer.get_cms_menu_list,
  isSaved: state.CMSContentReducer.add_cms_menu.isSaved,
  loading: state.CMSContentReducer.add_cms_menu.loading,
  error: state.CMSContentReducer.add_cms_menu.error,
  SuccessMessage: state.CMSContentReducer.add_cms_menu.msg
});

export default compose(
  // withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddMenuForm);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
