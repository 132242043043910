import * as batchProductCreationServices from "../../services/batchProductCreationServices";
import * as actionTypes from "./actionTypes";

export const batchProductCreate = (
  data,
  userId,
  partnerId
) => async dispatch => {
  dispatch(batchProductCreateRequest());
  try {
    let successnew = await batchProductCreationServices.batchProductCreate(
      data,
      userId,
      partnerId
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(batchProductCreateSuccess(successnew.data));
    } else {
      dispatch(batchProductCreateFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(batchProductCreateFailure(error.message));
  }
};

const batchProductCreateRequest = () => {
  return {
    type: actionTypes.BATCH_PRODUCT_CREATE_REQUEST
  };
};

const batchProductCreateSuccess = data => {
  return {
    type: actionTypes.BATCH_PRODUCT_CREATE_SUCCESS,
    payload: data
  };
};

const batchProductCreateFailure = error => {
  return {
    type: actionTypes.BATCH_PRODUCT_CREATE_FAILURE,
    payload: error
  };
};

export const getSavedBatchProductList = userid => async dispatch => {
  dispatch(getSavedBatchProductListRequest());
  try {
    var success = await batchProductCreationServices.getSavedBatchProductList(
      userid
    );
    if (success.status && success.status === "1") {
      dispatch(getSavedBatchProductListSuccess(success.data));
    } else {
      dispatch(getSavedBatchProductListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getSavedBatchProductListFailure(error.message));
  }
};

const getSavedBatchProductListRequest = () => {
  return {
    type: actionTypes.GET_BATCH_PRODUCT_LIST_REQUEST
  };
};

const getSavedBatchProductListSuccess = data => {
  return {
    type: actionTypes.GET_BATCH_PRODUCT_LIST_SUCCESS,
    payload: data
  };
};

const getSavedBatchProductListFailure = error => {
  return {
    type: actionTypes.GET_BATCH_PRODUCT_LIST_FAILURE,
    payload: error
  };
};

// export const getCategoryByIdAction = (id) =>
//     async dispatch => {
//         dispatch(getCategoryByIdRequest());
//         try {
//             var success = await categoryServices.getCategoryById(id);
//             if (success.status && success.status === "1") {
//                 dispatch(getCategoryByIdSuccess(success.data));
//             } else {
//                 dispatch(getCategoryByIdFailure("Some Error Occured."));
//             }
//         } catch (error) {
//             dispatch(getCategoryByIdFailure(error.message));
//         }
//     }

// const getCategoryByIdRequest = () => {
//     return {
//         type: actionTypes.GET_CATEGORY_BYID_REQUEST
//     }
// }

// const getCategoryByIdSuccess = (data) => {
//     return {
//         type: actionTypes.GET_CATEGORY_BYID_SUCCESS,
//         payload: data
//     }
// }

// const getCategoryByIdFailure = (error) => {
//     return {
//         type: actionTypes.GET_CATEGORY_BYID_FAILURE,
//         payload: error
//     }
// }

// export const getCategoryProductMappingAction = (categoryid, partnerid) =>
//     async dispatch => {
//         dispatch(getCategoryProductMappingRequest());
//         try {
//             var success = await categoryServices.getCategoryProductMapping(categoryid, partnerid);
//             if (success.status && success.status === "1") {
//                 dispatch(getCategoryProductMappingSuccess(success.data));
//             } else {
//                 dispatch(getCategoryProductMappingFailure("Some Error Occured."));
//             }
//         } catch (error) {
//             dispatch(getCategoryProductMappingFailure(error.message));
//         }
//     }

// const getCategoryProductMappingRequest = () => {
//     return {
//         type: actionTypes.GET_CATEGORY_PRODUCT_MAPPING_REQUEST
//     }
// }

// const getCategoryProductMappingSuccess = (data) => {
//     return {
//         type: actionTypes.GET_CATEGORY_PRODUCT_MAPPING_SUCCESS,
//         payload: data
//     }
// }

// const getCategoryProductMappingFailure = (error) => {
//     return {
//         type: actionTypes.GET_CATEGORY_PRODUCT_MAPPING_FAILURE,
//         payload: error
//     }
// }

// export const mapProductWithCategoryAction = (userid, data) =>
//     async dispatch => {
//         dispatch(mapProductWithCategoryRequest());
//         try {
//             var success = await categoryServices.mapProductWithCategory(userid, data);
//             if (success.status && success.status === "1") {
//                 dispatch(mapProductWithCategorySuccess(success.data));
//             } else {
//                 dispatch(mapProductWithCategoryFailure("Some Error Occured."));
//             }
//         } catch (error) {
//             dispatch(mapProductWithCategoryFailure(error.message));
//         }
//     }

// const mapProductWithCategoryRequest = () => {
//     return {
//         type: actionTypes.MAP_PRODUCT_WITH_CATEGORY_REQUEST
//     }
// }

// const mapProductWithCategorySuccess = (data) => {
//     return {
//         type: actionTypes.MAP_PRODUCT_WITH_CATEGORY_SUCCESS,
//         payload: data
//     }
// }

// const mapProductWithCategoryFailure = (error) => {
//     return {
//         type: actionTypes.MAP_PRODUCT_WITH_CATEGORY_FAILURE,
//         payload: error
//     }
// }

// export const getBrandCategoryProductHierarchyAction = (partnerid) =>
//     async dispatch => {
//         dispatch(getBrandCategoryProductHierarchyRequest());
//         try {
//             var success = await categoryServices.getBrandCategoryProductHierarchy(partnerid);
//             if (success.status && success.status === "1") {
//                 dispatch(getBrandCategoryProductHierarchySuccess(success.data));
//             } else {
//                 dispatch(getBrandCategoryProductHierarchyFailure("Some Error Occured."));
//             }
//         } catch (error) {
//             dispatch(getBrandCategoryProductHierarchyFailure(error.message));
//         }
//     }

// const getBrandCategoryProductHierarchyRequest = () => {
//     return {
//         type: actionTypes.GET_BRAND_CATEGORY_PRODUCT_REQUEST
//     }
// }

// const getBrandCategoryProductHierarchySuccess = (data) => {
//     return {
//         type: actionTypes.GET_BRAND_CATEGORY_PRODUCT_SUCCESS,
//         payload: data
//     }
// }

// const getBrandCategoryProductHierarchyFailure = (error) => {
//     return {
//         type: actionTypes.GET_BRAND_CATEGORY_PRODUCT_FAILURE,
//         payload: error
//     }
// }
