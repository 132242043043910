import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import { rolesNames } from "../../constants";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { AgGridReact } from "ag-grid-react";
import withStyles from "@material-ui/core/styles/withStyles";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import Button from "../../components/CustomButtons/Button";
import * as routesNames from "../../constants/routes";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import _ from "lodash";

function classColumnRenderer(params) {
  let classesMapped = "";
  if (
    params.data &&
    params.data.xref_classes &&
    params.data.xref_classes.length > 0
  ) {
    for (let i = 0; i < params.data.xref_classes.length; i++) {
      let currentClass = params.data.xref_classes[i];
      if (currentClass && currentClass.classes) {
        if (classesMapped) {
          classesMapped = classesMapped + ", ";
        }
        classesMapped = classesMapped + currentClass.classes.name;
      }
    }
  }
  return classesMapped;
}

function segmentColumnRenderer(params) {
  let segmentsMapped = [];

  if (
    params.data &&
    params.data.xref_classes &&
    params.data.xref_classes.length > 0
  ) {
    for (let i = 0; i < params.data.xref_classes.length; i++) {
      let currentClass = params.data.xref_classes[i];
      if (
        currentClass &&
        currentClass.xref_segment &&
        currentClass.xref_segment.length > 0
      ) {
        for (let j = 0; j < currentClass.xref_segment.length; j++) {
          if (
            segmentsMapped.indexOf(currentClass.xref_segment[j].segments.name) >
            -1
          ) {
            //In the array!, skip this name
          } else {
            segmentsMapped.push(currentClass.xref_segment[j].segments.name);
          }
        }
      }
    }
  }

  let segmentsName = "";
  if (segmentsMapped && segmentsMapped.length > 0) {
    for (let i = 0; i < segmentsMapped.length; i++) {
      if (segmentsName) {
        segmentsName = segmentsName + ", ";
      }
      segmentsName = segmentsName + segmentsMapped[i];
    }
  }
  return segmentsName;
}

let agGridViewApi = null;
let agGridEditApi = null;

class ProductTemplatesWholesalesalerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Wholesaler_name: "",

      selectedClassIndexes: [],
      ViewMode: true,
      ViewData: [],
      SavingCompleted: false,
      columnDefsView: [
        {
          headerName: "ID",
          field: "ptid",
          hide: true
        },
        {
          headerName: "Brand Name",
          field: "partnersname",
          width: 200,
          resizable: true,
          rowGroup: true,
          hide: true
        },
        {
          headerName: "Segment Name",
          field: "segname",
          width: 200,
          resizable: true,
          rowGroup: true,
          hide: true
        },
        {
          headerName: "Class Name",
          field: "clname",
          width: 200,
          resizable: true,
          rowGroup: true,
          hide: true
        },
        {
          headerName: "Name",
          field: "ptname",
          width: 300,
          suppressSizeToFit: true
        },
        {
          headerName: "Description",
          field: "ptdesc",
          resizable: true
        }
      ],
      columnDefs: [
        {
          headerName: "ID",
          field: "ptid",
          hide: true
        },
        {
          headerName: "Brand Name",
          field: "partnersname",
          width: 200,
          resizable: true,
          rowGroup: true,
          hide: true
        },
        {
          headerName: "Segment Name",
          field: "segname",
          width: 200,
          resizable: true,
          rowGroup: true,
          hide: true
        },
        {
          headerName: "Class Name",
          field: "clname",
          width: 200,
          resizable: true,
          rowGroup: true,
          hide: true
        },
        {
          headerName: "Name",
          field: "ptname",
          width: 300,
          suppressSizeToFit: true,
          resizable: true,
          //rowGroup: true
          checkboxSelection: true
        },
        {
          headerName: "Description",
          field: "ptdesc",
          resizable: true
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      rowData: null,
      domLayout: "autoHeight",
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      autoGroupColumnDefViewMode: {
        headerName: "Brand / Segments / Class",
        field: "",
        rowDrag: false,
        cellRendererParams: { checkbox: false },
        headerCheckboxSelection: false,
        width: 350,
        suppressSizeToFit: true
      },
      autoGroupColumnDef: {
        headerName: "Brand / Segments / Class",
        field: "",
        rowDrag: false,
        cellRendererParams: { checkbox: true },
        headerCheckboxSelection: true,
        width: 350,
        suppressSizeToFit: true
      },
      rowSelection: "multiple",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
  }

  onGridViewReady = params => {
    agGridViewApi = _.clone(params.api, true);
  };

  onGridEditReady = params => {
    agGridEditApi = _.clone(params.api, true);
  };

  handelEdit = () => event => {
    let themeIndexCounter = this.state.themeIndex + 1;
    if (this.state.themeIndex > 6) {
      themeIndexCounter = 0;
    }
    this.setState({ themeIndex: themeIndexCounter });
  };

  setSelectedIndexes = products => {
    if (this.state.ViewMode) {
      return;
    }

    this.setState(
      {
        selectedClassIndexes: []
      },
      () => {
        let selectedIndexArray = _.clone(this.state.selectedClassIndexes, true);

        for (let i = 0; i < products.length; i++) {
          if (selectedIndexArray.indexOf(products[i].ptid) > -1) {
            // node.setSelected(true);
          } else {
            selectedIndexArray.push(products[i].ptid);
          }
        }

        // this.setState({ selectedClassIndexes: selectedIndexArray });

        this.setState(
          {
            selectedClassIndexes: selectedIndexArray
          },
          () => {
            this.onGridRowSelected();
          }
        );
      }
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (
          nextProps.productTemplates &&
          nextProps.productTemplates.products &&
          nextProps.productTemplates.products.length > 0
        ) {
          this.setState(
            {
              rowData: nextProps.productTemplates.products
            },
            () => {
              if (this.state.ViewData && this.state.ViewData.length > 0) {
                this.setSelectedIndexes(this.state.ViewData);
                if (agGridEditApi) agGridEditApi.sizeColumnsToFit();
              }
            }
          );
        }

        if (
          nextProps.brandProductTemplates &&
          nextProps.brandProductTemplates.products &&
          nextProps.brandProductTemplates.products.length > 0
        ) {
          this.setState(
            {
              ViewData: nextProps.brandProductTemplates.products
            },
            () => {
              if (agGridViewApi) agGridViewApi.sizeColumnsToFit();
            }
          );
        }

        if (
          nextProps.saveProductTemplates &&
          nextProps.saveProductTemplates.saved
        ) {
          this.handleMode();
        }
      }
    }
    // let Brand_name = window.sessionStorage.getItem("Brand_Name");
    // this.setState({
    //     Brand_name: Brand_name

    // });
    let Wholesaler_name = window.sessionStorage.getItem("Wholesaler_Name");
    this.setState({
      Wholesaler_name: Wholesaler_name
    });
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let entityId = params.get("id");
    this.props.actions.getAllMapWholesalerCategorisedProductTemplatesSelected(
      entityId
    );
    // this.props.actions.getAllCategorisedProductTemplates();
  }

  onRowSelected(event) {
    if (event.node.data) {
      let selectedIndexArray = _.clone(this.state.selectedClassIndexes, true);
      if (event.node.isSelected()) {
        if (selectedIndexArray.indexOf(event.node.data.ptid) > -1) {
          // node.setSelected(true);
        } else {
          selectedIndexArray.push(event.node.data.ptid);
        }
      } else {
        if (selectedIndexArray.indexOf(event.node.data.ptid) > -1) {
          selectedIndexArray.pop(event.node.data.ptid);
        }
      }

      this.setState(
        {
          selectedClassIndexes: selectedIndexArray
        },
        () => {
          this.onGridRowSelected();
        }
      );
    }
  }

  onGridRowSelected() {
    const componentThis = this;
    agGridEditApi.forEachNode(function (node) {
      if (node.data) {
        if (
          componentThis.state.selectedClassIndexes.indexOf(node.data.ptid) > -1
        ) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      }
    });
  }

  onSelectionChanged() {
    // const componentThis = this;
    // agGridEditApi.forEachNode(function (node) {
    //     if (node.data) {
    //         if (componentThis.state.selectedClassIndexes.indexOf(node.data.ptid) > -1) {
    //             node.setSelected(true);
    //         } else {
    //             node.setSelected(false);
    //         }
    //     }
    // });
  }

  saveBrandProductTtemplates = () => {
    let { selectedClassIndexes } = this.state;
    var templateData = [];
    for (var i = 0; i < selectedClassIndexes.length; i++) {
      var data = {
        product_template: selectedClassIndexes[i]
      };
      templateData.push(data);
    }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let entityId = params.get("id");
    this.props.actions.saveBrandProductTemplates(
      this.props.UserId,
      entityId,
      templateData
    );
  };

  handleMode = () => {

    let clickedAction = "brand"
    let entityType = "wholesaler";

    this.props.actions.onNavigateTo(
      routesNames.PRODUCTTEMPLATESWHOLESALESALERCOMPONENT +
      "?entityType=" +
      btoa(entityType) +
      "&id=" +
      btoa(this.props.partnerId) +
      "&name=" +
      btoa(this.props.UserId.name) +
      "&action=" +
      btoa(clickedAction)
    );

  };

  renderEditMode() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>
                View, edit or assign new product templates to{" "}
                {this.state.Wholesaler_name}.
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <div>
                  <div
                    id="myGrid"
                    style={{
                      width: "100%"
                    }}
                    className="ag-theme-material"
                  >
                    <AgGridReact
                      gridOptions={this.gridOptions}
                      columnDefs={this.state.columnDefs}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      showToolPanel={true}
                      // floatingFilter={true}
                      statusBar={this.state.statusBar}
                      groupSelectsChildren={true}
                      sideBar={this.state.sideBar}
                      onSelectionChanged={this.onSelectionChanged.bind(this)}
                      onRowSelected={this.onRowSelected.bind(this)}
                      autoGroupColumnDef={this.state.autoGroupColumnDef}
                      pagination={true}
                      paginationPageSize={50}
                      rowMultiSelectWithClick={true}
                      icons={this.state.icons}
                      rowSelection={this.state.rowSelection}
                      onGridReady={this.onGridEditReady}
                      domLayout={this.state.domLayout}
                    />
                  </div>
                </div>
                <div>
                  <Button
                    color="info"
                    onClick={() => this.saveBrandProductTtemplates()}
                  >
                    Save Changes
                  </Button>
                  <Button onClick={() => this.handleMode()}>Cancel</Button>
                </div>
                <Loader
                  loading={this.props.productTemplates.isLoading}
                  text="Loading.."
                />
                <Loader
                  loading={this.props.productTemplates.isSaving}
                  text="Saving.."
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  renderViewMode() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              color="success"
              onClick={() => this.handleMode()}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} /> Manage Assigned Templates
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>
                View, edit or assign new product templates to{" "}
                {this.state.Wholesaler_name}.
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <div>
                  <div
                    id="myGrid"
                    style={{
                      width: "100%"
                    }}
                    className="ag-theme-material"
                  >
                    <AgGridReact
                      gridOptions={this.gridOptions}
                      columnDefs={this.state.columnDefsView}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.ViewData}
                      showToolPanel={true}
                      // floatingFilter={true}
                      statusBar={this.state.statusBar}
                      sideBar={this.state.sideBar}
                      autoGroupColumnDef={this.state.autoGroupColumnDefViewMode}
                      pagination={true}
                      paginationPageSize={50}
                      icons={this.state.icons}
                      rowSelection={false}
                      onGridReady={this.onGridViewReady}
                      domLayout={this.state.domLayout}
                    />
                  </div>
                </div>
                <Loader
                  loading={this.props.brandProductTemplates.isLoading}
                  text="Loading.."
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    return (
      <div>
        {this.state.ViewMode && this.renderViewMode()}
        {!this.state.ViewMode && this.renderEditMode()}
      </div>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  productTemplates: state.brandState.map_wholesaler_brand_producttemplate,
  brandProductTemplates:
    state.brandState.map_wholesaler_brand_producttemplate_Selected,
  saveProductTemplates: state.brandState.selectedProdcutTemplates,
  UserId: state.authState.user.pk,
  PartnerId: state.authState.user.partner_id
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductTemplatesWholesalesalerComponent);
