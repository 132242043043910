import * as productVariantService from "../../services/productVariantService";
import * as actionTypes from "./actionTypes";


const getVariantRequest = () => {
    return {
        type: actionTypes.GET_PRODUCT_VARIANT_REQUEST
    };
};

const getVariantSuccess = data => {
    return {
        type: actionTypes.GET_PRODUCT_VARIANT_SUCCESS,
        payload: data
    };
};

const getVariantFailure = error => {
    return {
        type: actionTypes.GET_PRODUCT_VARIANT_FAILURE,
        payload: error
    };
};


export const getProductVariants = (partner_id, page = 0, searchtext = null, isActive = false) => async dispatch => {
    dispatch(getVariantRequest());
    try {

        const success = await productVariantService.getProductVariants(
            partner_id,
            page,
            searchtext,
            isActive
        );

        if (success && success.status) {
            dispatch(getVariantSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(getVariantFailure(success.error));
            }
            else {
                dispatch(getVariantFailure("An error has occured while loading variant details. Please try refreshing page."));
            }
        }
    } catch (error) {
        dispatch(getVariantFailure("An error has occured while loading variant details. Please try refreshing page."));
        console.error("Product Variant Load Error", error);
    }
};


const getVariantDetailsRequest = () => {
    return {
        type: actionTypes.GET_PRODUCT_VARIANT_DETAIL_REQUEST
    };
};

const getVariantDetailsSuccess = data => {
    return {
        type: actionTypes.GET_PRODUCT_VARIANT_DETAIL_SUCCESS,
        payload: data
    };
};

const getVariantDetailsFailure = error => {
    return {
        type: actionTypes.GET_PRODUCT_VARIANT_DETAIL_FAILURE,
        payload: error
    };
};


export const getProductVariantDetails = (variant_id, partner_id) => async dispatch => {
    dispatch(getVariantDetailsRequest());
    try {

        const success = await productVariantService.getProductVariant(variant_id, partner_id);

        if (success && success.status) {
            dispatch(getVariantDetailsSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(getVariantDetailsFailure(success.error));
            }
            else {
                dispatch(getVariantDetailsFailure("An error has occured while loading variant details. Please try refreshing page."));
            }
        }
    } catch (error) {
        dispatch(getVariantDetailsFailure("An error has occured while loading variant details. Please try refreshing page."));
        console.error("Variant Load Error", error);
    }
};



const saveVariantRequest = () => {
    return {
        type: actionTypes.CREATE_PRODUCT_VARIANT_REQUEST
    };
};

const saveVariantSuccess = data => {
    return {
        type: actionTypes.CREATE_PRODUCT_VARIANT_SUCCESS,
        payload: data
    };
};

const saveVariantFailure = error => {
    return {
        type: actionTypes.CREATE_PRODUCT_VARIANT_FAILURE,
        payload: error
    };
};


export const saveProductVariant = (user_id, data) => async dispatch => {
    dispatch(saveVariantRequest());
    try {
        const success = await productVariantService.saveProductVariantService(user_id, data);

        if (success && success.status) {
            dispatch(saveVariantSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(saveVariantFailure(success.error));
            }
            else {
                dispatch(saveVariantFailure("An error has occured while saving variant details. Please try after some time."));
            }
        }
    } catch (error) {
        dispatch(saveVariantFailure("An error has occured while loading variant details. Please try refreshing page."));
        console.error("Variant Load Error", error);
    }
};



const deleteVariantRequest = () => {
    return {
        type: actionTypes.DELETE_PRODUCT_VARIANT_REQUEST
    };
};

const deleteVariantSuccess = (data, id) => {
    return {
        type: actionTypes.DELETE_PRODUCT_VARIANT_SUCCESS,
        payload: data,
        id: id
    };
};

const deleteVariantFailure = error => {
    return {
        type: actionTypes.DELETE_PRODUCT_VARIANT_FAILURE,
        payload: error
    };
};

export const deleteProductVariant = (id) => async dispatch => {
    dispatch(deleteVariantRequest());
    try {
        const success = await productVariantService.deleteProductVariant(id);

        if (success && success.status) {
            dispatch(deleteVariantSuccess(success.data));
        }
        else {
            if (success.error) {
                dispatch(deleteVariantFailure(success.error));
            }
            else {
                dispatch(deleteVariantFailure("An error has occured while deleting variant. Please try after some time."));
            }
        }
    } catch (error) {
        dispatch(deleteVariantFailure("An error has occured while deleting variant. Please try after some time."));
        console.error("Variant Load Error", error);
    }
};
