export const GET_ABOUTUS_REQUEST = "get_aboutus_request";
export const GET_ABOUTUS_FAILURE = "get_aboutus_failure";
export const GET_ABOUTUS_SUCCESS = "get_aboutus_success";

export const RETAILER_PUBLISH_REQUEST = "retailer_publish_request";
export const RETAILER_PUBLISH_FAILURE = "retailer_publish_failure";
export const RETAILER_PUBLISH_SUCCESS = "retailer_publish_success";

export const SAVE_ABOUTUS_REQUEST = "save_aboutus_request";
export const SAVE_ABOUTUS_FAILURE = "save_aboutus_failure";
export const SAVE_ABOUTUS_SUCCESS = "save_aboutus_success";

export const DELETE_ABOUTUS_REQUEST = "delete_aboutus_request";
export const DELETE_ABOUTUS_FAILURE = "delete_aboutus_failure";
export const DELETE_ABOUTUS_SUCCESS = "delete_aboutus_success";

export const GET_PARTNER_REQUEST = "get_partner_request";
export const GET_PARTNER_FAILURE = "get_partner_failure";
export const GET_PARTNER_SUCCESS = "get_partner_success";

export const GET_BRAND_PARTNER_REQUEST = "get_brand_partner_request";
export const GET_BRAND_PARTNER_FAILURE = "get_brand_partner_failure";
export const GET_BRAND_PARTNER_SUCCESS = "get_brand_partner_success";

export const GET_PARTNER_SETTLEMENT_REQUEST = "get_partner_settlement_request";
export const GET_PARTNER_SETTLEMENT_FAILURE = "get_partner_settlement_failure";
export const GET_PARTNER_SETTLEMENT_SUCCESS = "get_partner_settlement_success";

export const APPROVE_PARTNER_SETTLEMENT_REQUEST =
  "APPROVE_partner_settlement_request";
export const APPROVE_PARTNER_SETTLEMENT_FAILURE =
  "APPROVE_partner_settlement_failure";
export const APPROVE_PARTNER_SETTLEMENT_SUCCESS =
  "APPROVE_partner_settlement_success";

export const SAVE_PARTNER_REQUEST = "save_partner_request";
export const SAVE_PARTNER_FAILURE = "save_partner_failure";
export const SAVE_PARTNER_SUCCESS = "save_partner_success";

export const UPDATE_PARTNER_REQUEST = "update_partner_request";
export const UPDATE_PARTNER_FAILURE = "update_partner_failure";
export const UPDATE_PARTNER_SUCCESS = "update_partner_success";

export const SEGMET_IMAGES_REQUEST = "segmet_images_request";
export const SEGMET_IMAGES_FAILURE = "segmet_images_failure";
export const SEGMET_IMAGES_SUCCESS = "segmet_images_success";

export const BRAND_PRODUCT_TEMPLATE_REQUEST = "brand_product_template_request";
export const BRAND_PRODUCT_TEMPLATE_FAILURE = "brand_product_template_failure";
export const BRAND_PRODUCT_TEMPLATE_SUCCESS = "brand_product_template_success";

export const GET_RETAILER_PRODUCT_TEMPLATE_SELECTED_REQUEST =
  "get_retailer_product_template_selected_request";
export const GET_RETAILER_PRODUCT_TEMPLATE_SELECTED_FAILURE =
  "get_retailer_product_template_selected_failure";
export const GET_RETAILER_PRODUCT_TEMPLATE_SELECTED_SUCCESS =
  "get_retailer_product_template_selected_success";

export const GET_PARTNER_DETAIL_REQUEST = "get_partner_detail_request";
export const GET_PARTNER_DETAIL_FAILURE = "get_partner_detail_failure";
export const GET_PARTNER_DETAIL_SUCCESS = "get_partner_detail_success";

export const UPDATE_PARTNER_DETAIL_SUCCESS = "update_partner_detail_success";

export const CLONE_PARTNER_SITE_REQUEST = "clone_partner_site_request";
export const CLONE_PARTNER_SITE_FAILURE = "clone_partner_site_failure";
export const CLONE_PARTNER_SITE_SUCCESS = "clone_partner_site_success";
