// export const ADMIN = 'TL';
// export const USER = 'USER';
// export const BRANDMASTER = 'BRANDMASTER';
import * as RouteName from "./routes";

export const RoleMaster = {
  ADMIN: "AD",
  RETAILER: "RE",
  BRAND: "BR",
  WHOLESALER: "WS",
  ASSOCIATEUSER: "HH"
};

export const RoleLandingRoute = {
  ADMIN: RouteName.ROUTEMIDDLEWARE,
  RETAILER: RouteName.ROUTEMIDDLEWARE,
  BRAND: RouteName.ROUTEMIDDLEWARE,
  WHOLESALER: RouteName.ROUTEMIDDLEWARE,
  ASSOCIATEUSER: RouteName.ROUTEMIDDLEWARE
};

export const PartnerRoles = [
  {
    name: "Brand",
    key: "BR"
  },
  {
    name: "Wholesaler",
    key: "WS"
  }
];

export const ReportPartnerRoles = [
  {
    name: "Retailer",
    key: "RE"
  },{
    name: "Brand",
    key: "BR"
  },
  {
    name: "Wholesaler",
    key: "WS"
  }
];