import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";
import FormLabel from "@material-ui/core/FormLabel";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from "../components/ImageUpload.jsx";
import AgGrid from "components/AgGrid/AgGrid.jsx";
import EconomicSectorAction from "components/AgGrid/EconomicSectorAction.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { validationUtils } from "../../utils";

// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import List from "@material-ui/icons/List";
import ReactTooltip from "react-tooltip";

import * as routesNames from "../../constants/routes";

import { IndustriesComponent } from "../components";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class BusinessType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false
      },
      loading: false,
      isAddNew: false,
      addBusinessTypeData: {
        name: "",
        description: "",
        is_active: false,
        is_Deleted: false,
        image: ""
      },
      required: {
        name: "",
        description: ""
      },
      BusinessTypeData: [],
      error: null,
      openDeleteDialog: false,
      BusinessTypeId: null,
      entityData: null,
      isIndustriesComponentOpen: false,
      BusinessTypeValue: null,
      selectedITIndexes: [],
      mappingData: {
        business_type: null,
        industries: [],
        is_active: true
      },
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 300,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Description",
          field: "description",
          width: 300,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Status",
          field: "is_active",
          width: 200,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          cellRenderer: function(params) {
            if (params.data && params.data.is_active == true) {
              return "Active";
            } else {
              return "InActive";
            }
          }
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ]
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length &&
        !_.isEqual(nextProps.List, this.props.List)
      ) {
        const BusinessTypeData = nextProps.List;
        this.setState(() => ({
          BusinessTypeData: BusinessTypeData,
          pagination: nextProps.pagination
        }));
      }
      let error = "";
      let loading = false;
      if (nextProps.ListError && nextProps.ListError.trim() !== "") {
        error = nextProps.ListError;
        // this.setState(() => ({
        //     error: nextProps.ListError,
        //     // isAddNew: true
        // }));
      }

      if (nextProps.Loading) {
        loading = nextProps.Loading;
      }

      if (
        nextProps.EconomicSectorData &&
        !_.isEqual(
          nextProps.EconomicSectorData,
          this.props.EconomicSectorData
        ) &&
        !nextProps.isImageDeleted
      ) {
        const data = nextProps.EconomicSectorData;
        if (data) {
          this.setState({
            addBusinessTypeData: {
              name: data.name,
              description: data.description,
              // index: index,
              id: data.id,
              is_active: data.is_active,
              image: data.image,
              is_Deleted: false
            },
            isAddNew: true,
            isEdit: true
          });
        }
      }

      this.setState({
        error: error,
        loading: loading
      });

      if (nextProps.isSaved) {
        this.setState(() => ({
          isAddNew: false,
          isEdit: false
        }));
      }
      if (nextProps.isDeleted) {
        this.setState(
          {
            openDeleteDialog: false
          },
          () => {
            // this.props.actions.getBusinessType(this.props.UserId);
          }
        );
      }
      if (nextProps.isMapSaved) {
        this.closeIndustriesComponentDialog();
        this.props.actions.afertMapBusinessIndustries();
      }

      if (nextProps.isImageDeleted) {
        const { addBusinessTypeData } = this.state;
        delete addBusinessTypeData.image;
        this.setState(
          {
            addBusinessTypeData: {
              ...this.state.addBusinessTypeData,
              image: null
            }
          },
          () => {
            this.props.actions.denyRemoveImageAction();
          }
        );
      }
    }
  }

  componentDidMount() {
    this.props.actions.getBusinessType(
      this.props.UserId,
      this.state.pagination.page
    );
  }

  render() {
    return this.state.isAddNew
      ? this.renderBusinessType()
      : this.renderBusinessTypeTable();
  }

  renderBusinessType = () => {
    const { classes } = this.props;
    const {
      name,
      description,
      is_active,
      image
    } = this.state.addBusinessTypeData;
    const { required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <CustomInput
                    success={required.description === "success"}
                    error={required.description === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: this.handleTextChange("description", "")
                    }}
                    id={"description_"}
                  />
                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus(is_active)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />

                  <br />
                  <legend>
                    {" "}
                    Upload Logo&nbsp;
                    <span style={{ color: "#AAAAAA", fontSize: "13px" }}>
                      (Optimal logo size to upload should be 540x240 at 72 dpi)
                    </span>
                  </legend>
                  <ImageUpload
                    addButtonProps={{
                      round: true
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true
                    }}
                    imgUrl={
                      image instanceof Object
                        ? image instanceof Blob
                          ? image
                          : image.url
                        : image
                    }
                    onimageRemove={this.handelImageRemove}
                    onChange={this.handelImageChange}
                  />
                  {this.state.error && this.state.error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button color="info" onClick={this.saveBusinessType}>
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {this.props.Loading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderBusinessTypeTable = () => {
    const { classes } = this.props;

    return (
      <GridContainer>
        {this.deleteDilog()}
        {this.industriesComponentDilog()}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Economic Sectors
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.BusinessTypeData}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                handelMap={this.openIndustriesComponentDialog}
                handelView={this.openMenuList}
                delete={this.deleteDialog}
                loading={this.props.Loading}
                AgGridActionButton={EconomicSectorAction}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                search={this.searchText}
                new={this.handelIsAddNew}
                isAddBtn={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getBusinessType(
      this.props.UserId,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getBusinessType(this.props.UserId, page, param);
      }
    );
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      addBusinessTypeData: {
        name: "",
        description: "",
        is_active: false,
        is_Deleted: false
      }
    });
  };
  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      addBusinessTypeData: {
        ...this.state.addBusinessTypeData,
        [key]: event.target.value
      }
    });
  };
  handleToggleStatus = curObj => event => {
    this.setState({
      addBusinessTypeData: {
        ...this.state.addBusinessTypeData,
        is_active: event.target.checked
      }
    });
  };
  // handelImageRemove = () => {
  //     let { addBusinessTypeData } = this.state;
  //     delete addBusinessTypeData.image;
  //     this.setState({
  //         addBusinessTypeData: addBusinessTypeData
  //     });
  // }
  handelImageRemove = () => {
    let { image } = this.state.addBusinessTypeData;

    if (image instanceof Blob) {
      this.setState({
        addBusinessTypeData: {
          ...this.state.addBusinessTypeData,
          image: null
        }
      });
    } else if (image instanceof Object) {
      this.props.actions.removeImageAction(image.id);
      // delete image;
    }
  };

  saveBusinessType = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addBusinessTypeData;
      this.props.actions.saveBusinessType(
        this.props.UserId,
        savingData,
        this.props.partnerId
      );
    }
  };

  handelEdit = id => {
    this.props.actions.getBusinessTypeByIdAction(id);
  };

  deleteDialog = (state, Id, Index) => {
    this.setState({
      openDeleteDialog: state,
      BusinessTypeId: Id
    });
  };

  deleteBusinessType = Id => {
    this.props.actions.deleteBusinessType(
      this.props.UserId,
      this.state.BusinessTypeId
    );
  };

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addBusinessTypeData.hasOwnProperty("name") ||
      (this.state.addBusinessTypeData.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addBusinessTypeData.hasOwnProperty("description") ||
      (this.state.addBusinessTypeData.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  openIndustriesComponentDialog = id => {
    var { BusinessTypeData } = this.state;
    let index = BusinessTypeData.findIndex(p => p.id === id);
    if (BusinessTypeData) {
      this.setState(() => ({
        isIndustriesComponentOpen: true,
        entityData: BusinessTypeData[index]
      }));
    }
  };

  openMenuList = index => {
    this.props.actions.onNavigateTo(
      routesNames.MENUMASTER + "?businesstypeid=" + index
    );
  };

  closeIndustriesComponentDialog = () => {
    this.setState(() => ({
      isIndustriesComponentOpen: false
    }));
  };

  saveHandler = () => {
    this.state.mappingData.business_type = this.state.entityData.id;
    this.state.mappingData.industries = this.state.selectedITIndexes;
    this.props.actions.mapBusinessIndustries(
      this.props.UserId,
      this.state.mappingData
    );
    console.log(this.state.mappingData);
  };

  deleteDilog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        disableBackdropClick={true}
        onClose={() => this.closeIndustriesComponentDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              Are you sure you want to delete?
              {this.state.error && this.state.error.trim() !== "" && (
                <FormLabel className={classes.labelLeftHorizontal}>
                  <code>{this.state.error}</code>
                </FormLabel>
              )}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.deleteBusinessType()}
              >
                Delete
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.deleteDialog(false, "", "")}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  };

  handelImageChange = file => {
    if (file instanceof Blob) {
      let reader = new FileReader();
      let { addBusinessTypeData } = this.state;
      let original_name = file.name;
      let fileSize = file.size;
      addBusinessTypeData["image"] = file;
      addBusinessTypeData["file_size"] = fileSize;
      addBusinessTypeData["original_file_name"] = original_name;
      this.setState({
        addBusinessTypeData: addBusinessTypeData
      });
      // reader.onloadend = () => {
      //     addBusinessTypeData['image'] = reader.result;
      //     addBusinessTypeData['file_size'] = fileSize;
      //     addBusinessTypeData['original_file_name'] = original_name;
      //     this.setState({
      //         addBusinessTypeData: addBusinessTypeData
      //     });
      // };
      // reader.readAsDataURL(file);
    } else {
      let { addBusinessTypeData } = this.state;
      addBusinessTypeData.image = file.url;
      if (file.is_asset) {
        addBusinessTypeData.asset_id = file.id;
      }
      addBusinessTypeData.file_size = file.file_size;
      addBusinessTypeData.original_file_name = file.original_file_name;
      this.setState({
        ...this.state,
        addBusinessTypeData: addBusinessTypeData
      });
    }
  };

  getTableData = () => {
    const { attributeGroups } = [];
    const { BusinessTypeData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(BusinessTypeData, ({ id, name, description, is_active }, index) => {
      returnData.push([
        <span>
          {
            //eslint-disable-next-line
                            <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
              {name}
            </a>
          }
        </span>,
        <span>{description}</span>,
        <span>{is_active ? "Active" : "Inactive"}</span>,
        <span>
          <ReactTooltip id="menilist">
            <span>Show Menu List</span>
          </ReactTooltip>

          <Button
            color="primary"
            data-tip
            data-for="menilist"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.openMenuList(id)}
          >
            <List className={classes.icons} style={{ marginRight: "0px" }} />
          </Button>

          <ReactTooltip id="mapInd">
            <span>View or edit mapping with Industries</span>
          </ReactTooltip>

          <Button
            color="primary"
            data-tip
            data-for="mapInd"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.openIndustriesComponentDialog(index)}
          >
            <List className={classes.icons} style={{ marginRight: "0px" }} />
          </Button>

          <ReactTooltip id="edit">
            <span>Edit Economic Sector</span>
          </ReactTooltip>
          <Button
            color="success"
            data-tip
            data-for="edit"
            className={classes.actionButton}
            onClick={() => this.handelEdit(index)}
          >
            <CreateIcon className={classes.icon} />
          </Button>

          <ReactTooltip id="delete">
            <span>Delete Economic Sector</span>
          </ReactTooltip>
          <Button
            color="danger"
            data-tip
            data-for="delete"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.deleteDialog(true, id)}
          >
            <ClearIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>
        </span>
      ]);
    });
    return returnData;
  };

  industriesComponentDilog = () => {
    const { classes } = this.props;
    var title = "";
    if (this.state.entityData != null) {
      title =
        "Assign industries to " +
        this.state.entityData.name +
        " economic sector";
    }
    return (
      <Dialog
        open={this.state.isIndustriesComponentOpen}
        disableBackdropClick={true}
        onClose={() => this.closeIndustriesComponentDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <b>{title}</b>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <LoadingOverlay>
            <DialogContentText>
              <IndustriesComponent
                open={this.state.isAttributeGroupOpen}
                onClose={this.closeIndustriesComponentDialog}
                entityData={this.state.entityData}
                selectedITIndexes={this.state.selectedITIndexes}
              />
            </DialogContentText>

            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.saveHandler()} color="danger" simple>
            Save
          </Button>
          <Button
            onClick={() => this.closeIndustriesComponentDialog()}
            color="danger"
            simple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.businessTypeReducer.list,
  pagination: state.businessTypeReducer.pagination,
  Loading: state.businessTypeReducer.loadingData,
  ListError: state.businessTypeReducer.error,
  isSaved: state.businessTypeReducer.isSaved,
  isDeleted: state.businessTypeReducer.isDeleted,
  UserId: state.authState.user.pk,
  isMapSaved: state.businessTypeReducer.isMapSaved,
  partnerId: state.authState.user.partner_id,
  isImageDeleted: state.appMenuReducer.removeImage.isDeleted,
  EconomicSectorData: state.businessTypeReducer.business_type_by_id.data,
  EconomicSectoLoading: state.businessTypeReducer.business_type_by_id.loading,
  EconomicSectoError: state.businessTypeReducer.business_type_by_id.error
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.ADMIN ||
  rolesNames.RoleMaster.WHOLESALER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BusinessType);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
