import React from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import _ from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { LoadingOverlay, Loader } from "react-overlay-loader";

let AggridApi;

class CMSPagesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          sortable: true,
          filter: true,
          width: 100,
          checkboxSelection: true
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          filter: true,
          suppressSizeToFit: true
        },
        {
          headerName: "Slug",
          field: "slug",
          sortable: true,
          filter: true
        },
        {
          headerName: "System Page",
          field: "is_system_page",
          sortable: true,
          filter: true
        }
        // {
        //     headerName: "Environment Type",
        //     field: "type",
        //     sortable: false,
        //     filter: true
        // },
        // {
        //     headerName: "Active",
        //     field: "is_active",
        //     sortable: true,
        //     filter: true,
        //     width: 100,
        //     suppressSizeToFit: true,
        // }
      ],
      rowData: [],
      rows: [],
      assignList: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (nextProps.List && nextProps.List.length) {
          const classData = nextProps.List;
          this.setState(() => ({
            rows: classData
          }));

          if (nextProps.AssignList && nextProps.AssignList.length > 0) {
            this.setState(
              {
                assignList: nextProps.AssignList
              },
              () => {
                AggridApi.forEachNode(function(node) {
                  if (nextProps.AssignList.indexOf(node.data.id) > -1) {
                    node.setSelected(true);
                  } else {
                    node.setSelected(false);
                  }
                });
              }
            );
          } else {
            if (nextProps.menu && AggridApi) {
              AggridApi.forEachNode(function(node) {
                node.setSelected(false);
              });
            }
          }

          if (AggridApi) AggridApi.sizeColumnsToFit();
        }

        if (nextProps.Error && nextProps.Error.trim() !== "") {
          this.setState(() => ({
            error: nextProps.Error,
            isAddNew: true
          }));
        }
      }
      if (nextProps.isSaved) {
        this.props.onClose();
      }
    }
  }

  componentDidMount() {
    // this.props.actions.getMenuMasterList(this.props.UserId);

    // this.props.actions.getMenuMasterList();

    this.props.actions.getCMSPages(null, this.props.partnerId, true);
    this.props.actions.getAssignPartnerPages(this.props.partnerId);
  }

  closeDialog = () => {
    this.props.onClose();
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    AggridApi = _.clone(this.gridApi, true);
  };

  handelIsAddNew() {
    this.props.onClose();
  }
  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    var length = this.state.rows.length;
    var i = 0;
    const componentThis = this;
    for (i; i < length; i++) {
      componentThis.state.rows.pop();
    }
    selectedRows.forEach(function(selectedRow, index) {
      componentThis.state.rows.push(selectedRow.id);
    });
  }
  saveHandler = () => {
    const { assignList } = this.state;
    let selectedRows = this.gridApi.getSelectedRows();
    let pageId = _.map(selectedRows, "id");
    const componentThis = this.props;

    let pages = _.difference(pageId, assignList);

    componentThis.actions.copyPagePartner(pageId, componentThis.partnerId);
  };

  render() {
    return (
      <div>
        <LoadingOverlay>
          <div
            className="ag-theme-material"
            style={{
              height: "450px",
              width: "100%"
            }}
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowSelection="multiple"
              onGridReady={this.onGridReady}
              onSelectionChanged={this.onSelectionChanged.bind(this)}
              pagination={true}
              paginationAutoPageSize={true}
              rowData={this.state.rows}
            />
            <Loader loading={this.props.copyloading} text="Saving.." />

            <Button color="info" onClick={() => this.saveHandler()}>
              Save
            </Button>
            <Button onClick={() => this.handelIsAddNew(false)}>Cancel</Button>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserId: state.authState.user.user,
  isMapSaved: state.menuMasterReducer.save_mapping_menu_master.mapping_saved,

  Loading: state.cmsReducer.get_CMS_Pages.loading,
  LoadingError: state.cmsReducer.get_CMS_Pages.error,
  List: state.cmsReducer.get_CMS_Pages.list,

  isSaved: state.cmsReducer.copy_cms_page.isSaved,
  copyloading: state.cmsReducer.copy_cms_page.loading,
  error: state.cmsReducer.copy_cms_page.error,
  SuccessMessage: state.cmsReducer.copy_cms_page.msg,

  AssignList: state.cmsReducer.assign_partner_page.list
});

CMSPagesComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  entityData: PropTypes.object,
  menu: PropTypes.array,
  selectedClassIndexes: PropTypes.array.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CMSPagesComponent);

// export default menuComponent;
