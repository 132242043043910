import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@material-ui/core/FormLabel";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";

import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Check from "@material-ui/icons/Check";
import { validationUtils } from "../../../utils";
import * as services from "../../../services/industry-metadata";
import NavPills from "components/NavPills/NavPills.jsx";
import AssetManagementComponent from "../../components/assetManagementComponent.jsx";
import MenuListGrid from "components/MenuList/menu-list-grid";
import IndustryPages from "components/IndustryPages/industry-pages-grid";
import CMSComponents from "components/CMSComponentCards/cms-component-card";
import MetadataHeader from "components/CMSHeader/cms-header";
import MetadataFooter from "components/CMSFooter/cms-footer";
import LanguageListGrid from "components/LangaugeList/language-list-grid";

class PartnerMetaDataView extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.getSelectedImage = this.getSelectedImage.bind(this);
    this.state = {
      selectedId: "",
      files: [],
      isAddNew: false,
      industryList: [],
      currentStep: 0,
      partner_type: "",
      partner_type_list: [
        {
          id: "RE",
          name: "Retailer"
        },
        {
          id: "BR",
          name: "Brand"
        },
        {
          id: "WS",
          name: "Wholesaler"
        }
      ],
      industry_metadata: {
        industry: null,
        name: "",
        description: "",
        is_active: false,
        partner_type: "",
        id: "",
        menus: [],
        pages: [],
        components: [],
        headers: [],
        footers: [],
        languages: []
      },
      headers: [],
      footers: [],
      pages: [],
      menu: [],
      components: [],
      languages: [],
      data: {},
      files: [],
      required: {
        name: "",
        description: "",
        industry: "",
        partner_type: ""
      },
      NotificationData: [],
      error: null,
      openDeleteDialog: false,
      NotificationId: null,
      NotificationValue: null,
      selectedUAIndexes: [],
      templateList: [],
      saving: false,
      formUpdate: 0
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && !_.isEqual(this.props, nextProps)) {
    }
  }

  setEditData(cmpnn) {
    this.setState(
      () => ({
        ...this.state,
        industry_metadata: {
          industry: cmpnn.industry,
          id: cmpnn.id,
          name: cmpnn.name,
          description: cmpnn.description,
          is_active: cmpnn.is_active,
          menus: cmpnn.menu,
          pages: cmpnn.pages,
          components: cmpnn.components,
          partner_type: cmpnn.partner_type,
          headers: cmpnn.headers,
          footers: cmpnn.footers,
          languages: cmpnn.languages
        },
        headers: cmpnn.headers,
        footers: cmpnn.footers,
        partner_type: cmpnn.partner_type,
        menu: cmpnn.menu,
        pages: cmpnn.pages,
        components: cmpnn.components,
        languages: cmpnn.languages
      }),
      function() {}
    );
  }

  componentDidMount() {
    this.props.actions.getIndustriesTypeList(this.props.UserId, "list");

    if (this.props.metadata_id) {
      this.setState({
        loading: true
      });

      services
        .getMetaData(this.props.metadata_id)
        .then(data => {
          console.log("Meta Data load", data);
          console.log("Meta Data load data", data);
          if (data && data.status === "1") {
            this.setEditData(data.data);
          } else {
            this.setState({
              error: "An error has occured while loading Packages"
            });
          }
        })
        .catch(reason => {
          this.setState({
            error: "An error has occured while loading Packages"
          });
          console.error("Meta Data load api call error", reason);
        })
        .finally(info => {
          this.setState({
            loading: false
          });
          console.log(info);
        });
    }
  }

  handelIsAddNew = () => {
    this.setState({
      ...this.state,
      industryList: [],
      industry_metadata: {
        industry: [],
        id: "",
        name: "",
        description: "",
        is_active: true,
        menus: [],
        pages: [],
        components: [],
        headers: [],
        footers: [],
        languages: []
      },
      menus: [],
      pages: [],
      components: [],
      footers: [],
      headers: [],
      languages: [],
      required: {
        industry: "",
        name: "",
        description: ""
      },
      isEdit: false,
      currentStep: 0
    });
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;

    this.setState({
      ...this.state,
      required: reqData
    });

    this.setState({
      ...this.state,
      industry_metadata: {
        ...this.state.industry_metadata,
        [key]: event.target.value
      }
    });
  };

  handleToggleStatus = key => event => {
    this.setState({
      ...this.state,
      industry_metadata: {
        ...this.state.industry_metadata,
        [key]: event.target.checked
      }
    });
  };

  savePageDetails = (step, savingData) => {
    this.setState(
      {
        saving: true
      },
      () => {
        services
          .saveMetaData(
            this.props.Userid,
            step,
            savingData,
            this.props.user.partner_id,
            this.state.files
          )
          .then(data => {
            console.log("Meta Data Save step", step);
            console.log("Meta Data Save", savingData);
            if (data && data.status === "1") {
              if (step === 6) {
                this.CancelAddEdit();
                return false;
              }

              this.setEditData(data.data);

              this.setState({
                currentStep: step + 1
              });
            } else {
              this.setState({
                error: "An error has occured while saving Package"
              });
            }
          })
          .catch(reason => {
            this.setState({
              error: "An error has occured while saving Package"
            });
            console.error("Meta Data List api call error", reason);
          })
          .finally(info => {
            this.setState({
              saving: false
            });
            console.log(info);
          });
      }
    );
  };

  save = () => {
    if (this.validateIsRequired()) {
      this.savePageDetails(
        this.state.currentStep,
        this.state.industry_metadata
      );
    } else {
      this.setActiveTab(0);
    }
  };

  saveForms = () => {
    if (this.validateIsRequired()) {
    } else {
      this.setActiveTab(0);
    }
  };

  selectFilesFromComponent(values) {
    if (values instanceof Array) {
      values.forEach(x => {
        this.selectFilesFromComponent(x);
      });
    } else if (values instanceof Object) {
      let keys = Object.keys(values);
      keys.forEach(x => {
        if (~x.indexOf("_image")) {
          this.selectFilesFromComponent(values[x]);
        } else {
          if (x == "url" && typeof values[x] == "string") {
            let file = this.b64toBlob(values[x]);
            if (file && file instanceof Blob) {
              file.name = values["name"];
              let { files } = this.state;
              files.push(file);
              this.setState({
                files: files
              });
            }
            values[x] = file;
          }
          this.selectFilesFromComponent(values[x]);
        }
      });
    }
    return values;
  }

  b64toBlob(dataURI) {
    try {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/jpeg" });
    } catch (err) {
      return dataURI;
    }
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;

    if (
      !this.state.industry_metadata.hasOwnProperty("name") ||
      (this.state.industry_metadata.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.industry_metadata.hasOwnProperty("description") ||
      (this.state.industry_metadata.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }

    if (
      !this.state.industry_metadata.hasOwnProperty("industry") ||
      (!this.state.industry_metadata.industry ||
        this.state.industry_metadata.industry.length === 0 ||
        reqData["industry"] === false)
    ) {
      reqData["industry"] = false;
      isValid = false;
    }

    if (
      !this.state.industry_metadata.hasOwnProperty("partner_type") ||
      !this.state.industry_metadata.partner_type ||
      reqData["partner_type"] === false
    ) {
      reqData["partner_type"] = false;
      isValid = false;
    }

    this.setState({
      ...this.state,
      required: reqData
    });

    return isValid;
  }

  handleSelectChange = key => event => {
    var validData = true;
    if (!event.target.value) {
      validData = false;
    }

    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({
      ...this.state,
      required: reqData
    });

    this.setState({
      ...this.state,
      formUpdate: this.state.formUpdate,
      industry_metadata: {
        ...this.state.industry_metadata,
        [key]: event.target.value
      }
    });
  };

  setActiveTab = active => {
    this.setState({
      currentStep: active
    });
  };

  setSelectedItems(ids) {
    this.setState({
      industry_metadata: {
        ...this.state.industry_metadata,
        menus: ids
      }
    });
  }

  setSelectedPages(ids) {
    this.setState({
      industry_metadata: {
        ...this.state.industry_metadata,
        pages: ids
      }
    });
  }

  setSelectedComponents(ids) {
    this.setState({
      industry_metadata: {
        ...this.state.industry_metadata,
        components: ids
      },
      components: ids
    });
  }

  setSelectedHeaders(headers) {
    this.setState({
      industry_metadata: {
        ...this.state.industry_metadata,
        headers: headers
      },
      headers: headers
    });
  }

  setSelectedFooters(links) {
    console.log("footers", links);

    this.setState({
      industry_metadata: {
        ...this.state.industry_metadata,
        footers: links
      },
      footers: links
    });
  }

  setSelectedLanguages(links) {
    console.log("Languages", links);

    this.setState({
      industry_metadata: {
        ...this.state.industry_metadata,
        languages: links
      }
      // languages: links
    });
  }

  MetaDataLanguages = () => {
    const { classes } = this.props;
    return (
      <Card style={{ minHeight: 250 }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <LanguageListGrid
            setSelectedItems={links => this.setSelectedLanguages(links)}
            selected_items={this.state.languages}
          />

          <br />

          <Button color="info" onClick={this.save.bind(this)}>
            Save and Close
          </Button>

          <Button onClick={this.CancelAddEdit}>Cancel</Button>

          <br />

          {this.state.error && (
            <FormLabel className={classes.labelLeftHorizontal}>
              <code>{this.state.error}</code>
            </FormLabel>
          )}

          {this.state.saving && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-25px",
                marginLeft: "-12px"
              }}
            />
          )}
        </GridItem>
      </Card>
    );
  };

  MetadataFooter = () => {
    const { classes } = this.props;
    return (
      <Card style={{ minHeight: 250 }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <MetadataFooter
            setSelectedItems={links => this.setSelectedFooters(links)}
            footers={this.state.footers}
          />

          <br />
          <Button color="info" onClick={this.save.bind(this)}>
            Save and Next
          </Button>
          <Button onClick={this.CancelAddEdit}>Cancel</Button>

          <br />
          {this.state.error && (
            <FormLabel className={classes.labelLeftHorizontal}>
              <code>{this.state.error}</code>
            </FormLabel>
          )}

          {this.state.saving && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-25px",
                marginLeft: "-12px"
              }}
            />
          )}
        </GridItem>
      </Card>
    );
  };

  MetadataHeader = () => {
    const { classes } = this.props;
    return (
      <Card style={{ minHeight: 250 }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <MetadataHeader
            setSelectedItems={headers => this.setSelectedHeaders(headers)}
            headers={this.state.headers}
          />

          <br />
          <Button color="info" onClick={this.save.bind(this)}>
            Save and Next
          </Button>
          <Button onClick={this.CancelAddEdit}>Cancel</Button>

          <br />
          {this.state.error && (
            <FormLabel className={classes.labelLeftHorizontal}>
              <code>{this.state.error}</code>
            </FormLabel>
          )}

          {this.state.saving && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-25px",
                marginLeft: "-12px"
              }}
            />
          )}
        </GridItem>
      </Card>
    );
  };

  getMenuListTab = () => {
    const { classes } = this.props;
    return (
      <Card style={{ minHeight: 250 }}>
        <LoadingOverlay>
          <GridContainer style={{ margin: "0 0", width: "100%" }}>
            <GridItem xs={12} sm={12} md={12}>
              <MenuListGrid
                type={this.state.partner_type}
                setSelectedItems={ids => this.setSelectedItems(ids)}
                selected_items={this.state.menu}
              />
            </GridItem>
          </GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            {this.state.error && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code className="loading-error">{this.state.error}</code>
              </FormLabel>
            )}

            <br />
            <Button color="info" onClick={this.save.bind(this)}>
              Save and Next
            </Button>
            <Button onClick={this.CancelAddEdit}>Cancel</Button>
          </GridItem>

          {(this.state.saving || this.state.loading) && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-25px",
                marginLeft: "-12px"
              }}
            />
          )}
          <Loader loading={this.props.saving} text="Saving.." />
          <Loader loading={this.props.loading} text="Loading.." />
        </LoadingOverlay>
      </Card>
    );
  };

  getComponentsTab = () => {
    const { classes } = this.props;
    return (
      <Card style={{ minHeight: 250 }}>
        <LoadingOverlay>
          <GridContainer style={{ margin: "0 0", width: "100%" }}>
            <GridItem xs={12} sm={12} md={12}>
              <CMSComponents
                setSelectedItems={ids => this.setSelectedComponents(ids)}
                selected_items={this.state.components}
              />
            </GridItem>
          </GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            {this.state.error && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code className="loading-error">{this.state.error}</code>
              </FormLabel>
            )}

            <br />
            <Button color="info" onClick={this.save.bind(this)}>
              Save and Next
            </Button>
            <Button onClick={this.CancelAddEdit}>Cancel</Button>
          </GridItem>

          {(this.state.saving || this.state.loading) && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-25px",
                marginLeft: "-12px"
              }}
            />
          )}
          <Loader loading={this.props.saving} text="Saving.." />
          <Loader loading={this.props.loading} text="Loading.." />
        </LoadingOverlay>
      </Card>
    );
  };

  getPagesTab = () => {
    const { classes } = this.props;
    return (
      <Card style={{ minHeight: 250 }}>
        <LoadingOverlay>
          <GridContainer style={{ margin: "0 0", width: "100%" }}>
            <GridItem xs={12} sm={12} md={12}>
              <IndustryPages
                setSelectedItems={ids => this.setSelectedPages(ids)}
                selected_items={this.state.pages}
              />
            </GridItem>
          </GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            {this.state.error && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code className="loading-error">{this.state.error}</code>
              </FormLabel>
            )}

            <br />
            <Button color="info" onClick={this.save.bind(this)}>
              Save and Next
            </Button>
            <Button onClick={this.CancelAddEdit}>Cancel</Button>
          </GridItem>

          {(this.state.saving || this.state.loading) && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-25px",
                marginLeft: "-12px"
              }}
            />
          )}
          <Loader loading={this.props.saving} text="Saving.." />
          <Loader loading={this.props.loading} text="Loading.." />
        </LoadingOverlay>
      </Card>
    );
  };

  renderForm = () => {
    const { classes } = this.props;
    const {
      name,
      description,
      is_active,
      id,
      industry,
      partner_type
    } = this.state.industry_metadata;
    const { required } = this.state;
    return (
      <GridContainer style={{ width: "100%" }}>
        {this.state.openAssetModal && (
          <AssetManagementComponent
            open={this.state.openAssetModal}
            close={this.closeModal}
            onSelection={this.getSelectedImage}
          />
        )}
        <Card style={{ minHeight: 250 }}>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange(
                        "name",
                        validationUtils.validationTypes.name
                      )
                    }}
                    id={"name_"}
                  />

                  <CustomInput
                    success={required.description === "success"}
                    error={required.description === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: this.handleTextChange("description", "")
                    }}
                  />
                  <br />
                  <br />
                  <FormControl
                    fullWidth
                    style={customInputStyle.formControl}
                    name="partner_type"
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="Applicable Partner Type *"
                    >
                      Applicable Partner Type *
                    </InputLabel>
                    <Select
                      success={required.partner_type === true}
                      error={required.partner_type === false}
                      style={{ marginTop: "0px" }}
                      value={partner_type}
                      onChange={this.handleSelectChange("partner_type")}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Applicable partner type
                      </MenuItem>
                      {_.map(
                        this.state.partner_type_list,
                        ({ id, name }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                              key={index + "_" + id}
                            >
                              {name}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <FormControl
                    fullWidth
                    style={customInputStyle.formControl}
                    name="industry"
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="Industry *"
                    >
                      Industry *
                    </InputLabel>
                    <Select
                      success={required.industry === true}
                      error={required.industry === false}
                      style={{ marginTop: "0px" }}
                      value={industry ? industry : []}
                      multiple
                      onChange={this.handleSelectChange("industry")}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Industry
                      </MenuItem>
                      {_.map(
                        this.props.industriesList,
                        ({ id, name }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                              key={index + "_" + id}
                            >
                              {name}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>

                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus("is_active")}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />

                  <br />

                  {this.state.error && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  {(this.state.saving || this.state.loading) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}

                  <Button color="info" onClick={this.save.bind(this)}>
                    Save and Next
                  </Button>
                  <Button onClick={this.CancelAddEdit}>Cancel</Button>

                  <Loader loading={this.props.saving} text="Saving.." />
                  <Loader loading={this.props.loading} text="Loading.." />
                </LoadingOverlay>
              </form>
            </GridItem>
          </CardBody>
        </Card>
      </GridContainer>
    );
  };

  getModelTabs = () => {
    let tabs = [];

    tabs.push({
      tabButton: "1. Basic Details",
      tabContent: this.renderForm()
    });

    tabs.push({
      tabButton: "2. Menus",
      tabContent: this.getMenuListTab()
    });

    tabs.push({
      tabButton: "3. Pages",
      tabContent: this.getPagesTab()
    });

    tabs.push({
      tabButton: "4. Components",
      tabContent: this.getComponentsTab()
    });

    tabs.push({
      tabButton: "5. Header",
      tabContent: this.MetadataHeader()
    });

    tabs.push({
      tabButton: "6. Footer",
      tabContent: this.MetadataFooter()
    });

    tabs.push({
      tabButton: "7. Languages",
      tabContent: this.MetaDataLanguages()
    });

    return tabs;
  };

  renderTabs = () => {
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <NavPills
          setActive={this.setActiveTab}
          active={this.state.currentStep}
          tabs={this.getModelTabs()}
        />
      </GridItem>
    );
  };

  render() {
    return this.renderTabs();
  }

  CancelAddEdit = () => {
    this.props.addNew(false);
  };

  getSelectedImage(data) {
    let { value } = this.state.add_CMS_data;
    let asset_object = {};
    asset_object.storage = "asset";
    asset_object.type = "image/jpg";
    asset_object.size = data.file_size;
    asset_object.name = data.name;
    asset_object.url = data.url;
    asset_object.asset_id = data.id;
    asset_object.originalName = data.original_file_name;
    value["upload_image"] = asset_object;
    let asset_image_data = {};
    // asset_image_data.assets = true
    asset_image_data.name = data.name;
    asset_image_data.originalName = data.original_file_name;
    asset_image_data.size = data.file_size;
    asset_image_data.url = data.url;
    asset_image_data.asset_id = data.id;
    asset_image_data.storage = "asset";
    asset_image_data.type = "image/jpg";
    // value.cards.push(asset_object)
    this.setState(
      {
        ...this.state,
        add_CMS_data: {
          ...this.state.add_CMS_data,
          value: value
        },
        assetImageUpload: true,
        asset_image_data: asset_image_data
      },
      () => {
        this.setFormValue(this.state.add_CMS_data);
        this.closeModal();
      }
    );
  }

  onCustomEvent = e => {
    this.setState({
      openAssetModal: true
    });
  };

  closeModal() {
    this.setState({
      openAssetModal: false
    });
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  menuList: state.partnerMenuReducer.get_partner_menu_list,
  menuData: state.CMSContentReducer.get_CMS_Content.list,
  basicSaving: state.cmsReducer.create_CMS_Pages.saving,
  basicError: state.cmsReducer.create_CMS_Pages.error,
  basicData: state.cmsReducer.create_CMS_Pages.page,

  loading: state.cmsReducer.page_detail.loading,
  loadingerror: state.cmsReducer.page_detail.error,
  pagedetail: state.cmsReducer.page_detail.page,

  industriesList: state.industriesReducer.list,
  componentList: state.cmsReducer.get_CMS_Components.list,
  Userid: state.authState.user.pk,
  user: state.authState.user,
  UserId: state.authState.user.user
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PartnerMetaDataView);
