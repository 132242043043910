import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  get: {
    list: [],
    loading: false,
    loaderror: null
  },
  add: {
    adding: false,
    adderror: null
  }
};

const shippingTypeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SHIPPING_REQUEST:
      return {
        ...state,
        get: {
          list: [],
          loading: true,
          loaderror: null
        }
      };
    case actionTypes.GET_SHIPPING_FAILURE:
      return {
        ...state,
        get: {
          loading: false,
          loaderror: action.payload
        }
      };
    case actionTypes.GET_SHIPPING_SUCCESS:
      return {
        ...state,
        get: {
          list: action.payload,
          loading: false,
          loaderror: null
        }
      };
    case actionTypes.CREATE_SHIPPING_REQUEST:
      return {
        ...state,
        add: {
          adding: true,
          adderror: null
        }
      };
    case actionTypes.CREATE_SHIPPING_FAILURE:
      return {
        ...state,
        add: {
          adding: true,
          adderror: action.payload
        }
      };
    case actionTypes.CREATE_SHIPPING_SUCCESS:
      let list = [];
      var data = state.get.list;
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data[index] = action.payload;
      } else {
        list.push(action.payload);
        for (let i = 0; i < data.length; i++) {
          list.push(data[i]);
        }
      }
      return {
        ...state,
        add: {
          adding: false,
          adderror: null
        },
        get: {
          list: list,
          loading: false,
          loaderror: null
        }
      };

    default:
      return state;
  }
};

export default shippingTypeReducer;
