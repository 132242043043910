import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { validationUtils } from "../../utils";

// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import ReactTooltip from "react-tooltip";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

import extendedFormStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import { IndustriesComponent } from "../components";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      addTagsTypeData: {
        name: "",
        description: "",
        is_active: false,
        is_Deleted: false,
        tagTypeId: ""
      },
      required: {
        name: "",
        description: "",
        tagTypeId: ""
      },
      TagsData: null,
      error: null,
      openDeleteDialog: false,
      BusinessTypeId: null,
      entityData: null,
      isIndustriesComponentOpen: false,
      BusinessTypeValue: null,
      selectedITIndexes: [],

      tagTypeList: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.List && nextProps.List.length) {
        const TagsData = nextProps.List;
        this.setState(() => ({
          TagsData: TagsData
        }));
      }

      if (nextProps.ListError && nextProps.ListError.trim() !== "") {
        this.setState(() => ({
          error: nextProps.ListError,
          isAddNew: true
        }));
      }
      if (nextProps.isSaved) {
        this.props.actions.Aftergetdata();
        this.setState(() => ({
          isAddNew: false,
          isEdit: true
        }));
      }
      if (nextProps.isDeleted) {
        this.setState(() => ({
          openDeleteDialog: false
        }));
      }
      if (nextProps.isMapSaved) {
        this.closeIndustriesComponentDialog();
        this.props.actions.afertMapBusinessIndustries();
      }
      if (nextProps.TagTypeList) {
        this.setState({
          tagTypeList: nextProps.TagTypeList
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.List && this.props.List.length) {
      this.setState({
        TagsData: this.props.List
      });
    } else {
      this.props.actions.getTagsList(this.props.UserId);
    }
    if (this.props.tagTypeList && this.props.tagTypeList.length) {
      this.setState({
        tagTypeList: this.props.tagTypeList
      });
    } else {
      this.props.actions.getTagTypeList(this.props.UserId);
    }
  }

  handelIsAddNew = () => {
    // this.props.actions.getTagTypeList(this.props.UserId);
    this.setState({
      isAddNew: !this.state.isAddNew,
      addTagsTypeData: {
        name: "",
        description: "",
        is_active: false,
        is_Deleted: false
      },
      required: {
        name: "",
        description: "",
        tagTypeId: ""
      },
      isEdit: false
    });
  };
  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      addTagsTypeData: {
        ...this.state.addTagsTypeData,
        [key]: event.target.value
      }
    });
  };
  handleToggleStatus = curObj => event => {
    this.setState({
      addTagsTypeData: {
        ...this.state.addTagsTypeData,
        is_active: event.target.checked
      }
    });
  };
  handelImageRemove = () => {
    let { addTagsTypeData } = this.state;
    delete addTagsTypeData.imagePreviewUrl;
    this.setState({
      addTagsTypeData: addTagsTypeData
    });
  };

  saveBusinessType = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addTagsTypeData;
      this.props.actions.createTags(this.props.UserId, savingData);
    }
  };

  handelEdit = index => {
    const { isEdit, TagsData } = this.state;
    // this.props.actions.getTagTypeList(this.props.UserId);
    const data = TagsData;
    if (data[index]) {
      this.setState({
        addTagsTypeData: {
          name: data[index].name,
          description: data[index].description,
          index: index,
          id: data[index].id,
          is_active: data[index].is_active,
          tagTypeId: data[index].tags_type_id,
          is_Deleted: false
        },
        isAddNew: true,
        isEdit: true
      });
    }
  };

  deleteDialog = (state, Id, Index) => {
    this.setState({
      openDeleteDialog: state,
      BusinessTypeId: Id
    });
  };

  deleteBusinessType = Id => {
    this.props.actions.deleteBusinessType(
      this.props.UserId,
      this.state.BusinessTypeId
    );
  };

  render() {
    return this.state.isAddNew
      ? this.renderTagsType()
      : this.renderTagsTypeTable();
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addTagsTypeData.hasOwnProperty("name") ||
      (this.state.addTagsTypeData.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addTagsTypeData.hasOwnProperty("description") ||
      (this.state.addTagsTypeData.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }
    if (
      !this.state.addTagsTypeData.hasOwnProperty("tagTypeId") ||
      reqData["tagTypeId"] === "error" ||
      this.state.addTagsTypeData.tagTypeId === null
    ) {
      reqData["tagTypeId"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  openIndustriesComponentDialog = index => {
    var { TagsData } = this.state;
    if (TagsData) {
      this.setState(() => ({
        isIndustriesComponentOpen: true,
        entityData: TagsData[index]
      }));
    }
  };

  closeIndustriesComponentDialog = () => {
    this.setState(() => ({
      isIndustriesComponentOpen: false
    }));
  };

  saveHandler = () => {
    // this.state.mappingData.business_type = this.state.entityData.id;
    // this.state.mappingData.industries = this.state.selectedITIndexes;
    // this.props.actions.mapBusinessIndustries(this.props.UserId, this.state.mappingData);
    // console.log(this.state.mappingData);
  };

  deleteDilog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        disableBackdropClick={true}
        onClose={() => this.closeIndustriesComponentDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              Are you sure you want to delete?{" "}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.deleteBusinessType()}
              >
                Delete
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.deleteDialog(false, "", "")}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  };
  renderTagsType = () => {
    const { classes } = this.props;
    const { name, description, is_active, image } = this.state.addTagsTypeData;
    const { required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="Tags *"
                    >
                      Tags
                    </InputLabel>
                    <Select
                      success={required.tagTypeId === "success"}
                      error={required.tagTypeId === "error"}
                      style={{ marginTop: "0px" }}
                      value={this.state.addTagsTypeData.tagTypeId}
                      onChange={this.handleTextChange("tagTypeId", "")}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Tags
                      </MenuItem>
                      {_.map(this.state.tagTypeList, ({ id, name }, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={id}
                            key={index + "_" + id}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <CustomInput
                    success={required.description === "success"}
                    error={required.description === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: this.handleTextChange("description", "")
                    }}
                    id={"description_"}
                  />
                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus(is_active)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />

                  <br />
                  {this.props.ListError && this.props.ListError.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button
                    color="info"
                    onClick={this.saveBusinessType.bind(this)}
                  >
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {this.props.Saving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Saving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderTagsTypeTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.deleteDilog()}
        {this.industriesComponentDilog()}
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.Loading}
              color="success"
              onClick={this.handelIsAddNew}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} /> New
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>Tags.</p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <Table
                  tableHead={["Tag Type", "Name", "Description", "Status"]}
                  tableData={this.getTableData()}
                  tableShopping
                  customHeadCellClasses={[
                    classes.left,
                    classes.description,
                    classes.description,
                    classes.right,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                  customCellClasses={[
                    classes.tdName,
                    classes.customFont,
                    classes.customFont,
                    classes.tdNumber,
                    classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                    classes.tdNumber
                  ]}
                  customClassesForCells={[1, 2, 3, 4, 5, 6]}
                />
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  getTableData = () => {
    const { attributeGroups } = [];
    const { TagsData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(TagsData, ({ id, name, description, is_active, tagsType }, index) => {
      returnData.push([
        <span>
          <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
            {tagsType && tagsType[0] && tagsType[0].name}
          </a>
        </span>,
        <span>{name}</span>,
        <span>{description}</span>,
        <span>{is_active ? "Active" : "Inactive"}</span>,
        <span>
          <ReactTooltip id="edit">
            <span>Edit Tags</span>
          </ReactTooltip>
          <Button
            color="success"
            data-tip
            data-for="edit"
            className={classes.actionButton}
            onClick={() => this.handelEdit(index)}
          >
            <CreateIcon className={classes.icon} />
          </Button>

          <ReactTooltip id="delete">
            <span>Delete Tags</span>
          </ReactTooltip>
          <Button
            color="danger"
            data-tip
            data-for="delete"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.deleteDialog(true, id)}
          >
            <ClearIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>
        </span>
      ]);
    });
    return returnData;
  };

  industriesComponentDilog = () => {
    const { classes } = this.props;
    var title = "";
    if (this.state.entityData != null) {
      title =
        this.state.entityData.name +
        "(" +
        this.state.entityData.description +
        ")";
    }
    return (
      <Dialog
        open={this.state.isIndustriesComponentOpen}
        disableBackdropClick={true}
        onClose={() => this.closeIndustriesComponentDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <b>{title}</b>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <LoadingOverlay>
            <DialogContentText>
              <IndustriesComponent
                open={this.state.isAttributeGroupOpen}
                onClose={this.closeIndustriesComponentDialog}
                entityData={this.state.entityData}
                selectedITIndexes={this.state.selectedITIndexes}
              />
            </DialogContentText>

            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.saveHandler()} color="danger" simple>
            Save
          </Button>
          <Button
            onClick={() => this.closeIndustriesComponentDialog()}
            color="danger"
            simple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.tagsReducer.list,
  Loading: state.tagsReducer.loadingData,
  ListError: state.tagsReducer.error,
  isSaved: state.tagsReducer.isSaved,
  isDeleted: state.tagsReducer.isDeleted,
  UserId: state.authState.user.user,
  isMapSaved: state.tagsReducer.isMapSaved,
  TagTypeList: state.tagsTypeReducer.list
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.ADMIN ||
  rolesNames.RoleMaster.WHOLESALER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...extendedFormStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Tags);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
