import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "../../components/CustomButtons/Button";
import CreateIcon from "@material-ui/icons/Create";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import ReactTooltip from "react-tooltip";
import List from "@material-ui/icons/List";

class OfferActionComponent extends Component {
  constructor(props) {
    super(props);
    this.mapOfferWithProduct = this.mapOfferWithProduct.bind(this);
    this.editOfferDetails = this.editOfferDetails.bind(this);
  }

  invokeParentMethod() {
    const { classes } = this.props;
    if (this.props.data && this.props.data.id) {
      return (
        <span>
          <ReactTooltip id="editClass">
            <span>Edit Offer</span>
          </ReactTooltip>
          <Button
            color="primary"
            justIcon
            size="sm"
            data-tip
            data-for="editClass"
            onClick={this.editOfferDetails}
          >
            <CreateIcon className={classes.icons} />
          </Button>
          <ReactTooltip id="mapOffer">
            <span>Map offer with products</span>
          </ReactTooltip>
          <Button
            color="primary"
            justIcon
            size="sm"
            data-tip
            data-for="mapOffer"
            onClick={this.mapOfferWithProduct}
          >
            <List className={classes.icons} />
          </Button>
        </span>
      );
    }
  }

  editOfferDetails() {
    console.log("editing");
    this.props.context.componentParent.handelEdit(this.props.data.id);
  }

  mapOfferWithProduct() {
    this.props.context.componentParent.MapOfferToProductUnit(
      this.props.data.id
    );
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default withStyles(basicsStyle)(OfferActionComponent);
