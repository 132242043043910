import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import PropTypes from "prop-types";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Grid from "@material-ui/core/Grid";
// core components

import Button from "components/CustomButtons/Button.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components/Dialog

import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import ConfirmBox from "./../../../../components/ConfirmBox/ConfirmBox.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

class CloneSiteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      required_colne_site: {
        partnerId: ""
      },
      colne_site: {
        partnerId: null
      },
      isConfirmBox: false,
      List: [],
      config: [
        {
          key: "brand",
          title: "Brand"
        },
        {
          key: "products",
          title: "Products"
        },
        {
          key: "services",
          title: "Services"
        },
        {
          key: "usergroup",
          title: "User Group"
        },
        {
          key: "settings",
          title: "Settings"
        },
        {
          key: "cms",
          title: "CMS"
        },
        {
          key: "cms_data",
          title: "CMS with Data"
        },
        {
          key: "category",
          title: "Category"
        },
      ],
      config_key: {
        brand: false,
        wholesaler: false,
        products: false,
        services: false,
        usergroup: false,
        settings: false,
        cms: true,
        cms_data: false,
        category: false,
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.cloneSiteisSaved) {
        this.props.onClose();
      }
      if (nextProps.List && nextProps.List.length > 0) {
        let listData = _.clone(nextProps.List);
        let filterList = listData.filter(a => a.role == nextProps.role);
        // let Index = listData.findIndex(p => p.id == nextProps.partnerId);
        let PartnerFilter = filterList.filter(a =>
          nextProps.partnerId.includes(!a.id)
        );
        // if (Index > -1) {
        //     listData.splice(Index, 1);
        //     PartnerFilter = listData.filter(a => a.role == listData[Index].role);
        // }
        this.setState({
          List: filterList
        });
      }
    }
  }

  componentDidMount() {
    // this.props.actions.getPartnersList()
  }
  handleTextChange_copy(pratnerData) {
    if (pratnerData && pratnerData.id) {
      this.setState({
        colne_site: {
          partnerId: pratnerData.id
        }
      });
    }
  }

  saveCloneSite = () => {
    const { partnerId } = this.state.colne_site;
    const { config_key } = this.state;
    this.setState(
      {
        isConfirmBox: false
      },
      () => {
        this.props.actions.clonePartnerSite(
          partnerId,
          this.props.partnerId,
          config_key
        );
      }
    );
  };
  onHandleconfirmBoxCancle = () => {
    this.setState({
      isConfirmBox: false
    });
  };

  showConfirmBox = () => {
    this.setState({
      isConfirmBox: true
    });
  };
  handleToggleStatus = curObj => event => {
    const { config_key } = this.state;
    let temconfig = _.clone(config_key);
    temconfig[event.target.name] = event.target.checked;
    this.setState({
      config_key: temconfig
    });
  };
  confirmBoxDilog() {
    return (
      <ConfirmBox
        onHandleConfrim={this.saveCloneSite}
        onHandleCancle={this.onHandleconfirmBoxCancle}
        open={this.state.isConfirmBox}
        title="Your previously saved data will be removed and the custom pages without their data will be cloned, are you sure you want to proceed ?"
      />
    );
  }
  cloneSiteDilog() {
    const { classes } = this.props;
    const { config, config_key } = this.state;

    return (
      <Grid>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <LoadingOverlay>
              {this.confirmBoxDilog()}{" "}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    id="highlights-demo"
                    style={{
                      width: "100%"
                    }}
                    options={this.state.List}
                    error={true}
                    getOptionLabel={option => option.name}
                    onChange={(event, value) =>
                      this.handleTextChange_copy(value)
                    }
                    renderInput={params => (
                      <span>
                        <InputLabel className={classes.label + " "}>
                          Partners
                        </InputLabel>
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          classes={{
                            root: "attribute-txtbox"
                          }}
                        />
                      </span>
                    )}
                    renderOption={(option, { inputValue }) => {
                      const matches = match(option.name, inputValue);
                      const parts = parse(option.name, matches);

                      return (
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{ fontWeight: part.highlight ? 700 : 400 }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      );
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <br />
                  Select Options to clone
                  <br />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {config.map((prop, index) => (
                  <GridItem xs={6} sm={6} md={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checked={config_key[prop.key]}
                          onClick={this.handleToggleStatus(
                            config_key[prop.key]
                          )}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          name={prop.key}
                        />
                      }
                      classes={{ label: classes.label }}
                      label={prop.title}
                    />
                  </GridItem>
                ))}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {this.props.cloneSiteLoading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader
                    loading={this.props.cloneSiteLoading}
                    text="saving.."
                  />

                  {this.props.cloneSiteError &&
                    this.props.cloneSiteError.trim() !== "" && (
                      <FormLabel className={classes.labelLeftHorizontal}>
                        <code>{this.props.cloneSiteError}</code>
                      </FormLabel>
                    )}

                  <Button
                    onClick={() => this.showConfirmBox()}
                    color="danger"
                    simple
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => this.props.onClose()}
                    color="danger"
                    simple
                  >
                    Cancel
                  </Button>
                </GridItem>
              </GridContainer>
            </LoadingOverlay>
          </GridItem>
        </GridContainer>
      </Grid>
    );
  }
  onHanndelClose = () => {
    this.setState({
      openCopyDilog: false
    });
  };

  render() {
    return <span>{this.cloneSiteDilog()}</span>;
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserId: state.authState.user.user,

  cloneSiteList: state.cmsReducer.cloneSite.list,
  cloneSiteisSaved: state.cmsReducer.cloneSite.isSaved,
  cloneSiteLoading: state.cmsReducer.cloneSite.loading,
  cloneSiteError: state.cmsReducer.cloneSite.error,

  List: state.cmsReducer.retailer_list.list,
  Loading: state.cmsReducer.retailer_list.loading,
  ListError: state.cmsReducer.retailer_list.error
});

CloneSiteComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  entityData: PropTypes.object,
  menu: PropTypes.array,
  selectedClassIndexes: PropTypes.array.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CloneSiteComponent);

// export default menuComponent;
