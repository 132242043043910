export const CREATE_BRAND_REQUEST = "create_brand_request";
export const CREATE_BRAND_FAILURE = "create_brand_failure";
export const CREATE_BRAND_SUCCESS = "create_brand_success";

export const GET_BRAND_REQUEST = "get_brand_request";
export const GET_BRAND_FAILURE = "get_brand_failure";
export const GET_BRAND_SUCCESS = "get_brand_success";

export const DELETE_USER_REQUEST = "delete_user_request";
export const DELETE_USER_FAILURE = "delete_user_failure";
export const DELETE_USER_SUCCESS = "delete_user_success";

export const SAVE_BRAND_PRODUCT_TEMPLATES_REQUEST =
  "save_brand_product_templates_request";
export const SAVE_BRAND_PRODUCT_TEMPLATES_FAILURE =
  "save_brand_product_templates_failure";
export const SAVE_BRAND_PRODUCT_TEMPLATES_SUCCESS =
  "save_brand_product_templates_success";

export const GET_BRAND_PRODUCT_TEMPLATES_REQUEST =
  "get_brand_product_templates_request";
export const GET_BRAND_PRODUCT_TEMPLATES_FAILURE =
  "get_brand_product_templates_failure";
export const GET_BRAND_PRODUCT_TEMPLATES_SUCCESS =
  "get_brand_product_templates_success";

export const GET_UNIVERSAL_ATTRIBUTE_REQUEST =
  "get_universal_attribute_request";
export const GET_UNIVERSAL_ATTRIBUTE_FAILURE =
  "get_universal_attribute_failure";
export const GET_UNIVERSAL_ATTRIBUTE_SUCCESS =
  "get_universal_attribute_success";

export const CREATE_UNIVERSAL_ATTRIBUTE_REQUEST =
  "create_universal_attribute_request";
export const CREATE_UNIVERSAL_ATTRIBUTE_FAILURE =
  "create_universal_attribute_failure";
export const CREATE_UNIVERSAL_ATTRIBUTE_SUCCESS =
  "create_universal_attribute_success";

export const GET_ATTRIBUTE_GROUP_REQUEST = "get_attribute_group_request";
export const GET_ATTRIBUTE_GROUP_FAILURE = "get_attribute_group_failure";
export const GET_ATTRIBUTE_GROUP_SUCCESS = "get_attribute_group_success";

export const CREATE_ATTRIBUTE_GROUP_REQUEST = "create_attribute_group_request";
export const CREATE_ATTRIBUTE_GROUP_FAILURE = "create_attribute_group_failure";
export const CREATE_ATTRIBUTE_GROUP_SUCCESS = "create_attribute_group_success";

export const MAP_WHOLESALER_BRAND_REQUEST = "map_wholesaler_brand_request";
export const MAP_WHOLESALER_BRAND_FAILURE = "map_wholesaler_brand_failure";
export const MAP_WHOLESALER_BRAND_SUCCESS = "map_wholesaler_brand_success";

export const MAP_RETAILER_BRAND_REQUEST = "map_retailer_brand_request";
export const MAP_RETAILER_BRAND_FAILURE = "map_retailer_brand_failure";
export const MAP_RETAILER_BRAND_SUCCESS = "map_retailer_brand_success";

export const SAVE_WHOLESALER_BRAND_REQUEST = "SAVE_wholesaler_brand_request";
export const SAVE_WHOLESALER_BRAND_FAILURE = "SAVE_wholesaler_brand_failure";
export const SAVE_WHOLESALER_BRAND_SUCCESS = "SAVE_wholesaler_brand_success";

export const GET_WHOLESALER_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_REQUEST =
  "get_wholesaler_brands_categorised_product_template_request";
export const GET_WHOLESALER_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_FAILURET =
  "get_wholesaler_brands_categorised_product_template_failuret";
export const GET_WHOLESALER_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS =
  "get_wholesaler_brands_categorised_product_template_success";

export const GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_REQUEST =
  "get_brands_categorised_product_template_selected_request";
export const GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_FAILURE =
  "get_brands_categorised_product_template__selected_failure";
export const GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_SUCCESS =
  "get_brands_categorised_product_template__selected_success";

export const GET_RETAILER_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_REQUEST =
  "get_retailer_categorised_product_template_selected_request";
export const GET_RETAILER_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_FAILURE =
  "get_retailer_categorised_product_template__selected_failure";
export const GET_RETAILER_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_SUCCESS =
  "get_retailer_categorised_product_template__selected_success";

export const SAVE_ATTRIBUTE_GROUP_REQUEST = "save_attribute_group_request";
export const SAVE_ATTRIBUTE_GROUP_FAILURE = "save_attribute_group_failure";
export const SAVE_ATTRIBUTE_GROUP_SUCCESS = "save_attribute_group_success";

export const GET_RELATED_PARTNER_REQUEST = "get_related_partner_request";
export const GET_RELATED_PARTNER_FAILURE = "get_related_partner_failure";
export const GET_RELATED_PARTNER_SUCCESS = "get_related_partner_success";

export const GET_ATTRIBUTE_GROUP_BYID_REQUEST =
  "get_attribute_group_byid_request";
export const GET_ATTRIBUTE_GROUP_BYID_FAILURE =
  "get_attribute_group_byid_failure";
export const GET_ATTRIBUTE_GROUP_BYID_SUCCESS =
  "get_attribute_group_byid_success";

export const DELETE_ATTRIBUTE_REQUEST = "delete_attribute_request";
export const DELETE_ATTRIBUTE_FAILURE = "delete_attribute_failure";
export const DELETE_ATTRIBUTE_SUCCESS = "delete_attribute_success";
