import { LOCATION_CHANGE } from "react-router-redux";

import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  user: null,
  authenticated: null,
  loginError: null,
  registerError: null,
  loginPending: false,
  UserID: null,
  registerPending: false,
  UserStatus: null,
  passwordchangepending: false,
  passwordChangeError: null
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {
        ...state,
        loginPending: false,
        registerPending: false,
        loginError: null,
        registerError: null
      };
    }
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loginPending: true,
        loginError: null
      };
    case actionTypes.PASSWORD_CHANGE_REQUEST:
      return {
        ...state,
        passwordchangepending: true,
        passwordChangeError: null
      };
    case actionTypes.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        passwordchangepending: false,
        passwordChangeError: null
      };
    case actionTypes.PASSWORD_CHANGE_FAILURE:
      return {
        ...state,
        passwordchangepending: false,
        passwordChangeError: action.payload
      };
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        loginPending: false,
        loginError: action.payload
      };
    case actionTypes.SET_USER_AUTH:
      return {
        ...state,
        loginPending: false,
        registerPending: false,
        loginError: null,
        registerError: null,
        user: action.payload,
        authenticated: action.payload !== null
      };
    case actionTypes.SIGN_OUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case actionTypes.SEND_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loginPending: false,
        loginError: action.payload
      };
    case actionTypes.SEND_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loginPending: false,
        registerPending: false,
        loginError: null,
        registerError: null,
        user: action.payload,
        authenticated: action.payload !== null
      };
    case actionTypes.SEND_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        ...INITIAL_STATE
      };

    default:
      return state;
  }
};

export default authReducer;
