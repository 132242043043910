import * as userAuthService from "../../services/userAuthService";
import * as actionTypes from "./actionTypes";
import * as navigationActions from "./navigationActions";
import { routesNames } from "../../constants";
import appDomain from "../../constants/domains";
import config from "../../constants/config";

import { localStorageUtils } from "../../utils";

export const sso_to_link = (app, redirectUrl) => async dispatch => {
  try {
    // PORTAL: 'PORTAL',
    // CONSUMER: 'CONSUMER',
    // PARTNER: 'PARTNER'
    let siteLink = "";
    if (!redirectUrl) {
      redirectUrl = "/";
    }
    let sso_token = localStorageUtils.getToken(localStorageUtils.SSO_TOKEN);
    if (!sso_token) {
      throw "SSO Token not available";
    }

    if (app === appDomain.CONSUMER) {
      siteLink =
        config.env.CONSUMER +
        "/validate?token=" +
        sso_token +
        "&redirectUrl=" +
        redirectUrl;
    }

    if (app === appDomain.PARTNER) {
      siteLink = redirectUrl + "/validate?token=" + sso_token;
    }

    let win = window.open(siteLink, "_blank");
    win.focus();
  } catch (error) {
    console.error("Single Sign On", error);
  }
};

export const signOutUser = () => async dispatch => {
  try {
    //await userAuthService.logout();
    dispatch(setUserAuth(null));
    dispatch(navigationActions.onNavigateTo(routesNames.LOGIN));
    localStorageUtils.removeToken(localStorageUtils.SESSION);
    localStorageUtils.removeToken(localStorageUtils.SSO_TOKEN);
    localStorageUtils.removeToken(localStorageUtils.TOKEN);
    localStorageUtils.removeToken(localStorageUtils.USERID);
  } catch (error) {
    dispatch(setUserAuth(null));
    dispatch(navigationActions.onNavigateTo(routesNames.LOGIN));
  }
};

export const setUserTimedOut = () => async dispatch => {
  try {
    dispatch(setUserAuth(null));
    dispatch(navigationActions.onNavigateTo(routesNames.LOGIN));
  } catch (error) {
    dispatch(setUserAuth(null));
    dispatch(navigationActions.onNavigateTo(routesNames.LOGIN));
  }
};

export const getCurentUser = () => async dispatch => {
  try {
    const user = await userAuthService.getCurrentUser();
    if (user) {
      var userData = {
        Token: user.data.api_key,
        id: user.data.api_key,
        name:
          user.data.detail && user.data.detail.name
            ? user.data.detail.name
            : user.data.email,
        user: user.data.email,
        pk: user.data.id,
        status: user.data.status,
        folderId:
          user.data.folder && user.data.folder.id ? user.data.folder.id : "",
        isAdminUser: false,
        user_group: user.data.user_group.id,
        partner_status: user.partner.status,
        approval_status: user.partner.approval_status,
        test_deploy_url: user.partner.test_deploy_url
      };

      userData["role"] = user.partner.role;
      userData["partner_id"] = user.partner.id;
      userData["id"] = user.partner.api_key;
      userData["businessUrl"] =
        user.partner && user.partner.deploy_url ? user.partner.deploy_url : "";
      if (
        user.data.user_group &&
        (user.data.user_group.name === "Retailer Admin Group" ||
          user.data.user_group.name === "Brand Admin Group" ||
          user.data.user_group.name === "Wholesaler Admin Group")
      ) {
        userData["isAdminUser"] = true;
      }
      dispatch(setUserAuth(userData));
    } else {
      dispatch(setUserAuth(null));
      dispatch(navigationActions.onNavigateTo(routesNames.LOGIN));
    }
  } catch (error) {
    dispatch(setUserAuth(null));
  }
};

export const signInUser = (
  email,
  password,
  isImpersonting,
  loggedInUser
) => async dispatch => {
  dispatch(loginRequest());
  try {
    const user = await userAuthService.login(
      email,
      password,
      isImpersonting,
      loggedInUser
    );
    if (!isImpersonting) {
      isImpersonting = false;
    }

    if (user && user.status === "1") {
      var userData = {
        Token: user.data.api_key,
        id: user.data.api_key,
        name:
          user.data.detail && user.data.detail.name
            ? user.data.detail.name
            : user.data.email,
        user: user.data.email,
        pk: user.data.id,
        status: user.data.status,
        folderId:
          user.data.folder && user.data.folder.id ? user.data.folder.id : "",
        isAdminUser: false,
        user_group: user.data.user_group.id,
        partner_status: user.partner.status,
        approval_status: user.partner.approval_status,
        test_deploy_url: user.partner.test_deploy_url
      };
      userData["role"] = user.partner.role;
      userData["partner_id"] = user.partner.id;
      userData["id"] = user.partner.api_key;
      userData["Impersonate"] = isImpersonting;
      userData["businessUrl"] =
        user.partner && user.partner.deploy_url ? user.partner.deploy_url : "";

      if (
        user.data.user_group &&
        (user.data.user_group.name === "Retailer Admin Group" ||
          user.data.user_group.name === "Brand Admin Group" ||
          user.data.user_group.name === "Wholesaler Admin Group")
      ) {
        userData["isAdminUser"] = true;
      }

      dispatch(setUserAuth(userData));
      dispatch(navigationActions.onNavigateTo(routesNames.ROUTEMIDDLEWARE));
    } else {
      if (!isImpersonting) {
        dispatch(setUserAuth(null));
        dispatch(setInvalidCredentials("Invalid Credentials"));
      }
    }
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

export const getCurentUserFromToken = (token, key) => async dispatch => {
  try {
    const user = await userAuthService.getUserToken(token, key);
    if (user) {
      var userData = {
        Token: user.data.api_key,
        id: user.data.api_key,
        name:
          user.data.detail && user.data.detail.name
            ? user.data.detail.name
            : user.data.email,
        user: user.data.email,
        pk: user.data.id,
        status: user.data.status,
        folderId:
          user.data.folder && user.data.folder.id ? user.data.folder.id : "",
        isAdminUser: false,
        user_group: user.data.user_group.id,
        partner_status: user.partner.status,
        approval_status: user.partner.approval_status,
        test_deploy_url: user.partner.test_deploy_url
      };

      userData["role"] = user.partner.role;
      userData["partner_id"] = user.partner.id;
      userData["id"] = user.partner.api_key;
      userData["Impersonate"] =
        user.data.api_key.toLowerCase() !== user.partner.api_key.toLowerCase();
      userData["businessUrl"] =
        user.partner && user.partner.deploy_url ? user.partner.deploy_url : "";
      if (
        user.data.user_group &&
        (user.data.user_group.name === "Retailer Admin Group" ||
          user.data.user_group.name === "Brand Admin Group" ||
          user.data.user_group.name === "Wholesaler Admin Group")
      ) {
        userData["isAdminUser"] = true;
      }
      dispatch(setUserAuth(userData));
      dispatch(navigationActions.onNavigateTo(routesNames.ROUTEMIDDLEWARE));
    } else {
      dispatch(setUserAuth(null));
      dispatch(navigationActions.onNavigateTo(routesNames.LOGIN));
    }
  } catch (error) {
    dispatch(setUserAuth(null));
  }
};

const setUserAuth = user => {
  return {
    type: actionTypes.SET_USER_AUTH,
    payload: user
  };
};
const setInvalidCredentials = msg => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    payload: msg
  };
};

const loginRequest = () => {
  return {
    type: actionTypes.LOGIN_REQUEST
  };
};

const loginFailure = error => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    payload: error
  };
};
