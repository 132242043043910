import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "recompose";
import _ from "lodash";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { CircularProgress, FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { ActionCreators } from "../../../redux/actions";
import { validationUtils } from "../../../utils";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class ValuesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: "",
      saveAndStay: false,
      isAddNew: false,
      industryList: [],
      currentStep: 0,
      language_submission: [],
      sections: [],

      variant_id: "",

      variant: {
        name: "",
        code: "",
        id: "",
        variant_id: this.props.variant_id
      },

      required: {
        name: "",
        code: "",
      },

      error: null,
      saveRequested: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && !_.isEqual(this.props, nextProps)) {

      if (nextProps.variantDetail) {
        this.setEditData(nextProps.variantDetail);
      }

      if (this.state.saveRequested) {
        if (!nextProps.basicError && !nextProps.basicSaving) {
          this.setState(
            () => ({
              saveRequested: false,
            })
          );
          this.CancelAddEdit();
        }
      }
    }
  }

  setEditData(cmpnn) {
    this.setState(
      () => ({
        ...this.state,
        variant: {
          ...this.state.variant,
          id: cmpnn.id,
          name: cmpnn.name,
          code: cmpnn.code,
          variant_id: this.state.variant_id
        }
      })
    );
  }

  componentDidMount() {

    this.setState({
      variant_id: this.props.variant_id,
      value_id: this.props.value_id
    }, () => {
      if (this.state.variant_id && this.state.value_id) {
        this.props.actions.getVariantValueDetails(this.state.variant_id, this.state.value_id);
      }
    })


  }

  handelIsAddNew = () => {
    this.setState({
      ...this.state,

      variant: {
        id: "",
        name: "",
        code: "",
        is_active: true,
        variant_id: this.props.variant_id
      },

      required: {
        name: "",
        code: "",
      },

      isEdit: false,
      currentStep: 0
    });
  };


  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;

    this.setState({
      ...this.state,
      required: reqData
    });

    this.setState({
      ...this.state,
      variant: {
        ...this.state.variant,
        [key]: event.target.value
      }
    });
  };

  handleToggleStatus = key => event => {
    this.setState({
      ...this.state,
      variant: {
        ...this.state.variant,
        [key]: event.target.checked
      }
    });
  };

  saveDetails = () => {
    this.setState({
      saveRequested: true
    }, () => {
      this.props.actions.saveVariantValue(this.props.Userid, this.state.variant);
    });
  };

  saveBasicDetails = () => {
    if (this.validateIsRequired()) {
      this.saveDetails();
    }
  };

  CancelAddEdit = () => {
    this.props.addNewValue(false);
  };


  renderTabs = () => {
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        {this.renderForm()}
      </GridItem>
    );
  };

  render() {
    return this.renderTabs();
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.variant.hasOwnProperty("name") ||
      (this.state.variant.name.trim() === "" || reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.variant.hasOwnProperty("code") ||
      (this.state.variant.code.trim() === "" ||
        reqData["code"] === "error")
    ) {
      reqData["code"] = "error";
      isValid = false;
    }

    this.setState({
      ...this.state,
      required: reqData
    });
    return isValid;
  }


  renderForm = () => {
    const { classes } = this.props;
    const {
      name,
      id,
      code
    } = this.state.variant;
    const { required } = this.state;
    return (

      <Card>
        <CardHeader color="info" icon>
          <br />
          <p className={classes.cardIconTitle + " grid-header-title"}>
            Make edits to the fields below, then save changes.
              </p>
        </CardHeader>
        <CardBody>
          <form>
            <LoadingOverlay>
              <CustomInput
                success={required.code === "success"}
                error={required.code === "error"}
                labelText="Code *"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  value: code,
                  onChange: this.handleTextChange(
                    "code",
                    // validationUtils.validationTypes.name
                  )
                }}
                id={"code_"}
              />

              <CustomInput
                success={required.name === "success"}
                error={required.name === "error"}
                labelText="Name *"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  value: name,
                  onChange: this.handleTextChange(
                    "name",
                    validationUtils.validationTypes.name
                  )
                }}
                id={"name_"}
              />

              <br />
              <Button
                color="info"
                onClick={this.saveBasicDetails.bind(this)}
              >Save
                  </Button>
              <Button onClick={this.CancelAddEdit}>Cancel</Button>
              <br />
              {this.props.loadingerror && (
                <FormLabel className={classes.labelLeftHorizontal}>
                  <code>{this.props.loadingerror}</code>
                </FormLabel>
              )}
              {this.props.basicError && (
                <FormLabel className={classes.labelLeftHorizontal}>
                  <code>{this.props.basicError}</code>
                </FormLabel>
              )}
              {(this.props.basicSaving || this.props.loading) && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-12px"
                  }}
                />
              )}
              <Loader loading={this.props.basicSaving} text="Saving.." />
              <Loader loading={this.props.loading} text="Loading.." />
            </LoadingOverlay>
          </form>
        </CardBody>
      </Card>

    );
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({

  basicSaving: state.variantReducer.add_variant_value.saving,
  basicError: state.variantReducer.add_variant_value.error,

  loading: state.variantReducer.variant_value_details.loading,
  loadingerror: state.variantReducer.variant_value_details.error,
  variantDetail: state.variantReducer.variant_value_details.variant_value,

  Userid: state.authState.user.pk,
  user: state.authState.user,
  UserId: state.authState.user.user
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ValuesView);
