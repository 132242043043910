import { routesNames, rolesNames } from "../constants";
import {
  adminRoutes,
  userRoutes,
  brandRoutes,
  wholesalerRoutes
} from "../variables/routes";
export function getLandingRoute(role) {
  switch (role) {
    case rolesNames.RoleMaster.ADMIN:
      return rolesNames.RoleLandingRoute.ADMIN;
    case rolesNames.RoleMaster.BRAND:
      return rolesNames.RoleLandingRoute.BRAND;
    case rolesNames.RoleMaster.RETAILER:
      return rolesNames.RoleLandingRoute.RETAILER;
    case rolesNames.RoleMaster.WHOLESALER:
      return rolesNames.RoleLandingRoute.WHOLESALER;
    case rolesNames.RoleMaster.ASSOCIATEUSER:
      return rolesNames.RoleLandingRoute.ASSOCIATEUSER;
    default:
      return routesNames.LOGIN;
  }
}

export function getRoutesMenu(role) {
  switch (role) {
    case rolesNames.RoleMaster.ADMIN:
      return adminRoutes;
    case rolesNames.RoleMaster.BRAND:
      return brandRoutes;
    case rolesNames.RoleMaster.RETAILER:
      return userRoutes;
    case rolesNames.RoleMaster.WHOLESALER:
      return wholesalerRoutes;
    default:
      return userRoutes;
  }
}
