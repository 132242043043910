import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import Swal from "sweetalert2";

// @material-ui/core components

import { FormLabel } from "@material-ui/core";


import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import ComponentEditAction from "../../components/AgGrid/AgGridActionButton";

class VariantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false
      },
      isAddNew: false,
      NotificationId: null,
      NotificationValue: null,
      selectedUAIndexes: [],
      mappingData: {
        notifications: null,
        description: "",
        is_active: true
      },
      componentList: [],
      columnDefs: [
        {
          headerName: "id",
          field: "id",
          width: 150,
          resizable: true,
          sortable: false
        },
        {
          headerName: "NAME",
          field: "name",
          width: 200,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter"
        },
        {
          headerName: "ACTIVE",
          field: "is_active",
          width: 120,
          resizable: true,
          sortable: true
        },
        {
          headerName: "ACTIONS",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ],
      required_copy_menu: {
        industry: "",
        menu_name: ""
      },
      copy_menu: {
        industry: null,
        menu_name: "",
        page_id: null,
        slug: ""
      }
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps && !_.isEqual(nextProps, this.props)) {
      if (nextProps.list) {
        this.setState({
          componentList: nextProps.list,
          pagination: nextProps.pagination
        });
      }


      if (this.state.openDeleteDialog && !nextProps.deleting && !nextProps.deleteError) {
        this.setState({
          openDeleteDialog: false
        }, () => {
          this.refreshList();
        });
      }
    }
  }

  refreshList = () => {

    this.props.actions.getVariants(
      this.state.pagination.page,
      this.state.searchtext
    );
  }


  componentDidMount() {
    this.refreshList();
  }

  handleAddNew = () => {
    this.props.addNew(true);
  };

  handelEdit = id => {
    const { componentList } = this.state;
    const data = componentList;
    let index = data.findIndex(p => p.id === id);
    if (data[index]) {
      this.props.edit(id);
    }
  };


  deleteDialog = (Id) => {

    Swal.fire({
      title: "Are you sure?",
      text: "This action will delete the variant.",
      icon: "warning",
      showCancelButton: true,
      focusCancel: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete"
    }).then(result => {
      if (result.isConfirmed) {
        this.setState({
          openDeleteDialog: true
        })
        this.props.actions.deleteVariant(Id);
      }
    });
  };

  renderList = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  View, edit or delete Variants below.
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.componentList}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                handleDelete={this.deleteDialog}
                AgGridActionButton={ComponentEditAction}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                loading={this.props.loading}
                search={this.searchText}
                new={this.handleAddNew}
                isAddBtn={true}
              />

              {this.props.deleteError && (
                <FormLabel className={classes.labelLeftHorizontal}>
                  <code>{this.props.deleteError}</code>
                </FormLabel>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        page: page
      }
    }, () => {
      this.refreshList();
    })
  }

  searchText(param) {
    this.setState({
      searchtext: param,
      pagination: {
        ...this.state.pagination,
        page: 1,
      }
    }, () => {
      this.refreshList();
    });
  }

  render() {
    return this.renderList();
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  user: state.authState.user,
  UserId: state.authState.user.user,

  loading: state.variantReducer.list_variant.loading,
  error: state.variantReducer.list_variant.error,
  list: state.variantReducer.list_variant.variants,

  deleting: state.variantReducer.delete_variant.deleting,
  deleteError: state.variantReducer.delete_variant.error,

  pagination: state.variantReducer.pagination
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(VariantList);
