import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import PropTypes from "prop-types";
import * as routesNames from "../../constants/routes";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "../../style/app.css";

import { LoadingOverlay, Loader } from "react-overlay-loader";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import CardBody from "components/Card/CardBody.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "components/CustomButtons/Button.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

import Alert from "@material-ui/lab/Alert";
import AgGrid from "components/AgGrid/AgGrid.jsx";

let AggridApi;

let agGridViewApi = null;
let agGridEditApi = null;

function getSimpleCellRenderer() {
  function SimpleCellRenderer() { }
  SimpleCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");

    tempDiv.innerHTML = "<span>" + params.value + "</span>";
    this.eGui = tempDiv.firstChild;
  };
  SimpleCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return SimpleCellRenderer;
}

class UserGroupMenuMappingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      userGroupId: "",
      data: {},
      menus: [],
      selectedServices: [],
      components: { simpleCellRenderer: getSimpleCellRenderer() },
      columnDefs: [
        {
          headerName: "Services",
          field: "name",
          width: 720,
          resizable: true,
          showRowGroup: true,
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            suppressCount: true,
            checkbox: function (params) {
              if (params.node && params.node.group == false) {
                return true;
              } else {
                return false;
              }
            },
            innerRenderer: "unLabeledCheckboxCellRender",
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true
          }
        }
      ],
      context: { componentParent: this },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      domLayout: "autoHeight",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      getNodeChildDetails: function (data) {
        if (data && data.services && data.services.length > 0) {
          let children = [];
          children = data.services;
          return {
            group: true,
            children: children,
            expanded: data.open
          };
        } else {
          return null;
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
    this.searchText = this.searchText.bind(this);
    this.updateGridData = this.updateGridData.bind(this);
    this.gridRefs = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.List) {
      this.setState(
        {
          ...this.state,
          menus: nextProps.List,
          pagination: nextProps.pagination
        },
        () => {
          if (nextProps && nextProps.assignedServiceList) {
            let assignedService = nextProps.assignedServiceList.map(
              x => x.services
            );
            this.gridRefs.current.setSelections(assignedService, "Service");
          }
        }
      );
    }
    if (nextProps.ServiceMappingisSaved) {
      if (this.props.authUser.role == "AD") {
        this.props.actions.onNavigateTo(routesNames.CREATEWEBSITE);
      }
      if (this.props.authUser.role == "RE") {
        this.props.actions.assignedServicer(this.props.authUser.partner_id);
      }
    }
  }

  componentDidMount() {
    if (this.props.authUser.role == "RE") {
      this.props.actions.assignedServicer(this.props.authUser.partner_id);
    } else {
      let search = window.location.search;
      let urlParams = new URLSearchParams(search);
      let id = urlParams.get("id");
      this.props.actions.assignedServicer(id);
    }
    // this.props.actions.getServicesList(1, this.state.pagination.page, this.state.searchtext);
    this.props.actions.getServicesList(1);
  }

  render() {
    return <>{this.renderOrderTable()}</>;
  }

  renderOrderTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <p
                className={
                  classes.cardIconTitle + " wholesalor-hierarchy-title"
                }
              >
                Assign Services
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <AgGrid
                  ref={this.gridRefs}
                  rowData={this.state.menus}
                  columnDefs={this.state.columnDefs}
                  update={this.updateGridData}
                  // isServerPagination={true}
                  // pagination={this.state.pagination}
                  loading={this.state.Loading}
                  // search={this.searchText}
                  isHierarchyView={true}
                  getNodeChildDetails={this.state.getNodeChildDetails}
                  // onSelectionChanged={this.onSelectionChanged.bind(this)}
                  rowSelection="multiple"
                  groupSelectsChildren={true}
                />
                <div>
                  {this.props.ServiceMappingdata && (
                    <span>
                      {" "}
                      <Alert severity="success">
                        {this.props.ServiceMappingdata}
                      </Alert>
                    </span>
                  )}

                  <br />
                  <Button color="info" onClick={() => this.saveMapping()}>
                    Save Changes
                  </Button>
                  {this.props.authUser.role == "AD" && (
                    <Button onClick={() => this.closeMappingComponent()}>
                      Cancel
                    </Button>
                  )}
                </div>
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getServicesList(1, page, this.state.searchtext);
  }

  searchText(params) {
    this.setState(
      {
        searchtext: params
      },
      () => {
        let page = 1;
        this.props.actions.getServicesList(1, page, this.state.searchtext);
      }
    );
  }

  saveMapping = () => {
    let selectedRow = this.gridRefs.current.agGridApi.getSelectedRows();
    // let { data } = this.state
    let partnerId = null;
    if (this.props.authUser.role == "RE") {
      partnerId = this.props.authUser.partner_id;
    } else {
      let search = window.location.search;
      let urlParams = new URLSearchParams(search);
      partnerId = urlParams.get("id");
    }
    this.props.actions.saveServicePartnerMapping(
      selectedRow,
      partnerId,
      this.props.UserId
    );
  };

  closeMappingComponent = () => {
    if (this.props.authUser.role == "AD") {
      this.props.actions.onNavigateTo(routesNames.CREATEWEBSITE);
    }
    if (this.props.authUser.role == "RE") {
      this.props.actions.onNavigateTo(routesNames.DASHBOARD);
    }
  };

  onSelectionChanged() {
    let selectedRows = this.gridRefs.current.agGridApi.getSelectedRows();
    var length = this.state.selectedServices.length;
    var i = 0;
    const componentThis = this;
    for (i = 0; i < length; i++) {
      componentThis.state.selectedServices.pop();
    }
    // selectedRows.forEach(function (selectedRow, index) {
    //     componentThis.state.selectedServices.push(selectedRow);
    // });
    this.gridRefs.current.setState({
      selectedNodes: selectedRows
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    AggridApi = _.clone(this.gridApi, true);
  };

  onGridViewReady = params => {
    this.gridApi = params.api;
    agGridViewApi = _.clone(params.api, true);
  };
  onGridEditReady = params => {
    this.gridApi = params.api;
    agGridEditApi = _.clone(params.api, true);
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  authUser: state.authState.user,
  authenticated: state.authState.authenticated,
  menuList: state.partnerMenuReducer.get_partner_menu_list,
  appMenus: state.appMenuReducer.list,
  // List: state.userGroupReducer.userGroupMenu.list
  List: state.serviceReducer.list,
  pagination: state.serviceReducer.pagination,
  Loading: state.serviceReducer.loading,
  ListError: state.serviceReducer.error,
  isSaved: state.serviceReducer.isSaved,
  UserId: state.authState.user.user,
  ServiceMappingLoading: state.serviceReducer.partnerServiceMapping.loading,
  ServiceMappingerror: state.serviceReducer.partnerServiceMapping.error,
  ServiceMappingisSaved: state.serviceReducer.partnerServiceMapping.isSaved,
  ServiceMappingdata: state.serviceReducer.partnerServiceMapping.data,
  assignedServiceList: state.serviceReducer.assignedService.list
});

UserGroupMenuMappingComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func,
  save: PropTypes.func
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserGroupMenuMappingComponent);
