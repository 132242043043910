import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _ from "lodash";
import cx from "classnames";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import moment from "moment";
import CampaignInfoTab from "./CampaignInfoTab";
import ChannelTab from "./ChannelTab";
import PartnerTab from "./PartnerTab";
import ContentTab from "./ContentTab";
import FormLabel from "@material-ui/core/FormLabel";

const Style = {
  title: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "bold",
    "font-size": "36px",
    "line-height": "42px",
    "text-align": "center",
    color: "#666666"
  },
  titlePara: {
    "text-align": "center"
  },
  selectedTabHeader: {
    color: "#F44336"
  },
  tabHeaderDiv: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "14px",
    "line-height": "16px",
    display: "flex",
    "align-items": "center",
    "text-align": "center",
    "letter-spacing": "0.75px",
    "text-transform": "uppercase",
    display: "inline-block",
    padding: "15px",
    cursor: "pointer",
    color: "#919699"
  }
};

const initialState = {
  id: "",
  name: "",
  description: "",
  start_date: moment().format("MM/DD/YYYY"),
  end_date: moment().format("MM/DD/YYYY"),
  retailers: [],
  is_active: false,
  is_deleted: false,
  channels: [],
  navigation_url: "",
  status: 1,
  required: {
    name: "",
    retailers: [],
    image: "",
    start_date: "",
    end_date: "",
    channels: []
  }
};
const wizardHeaders = ["Campaign Info", "Channel", "Partner", "Content"];

class CreateEditCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveBtnClick: false,
      error: null,
      start_date: moment().format("MM/DD/YYYY"),
      end_date: moment().format("MM/DD/YYYY"),
      // addPromotionData: {
      //     ...initialState,
      // },
      addPromotionData: {},
      selectedTab: 0,
      showChannels: {
        retailerChannel: "No",
        teamlocalChannel: "No"
      },
      hidePartnerTab: false,
      setEditData: true
    };
    this.changeSelectedTab = this.changeSelectedTab.bind(this);
    this.closePromotion = this.closePromotion.bind(this);
    this.setPromotionData = this.setPromotionData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({
        addPromotionData: nextProps.data
      });
    }
    if (nextProps.channels) {
      this.setState({
        showChannels: nextProps.channels
      });
    }
    this.setState({
      hidePartnerTab: nextProps.hidePartnerTab
    });
    if (nextProps.isSaved && this.state.saveBtnClick) {
      this.closePromotion();
    }
    let error = "";
    if (nextProps.ListError) {
      error = nextProps.ListError;
    }

    this.setState({
      error: error
    });
  }

  componentDidMount() {
    if (this.props.channels) {
      this.setState({
        showChannels: this.props.channels
      });
    }
    if (this.props.data) {
      this.setState({
        addPromotionData: this.props.data
      });
    }
    this.setState({
      hidePartnerTab: this.props.hidePartnerTab
    });
  }

  render() {
    return <div>{this.renderCreatePromotions()}</div>;
  }

  renderCreatePromotions = () => {
    const { classes } = this.props;
    if (this.state.hidePartnerTab) {
      const index = wizardHeaders.indexOf("Partner");
      if (index !== -1) {
        wizardHeaders.splice(index, 1);
      }
    } else {
      const index = wizardHeaders.indexOf("Partner");
      if (index === -1) {
        wizardHeaders.splice(2, 0, "Partner");
      }
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <LoadingOverlay>
            <Card>
              <CardHeader>
                <div style={Style.title}>Campaign Setup Wizard</div>
                <p style={Style.titlePara}>
                  Make edits to the fields below, then save changes.
                </p>
              </CardHeader>
              <CardBody>
                <Grid>
                  <GridContainer>
                    <GridItem lg={1} md={1} />
                    <GridItem lg={10} md={10} sm={12} xs={12}>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                          {_.map(wizardHeaders, (model, index) => (
                            <GridItem
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              style={{
                                "text-align": "center"
                              }}
                            >
                              <div
                                value={index}
                                onClick={() => this.handleChange(index)}
                                className={cx("wizard-tab", {
                                  "wizard-selected-tab":
                                    this.state.selectedTab == index
                                })}
                              >
                                {model}
                              </div>
                            </GridItem>
                          ))}
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Divider variant="center" />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        {this.showSelectedTabContent()}
                        {this.state.error && this.state.error.trim() !== "" && (
                          <FormLabel className={classes.labelLeftHorizontal}>
                            <code>{this.state.error}</code>
                          </FormLabel>
                        )}
                      </GridItem>
                    </GridItem>
                    <GridItem lg={1} md={1} />
                  </GridContainer>
                </Grid>
              </CardBody>
            </Card>
            {this.props.Loading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.isSaving} text="Saving..." />
          </LoadingOverlay>
        </GridItem>
      </GridContainer>
    );
  };

  showSelectedTabContent() {
    const { selectedTab, addPromotionData, showChannels } = this.state;
    switch (selectedTab) {
      case 0:
        return (
          <div>
            <CampaignInfoTab
              data={addPromotionData}
              changeSelectedTab={this.changeSelectedTab}
              closePromotion={this.closePromotion}
              setPromotionData={this.setPromotionData}
              editState={this.props.isEditMode}
              save={this.saveCampign}
            />
          </div>
        );

      case 1:
        return (
          <div>
            <ChannelTab
              data={addPromotionData}
              changeSelectedTab={this.changeSelectedTab}
              closePromotion={this.closePromotion}
              selectedChannelDist={showChannels}
              setPromotionData={this.setPromotionData}
              hidePartner={this.state.hidePartnerTab}
              editState={this.props.isEditMode}
              save={this.saveCampign}
            />
          </div>
        );

      case 2:
        return (
          <div>
            {!this.state.hidePartnerTab ? (
              <PartnerTab
                data={addPromotionData}
                changeSelectedTab={this.changeSelectedTab}
                closePromotion={this.closePromotion}
                setPromotionData={this.setPromotionData}
                editState={this.props.isEditMode}
                save={this.saveCampign}
              />
            ) : (
              ""
            )}
          </div>
        );

      case 3:
        return (
          <div>
            <ContentTab
              data={addPromotionData}
              selectedChannelDist={showChannels}
              changeSelectedTab={this.changeSelectedTab}
              closePromotion={this.closePromotion}
              setPromotionData={this.setPromotionData}
              save={this.saveCampign}
              hidePartner={this.state.hidePartnerTab}
              editState={this.props.isEditMode}
            />
          </div>
        );
    }
  }

  handleChange(value) {
    if (this.state.hidePartnerTab && value == 2) {
      value = value + 1;
    }
    this.setState({
      selectedTab: value
    });
  }

  changeSelectedTab = value => {
    let { selectedTab } = this.state;
    selectedTab = selectedTab + value;
    this.setState({
      selectedTab: selectedTab
    });
  };

  closePromotion = () => {
    this.props.closePromotionWizard();
  };

  setPromotionData(key, value, optional_value, channelid = null) {
    this.props.updateData(key, value, optional_value, channelid);
  }

  handelImageChange = file => {
    let reader = new FileReader();
    let { addPromotionData } = this.state;

    reader.onloadend = () => {
      addPromotionData["image"] = reader.result;
      this.setState({
        addPromotionData: addPromotionData
      });
    };
    reader.readAsDataURL(file);
  };

  handelImageRemove = () => {
    let { addPromotionData } = this.state;
    addPromotionData["image"] = "";
    this.setState({
      addPromotionData: addPromotionData
    });
  };

  checkCampaignComplete() {
    const {
      name,
      channels,
      retailers,
      start_date,
      end_date
    } = this.state.addPromotionData;
    let is_complete = true;

    if (channels === null || channels.length === 0 || channels === undefined) {
      is_complete = false;
    } else {
      for (var i in channels) {
        if (
          channels[i].channelDistId == undefined ||
          channels[i].image == undefined ||
          channels[i].image == ""
        ) {
          is_complete = false;
        }
      }
    }

    if (
      retailers === null ||
      retailers.length === 0 ||
      retailers === undefined
    ) {
      is_complete = false;
    }

    if (
      start_date === null ||
      start_date.length === 0 ||
      start_date === undefined
    ) {
      is_complete = false;
    }

    if (end_date === null || end_date.length === 0 || end_date === undefined) {
      is_complete = false;
    }

    return is_complete;
  }

  saveCampign = filter => {
    let { name, is_active, status } = this.state.addPromotionData;

    if (filter === 1) {
      is_active = false;
    } else {
      is_active = true;
    }
    if (this.checkCampaignComplete()) {
      status = 0;
    }

    if (name === null || name.length === 0 || name === undefined) {
      this.setState({
        error: "Please Choose the name for Campaign"
      });
    } else {
      this.setState(
        {
          ...this.state,
          saveBtnClick: true,
          addPromotionData: {
            ...this.state.addPromotionData,
            is_active: is_active,
            status: status,
            start_date: moment(this.state.addPromotionData.start_date),
            end_date: moment(this.state.addPromotionData.end_date)
          }
        },
        () => {
          var files = [];
          let { channels } = this.state.addPromotionData;
          for (var i in channels) {
            if (channels[i].image && channels[i].image instanceof Blob) {
              files.push(channels[i].image);
            }
          }
          this.props.actions.saveCampaign(
            this.props.UserId,
            this.state.addPromotionData,
            this.props.PartnerId,
            files
          );
        }
      );
    }
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

CreateEditCampaign.propTypes = {
  closePromotionWizard: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  retailers: state.partnerSate.brandPartner.list,
  channelList: state.campignManagementReducer.channels.list,
  channelDistribution: state.campignManagementReducer.channeldistribution.list,
  List: state.campignManagementReducer.list,
  Loading: state.campignManagementReducer.loading,
  ListError: state.campignManagementReducer.error,
  isSaved: state.campignManagementReducer.isSaved,
  PartnerId: state.authState.user.partner_id,
  User: state.authState.user,
  UserId: state.authState.user.user,
  playPause: state.campignManagementReducer.playPause,
  deleteCampaign: state.campignManagementReducer.deleteCampaign,
  isSaving: state.campignManagementReducer.isSaving
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateEditCampaign);
