import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";

import { validationUtils } from "../../utils";

// @material-ui/core components/Dialog
import Slide from "@material-ui/core/Slide";

import ReactTooltip from "react-tooltip";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);

    this.state = {
      isAddNew: false,
      addTemplateData: {
        name: "",
        description: "",
        is_active: false,
        is_Deleted: false
      },
      required: {
        name: "",
        description: ""
      },
      classData: [],
      error: null,
      isEdit: false,
      isAttributeGroupOpen: false,
      openDeleteDialog: false,
      classId: null,
      classValue: null,
      selectedUAIndexes: [],
      mappingData: {
        classes: null,
        attribute_group: null,
        description: "",
        is_active: true
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const classData = nextProps.List;
        this.setState(() => ({
          classData: classData
        }));
      }

      if (nextProps.Error && nextProps.Error.trim() !== "") {
        this.setState(() => ({
          error: nextProps.Error,
          isAddNew: true
        }));
      }

      if (nextProps.isSaved && (this.state.isAddNew || this.state.isEdit)) {
        this.setState(
          {
            isAddNew: false,
            isEdit: false
          },
          function() {
            this.props.actions.getTemplateList(this.props.UserId);
          }
        );
      }
      if (nextProps.isDeleted) {
        this.setState(() => ({
          openDeleteDialog: false
        }));
      }
    }
  }

  componentDidMount() {
    this.props.actions.getTemplateList(this.props.UserId);
  }

  handelIsAddNew = () => {
    // addTemplateData: {
    //     ...this.state.addTemplateData,

    // }
    this.setState({
      isAddNew: !this.state.isAddNew,
      addTemplateData: {
        name: "",
        description: "",
        is_active: false,
        is_Deleted: false,
        image: ""
      },
      editorState: EditorState.createWithContent(
        ContentState.createFromText("")
      )
    });
  };
  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      addTemplateData: {
        ...this.state.addTemplateData,
        [key]: event.target.value
      }
    });
  };
  handleToggleStatus = curObj => event => {
    this.setState({
      addTemplateData: {
        ...this.state.addTemplateData,
        is_active: event.target.checked
      }
    });
  };

  openClassComponentDialog = index => {
    var { classData } = this.state;
    if (classData) {
      this.setState(() => ({
        isAttributeGroupOpen: true,
        classValue: classData[index]
      }));
    }
  };

  saveTemplate = () => {
    // if (this.validateIsRequired()) {
    const savingData = _.cloneDeep(this.state.addTemplateData);
    if (!this.state.isEdit) {
      let classData = _.cloneDeep(this.state.classData);
      classData.push(savingData);
      this.setState({
        classData: classData,
        editorState: EditorState.createWithContent(
          ContentState.createFromText("")
        )
      });
    } else {
      let classData = _.cloneDeep(this.state.classData);
      for (let i = 0; i < classData.length; i++) {
        if (classData[i].id === savingData.id) {
          classData[i].description = savingData.description;
          classData[i].is_deleted = savingData.is_Deleted;
          classData[i].name = savingData.name;
          classData[i].is_active = savingData.is_active;
        }
      }
      this.setState({
        classData: classData,
        editorState: EditorState.createWithContent(
          ContentState.createFromText("")
        )
      });
    }
    savingData.disclaimer = savingData.description;
    this.props.actions.saveTemplate(this.props.UserId, savingData);
    // }
  };

  handelEdit = index => {
    const { isEdit, classData } = this.state;
    const data = classData;
    var editData = data[index];
    let content = null;
    let contentState = null;
    let editorState = null;
    // disclaimer: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    if (editData && editData.description) {
      content = htmlToDraft(editData.description);
      contentState = ContentState.createFromBlockArray(
        content.contentBlocks,
        content.entityMap
      );
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createWithContent(
        ContentState.createFromText("")
      );
    }

    if (data[index]) {
      this.setState({
        addTemplateData: {
          name: data[index].name,
          description: data[index].description,
          index: index,
          id: data[index].id,
          is_active: data[index].is_active,
          is_Deleted: false,
          image: data[index].image
        },
        isAddNew: true,
        isEdit: true,
        editorState: editorState
      });
    }
  };

  deleteDialog = (state, Id, Index) => {
    this.setState({
      openDeleteDialog: state,
      classId: Id
    });
  };

  deleteSegment = Id => {
    this.props.actions.deleteClass(this.props.UserId, this.state.classId);
  };

  render() {
    return this.state.isAddNew ? this.renderClass() : this.renderClassTable();
  }

  closeAttributeGroupComponentDialog = () => {
    this.setState(() => ({
      isAttributeGroupOpen: false
    }));
  };

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addTemplateData.hasOwnProperty("name") ||
      (this.state.addTemplateData.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addTemplateData.hasOwnProperty("description") ||
      (this.state.addTemplateData.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  saveHandler = () => {
    this.state.mappingData.classes = this.state.classValue.id;
    this.state.mappingData.attribute_group = this.state.selectedUAIndexes;
    this.props.actions.mapClassUA(this.props.UserId, this.state.mappingData);
    console.log(this.state.mappingData);
  };
  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
    this.setState({
      addTemplateData: {
        ...this.state.addTemplateData,
        description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
      }
    });
  };

  renderClass = () => {
    const { classes } = this.props;
    const { name, description, is_active, image } = this.state.addTemplateData;
    const { required, editorState } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      className={classes.label + " "}
                      labelText="Notes"
                    >
                      Template Message
                    </InputLabel>
                    {/* <br /> */}
                    <div style={{ marginTop: "25px" }}>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapper"
                        editorClassName="editor"
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    </div>
                  </FormControl>
                  <br />
                  {this.props.Error && this.props.Error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button color="info" onClick={this.saveTemplate.bind(this)}>
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {this.props.isSaving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.isSaving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderClassTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.Loading}
              color="success"
              onClick={this.handelIsAddNew.bind(this)}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} /> New
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>Notification Template </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <Table
                  tableHead={["Name", "Status"]}
                  tableData={this.getTableData()}
                  tableShopping
                  customHeadCellClasses={[
                    classes.description,
                    classes.description,
                    classes.description,
                    classes.right,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                  customCellClasses={[
                    classes.customFont,
                    classes.tdName,
                    classes.customFont,
                    classes.tdNumber,
                    classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                    classes.tdNumber
                  ]}
                  customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                />
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  getTableData = () => {
    const { attributeGroups } = [];
    const { classData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(classData, ({ id, name, description, is_active }, index) => {
      returnData.push([
        <span>
          {
            //eslint-disable-next-line
                            <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
              {name}
            </a>
          }
        </span>,
        // <span>{description}</span>,
        <span>{is_active ? "Active" : "Inactive"}</span>,
        <span>
          <ReactTooltip id="editClass">
            <span>Edit template</span>
          </ReactTooltip>
          <Button
            color="success"
            data-tip
            data-for="editClass"
            className={classes.actionButton}
            onClick={() => this.handelEdit(index)}
          >
            <CreateIcon className={classes.icon} />
          </Button>

          <ReactTooltip id="deleteClass">
            <span>Delete Template</span>
          </ReactTooltip>
          <Button
            color="danger"
            data-tip
            data-for="deleteClass"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.deleteDialog(true, id)}
          >
            <ClearIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>
        </span>
      ]);
    });
    return returnData;
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.templateReducer.list,
  Loading: state.templateReducer.loading,
  ListError: state.templateReducer.error,
  isSaved: state.templateReducer.isSaved,
  isDeleted: state.templateReducer.isDeleted,
  UserId: state.authState.user.user,
  isSaving: state.classReducer.isSaving
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Templates);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
