import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import {Dialog, DialogContent, DialogContentText, DialogTitle, Slide} from "@material-ui/core";
import _ from "lodash";
import { LoadingOverlay } from "react-overlay-loader";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import CmsActionButton from "./Component/cmsActionButton.jsx";

import AddMenuForm from "./Component/AddMenuForm.jsx";
import SubHeader from "./Component/SubHeader.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.isHandleSave = this.isHandleSave.bind(this);
    this.closeHierarchyView = this.closeHierarchyView.bind(this);
    this.saveHierarchy = this.saveHierarchy.bind(this);
    this.state = {
      isHierarchyView: false,
      isAddNew: false,
      editData: null,
      list: [],
      editDataIndex: null,
      columnDefs: [
        {
          headerName: "Title",
          field: "name",
          width: 240,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "url",
          field: "url",
          width: 240,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Status",
          field: "status",
          width: 150,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },

        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ]
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        list: nextProps.List.data ? nextProps.List.data : []
      });
    }
  }

  componentDidMount() {
    this.props.actions.getCMSMenu(this.props.User.partner_id, "header");
  }

  render() {
    return(
      this.renderHeaderTable()
    )
  }

  isHandleSave = (obj, index) => {
    const { editDataIndex } = this.state;
    if (editDataIndex == null) {
      let menuList = _.clone(this.state.list);
      menuList.push(obj);
      this.props.actions.saveCMSMenu(
        menuList,
        this.props.User.partner_id,
        "header"
      );
    } else if (editDataIndex != null) {
      let menuList = _.clone(this.state.list);
      menuList[editDataIndex] = obj;
      this.props.actions.saveCMSMenu(
        menuList,
        this.props.User.partner_id,
        "header"
      );
    }
  };

  onHandleDelete = index => {
    let menuList = _.clone(this.state.list);
    menuList.splice(index, 1);
    this.props.actions.saveCMSMenu(
      menuList,
      this.props.User.partner_id,
      "header"
    );
  };

  handelIsAddNew = () => {
    this.setState({
      isAddNew: true,
      editDataIndex: null,
      editData: null
    });
  };

  handelIsAddNewCancle = () => {
    this.setState({
      isAddNew: false,
      editDataIndex: null
    });
  };

  handelEdit = index => {
    let data = this.state.list[index];
    this.setState({
      editData: data,
      editDataIndex: index,
      isAddNew: true
    });
  };

  onHandleHierarchy = index => {
    let data = this.state.list[index];
    this.setState({
      editData: data,
      editDataIndex: index,
      isHierarchyView: true
    });
  }

  closeHierarchyView = () => {
    this.setState({
      editData: null,
      editDataIndex: -1,
      isHierarchyView: false
    }, () => {
      this.props.actions.getCMSMenu(this.props.User.partner_id, "header");
    })
  }

  saveHierarchy = (data) => {
    this.isHandleSave(data)
    console.log('This is Save Hierarchy', data)
  }

  renderHeaderTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.addMenuDilog()}
        <Card>
          <CardHeader color="rose" icon>
            <div>
              <p className={classes.cardIconTitle + " grid-header-title"}>
                {this.state.isHierarchyView ? "Assign Classes to Sub Header" : `CMS Menu ${" "}`}
              </p>
            </div>
          </CardHeader>
          <CardBody>
            {this.state.isHierarchyView ?
              <SubHeader
                onclose={this.closeHierarchyView}
                onsave={this.saveHierarchy}
                data={this.state.editData}
              /> 
            :
              <AgGrid
                rowData={this.state.list}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                Loading={this.props.Loading}
                handelDelete={this.onHandleDelete}
                handelMap={this.onHandleHierarchy}
                AgGridActionButton={CmsActionButton}
                isAddBtn={true}
                new={this.handelIsAddNew}
              />
            }
          </CardBody>
        </Card>
      </GridContainer>
    );
  };

  addMenuDilog = () => {
    const { classes } = this.props;
    var title = "";
    const { editData } = this.state;
    if (this.state.productTemp != null) {
      title =
        this.state.productTemp.name +
        "(" +
        this.state.productTemp.description +
        ")";
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Dialog
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSignup
            }}
            open={this.state.isAddNew}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.handelIsAddNewCancle("Javascript ")}
            aria-labelledby="signup-modal-slide-title"
            aria-describedby="signup-modal-slide-description"
          >
            <DialogTitle
              id="signup-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <h4 style={{ width: "500px" }}>
                <b>Header Link</b>
              </h4>
            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classes.modalBody}
            >
              <LoadingOverlay>
                <DialogContentText>
                  <AddMenuForm
                    onClose={this.handelIsAddNewCancle}
                    onSave={this.isHandleSave}
                    formData={editData}
                  />
                </DialogContentText>
              </LoadingOverlay>
            </DialogContent>
          </Dialog>
        </GridItem>
      </GridContainer>
    );
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserId: state.authState.user.pk,
  User: state.authState.user,
  List: state.CMSContentReducer.cms_menu.list,
  Loading: state.CMSContentReducer.cms_menu.loading,
  Error: state.CMSContentReducer.cms_menu.error,
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.RETAILER ||
  rolesNames.RoleMaster.WHOLESALOR ||
  rolesNames.RoleMaster.BRAND ||
  rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Header);
