import * as EventServices from '../../services/eventServices'
import * as actionTypes from './actionTypes'


export const getEventTypeAction = (id = null, isActive = false) => async dispatch => {
    dispatch(getEventTypeRequest())
    try {
        const responsedata = await EventServices.getEventType(id, isActive)
        if (responsedata.status && responsedata.status === '1') {
            dispatch(getEventTypeSuccess(responsedata.data))
        } else {
            dispatch(getEventTypeFailure(responsedata.error))
        }
    } catch (error) {
        dispatch(getEventTypeFailure(error))
    }
}

const getEventTypeRequest = () => {
    return {
        type: actionTypes.EVENT_TYPE_REQUEST
    }
}

const getEventTypeFailure = (err) => {
    return {
        type: actionTypes.EVENT_TYPE_FAILURE,
        payload: err,
    }
}

const getEventTypeSuccess = (data) => {
    return {
        type: actionTypes.EVENT_TYPE_SUCCESS,
        payload: data,
    }
}

export const deleteEventTypeAction = (id) => async dispatch => {
    dispatch(deleteEventTypeRequest())
    try {
        const responsedata = await EventServices.deleteEventType(id)
        if (responsedata.status && responsedata.status === '1') {
            dispatch(deleteEventTypeSuccess(responsedata.data))
        } else {
            dispatch(deleteEventTypeFailure(responsedata.error))
        }
    } catch (error) {
        dispatch(deleteEventTypeFailure(error))
    }
}

const deleteEventTypeRequest = () => {
    return {
        type: actionTypes.DELETE_EVENT_TYPE_REQUEST
    }
}

const deleteEventTypeFailure = (err) => {
    return {
        type: actionTypes.DELETE_EVENT_TYPE_FAILURE,
        payload: err,
    }
}

const deleteEventTypeSuccess = (data) => {
    return {
        type: actionTypes.DELETE_EVENT_TYPE_SUCCESS,
        payload: data,
    }
}

export const saveEventTypeAction = (data, userid) => async dispatch => {
    dispatch(saveEventTypeRequest())
    try {
        const responsedata = await EventServices.saveEventType(data, userid)
        if (responsedata.status && responsedata.status === '1') {
            dispatch(saveEventTypeSuccess(responsedata.data))
        } else {
            dispatch(saveEventTypeFailure(responsedata.error))
        }
    } catch (error) {
        dispatch(saveEventTypeFailure(error))
    }
}

const saveEventTypeRequest = () => {
    return {
        type: actionTypes.SAVE_EVENT_TYPE_REQUEST
    }
}

const saveEventTypeFailure = (err) => {
    return {
        type: actionTypes.SAVE_EVENT_TYPE_FAILURE,
        payload: err,
    }
}

const saveEventTypeSuccess = (data) => {
    return {
        type: actionTypes.SAVE_EVENT_TYPE_SUCCESS,
        payload: data,
    }
}

export const getEventAction = (partnerid, id = null) => async dispatch => {
    dispatch(getEventRequest())
    try {
        const responsedata = await EventServices.getEvents(partnerid, id)
        if (responsedata.status && responsedata.status === '1') {
            dispatch(getEventSuccess(responsedata.data))
        } else {
            dispatch(getEventFailure(responsedata.error))
        }
    } catch (error) {
        dispatch(getEventFailure(error))
    }
}

const getEventRequest = () => {
    return {
        type: actionTypes.EVENT_REQUEST
    }
}

const getEventFailure = (err) => {
    return {
        type: actionTypes.EVENT_FAILURE,
        payload: err,
    }
}

const getEventSuccess = (data) => {
    return {
        type: actionTypes.EVENT_SUCCESS,
        payload: data,
    }
}

export const saveEventAction = (data, imagefiles, userid) => async dispatch => {
    dispatch(saveEventRequest())
    try {
        const responsedata = await EventServices.saveEvents(data, imagefiles, userid)
        if (responsedata.status && responsedata.status === '1') {
            dispatch(saveEventSuccess(responsedata.data))
        } else {
            dispatch(saveEventFailure(responsedata.error))
        }
    } catch (error) {
        dispatch(saveEventFailure(error))
    }
}

const saveEventRequest = () => {
    return {
        type: actionTypes.SAVE_EVENT_REQUEST
    }
}

const saveEventFailure = (err) => {
    return {
        type: actionTypes.SAVE_EVENT_FAILURE,
        payload: err,
    }
}

const saveEventSuccess = (data) => {
    return {
        type: actionTypes.SAVE_EVENT_SUCCESS,
        payload: data,
    }
}

export const deleteEventAction = (id) => async dispatch => {
    dispatch(deleteEventRequest())
    try {
        const responsedata = await EventServices.deleteEvent(id)
        if (responsedata.status && responsedata.status === '1') {
            dispatch(deleteEventSuccess(responsedata.data))
        } else {
            dispatch(deleteEventFailure(responsedata.error))
        }
    } catch (error) {
        dispatch(deleteEventFailure(error))
    }
}

const deleteEventRequest = () => {
    return {
        type: actionTypes.DELETE_EVENT_REQUEST
    }
}

const deleteEventFailure = (err) => {
    return {
        type: actionTypes.DELETE_EVENT_FAILURE,
        payload: err,
    }
}

const deleteEventSuccess = (data) => {
    return {
        type: actionTypes.DELETE_EVENT_SUCCESS,
        payload: data,
    }
}


export const getEventPartnersAction = (eventid, partnerid) => async dispatch => {
    dispatch(getEventPartnersRequest())
    try {
        const responsedata = await EventServices.getEventPartnerMapping(eventid, partnerid)
        if (responsedata.status && responsedata.status === '1') {
            dispatch(getEventPartnersSuccess(responsedata.data))
        } else {
            dispatch(getEventPartnersFailure(responsedata.error))
        }
    } catch (error) {
        dispatch(getEventPartnersFailure(error))
    }
}

const getEventPartnersRequest = () => {
    return {
        type: actionTypes.EVENT_PARTNER_MAPPING_REQUEST
    }
}

const getEventPartnersFailure = (err) => {
    return {
        type: actionTypes.EVENT_PARTNER_MAPPING_FAILURE,
        payload: err,
    }
}

const getEventPartnersSuccess = (data) => {
    return {
        type: actionTypes.EVENT_PARTNER_MAPPING_SUCCESS,
        payload: data,
    }
}

export const saveEventPartnerAction = (data, event, userid) => async dispatch => {
    dispatch(ssaveEventPartnerRequest())
    try {
        const responsedata = await EventServices.saveEventPartnerMapping(data, event, userid)
        if (responsedata.status && responsedata.status === '1') {
            dispatch(saveEventPartnerSuccess(responsedata.data))
        } else {
            dispatch(saveEventPartnerFailure(responsedata.error))
        }

    } catch (error) {
        dispatch(saveEventPartnerFailure(error))
    }
}

const ssaveEventPartnerRequest = () => {
    return {
        type: actionTypes.SAVE_EVENT_PARTNER_MAPPING_REQUEST
    }
}

const saveEventPartnerFailure = (err) => {
    return {
        type: actionTypes.SAVE_EVENT_PARTNER_MAPPING_FAILURE,
        payload: err,
    }
}

const saveEventPartnerSuccess = (data) => {
    return {
        type: actionTypes.SAVE_EVENT_PARTNER_MAPPING_SUCCESS,
        payload: data,
    }
}
