import React, { Component } from "react";
import Edit from "@material-ui/icons/Edit";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import List from "@material-ui/icons/List";
import moment from "moment";
function dateFormatter(params) {
  if (params) {
    return moment(params).format("MM/DD/YYYY");
  } else {
    return "";
  }
}
class ProductAttributeAction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  invokeParentMethod() {
    const { data } = this.props;
    const { is_Stock } = this.props.context.componentParent.props;
    return (
      <div
        style={{
          lineHeight: "60px"
        }}
      >
        {!is_Stock && this.props.context.componentParent.props.handelEdit && (
          <span onClick={() => this.performAction(data.id, 0)} title="Edit">
            <Edit className="grid_action_btn" />
          </span>
        )}
        {is_Stock &&
        this.props.context.componentParent.props.handelEdit &&
        dateFormatter(data.created_at) === moment().format("MM/DD/YYYY") ? (
          <span onClick={() => this.performAction(data.id, 0)} title="Edit">
            <Edit className="grid_action_btn" />
          </span>
        ) : (
          ""
        )}

        {this.props.context.componentParent.props.is_map && (
          <span title="Map" onClick={() => this.performAction(data.id, 2)}>
            <List className="grid_action_btn" />
          </span>
        )}
        {/* <span onClick={() => this.performAction(data.id, 1)} title="Delete" >
                    <DeleteIcon className="grid_action_btn" />
                </span> */}
        {this.props.context.componentParent.props.handelView && (
          <span
            span
            onClick={() => this.performAction(data.id, 3)}
            title="View Move Stock Entry"
          >
            <ViewHeadlineIcon />
          </span>
        )}
      </div>
    );
  }

  performAction(id, value) {
    switch (value) {
      case 0:
        this.props.context.componentParent.handelEdit(id);
        break;
      case 1:
        this.props.context.componentParent.delete(id);
        break;
      case 2:
        this.props.context.componentParent.map(id);
        break;
      case 3:
        this.props.context.componentParent.view(id);
        break;
    }
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default ProductAttributeAction;
