import * as partnerMenuService from "../../services/partnerMenuService";
import * as actionTypes from "./actionTypes";

export const partenrMenuList = (userId, type) => async dispatch => {
  dispatch(getPartnerMenuListRequest());
  try {
    var success = await partnerMenuService.getPartnerMenuList(userId, type);

    if (success.status && success.status === "1") {
      dispatch(getPartnerMenuListSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getPartnerMenuListFailure(success.error));
    } else {
      dispatch(getPartnerMenuListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getPartnerMenuListFailure(error.message));
  }
};

const getPartnerMenuListRequest = () => {
  return {
    type: actionTypes.GET_PARTNER_MENU_LIST_REQUEST
  };
};

const getPartnerMenuListSuccess = data => {
  return {
    type: actionTypes.GET_PARTNER_MENU_LIST_SUCCESS,
    payload: data
  };
};

const getPartnerMenuListFailure = error => {
  return {
    type: actionTypes.GET_PARTNER_MENU_LIST_FAILURE,
    payload: error
  };
};

export const CMSMenuList = (userId, type, isOldCMSList) => async dispatch => {
  dispatch(getCMSMenuListRequest());
  try {
    var success = await partnerMenuService.getPartnerMenuList(
      userId,
      type,
      isOldCMSList
    );

    if (success.status && success.status === "1") {
      dispatch(getCMSMenuListSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getCMSMenuListFailure(success.error));
    } else {
      dispatch(getCMSMenuListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getCMSMenuListFailure(error.message));
  }
};

const getCMSMenuListRequest = () => {
  return {
    type: actionTypes.GET_CMS_MENU_LIST_REQUEST
  };
};

const getCMSMenuListSuccess = data => {
  return {
    type: actionTypes.GET_CMS_MENU_LIST_SUCCESS,
    payload: data
  };
};

const getCMSMenuListFailure = error => {
  return {
    type: actionTypes.GET_CMS_MENU_LIST_FAILURE,
    payload: error
  };
};
