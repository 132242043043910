import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Person from "@material-ui/icons/Person";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import Table from "components/Table/Table.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import ReactTooltip from "react-tooltip";
import { validationUtils } from "../../utils";
import List from "@material-ui/icons/List";
import * as routesNames from "../../constants/routes";
import ChangePassword from "../UserAuth/ChangePassword.jsx";
// import { rolesNames } from "../../constants"

class CreateBrand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      selectedAPIKey: null,
      isEdit: false,
      editIndex: -1,
      file: null,
      changepasswordmodal: false,
      partnerId: null,
      brand: {
        api_key: null,
        name: "",
        userName: "",
        image: "",
        file: null,
        businessURL: "",
        role: rolesNames.RoleMaster.BRAND
      },
      required: {
        api_key: "",
        name: "",
        userName: "",
        image: "",
        file: null,
        businessURL: ""
      },
      tableData: [],
      error: ""
    };
  }

  componentDidMount() {
    if (this.props.List && this.props.List.length > 0 && this.props.List) {
      const brandData = this.props.List;
      var result = Object.keys(brandData).map(function(key) {
        return brandData[key];
      });
      this.setState(() => ({
        tableData: result
      }));
    } else {
    }
    this.props.actions.getBrand(rolesNames.RoleMaster.BRAND);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        nextProps.List &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        this.setState(() => ({
          tableData: nextProps.List
        }));
      }
      if (nextProps.ListError && nextProps.ListError.trim() !== "") {
        this.setState(() => ({
          error: nextProps.ListError,
          isAddNew: true
        }));
      }
      if (nextProps.passwordChange) {
        this.setState(() => ({
          changepasswordmodal: false
        }));
      }
      if (nextProps.isSaved) {
        this.setState(() => ({
          isAddNew: false,
          isEdit: false
        }));
      }
    }
  }

  editProductTemplateMapping = brandid => {
    let name = "";
    for (let i = 0; i < this.state.tableData.length; i++) {
      if (brandid === this.state.tableData[i].id) {
        name = this.state.tableData[i]["name"];
      }
    }
    window.sessionStorage.setItem("Brand_Name", name);
    this.props.actions.onNavigateTo(
      routesNames.PRODUCTTEMPLATECOMPONENT + "?entityType=brand&id=" + brandid
    );
  };

  getTableData = () => {
    const { tableData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(tableData, ({ id, api_key, name, user, data, deploy_url }, index) => {
      returnData.push([
        <span>
          <div className={classes.imgContainer} style={{ width: "80px" }}>
            <img
              src={data.image ? data.image.secure_url : ""}
              alt="..."
              className={classes.img}
              style={{ height: "70px", width: "auto" }}
            />
          </div>
        </span>,
        <span>{name}</span>,
        <span>{user.email}</span>,
        <span>{deploy_url} </span>,
        <span>
          <ReactTooltip id="impersonate">
            <span>Log in as Brand</span>
          </ReactTooltip>

          <Button
            data-tip
            data-for="impersonate"
            color="primary"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.signinUser(user.email)}
          >
            <Person className={classes.icons} style={{ marginRight: "0px" }} />
          </Button>

          <ReactTooltip id="mapProductTemplate">
            <span>View or edit mapping with Product Templates</span>
          </ReactTooltip>

          <Button
            data-tip
            data-for="mapProductTemplate"
            color="primary"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.editProductTemplateMapping(id)}
          >
            <List className={classes.icons} style={{ marginRight: "0px" }} />
          </Button>

          <Button
            color="success"
            data-tip
            data-for="viewBrand"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={this.handelEdit(index)}
          >
            <CreateIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>
          <ReactTooltip id="viewBrand">
            <span>View or edit Brand details</span>
          </ReactTooltip>
          <ReactTooltip id="changepassword">
            <span>Change Password</span>
          </ReactTooltip>
          <Button
            color="primary"
            data-tip
            data-for="changepassword"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={this.handelPasswordChange(index).bind(this)}
          >
            <VpnKeyOutlinedIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>

          <Button
            color="danger"
            data-tip
            data-for="deleteBrand"
            className={classes.marginRight}
            style={{ padding: "5px" }}
          >
            <ClearIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>
          <ReactTooltip id="deleteBrand">
            <span>Delete Brand</span>
          </ReactTooltip>
        </span>
      ]);
    });
    return returnData;
  };

  handelIsAddNew = isAddNew => event => {
    this.setStateAddNew(isAddNew);
  };
  setStateAddNew(isAddNew) {
    this.setState({
      isAddNew: isAddNew,
      isEdit: false,
      editIndex: -1,
      brand: {
        api_key: null,
        name: "",
        userName: "",
        role: rolesNames.RoleMaster.BRAND
      },
      error: "",
      required: {
        api_key: "",
        name: "",
        userName: ""
      }
    });
  }

  signinUser = partner_email_id => {
    this.props.actions.signInUser(
      partner_email_id,
      "",
      true,
      this.props.authUser.user
    );
  };

  handelPasswordChange = index => event => {
    this.setState({
      changepasswordmodal: true,
      partnerId: this.state.tableData[index].id,
      selectedAPIKey: this.state.tableData[index].api_key
    });
  };

  changePasswordModelClose = () => {
    this.setState({
      partnerId: null,
      changepasswordmodal: false
    });
  };

  handelEdit = index => event => {
    var editData = this.state.tableData[index];
    var data = {
      api_key: editData.api_key,
      name: editData.name,
      userName: editData.user.email,
      image: editData.data.image,
      businessURL: editData.deploy_url,
      role: rolesNames.RoleMaster.BRAND
    };
    this.setState({
      brand: data,
      isEdit: true,
      editIndex: index
    });
  };

  renderStaffTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.state.changepasswordmodal && (
          <ChangePassword
            open={this.state.changepasswordmodal}
            userid={this.state.partnerId}
            onClose={this.changePasswordModelClose}
            isAdminChangingPartnerPassword={true}
            api_key_partner={this.state.selectedAPIKey}
          />
        )}
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.Loading}
              color="success"
              onClick={this.handelIsAddNew(true)}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} /> Create Brand
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>
                View, edit or delete brands below{" "}
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <Table
                  tableHead={[
                    "#",
                    "Brand Name",
                    "User Name",
                    "Actions",
                    "deploy_url"
                  ]}
                  tableData={this.getTableData()}
                  tableShopping
                  customHeadCellClasses={[
                    classes.description,
                    classes.description,
                    classes.description,
                    classes.right,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                  customCellClasses={[
                    classes.tdName,
                    classes.customFont,
                    classes.customFont,
                    classes.tdNumber,
                    classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                    classes.tdNumber
                  ]}
                  customClassesForCells={[1, 2, 3, 4, 5, 6]}
                />
                {(this.props.Loading || this.props.PartnerSaving) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    var brand = this.state.brand;
    brand[key] = event.target.value;
    this.setState({ brand: brand });
  };

  renderAddStaffMember = () => {
    const { classes } = this.props;
    const { api_key, name, userName, image, businessURL } = this.state.brand;
    const { isEdit, required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>language</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Fill in fields below, then click Create Brand button to spawn
                new brand.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    labelText="Partner Number (not editable)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: api_key,
                      onChange: this.handleTextChange("api_key", ""),
                      disabled: true
                    }}
                    id={"api_key"}
                  />
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Brand Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name"}
                  />
                  <CustomInput
                    success={required.userName === "success"}
                    error={required.userName === "error"}
                    labelText="User Name (must be an email address) *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: userName,
                      onChange: this.handleTextChange(
                        "userName",
                        validationUtils.validationTypes.email
                      )
                    }}
                    id={"userName"}
                  />
                  <CustomInput
                    success={required.businessURL === "success"}
                    error={required.businessURL === "error"}
                    labelText="Business URL *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: businessURL,
                      onChange: this.handleTextChange(
                        "businessURL",
                        validationUtils.validationTypes.url
                      )
                    }}
                    id={"businessURL_"}
                  />
                  <br />
                  <br />
                  <br />
                  <legend>
                    {" "}
                    Upload Logo&nbsp;
                    <span style={{ color: "#AAAAAA", fontSize: "13px" }}>
                      (Optimal logo size to upload should be 540x240 at 72 dpi)
                    </span>
                  </legend>
                  <ImageUpload
                    addButtonProps={{
                      round: true
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true
                    }}
                    imgUrl={image ? image.secure_url : null}
                    onimageRemove={this.handelImageRemove}
                    onChange={this.handelImageChange}
                  />

                  <br />
                  {this.state.error && this.state.error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}
                  <br />
                  <br />
                  {isEdit ? (
                    <Button color="info" onClick={this.updatebrand}>
                      Update Brand
                    </Button>
                  ) : (
                    <Button color="info" onClick={this.saveReatiler}>
                      Create Brand
                    </Button>
                  )}
                  <Button onClick={this.handelIsAddNew(false)}>Cancel</Button>
                  {this.props.Loading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  handelImageChange = file => {
    if (file instanceof Blob) {
      let reader = new FileReader();
      let { brand } = this.state;
      let original_name = file.name;
      let fileSize = file.size;
      reader.onloadend = () => {
        brand["image"] = reader.result;
        brand["file_size"] = fileSize;
        brand["original_file_name"] = original_name;
        this.setState({
          brand: brand
        });
      };
      reader.readAsDataURL(file);
    } else {
      let { brand } = this.state;
      brand.image = file[0].document_object;
      brand.file_size = file[0].file_size;
      brand.original_file_name = file[0].original_file_name;
      this.setState({
        ...this.state,
        brand: brand
      });
    }
  };

  handelImageRemove = () => {
    let { brand } = this.state;
    delete brand.imagePreviewUrl;
    this.setState({
      brand: brand
    });
  };

  saveReatiler = () => {
    if (this.validateIsRequired()) {
      var brand = this.state.brand;
      this.props.actions.createBrand(brand);
    }
  };

  updatebrand = () => {
    if (this.validateIsRequired()) {
      const { brand } = this.state;
      if (brand) {
        this.props.actions.createBrand(brand);
      }
    }
  };

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.brand.hasOwnProperty("name") ||
      (this.state.brand.name.trim() === "" || reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.brand.hasOwnProperty("userName") ||
      (this.state.brand.userName.trim() === "" ||
        reqData["userName"] === "error")
    ) {
      reqData["userName"] = "error";
      isValid = false;
    }
    if (
      !this.state.brand.hasOwnProperty("businessURL") ||
      (this.state.brand.businessURL.trim() === "" ||
        reqData["businessURL"] === "error")
    ) {
      reqData["businessURL"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  render() {
    return this.state.isAddNew || this.state.isEdit
      ? this.renderAddStaffMember()
      : this.renderStaffTable();
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.brandState.list,
  Loading: state.brandState.loading,
  ListError: state.brandState.error,
  authUser: state.authState.user,
  isSaved: state.brandState.isSaved,
  passwordChange: state.authState.passwordchangepending
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateBrand);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
