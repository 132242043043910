export const USER_BASED_MENU_LIST_REQUEST = "user_based_menu_list_request";
export const USER_BASED_MENU_LIST_FAILURE = "user_based_menu_list_failure";
export const USER_BASED_MENU_LIST_SUCCESS = "user_based_menu_list_success";

export const MENU_LIST_REQUEST = "menu_list_request";
export const MENU_LIST_FAILURE = "menu_list_failure";
export const MENU_LIST_SUCCESS = "menu_list_success";

export const PACKAGE_MENU_LIST_REQUEST = "package_menu_list_request";
export const PACKAGE_MENU_LIST_FAILURE = "package_menu_list_failure";
export const PACKAGE_MENU_LIST_SUCCESS = "package_menu_list_success";

export const IMAGE_REMOVE_REQUEST = "image_remove_request";
export const DENY_IMAGE_REMOVE_REQUEST = "deny_image_remove_request";

export const CONFIRM_IMAGE_REMOVE_REQUEST = "confirm_image_remove_request";
export const CONFIRM_IMAGE_REMOVE_FAILURE = "confirm_image_remove_failure";
export const CONFIRM_IMAGE_REMOVE_SUCCESS = "confirm_image_remove_success";
