import * as changePasswordService from "../../services/changePasswordService";
import * as actionTypes from "./actionTypes";
import * as navigationActions from "./navigationActions";
import { routesNames, rolesNames } from "../../constants";
import { commonUtils } from "../../utils";

export const savePassword = (
  id,
  password,
  api_key,
  loginuserid = null,
  isAdminChangingPartnerPassword = false,
  isReset
) => async dispatch => {
  // dispatch(getClassRequest());
  dispatch(passwordrequest());
  try {
    if (loginuserid === null) {
      loginuserid = id;
    }
    const user = await changePasswordService.savePassword(
      id,
      password,
      api_key,
      loginuserid
    );
    if (user && user.status === "1" && !isReset) {
      var userData = {
        Token: user.data.api_key,
        id: user.data.api_key,
        name: user.data.email,
        user: user.data.email,
        pk: user.data.id,
        status: user.data.status,
        folderId:
          user.data.folder && user.data.folder.id ? user.data.folder.id : ""
      };
      if (user.data.is_superuser) {
        userData["role"] = rolesNames.RoleMaster.ADMIN;
        userData["partner_id"] = "";
      } else {
        userData["role"] = user.partner.role;
        userData["partner_id"] = user.partner.id;
      }
      if (!isAdminChangingPartnerPassword) {
        dispatch(setUserAuth(userData));
        dispatch(
          navigationActions.onNavigateTo(
            commonUtils.getLandingRoute(userData.role)
          )
        );
      } else {
        dispatch(passwordsuccess());
      }
    } else if (user && user.status === "1" && isReset) {
      dispatch(setUserAuth(null));
      dispatch(navigationActions.onNavigateTo(routesNames.LOGIN));
    } else if (user && user.status === "0") {
      dispatch(passwordfailure(user.error));
    }
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

export const sendForgotlink = email => async dispatch => {
  dispatch(sendForgotpasswordlinkRequest());
  try {
    const user = await changePasswordService.sendForgotPasswordLink(email);
    if (user && user.status === "1") {
      dispatch(sendForgotpasswordlinkSuccess(user));
    } else {
    }
  } catch (error) {
    dispatch(sendForgotpasswordlinkFailure(error.message));
  }
};

const sendForgotpasswordlinkRequest = () => {
  return {
    type: actionTypes.SEND_FORGOT_PASSWORD_REQUEST
  };
};

const sendForgotpasswordlinkSuccess = data => {
  return {
    type: actionTypes.SEND_FORGOT_PASSWORD_SUCCESS,
    payload: data
  };
};

const sendForgotpasswordlinkFailure = error => {
  return {
    type: actionTypes.SEND_FORGOT_PASSWORD_FAILURE,
    payload: error
  };
};

const setUserAuth = user => {
  return {
    type: actionTypes.SET_USER_AUTH,
    payload: user
  };
};
const passwordrequest = () => {
  return {
    type: actionTypes.PASSWORD_CHANGE_REQUEST
  };
};
const passwordsuccess = () => {
  return {
    type: actionTypes.PASSWORD_CHANGE_SUCCESS
  };
};
const passwordfailure = error => {
  return {
    type: actionTypes.PASSWORD_CHANGE_FAILURE,
    payload: error
  };
};
const setInvalidCredentials = msg => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    payload: msg
  };
};
const loginFailure = error => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    payload: error
  };
};
