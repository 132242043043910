// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const createServices = async (userId, Savedata, PartnerId) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("partnerid", PartnerId);
    data.append("file", Savedata.image);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("api/service/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getServicesList = async (isHierarchy, page, search) => {
  try {
    let url = "/api/service/list/?isHierarchy=" + isHierarchy + "&page=" + page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const assignedServicer = async id => {
  try {
    const response = await axios.get(
      "/api/partnerservice/assignedlist/?id=" + id
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getServiceDetaileById = async id => {
  try {
    const response = await axios.get("/api/service/get/?serviceid=" + id);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveServicePartnerMapping = async (
  selectedService,
  PartnerId,
  userId
) => {
  try {
    let data = new FormData();
    data.append("services", JSON.stringify(selectedService));
    data.append("partnerid", PartnerId);
    data.append("userId", userId);

    const response = await axios.post("api/partnerservice/mapping/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
