import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormLabel from "@material-ui/core/FormLabel";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import * as routesNames from "../../constants/routes";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

class AddProductInBrand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      productTemplates: null,
      assignedTemplates: [],
      checkedTemplates: []
    };
  }

  componentDidMount() {
    if (this.props.UserId.role === rolesNames.RoleMaster.BRAND) {
      this.props.actions.onNavigateTo(
        routesNames.PRODUCTTEMPLATECOMPONENT +
          "?entityType=brand&id=" +
          this.props.partner_id
      );
      return;
    } else {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let entityType = params.get("switch");
      if (entityType == "BR") {
        this.props.actions.onNavigateTo(
          routesNames.BRANDCOMPONENT +
            "?entityType=wholeSaler&id=" +
            this.props.partner_id
        );
        return;
      } else if (entityType == "PT") {
        this.props.actions.onNavigateTo(
          routesNames.PRODUCTTEMPLATESWHOLESALESALERCOMPONENT +
            "?entityType=wholeSaler&id=" +
            this.props.partner_id
        );
        return;
      } else {
        this.props.actions.onNavigateTo(
          routesNames.BRANDCOMPONENT +
            "?entityType=wholeSaler&id=" +
            this.props.partner_id
        );
        return;
      }
    }

    if (this.props.productTemplates && this.props.productTemplates.length > 0) {
      this.setState({
        productTemplates: this.props.productTemplates
      });
    } else {
      this.props.actions.getAllProductTemplates(this.props.partner_id);
    }
    if (
      this.props.selectedTemplates &&
      this.props.selectedTemplates.length > 0
    ) {
      this.setState({
        assignedTemplates: this.props.selectedTemplates
      });
    } else {
      this.props.actions.getAssignedProductTemplates({
        api_key: this.props.api_key
      });
    }
  }

  saveBrandProductTtemplates = () => {
    let { checkedTemplates } = this.state;
    var templateData = [];
    for (var i = 0; i < checkedTemplates.length; i++) {
      var data = {
        product_template: checkedTemplates[i]
      };
      templateData.push(data);
    }
    this.props.actions.saveBrandProductTemplates(
      this.props.Userid,
      this.props.UserKey,
      templateData
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.productTemplates &&
        nextProps.productTemplates.length > 0 &&
        !_.isEqual(this.props.productTemplates, nextProps.productTemplates)
      ) {
        this.setState({
          productTemplates: nextProps.productTemplates
        });
      }
      if (
        nextProps.selectedTemplates &&
        nextProps.selectedTemplates.length > 0 &&
        !_.isEqual(this.props.selectedTemplates, nextProps.selectedTemplates)
      ) {
        this.handelAssignedTemplates(nextProps.selectedTemplates);
      }
      if (
        !nextProps.isSaving &&
        this.props.isSaving &&
        nextProps.error === null
      ) {
        this.setState({
          isEdit: false
        });
      }
    }
  }

  handelAssignedTemplates = selectedTemplates => {
    var checkedTemplates = [];
    selectedTemplates.forEach(function(item) {
      checkedTemplates.push(item.product_template.id);
    });
    this.setState({
      assignedTemplates: selectedTemplates,
      checkedTemplates: checkedTemplates
    });
  };

  handleToggle(value) {
    const { checkedTemplates } = this.state;
    const currentIndex = checkedTemplates.indexOf(value);
    const newChecked = [...checkedTemplates];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checkedTemplates: newChecked
    });
  }

  getTableData = () => {
    const { productTemplates, checkedTemplates } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(productTemplates, ({ id, name, description }, index) => {
      returnData.push([
        <Checkbox
          tabIndex={-1}
          checked={checkedTemplates.indexOf(id) > -1}
          onClick={() => this.handleToggle(id)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot
          }}
        />,
        <span>
          {
            //eslint-disable-next-line
                            <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
              {name}
            </a>
          }
        </span>,
        <span>{description}</span>,
        <Button simple className={classes.actionButton}>
          <Close className={classes.icon} />
        </Button>
      ]);
    });
    return returnData;
  };
  getAssignedTableData = () => {
    const { productTemplates } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(productTemplates, ({ id, name, description }, index) => {
      returnData.push([
        <span>
          {
            //eslint-disable-next-line
                            <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
              {name}
            </a>
          }
        </span>,
        <span>{description}</span>,
        <Button simple className={classes.actionButton}>
          <Close className={classes.icon} />
        </Button>
      ]);
    });
    return returnData;
  };
  handleEdit = () => {
    this.setState({
      isEdit: true
    });
  };

  renderSelectedProductTemplate = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.loadingGetData}
              color="success"
              onClick={this.handleEdit}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} /> Product Template
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>Product Templates</p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <Table
                  tableHead={["Name", "Description", ""]}
                  tableData={this.getAssignedTableData()}
                  tableShopping
                  customHeadCellClasses={[
                    classes.description,
                    classes.description,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                  customCellClasses={[
                    classes.customFont,
                    classes.customFont,
                    classes.customFont,
                    classes.tdNumber + " " + classes.tdNumberAndButtonGroup
                  ]}
                  customClassesForCells={[1, 2, 3, 4, 5, 6]}
                />
                {(this.props.Loading || this.props.LoadingSelected) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader
                  loading={this.props.Loading || this.props.LoadingSelected}
                  text="Loading.."
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
  handelCancel = () => {
    this.handelAssignedTemplates(this.state.assignedTemplates);
    this.setState({
      isEdit: false
    });
  };
  renderAddProductTemplate = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <Table
                    tableHead={["", "Name", "Description", ""]}
                    tableData={this.getTableData()}
                    tableShopping
                    customHeadCellClasses={[
                      classes.description,
                      classes.description,
                      classes.right
                    ]}
                    customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                    customCellClasses={[
                      classes.customFont,
                      classes.customFont,
                      classes.customFont,
                      classes.tdNumber + " " + classes.tdNumberAndButtonGroup
                    ]}
                    customClassesForCells={[1, 2, 3, 4, 5, 6]}
                  />
                  <br />
                  <Button
                    color="info"
                    onClick={this.saveBrandProductTtemplates}
                  >
                    Save Changes
                  </Button>
                  <Button onClick={this.handelCancel}>Cancel</Button>
                  <br />
                  {(this.props.Loading || this.props.isSaving) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Loading.." />
                  <Loader loading={this.props.isSaving} text="Saving.." />
                  {this.props.error && this.props.error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.props.error}</code>
                    </FormLabel>
                  )}
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    return this.state.isEdit
      ? this.renderAddProductTemplate()
      : this.renderSelectedProductTemplate();
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  productTemplates: state.brandState.productTemplate.products,
  Loading: state.brandState.productTemplate.isLoading,
  error: state.brandState.error,
  selectedTemplates: state.brandState.selectedProdcutTemplates.products,
  isSaving: state.brandState.selectedProdcutTemplates.isSaving,
  LoadingSelected: state.brandState.selectedProdcutTemplates.isLoading,
  UserKey: state.authState.user.user,
  Userid: state.authState.user.pk,
  partner_id: state.authState.user.partner_id,
  UserId: state.authState.user
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.BRAND ||
  rolesNames.RoleMaster.WHOLESALER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...customInputStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddProductInBrand);
