import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

import { FormLabel, Select, MenuItem, Grid, Button } from "@material-ui/core";

import Swal from "sweetalert2";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import ProductVariantView from "../ProductVariantGroup/product-variant-group-view";
import { ReportPartnerRoles } from "../../constants/roles";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import ProductVariantAction from "../../components/AgGrid/productVariantAction";

class RetailerProductReport extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        searchtext: null,
        partner: "",
        partner_type: "RE",
        file_name:null,
        pagination: {
          page: "1",
          totalpage: "1",
          next: false,
          prev: false
        },
        columnDefs: [
          {
            headerName: "Report Options",
            field: "name",
            width: 200,
            resizable: true,
            sortable: true,
            checkboxSelection: true,
            // filter: "agTextColumnFilter"
          }
        ],
        error: null,
        option_list:[]
      }
      this.eventRefs = React.createRef()
    }
    componentDidMount() {
      if (this.props.authUser.role == rolesNames.RoleMaster.ADMIN) {
        
        this.refreshPartner();
        this.refreshList()
      }
      else {
        // this.setState({
        //   partner_type: "RE"
        // }, () => {
        //   this.refreshList();
        // })
      }
    }
    componentWillReceiveProps(nextProps) {

      if (nextProps && !_.isEqual(nextProps, this.props)) {
        console.log(nextProps.report_option)
        if (nextProps.report_option) {

          this.setState({
            option_list: nextProps.report_option,
          });
        }
        if(nextProps.file_data && !_.isEqual(nextProps.file_data, this.props.file_data)){
          
          var report_file = document.createElement('a');
          report_file.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(nextProps.file_data));
          report_file.setAttribute('download', this.state.file_name+'-report.csv');
          report_file.click()
        }
        let error = "";
        if (nextProps.file_error) {
          error = nextProps.file_error;
        }
        this.setState({
          error: error
        });
      }
    }
    refreshPartner = () => {
      this.props.actions.getBrand(this.state.partner_type, true);
    }
    searchText = (param) => {
      this.setState({
        searchtext: param,
        pagination: {
          ...this.state.pagination,
          page: 1,
        }
      }, () => {
        this.refreshList();
      });
    }
    refreshList = () => {
      
      if (this.state.partner_type)
      this.props.actions.partnerReportOptionAction(
        this.state.partner_type,
      );
    }


    updateGridData = (page) => {
      this.setState({
        pagination: {
          ...this.state.pagination,
          page: page
        }
      }, () => {
        this.refreshList();
      })
    }

    handlePartnerChange = (key, type) => event => {
      this.setState({
        partner: event.target.value
      }, () => {
        this.refreshList();
      })
    };

    handelExport = () => {
    let data = this.eventRefs.current.agGridApi.getSelectedRows();
    if(this.state.partner && data && data[0] && data[0].type){
      this.props.actions.downloadProductReportAction(data[0].type,this.state.partner)
      this.setState({
        error: null,
        file_name: data[0].type
      })
      }
    else{
      this.setState({
        error:"Please select proper options"
      })
      console.log('Please select proper options') 
    }
    };

    renderProducts = () => {
      const { classes } = this.props;
      return (
        <Card>
          <CardHeader color="rose" icon>
  
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="start">
                  <div>
                    <p className={classes.cardIconTitle + " grid-header-title"}>
                      Export Partner report.
                        </p>
                  </div>
                </Grid>
              </Grid>
  
            </Grid>
          </CardHeader>
          <CardBody>
  
            <AgGrid
              ref={this.eventRefs}
              rowData={this.state.option_list}
              columnDefs={this.state.columnDefs}
              loading={this.props.loading}
              rowSelection="single"
              update={this.updateGridData}
              isServerPagination={false}
              pagination={this.state.pagination}
              loading={this.props.loading}
              search={this.searchText}
              isExportBtn={true}
              export={this.handelExport}
            />
  
            {this.props.deleteError && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code>{this.props.deleteError}</code>
              </FormLabel>
            )}
  
          </CardBody>
  
        </Card>
      )
    }

    handleRoleChange = (key, type) => event => {
      
      this.setState({
        partner_type: event.target.value,
        partner: '',
      }, () => {
        this.refreshPartner()
        this.refreshList()
      })
    };

    render(){
      const { classes } = this.props;
return( <GridContainer>
  <GridItem xs={12}>
    <Card>
      <CardHeader color="rose" icon>

        {
          this.props.authUser.role == rolesNames.RoleMaster.ADMIN &&

          <Grid container className={classes.root} spacing={2}>
            <Card>
              <CardBody>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                  <Grid item xs={4}>
                  <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Select a Partner Type
                    </MenuItem>
                    <Select
                      variant="outlined"
                      style={{ width: '100%' }}
                      value={this.state.partner_type ? this.state.partner_type : 'BR'}
                      classes={{
                        root: "select-control-root"
                      }}
                      onChange={this.handleRoleChange("role", "")}
                      disabled={this.state.isAddNew || this.state.isMapping}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Partner Type *
          </MenuItem>
                      {_.map(ReportPartnerRoles, ({ key, name }, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={key}
                            key={index + "_" + key}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                    <Grid item xs={4}>
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Select a Partner
                    </MenuItem>
                      <Select
                        style={{ width: '100%' }}
                        variant="outlined"
                        value={this.state.partner ? this.state.partner : null}
                        classes={{
                          root: "select-control-root"
                        }}
                        onChange={this.handlePartnerChange("role", "")}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Partner *
                        </MenuItem>
                        {_.map(this.props.Retailer, ({ id, name }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                              key={index + "_" + id}
                            >
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={4}>

                    </Grid>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>

        }
      {this.renderProducts()}
      </CardHeader>
      </Card>
      {this.state.error && this.state.error.trim() !== "" && (
        <FormLabel className={classes.labelLeftHorizontal}>
          <code>{this.state.error}</code>
        </FormLabel>
      )}
      </GridItem>
      </GridContainer>
      )
    }

}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
  });
  
  const mapStateToProps = state => ({
    authUser: state.authState.user,
    UserId: state.authState.user.user,
    PartnerId: state.authState.user.partner_id,
    Retailer: state.brandState.list,
    assignProductList: state.productsAssignmentsState.list,
    loading: state.productsAssignmentsState.loading,
    pagination: state.productsAssignmentsState.pagination,
    file_data: state.reportReducer.download_report.data,
    file_error: state.reportReducer.download_report.error,
    report_option : state.reportReducer.options_report.data,
  });
  
  const condition = authUser =>
    authUser.role === rolesNames.RoleMaster.ADMIN || authUser.role === rolesNames.RoleMaster.RETAILER || authUser.role === rolesNames.RoleMaster.BRAND
  
  export default compose(
    withAuthorization(condition),
    withStyles({
      ...regularFormsStyle,
      ...extendedTablesStyle,
      ...validationFormsStyle
    }),
    withRouter,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(RetailerProductReport);