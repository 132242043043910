// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const getPartnerMenuList = async (userId, type, isOldCMSList) => {
  try {
    let _isOldCMSList = true;
    if (isOldCMSList) {
      _isOldCMSList = isOldCMSList;
    }
    const response = await axios.get(
      "/api/retailer/menu/list/?retailer=" +
        userId +
        "&type=" +
        type +
        "&isOldCMSList=" +
        isOldCMSList
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
