// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const getLanguages = async (id, partnerId, page, onlyActive, search) => {
  return new Promise((resolve, reject) => {
    try {
      let fetchUrl = "/mgmt/languages/?1=1";

      if (id) {
        fetchUrl = fetchUrl + "&id=" + id;
      }

      if (partnerId) {
        fetchUrl = fetchUrl + "&partner_id=" + partnerId;
      }

      if (page) {
        fetchUrl = fetchUrl + "&page=" + page;
      }

      if (onlyActive) {
        fetchUrl = fetchUrl + "&is_active=1";
      }

      if (search) {
        fetchUrl = fetchUrl + "&search=" + search;
      }

      axios.get(fetchUrl).then(function(response) {
        if (response.data) {
          resolve(response.data);
        } else {
          reject("An error with get method");
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};
