import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
// core components
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import InputLabel from "@material-ui/core/InputLabel";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineRounded from "@material-ui/icons/HelpOutlineRounded";
import "antd/dist/antd.css";
import { Select } from "antd";
const { Option } = Select;

class ProductsOtherDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      offerlist: [],
      classlist: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (!_.isEqual(this.props.data, nextProps.data)) {
          this.setState({
            data: nextProps.data
          });
        }
        if (!_.isEqual(this.props.OfferList, nextProps.OfferList)) {
          this.setState({
            offerlist: nextProps.OfferList
          });
        }
      }
    }
  }

  componentDidMount() {
    this.props.actions.getOffersList(this.props.PartnerId);
    const { data } = this.props;
    if (data) {
      const content = htmlToDraft(data.disclaimer);
      const contentState = ContentState.createFromBlockArray(
        content.contentBlocks,
        content.entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        data: data,
        editorState: editorState
      });
    }
  }

  render() {
    const { editorState } = this.state;
    const { classes } = this.props;
    const { offerlist } = this.state;
    const { offers, disclaimer } = this.state.data;
    return (
      <Grid>
        <GridContainer>
          <GridItem lg={1} md={1} />
          <GridItem lg={10} md={10} sm={12} xs={12}>
            <form>
              <GridContainer className="grid-container-control">
                <GridItem lg={12} md={12} className="griditem-control">
                  <InputLabel
                    className={classes.label + " "}
                    labelText="Offers"
                  >
                    Offers
                    <Tooltip
                      className="control-tooltip"
                      title="Please select Offers"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>
                  <br />
                  <Select
                    mode="multiple"
                    size="default"
                    value={offers}
                    placeholder="Please select Offers"
                    onChange={this.handleChange}
                    style={{ width: "100%" }}
                  >
                    {_.map(offerlist, (model, index) => (
                      <Option key={index} value={model.id}>
                        {model.name}
                      </Option>
                    ))}
                  </Select>
                </GridItem>
                <GridItem lg={12} md={12} className="griditem-control">
                  <InputLabel
                    className={classes.label + " "}
                    labelText="Disclaimer"
                  >
                    Disclaimer
                    <Tooltip
                      className="control-tooltip"
                      title="Add Disclaimer for product"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>
                  <br />
                  <Editor
                    readOnly={this.props.editState ? true : false}
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapper"
                    editorClassName="editor"
                    onEditorStateChange={this.onEditorStateChange}
                  />
                  {/* {this.props.nameError && <h7 style={{ "color": "red" }}>{this.props.nameError}</h7>} */}
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
          <GridItem lg={1} md={1} />
        </GridContainer>
      </Grid>
    );
  }

  handleChange(value) {
    this.props.setProductData("offers", value);
    console.log(`Selected: ${value}`);
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.setProductData("disclaimer", value);
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  OfferList: state.offersReducer.get_offers.list,
  PartnerId: state.authState.user.partner_id
});

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductsOtherDetails);
