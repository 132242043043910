import * as actionTypes from "./actionTypes";
import * as userGroupService from "../../services/userGroupServices";

export const createUserGroup = (id, data) => async dispatch => {
  dispatch(createUserGroupRequest());
  try {
    const success = await userGroupService.createUserGroup(id, data);
    if (success.status === "1") {
      dispatch(createUserGroupSuccess(success.data));
    } else {
      dispatch(createUserGroupFailure(success.error));
    }
  } catch (error) {
    dispatch(createUserGroupFailure(error.message));
  }
};

const createUserGroupRequest = () => {
  return {
    type: actionTypes.CREATE_USERGROUP_REQUEST
  };
};

const createUserGroupSuccess = data => {
  return {
    type: actionTypes.CREATE_USERGROUP_SUCCESS,
    payload: data
  };
};

const createUserGroupFailure = error => {
  return {
    type: actionTypes.CREATE_USERGROUP_FAILURE,
    payload: error
  };
};

export const getUserGroupList = (
  id,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getUserGroupRequest());
  try {
    var success = await userGroupService.getUserGroupList(id, page, search);
    if (success.status && success.status === "1") {
      dispatch(getUserGroupSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getUserGroupFailure(success.error));
    } else {
      dispatch(getUserGroupFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getUserGroupFailure(error.message));
  }
};

const getUserGroupRequest = () => {
  return {
    type: actionTypes.GET_USERGROUP_REQUEST
  };
};

const getUserGroupSuccess = data => {
  return {
    type: actionTypes.GET_USERGROUP_SUCCESS,
    payload: data
  };
};

const getUserGroupFailure = error => {
  return {
    type: actionTypes.GET_USERGROUP_FAILURE,
    payload: error
  };
};

export const saveUserGroupMenuMappingActions = (id, data) => async dispatch => {
  dispatch(saveUserGroupMenuMappingRequest());
  try {
    const success = await userGroupService.saveUserGroupMenuMapping(id, data);
    if (success.status === "1") {
      dispatch(saveUserGroupMenuMappingSuccess(success.data));
    } else {
      dispatch(saveUserGroupMenuMappingFailure(success.error));
    }
  } catch (error) {
    dispatch(saveUserGroupMenuMappingFailure(error.message));
  }
};

const saveUserGroupMenuMappingRequest = () => {
  return {
    type: actionTypes.SAVE_USERGROUP_MENU_MAPPING_REQUEST
  };
};

const saveUserGroupMenuMappingSuccess = data => {
  return {
    type: actionTypes.SAVE_USERGROUP_MENU_MAPPING_SUCCESS,
    payload: data
  };
};

const saveUserGroupMenuMappingFailure = error => {
  return {
    type: actionTypes.SAVE_USERGROUP_MENU_MAPPING_FAILURE,
    payload: error
  };
};

export const getUserGroupMenuMappingActions = id => async dispatch => {
  dispatch(getUserGroupMenuMappingRequest());
  try {
    var success = await userGroupService.getUserGroupMenuMappingList(id);
    if (success.status && success.status === "1") {
      dispatch(getUserGroupMenuMappingSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getUserGroupMenuMappingFailure(success.error));
    } else {
      dispatch(getUserGroupMenuMappingFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getUserGroupMenuMappingFailure(error.message));
  }
};

const getUserGroupMenuMappingRequest = () => {
  return {
    type: actionTypes.GET_USERGROUP_MENU_MAPPING_REQUEST
  };
};

const getUserGroupMenuMappingSuccess = data => {
  return {
    type: actionTypes.GET_USERGROUP_MENU_MAPPING_SUCCESS,
    payload: data
  };
};

const getUserGroupMenuMappingFailure = error => {
  return {
    type: actionTypes.GET_USERGROUP_MENU_MAPPING_FAILURE,
    payload: error
  };
};
