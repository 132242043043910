import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "recompose";
import _ from "lodash";
import { ActionCreators } from "../../../redux/actions";
import Button from "../../../components/CustomButtons/Button.jsx";
import AgGrid from "../../../components/AgGrid/AgGrid.jsx";
import SubHeaderSortCompnent from "../../../components/AgGrid/SubHeaderSortCompnent.jsx";

class SubHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
        classList: null,
        context: { submenu: this },
        domLayout: "autoHeight",
        defaultColDef: {
          flex: 1,
          sortable: true,
          filter: true,
          floatingFilter: true
        },
        columnDefs: [
          {
            headerName: "Class",
            field: "name",
            width: 240,
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: {
              suppressCount: true,
              checkbox: true,
              innerRenderer: "unLabeledCheckboxCellRender",
              suppressDoubleClickExpand: true,
              suppressEnterExpand: true
            },
          },
          {
            headerName: "Sort Order",
            field: "sort_order",
            cellRenderer: "AgGridActionButton",
            colId: "params",
            width: 200,
            filter: false
          }
        ],
        hierarchicalView: function(data) {
          if (data && data.classes && data.classes.length > 0) {
            return {
              group: true,
              children: data.classes,
              expanded: data.open
            };
          } else {
            return null;
          }
        },
      };
      this.gridRefs = React.createRef();
    }
  
    componentWillReceiveProps(nextProps) {
      if(nextProps) {
        if (nextProps.isSaved) {
            this.props.onclose();
        }
        if (nextProps.classeshierarchy) {
          this.setHierarchyData(nextProps.classeshierarchy)
        }
      }
    }

    setHierarchyData(list) {
      let { hierarchy } = this.props.data
      let selectedData = this.getSelectedItemIds(hierarchy)
      let updatedList = this.updateSortOrder(list, hierarchy, selectedData)
      this.setState({
        classList: updatedList
      },() => {
          let selections = selectedData.map(x=>x.id)
          this.gridRefs.current.setSelections(
            selections,
            "Other"
          );
      })
    }

    updateSortOrder(list, hierarchyList, selectedItems) {
      for (let i in list) {
        list[i].sort_order = ""
        let index = selectedItems.findIndex(x=>x.id===list[i].id)
        if (index > -1) {
          list[i].sort_order = selectedItems[index].sort_order
        }
        list[i].classes = this.updateSortOrder(list[i].classes, hierarchyList, selectedItems)
      }
      return list
    }

    getSelectedItemIds(data, rlist=[]) {
      if (data instanceof Array) {
        for(let i in data) {
          rlist = this.getSelectedItemIds(data[i], rlist)
        }
      }else {
        if (data?.id && rlist.indexOf(data.id) === -1) {
          rlist.push(data)
        }
        if (data?.classes?.length) {
          rlist = this.getSelectedItemIds(data.classes, rlist)
        }
      }
      return rlist
    }
  
    componentDidMount() {
      this.props.actions.getPartnersClassesActions(this.props.User.partner_id);
    }

    getTreeView() {
      let list= _.clone(this.gridRefs.current.agGridApi.getSelectedRows())
      let tree =  _.cloneDeep(list)
      tree = tree.filter(element => {
        let index = tree.findIndex(x => x.id === element.parent_class_id)
        if(index === -1) {
          if (element.classes) {
            delete element.classes
          }
          return element
        }
      });
      tree = this.sortList(this.getTree(list, tree))
      return tree
    }

    getTree(list, rlist) {
      let treeData = _.cloneDeep(list)
      for(let x of rlist) {
        let classes = treeData.filter(o => o.parent_class_id === x.id)
        if (classes && classes.length) {
          classes = this.sortList(classes)
          x.classes = this.getTree(list, classes)
        }else {
          delete x.classes
        }
      }
      return rlist
    }

    sortList(list) {
      return list.sort((a, b) => Number(a.sort_order) - Number(b.sort_order))
    }
  
    backAction = () => {
      this.props.onclose();
    }
  
    handleSave = () => {
      let hierarchy = this.getTreeView();
      let editData = this.props.data;
      editData.hierarchy = hierarchy
      this.props.onsave(editData);
    }
  
    render() {
      return this.renderHierarchyView();
    }

    handelSortOrder = (data, val) => {
      let list = _.clone(this.state.classList)
      let updatedList = this.getUpdatedList(list, data, val);
      let selectedItems = this.gridRefs.current.agGridApi.getSelectedRows();
      this.setState({
        ...this.state,
        classList: updatedList
      }, () => {
        let selections = selectedItems && selectedItems.length ? selectedItems.map(x=>x.id) : []
        this.gridRefs.current.setSelections(
          selections,
          "Other"
        );
      })
    }

    getUpdatedList(list, data, val) {
      for (let i in list) {
        let index = list.findIndex(x => x.sort_order === Number(val))
        if (list[i] && list[i].id === data.id && index === -1) {
          list[i].sort_order = val
        } else {
          list[i].classes = this.getUpdatedList(list[i].classes, data, val)
        }
      }
      return list
    }
  
    renderHierarchyView = () => {
      return(
        <React.Fragment>
          <AgGrid
            rowData={this.state.classList}
            columnDefs={this.state.columnDefs}
            Loading={this.props.Loading}
            rowSelection="multiple"
            isHierarchyView={true}
            domLayout={this.state.domLayout}
            getNodeChildDetails={this.state.hierarchicalView}
            defaultColDef={this.state.defaultColDef}
            ref={this.gridRefs}
            floatingFilter={true}
            context={this.state.context}
            groupSuppressAutoColumn={true}
            suppressRowClickSelection={true}
            isSelectionFunc={true}
            isPagination={false}
            AgGridActionButton={SubHeaderSortCompnent}
          />
          <div style={{ float: "right" }}>
            <Button
              onClick={() => this.backAction()}
            >
              Close
            </Button>
              <Button
                color="info"
                style={{ backgroundColor: "#248f24" }}
                onClick={() => this.handleSave()}
              >
                Save
              </Button>
            </div>
        </React.Fragment>
      )
    }
}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
    User: state.authState.user,
    Loading: state.classReducer.partner_classes.loading,
    Error: state.classReducer.partner_classes.error,
    classeshierarchy: state.classReducer.partner_classes.data,
    isSaved: state.CMSContentReducer.add_cms_menu.isSaved,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SubHeader);
