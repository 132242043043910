import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Slide from "@material-ui/core/Slide";
import withAuthorization from "../../Session/withAuthorization";
import { rolesNames } from "../../../constants";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import NetStockActionBtn from "./components/NetStockActionBtn.jsx";
import moment from "moment";
import NetStockActionButton from "../../../components/AgGrid/NetStockActionButton";

import AgGrid from "../../../components/AgGrid/AgGrid.jsx";
let _agGridAPI = null;

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function dateFormatter(params) {
  if (params.value) {
    return moment(params.value).format("MM/DD/YYYY");
  } else {
    return "";
  }
}

class NetStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      productId: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      detailspagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      netStockList: [],
      isOpenStockMove: false,
      selectedProductId: null,
      stockMoveData: [],
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 370,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Net Stock",
          field: "stock",
          width: 370,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ],
      stockMoveColumn: [
        {
          headerName: "Product Name",
          field: "name",
          width: 200,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Quantity",
          field: "product_qty",
          width: 100,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Source Location",
          field: "source_location.name",
          width: 200,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Destination Location",
          field: "dest_location.name",
          width: 200,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Status",
          field: "status",
          width: 200,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Created Date",
          field: "created_at",
          width: 200,
          resizable: true,
          sortable: true,
          valueFormatter: dateFormatter
        }
      ],
      context: { componentParent: this },

      frameworkComponents: {
        NetStockComponent: NetStockActionBtn
      },
      domLayout: "autoHeight"
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.updateDetailGridData = this.updateDetailGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.List && !_.isEqual(this.props.List, nextProps.List)) {
        this.setState(() => ({
          netStockList: nextProps.List,
          pagination: nextProps.pagination
        }));
      }

      if (
        nextProps.StockMoveData &&
        !_.isEqual(this.props.StockMoveData, nextProps.StockMoveData)
      ) {
        this.setState(() => ({
          stockMoveData: nextProps.StockMoveData,
          detailspagination: nextProps.detailsPagination
        }));
      }
    }
  }

  componentDidMount() {
    this.props.actions.getProductsNetStockAction(
      this.props.PartnerId,
      this.state.pagination.page
    );
  }

  render() {
    const { isOpenStockMove } = this.state;
    return isOpenStockMove
      ? this.openStockMoveModel()
      : this.renderNetStockTable();
  }

  renderNetStockTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                {" "}
                Net Stock
              </p>
            </CardHeader>
            <CardBody>
              {/* <div id="myGrid"
                                style={{
                                    width: '100%'
                                }}
                                className="ag-theme-material" > */}

              <AgGrid
                rowData={this.state.netStockList}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                handelView={this.openStockMoveDetailModel}
                loading={this.props.Loading}
                AgGridActionButton={NetStockActionButton}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                search={this.searchText}
              />
              {/* </div> */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
  updateGridData(page) {
    this.props.actions.getProductsNetStockAction(
      this.props.PartnerId,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getProductsNetStockAction(
          this.props.PartnerId,
          page,
          this.state.searchtext
        );
      }
    );
  }

  openStockMoveModel = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          />
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Stock Detail
              </p>
            </CardHeader>
            <CardBody>
              <div
                id="myGrid"
                style={{
                  width: "100%"
                }}
                className="ag-theme-material"
              >
                {/* <AgGridReact
                                        gAgGridReactridOptions={this.gridOptions}
                                        columnDefs={this.state.stockMoveColumn}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.stockMoveData}
                                        showToolPanel={true}
                                        // floatingFilter={true}
                                        context={this.state.context}
                                        statusBar={this.state.statusBar}
                                        groupSelectsChildren={true}
                                        sideBar={this.state.sideBar}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        pagination={true}
                                        paginationPageSize={50}
                                        rowMultiSelectWithClick={true}
                                        icons={this.state.icons}
                                        rowSelection={this.state.rowSelection}
                                        onGridReady={this.onGridReady}
                                        domLayout={this.state.domLayout}
                                        enableCellChangeFlash={true}
                                    /> */}
                <AgGrid
                  rowData={this.state.stockMoveData}
                  columnDefs={this.state.stockMoveColumn}
                  // handelEdit={this.handelEdit}
                  // handelView={this.openStockMoveDetailModel}
                  loading={this.props.Loading}
                  update={this.updateDetailGridData}
                  isServerPagination={true}
                  pagination={this.state.detailspagination}
                />
              </div>
              <div>
                <Button onClick={() => this.handleClose()}>Close</Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateDetailGridData(page) {
    this.props.actions.getProductStockMoveDataAction(
      this.state.productId,
      page
    );
  }

  handleClose = () => {
    this.setState({
      isOpenStockMove: false,
      detailspagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      }
    });
  };

  openStockMoveDetailModel = id => {
    this.props.actions.getProductStockMoveDataAction(
      id,
      this.state.detailspagination.page
    );
    this.setState({
      productId: id,
      isOpenStockMove: true
    });
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.inventoryManagementReducer.productsNetStock.data,
  Loading: state.inventoryManagementReducer.productsNetStock.loading,
  Error: state.inventoryManagementReducer.productsNetStock.error,
  UserId: state.authState.user.pk,
  PartnerId: state.authState.user.partner_id,
  StockMoveData: state.inventoryManagementReducer.productStoveMoveData.data,
  pagination: state.inventoryManagementReducer.productsNetStock.pagination,
  detailsPagination:
    state.inventoryManagementReducer.productStoveMoveData.pagination
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NetStock);
