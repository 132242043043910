import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";

class GridSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: ""
    };
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  handleSearchTextChange = () => event => {
    this.setState({
      text: event.target.value
    });
  };

  render() {
    const { text } = this.state;

    return (
      <div className="search-component">
        <TextField
          value={text ? text : ""}
          placeholder="Search"
          classes={{
            root: "search-box"
          }}
          onChange={this.handleSearchTextChange()}
          onKeyPress={this.handleKeyPress()}
        />
        <div title="Search" className="search-component-icons">
          <SearchIcon
            onClick={() => this.searchText()}
            className="search-component-icon"
          />
        </div>
        <div title="Reset Search" className="search-component-icons">
          <RefreshIcon
            onClick={() => this.resetSearch()}
            className="search-component-icon"
          />
        </div>
      </div>
    );
  }

  handleKeyPress = () => event => {
    if (event.key === "Enter") {
      this.props.search(event.target.value);
    }
  };

  resetSearch() {
    this.setState(
      {
        text: ""
      },
      () => {
        this.props.search(null);
      }
    );
  }

  searchText() {
    this.props.search(this.state.text);
  }
}

export default GridSearch;
