//are these still in use?
export const _user_ID = "";
export const _type = "teamlocal:affiliate-retailer";

//export const _repository_id = 'f75f12e226b5d44509f5';
//export const _branch_id = '33ba88d8fc3d08ddccd0';
//export const _api_key = '2ZHJNVSDI8';

const dev = {
  env: {
    API_URL: "http://127.0.0.1:8000/",
    PORTAL: "http://localhost:3000",
    CONSUMER: "http://localhost:3002"
  }
};

const staging = {
  env: {
    API_URL: "https://stagingapi.teamlocal.net/",
    PORTAL: "https://stagingportal.teamlocal.net",
    CONSUMER: "https://staging.teamlocal.net"
  }
};

const demo = {
  env: {
    API_URL: "https://api.teamlocal.co/",
    PORTAL: "https://portal.teamlocal.co",
    CONSUMER: "https://teamlocal.co"
  }
};

const prod = {
  env: {
    API_URL: "https://api.teamlocal.co/",
    PORTAL: "https://portal.teamlocal.co",
    CONSUMER: "https://teamlocal.co"
  }
};

const aws = {
  env: {
    API_URL: "https://api.teamlocal.ws/",
    PORTAL: "https://portal.teamlocal.ws",
    CONSUMER: "https://teamlocal.ws"
  }
};

const config =
  process.env.REACT_APP_STAGE === "production"
    ? prod
    : process.env.REACT_APP_STAGE === "staging"
    ? staging
    : process.env.REACT_APP_STAGE === "demo"
    ? demo
    : process.env.REACT_APP_STAGE === "aws"
    ? aws
    : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
