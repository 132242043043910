import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withAuthorization from "../../Session/withAuthorization";
import { rolesNames } from "../../../constants";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import extendedFormStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { AgGridReact } from "ag-grid-react";
// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

// import { MenuModule } from '@ag-grid-enterprise/menu';
// import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
// import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import Typography from "@material-ui/core/Typography";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "style/app.css";

import { DropzoneArea } from "material-ui-dropzone";
// import * as XLSX from 'xlsx';
import { utils, read } from "xlsx";
import { savePartners } from "../../../services/importServices";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class PartnerImport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      _agGridAPI: null,
      _agGridColumnAPI: null,
      disableView: false,
      importStatus: "Calculating data to be imported....",
      open: false,
      columnDefs: [],
      defaultColDef: {
        editable: true
      },
      autoGroupColumnDef: {
        minWidth: 200
      },
      rowData: [],
      rowClassRules: {
        "yellow-ready": function (params) {
          return params.data && params.data.status === "PROCESSING";
        },
        "green-success": function (params) {
          return params.data && params.data.status === "SUCCESS";
        },
        "red-error": function (params) {
          return params.data && params.data.status === "ERROR";
        }
      }
    };
  }

  componentWillReceiveProps(nextProps) { }

  componentDidMount() { }

  onGridReady = params => {
    this.setState({
      _agGridAPI: params.api,
      _agGridColumnAPI: params.columnApi
    });

    params.api.setColumnDefs(this.state.columnDefs);
    params.api.setRowData(this.state.rowData);

    this.setState({
      ...this.state,
      importStatus: this.state.rowData.length + " records available to import"
    });
  };

  handlePopUp = visibility => {
    if (!this.state.disableView) {
      this.setState({
        ...this.state,
        open: visibility
      });
    }
  };

  pushBusinessToServer = () => {
    let selectedNodes = this.state._agGridAPI.getSelectedNodes();
    let selectedData = selectedNodes.map(node => {
      return node.data;
    });

    if (!selectedData || selectedData.length == 0) {
      return;
    }

    this.setState({
      ...this.state,
      disableView: true,
      error: "",
      importStatus: "Selected " + selectedData.length + " records to uplaod"
    });

    var i,
      j,
      temparray,
      chunk = 10,
      chunkArray = [];
    for (i = 0, j = selectedData.length; i < j; i += chunk) {
      temparray = selectedData.slice(i, i + chunk);
      chunkArray.push(temparray);
    }

    console.log("chunkArray", chunkArray);
    for (let i = 0; i < chunkArray.length; i++) {
      ///// Marking what all row are pushed in batch for processing

      let PRCOSSING_DATA = _.map(chunkArray[i], function (item) {
        item.status = "PROCESSING";
        item.message = "Uploading...";
        return item;
      });

      let PROCESSING_RESULT = _.map(this.state.rowData, function (item) {
        let correspondingPartner = _.find(PRCOSSING_DATA, {
          temp_id: item.temp_id
        });
        let updatedRow = item;
        if (correspondingPartner) {
          updatedRow.status = correspondingPartner.status;
          updatedRow.message = correspondingPartner.message;
        }

        return updatedRow;
      });

      this.setState(
        {
          rowData: PROCESSING_RESULT
        },
        () => {
          this.state._agGridAPI.setRowData(this.state.rowData);
        }
      );

      ///// End of processing row marking

      savePartners(this.props.UserId, chunkArray[i])
        .then(data => {
          console.log("data", data);
          this.setState({
            ...this.state,
            disableView: false
          });

          if (data) {
            let UPDATED_RESULT = this.state.rowData;
            if (data.status === "0" && data.error) {
              this.setState({
                ...this.state,
                error: data.error,
                importStatus: "Error occured"
              });

              UPDATED_RESULT = _.map(this.state.rowData, function (item) {
                let correspondingPartner = _.find(chunkArray[i], {
                  temp_id: item.temp_id
                });
                let updatedRow = item;
                if (correspondingPartner) {
                  updatedRow.id = "";
                  updatedRow.status = "Error";
                  updatedRow.message = "An error has occured, Try again.";
                }
                return updatedRow;
              });
            } else if (data.status === "1" && data.data) {
              ///// Marking what all row are returned back from server in batch processing

              UPDATED_RESULT = _.map(this.state.rowData, function (item) {
                let correspondingPartner = _.find(data.data, {
                  temp_id: item.temp_id
                });
                let updatedRow = item;
                if (correspondingPartner) {
                  updatedRow.id = correspondingPartner.id;
                  updatedRow.status = correspondingPartner.status;
                  updatedRow.message = correspondingPartner.message;
                }
                return updatedRow;
              });
              ///// End of marking what all row are returned back from server in batch processing
            }

            this.setState(
              {
                rowData: UPDATED_RESULT
              },
              () => {
                this.state._agGridAPI.setRowData(this.state.rowData);
              }
            );
          }
        })
        .catch(reason => {
          console.error("Import api call error", reason);
        })
        .finally(info => console.log(info));
    }
  };

  dataDialogView = () => {
    const { classes } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.state.open}
        onClose={() => this.handlePopUp(false)}
        TransitionComponent={Transition}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => this.handlePopUp(false)}
              aria-label="close"
            >
              {this.state.disableView ? (
                <CircularProgress color="secondary" />
              ) : (
                <CloseIcon />
              )}
            </IconButton>
            <Typography
              variant="h6"
              style={{ flex: 1, marginLeft: "16px", color: "white" }}
            >
              {this.state.importStatus}
            </Typography>

            <Button
              autoFocus
              color="inherit"
              onClick={() => this.pushBusinessToServer(false)}
              disabled={this.state.disableView}
            >
              Import
            </Button>
          </Toolbar>
        </AppBar>

        <GridContainer style={{ width: "100%" }}>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ top: "5%" }}>
              <CardBody>
                <div
                  id="myGrid"
                  style={{
                    width: "100%",
                    height: "500px"
                  }}
                  className="ag-theme-balham-dark"
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    enableRangeSelection={true}
                    animateRows={true}
                    floatingFilter={true}
                    suppressAggFuncInHeader={true}
                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    // rowData={this.state.rowData}
                    rowMultiSelectWithClick={true}
                    rowSelection={"multiple"}
                    rowClassRules={this.state.rowClassRules}
                  />
                </div>

                {this.state.error && (
                  <>
                    <br />
                    <FormLabel>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  </>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Dialog>
    );
  };

  render() {
    return (
      <>
        {this.dataDialogView()}
        {this.renderTagsType()}
      </>
    );
  }

  createGridData(data) {
    if (!data || data.length === 0) {
      this.setState({
        ...this.state,
        error: "Data not available for import"
      });
      this.handlePopUp(false);
      return;
    }

    // let compileData = _.map(data, stateItem => {

    //     for (var k in stateItem) {
    //         if (stateItem.hasOwnProperty(k)){

    //         }
    //     };
    // });

    let firstRecord = data[0];

    let cols = [
      {
        headerName: "",
        field: "",
        width: 75,
        checkboxSelection: true,
        resizable: true,
        sortable: false,
        pinned: "left",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      {
        headerName: "Status",
        field: "status",
        width: 150,
        resizable: true,
        sortable: true,
        pinned: "left",
        filter: "agSetColumnFilter"
      },
      {
        headerName: "Message",
        field: "message",
        width: 200,
        resizable: true,
        sortable: true,
        pinned: "right",
        filter: "agSetColumnFilter"
      }
    ];
    for (var k in firstRecord) {
      cols.push({
        headerName: k,
        field: k,
        width: 200,
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter"
        // floatingFilter: true
      });
    }

    let i = 0;
    let dataWithDefaultStatus = _.map(data, function (element) {
      return _.extend({}, element, {
        status: "READY",
        message: "Ready to upload",
        temp_id: i++
      });
    });

    this.setState(
      {
        ...this.state,
        columnDefs: cols,
        rowData: dataWithDefaultStatus
      },
      function () {
        this.handlePopUp(true);
      }
    );
  }

  handleChange(files) {
    var i, f;
    for (i = 0; i < files.length; i++) {
      var reader = new FileReader();
      reader.onload = e => {
        let data = e.target.result;
        let readedData = read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = utils.sheet_to_json(ws, { raw: true, header: true });

        this.createGridData(dataParse);
      };
      reader.readAsBinaryString(files[i]);
    }
  }

  removeFile() {
    this.setState({
      files: null
    });
  }

  renderTagsType = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>Import Partners</p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <DropzoneArea
                  acceptedFiles={[
                    "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ]}
                  onChange={this.handleChange.bind(this)}
                  filesLimit={1}
                  onDelete={this.removeFile.bind(this)}
                  showFileNames={true}
                  dropzoneText={"Drag file here or click to browse"}
                />

                {this.state.error && (
                  <FormLabel className={classes.labelLeftHorizontal}>
                    <code>{this.state.error}</code>
                  </FormLabel>
                )}

                {this.state.uploading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}

                <Loader loading={this.state.uploading} text="Processing.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
}

var countDownDirection = true;
function startInterval(api, columnApi) {
  var actionIndex = 0;
  resetCountdown();
  executeAfterXSeconds();
  function executeAfterXSeconds() {
    setTimeout(function () {
      var action = getActions()[actionIndex];
      action(api, columnApi);
      actionIndex++;
      if (actionIndex >= getActions().length) {
        actionIndex = 0;
      }
      resetCountdown();
      executeAfterXSeconds();
    }, 3000);
  }
}

function resetCountdown() {
  countDownDirection = !countDownDirection;
}

function getActions() {
  return [
    function (api, columnApi) {
      console.log("api", api);
      console.log("columnApi", columnApi);
      columnApi.applyColumnState({
        state: [
          {
            colId: "country",
            sort: "asc"
          }
        ],
        defaultState: { sort: null }
      });
    },
    function (api, columnApi) {
      columnApi.applyColumnState({
        state: [
          {
            colId: "year",
            sort: "asc"
          },
          {
            colId: "country",
            sort: "asc"
          }
        ],
        defaultState: { sort: null }
      });
    },
    function (api, columnApi) {
      columnApi.applyColumnState({
        state: [
          {
            colId: "year",
            sort: "asc"
          },
          {
            colId: "country",
            sort: "desc"
          }
        ],
        defaultState: { sort: null }
      });
    },
    function (api, columnApi) {
      columnApi.applyColumnState({ defaultState: { sort: null } });
    }
  ];
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserId: state.authState.user.user
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...extendedFormStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PartnerImport);
