import * as actionTypes from '../actions/actionTypes'

const INITIAL_STATE = {
    event: {
        loading: false,
        error: null,
        data: null,
    },
    create_event: {
        loading: false,
        error: null,
        data: null,
    },
    delete_event: {
        loading: false,
        error: null,
        data: null,
    },
    event_type: {
        loading: false,
        error: null,
        data: null,
    },
    create_event_type: {
        loading: false,
        error: null,
        data: null,
    },
    delete_event_type: {
        loading: false,
        error: null,
        data: null,
    },
    event_partner: {
        loading: false,
        error: null,
        data: null,
    },
    create_event_partner: {
        loading: false,
        error: null,
        data: null,
    },
}

const EventReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.EVENT_REQUEST:
            return {
                ...state,
                event: {
                    ...state.event,
                    loading: true,
                    error: null,
                    data: null,
                },
            }
        case actionTypes.EVENT_SUCCESS:
            return {
                ...state,
                event: {
                    ...state.event,
                    loading: false,
                    error: null,
                    data: action.payload,
                },
            }
        case actionTypes.EVENT_FAILURE:
            return {
                ...state,
                event: {
                    ...state.event,
                    loading: false,
                    error: action.payload,
                    data: null,
                },
            }
        case actionTypes.SAVE_EVENT_REQUEST:
            return {
                ...state,
                create_event: {
                    ...state.create_event,
                    loading: true,
                    error: null,
                    data: null,
                },
            }
        case actionTypes.SAVE_EVENT_SUCCESS:
            return {
                ...state,
                create_event: {
                    ...state.create_event,
                    loading: false,
                    error: null,
                    data: action.payload,
                },
            }
        case actionTypes.SAVE_EVENT_FAILURE:
            return {
                ...state,
                create_event: {
                    ...state.create_event,
                    loading: false,
                    error: action.payload,
                    data: null,
                },
            }
        case actionTypes.EVENT_PARTNER_MAPPING_REQUEST:
            return {
                ...state,
                event_partner: {
                    ...state.event_partner,
                    loading: true,
                    error: null,
                    data: null,
                },
            }
        case actionTypes.EVENT_PARTNER_MAPPING_SUCCESS:
            return {
                ...state,
                event_partner: {
                    ...state.event_partner,
                    loading: false,
                    error: null,
                    data: action.payload,
                },
            }
        case actionTypes.EVENT_PARTNER_MAPPING_FAILURE:
            return {
                ...state,
                event_partner: {
                    ...state.event_partner,
                    loading: false,
                    error: action.payload,
                    data: null,
                },
            }
        case actionTypes.SAVE_EVENT_PARTNER_MAPPING_REQUEST:
            return {
                ...state,
                create_event_partner: {
                    ...state.create_event_partner,
                    loading: true,
                    error: null,
                    data: null,
                },
            }
        case actionTypes.SAVE_EVENT_PARTNER_MAPPING_SUCCESS:
            return {
                ...state,
                create_event_partner: {
                    ...state.create_event_partner,
                    loading: false,
                    error: null,
                    data: action.payload,
                },
            }
        case actionTypes.SAVE_EVENT_PARTNER_MAPPING_FAILURE:
            return {
                ...state,
                create_event_partner: {
                    ...state.create_event_partner,
                    loading: false,
                    error: action.payload,
                    data: null,
                },
            }
        case actionTypes.EVENT_TYPE_REQUEST:
            return {
                ...state,
                event_type: {
                    ...state.event_type,
                    loading: true,
                    error: null,
                    data: null,
                },
                create_event_type: {
                    loading: false,
                    error: null,
                    data: null,
                },
                delete_event_type: {
                    loading: false,
                    error: null,
                    data: null,
                },
            }
        case actionTypes.EVENT_TYPE_SUCCESS:
            return {
                ...state,
                event_type: {
                    ...state.event_type,
                    loading: false,
                    error: null,
                    data: action.payload,
                },
            }
        case actionTypes.EVENT_TYPE_FAILURE:
            return {
                ...state,
                event_type: {
                    ...state.event_type,
                    loading: false,
                    error: action.payload,
                    data: null,
                },
            }
        case actionTypes.SAVE_EVENT_TYPE_REQUEST:
            return {
                ...state,
                create_event_type: {
                    ...state.create_event_type,
                    loading: true,
                    error: null,
                    data: null,
                },
            }
        case actionTypes.SAVE_EVENT_TYPE_SUCCESS:
            return {
                ...state,
                create_event_type: {
                    ...state.create_event_type,
                    loading: false,
                    error: null,
                    data: action.payload,
                },
            }
        case actionTypes.SAVE_EVENT_TYPE_FAILURE:
            return {
                ...state,
                create_event_type: {
                    ...state.create_event_type,
                    loading: false,
                    error: action.payload,
                    data: null,
                },
            }
        case actionTypes.DELETE_EVENT_TYPE_REQUEST:
            return {
                ...state,
                delete_event_type: {
                    ...state.delete_event_type,
                    loading: true,
                    error: null,
                    data: null,
                },
            }
        case actionTypes.DELETE_EVENT_TYPE_SUCCESS:
            return {
                ...state,
                delete_event_type: {
                    ...state.delete_event_type,
                    loading: false,
                    error: null,
                    data: action.payload,
                },
            }
        case actionTypes.DELETE_EVENT_TYPE_FAILURE:
            return {
                ...state,
                delete_event_type: {
                    ...state.delete_event_type,
                    loading: false,
                    error: action.payload,
                    data: null,
                },
            }
        case actionTypes.DELETE_EVENT_REQUEST:
            return {
                ...state,
                delete_event_type: {
                    ...state.delete_event,
                    loading: true,
                    error: null,
                    data: null,
                },
            }
        case actionTypes.DELETE_EVENT_SUCCESS:
            return {
                ...state,
                delete_event: {
                    ...state.delete_event,
                    loading: false,
                    error: null,
                    data: action.payload,
                },
            }
        case actionTypes.DELETE_EVENT_FAILURE:
            return {
                ...state,
                delete_event: {
                    ...state.delete_event,
                    loading: false,
                    error: action.payload,
                    data: null,
                },
            }
        default:
            return state
    }
}

export default EventReducers