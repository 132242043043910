import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  list: [],
  loading: true,
  error: null
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        list: [],
        loading: true,
        error: null
      };

    case actionTypes.GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        list: [],
        loading: false,
        error: action.payload
      };

    case actionTypes.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
        error: null
      };
    default:
      return state;
  }
};

export default dashboardReducer;
