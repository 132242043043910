// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const createUser = async (userid, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userid);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post(
      "/api/user/user-associate/create/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserList = async (id, page, search) => {
  try {
    let url = "/api/user/user-associate/get/?partnerid=" + id + "&page=" + page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
