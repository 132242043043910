import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  list: null,
  loading: false,
  error: null,
  isSaved: false,
  isSaving: false,
  pagination: {
    page: 1,
    next: null,
    prev: null,
    totalpages: 1,
    trecords: 0,
    pagesize: 25
  },
  playPause: {
    loading: false,
    error: null,
    data: null,
    isSaved: false
  },
  deleteCampaign: {
    loading: false,
    error: null,
    isDeleted: false
  },
  channels: {
    list: null,
    loading: false,
    error: null
  },
  channeldistribution: {
    list: null,
    loading: false,
    error: null
  },
  publishCampaign: {
    loading: false,
    error: null,
    isPublished: false
  },
  campaign: {
    loading: false,
    error: null,
    data: []
  },
  partnerscampaign: {
    loading: false,
    error: null,
    list: [],
    pagination: {
      page: 1,
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 25
    }
  },
  partners: {
    loading: false,
    error: null,
    data: []
  },
  brandaccesstopublish: {
    isSaved: false,
    loading: false,
    error: null,
    data: []
  }
};

const campignManagementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CAMPAIGN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        list: null,
        isSaved: false,
        isSaving: false,
        pagination: {
          page: 1,
          next: null,
          prev: null,
          totalpages: 1,
          trecords: 0,
          pagesize: 25
        },
        playPause: {
          loading: false,
          isSaved: false
        },
        deleteCampaign: {
          loading: false,
          isDeleted: false
        },
        publishCampaign: {
          loading: false,
          isPublished: false
        }
      };
    case actionTypes.CAMPAIGN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        list: null,
        isSaving: false
      };
    case actionTypes.CAMPAIGN_LIST_SUCCESS:
      let l_pagination = _.clone(state.partnerscampaign.pagination);
      let sl_data = action.payload;
      if (sl_data && sl_data.results) {
        l_pagination.page = sl_data.current;
        l_pagination.next = sl_data.next;
        l_pagination.prev = sl_data.prev;
        l_pagination.totalpage = sl_data.total_pages;
        l_pagination.totalrecords = sl_data.total_records;
        l_pagination.pagesize = sl_data.page_size;
        sl_data = sl_data.results;
      }
      return {
        ...state,
        loading: false,
        error: null,
        isSaved: false,
        list: sl_data,
        isSaving: false,
        pagination: {
          ...l_pagination
        }
      };
    case actionTypes.SAVE_CAMPAIGN_DATA_REQUEST:
      return {
        ...state,
        playPause: {
          loading: false,
          isSaved: false
        },
        deleteCampaign: {
          loading: false,
          isDeleted: false
        },
        publishCampaign: {
          loading: false,
          isPublished: false
        },
        loading: true,
        error: null,
        isSaved: false,
        isSaving: true
      };
    case actionTypes.SAVE_CAMPAIGN_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        isSaving: false
      };
    case actionTypes.SAVE_CAMPAIGN_DATA_SUCCESS:
      let list = _.clone(state.list);
      let index1 = list.findIndex(p => p.id === action.payload.id);
      if (index1 > -1) {
        list.splice(index1, 1);
      }
      list.unshift(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
        isSaved: true,
        list: list,
        isSaving: false
      };
    case actionTypes.PLAY_PAUSE_CAMPAIGN_REQUEST:
      return {
        ...state,
        playPause: {
          ...state.playPause,
          loading: true,
          error: null,
          data: null,
          isSaved: false
        }
      };
    case actionTypes.PLAY_PAUSE_CAMPAIGN_FAILURE:
      return {
        ...state,
        playPause: {
          ...state.playPause,
          loading: false,
          error: action.payload,
          data: null,
          isSaved: false
        }
      };
    case actionTypes.PLAY_PAUSE_CAMPAIGN_SUCCESS:
      var data = _.clone(state.list);
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data[index] = action.payload;
      } else {
        data.push(action.payload);
      }
      return {
        ...state,
        list: data,
        playPause: {
          ...state.playPause,
          loading: false,
          error: null,
          data: action.payload,
          isSaved: true
        }
      };

    case actionTypes.DELETE_CAMPAIGN_REQUEST:
      return {
        ...state,
        deleteCampaign: {
          ...state.deleteCampaign,
          loading: true,
          error: null,
          isDeleted: false
        }
      };
    case actionTypes.DELETE_CAMPAIGN_FAILURE:
      return {
        ...state,
        deleteCampaign: {
          ...state.deleteCampaign,
          loading: false,
          error: action.payload,
          isDeleted: false
        }
      };
    case actionTypes.DELETE_CAMPAIGN_SUCCESS:
      var data = _.clone(state.list);
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index !== -1) {
        data.splice(index, 1);
      }
      return {
        ...state,
        list: data,
        deleteCampaign: {
          ...state.deleteCampaign,
          loading: false,
          error: null,
          isDeleted: true
        }
      };
    case actionTypes.CHANNEL_LIST_REQUEST:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: true,
          error: null,
          list: null
        }
      };
    case actionTypes.CHANNEL_LIST_FAILURE:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: false,
          error: action.payload,
          list: null
        }
      };
    case actionTypes.CHANNEL_LIST_SUCCESS:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: false,
          list: action.payload,
          error: null
        }
      };

    case actionTypes.CHANNEL_DIST_LIST_REQUEST:
      return {
        ...state,
        channeldistribution: {
          ...state.channeldistribution,
          loading: true,
          error: null,
          list: null
        }
      };
    case actionTypes.CHANNEL_DIST_LIST_FAILURE:
      return {
        ...state,
        channeldistribution: {
          ...state.channeldistribution,
          loading: false,
          error: action.payload,
          list: null
        }
      };
    case actionTypes.CHANNEL_DIST_LIST_SUCCESS:
      return {
        ...state,
        channeldistribution: {
          ...state.channeldistribution,
          loading: false,
          list: action.payload,
          error: null
        }
      };

    case actionTypes.PUBLISH_CAMPAIGN_REQUEST:
      return {
        ...state,
        publishCampaign: {
          loading: true,
          error: null,
          isPublished: false
        }
      };
    case actionTypes.PUBLISH_CAMPAIGN_FAILURE:
      return {
        ...state,
        publishCampaign: {
          loading: false,
          error: action.payload,
          isPublished: false
        }
      };
    case actionTypes.PUBLISH_CAMPAIGN_SUCCESS:
      var data = _.clone(state.list);
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data[index] = action.payload;
      } else {
        data.push(action.payload);
      }
      return {
        ...state,
        list: data,
        publishCampaign: {
          loading: false,
          error: null,
          isPublished: true
        }
      };

    case actionTypes.CAMPAIGN_DATA_BY_ID_REQUEST:
      return {
        ...state,
        isSaved: false,
        campaign: {
          loading: true,
          error: null,
          data: null
        }
      };
    case actionTypes.CAMPAIGN_DATA_BY_ID_FAILURE:
      return {
        ...state,
        campaign: {
          loading: false,
          error: action.payload,
          data: null
        }
      };
    case actionTypes.CAMPAIGN_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        campaign: {
          loading: false,
          error: null,
          data: action.payload
        }
      };

    case actionTypes.PARTNERS_CAMPAIGN_REQUEST:
      return {
        ...state,
        partnerscampaign: {
          loading: true,
          error: null,
          list: null,
          pagination: {
            page: 1,
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 25
          }
        }
      };
    case actionTypes.PARTNERS_CAMPAIGN_FAILURE:
      return {
        ...state,
        partnerscampaign: {
          loading: false,
          error: action.payload,
          list: null
        }
      };
    case actionTypes.PARTNERS_CAMPAIGN_SUCCESS:
      let pagination = _.clone(state.partnerscampaign.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        partnerscampaign: {
          loading: false,
          error: null,
          list: sdata,
          pagination: {
            ...pagination
          }
        }
      };
    case actionTypes.GET_RETAILERS_BRAND_LIST_REQUEST:
      return {
        ...state,
        partners: {
          loading: true,
          error: null,
          data: null
        }
      };
    case actionTypes.GET_RETAILERS_BRAND_LIST_FAILURE:
      return {
        ...state,
        partners: {
          loading: false,
          error: action.payload,
          data: null
        }
      };
    case actionTypes.GET_RETAILERS_BRAND_LIST_SUCCESS:
      return {
        ...state,
        partners: {
          loading: false,
          error: null,
          data: action.payload
        }
      };

    case actionTypes.GET_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_REQUEST:
      return {
        ...state,
        brandaccesstopublish: {
          ...state.brandaccesstopublish,
          isSaved: false,
          loading: true,
          error: null,
          data: []
        }
      };
    case actionTypes.GET_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_FAILURE:
      return {
        ...state,
        brandaccesstopublish: {
          ...state.brandaccesstopublish,
          loading: false,
          error: action.payload,
          data: []
        }
      };
    case actionTypes.GET_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        brandaccesstopublish: {
          ...state.brandaccesstopublish,
          loading: false,
          error: null,
          data: action.payload
        }
      };

    case actionTypes.SAVE_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_REQUEST:
      return {
        ...state,
        brandaccesstopublish: {
          ...state.brandaccesstopublish,
          isSaved: false,
          loading: true,
          error: null
        }
      };
    case actionTypes.SAVE_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_FAILURE:
      return {
        ...state,
        brandaccesstopublish: {
          ...state.brandaccesstopublish,
          isSaved: true,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.SAVE_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        brandaccesstopublish: {
          ...state.brandaccesstopublish,
          isSaved: true,
          loading: false,
          error: null
        }
      };

    default:
      return state;
  }
};

export default campignManagementReducer;
