import React from "react";
import _ from "lodash";
import ClearIcon from "@material-ui/icons/Clear";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";

const ComponentSelector = props => {
  const handleAddPrimaryTabRow = () => {
    props.handleAddPrimaryTabRow();
  };

  const handlePrimaryTabRowRemove = index => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action will remove the existing data for this section.",
      icon: "warning",
      showCancelButton: true,
      focusCancel: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Remove"
    }).then(result => {
      if (result.isConfirmed) {
        props.handlePrimaryTabRowRemove(index);
      }
    });
  };

  const handlePrimaryTabRowMappingFieldChange = (
    newval,
    index,
    sort_order
  ) => event => {
    props.handlePrimaryTabRowMappingFieldChange(
      newval,
      index,
      sort_order,
      event.target.value
    );
  };

  const handlePrimaryDataSortOrder = index => event => {
    props.handleSortOrderChange(index, event.target.value);
  };

  const getPrimaryTabTableData = () => {
    const { required } = props;
    let rows = props.rows || [];
    const { classes } = props;

    let sorted_row = rows;
    var returnData = [];

    _.map(sorted_row, (data, index) => {
      returnData.push([
        <FormControl fullWidth name="Component">
          <Select
            style={{ marginTop: "0px" }}
            onChange={handlePrimaryTabRowMappingFieldChange(
              "component",
              index,
              data.sort_order
            )}
            value={data.component.id}
          >
            <MenuItem disabled>Choose Component</MenuItem>
            {_.map(props.componentList, ({ id, name }, index) => {
              return (
                <MenuItem value={id} key={index + "_" + id}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>,
        <FormControl
          fullWidth
          name="Sort Order"
          style={{
            width: "70px"
          }}
        >
          <TextField
            value={data.sort_order}
            onChange={handlePrimaryDataSortOrder(index)}
          />
        </FormControl>,
        <span style={{ width: "50px" }}>
          <ReactTooltip id="remove">
            <span>Remove section</span>
          </ReactTooltip>
          <Button
            size="sm"
            color="success"
            data-tip
            data-for="remove"
            onClick={() => handlePrimaryTabRowRemove(index)}
          >
            <ClearIcon />
          </Button>
        </span>
      ]);
    });

    return returnData;
  };

  const getPrimaryTab = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ float: "right" }}>
            <Button
              size="sm"
              color="info"
              onClick={() => handleAddPrimaryTabRow(1)}
            >
              Add Section
            </Button>
          </div>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Table
            tableShopping
            tableHead={[
              // "Sort #",
              "Component",
              "Sort #",
              ""
            ]}
            tableData={getPrimaryTabTableData()}
          />
        </GridItem>
      </GridContainer>
    );
  };

  return getPrimaryTab();
};

export default ComponentSelector;
