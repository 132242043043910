import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  partner_mapping: {
    data: null,
    loading: false,
    isSaved: false,
    error: null,
    isDeleted: false
  }
};

const partnerMappingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PARTNER_PARTNERS_MAPPING_REQUEST:
      return {
        ...state,
        partner_mapping: {
          ...state.partner_mapping,
          data: null,
          loading: true,
          isSaved: false,
          error: null,
          isDeleted: false
        }
      };
    case actionTypes.SAVE_PARTNER_PARTNERS_MAPPING_FAILURE:
      return {
        ...state,
        partner_mapping: {
          ...state.partner_mapping,
          data: null,
          loading: false,
          isDeleted: false,
          isSaved: false,
          error: action.payload
        }
      };
    case actionTypes.SAVE_PARTNER_PARTNERS_MAPPING_SUCCESS:
      return {
        ...state,
        partner_mapping: {
          ...state.partner_mapping,
          data: null,
          loading: false,
          isDeleted: false,
          isSaved: true,
          error: null
        }
      };
    case actionTypes.GET_PARTNER_MAPPING_REQUEST:
      return {
        ...state,
        partner_mapping: {
          ...state.partner_mapping,
          data: null,
          loading: true,
          isSaved: false,
          isDeleted: false,
          error: null,
          isDeleted: false
        }
      };
    case actionTypes.GET_PARTNER_MAPPING_FAILURE:
      return {
        ...state,
        partner_mapping: {
          ...state.partner_mapping,
          data: null,
          loading: false,
          isSaved: false,
          isDeleted: false,
          error: action.payload
        }
      };
    case actionTypes.GET_PARTNER_MAPPING_SUCCESS:
      return {
        ...state,
        partner_mapping: {
          ...state.partner_mapping,
          data: action.payload,
          loading: false,
          isSaved: false,
          isDeleted: false,
          error: null
        }
      };

    case actionTypes.REMOVE_PARTNER_PARTNERS_MAPPING_REQUEST:
      return {
        ...state,
        partner_mapping: {
          ...state.partner_mapping,
          loading: true,
          isSaved: false,
          isDeleted: false,
          error: null
        }
      };
    case actionTypes.REMOVE_PARTNER_PARTNERS_MAPPING_FAILURE:
      return {
        ...state,
        partner_mapping: {
          ...state.partner_mapping,
          loading: false,
          isSaved: false,
          isDeleted: false,
          error: action.payload
        }
      };
    case actionTypes.REMOVE_PARTNER_PARTNERS_MAPPING_SUCCESS:
      return {
        ...state,
        partner_mapping: {
          ...state.partner_mapping,
          loading: false,
          isSaved: false,
          isDeleted: true,
          error: null
        }
      };

    default:
      return state;
  }
};

export default partnerMappingReducer;
