// import axios from 'axios';
import axios from "../utils/axios.jsx";
import { localStorageUtils } from "../utils";

export const saveBrandData = async Savedata => {
  try {
    let data = new FormData();
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("api/admin/user/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBrandData = async (role, isList) => {
  try {
    const response = await axios.get(
      "api/admin/user/getall?role=" + role + "&isList=" + isList,
      null,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUesr = async api_key => {
  try {
    let data = new FormData();
    data.append("api_key", api_key);

    const response = await axios.post("/api/admin/user/delete/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductTemplateByBrandID = async () => {
  try {
    const response = await axios.get(
      "api/brands/getall/brands/",
      null,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveBrandProductTemplates = async (
  userID,
  partnerID,
  brandTemplateData
) => {
  try {
    let data = new FormData();
    data.append("data", JSON.stringify(brandTemplateData));
    data.append("userid", userID);
    data.append("partner_id", partnerID);
    const response = await axios.post("api/brands/product/attach/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAssignedProductTemplates = async data => {
  try {
    let form_data = new FormData();
    form_data.append("data", JSON.stringify(data));
    const response = await axios.post(
      "api/brands/assignedtemplate/getall/",
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUniversalAttributes = async () => {
  try {
    const response = await axios.get(
      "api/product/template/getuniversalattribute/"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createUniversalAttributes = async (userid, data) => {
  try {
    var form_data = new FormData();
    form_data.append("userid", userid);
    form_data.append("data", JSON.stringify(data));
    const response = await axios.post(
      "api/product/template/saveuniversalattribute/",
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAttributeGroups = async (page, search) => {
  try {
    let url = "api/products/attributegroups/getall/?page=" + page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createAttributeGroup = async (userid, data) => {
  try {
    var form_data = new FormData();
    form_data.append("userid", userid);
    form_data.append("data", JSON.stringify(data));
    const response = await axios.post(
      "api/products/createattributegroup/",
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const mapWholesalerBrand = async (userId, mapData, role) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("role", role);
    data.append("data", JSON.stringify(mapData));

    const response = await axios.post("/api/partner/attachpartners/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllMapBrandsCategorisedProductTemplates = async (
  userId,
  mapData
) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(mapData));

    const response = await axios.post(
      "/producttemplate/wholesaler/brands/categorised/list/$",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllMapWholesalerCategorisedProductTemplates = async brandid => {
  try {
    const response = await axios.get(
      "/api/producttemplate/wholesaler/brands/categorised/list/?id=" + brandid
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "An error has occured while getting list of Product Templates"
      );
    }
  } catch (error) {
    throw error;
  }
};

export const getAllMapWholesalerCategorisedProductTemplatesSelected = async brandid => {
  try {
    const response = await axios.get(
      "/api/producttemplate/get/wholesaler/brands/categorised/list/?id=" +
        brandid
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "An error has occured while getting list of Product Templates"
      );
    }
  } catch (error) {
    throw error;
  }
};

export const getAllMapRetailerCategorisedProductTemplatesSelected = async (brandid, source) => {
  try {
    const response = await axios.get(
      "/api/producttemplate/get/retailer/partners/categorised/list/?id=" +
        brandid+'&source='+source
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "An error has occured while getting list of Product Templates"
      );
    }
  } catch (error) {
    throw error;
  }
};

export const getAllMapRetailerCategorisedProductTemplates = async brandid => {
  try {
    const response = await axios.get(
      "/api/producttemplate/retailer/partners/categorised/list/?id=" + brandid
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "An error has occured while getting list of Product Templates"
      );
    }
  } catch (error) {
    throw error;
  }
};

export const mapRetailerBrand = async (userId, mapData) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(mapData));

    const response = await axios.post("/api/partner/attachpartners/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveAttributeGroup = async (userid, data) => {
  try {
    var form_data = new FormData();
    form_data.append("userid", userid);
    form_data.append("data", JSON.stringify(data));
    const response = await axios.post(
      "api/partner/attibute-group/save/",
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRelatedPartner = async (role, partnerid) => {
  try {
    const response = await axios.get(
      "api/partner/partners/?role=" + role + "&partnerid=" + partnerid,
      null,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAttributeGroupById = async attributegroupid => {
  try {
    const response = await axios.get(
      "api/product/attribute-group-byid/?id=" + attributegroupid,
      null,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAttributeById = async attributeid => {
  try {
    const response = await axios.get(
      "api/product/attribute/delete/?attributeid=" + attributeid,
      null,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
