import React, { Component } from "react";
import { Edit, Delete } from "@material-ui/icons";

const Style = {
  icon: {
    cursor: "pointer",
    padding: "2px"
  },
  input: {
    display: "none !important"
  },
  icon_size: {
    height: "20px",
    width: "20px"
  }
};
class BrandCategoryActionButton extends Component {
  constructor(props) {
    super(props);

    this.delete = this.delete.bind(this);
    this.view = this.view.bind(this);
    this.edit = this.edit.bind(this);
  }

  invokeParentMethod() {
    const { data } = this.props;
    return (
      <div
        style={{
          lineHeight: "60px"
        }}
      >
        <div>
          {this.props.data.role === "CAT" && (
            <>
              <span
                onClick={() => this.edit(data.id)}
                title="Edit Category"
                style={Style.icon}
              >
                <Edit style={Style.icon_size} />
              </span>
              <span
                onClick={() => this.delete(data.id)}
                title="Delete Category"
                style={Style.icon}
              >
                <Delete style={Style.icon_size} />
              </span>
            </>
          )}
        </div>
      </div>
    );
  }

  edit(data) {
    this.props.context.componentParent.handelEdit(data);
  }

  delete(catId) {
    this.props.context.componentParent.delete(catId, 1);
  }
  view() {
    this.props.context.componentParent.view();
  }

  render() {
    return <div>{this.invokeParentMethod()}</div>;
  }
}
export default BrandCategoryActionButton;
