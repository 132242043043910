import React from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import Button from "components/CustomButtons/Button.jsx";
import AddIcon from "@material-ui/icons/Add";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import OrderAction from "../../components/AgGrid/OrderAction.jsx";
import CustomPaginationPanel from "./CustomPaginationPanel.jsx";
import GridSearch from "./GridSearch.jsx";

import { LoadingOverlay, Loader } from "react-overlay-loader";

import CircularProgress from "@material-ui/core/CircularProgress";
// let agGridEditApi = null;

function getCheckboxCellRenderer() {
  function CheckboxCellRenderer() { }
  CheckboxCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");
    let icon =
      "<span class='hierarchy-tab-label-initial product-label-color'>P</span>";
    switch (params.node.data.role) {
      case "WS":
        icon =
          "<span class='hierarchy-tab-label-initial wholesalor-label-color'>W</span>";
        break;
      case "BR":
        icon =
          "<span class='hierarchy-tab-label-initial brand-label-color'>B</span>";
        break;
      case "CAT":
        icon =
          "<span class='hierarchy-tab-label-initial category-label-color'>C</span>";
        break;
      case "P":
        icon =
          "<span class='hierarchy-tab-label-initial product-label-color'>P</span>";
        break;
    }
    tempDiv.innerHTML = "<span>" + icon + params.value + "</span>";
    this.eGui = tempDiv.firstChild;
  };
  CheckboxCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return CheckboxCellRenderer;
}

function getSimpleCellRenderer() {
  function SimpleCellRenderer() { }
  SimpleCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement("div");

    tempDiv.innerHTML = "<span>" + params.value + "</span>";
    this.eGui = tempDiv.firstChild;
  };
  SimpleCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return SimpleCellRenderer;
}

class AgGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNodes: null,
      context: { componentParent: this },
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        floatingFilter: true
      },
      frameworkComponents: {
        AgGridActionButton: this.props.AgGridActionButton,
        gridActionComponent: OrderAction
        //  partnerAgGridActionsButton: partnerAgGridActionsButton
      },
      components: {
        checkboxCellRenderer: getCheckboxCellRenderer(),
        unLabeledCheckboxCellRender: getSimpleCellRenderer()
      },
      domLayout: "autoHeight",
      rowData: []
    };
    this.agGridApi = null;
    this.selectedNodes = null;
  }

  handelEdit = id => {
    this.props.handelEdit(id);
  };

  handleEdit = id => {
    this.props.handleEdit(id);
  };

  handleCloneProduct = id => {
    this.props.handleCloneProduct(id);
  };

  handleExport = id => {
    this.props.handleExport(id);
  };

  handelDelete = id => {
    this.props.handelDelete(id);
  };

  handleDelete = id => {
    this.props.handleDelete(id);
  };

  publish = id => {
    this.props.publish(id);
  };

  performAction = (id, value) => {
    this.props.performAction(id, value);
  };

  delete = (id, callfunction = 0) => {
    if (callfunction == 1) {
      this.props.delete(true, id);
    } else {
      return;
    }
  };

  map = id => {
    this.props.handelMap(id);
  };

  openClassComponentDialog = id => {
    this.props.openClassComponentDialog(id);
  };

  view = data => {
    this.props.handelView(data);
  };

  onSelectionChanged(event) {
    if (
      this.props &&
      this.props.onSelectionChanged &&
      !this.props.isInitialSet
    ) {
      this.props.onSelectionChanged(event);
    }
  }

  onRowSelected(event) {
    this.props.onRowSelected(event);
  }

  signinUser(email) {
    this.props.signinUser(email);
  }

  handelSettlementOpen(id, api_key) {
    this.props.handelSettlementOpen(id, api_key);
  }

  editBrandTemplateMapping(id, role, action) {
    this.props.editBrandTemplateMapping(id, role, action);
  }

  editRetailerWholesalerMapping(id) {
    this.props.editRetailerWholesalerMapping(id);
  }

  editProductTemplateMapping(id, role) {
    this.props.editProductTemplateMapping(id, role);
  }

  handelPasswordChange(id, api_key) {
    this.props.handelPasswordChange(id, api_key);
  }

  handleSitePublish(id) {
    this.props.handleSitePublish(id);
  }

  onGridReady = params => {
    this.agGridApi = params.api;
    if (this.agGridApi) this.agGridApi.sizeColumnsToFit();
  };

  mapPartnerServices = id => {
    this.props.mapPartnerServices(id);
  };

  handleCopy(id) {
    this.props.handleCopy(id);
  }

  handelAssignCMSPages(id, api_key) {
    this.props.handelAssignCMSPages(id);
  }

  handelCloneSite(id, api_key) {
    this.props.handelCloneSite(id);
  }

  handelAdd(index) {
    this.props.handelAdd(index);
  }

  /// for Product Publish ////
  handelPublish(id) {
    this.props.handelPublish(id);
  }

  getRowStyle(node) {
    if (node && node.data && node.data.role === "RE") {
      return { "background-color": "#bed9dc" };
    } else if (node && node.data && node.data.role === "BR") {
      return { "background-color": "#9ae19d" };
    } else if (node && node.data && node.data.role === "WS") {
      return { "background-color": "#c6c6c6" };
    }
  }

  setSelections(selectedItems, optionalParams) {
    let grid = this.agGridApi;
    if (grid && optionalParams) {
      if (optionalParams.toUpperCase() === "CATEGORY") {
        grid.forEachNode(function (node) {
          if (node.data) {
            if (selectedItems.indexOf(node.data.id) > -1) {
              node.setSelected(true);
            } else {
              node.setSelected(false);
            }
          }
        });
      } else if (
        optionalParams &&
        optionalParams.toUpperCase() === "PARTNER_MAPPING"
      ) {
        grid.forEachNode(function (node) {
          if (node.data) {
            let index = selectedItems.findIndex(
              x => x.id === node.data.id && x.role === node.data.role
            );
            if (index > -1) {
              node.setSelected(true);
            } else {
              node.setSelected(false);
            }
          }
        });
      } else if (optionalParams && optionalParams.toUpperCase() === "OTHER") {
        grid.forEachNode(function (node) {
          if (node.data) {
            if (selectedItems.indexOf(node.data.id) > -1) {
              node.setSelected(true);
            } else {
              node.setSelected(false);
            }
          }
        });
      } else if (optionalParams && optionalParams.toUpperCase() === "SERVICE") {
        grid.forEachNode(function (node) {
          if (node.data) {
            let index = selectedItems.findIndex(x => x.id === node.data.id);
            if (index > -1) {
              node.setSelected(true);
            } else {
              node.setSelected(false);
            }
          }
        });
      } else if (optionalParams && optionalParams.toUpperCase() === "BRAND_PUSH") {
        grid.forEachNode(function (node) {
          if (node.data) {
            if (node.data.role === 'PR' && selectedItems.indexOf(node.data.id) > -1) {
              node.setSelected(true);
            } else {
              node.setSelected(false);
            }
          }
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rowData) {
      this.setState(
        {
          ...this.state,
          rowData: nextProps.rowData
        },
        () => {
          this.agGridApi && this.agGridApi.setRowData(this.state.rowData);
        }
      );
    }
  }

  componentDidMount() {
    if (this.props.rowData) {
      this.setState(
        {
          ...this.state,
          rowData: this.props.rowData
        },
        () => {
          this.agGridApi && this.agGridApi.setRowData(this.state.rowData);
        }
      );
    }
  }

  render() {
    const { classes } = this.props;
    const {
      rowData,
      columnDefs,
      is_map,
      loading,
      autoGroupColumnDef,
      onGridReady,
      pagesize,
      isServerPagination
    } = this.props;
    let columns = [];
    if (!this.state.frameworkComponents.AgGridActionButton) {
      for (let i = 0; i < columnDefs.length; i++) {
        if (columnDefs[i].headerName !== "Actions") {
          columns.push(columnDefs[i]);
        }
      }
    } else {
      columns = columnDefs;
    }
    return (
      <LoadingOverlay>
        <div
          style={{
            padding: "0 0 5px 0"
          }}
        >
          {/* {isServerPagination && this.props.isAddBtn ?
                        <GridSearch
                            search={this.searchText}
                        /> : ''
                    } */}
          {isServerPagination ? <GridSearch search={this.searchText} /> : ""}
          {this.props.isAddBtn && (
            <div className="add-new-btn">
              <Button
                disabled={loading}
                color="success"
                onClick={() => this.handelIsAddNew(true)}
                className="aggrid-new-button"
              >
                <AddIcon /> New
              </Button>
            </div>
          )}
          {this.props.isProductExportBtn && (
            <div className="add-new-btn">
              <Button
                disabled={loading}
                color="success"
                onClick={() => this.handelProductExport(true)}
                className="aggrid-new-button"
              >
                <CloudDownloadIcon /> Export Products
              </Button>
            </div>
          )}
          {this.props.isExportBtn && (
            <div className="add-new-btn">
              <Button
                disabled={loading}
                color="success"
                onClick={() => this.handelExport(true)}
                className="aggrid-new-button"
              >
                <CloudDownloadIcon /> Export
              </Button>
            </div>
          )}
        </div>
        <div
          id="myGrid"
          style={{
            width: "100%"
          }}
          className="ag-theme-material"
        >
          <AgGridReact
            columnDefs={columns}
            rowSelection={
              this.props.rowSelection ? this.props.rowSelection : "single"
            }
            defaultColDef={this.state.defaultColDef}
            rowData={this.state.rowData}
            frameworkComponents={this.state.frameworkComponents}
            floatingFilter={true}
            context={this.state.context}
            groupSuppressAutoColumn={
              this.props.groupSuppressAutoColumn
                ? this.props.groupSuppressAutoColumn
                : false
            }
            suppressRowClickSelection={
              this.props.suppressRowClickSelection
                ? this.props.suppressRowClickSelection
                : false
            }
            components={this.state.components}
            groupSelectsChildren={
              this.props.groupSelectsChildren
                ? this.props.groupSelectsChildren
                : false
            }
            autoGroupColumnDef={autoGroupColumnDef}
            pagination={this.props.isPagination ? this.props.isPagination : true}
            paginationPageSize={
              isServerPagination ? this.props.pagination.pagesize : 10
            }
            icons={this.state.icons}
            onGridReady={this.onGridReady}
            domLayout={
              this.state.domLayout ? this.state.domLayout : "autoHeight"
            }
            // onSelectionChanged={this.onSelectionChanged.bind(this) }
            // onRowSelected={this.onRowSelected.bind(this)}
            action_isMap={is_map}
            suppressPaginationPanel={isServerPagination}
            getNodeChildDetails={
              this.props.isHierarchyView ? this.hierarchicalView : null
            }
          />
        </div>
        {isServerPagination ? (
          <CustomPaginationPanel
            pagination={this.props.pagination}
            currentpage={this.props.pagination.page}
            totalpage={this.props.pagination.totalpage}
            nextpage={this.props.pagination.next}
            prevpage={this.props.pagination.prev}
            update={this.updatedPageData}
          />
        ) : (
          ""
        )}

        {loading && (
          <CircularProgress
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-25px",
              marginLeft: "-12px"
            }}
          />
        )}
        <Loader loading={loading} />
      </LoadingOverlay>
    );
  }

  hierarchicalView = data => {
    return this.props.getNodeChildDetails(data);
  };
  handelIsAddNew = param => {
    this.props.new(param);
  };

  handelProductExport = param => {
    this.props.handleProductExport(param);
  };

  handelExport = param => {
    this.props.export(param);
  };

  updatedPageData = page => {
    this.props.update(page);
  };

  searchText = param => {
    this.props.search(param);
  };
}

AgGrid.propTypes = {
  // rowData: PropTypes.array.isRequired,
  columnDefs: PropTypes.array.isRequired
  // handelEdit: PropTypes.func.isRequired,
  // // signinUser: PropTypes.func.isRequired,
  // handelSettlementOpen: PropTypes.func.isRequired,
  // editBrandTemplateMapping: PropTypes.func.isRequired,
  // editRetailerWholesalerMapping: PropTypes.func.isRequired,
  // editProductTemplateMapping: PropTypes.func.isRequired,
  // handelPasswordChange: PropTypes.func.isRequired
};

export default AgGrid;
