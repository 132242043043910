export const CREATE_SERVICES_REQUEST = "create_services_request";
export const CREATE_SERVICES_FAILURE = "create_services_failure";
export const CREATE_SERVICES_SUCCESS = "create_services_success";

export const GET_SERVICES_REQUEST = "get_services_request";
export const GET_SERVICES_FAILURE = "get_services_failure";
export const GET_SERVICES_SUCCESS = "get_services_success";

export const DELETE_SERVICES_REQUEST = "delete_services_request";
export const DELETE_SERVICES_FAILURE = "delete_services_failure";
export const DELETE_SERVICES_SUCCESS = "delete_services_success";

export const GET_SERVICE_DETAIL_REQUEST = "get_service_detail_request";
export const GET_SERVICE_DETAIL_FAILURE = "get_service_detail_failure";
export const GET_SERVICE_DETAIL_SUCCESS = "get_service_detail_success";

export const SAVE_SERVICE_PARTNER_MAPPING_REQUEST =
  "get_service_partner_mapping_request";
export const SAVE_SERVICE_PARTNER_MAPPING_FAILURE =
  "get_service_partner_mapping_failure";
export const SAVE_SERVICE_PARTNER_MAPPING_SUCCESS =
  "get_service_partner_mapping_success";

export const GET_ASSIGNED_SERVICE_PARTNER_MAPPING_REQUEST =
  "get_assigned_service_partner_mapping_request";
export const GET_ASSIGNED_SERVICE_PARTNER_MAPPING_FAILURE =
  "get_assigned_service_partner_mapping_failure";
export const GET_ASSIGNED_SERVICE_PARTNER_MAPPING_SUCCESS =
  "get_assigned_service_partner_mapping_success";
