import * as assetManagerService from "../../services/assetManagerService";
import * as actionTypes from "./actionTypes";

export const createFolderAction = (userid, data) => async dispatch => {
  dispatch(createFolderRequest());
  try {
    let successnew = await assetManagerService.createFolder(userid, data);
    if (successnew.status && successnew.status === "1") {
      dispatch(createFolderSuccess(successnew.data));
    } else {
      dispatch(createFolderFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(createFolderFailure(error.message));
  }
};

const createFolderRequest = () => {
  return {
    type: actionTypes.CREATE_FOLDER_REQUEST
  };
};

const createFolderSuccess = data => {
  return {
    type: actionTypes.CREATE_FOLDER_SUCCESS,
    payload: data
  };
};

const createFolderFailure = error => {
  return {
    type: actionTypes.CREATE_FOLDER_FAILURE,
    payload: error
  };
};

export const uploadFileAction = (userid, data) => async dispatch => {
  dispatch(uploadFileRequest());
  try {
    let successnew = await assetManagerService.uploadFile(userid, data);
    if (successnew.status && successnew.status === "1") {
      dispatch(uploadFileSuccess(successnew.data));
    } else {
      dispatch(uploadFileFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(uploadFileFailure(error.message));
  }
};

const uploadFileRequest = () => {
  return {
    type: actionTypes.UPLOAD_FILE_REQUEST
  };
};

const uploadFileSuccess = data => {
  return {
    type: actionTypes.UPLOAD_FILE_SUCCESS,
    payload: data
  };
};

const uploadFileFailure = error => {
  return {
    type: actionTypes.UPLOAD_FILE_FAILURE,
    payload: error
  };
};

export const getAssetListAction = (
  userid,
  folderId,
  type,
  page = 0
) => async dispatch => {
  dispatch(assetListRequest());
  try {
    let successnew = await assetManagerService.getAssetList(
      userid,
      folderId,
      type,
      page
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(assetListSuccess(successnew.data));
    } else {
      dispatch(assetListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(assetListFailure(error.message));
  }
};

const assetListRequest = () => {
  return {
    type: actionTypes.ASSET_LIST_REQUEST
  };
};

const assetListSuccess = data => {
  return {
    type: actionTypes.ASSET_LIST_SUCCESS,
    payload: data
  };
};

const assetListFailure = error => {
  return {
    type: actionTypes.ASSET_LIST_FAILURE,
    payload: error
  };
};

export const deleteAction = (user, id, type) => async dispatch => {
  dispatch(deleteRequest());
  try {
    let successnew = await assetManagerService.deleteFileFolder(user, id, type);
    if (successnew.status && successnew.status === "1") {
      dispatch(deleteSuccess(successnew.data));
    } else {
      dispatch(deleteFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(deleteFailure(error.message));
  }
};

const deleteRequest = () => {
  return {
    type: actionTypes.DELETE_REQUEST
  };
};

const deleteSuccess = data => {
  return {
    type: actionTypes.DELETE_SUCCESS,
    payload: data
  };
};

const deleteFailure = error => {
  return {
    type: actionTypes.DELETE_FAILURE,
    payload: error
  };
};
