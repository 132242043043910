import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";
import FormLabel from "@material-ui/core/FormLabel";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ImageUpload from "../components/ImageUpload";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { validationUtils } from "../../utils";
import draftToHtml from "draftjs-to-html";
// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import htmlToDraft from "html-to-draftjs";
import ReactTooltip from "react-tooltip";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import AgGridActionButton from "../../components/AgGrid/AgGridActionButton.jsx";

import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineRounded from "@material-ui/icons/HelpOutlineRounded";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      isAddNew: false,
      addServiceData: {
        name: "",
        description: "",
        is_active: false,
        segments: [],
        classarray: [],
        image: ""
      },
      required: {
        name: "",
        description: ""
      },
      serviceData: null,
      error: null,
      openDeleteDialog: false,
      serviceId: null,
      serviceValue: null,
      selectedUAIndexes: [],
      mappingData: {
        classes: null,
        attribute_group: null,
        description: "",
        is_active: true
      },
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 370,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Description",
          field: "description",
          width: 370,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ],
      classlist: [],
      segmentlist: [],
      data: {}
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      let serviceData = [];
      if (nextProps.List && nextProps.List.length) {
        serviceData = nextProps.List;
      }
      this.setState({
        serviceData: serviceData,
        pagination: nextProps.pagination
      });
      if (nextProps.Error && nextProps.Error.trim() !== "") {
        this.setState(() => ({
          error: nextProps.Error,
          isAddNew: true
        }));
      }
      if (nextProps.isSaved) {
        this.setState(
          {
            isAddNew: false,
            isEdit: true
          },
          () => {
            this.props.actions.getServicesList(0, this.state.pagination.page);
          }
        );
      }
      if (nextProps.isDeleted) {
        this.setState(() => ({
          openDeleteDialog: false
        }));
      }
      if (nextProps.SegmentList) {
        let list = nextProps.SegmentList;
        list.sort(function (a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        this.setState({
          segmentlist: list
        });
      }
      if (nextProps.ClassList) {
        let { classes } = this.state.data;
        if (
          this.props.ClassList &&
          !_.isEqual(nextProps.ClassList, this.props.ClassList)
        ) {
          let newclass = _.difference(
            nextProps.ClassList,
            this.props.ClassList
          );
          for (let attr_index in newclass) {
            const index = classes.findIndex(
              x => x.id == newclass[attr_index].id
            );
            if (index == -1) {
              classes.push(newclass[attr_index]);
            }
          }
          this.props.setProductData("classes", classes);
        }
        let classlist = nextProps.ClassList;
        classlist.sort(function (a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        this.setState({
          classlist: classlist
        });
      }
      if (
        nextProps.serviceDetail &&
        nextProps.serviceDetail.data &&
        nextProps.serviceDetail.data.length > 0 &&
        !nextProps.isImageDeleted
      ) {
        this.setEditData(nextProps.serviceDetail.data[0]);
      }

      if (nextProps.isImageDeleted) {
        const { addServiceData } = this.state;
        delete addServiceData.image;
        this.setState(
          {
            addServiceData: {
              ...this.state.addServiceData,
              image: null
            }
          },
          () => {
            this.props.actions.denyRemoveImageAction();
          }
        );
      }
    }
  }

  componentDidMount() {
    this.props.actions.getSegmentsList(this.props.UserId);
    this.props.actions.getClassList(this.props.PartnerId, 2);
    this.props.actions.getServicesList(0, this.state.pagination.page);
    // if (this.props.List && this.props.List.length) {
    //     this.setState({
    //         serviceData: this.props.List
    //     })

    // } else {
    //     this.props.actions.getServicesList(0);
    // }
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      addServiceData: {
        name: "",
        description: ""
      }
    });
  };
  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      addServiceData: {
        ...this.state.addServiceData,
        [key]: event.target.value
      }
    });
  };
  handleToggleStatus = curObj => event => {
    this.setState({
      addServiceData: {
        ...this.state.addServiceData,
        is_active: event.target.checked
      }
    });
  };

  saveService = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addServiceData;
      this.props.actions.createServices(
        this.props.UserId,
        savingData,
        this.props.PartnerId
      );
    }
  };

  handelEdit = id => {
    this.props.actions.getServiceDetaile_id(id);
    // const { isEdit, serviceData } = this.state;
    // const index = serviceData.findIndex(x => x.id == id)
    // const data = serviceData;
    // if (data[index]) {
    //     this.setState({
    //         addServiceData: {
    //             name: data[index].name,
    //             description: data[index].description,
    //             index: index,
    //             id: data[index].id,
    //             image:data[index].image
    //         },
    //         isAddNew: true,
    //         isEdit: true
    //     });
    // }
  };

  setEditData = data => {
    let classes = [];
    let segment = [];
    if (data.xref_classes) {
      data.xref_classes.forEach(element => {
        classes.push(element.classes);
      });
    }
    if (data.xref_segments) {
      data.xref_segments.forEach(element => {
        segment.push(element.segment);
      });
    }
    const content = htmlToDraft(data.description);
    const contentState = ContentState.createFromBlockArray(
      content.contentBlocks,
      content.entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    this.setState({
      editorState: editorState,
      data: data
    });

    if (data) {
      this.setState({
        addServiceData: {
          name: data.name,
          description: data.description,
          classarray: classes,
          id: data.id,
          image: data.image,
          segments: segment
        },
        editorState: editorState,
        isAddNew: true,
        isEdit: true
      });
    }
  };
  deleteDialog = (state, Id, Index) => {
    this.setState({
      openDeleteDialog: state,
      serviceId: Id
    });
  };

  deleteSegment = Id => {
    this.props.actions.deleteClass(this.props.UserId, this.state.serviceId);
  };

  render() {
    return this.state.isAddNew
      ? this.renderService()
      : this.renderServiceTable();
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addServiceData.hasOwnProperty("name") ||
      (this.state.addServiceData.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addServiceData.hasOwnProperty("description") ||
      (this.state.addServiceData.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  saveHandler = () => {
    this.state.mappingData.classes = this.state.serviceValue.id;
    this.state.mappingData.attribute_group = this.state.selectedUAIndexes;
    this.props.actions.mapClassUA(this.props.UserId, this.state.mappingData);
    console.log(this.state.mappingData);
  };

  handleSegmentChange = (name, value) => {
    this.setState(
      {
        addServiceData: {
          ...this.state.addServiceData,
          [name]: value
        }
      },
      () => {
        this.setIntialValue();
      }
    );
  };

  setIntialValue() {
    this.setState({
      isValueSetCheck: true
    });
  }

  // handelImageRemove = () => {
  //     let { addServiceData } = this.state;
  //     delete addServiceData.image;
  //     this.setState({
  //         addServiceData: addServiceData
  //     });
  // }

  handelImageRemove = () => {
    // let { image } = this.state.addServiceData;

    // if (image instanceof Object){
    //     this.props.actions.removeImageAction(image.id)
    // }else{
    //     this.setState({
    //         addServiceData: {
    //             ...this.state.addServiceData,
    //             image: null
    //         }
    //     });
    // }

    let { image } = this.state.addServiceData;

    if (image instanceof Blob) {
      this.setState({
        addServiceData: {
          ...this.state.addServiceData,
          image: null
        }
      });
    } else if (image instanceof Object) {
      this.props.actions.removeImageAction(image.id);
      // delete image;
    }
  };

  handelImageChange = file => {
    if (file instanceof Blob) {
      let reader = new FileReader();
      let { addServiceData } = this.state;
      let original_name = file.name;
      let fileSize = file.size;
      reader.onloadend = () => {
        addServiceData["image"] = file;
        addServiceData["file_size"] = fileSize;
        addServiceData["original_file_name"] = original_name;
        this.setState({
          addServiceData: addServiceData
        });
      };
      reader.readAsDataURL(file);
    } else {
      let { addServiceData } = this.state;
      addServiceData.image = file.url;
      if (file.is_asset) {
        addServiceData.asset_id = file.id;
      }
      addServiceData.file_size = file.file_size;
      addServiceData.original_file_name = file.original_file_name;
      this.setState({
        ...this.state,
        addServiceData: addServiceData
      });
    }
  };

  deleteDilog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        disableBackdropClick={true}
        onClose={() => this.openModalDialog("openDialog", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              Are you sure you want to delete?{" "}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.deleteSegment()}
              >
                Delete
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.deleteDialog(false, "", "")}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    var validData = "success";
    if (!validationUtils.validateData("description", value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData["description"] = validData;
    this.setState({ required: reqData });

    this.setState({
      addServiceData: {
        ...this.state.addServiceData,
        ["description"]: value
      }
    });
  };

  renderService = () => {
    const { classes } = this.props;
    const {
      name,
      description,
      segments,
      classarray,
      image
    } = this.state.addServiceData;
    const { classlist, segmentlist, editorState } = this.state;

    const { required } = this.state;
    // const {  segments } = this.state.data
    // const classarray = this.state.data && this.state.data.classes ? this.state.data.classes : []
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <InputLabel className={classes.label + " "}>
                        Name
                      </InputLabel>
                      <TextField
                        value={name ? name : ""}
                        variant="outlined"
                        name="name"
                        fullWidth
                        placeholder="Name"
                        success={required.name === "success"}
                        error={required.name === "error"}
                        classes={{
                          root: "attribute-txtbox"
                        }}
                        onChange={this.handleTextChange("name", "")}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <br />

                      <InputLabel className={classes.label + " "}>
                        Classes
                        <Tooltip
                          className="control-tooltip"
                          title="Please select or Add new Classes"
                        >
                          <HelpOutlineRounded />
                        </Tooltip>
                      </InputLabel>
                      <br />
                      <Autocomplete
                        multiple
                        options={classlist}
                        getOptionLabel={option => option.name}
                        filterSelectedOptions
                        value={
                          classarray && classarray.length > 0 ? classarray : []
                        }
                        onChange={(event, value) =>
                          this.handleSegmentChange("classarray", value)
                        }
                        classes={{
                          root: "custom-autocomplete",
                          inputRoot: "custom-autocomplete-input-root",
                          tag: "custom-autocomplete-tag"
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Classes"
                          />
                        )}
                      />
                    </GridItem>
                    <br />
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <br />
                      <InputLabel className={classes.label + " "}>
                        Segment
                        <Tooltip
                          className="control-tooltip"
                          title="Please select or Add new Segment"
                        >
                          <HelpOutlineRounded />
                        </Tooltip>
                      </InputLabel>
                      <br />
                      <Autocomplete
                        multiple
                        options={segmentlist}
                        getOptionLabel={option => option.name}
                        filterSelectedOptions
                        value={segments && segments.length > 0 ? segments : []}
                        onChange={(event, value) =>
                          this.handleSegmentChange("segments", value)
                        }
                        classes={{
                          root: "custom-autocomplete",
                          inputRoot: "custom-autocomplete-input-root",
                          tag: "custom-autocomplete-tag"
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Segment"
                          />
                        )}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <br />
                      <InputLabel className={classes.label + " "}>
                        Description
                      </InputLabel>

                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapper"
                        editorClassName="editor"
                        onEditorStateChange={this.onEditorStateChange}
                      />

                      {/* <TextField
                                                value={description ? description : ''}
                                                variant="outlined"
                                                name='Description'
                                                fullWidth
                                                multiline
                                                rows="4"
                                                placeholder="Description"
                                                success={required.description === "success"}
                                                error={required.description === "error"}
                                                classes={{
                                                    root: 'attribute-txtbox',
                                                }}
                                                onChange={this.handleTextChange('description', "")}
                                            /> */}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <ImageUpload
                        addButtonProps={{
                          round: true
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true
                        }}
                        imgUrl={
                          image instanceof Object
                            ? image instanceof Blob
                              ? image
                              : image.url
                            : image
                        }
                        onimageRemove={this.handelImageRemove}
                        onChange={this.handelImageChange}
                      />
                    </GridItem>
                  </GridContainer>

                  {this.props.Error && this.props.Error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <Button color="info" onClick={this.saveService}>
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {this.props.Loading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderServiceTable = () => {
    const { classes } = this.props;
    const { serviceData } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Services
                </p>
              </div>
            </CardHeader>

            <CardBody>
              <AgGrid
                rowData={serviceData}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                handelView={this.openStockMoveDetailModel}
                AgGridActionButton={AgGridActionButton}
                loading={this.props.Loading}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                search={this.searchText}
                new={this.handelIsAddNew}
                isAddBtn={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
  updateGridData(page) {
    this.props.actions.getServicesList(0, page, this.state.searchtext);
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getServicesList(0, page, this.state.searchtext);
      }
    );
  }

  getTableData = () => {
    const { attributeGroups } = [];
    const { serviceData } = this.state;
    const { classes } = this.props;

    var returnData = [];
    _.map(serviceData, ({ id, name, description, is_active }, index) => {
      returnData.push([
        <span>
          {
            //eslint-disable-next-line
            <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
              {name}
            </a>
          }
        </span>,
        <span>{description}</span>,
        <span>{is_active ? "Active" : "Inactive"}</span>,
        <span>
          <ReactTooltip id="editService">
            <span>Edit Service</span>
          </ReactTooltip>
          <Button
            color="success"
            data-tip
            data-for="editService"
            className={classes.actionButton}
            onClick={() => this.handelEdit(index)}
          >
            <CreateIcon className={classes.icon} />
          </Button>

          <ReactTooltip id="deleteService">
            <span>Delete Service</span>
          </ReactTooltip>
          <Button
            color="danger"
            data-tip
            data-for="deleteService"
            className={classes.marginRight}
            style={{ padding: "5px" }}
          >
            <ClearIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>
        </span>
      ]);
    });
    return returnData;
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.serviceReducer.list,
  Loading: state.serviceReducer.loadingData,
  ListError: state.serviceReducer.error,
  isSaved: state.serviceReducer.isSaved,
  isDeleted: state.serviceReducer.isDeleted,
  UserId: state.authState.user.user,
  isSaving: state.serviceReducer.isSaving,
  SegmentList: state.SegmentsReducer.list,
  ClassList: state.classReducer.list,
  PartnerId: state.authState.user.partner_id,
  serviceDetail: state.serviceReducer.serviceDetail,
  ServiceMappingLoading: state.serviceReducer.partnerServiceMapping.loading,
  ServiceMappingerror: state.serviceReducer.partnerServiceMapping.error,
  ServiceMappingisSaved: state.serviceReducer.partnerServiceMapping.isSaved,
  isImageDeleted: state.appMenuReducer.removeImage.isDeleted,
  pagination: state.serviceReducer.pagination
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Services);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
