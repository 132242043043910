import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import FormLabel from "@material-ui/core/FormLabel";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { validationUtils } from "../../utils";

// @material-ui/core components/Dialog
import Slide from "@material-ui/core/Slide";

import ReactTooltip from "react-tooltip";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import ShippingAction from "../../components/AgGrid/ShippingAction";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ShippingMethods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      addShippingData: {
        name: "",
        descriptions: "",
        is_deleted: false,
        is_active: false,
        charges: ""
      },
      required: {
        name: "",
        descriptions: "",
        is_active: ""
      },
      ShippingData: [],
      error: null,
      openDeleteDialog: false,
      ShippingId: null,
      ShippingValue: null,
      selectedUAIndexes: [],
      mappingData: {
        Shippings: null,
        descriptions: "",
        is_active: true
      },
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 200,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Description",
          field: "description",
          width: 200,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Charges",
          field: "charges",
          width: 200,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Status",
          field: "Status",
          width: 200,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 190,
          filter: false
        }
      ]
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && !_.isEqual(this.props, nextProps)) {
      if (
        nextProps.List &&
        nextProps.List.length &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const ShippingData = nextProps.List;
        this.setState(() => ({
          ShippingData: ShippingData
        }));
      }

      if (nextProps.Error && nextProps.Error.trim() !== "") {
        this.setState(() => ({
          error: nextProps.Error,
          isAddNew: true
        }));
      }

      if (!nextProps.addError) {
        this.setState(() => ({
          isAddNew: false,
          isEdit: true
        }));
      }

      if (nextProps.isDeleted) {
        this.setState(() => ({
          openDeleteDialog: false
        }));
      }
    }
  }

  componentDidMount() {
    this.props.actions.getShippingList();
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      addShippingData: {
        name: "",
        descriptions: "",
        is_active: false,
        is_deleted: false,
        charges: ""
      },
      required: {
        name: "",
        descriptions: "",
        is_active: ""
      }
    });
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });
    this.setState({
      addShippingData: {
        ...this.state.addShippingData,
        [key]: event.target.value
      }
    });
  };

  handleToggleStatus = curObj => event => {
    this.setState({
      addShippingData: {
        ...this.state.addShippingData,
        is_active: event.target.checked
      }
    });
  };

  saveShippingType = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addShippingData;
      this.props.actions.createShipping(
        this.props.UserId,
        savingData,
        this.props.PartnerId
      );
    }
  };

  handelEdit = id => {
    const { isEdit, ShippingData } = this.state;
    const data = ShippingData;
    let index = data.findIndex(a => a.id == id);

    if (data[index]) {
      this.setState({
        addShippingData: {
          name: data[index].name,
          descriptions: data[index].description,
          index: index,
          charges: data[index].charges,
          id: data[index].id,
          is_active: data[index].is_active,
          is_deleted: false
        },
        isAddNew: true,
        isEdit: true
      });
    }
  };

  render() {
    return this.state.isAddNew
      ? this.renderShippingType()
      : this.renderShippingTypeTable();
  }
  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addShippingData.hasOwnProperty("name") ||
      (this.state.addShippingData.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addShippingData.hasOwnProperty("descriptions") ||
      (this.state.addShippingData.descriptions.trim() === "" ||
        reqData["descriptions"] === "error")
    ) {
      reqData["descriptions"] = "error";
      isValid = false;
    }

    if (
      !this.state.addShippingData.hasOwnProperty("charges") ||
      (this.state.addShippingData.charges.trim() === "" ||
        reqData["charges"] === "error") ||
      isNaN(this.state.addShippingData.charges) === true
    ) {
      reqData["charges"] = "error";
      isValid = false;
    }

    this.setState({ required: reqData });
    return isValid;
  }

  renderShippingType = () => {
    const { classes } = this.props;
    const {
      name,
      descriptions,
      is_active,
      charges
    } = this.state.addShippingData;
    const { required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <LocalShippingIcon style={{ fontSize: "36px" }} />
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <CustomInput
                    success={required.descriptions === "success"}
                    error={required.descriptions === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: descriptions,
                      onChange: this.handleTextChange("descriptions", "")
                    }}
                    id={"description_"}
                  />
                  <CustomInput
                    success={required.charges === "success"}
                    error={required.charges === "error"}
                    labelText="Charges *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: charges,
                      onChange: this.handleTextChange(
                        "charges",
                        validationUtils.validationTypes.charges
                      )
                    }}
                    id={"charges_"}
                  />
                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus(is_active)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />
                  <br />
                  {this.props.Error && this.props.Error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button color="info" onClick={this.saveShippingType}>
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {this.props.isSaving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.isSaving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderShippingTypeTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} />
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Shipping Methods{" "}
                </p>

                <p>
                  <Button
                    disabled={this.props.Loading}
                    color="success"
                    onClick={this.handelIsAddNew}
                    size="sm"
                    className={classes.marginRight + " grid-header-button"}
                  >
                    <AddIcon className="grid-header-button-icon" /> New
                  </Button>
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <AgGrid
                  rowData={this.state.ShippingData}
                  columnDefs={this.state.columnDefs}
                  handelEdit={this.handelEdit}
                  AgGridActionButton={ShippingAction}
                />
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}

                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  getTableData = () => {
    const { ShippingData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(
      ShippingData,
      ({ id, name, description, is_active, charges }, index) => {
        returnData.push([
          <span>
            {
              <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
                {name}
              </a>
            }
          </span>,
          <span>{description}</span>,
          <span>{charges}</span>,
          <span>{is_active ? "Active" : "Inactive"}</span>,
          <span>
            <ReactTooltip id="editClass">
              <span>Edit Shipping Method Type</span>
            </ReactTooltip>
            <Button
              color="success"
              data-tip
              data-for="editClass"
              className={classes.actionButton}
              onClick={() => this.handelEdit(index)}
            >
              <CreateIcon className={classes.icon} />
            </Button>
          </span>
        ]);
      }
    );
    return returnData;
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.ShippingTypeReducer.get.list,
  Loading: state.ShippingTypeReducer.get.loading,
  ListError: state.ShippingTypeReducer.get.loaderror,
  UserId: state.authState.user.pk,
  PartnerId: state.authState.user.partner_id,
  isSaving: state.ShippingTypeReducer.add.adding,
  addError: state.ShippingTypeReducer.add.adderror
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ShippingMethods);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
