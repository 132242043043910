import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import moment from "moment";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Dialog, DialogTitle, DialogContent, FormControlLabel, Checkbox, CircularProgress, FormLabel, withStyles } from "@material-ui/core";
import { GetApp as GetAppIcon, Publish as PublishIcon, Check } from '@material-ui/icons';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withAuthorization from "../../Session/withAuthorization";
import { rolesNames } from "../../../constants";
import AgGrid from "components/AgGrid/AgGrid.jsx";
import ImportAgGridAction from './GridActions';
import config from "../../../constants/config";

import { DropzoneArea } from "material-ui-dropzone";
import { getImportList, uploadImportDocument } from "../../../services/importServices";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";


function dateFormatter(params) {
    if (params.value) {
        return moment(params.value).format("MM/DD/YYYY");
    } else {
        return "";
    }
}

function successCounter(params) {
    if (params?.data?.data && Array.isArray(params.data.data)) {
        let successImport = params.data.data.filter(x => x?.Status?.toUpperCase() === "SUCCESS")
        return successImport.length;
    } else {
        return "";
    }
}

function failedCounter(params) {
    if (params?.data?.data && Array.isArray(params.data.data)) {
        let successImport = params.data.data.filter(x => x?.Status?.toUpperCase() !== "SUCCESS")
        return successImport.length;
    } else {
        return "";
    }
}

class ImportBrandPartner extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            templateType: '',
            isTempSelection: false,
            _agGridAPI: null,
            _agGridColumnAPI: null,
            disableView: false,
            importStatus: "Calculating data to be imported....",
            open: false,
            columnDefs: [
                {
                    headerName: "ID",
                    field: "id",
                    width: 100,
                    resizable: true,
                    filter: "agTextColumnFilter"
                },
                {
                    headerName: "File",
                    field: "filename",
                    width: 250,
                    resizable: true,
                    filter: "agTextColumnFilter"
                },
                {
                    headerName: "Date",
                    field: "uplaoded_at",
                    width: 150,
                    sortable: true,
                    resizable: true,
                    valueFormatter: dateFormatter
                },
                {
                    headerName: "Success",
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: successCounter
                },
                {
                    headerName: "Failed",
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: failedCounter
                },
                {
                    headerName: "Status",
                    field: "status",
                    resizable: true,
                    filter: "agTextColumnFilter"
                },
                {
                    headerName: "Report",
                    field: "value",
                    cellRenderer: "AgGridActionButton",
                    colId: "params",
                    width: 100,
                    filter: false
                }
            ],
            context: { componentParent: this },
            autoGroupColumnDef: {
                minWidth: 200
            },
            frameworkComponents: {
                AgGridActionButton: ImportAgGridAction,
            },
            rowData: [],
            rowClassRules: {
                "yellow-ready": function (params) {
                    return params.data && params.data.status === "PROCESSING";
                },
                "green-success": function (params) {
                    return params.data && params.data.status === "SUCCESS";
                },
                "red-error": function (params) {
                    return params.data && params.data.status === "ERROR";
                }
            }
        };
        this.templateSelection = this.templateSelection.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    componentWillReceiveProps(nextProps) {

    }

    componentDidMount() {
        this.refreshList(this.props.PartnerId);
    }

    refreshList = (partnerid) => {
        getImportList((this.props.PartnerRole == "AD" ? "BRAND-PRODUCT" : "RETAILER-PRODUCT"), partnerid)
            .then(data => {
                console.log("data", data);

                if (data?.status === "1") {
                    this.setState({
                        rowData: data.data
                    });
                }
                else {
                    this.setState({
                        error: "An error has occured while loading previous imports, please try again later."
                    });
                }
            })
            .catch(reason => {

                this.setState({
                    error: "An error has occured while loading previous imports, please try again later."
                });

                console.error("Import get api call error", reason);

            })
            .finally(info => console.log(info));
    }


    uploadFile = () => {
        if (!this.state.files) {
            this.setState({
                success: "",
                error: "Can not upload blank file"
            });

            return false;
        }

        this.setState({
            success: "",
            error: "",
            uploading: true,
        });

        uploadImportDocument((this.props.PartnerRole == "AD" ? "BRAND-PRODUCT" : "RETAILER-PRODUCT"), this.state.files, this.props.PartnerId)
            .then(data => {
                console.log("data", data);

                if (data?.status === "1") {

                    this.setState({
                        success: data.data,
                        uploading: false,
                    });

                    this.refreshList(this.props.PartnerId)
                }
                else {
                    this.setState({
                        error: "An error has occured while uploading document, please try again later.",
                        uploading: false,
                    });
                }
            })
            .catch(reason => {

                this.setState({
                    error: "An error has occured while uplaoding document, please try again later.",
                    uploading: false,
                });

                console.error("Import api call error", reason);

            })
            .finally(info => console.log(info));
    }

    render() {
        return (
            <>
                {this.renderTagsType()}
            </>
        );
    }

    handleChange(files) {
        this.setState({
            files: files
        })
    }

    removeFile() {
        this.setState({
            files: null
        });
    }

    handleDownload = (id) => {
        console.log("id", id);
        let reqUrl = `${config.env.API_URL}mgmt/import/result/?id=${id}`
        var win = window.open(reqUrl, '_blank');
    }

    templateDownload = () => {
        const { templateType } = this.state
        let filename = ''
        if (templateType === 1) {
            filename = 'brand_product_template.csv'
        } else {
            filename = 'store_product_template.csv'
        }

        let reqUrl = `${config.env.API_URL}mgmt/import/template/?file=${filename}`
        var win = window.open(reqUrl, '_blank');
    }

    templateSelection() {
        this.setState({
            isTempSelection: true
        })
    }

    handleCloseDialog() {
        this.setState({
            isTempSelection: false
        })
    }

    handleToggleStatus(type) {
        this.setState({
            ...this.state,
            templateType: type
        })
    }

    templateSelectionView() {
        const { classes } = this.props
        const { templateType } = this.state
        return (
            <Dialog
                open={this.state.isTempSelection}
                // TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleCloseDialog()}
                aria-labelledby="signup-modal-slide-title"
                aria-describedby="signup-modal-slide-description"
            >
                <DialogTitle>Choose Template type</DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                // tabIndex={-1}
                                checked={templateType === 1 ? true : false}
                                onClick={() => this.handleToggleStatus(1)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                }}
                            />
                        }
                        classes={{ label: classes.label }}
                        label='Brand Product Template'
                    />
                    <br />
                    <FormControlLabel
                        control={
                            <Checkbox
                                // tabIndex={-1}
                                checked={templateType === 2 ? true : false}
                                onClick={() => this.handleToggleStatus(2)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                }}
                            />
                        }
                        classes={{ label: classes.label }}
                        label='Retailer Product Template'
                    />
                    <br />
                    <Button
                        disabled={templateType === ''}
                        color="primary"
                        onClick={this.templateDownload}
                        size="sm"
                    >
                        Download
                    </Button>
                    <Button
                        onClick={this.handleCloseDialog}
                        size="sm"
                    >
                        Close
                    </Button>
                </DialogContent>
            </Dialog>
        )
    }

    renderTagsType = () => {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.templateSelectionView()}
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="info" icon>
                            <p
                                className={
                                    classes.cardIconTitle + " wholesalor-hierarchy-title"
                                }
                                style={{ color: "black" }}
                            >
                                Import Brand Product
                            </p>
                            <div className="add-new-btn">
                                <Button
                                    color="success"
                                    onClick={this.uploadFile}
                                    size="sm"
                                >
                                    <PublishIcon /> Upload
                                </Button>

                                {this.props.PartnerRole == "RE" ? <Button
                                    color="primary"
                                    onClick={this.templateSelection}
                                    size="sm"
                                >
                                    <GetAppIcon /> Get Template
                                </Button> : ""}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <LoadingOverlay>
                                <DropzoneArea
                                    acceptedFiles={
                                        [".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]
                                    }
                                    onChange={this.handleChange.bind(this)}
                                    filesLimit={1}
                                    onDelete={this.removeFile.bind(this)}
                                    dropzoneText={"Browse CSV files to import brand's product"}
                                />

                                <br />
                                <br />

                                {this.state.error && (
                                    <FormLabel error>
                                        <code>{this.state.error}</code>
                                    </FormLabel>
                                )}

                                {this.state.success && (
                                    <FormLabel>
                                        <code>{this.state.success}</code>
                                    </FormLabel>
                                )}

                                {this.state.uploading && (
                                    <CircularProgress
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-25px",
                                            marginLeft: "-12px"
                                        }}
                                    />
                                )}

                                <Loader loading={this.state.uploading} text="Processing.." />
                            </LoadingOverlay>
                            <AgGrid
                                rowData={this.state.rowData}
                                columnDefs={this.state.columnDefs}
                                Loading={this.props.Loading}
                                context={this.state.context}
                                AgGridActionButton={ImportAgGridAction}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };
}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
    UserId: state.authState.user.user,
    PartnerId: state.authState.user.partner_id,
    PartnerRole: state.authState.user.role,
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN || rolesNames.RoleMaster.RETAILER;

export default compose(
    withAuthorization(condition),
    withRouter,
    withStyles({
        ...regularFormsStyle,
        ...extendedTablesStyle,
        ...validationFormsStyle,
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ImportBrandPartner);
