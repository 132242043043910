import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Assignment from "@material-ui/icons/Assignment";
import FormControl from "@material-ui/core/FormControl";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import ReactTooltip from "react-tooltip";
import { validationUtils } from "../../utils";
import * as routesNames from "../../constants/routes";
// import { rolesNames } from "../../constants"

class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      isEdit: false,
      editIndex: -1,
      file: null,
      payment: {
        payment_type_id: "",
        account: "",
        clientId: "",
        secretkey: ""
      },
      required: {
        payment_type_id: "",
        account: "",
        clientId: "",
        secretkey: ""
      },
      tableData: [],
      error: ""
    };
  }

  componentDidMount() {
    if (
      this.props.Detailslist &&
      this.props.Detailslist.length > 0 &&
      this.props.Detailslist
    ) {
      const paymentData = this.props.Detailslist;
      var result = Object.keys(paymentData).map(function(key) {
        return paymentData[key];
      });
      this.setState(() => ({
        tableData: result
      }));
    } else {
    }
    this.props.actions.getPayMethod();
    this.props.actions.getPayCred();
    console.log(this.props);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.Detailslist &&
        nextProps.Detailslist.length > 0 &&
        nextProps.Detailslist &&
        !_.isEqual(this.props.Detailslist, nextProps.Detailslist)
      ) {
        this.setState(() => ({
          tableData: nextProps.Detailslist
        }));
      }
      if (nextProps.ListError && nextProps.ListError.trim() !== "") {
        this.setState(() => ({
          error: nextProps.ListError,
          isAddNew: true
        }));
      }
      if (nextProps.isSaved) {
        this.setState(() => ({
          isAddNew: false,
          isEdit: false
        }));
      }
    }
  }

  editProductTemplateMapping = brandid => {
    this.props.actions.onNavigateTo(
      routesNames.PRODUCTTEMPLATECOMPONENT + "?entityType=brand&id=" + brandid
    );
  };

  getTableData = () => {
    const { tableData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(tableData, ({ payment_method, account, clientId }, index) => {
      returnData.push([
        <span>{payment_method}</span>,
        <span>{account}</span>,
        <span>{clientId}</span>,
        <span>
          <Button
            color="success"
            data-tip
            data-for="viewPayment"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={this.handelEdit(index)}
          >
            <CreateIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>
          <ReactTooltip id="viewPayment">
            <span>View or edit Payment details</span>
          </ReactTooltip>

          <Button
            color="danger"
            data-tip
            data-for="deletePayment"
            className={classes.marginRight}
            style={{ padding: "5px" }}
          >
            <ClearIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>
          <ReactTooltip id="deletePayment">
            <span>Delete Payment Method</span>
          </ReactTooltip>
        </span>
      ]);
    });
    return returnData;
  };

  handelIsAddNew = isAddNew => event => {
    this.setStateAddNew(isAddNew);
  };
  setStateAddNew(isAddNew) {
    this.setState({
      isAddNew: isAddNew,
      isEdit: false,
      editIndex: -1,
      payment: {
        payment_type_id: "",
        account: "",
        clientId: "",
        secretkey: ""
      },
      error: "",
      required: {
        payment_type_id: "",
        account: "",
        clientId: "",
        secretkey: ""
      }
    });
  }

  handelEdit = index => event => {
    var editData = this.state.tableData[index];
    let listData = this.props.List.find(o => o.id === index);
    var data = {
      payment_type_id: listData.payment_method,
      account: editData.account,
      clientId: editData.clientId,
      secretkey: editData.secretkey
    };
    this.setState({
      payment: data,
      isEdit: true,
      editIndex: index
    });
  };

  renderStaffTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.Loading}
              color="success"
              onClick={this.handelIsAddNew(true)}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} /> Add Payment Credentials
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>
                View, edit or delete Payment Credentials below{" "}
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <Table
                  tableHead={[
                    "payment_method",
                    "Account",
                    "clientId",
                    "Actions"
                  ]}
                  tableData={this.getTableData()}
                  tableShopping
                  customHeadCellClasses={[
                    classes.description,
                    classes.description,
                    classes.description,
                    classes.right,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                  customCellClasses={[
                    classes.tdName,
                    classes.customFont,
                    classes.customFont,
                    classes.tdNumber,
                    classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                    classes.tdNumber
                  ]}
                  customClassesForCells={[1, 2, 3, 4, 5, 6]}
                />
                {(this.props.Loading || this.props.PartnerSaving) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    var payment = this.state.payment;
    payment[key] = event.target.value;
    this.setState({ payment: payment });
  };

  renderAddStaffMember = () => {
    const { classes } = this.props;
    const {
      payment_type_id,
      name,
      account,
      clientId,
      secretkey
    } = this.state.payment;
    const { isEdit, required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>language</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Fill in fields below, then click Create Brand button to spawn
                new brand.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.label + " "}
                      labelText="Offers *"
                    >
                      Payment Method *
                    </InputLabel>
                    <Select
                      success={required.payment_type_id === "success"}
                      error={required.payment_type_id === "error"}
                      style={{ marginTop: "0px" }}
                      value={payment_type_id}
                      onChange={this.handleTextChange("payment_type_id", "")}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Select Payment Method
                      </MenuItem>
                      {_.map(
                        this.props.List,
                        ({ id, payment_method }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                              key={index + "_" + payment_method}
                            >
                              {payment_method}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                  <CustomInput
                    success={required.account === "success"}
                    error={required.account === "error"}
                    labelText="User account *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: account,
                      onChange: this.handleTextChange("account", "")
                    }}
                    id={"account"}
                  />
                  <CustomInput
                    success={required.clientId === "success"}
                    error={required.clientId === "error"}
                    labelText="Client Id *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: clientId,
                      onChange: this.handleTextChange("clientId", "")
                    }}
                    id={"clientId"}
                  />
                  <CustomInput
                    success={required.secretkey === "success"}
                    error={required.secretkey === "error"}
                    labelText="Secret Key *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: secretkey,
                      onChange: this.handleTextChange("secretkey", "")
                    }}
                    id={"secretkey"}
                  />
                  {this.state.error && this.state.error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}
                  <br />
                  <br />
                  {isEdit ? (
                    <Button color="info" onClick={this.updatePayMethod}>
                      Update Credentials
                    </Button>
                  ) : (
                    <Button color="info" onClick={this.savePayMethod}>
                      Save Credentials
                    </Button>
                  )}
                  <Button onClick={this.handelIsAddNew(false)}>Cancel</Button>
                  {this.props.Loading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  savePayMethod = () => {
    if (this.validateIsRequired()) {
      console.log(this.props.authUser);
      this.setState(
        {
          payment: {
            ...this.state.payment,
            partner_id: this.props.authUser.partner_id
          }
        },
        () => {
          this.props.actions.createPayMethod(
            this.props.authUser.pk,
            this.state.payment
          );
        }
      );
    }
  };

  updatePayMethod = () => {
    if (this.validateIsRequired()) {
      const { payment } = this.state;
      if (payment) {
        this.props.actions.createPayMethod(this.props.authUser.pk, payment);
      }
    }
  };

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.payment.hasOwnProperty("payment_type_id") ||
      (this.state.payment.payment_type_id === null ||
        reqData["payment_type_id"] === "error")
    ) {
      reqData["payment_type_id"] = "error";
      isValid = false;
    }

    if (
      !this.state.payment.hasOwnProperty("account") ||
      (this.state.payment.account.trim() === "" ||
        reqData["account"] === "error")
    ) {
      reqData["account"] = "error";
      isValid = false;
    }

    if (
      !this.state.payment.hasOwnProperty("clientId") ||
      (this.state.payment.clientId.trim() === "" ||
        reqData["clientId"] === "error")
    ) {
      reqData["clientId"] = "error";
      isValid = false;
    }

    if (
      !this.state.payment.hasOwnProperty("secretkey") ||
      (this.state.payment.secretkey.trim() === "" ||
        reqData["secretkey"] === "error")
    ) {
      reqData["secretkey"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  render() {
    return this.state.isAddNew || this.state.isEdit
      ? this.renderAddStaffMember()
      : this.renderStaffTable();
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.paymentReducer.list,
  Detailslist: state.paymentReducer.detailslist,
  Loading: state.paymentReducer.loading,
  ListError: state.paymentReducer.error,
  authUser: state.authState.user,
  isSaved: state.paymentReducer.isSaved
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PaymentMethod);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
