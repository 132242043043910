import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { validationUtils } from "../../../utils";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

class AddMenuForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.state = {
      isAddNew: false,
      cmsMenuList: [],
      menuKey: null,
      menuLink: {
        id: "",
        name: "",
        description: "",
        sort_order: "",
        status: true,
        key: null,
        issubmenu: false
      },
      required: {
        name: "",
        id: "",
        description: "",
        sort_order: "",
        status: ""
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    
    if (!_.isEqual(this.props, nextProps)) {
      
      if (this.props.source === "PARTNER") {
       
        if (nextProps && nextProps.partnerMenuList && nextProps.partnerMenuList.list && nextProps.partnerMenuList.list.length > 0) {
          this.setUrlList(nextProps.partnerMenuList.list);
        }

      }
      else {
        if (nextProps && nextProps.menuList && nextProps.menuList.length > 0) {
          this.setUrlList(nextProps.menuList);
        }
      }



      if (nextProps.formData) {
        this.setEditData(nextProps.formData);
      } else {
        this.setAddNew();
      }
    }
  }

  setAddNew = () => {
    this.setState({
      menuLink: {
        id: "",
        name: "",
        description: "",
        sort_order: 9999,
        status: true,
        key: null,
        issubmenu: false
      }
    });
  };

  setEditData = data => {
    this.setState({
      menuLink: {
        id: data.id,
        name: data.name,
        description: data.description,
        sort_order: data.sort_order,
        status: data.status,
        key: data.key,
        issubmenu: data.issubmenu
      }
    });
  };

  setUrlList = list => {
    let productsClasses = list.filter(element => element.is_active === true);

    this.setState({
      cmsMenuList: productsClasses
    });
  };

  handelIsAddNew = () => {
    this.props.onClose();
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });
    this.setState({
      menuLink: {
        ...this.state.menuLink,
        [key]: event.target.value
      }
    });
  };

  render() {
    return this.renderClass();
  }

  saveHandler = () => {
    this.props.onSave(this.state.menuLink);
  };

  componentDidMount() {
    if (this.props.source === "PARTNER") {
      if (this?.props?.partnerMenuList?.list) {
        this.setUrlList(this.props.partnerMenuList.list);
      }else {
        this.props.actions.CMSMenuList(this.props.partner_id, "CMS");
      }
    }
    else {
      if (this?.props?.menuList) {
        this.setUrlList(this.props.menuList);
      }else {
        this.props.actions.getCMSPages(null, this.props.partner_id);
      }
      
    } 
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.menuLink.hasOwnProperty("name") ||
      (this.state.menuLink.name.trim() === "" || reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  onSelectChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      menuLink: {
        ...this.state.menuLink,
        [key]: event.target.value,
        key: event.currentTarget.title,
        name: event.currentTarget.accessKey,
        issubmenu: false
      }
    });
  };

  handleToggleStatus = key => event => {
    this.setState({
      menuLink: {
        ...this.state.menuLink,
        [key]: event.target.checked
      }
    });
  };

  renderClass = () => {
    const { classes } = this.props;
    const {
      name,
      id,
      description,
      sort_order,
      status,
      key,
      issubmenu
    } = this.state.menuLink;
    const { isEdit, required, cmsMenuList } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <InputLabel className={classes.label + " "}>Page Link</InputLabel>
              <Select
                variant="outlined"
                style={{ width: "100%" }}
                success={required.id === "success"}
                error={required.id === "error"}
                value={id ? id : null}
                classes={{
                  root: "select-control-root"
                }}
                onChange={this.onSelectChange("id", "")}
                name="slturl"
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose from list
                </MenuItem>
               
                {_.map(
                  cmsMenuList,
                  ({ name, id, key, slug, is_system_page }, index) => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={id}
                        title={name}
                        accessKey={name}
                      >
                        {name}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <InputLabel className={classes.label + " "}>Link Text</InputLabel>
              <br />
              <TextField
                value={name}
                success={required.name === "success"}
                error={required.name === "error"}
                variant="outlined"
                fullWidth
                classes={{
                  root: "attribute-txtbox"
                }}
                onChange={this.handleTextChange("name", "")}
              />
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={status}
                    onClick={this.handleToggleStatus("status")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{ label: classes.label }}
                label="Active"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={issubmenu}
                    onClick={this.handleToggleStatus("issubmenu")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                    disabled={key ? false : true}
                  />
                }
                classes={{ label: classes.label }}
                label='Group by "More"'
              />
            </GridItem>
          </GridContainer>

          <Button color="info" onClick={() => this.saveHandler()}>
            Save
          </Button>
          <Button onClick={() => this.handelIsAddNew(false)}>Cancel</Button>
        </GridItem>
      </GridContainer>
    );
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  authUser: state.authState.user,
  menuList: state.cmsReducer.get_CMS_Pages.list,
  partnerMenuList: state.partnerMenuReducer.get_cms_menu_list,
});

export default compose(
  // withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddMenuForm);

