import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  get_CMS_Components: {
    list: [],
    loading: false,
    error: null,
    pagination: {
      page: "1",
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  },
  create_CMS_Components: {
    component: null,
    saving: false,
    error: null
  },
  delete_CMS_Components: {
    component: null,
    deleting: false,
    error: null
  },
  detail: {
    component: null,
    loading: false,
    error: null
  },

  get_CMS_Pages: {
    list: [],
    loading: false,
    error: null,
    pagination: {
      page: "1",
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  },
  create_CMS_Pages: {
    page: null,
    saving: false,
    error: null
  },
  page_detail: {
    page: null,
    loading: false,
    error: null
  },

  copy_cms_page: {
    isSaved: false,
    loading: false,
    error: null,
    msg: ""
  },
  assign_partner_page: {
    loading: false,
    error: null,
    list: []
  },

  get_CMS_banner: {
    list: [],
    loading: false,
    error: null
  },
  create_CMS_banner: {
    banner: null,
    saving: false,
    error: null
  },
  banner_detail: {
    banner: null,
    loading: false,
    error: null
  },
  clone_page: {
    list: [],
    loading: false,
    error: null,
    isSaved: false
  },
  clone_package: {
    saving: false,
    error: null,
    isSaved: false
  },
  delete_CMS_Banner: {
    banner: null,
    deleting: false,
    error: null
  },
  delete_CMS_Page: {
    page: null,
    deleting: false,
    error: null
  },
  retailer_list: {
    list: [],
    loading: false,
    error: null
  },
  cloneSite: {
    loading: false,
    error: null,
    list: null,
    isSaved: false
  },

  get_industry_page_groups: {
    list: [],
    loading: false,
    error: null
  }
};

const cmsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CLONE_PACKAGE_REQUEST:
      return {
        ...state,
        clone_package: {
          isSaved: false,
          saving: true,
          error: null
        }
      };

    case actionTypes.CLONE_PACKAGE_FAILURE:
      return {
        ...state,
        clone_package: {
          isSaved: false,
          saving: false,
          error: action.payload
        }
      };

    case actionTypes.CLONE_PACKAGE_SUCCESS:
      // var data = state.get_CMS_Pages.list;
      // var index = data.findIndex(p => p.id === action.payload.id);
      // if (index > -1) {
      //     data[index] = action.payload;
      // } else {
      //     //data.push(action.payload);
      //     data.splice(0, 0, action.payload);
      // }

      return {
        ...state,
        clone_package: {
          isSaved: true,
          saving: false,
          error: null
        }
      };

    case actionTypes.GET_INDUSTRY_PAGE_GROUP_REQUEST:
      return {
        ...state,
        get_industry_page_groups: {
          list: null,
          loading: true,
          error: null
        }
      };
    case actionTypes.GET_INDUSTRY_PAGE_GROUP_FAILURE:
      return {
        ...state,
        get_industry_page_groups: {
          list: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_INDUSTRY_PAGE_GROUP_SUCCESS:
      return {
        ...state,
        get_industry_page_groups: {
          list: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.RESET_BANNER_DATA:
      return {
        ...state,
        banner_detail: {
          banner: null,
          loading: false,
          error: null
        }
      };

    case actionTypes.BANNER_DETAIL_REQUEST:
      return {
        ...state,
        banner_detail: {
          banner: null,
          loading: true,
          error: null
        }
      };

    case actionTypes.BANNER_DETAIL_FAILURE:
      return {
        ...state,
        banner_detail: {
          banner: null,
          loading: false,
          error: action.payload
        }
      };

    case actionTypes.BANNER_DETAIL_SUCCESS:
      return {
        ...state,
        banner_detail: {
          banner: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.SAVE_BANNER_REQUEST:
      return {
        ...state,
        create_CMS_banner: {
          banner: null,
          saving: true,
          error: null
        },
        get_CMS_banner: {
          ...state.get_CMS_banner,
          loading: false,
          error: null
        }
      };

    case actionTypes.SAVE_BANNER_FAILURE:
      return {
        ...state,
        create_CMS_banner: {
          banner: null,
          saving: false,
          error: action.payload
        },
        get_CMS_banner: {
          ...state.get_CMS_banner,
          loading: false,
          error: null
        }
      };

    case actionTypes.SAVE_BANNER_SUCCESS:
      let bannerList = state.get_CMS_banner.list;
      let bannerItemIndex = bannerList.findIndex(
        p => p.id === action.payload.id
      );
      let newBannerList = [];

      if (bannerItemIndex > -1) {
        newBannerList.push(action.payload);
        for (let i = 0; i < bannerList.length; i++) {
          if (i !== bannerItemIndex) {
            newBannerList.push(bannerList[i]);
          }
        }
      } else {
        newBannerList.push(action.payload);
        for (let i = 0; i < bannerList.length; i++) {
          newBannerList.push(bannerList[i]);
        }
      }

      return {
        ...state,
        banner_detail: {
          banner: action.payload,
          loading: false,
          error: null
        },
        create_CMS_banner: {
          banner: null,
          saving: false,
          error: null
        },
        get_CMS_banner: {
          list: newBannerList,
          loading: false,
          error: null
        }
      };

    case actionTypes.GET_BANNER_REQUEST:
      return {
        ...state,
        create_CMS_banner: {
          banner: null,
          saving: false,
          error: null
        },
        get_CMS_banner: {
          ...state.get_CMS_banner,
          loading: true,
          error: null
        }
      };

    case actionTypes.GET_BANNER_FAILURE:
      return {
        ...state,
        create_CMS_banner: {
          banner: null,
          saving: false,
          error: null
        },
        get_CMS_banner: {
          ...state.get_CMS_banner,
          loading: false,
          error: action.payload
        }
      };

    case actionTypes.GET_BANNER_SUCCESS:
      return {
        ...state,
        create_CMS_banner: {
          banner: null,
          saving: false,
          error: null
        },
        get_CMS_banner: {
          list: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.RESET_PAGE_DATA:
      return {
        ...state,
        page_detail: {
          page: null,
          loading: false,
          error: null
        },
        detail: {
          component: null,
          loading: false,
          error: null
        },
        banners_detail: {
          banner: null,
          loading: false,
          error: null
        }
      };

    case actionTypes.PAGE_DETAIL_REQUEST:
      return {
        ...state,
        page_detail: {
          page: null,
          loading: true,
          error: null
        }
      };
    case actionTypes.PAGE_DETAIL_FAILURE:
      return {
        ...state,
        page_detail: {
          page: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.PAGE_DETAIL_SUCCESS:
      return {
        ...state,
        page_detail: {
          page: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.SAVE_PAGE_REQUEST:
      return {
        ...state,
        create_CMS_Pages: {
          page: null,
          saving: true,
          error: null
        },
        get_CMS_Pages: {
          ...state.get_CMS_Pages,
          loading: false,
          error: null
        }
      };
    case actionTypes.SAVE_PAGE_FAILURE:
      return {
        ...state,
        create_CMS_Pages: {
          page: null,
          saving: false,
          error: action.payload
        },
        get_CMS_Pages: {
          ...state.get_CMS_Pages,
          loading: false,
          error: null
        }
      };
    case actionTypes.SAVE_PAGE_SUCCESS:
      let pageList = state.get_CMS_Pages.list;
      let pageItemIndex = pageList.findIndex(p => p.id === action.payload.id);
      let newPageList = [];

      if (pageItemIndex > -1) {
        newPageList.push(action.payload);
        for (let i = 0; i < pageList.length; i++) {
          if (i !== pageItemIndex) {
            newPageList.push(pageList[i]);
          }
        }
      } else {
        newPageList.push(action.payload);
        for (let i = 0; i < pageList.length; i++) {
          newPageList.push(pageList[i]);
        }
      }

      return {
        ...state,
        page_detail: {
          page: action.payload,
          loading: false,
          error: null
        },
        create_CMS_Pages: {
          page: null,
          saving: false,
          error: null
        },
        get_CMS_Pages: {
          list: newPageList,
          loading: false,
          error: null
        }
      };

    case actionTypes.GET_PAGE_REQUEST:
      return {
        ...state,
        create_CMS_Pages: {
          page: null,
          saving: false,
          error: null
        },
        get_CMS_Pages: {
          ...state.get_CMS_Pages,
          loading: true,
          error: null,
          pagination: {
            page: "1",
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        },
        copy_cms_page: {
          isSaved: false,
          loading: false,
          error: null,
          msg: ""
        },
        clone_page: {
          ...state.clone_page,
          loading: false,
          isSaved: false
        },
        delete_CMS_Page: {
          ...state.delete_CMS_Page,
          deleting: false,
          error: null
        }
      };
    case actionTypes.GET_PAGE_FAILURE:
      return {
        ...state,
        create_CMS_Pages: {
          page: null,
          saving: false,
          error: null
        },
        get_CMS_Pages: {
          ...state.get_CMS_Pages,
          loading: false,
          error: action.payload
        },
        copy_cms_page: {
          isSaved: false,
          loading: false,
          error: null,
          msg: ""
        }
      };
    case actionTypes.GET_PAGE_SUCCESS:
      let pagination = _.clone(state.get_CMS_Pages.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        create_CMS_Pages: {
          page: null,
          saving: false,
          error: null
        },
        get_CMS_Pages: {
          list: sdata,
          loading: false,
          error: null,
          pagination: {
            ...pagination
          }
        },
        copy_cms_page: {
          isSaved: false,
          loading: false,
          error: null,
          msg: ""
        }
      };

    case actionTypes.GET_COMPONENT_REQUEST:
      return {
        ...state,
        create_CMS_Components: {
          component: null,
          saving: false,
          error: null
        },
        get_CMS_Components: {
          ...state.get_CMS_Components,
          loading: true,
          error: null,
          pagination: {
            page: "1",
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        },
        detail: {
          component: null,
          loading: false,
          error: null
        }
      };
    case actionTypes.GET_COMPONENT_FAILURE:
      return {
        ...state,
        create_CMS_Components: {
          component: null,
          saving: false,
          error: null
        },
        get_CMS_Components: {
          ...state.get_CMS_Components,
          loading: false,
          error: action.payload
        },
        detail: {
          component: null,
          loading: false,
          error: null
        }
      };
    case actionTypes.GET_COMPONENT_SUCCESS:
      let lpagination = _.clone(state.get_CMS_Components.pagination);
      let sldata = action.payload;
      if (sldata && sldata.results) {
        lpagination.page = sldata.current;
        lpagination.next = sldata.next;
        lpagination.prev = sldata.prev;
        lpagination.totalpage = sldata.total_pages;
        lpagination.totalrecords = sldata.total_records;
        lpagination.pagesize = sldata.page_size;
        sldata = sldata.results;
      }
      return {
        ...state,
        create_CMS_Components: {
          component: null,
          saving: false,
          error: null
        },
        get_CMS_Components: {
          list: sldata,
          loading: false,
          error: null,
          pagination: {
            ...lpagination
          }
        },
        detail: {
          component: null,
          loading: false,
          error: null
        }
      };

    case actionTypes.COMPONENT_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          component: null,
          loading: true,
          error: null
        }
      };
    case actionTypes.COMPONENT_DETAIL_FAILURE:
      return {
        ...state,
        detail: {
          component: null,
          loading: false,
          error: action.payload
        }
      };

    case actionTypes.COMPONENT_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          component: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.SAVE_COMPONENT_REQUEST:
      return {
        ...state,
        create_CMS_Components: {
          component: null,
          saving: true,
          error: null
        },
        get_CMS_Components: {
          ...state.get_CMS_Components,
          loading: false,
          error: null
        }
      };
    case actionTypes.SAVE_COMPONENT_FAILURE:
      return {
        ...state,
        create_CMS_Components: {
          component: null,
          saving: false,
          error: action.payload
        },
        get_CMS_Components: {
          ...state.get_CMS_Components,
          loading: false,
          error: null
        }
      };
    case actionTypes.SAVE_COMPONENT_SUCCESS:
      var data = state.get_CMS_Components.list;
      var index = data.findIndex(p => p.id === action.payload.id);
      let Newlist = [];

      if (index > -1) {
        Newlist.push(action.payload);
        for (let i = 0; i < data.length; i++) {
          if (i !== index) {
            Newlist.push(data[i]);
          }
        }
      } else {
        Newlist.push(action.payload);
        for (let i = 0; i < data.length; i++) {
          Newlist.push(data[i]);
        }
      }

      return {
        ...state,
        create_CMS_Components: {
          component: null,
          saving: false,
          error: null
        },
        get_CMS_Components: {
          list: Newlist,
          loading: false,
          error: null
        },
        detail: {
          component: null,
          loading: false,
          error: null
        }
      };

    case actionTypes.DELETE_COMPONENT_REQUEST:
      return {
        ...state,
        delete_CMS_Components: {
          component: null,
          deleting: true,
          error: null
        }
      };

    case actionTypes.DELETE_COMPONENT_FAILURE:
      return {
        ...state,
        delete_CMS_Components: {
          component: null,
          deleting: false,
          error: action.payload
        }
      };

    case actionTypes.DELETE_COMPONENT_SUCCESS:
      // var deleteIndex = state.get_CMS_Components.list.findIndex(p => p.id === action.payload.id);
      // let newComponentList = state.get_CMS_Components.list

      // if (deleteIndex > -1) {
      //     newComponentList.splice(deleteIndex, 1);
      // }

      return {
        ...state,
        get_CMS_Components: {
          ...state.get_CMS_Components,
          // list: newComponentList,
          loading: false,
          error: null
        },
        delete_CMS_Components: {
          component: null,
          deleting: false,
          error: null
        }
      };

    case actionTypes.DELETE_BANNER_REQUEST:
      return {
        ...state,
        delete_CMS_Banner: {
          banner: null,
          deleting: true,
          error: null
        }
      };

    case actionTypes.DELETE_BANNER_FAILURE:
      return {
        ...state,
        delete_CMS_Banner: {
          banner: null,
          deleting: false,
          error: action.payload
        }
      };

    case actionTypes.DELETE_BANNER_SUCCESS:
      var deleteIndex = state.get_CMS_banner.list.findIndex(
        p => p.id === action.payload.id
      );
      let UpdatedBannerList = state.get_CMS_banner.list;

      if (deleteIndex > -1) {
        UpdatedBannerList.splice(deleteIndex, 1);
      }

      return {
        ...state,
        get_CMS_banner: {
          list: UpdatedBannerList,
          loading: false,
          error: null
        },
        delete_CMS_Banner: {
          banner: null,
          deleting: false,
          error: null
        }
      };

    case actionTypes.DELETE_PAGE_REQUEST:
      return {
        ...state,
        delete_CMS_Page: {
          page: null,
          deleting: true,
          error: null
        }
      };

    case actionTypes.DELETE_PAGE_FAILURE:
      return {
        ...state,
        delete_CMS_Page: {
          page: null,
          deleting: false,
          error: action.payload
        }
      };

    case actionTypes.DELETE_PAGE_SUCCESS:
      // var deletePageIndex = state.get_CMS_Pages.list.findIndex(p => p.id === action.payload.id);
      // let UpdatedPageList = state.get_CMS_Pages.list

      // if (deletePageIndex > -1) {
      //     UpdatedPageList.splice(deletePageIndex, 1);
      // }

      return {
        ...state,
        get_CMS_Pages: {
          ...state.get_CMS_Pages,
          // list: UpdatedPageList,
          loading: false,
          error: null
        },
        delete_CMS_Page: {
          page: null,
          deleting: false,
          error: null
        }
      };

    case actionTypes.COPY_PAGE_PARTNER_REQUEST:
      return {
        ...state,

        copy_cms_page: {
          isSaved: false,
          loading: true,
          error: null,
          msg: ""
        }
      };
    case actionTypes.COPY_PAGE_PARTNER_FAILURE:
      return {
        ...state,
        copy_cms_page: {
          isSaved: false,
          loading: false,
          error: action.payload,
          msg: ""
        }
      };
    case actionTypes.COPY_PAGE_PARTNER_SUCCESS:
      return {
        ...state,
        copy_cms_page: {
          isSaved: true,
          loading: false,
          error: null,
          msg: action.payload
        }
      };

    case actionTypes.GET_ASSIGN_PARTNER_PAGE_REQUEST:
      return {
        ...state,

        assign_partner_page: {
          loading: true,
          error: null,
          list: []
        }
      };
    case actionTypes.GET_ASSIGN_PARTNER_PAGE_FAILURE:
      return {
        ...state,
        assign_partner_page: {
          loading: false,
          error: action.payload,
          list: []
        }
      };
    case actionTypes.GET_ASSIGN_PARTNER_PAGE_SUCCESS:
      return {
        ...state,
        assign_partner_page: {
          loading: false,
          error: null,
          list: action.payload
        }
      };

    case actionTypes.CLONE_CMS_PAGES_REQUEST:
      return {
        ...state,
        clone_page: {
          isSaved: false,
          loading: true,
          error: null
        }
      };

    case actionTypes.CLONE_CMS_PAGES_FAILURE:
      return {
        ...state,
        clone_page: {
          isSaved: false,
          loading: false,
          error: action.payload
        }
      };

    case actionTypes.CLONE_CMS_PAGES_SUCCESS:
      // var data = state.get_CMS_Pages.list;
      // var index = data.findIndex(p => p.id === action.payload.id);
      // if (index > -1) {
      //     data[index] = action.payload;
      // } else {
      //     //data.push(action.payload);
      //     data.splice(0, 0, action.payload);
      // }

      return {
        ...state,

        get_CMS_Pages: {
          ...state.get_CMS_Pages,
          // list: data,
          loading: false,
          error: null
        },
        clone_page: {
          isSaved: true,
          loading: false,
          error: null
        }
      };

    case actionTypes.GET_PARTNER_LIST_REQUEST:
      return {
        ...state,
        retailer_list: {
          list: data,
          loading: true,
          error: null
        },
        cloneSite: {
          ...state.cloneSite,
          loading: false,
          error: null,
          list: null,
          isSaved: false
        }
      };

    case actionTypes.GET_PARTNER_LIST_FAILURE:
      return {
        ...state,
        retailer_list: {
          list: data,
          loading: false,
          error: null
        }
      };

    case actionTypes.GET_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        retailer_list: {
          list: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.CLONE_PARTNER_SITE_REQUEST:
      return {
        ...state,
        cloneSite: {
          ...state.cloneSite,
          loading: true,
          error: null,
          list: null,
          isSaved: false
        }
      };
    case actionTypes.CLONE_PARTNER_SITE_FAILURE:
      return {
        ...state,
        cloneSite: {
          ...state.cloneSite,
          loading: false,
          error: action.payload,
          list: null,
          isSaved: false
        }
      };
    case actionTypes.CLONE_PARTNER_SITE_SUCCESS:
      return {
        ...state,
        cloneSite: {
          ...state.cloneSite,
          loading: false,
          error: null,
          list: null,
          isSaved: true
        }
      };

    default:
      return state;
  }
};

export default cmsReducer;
