import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  stock_location: {
    list: null,
    loading: false,
    error: null,
    is_deleted: false,
    isSaved: false,
    pagination: {
      page: 1,
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 25
    }
  },
  stock: {
    list: null,
    loading: false,
    error: null,
    isSaved: false,
    pagination: {
      page: 1,
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 25
    }
  },
  stockById: {
    data: null,
    loading: false,
    error: null
  },
  stockLocationById: {
    data: null,
    loading: false,
    error: null
  },
  productsNetStock: {
    data: null,
    loading: false,
    error: null,
    pagination: {
      page: 1,
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 25
    }
  },
  productStoveMoveData: {
    data: null,
    loading: false,
    error: null,
    pagination: {
      page: 1,
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 25
    }
  }
};

const inventoryManagementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_STOCK_LOCATION_REQUEST:
      return {
        ...state,
        stock_location: {
          list: null,
          loading: true,
          error: null,
          is_deleted: false,
          isSaved: false,
          pagination: {
            page: 1,
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 25
          }
        }
      };
    case actionTypes.GET_STOCK_LOCATION_FAILURE:
      return {
        ...state,
        stock_location: {
          list: null,
          loading: false,
          error: action.payload,
          is_deleted: false
        }
      };
    case actionTypes.GET_STOCK_LOCATION_SUCCESS:
      let pagination = _.clone(state.stock_location.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        stock_location: {
          list: sdata,
          loading: false,
          error: null,
          is_deleted: false,
          isSaved: false,
          pagination: {
            ...pagination
          }
        }
      };
    case actionTypes.DELETE_STOCK_LOCATION_REQUEST:
      return {
        ...state,
        stock_location: {
          ...state.stock_location,
          loading: true,
          error: null,
          is_deleted: false
        }
      };
    case actionTypes.DELETE_STOCK_LOCATION_FAILURE:
      return {
        ...state,
        stock_location: {
          ...state.stock_location,
          loading: false,
          error: action.payload,
          is_deleted: false
        }
      };
    case actionTypes.DELETE_STOCK_LOCATION_SUCCESS:
      return {
        ...state,
        stock_location: {
          ...state.stock_location,
          loading: false,
          error: null,
          is_deleted: true
        }
      };
    case actionTypes.CREATE_STOCK_LOCATION_REQUEST:
      return {
        ...state,
        stock_location: {
          ...state.stock_location,
          loading: true,
          error: null,
          is_deleted: false
        }
      };
    case actionTypes.CREATE_STOCK_LOCATION_FAILURE:
      return {
        ...state,
        stock_location: {
          ...state.stock_location,
          loading: false,
          error: action.payload,
          is_deleted: false
        }
      };
    case actionTypes.CREATE_STOCK_LOCATION_SUCCESS:
      // let list = _.clone(state.stock_location.list)
      // let index = list.findIndex(x => x.id === action.payload.id)
      // if (index > -1) {
      //     list[index] = action.payload;
      // } else {
      //     list.splice(0, 0, action.payload);
      // }
      return {
        ...state,
        stock_location: {
          // list: list,
          loading: false,
          error: null,
          is_deleted: false,
          isSaved: true
        }
      };
    case actionTypes.STOCK_LIST_REQUEST:
      return {
        ...state,
        stock: {
          list: null,
          loading: true,
          error: null,
          pagination: {
            page: 1,
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 25
          }
        }
      };
    case actionTypes.STOCK_LIST_FAILURE:
      return {
        ...state,
        stock: {
          list: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.STOCK_LIST_SUCCESS:
      let slpagination = _.clone(state.stock.pagination);
      let sldata = action.payload;
      if (sldata && sldata.results) {
        slpagination.page = sldata.current;
        slpagination.next = sldata.next;
        slpagination.prev = sldata.prev;
        slpagination.totalpage = sldata.total_pages;
        slpagination.totalrecords = sldata.total_records;
        slpagination.pagesize = sldata.page_size;
        sldata = sldata.results;
      }
      return {
        ...state,
        stock: {
          list: sldata,
          loading: false,
          error: null,
          isSaved: false,
          pagination: {
            ...slpagination
          }
        }
      };
    case actionTypes.ADD_STOCK_REQUEST:
      return {
        ...state,
        stock: {
          ...state.stock,
          loading: true,
          error: null
        }
      };
    case actionTypes.ADD_STOCK_FAILURE:
      return {
        ...state,
        stock: {
          ...state.stock,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.ADD_STOCK_SUCCESS:
      // let stocklist = _.clone(state.stock.list)
      // let stockindex = stocklist.findIndex(x => x.id === action.payload.id)
      // if (stockindex > -1) {
      //     stocklist[stockindex] = action.payload;
      // } else {
      //     stocklist.splice(0, 0, action.payload);
      // }
      return {
        ...state,
        stock: {
          // list: stocklist,
          loading: false,
          error: null,
          isSaved: true
        }
      };
    case actionTypes.STOCK_BYID_REQUEST:
      return {
        ...state,
        stockById: {
          data: null,
          loading: true,
          error: null
        }
      };
    case actionTypes.STOCK_BYID_FAILURE:
      return {
        ...state,
        stockById: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.STOCK_BYID_SUCCESS:
      return {
        ...state,
        stockById: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    case actionTypes.GET_STOCK_LOCATION_BYID_REQUEST:
      return {
        ...state,
        stockLocationById: {
          data: null,
          loading: true,
          error: null
        }
      };
    case actionTypes.GET_STOCK_LOCATION_BYID_FAILURE:
      return {
        ...state,
        stockLocationById: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_STOCK_LOCATION_BYID_SUCCESS:
      return {
        ...state,
        stockLocationById: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    case actionTypes.GET_PRODUCTS_NETSTOCK_REQUEST:
      return {
        ...state,
        productsNetStock: {
          data: null,
          loading: true,
          error: null,
          pagination: {
            page: 1,
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 25
          }
        }
      };
    case actionTypes.GET_PRODUCTS_NETSTOCK_FAILURE:
      return {
        ...state,
        productsNetStock: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_PRODUCTS_NETSTOCK_SUCCESS:
      let nspagination = _.clone(state.productsNetStock.pagination);
      let nsdata = action.payload;
      if (nsdata && nsdata.results) {
        nspagination.page = nsdata.current;
        nspagination.next = nsdata.next;
        nspagination.prev = nsdata.prev;
        nspagination.totalpage = nsdata.total_pages;
        nspagination.totalrecords = nsdata.total_records;
        nspagination.pagesize = nsdata.page_size;
        nsdata = nsdata.results;
      }
      return {
        ...state,
        productsNetStock: {
          data: nsdata,
          loading: false,
          error: null,
          pagination: {
            ...nspagination
          }
        }
      };
    case actionTypes.GET_PRODUCTS_STOCKMOVEDATA_REQUEST:
      return {
        ...state,
        productStoveMoveData: {
          data: null,
          loading: true,
          error: null,
          pagination: {
            page: 1,
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 25
          }
        }
      };
    case actionTypes.GET_PRODUCTS_STOCKMOVEDATA_FAILURE:
      return {
        ...state,
        productStoveMoveData: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_PRODUCTS_STOCKMOVEDATA_SUCCESS:
      let smpagiantion = _.clone(state.productsNetStock.pagination);
      let smdata = action.payload;
      if (smdata && smdata.results) {
        smpagiantion.page = smdata.current;
        smpagiantion.next = smdata.next;
        smpagiantion.prev = smdata.prev;
        smpagiantion.totalpage = smdata.total_pages;
        smpagiantion.totalrecords = smdata.total_records;
        smpagiantion.pagesize = smdata.page_size;
        smdata = smdata.results;
      }
      return {
        ...state,
        productStoveMoveData: {
          data: smdata,
          loading: false,
          error: null,
          pagination: {
            ...smpagiantion
          }
        }
      };
    default:
      return state;
  }
};

export default inventoryManagementReducer;
