import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import cx from "classnames";
import { LoadingOverlay, Loader } from "react-overlay-loader";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FormLabel from "@material-ui/core/FormLabel";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedFormStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import ProductInfo from "./components/ProductInfo";
import ProductVariant from "./components/ProductVariant";
import ProductSegmentCategory from "./components/ProductSegmentCategory";
import ProductAttributes from "./components/ProductAttributes";
import Button from "components/CustomButtons/Button.jsx";

const Style = {
  pagination: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#666666",
    float: "right"
  },
  questionHead: {
    fontWeight: "500"
  }
};

const initialState = {
  id: "",
  name: "",
  description: "",
  disclaimer: "",
  is_used: false,
  is_consumable: false,
  attributes: [],
  segments: [],
  categories: [],
  classes: [],
  offers: [],
  brands: [],
  attribute_groups: [],
  mrp: "",
  producttype: "",
  variantValues: [],
  msrp: 0,
  is_template: true,
  discount: "",
  editorState: ""
};

const wizardHeaders = [
  "Basic Info",
  "Segment & Classes",
  "Attribute Groups",
  "Variant Options"
  // 'Offer & Other Details'
];

const paginationInitialState = {
  total_page: 1,
  current_page: 1
};

class ProductWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      addProduct: {
        ...initialState,
        partner_id: this.props.PartnerId
      },
      selectedTab: 0,
      setEditData: true,
      pagination: {
        ...paginationInitialState
      },
      isEditMode: false,
      isValueSetCheck: false,
      clickaction: 0,
      isSaveNext: false,
      isSaveClose: false
    };
    this.clickPreviousBtn = this.clickPreviousBtn.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.setProductData = this.setProductData.bind(this);
    this.setIntialValue = this.setIntialValue.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.savedTemplate &&
        !_.isEqual(nextProps.savedTemplate, this.props.savedTemplate)
      ) {
        let data = this.modifyTemplateData(nextProps.savedTemplate);
        if (this.state.clickaction == 1) {
          this.clickNextBtn(data);
        }
        if (this.state.clickaction == 2) {
          this.props.close();
        }
      }
      let error = "";
      if (nextProps.error) {
        error = nextProps.error;
      }
      this.setState({
        error: error
      });
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState(
        {
          addProduct: this.props.data,
          isEditMode: this.props.isEdit
        },
        () => {
          this.setPaginationValues();
        }
      );
    }

    this.props.actions.getVariants(null, this.state.variantSearch, true);
  }

  modifyTemplateData(product) {
    let data = {};
    data["id"] = product.id;
    data["name"] = product.name;
    data["description"] = product.description;
    data["mrp"] = product.msrp;
    data["brands"] = product.brands;
    if (product.is_consumable == 1) {
      product.is_consumable = true;
    } else {
      product.is_consumable = false;
    }
    data["is_consumable"] = product.is_consumable;
    data["is_template"] = product.is_template;
    data["attribute_groups"] = product.attribute_groups;
    data["partner_id"] = this.props.partnerId;
    data["offers"] = product.offers;
    data["categories"] = product.categories;
    data["classes"] = product.classes;
    data["attributes"] = product.attributes;
    data["segments"] = product.segments;
    data["producttype"] = product.producttype ? product.producttype.id : "";
    data["variantValues"] = product.variantValues;
    return data;
  }

  setPaginationValues() {
    let totalpagecount = wizardHeaders.length;
    this.setState({
      pagination: {
        ...this.state.pagination,
        total_page: totalpagecount,
        current_page: 1
      },
      selectedTab: 0
    });
  }

  render() {
    const { current_page, total_page } = this.state.pagination;
    const { classes } = this.props;
    return (
      <Grid>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <LoadingOverlay>
              <Card>
                <CardHeader>
                  <div
                    style={{
                      color: "black",
                      fontWeight: "500",
                      float: "left",
                      fontSize: "16px",
                      lineHeight: "18px"
                    }}
                  >
                    <span>
                      {this.state.isEditMode
                        ? `Edit: ${this.state &&
                        this.state.addProduct &&
                        this.state.addProduct.name}`
                        : "New Product"}
                    </span>
                  </div>
                  <div style={Style.pagination}>
                    Page: {current_page}/{total_page}
                  </div>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                          {_.map(wizardHeaders, (model, index) => (
                            <GridItem
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              key={index}
                              style={{
                                textAlign: "center"
                              }}
                            >
                              <div
                                value={index}
                                onClick={() => this.handleChange(index)}
                                className={cx("wizard-tab", {
                                  "wizard-selected-tab":
                                    this.state.selectedTab == index
                                })}
                              >
                                {model}
                              </div>
                            </GridItem>
                          ))}
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Divider variant="middle" />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        {this.showSelectedTabContent()}
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                  {this.state.error && this.state.error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}
                  <div
                    style={{
                      float: "right"
                    }}
                  >
                    {
                      current_page > 1 ?
                        (
                          <Button
                            className="wizard-btn"
                            onClick={() => this.clickPreviousBtn()}
                          >
                            Previous
                          </Button>
                        ) :

                        (
                          ""
                        )
                    }
                    {current_page < total_page ? (
                      <Button
                        className="wizard-btn"
                        onClick={() => this.handleSaveNext()}
                      >
                        {this.state.isEditMode
                          ? "Update & Next"
                          : "Save & Next"}
                      </Button>
                    ) : (
                      ""
                    )}
                    {current_page <= total_page ? (
                      <Button
                        className="wizard-btn"
                        onClick={() => this.handleSaveClose()}
                      >
                        {this.state.isEditMode
                          ? "Update & Close"
                          : "Save & Close"}
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      className="wizard-btn"
                      onClick={() => this.closeWizard()}
                    >
                      Close
                    </Button>
                  </div>
                </CardBody>
              </Card>
              {this.props.Loading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-12px"
                  }}
                />
              )}
              <Loader loading={this.props.isSaving} text="Saving..." />
            </LoadingOverlay>
          </GridItem>
        </GridContainer>
      </Grid>
    );
  }

  handleAddVariantRow = () => {
    let variantValues = this.state.addProduct.variantValues;

    if (!variantValues) {
      variantValues = new Array();
    }

    variantValues.push({
      variant_id: '',
      values: []
    });

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: variantValues
      }
    });
  };

  handleVariantRemove = (index) => {
    let variantValues = this.state.addProduct.variantValues;
    variantValues.splice(index, 1);

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: variantValues
      }
    });
  }

  handleVariantChange = (index, value) => {
    if (!this.state.addProduct || !this.state.addProduct.variantValues) return;

    let existingValues = this.state.addProduct.variantValues;
    existingValues[index]["variant_id"] = value

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: existingValues
      }
    })
  }


  handleVariantValueChange = (index, value) => {
    if (!this.state.addProduct || !this.state.addProduct.variantValues) return;

    let existingValues = this.state.addProduct.variantValues;
    existingValues[index]["value_id"] = value

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: existingValues
      }
    })
  }


  showSelectedTabContent() {

    const { selectedTab, addProduct } = this.state;

    if (selectedTab === 0) {
      return (
        <div>
          <ProductInfo
            data={addProduct}
            editState={this.state.isEditMode}
            setProductData={this.setProductData}
            setIntialValue={this.setIntialValue}
            valueSetCheck={this.state.isValueSetCheck}
          />
        </div>
      );
    }
    else if (selectedTab === 1) {
      return (
        <div>
          <ProductSegmentCategory
            data={addProduct}
            editState={this.state.isEditMode}
            setProductData={this.setProductData}
            setIntialValue={this.setIntialValue}
            valueSetCheck={this.state.isValueSetCheck}
          />
        </div>
      );
    }
    else if (selectedTab === 2) {
      return (
        <div>
          <ProductAttributes
            data={addProduct}
            editState={this.state.isEditMode}
            setProductData={this.setProductData}
            setIntialValue={this.setIntialValue}
            valueSetCheck={this.state.isValueSetCheck}
          />
        </div>
      );
    }
    else if (selectedTab === 3) {
      return (
        <ProductVariant
          variantMaster={this.props.variant_list}
          data={addProduct}
          partner_id={addProduct && addProduct.brand ? addProduct.brand.id : ''}
          onVariantGroupChange={this.onVariantGroupChange}
          handleAddVariantRow={this.handleAddVariantRow}
          handleVariantRemove={this.handleVariantRemove}
          handleVariantChange={this.handleVariantChange}
          handleVariantValueChange={this.handleVariantValueChange}
          pagination={this.state.pagination.current_page}
        />
      );
    }
  }

  setProductData(key, value) {
    this.setState(
      {
        addProduct: {
          ...this.state.addProduct,
          [key]: value
        }
      },
      () => {
        this.setIntialValue();
      }
    );
  }

  setIntialValue() {
    this.setState({
      isValueSetCheck: true
    });
  }

  clickPreviousBtn() {
    let { current_page } = this.state.pagination;
    let new_page = current_page - 1;
    let selected_tab = this.state.selectedTab - 1;
    if (new_page >= 1) {
      this.setState({
        ...this.state,
        selectedTab: selected_tab,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    }
  }

  clickNextBtn(data) {
    let { current_page, total_page } = this.state.pagination;
    let new_page = current_page + 1;
    let selected_tab = this.state.selectedTab + 1;
    if (new_page <= total_page) {
      this.setState({
        ...this.state,
        addProduct: data,
        selectedTab: selected_tab,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    }
  }

  closeWizard = () => {
    this.props.close();
  };

  handleChange(value) {

    this.setState({
      selectedTab: value,
      pagination: {
        ...this.state.pagination,
        current_page: value + 1
      }
    });

  }

  handleSaveClose = () => {
    this.setState(
      {
        clickaction: 2
      },
      () => {
        let data = this.state.addProduct;
        if (data.is_consumable) {
          data.is_consumable = 1;
        } else {
          data.is_consumable = 0;
        }
        data.partner_id = this.props.PartnerId;
        console.log(JSON.stringify(data));

        let checkvalidation = this.validateData();
        if (checkvalidation.length === 0) {
          this.props.actions.saveProductTemplateAction(
            data,
            this.props.UserId,
            []
          );
        } else {
          this.setState({
            error: checkvalidation.toString()
          });
        }
      }
    );
  };

  handleSaveNext = () => {
    this.setState(
      {
        clickaction: 1
      },
      () => {
        let data = this.state.addProduct;
        if (data.is_consumable) {
          data.is_consumable = 1;
        } else {
          data.is_consumable = 0;
        }
        data.partner_id = this.props.PartnerId;
        let checkvalidation = this.validateData();
        if (checkvalidation.length === 0) {
          this.props.actions.saveProductTemplateAction(
            data,
            this.props.UserId,
            []
          );
        } else {
          this.setState({
            error: checkvalidation.toString()
          });
        }
      }
    );
  };

  validateData() {
    const { name, description, producttype } = this.state.addProduct;
    let error = 0;
    let msg = [];

    if (name == "" || name == null || name.length < 0) {
      error = 1;
      msg.push("Please enter the name of the product");
    }

    // if (description == '' || description == null || description.length < 0) {
    //     error = 1;
    //     msg.push('Please enter the description of the product');
    // }

    if (producttype == "" || producttype == null) {
      error = 1;
      msg.push("Please select the product type of the product");
    }
    return msg;
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  Loading: state.productsAssignmentsState.loading,
  ListError: state.productsAssignmentsState.error,
  PartnerId: state.authState.user.partner_id,
  UserId: state.authState.user.pk,
  error: state.brandState.product_template.error,
  Loading: state.brandState.product_template.loading,
  savedTemplate: state.brandState.product_template.data,
  variant_list_error: state.variantReducer.list_variant.error,
  variant_list: state.variantReducer.list_variant.variants,
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...extendedFormStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductWizard);
