import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  list: null,
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  isSaving: false,
  isDeleted: false,
  isMapSaved: false,
  unassignWholsaler: {
    data: null,
    error: null,
    loading: false
  },
  saveRetailersWholesaler: {
    isSaved: false,
    error: null,
    loading: false
  },
  pagination: {
    page: "1",
    next: null,
    prev: null,
    totalpages: 1,
    trecords: 0,
    pagesize: 10
  },
  partnerIndustrySegment: {
    list: null,
    loading: false,
    error: null,
  }
};

const SegmentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SEGMENT_REQUEST:
      return {
        ...state,
        list: null,
        loading: true,
        error: null,
        isSaved: false,
        isDeleted: false,
        pagination: {
          page: "1",
          next: null,
          prev: null,
          totalpages: 1,
          trecords: 0,
          pagesize: 10
        }
      };
    case actionTypes.GET_SEGMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        isDeleted: false
      };
    case actionTypes.GET_SEGMENT_SUCCESS:
      let pagination = _.clone(state.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }

      return {
        ...state,
        loading: false,
        error: null,
        list: sdata,
        isSaved: false,
        isDeleted: false,
        pagination: {
          ...pagination
        }
      };

    case actionTypes.SAVE_SEGMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        isSaving: true,
        isDeleted: false
      };
    case actionTypes.SAVE_SEGMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        isSaving: true,
        isDeleted: false
      };
    case actionTypes.SAVE_SEGMENT_SUCCESS:
      // var data = state.list;
      // var index = data.findIndex(p => p.id === action.payload.id);
      // let list = []
      // if (index > -1) {
      // 	list.push(action.payload);
      // 	for (let i = 0; i < data.length; i++) {
      // 		if (i !== index) {

      // 			list.push(data[i]);

      // 		}

      // 	}
      // } else {

      // 	list.push(action.payload)
      // 	for (let i = 0; i < data.length; i++) {
      // 		if (i !== index) {
      // 			list.push(data[i])
      // 		}
      // 	}
      // }
      return {
        ...state,
        loading: false,
        error: null,
        // list: list,
        isSaved: true,
        isSaving: false,
        isDeleted: false,
        isMapSaved: true
      };
    case actionTypes.DELETE_SEGMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        isDeleted: false
      };
    case actionTypes.DELETE_SEGMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        isDeleted: false
      };
    case actionTypes.DELETE_SEGMENT_SUCCESS:
      var data = state.list;
      let segmentIndex = data.findIndex(o => o.id === action.segmentId);
      data.splice(segmentIndex, 1);
      return {
        ...state,
        loading: false,
        error: null,
        list: data,
        isSaved: false,
        isDeleted: true
      };
    case actionTypes.MAP_CLASS_SEGMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        isDeleted: false,
        isMapSaved: false
      };
    case actionTypes.MAP_CLASS_SEGMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        isDeleted: false,
        isMapSaved: false
      };
    case actionTypes.MAP_CLASS_SEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isSaved: true,
        isDeleted: false,
        isMapSaved: true
      };
    case actionTypes.AFTER_MAP_CLASS_SEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isSaved: true,
        isDeleted: false,
        isMapSaved: false
      };

    case actionTypes.LIST_UNASSIGNED_WHOLESALER_REQUEST:
      return {
        ...state,
        unassignWholsaler: {
          data: null,
          error: null,
          loading: true
        },
        saveRetailersWholesaler: {
          isSaved: false
        }
      };
    case actionTypes.LIST_UNASSIGNED_WHOLESALER_FAILURE:
      return {
        ...state,
        unassignWholsaler: {
          data: null,
          error: action.payload,
          loading: false
        },
        saveRetailersWholesaler: {
          isSaved: false
        }
      };
    case actionTypes.LIST_UNASSIGNED_WHOLESALER_SUCCESS:
      return {
        ...state,
        unassignWholsaler: {
          data: action.payload,
          error: null,
          loading: false
        },
        saveRetailersWholesaler: {
          isSaved: false
        }
      };

    case actionTypes.SAVE_RETAILER_WHOLESALER_MAPPING_REQUEST:
      return {
        ...state,
        saveRetailersWholesaler: {
          error: null,
          loading: true,
          isSaved: false
        },
        isSaved: true,
        isDeleted: false,
        isMapSaved: false
      };
    case actionTypes.SAVE_RETAILER_WHOLESALER_MAPPING_FAILURE:
      return {
        ...state,
        saveRetailersWholesaler: {
          error: action.payload,
          loading: false,
          isSaved: false
        }
      };
    case actionTypes.SAVE_RETAILER_WHOLESALER_MAPPING_SUCCESS:
      return {
        ...state,
        saveRetailersWholesaler: {
          error: null,
          loading: false,
          isSaved: true
        }
      };
    case actionTypes.GET_PARTNERS_INDUSTRY_SEGMENT_REQUEST:
      return {
        ...state,
        partnerIndustrySegment: {
          ...state.partnerIndustrySegment,
          list: null,
          loading: true,
          error: null,
        }
      };
    case actionTypes.GET_PARTNERS_INDUSTRY_SEGMENT_FAILURE:
      return {
        ...state,
        partnerIndustrySegment: {
          ...state.partnerIndustrySegment,
          list: null,
          loading: false,
          error: action.payload,
        }
      };
    case actionTypes.GET_PARTNERS_INDUSTRY_SEGMENT_SUCCESS:
      return {
        ...state,
        partnerIndustrySegment: {
          ...state.partnerIndustrySegment,
          list: action.payload,
          loading: false,
          error: null,
        }
      };
    default:
      return state;
  }
};

export default SegmentsReducer;
