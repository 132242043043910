import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import Checkbox from "@material-ui/core/Checkbox";
import withAuthorization from "../Session/withAuthorization";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Check from "@material-ui/icons/Check";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import tasksStyle from "assets/jss/material-dashboard-pro-react/components/tasksStyle.jsx";
import { rolesNames } from "../../constants";
import { validationUtils } from "../../utils";
// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
// import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

import ImageUpload from "../components/ImageUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import SegmentActionButton from "../../components/AgGrid/SegmentActionButton";
import { ClassComponent, ServiceComponent } from "../components";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

let _agGridAPI = null;
class Segment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segmenttext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false
      },
      isEdit: false,
      isAddNew: false,
      segmentsData: [],
      segmentsId: null,
      segmentsValue: null,
      editsegmentsData: null,
      modalSegmentLogo: false,
      segmentImages: [],
      openDeleteDialog: false,
      addSegmentData: {
        name: "",
        description: "",
        is_active: false,
        isDeleted: false,
        slug: "",
        initials:"",
        color:"",
        image: ""
      },
      required: {
        name: "",
        description: "",
        initials:"",
        color:"",
        image: ""
      },
      mappingData: {
        classes: [],
        segment: null,
        description: "",
        is_active: true
      },
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 240,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter"
        },
        {
          headerName: "Slug",
          field: "slug",
          width: 240,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter"
        },
        {
          headerName: "Description",
          field: "description",
          width: 340,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter"
        },
        
        // {
        //     headerName: "Status",
        //     field: "is_active",
        //     width: 120,
        //     resizable: true,
        //     sortable: true,
        //     // filter: "agTextColumnFilter",
        //     // filterParams: {
        //     //     defaultToNothingSelected: true,
        //     // }
        // },
        // {
        //     headerName: "Logo",
        //     field: "image",
        //     width: 180,
        //     resizable: true,
        //     sortable: true,
        //     cellRenderer: function (params) {

        //         if (params.value && params.value.secure_url) {
        //             return '<img src=' + params.value.image + ' className={classes.img}' + ' style = width:70px > ';
        //         }
        //     },
        // },

        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 120,
          filter: false
        }
      ],
      frameworkComponents: {
        AgGridActionButton: SegmentActionButton
      },
      defaultColDef: {
        width: 240,
        resizable: true
      },
      domLayout: "autoHeight",
      context: { segmentGrid: this },
      isClassComponentOpen: false,
      isServiceComponentOpen: false,
      selectedClassIndexes: [],
      selectedServiceIndexes: [],
      ServiceList: []
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentDidMount() {
    this.props.actions.getSegmentsList(
      this.props.UserId,
      null,
      null,
      this.state.pagination.page
    );

    if (this.props.ServiceList && this.props.ServiceList.length > 0) {
      this.setState({
        ServiceList: this.props.ServiceList
      });
    } else {
      this.props.actions.getServicesList();
    }
    this.props.actions.getSegmentsImages();
  }

  saveSegments = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addSegmentData;
      this.props.actions.saveSegments(
        this.props.UserId,
        savingData,
        this.props.PartnerId
      );
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.List && !_.isEqual(this.props.List, nextProps.List)) {
        const segmentsData = nextProps.List;
        this.setState(() => ({
          segmentsData: segmentsData,
          editsegmentsData: segmentsData,
          pagination: nextProps.pagination
        }));
      }
      if (nextProps.ServiceList && nextProps.ServiceList.length > 0) {
        const serviceData = nextProps.ServiceList;
        this.setState(() => ({
          ServiceList: serviceData
        }));
      }
      if (nextProps.segmentImages) {
        this.setState({
          segmentImages: nextProps.segmentImages
        });
      }
      if (nextProps.Error && nextProps.Error.trim() !== "") {
        this.setState(() => ({
          error: nextProps.Error,
          isAddNew: true
        }));
      }
      if (nextProps.isSaved) {
        this.setState(
          {
            isAddNew: false,
            isEdit: true
          },
          () => {
            this.props.actions.getSegmentsList(
              this.props.UserId,
              null,
              null,
              this.state.pagination.page
            );
          }
        );
      }
      if (nextProps.isDeleted) {
        this.setState(() => ({
          openDeleteDialog: false
        }));
      }

      if (nextProps.isImageDeleted) {
        const { addSegmentData } = this.state;
        delete addSegmentData.image;
        this.setState(
          {
            addSegmentData: {
              ...this.state.addSegmentData,
              image: null
            }
          },
          () => {
            this.props.actions.denyRemoveImageAction();
          }
        );
      }
    }
  }

  closeModel = () => {
    this.setState({
      modalSegmentLogo: false
    });
  };
  handelEdit = id => {
    const { isEdit, segmentsData } = this.state;
    const data = segmentsData;
    let index = data.findIndex(p => p.id === id);
    if (data[index]) {
      this.setState({
        addSegmentData: {
          name: data[index].name,
          description: data[index].description,
          index: index,
          id: data[index].id,
          is_active: data[index].is_active,
          isDeleted: "",
          image: data[index].image,
          slug: data[index].slug,
          initials: data[index].initials,
          color: data[index].color
        },
        isAddNew: true,
        isEdit: true,
        isClassComponentOpen: false
      });
    }
  };
  getChecked = curObj => {
    const { editsegmentsData } = this.state;
    if (editsegmentsData) {
      let obj = editsegmentsData.find(o => o.public_id === curObj.public_id);
      if (obj) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  openDeleteModalDialog = (
    modalName,
    isOpen,
    index,
    deletedItemName,
    deletedItemKey
  ) => {
    if (isOpen) {
    }
    this.setState({
      [modalName]: isOpen,
      deletedIndex: index,
      deletedItemName: deletedItemName,
      deletedItemKey: deletedItemKey
    });
  };

  getSegmentName = curObj => {
    const { editsegmentsData } = this.state;
    if (editsegmentsData) {
      let obj = editsegmentsData.find(o => o.public_id === curObj.public_id);
      if (obj) {
        return obj.segmentName ? obj.segmentName : "";
      }
      return "";
    } else {
      return "";
    }
  };

  deleteDialog = (state, Id, Index) => {
    this.setState({
      openDeleteDialog: state,
      segmentsId: Id
    });
  };

  closeClassComponentDialog = () => {
    this.setState(() => ({
      isClassComponentOpen: false
    }));
  };

  closeServiceComponentDialog = () => {
    this.setState(() => ({
      isServiceComponentOpen: false
    }));
  };

  deleteSegment = Id => {
    this.props.actions.deleteSegments(this.props.UserId, this.state.segmentsId);
  };

  deleteDilog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        disableBackdropClick={true}
        onClose={() => this.openModalDialog("openDialog", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              Are you sure you want to delete?{" "}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.deleteSegment()}
              >
                Delete
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.deleteDialog(false, "", "")}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  };
  openServiceComponentDialog = id => {
    var { segmentsData } = this.state;
    let index = segmentsData.findIndex(p => p.id === id);
    if (segmentsData) {
      this.setState(() => ({
        isServiceComponentOpen: true,
        segmentsValue: segmentsData[index]
      }));
    }
  };

  openClassComponentDialog = id => {
    var { segmentsData } = this.state;
    let index = segmentsData.findIndex(p => p.id === id);
    if (segmentsData) {
      this.setState(() => ({
        isClassComponentOpen: true,
        segmentsValue: segmentsData[index]
      }));
    }
  };

  saveClassMappingHandler = () => {
    this.state.mappingData.classes = this.state.selectedClassIndexes;
    this.state.mappingData.segment = this.state.segmentsValue.id;
    this.props.actions.mapClassSegment(
      this.props.UserId,
      this.state.mappingData
    );
    console.log(this.state.mappingData);
  };

  saveServiceMappingHandler = () => {
    this.state.mappingData.services = this.state.selectedServiceIndexes;
    let xrefServices = [];
    for (let i = 0; i < this.state.selectedServiceIndexes.length; i++) {
      // xrefServices.push(this.state.ServiceList[this.state.selectedServiceIndexes[i]])
      for (let j = 0; j < this.state.ServiceList.length; j++) {
        if (
          this.state.selectedServiceIndexes[i] === this.state.ServiceList[j].id
        ) {
          xrefServices.push(this.state.ServiceList[j]);
          break;
        }
      }
    }
    let segmentsData = _.cloneDeep(this.state.segmentsData);
    for (let i = 0; i < segmentsData.length; i++) {
      if (segmentsData[i].id === this.state.segmentsValue.id) {
        segmentsData[i].xref_services = [];
        for (let j = 0; j < xrefServices.length; j++) {
          let services = {
            service: xrefServices[j]
          };
          segmentsData[i].xref_services.push(services);
        }
      }
    }
    this.state.mappingData.segment = this.state.segmentsValue.id;
    this.props.actions.mapServiceSegment(
      this.props.UserId,
      this.state.mappingData
    );
    this.setState(() => ({
      isServiceComponentOpen: false,
      segmentsData: segmentsData
    }));
    console.log(this.state.mappingData);
  };

  classComponentDilog = () => {
    const { classes } = this.props;
    var title = "";
    if (this.state.segmentsValue != null) {
      const { name, description } = this.state.segmentsValue;
      title = "Assign classes to " + this.state.segmentsValue.name;
    }
    return (
      <Dialog
        open={this.state.isClassComponentOpen}
        disableBackdropClick={true}
        onClose={() => this.openModalDialog("openDialog", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <b>{title}</b>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <LoadingOverlay>
            <DialogContentText>
              <ClassComponent
                open={this.state.isClassComponentOpen}
                onClose={this.closeClassComponentDialog}
                entityData={this.state.segmentsValue}
                selectedClassIndexes={this.state.selectedClassIndexes}
              />
            </DialogContentText>

            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => this.saveClassMappingHandler()}
            color="danger"
            simple
          >
            Save
          </Button>
          <Button
            onClick={() => this.closeClassComponentDialog()}
            color="danger"
            simple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  classServiceDilog = () => {
    const { classes } = this.props;
    var title = "";
    if (this.state.segmentsValue != null) {
      const { name, description } = this.state.segmentsValue;
      title = "Assign service to " + this.state.segmentsValue.name;
    }
    return (
      <Dialog
        open={this.state.isServiceComponentOpen}
        disableBackdropClick={true}
        onClose={() => this.openModalDialog("openDialog", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <b>{title}</b>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <LoadingOverlay>
            <DialogContentText>
              <ServiceComponent
                open={this.state.isServiceComponentOpen}
                onClose={this.closeServiceComponentDialog}
                entityData={this.state.segmentsValue}
                selectedServiceIndexes={this.state.selectedServiceIndexes}
              />
            </DialogContentText>

            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => this.saveServiceMappingHandler()}
            color="danger"
            simple
          >
            Save
          </Button>
          <Button
            onClick={() => this.closeServiceComponentDialog()}
            color="danger"
            simple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleToggle = curObj => {
    var { editsegmentsData } = this.state;
    if (editsegmentsData) {
      let index = editsegmentsData.findIndex(
        o => o.public_id === curObj.public_id
      );
      if (index > -1) {
        editsegmentsData.splice(index, 1);
      } else {
        editsegmentsData.push(curObj);
      }
    } else {
      editsegmentsData = [];
      editsegmentsData.push(curObj);
    }
    this.setState({
      editsegmentsData
    });
  };

  handleToggleStatus = curObj => event => {
    this.setState({
      addSegmentData: {
        ...this.state.addSegmentData,
        is_active: event.target.checked
      }
    });
  };
  handleSegmentName = curObj => event => {
    var { editsegmentsData } = this.state;
    if (editsegmentsData) {
      let index = editsegmentsData.findIndex(
        o => o.public_id === curObj.public_id
      );
      if (index > -1) {
        if (event.target.value && event.target.value.trim().length > 0) {
          editsegmentsData[index]["segmentName"] = event.target.value;
        } else {
          editsegmentsData.splice(index, 1);
        }
      } else {
        var inserData = {
          ...curObj,
          segmentName: event.target.value
        };
        editsegmentsData.push(inserData);
      }
    } else {
      editsegmentsData = [];
      var insertData = {
        ...curObj,
        segmentName: event.target.value
      };
      editsegmentsData.push(insertData);
    }
    this.setState({
      editsegmentsData
    });
  };

  handelIsAddNew = () => {
    let segmentImages = {};
    segmentImages = _.cloneDeep(this.state.segmentImages);

    for (
      let i = 0;
      i < segmentImages.images && segmentImages.images.resources.length;
      i++
    ) {
      segmentImages.images.resources[i].assigned = false;
    }
    this.setState({
      isAddNew: !this.state.isAddNew,
      addSegmentData: {
        name: "",
        description: "",
        is_active: false,
        is_Deleted: false,
        initials:"",
        color:"",
        image: ""
      },
      segmentImages: segmentImages
    });
  };

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addSegmentData.hasOwnProperty("name") ||
      (this.state.addSegmentData.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addSegmentData.hasOwnProperty("description") ||
      (this.state.addSegmentData.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }

    this.setState({ required: reqData });
    return isValid;
  }

  onGridEditReady = params => {
    this.gridApi = params.api;
    _agGridAPI = _.clone(params.api, true);
  };

  rendersegmentsTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.deleteDilog()}
        {this.classComponentDilog()}
        {this.classServiceDilog()}
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Segments
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.segmentsData}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                AgGridActionButton={SegmentActionButton}
                openClassComponentDialog={this.openClassComponentDialog}
                openServiceComponentDialog={this.openServiceComponentDialog}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                loading={this.props.Loading}
                search={this.searchText}
                new={this.handelIsAddNew}
                isAddBtn={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getSegmentsList(
      this.props.PartnerId,
      null,
      null,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getSegmentsList(
          this.props.PartnerId,
          null,
          null,
          page,
          param
        );
      }
    );
  }

  handelImageChange = file => {
    if (file instanceof Blob) {
      let reader = new FileReader();
      let { addSegmentData } = this.state;
      let original_name = file.name;
      let fileSize = file.size;
      addSegmentData["image"] = file;
      addSegmentData["file_size"] = fileSize;
      addSegmentData["original_file_name"] = original_name;
      this.setState({
        addSegmentData: addSegmentData
      });
    } else {
      let { addSegmentData } = this.state;
      addSegmentData.image = file.url;
      if (file.is_asset) {
        addSegmentData.asset_id = file.id;
      }
      addSegmentData.file_size = file.file_size;
      addSegmentData.original_file_name = file.original_file_name;
      this.setState({
        ...this.state,
        addSegmentData: addSegmentData
      });
    }
  };

  handelImageRemove = () => {
    let { image } = this.state.addSegmentData;

    if (image instanceof Blob) {
      this.setState({
        addSegmentData: {
          ...this.state.addSegmentData,
          image: null
        }
      });
    } else if (image instanceof Object) {
      this.props.actions.removeImageAction(image.id);
    }
  };

  rendersegments = () => {
    const { classes } = this.props;
    const {
      name,
      description,
      image,
      is_active,
      initials,
      color,
      slug
    } = this.state.addSegmentData;
    const { required } = this.state;
    return (
      <GridContainer>
        {this.selectSengmentLogo()}

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              {/* <CardIcon color="info">
                                <Icon style={{ fontSize: '36px' }}>account_circle</Icon>
                            </CardIcon> */}
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <CustomInput
                    success={required.description === "success"}
                    error={required.description === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: this.handleTextChange("description", "")
                    }}
                    id={"description_"}
                  />

                  <CustomInput
                    success={required.slug === "success"}
                    error={required.slug === "error"}
                    labelText="Slug *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: slug,
                      onChange: this.handleTextChange(
                        "slug",
                        validationUtils.validationTypes.name
                      )
                    }}
                    id={"slug_"}
                  />
                  <CustomInput
                    success={required.initials === "success"}
                    error={required.initials === "error"}
                    labelText="Initials *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: initials,
                      onChange: this.handleTextChange(
                        "initials",
                        validationUtils.validationTypes.name
                      )
                    }}
                    id={"slug_"}
                  />
                   <CustomInput
                    success={required.color === "success"}
                    error={required.color === "error"}
                    labelText="Color *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: color,
                      onChange: this.handleTextChange(
                        "color",
                        validationUtils.validationTypes.name
                      )
                    }}
                    id={"color"}
                  />
                  
                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus(is_active)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />
                  <br />

                  <ImageUpload
                    addButtonProps={{
                      round: true
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true
                    }}
                    imgUrl={
                      image instanceof Object
                        ? image instanceof Blob
                          ? image
                          : image.url
                        : image
                    }
                    onimageRemove={this.handelImageRemove}
                    onChange={this.handelImageChange}
                  />

                  {/* {image && image.secure_url && <div className={classes.imgContainer} style={{ width: '80px' }}>
                                        <img src={image && image.secure_url ? image.secure_url : ""} alt="..." className={classes.img} style={{ height: '80px', width: '100px' }} />
                                    </div>
                                    } */}
                  {/* <br />
                                    <Button
                                        color="success"
                                        onClick={() => this.modalSegments(true)}
                                        size='sm'
                                    >
                                        Select logo
                                    </Button> */}
                  <br />
                  {this.props.Error && this.props.Error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}
                  <br />

                  <Button color="info" onClick={this.saveSegments}>
                    {this.state.isEdit ? "Save" : "Save"}
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {this.props.isSaving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.isSaving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  modalSegments = type => {
    this.setState({
      modalSegmentLogo: type
    });
  };
  selectSengmentLogo = () => {
    const { segmentImages } = this.state;
    return (
      <Dialog
        open={this.state.modalSegmentLogo}
        disableBackdropClick={true}
        onClose={() => this.openModalDialog("modalContactUs", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Logo</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardBody>
                    <form>
                      <LoadingOverlay>
                        <GridContainer>
                          {segmentImages &&
                            segmentImages.images &&
                            segmentImages.images.resources &&
                            _.map(
                              segmentImages.images.resources,
                              (obj, index) => {
                                return (
                                  <GridItem xs={6} sm={4} md={3}>
                                    <div
                                      style={{
                                        border: "1px solid #D1D1D1",
                                        boxSizing: "border-box",
                                        borderRadius: "6px",
                                        marginTop: "10px",
                                        cursor: "pointer",
                                        background:
                                          obj && obj.assigned ? "#bce0e4" : ""
                                      }}
                                      onClick={() =>
                                        this.assignToggelSegment(obj.public_id)
                                      }
                                    >
                                      <div
                                        style={{
                                          minHeight: "28px",
                                          marginTop: "12px"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: "bold",
                                            fontSize: "20px",
                                            lineHeight: "28px",
                                            textAlign: "center",
                                            color: "#EB9514",
                                            display: "block",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "100%"
                                          }}
                                          title={obj.name}
                                        >
                                          {obj.name}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          height: "80px",
                                          textAlign: "center"
                                        }}
                                      >
                                        <img
                                          src={obj.secure_url}
                                          alt={obj.public_id}
                                          style={{
                                            height: "50px",
                                            width: "80px"
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </GridItem>
                                );
                              }
                            )}
                        </GridContainer>
                        <br />
                        <br />
                        {
                          <Button color="info" onClick={this.onSelectClick}>
                            select{" "}
                          </Button>
                        }
                        {<Button onClick={this.closeModel}> Cancel </Button>}
                        {(this.props.Loading ||
                          this.props.loadingGetData ||
                          this.props.segmentImages.loading) && (
                          <CircularProgress
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-25px",
                              marginLeft: "-12px"
                            }}
                          />
                        )}
                        <Loader loading={this.props.Loading} text="Saving.." />
                        <Loader
                          loading={
                            this.props.loadingGetData ||
                            this.props.segmentImages.loading
                          }
                          text="Loading.."
                        />
                      </LoadingOverlay>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContentText>
          <DialogActions>
            {/* <Button style={{
                                paddingLeft: "10px",
                                paddingRight: "5px"
                            }} className={classes.marginRight} simple onClick={() => this.saveReatiler()} color="primary">
                                {this.state.addNew ? "Add" : "Update"}
                            </Button>
                            <Button style={{
                                paddingLeft: "10px",
                                paddingRight: "5px"
                            }} className={classes.marginRight} simple onClick={() => this.openModalDialog("modalContactUs", false)} color="primary" autoFocus>
                                Cancel
                    </Button> */}
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  };

  assignToggelSegment = logoKey => {
    const { segmentImages } = this.state;
    for (var i = 0; i <= segmentImages.images.resources.length; i++) {
      if (
        segmentImages.images.resources[i] &&
        segmentImages.images.resources[i].assigned
      ) {
        segmentImages.images.resources[i].assigned = false;
      }
    }
    var find_index = segmentImages.images.resources.findIndex(
      obj => obj.public_id.trim() === logoKey.trim()
    );
    if (find_index >= 0) {
      segmentImages.images.resources[find_index].assigned = !segmentImages
        .images.resources[find_index].assigned;
    }
    this.setState({
      segmentImages: segmentImages
    });
  };

  onSelectClick = () => {
    const { segmentImages } = this.state;

    var find_index = segmentImages.images.resources.findIndex(
      obj => obj.assigned === true
    );

    let { addSegmentData } = this.state;
    addSegmentData.image =
      segmentImages.images.resources[find_index].secure_url;
    // if (file.is_asset) {
    //     addSegmentData.asset_id = file.id;
    // }
    addSegmentData.file_size = segmentImages.images.resources[find_index].bytes;

    let original_file_name = "";
    if (segmentImages.images.resources[find_index].public_id) {
      original_file_name = segmentImages.images.resources[
        find_index
      ].public_id.replace("/", "_");
    }
    addSegmentData.original_file_name = original_file_name;
    this.setState({
      ...this.state,
      addSegmentData: addSegmentData
    });

    this.setState({
      addSegmentData: {
        ...this.state.addSegmentData,
        image: segmentImages.images.resources[find_index].secure_url
      },
      modalSegmentLogo: false
    });
  };

  // handelImageRemove = () => {
  //     var currState = this.state.addSegmentData;
  //     currState['file'] = null;
  //     delete currState.imagePreviewUrl;
  //     this.setState({
  //         currState
  //     });
  // }

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    let new_slug = this.state.addSegmentData.slug;

    if (!this.state.addSegmentData.id && key === "name") {
      new_slug = this.string_to_slug(event.target.value);
      reqData["slug"] = "success";
    }
    if (key === "slug") new_slug = this.string_to_slug(event.target.value);

    this.setState({
      addSegmentData: {
        ...this.state.addSegmentData,
        [key]: event.target.value,
        slug: new_slug
      }
    });
  };

  string_to_slug = str => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-") // collapse dashes
      .replace(/^-+/, ""); // trim - from start of text
    // .replace(/-+$/, ""); // trim - from end of text

    return str;
  };

  // handelImageChange = (file) => {
  //     let reader = new FileReader();
  //     reader.onloadend = () => {
  //         this.setState({
  //             addSegmentData: {
  //                 ...this.state.addSegmentData,
  //                 file: file,
  //                 imagePreviewUrl: reader.result
  //             }
  //         });
  //     };
  //     reader.readAsDataURL(file);
  // }

  render() {
    return this.state.isAddNew
      ? this.rendersegments()
      : this.rendersegmentsTable();
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.SegmentsReducer.list,
  pagination: state.SegmentsReducer.pagination,
  Loading: state.SegmentsReducer.loading,
  Error: state.SegmentsReducer.error,
  segmentImages: state.retailerState.segmentImages,
  loadingGetData: state.retailerState.loadingData,
  isSaved: state.SegmentsReducer.isSaved,
  isSaving: state.SegmentsReducer.isSaving,
  UserKey: state.authState.user.Token,
  UserId: state.authState.user.user,
  isDeleted: state.SegmentsReducer.isDeleted,
  ServiceList: state.serviceReducer.list,
  isImageDeleted: state.appMenuReducer.removeImage.isDeleted,
  PartnerId: state.authState.user.partner_id
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({ ...regularFormsStyle, ...tasksStyle }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Segment);
