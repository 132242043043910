// import axios from 'axios';
import axios from "../utils/axios";
import { localStorageUtils } from "../utils";
import { updateTypeKey, config } from "../constants";
export const login = async (email, password, isImpersonting, loggedInUser) => {
  try {
    let data = new FormData();
    data.append("userid", email);
    data.append("password", password);
    data.append("_repository_id", config._repository_id);
    data.append("_branch_id", config._branch_id);
    data.append("_type", config._type);
    data.append("key", updateTypeKey.updateAboutUS);

    let response = {};
    if (isImpersonting) {
      data.append("loggedinuser", loggedInUser);
      response = await axios.post("/api/admin/impersonate/", data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      if (response && response.data && response.data.token) {
        localStorageUtils.setToken(
          localStorageUtils.TOKEN,
          response.data.token
        );
        localStorageUtils.setToken(localStorageUtils.USERID, email);
        if (response.data.loggedinuser) {
          localStorageUtils.setToken(
            localStorageUtils.SESSION,
            JSON.stringify(response.data.loggedinuser)
          );
        } else {
          localStorageUtils.removeToken(localStorageUtils.SESSION);
        }
      }
    } else {
      response = await axios.post("/api/login/", data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      if (response && response.data && response.data.token) {
        localStorageUtils.setToken(
          localStorageUtils.SSO_TOKEN,
          response.data.sso_token
        );
        localStorageUtils.setToken(
          localStorageUtils.TOKEN,
          response.data.token
        );
        localStorageUtils.setToken(localStorageUtils.USERID, email);
      } else {
        localStorageUtils.removeToken(localStorageUtils.SSO_TOKEN);
        localStorageUtils.removeToken(localStorageUtils.TOKEN);
        localStorageUtils.removeToken(localStorageUtils.USERID);
      }
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCurrentUser = async () => {
  try {
    const email = localStorageUtils.getToken(localStorageUtils.USERID);
    const impersonatingUser = localStorageUtils.getToken(
      localStorageUtils.impersonating
    );
    let data = new FormData();
    data.append("userid", email);
    data.append("_repository_id", config._repository_id);
    data.append("_branch_id", config._branch_id);
    data.append("_type", config._type);
    data.append("key", updateTypeKey.updateAboutUS);
    if (impersonatingUser) {
      data.append("impersonating", impersonatingUser);
    }

    const response = await axios.post("/api/current/user/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    if (response && response.data && response.data.token) {
      localStorageUtils.setToken(
        localStorageUtils.SSO_TOKEN,
        response.data.sso_token
      );
      localStorageUtils.setToken(localStorageUtils.TOKEN, response.data.token);
    } else {
      localStorageUtils.removeToken(localStorageUtils.SSO_TOKEN);
      localStorageUtils.removeToken(localStorageUtils.TOKEN);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserToken = async (token, key) => {
  try {
    const response = await axios.get(
      "api/verifyjwttoken/?token=" + token + "&apikey=" + key,
      null,
      localStorageUtils.getHeader()
    );
    if (response && response.data && response.data.token) {
      localStorageUtils.setToken(
        localStorageUtils.SSO_TOKEN,
        response.data.sso_token
      );
      localStorageUtils.setToken(localStorageUtils.TOKEN, response.data.token);
      localStorageUtils.setToken(
        localStorageUtils.USERID,
        response.data.data.email
      );
      localStorageUtils.setToken(localStorageUtils.impersonating, key);
    } else {
      localStorageUtils.removeToken(localStorageUtils.SSO_TOKEN);
      localStorageUtils.removeToken(localStorageUtils.TOKEN);
      localStorageUtils.removeToken(localStorageUtils.USERID);
      localStorageUtils.removeToken(localStorageUtils.impersonating);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};
