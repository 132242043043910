import * as CMSContantService from "../../services/CMSContantService";
import * as actionTypes from "./actionTypes";

export const createCMSData = (userId, data, files) => async dispatch => {
  dispatch(createCMSContantRequest());
  try {
    var success = await CMSContantService.createCMSData(userId, data, files);
    if (success.status && success.status === "1") {
      dispatch(
        createCMSContantSuccess("Data Saved Successfully.", success.data)
      );
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(createCMSContantFailure(success.error));
    } else {
      dispatch(createCMSContantFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(createCMSContantFailure(error.message));
  }
};

export const getCMSData = (menuId, retailerid) => async dispatch => {
  dispatch(getCMSContantRequest());
  try {
    var success = await CMSContantService.getCMSData(menuId, retailerid);
    if (success.status && success.status === "1") {
      dispatch(getCMSContantSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getCMSContantFailure(success.error));
    } else {
      dispatch(getCMSContantFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getCMSContantFailure(error.message));
  }
};

const getCMSContantRequest = () => {
  return {
    type: actionTypes.GET_CONTENT_REQUEST
  };
};

const getCMSContantSuccess = data => {
  return {
    type: actionTypes.GET_CONTENT_SUCCESS,
    payload: data
  };
};

const getCMSContantFailure = error => {
  return {
    type: actionTypes.GET_CONTENT_FAILURE,
    payload: error
  };
};

const createCMSContantRequest = () => {
  return {
    type: actionTypes.CREATE_CONTENT_REQUEST
  };
};

const createCMSContantSuccess = (data, id) => {
  let res = {
    data: "",
    id: ""
  };
  res.data = data;
  res.id = id;
  return {
    type: actionTypes.CREATE_CONTENT_SUCCESS,
    payload: res
  };
};

const createCMSContantFailure = error => {
  return {
    type: actionTypes.CREATE_CONTENT_FAILURE,
    payload: error
  };
};

export const getCMSMenu = (Partnerid, type) => async dispatch => {
  dispatch(getCMSMenuRequest());
  try {
    var success = await CMSContantService.getCMSMenu(Partnerid, type);

    if (success.status && success.status === "1") {
      dispatch(getCMSMenuSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getCMSMenuFailure(success.error));
    } else {
      dispatch(getCMSMenuFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getCMSMenuFailure(error.message));
  }
};

const getCMSMenuRequest = () => {
  return {
    type: actionTypes.GET_CMS_MENU_REQUEST
  };
};

const getCMSMenuSuccess = data => {
  return {
    type: actionTypes.GET_CMS_MENU_SUCCESS,
    payload: data
  };
};

const getCMSMenuFailure = error => {
  return {
    type: actionTypes.GET_CMS_MENU_FAILURE,
    payload: error
  };
};
