import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  list: [],
  error: null,
  loader: false
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SEND_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        list: action.payload,
        error: null,
        loader: false
      };
    case actionTypes.SEND_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        list: action.payload,
        error: null,
        loader: false
      };
    case actionTypes.SEND_FORGOT_PASSWORD_REQUEST:
      return {
        list: [],
        error: null,
        loader: false
      };

    default:
      return state;
  }
};

export default authReducer;
