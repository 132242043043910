import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Assignment from "@material-ui/icons/Assignment";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import Slide from "@material-ui/core/Slide";

import moment from "moment";

import { AgGridReact } from "ag-grid-react";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function dateFormatter(params) {
  if (params.value) {
    return moment(params.value).format("MM/DD/YYYY h:mm a");
  } else {
    return "";
  }
}

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          hide: true
        },
        {
          headerName: "Message Type",
          field: "notification_type.name",
          width: 200,
          resizable: true,
          rowGroup: true,
          hide: true
        },
        {
          headerName: "To",
          field: "to_user_email",
          width: 250,
          resizable: true
        },
        {
          headerName: "Subject",
          field: "subject",
          width: 350,
          resizable: true
        },
        {
          headerName: "Date",
          field: "created_at",
          sortable: true,
          resizable: true,
          valueFormatter: dateFormatter
        }
      ],
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      domLayout: "autoHeight",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log(this.props.user);
  }

  componentDidMount() {
    this.props.actions.getMessageList(this.props.UserId.id);
  }

  render() {
    return this.renderMessageTable();
  }

  renderMessageTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>Notifications</p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <div>
                  <div
                    id="myGrid"
                    style={{
                      width: "100%"
                    }}
                    className="ag-theme-material"
                  >
                    <AgGridReact
                      gridOptions={this.gridOptions}
                      columnDefs={this.state.columnDefs}
                      defaultColDef={this.state.columnDefs}
                      rowData={this.props.List}
                      showToolPanel={true}
                      // floatingFilter={true}
                      statusBar={this.state.statusBar}
                      groupSelectsChildren={true}
                      sideBar={this.state.sideBar}
                      autoGroupColumnDef={this.state.autoGroupColumnDef}
                      pagination={true}
                      paginationPageSize={50}
                      rowMultiSelectWithClick={true}
                      icons={this.state.icons}
                      rowSelection={this.state.rowSelection}
                      onGridReady={this.onGridEditReady}
                      domLayout={this.state.domLayout}
                    />
                  </div>
                </div>

                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.messageReducer.get_message.list,
  Error: state.messageReducer.get_message.error,
  Loading: state.messageReducer.get_message.loading,
  UserId: state.authState.user
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Message);
