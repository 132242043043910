import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import FormLabel from "@material-ui/core/FormLabel";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ImageUpload from "../components/ImageUpload.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import InputLabel from "@material-ui/core/InputLabel";
import Slide from "@material-ui/core/Slide";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import CustomPageActionButton from "../../components/AgGrid/CustomPageActionButton";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
const pageData = {
  id: "",
  name: "",
  value: {}
};

class CustomPages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      newPage: {
        title: "",
        description: "",
        images: [
          {
            id: "",
            url: ""
          }
        ]
      },
      imageObj: {
        id: "",
        image: null
      },
      isAddNew: false,
      addPage: {
        ...pageData
      },
      required: {
        email: "",
        password: "",
        user_group_id: ""
      },
      customPageList: [],
      userGroupData: null,
      error: null,
      openDeleteDialog: false,
      userGroupId: null,
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 800,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ],
      editorState: EditorState.createEmpty()
    };
    this.handelEdit = this.handelEdit.bind(this);
    this.savePage = this.savePage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        let { isAddNew, isEdit, files } = this.state;
        const list = nextProps.List;
        if (nextProps.isSaved) {
          isAddNew = false;
          isEdit = false;
          files = [];
        }
        this.setState(() => ({
          customPageList: list,
          isAddNew: isAddNew,
          isEdit: isEdit,
          files: files
        }));
      }

      if (
        nextProps.pageData &&
        !_.isEqual(nextProps.pageData, this.props.pageData)
      ) {
        const content = htmlToDraft(nextProps.pageData.value.description);
        const contentState = ContentState.createFromBlockArray(
          content.contentBlocks,
          content.entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        let images = nextProps.pageData.value.images;
        images.push(this.state.imageObj);
        this.setState(() => ({
          addPage: nextProps.pageData,
          newPage: {
            ...nextProps.pageData.value,
            images: images
          },
          editorState: editorState,
          isAddNew: true,
          isEdit: true
        }));
      }
      let loading = false;
      let error = null;
      if (nextProps.Error) {
        error = nextProps.Error;
      }

      if (nextProps.Loading) {
        loading = nextProps.Loading;
      }

      if (nextProps.pageDataError) {
        error = nextProps.pageDataError;
      }

      if (nextProps.pageDataLoading) {
        loading = nextProps.pageDataLoading;
      }

      this.setState({
        loading: loading,
        error: error
      });
    }
  }

  componentDidMount() {
    this.props.actions.getPageListAction(this.props.PartnerId);
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: true,
      newPage: {
        title: "",
        description: "",
        images: [
          {
            id: "",
            image: null
          }
        ]
      },
      files: [],
      editorState: EditorState.createEmpty()
    });
  };

  handleChange = () => event => {
    this.setState({
      newPage: {
        ...this.state.newPage,
        [event.target.name]: event.target.value
      }
    });
  };

  savePage = () => {
    let { addPage, newPage, files } = this.state;
    if (newPage.title == undefined || newPage.title.trim() == "") {
      this.setState({
        error: "Please add title for page"
      });
    } else {
      let index = newPage.images.findIndex(x => x.image == null);
      if (index > -1) {
        newPage.images.splice(index, 1);
      }
      addPage.name = newPage.title;
      addPage.value = newPage;
      this.props.actions.createPageAction(
        this.props.UserId,
        this.props.PartnerId,
        addPage,
        files
      );
    }
  };

  handelEdit = id => {
    this.props.actions.getPageByIdAction(id);
  };

  closeCreateModal = () => {
    this.setState({
      addPage: {
        id: "",
        name: "",
        value: {}
      },
      newPage: {
        title: "",
        description: "",
        images: []
      },
      isAddNew: false,
      isEdit: false,
      editorState: EditorState.createEmpty()
    });
  };

  render() {
    return this.state.isAddNew ? this.renderPages() : this.renderPagesTable();
  }

  renderPages = () => {
    const { classes } = this.props;
    const { title, description, images } = this.state.newPage;
    const { editorState } = this.state;
    return (
      <Card>
        <CardHeader>
          <p className={classes.cardIconTitle + " wholesalor-hierarchy-title"}>
            Make edits to the fields below, then save changes.
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer className="grid-container">
            <LoadingOverlay>
              <GridItem
                lg={12}
                md={12}
                className="griditem-control page-grid-item"
              >
                <InputLabel className={classes.label + " "}>Title</InputLabel>
                <TextField
                  value={title ? title : ""}
                  variant="outlined"
                  name="title"
                  fullWidth
                  // placeholder="Title"
                  classes={{
                    root: "attribute-txtbox"
                  }}
                  onChange={this.handleChange()}
                />
              </GridItem>
              <GridItem
                lg={12}
                md={12}
                className="griditem-control page-grid-item"
              >
                <InputLabel className={classes.label + " "}>
                  Description
                </InputLabel>
                <Editor
                  // readOnly={true}
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapper"
                  editorClassName="editor"
                  onEditorStateChange={this.onEditorStateChange}
                />
              </GridItem>
              <GridItem
                lg={12}
                md={12}
                className="griditem-control page-grid-item"
              >
                {/* <InputLabel
                                    className={classes.label + " "}
                                    labelText="Image"
                                >
                                    Image
                                </InputLabel>
                                <br /> */}
                {_.map(images, ({ id, image }, index) => {
                  return (
                    <ImageUpload
                      key={index}
                      ref={"image_" + index}
                      addButtonProps={{
                        round: true
                      }}
                      changeButtonProps={{
                        color: "rose",
                        round: true
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true
                      }}
                      imgUrl={
                        image instanceof Object
                          ? image instanceof Blob
                            ? image
                            : image.secure_url
                          : image
                      }
                      onimageRemove={() => this.handelImageRemove(index)}
                      onChange={file => this.handelImageChange(file, id)}
                    />
                  );
                })}
              </GridItem>
              {this.state.error && this.state.error.trim() !== "" && (
                <div>
                  <FormLabel className={classes.labelLeftHorizontal}>
                    <code>{this.state.error}</code>
                  </FormLabel>
                </div>
              )}

              <br />
              <Button color="info" onClick={this.savePage}>
                {this.state.isEdit ? "Update Changes" : "Save Changes"}
              </Button>
              <Button onClick={this.closeCreateModal}>Cancel</Button>
              {this.state.loading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-12px"
                  }}
                />
              )}
              <Loader loading={this.state.loading} text="Saving.." />
            </LoadingOverlay>
          </GridContainer>
        </CardBody>
      </Card>
    );
  };

  handelImageChange = (file, id) => {
    let { imageObj, files } = this.state;
    let { images } = this.state.newPage;
    let clonnedObj = _.clone(imageObj);
    if (file instanceof Blob) {
      clonnedObj.image = file;
      clonnedObj.file_size = file.size;
      clonnedObj.original_file_name = file.name;
      clonnedObj.name = file.name;
      files.push(file);
    } else {
      clonnedObj.image = file.url;
      if (file.is_asset) {
        clonnedObj.asset_id = file.id;
      }
      clonnedObj.file_size = file.file_size;
      clonnedObj.original_file_name = file.original_file_name;
      clonnedObj.name = file.name;
    }
    images.splice(images.length - 1, 0, clonnedObj);
    this.setState({
      ...this.state,
      files: files,
      newPage: {
        ...this.state.newPage,
        images: images
      }
    });
  };

  handelImageRemove = index => {
    let { images } = this.state.newPage;
    let selectedImage = images[index];
    if (selectedImage instanceof Object && selectedImage.id !== "") {
      this.props.actions.removeImageAction(selectedImage.id);
    } else {
      images.splice(index, 1);
      this.setState({
        newPage: {
          ...this.state.newPage,
          images: images
        }
      });
    }
  };

  onEditorStateChange = editorState => {
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.setState({
      ...this.state,
      editorState: editorState,
      newPage: {
        ...this.state.newPage,
        description: value
      }
    });
  };

  renderPagesTable = () => {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="rose" icon>
          <div>
            <p className={classes.cardIconTitle + " grid-header-title"}>
              Pages
            </p>
            <p>
              <Button
                disabled={this.state.loading}
                color="success"
                onClick={this.handelIsAddNew}
                size="sm"
                className={classes.marginRight + " grid-header-button"}
              >
                <AddIcon className="grid-header-button-icon" /> New
              </Button>
            </p>
          </div>
        </CardHeader>
        <CardBody>
          <AgGrid
            rowData={this.state.customPageList}
            columnDefs={this.state.columnDefs}
            handelEdit={this.handelEdit}
            Loading={this.state.loading}
            AgGridActionButton={CustomPageActionButton}
          />
        </CardBody>
      </Card>
    );
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.customPageReducer.data,
  Loading: state.customPageReducer.loading,
  Error: state.customPageReducer.error,
  isSaved: state.customPageReducer.isSaved,
  pageData: state.customPageReducer.get_page_byid.data,
  pageDataLoading: state.customPageReducer.get_page_byid.loading,
  pageDataError: state.customPageReducer.get_page_byid.error,
  PartnerId: state.authState.user.partner_id,
  UserId: state.authState.user.pk
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.RETAILER ||
  rolesNames.RoleMaster.WHOLESALOR ||
  rolesNames.RoleMaster.BRAND;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CustomPages);
