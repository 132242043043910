import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "../../style/app.css";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

import { AgGridReact } from "ag-grid-react";

let AggridApi;

class MenuListGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userGroupId: "",
      data: {},
      menus: [],
      selectedMenu: [],
      columnDefs: [
        {
          headerName: "Menu",
          field: "name",
          width: 400,
          resizable: true,
          cellRenderer: "group",
          checkboxSelection: true
        }
      ],
      context: { componentParent: this },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      domLayout: "autoHeight",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      getNodeChildDetails: function (data) {
        if (data && data.views && data.views.length > 0) {
          let children = [];
          children = data.views;
          return {
            group: true,
            children: children,
            expanded: data.open
          };
        } else {
          return null;
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.type !== nextProps.type) {
      this.refreshList(nextProps.type);
    }
    if (
      !_.isEqual(this.props.appMenus, nextProps.appMenus))
    {
        if (nextProps && nextProps.appMenus) {
          this.setState(
            {
              ...this.state,
              menus: nextProps.appMenus
            },
            () => {
              if (this.gridApi) {
                this.gridApi.setRowData(this.state.menus);
                if (
                  this.props.selected_items &&
                  this.props.selected_items.length > 0
                ) {
                  let selected_items = this.props.selected_items;
                  this.gridApi.forEachNode(function (node) {
                    if (selected_items.indexOf(node.data.id) > -1) {
                      node.setSelected(true);
                    } else {
                      node.setSelected(false);
                    }
                  });
                }
              }
            }
          );
        }
      }
    }
 


    refreshList(type) {
      this.props.actions.getPackagesMenuListAction(this.props.authUser.pk, type);
    }

    componentDidMount() {
      if (this.props.type) {
        this.refreshList(this.props.type);
      }
    }

    render() {
      return <div>{this.renderOrderTable()}</div>;
    }

    renderOrderTable = () => {
      const { classes } = this.props;
      return (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <p
                  className={
                    classes.cardIconTitle + " wholesalor-hierarchy-title"
                  }
                >
                  Menus
                </p>
              </CardHeader>
              <CardBody>
                <LoadingOverlay>
                  <div>
                    <div
                      id="myGrid"
                      style={{
                        width: "100%"
                      }}
                      className="ag-theme-material"
                    >
                      <AgGridReact
                        columnDefs={this.state.columnDefs}
                        defaultColDef={this.state.defaultColDef}
                        // rowData={this.state.menus}
                        showToolPanel={true}
                        onGridReady={param => this.onGridReady(param)}
                        onSelectionChanged={this.onSelectionChanged.bind(this)}
                        statusBar={this.state.statusBar}
                        rowSelection="multiple"
                        sideBar={this.state.sideBar}
                        domLayout={this.state.domLayout}
                        getNodeChildDetails={this.state.getNodeChildDetails}
                        groupSelectsChildren={true}
                        groupSelectsFiltered={true}
                      // pagination={true}
                      // paginationAutoPageSize={true}
                      />
                    </div>
                  </div>
                  {this.props.Loading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Loading.." />
                </LoadingOverlay>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    };

    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      let selectedIds = [];
      if (selectedRows) {
        selectedIds = _.flatMap(selectedRows, data => {
          return data.id;
        });
        this.props.setSelectedItems(selectedIds);
      }
      // var length = this.state.selectedMenu.length;
      // var i = 0;
      // const componentThis = this;
      // for (i = 0; i < length; i++) {
      //     componentThis.state.selectedMenu.pop();
      // }
      // selectedRows.forEach(function (selectedRow, index) {
      //     componentThis.state.selectedMenu.push(selectedRow);
      // });
    }

    onGridReady(params) {
      this.gridApi = params.api;
      params.api.sizeColumnsToFit();
    }
  }

  const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
  });

  const mapStateToProps = state => ({
    authUser: state.authState.user,
    menuList: state.partnerMenuReducer.get_partner_menu_list,
    appMenus: state.appMenuReducer.package.menu,
    List: state.userGroupReducer.userGroupMenu.list
  });

MenuListGrid.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    save: PropTypes.func,
    type: PropTypes.string.isRequired
  };

export default compose(
    withStyles({
      ...regularFormsStyle,
      ...extendedTablesStyle,
      ...validationFormsStyle,
      ...basicsStyle
    }),
      connect(
        mapStateToProps,
        mapDispatchToProps
      )
) (MenuListGrid);
