import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";

import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import Table from "components/Table/Table.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { validationUtils } from "../../utils";

class Brands extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      name: "",
      image: "",
      file: null,
      tableData: [],
      required: {
        name: ""
      }
    };
  }

  componentDidMount() {
    if (
      this.props.List &&
      this.props.List.length > 0 &&
      this.props.List[0]["brands"]
    ) {
      const brandsData = this.props.List[0]["brands"];
      var result = Object.keys(brandsData).map(function(key) {
        return brandsData[key];
      });
      this.setState(() => ({
        tableData: result
      }));
    } else {
      this.props.actions.getRetailer(this.props.UserKey);
    }
  }

  saveReatiler = () => {
    var reqData = this.state["required"];
    var isValid = true;
    // if((this.state.title.trim() === "" || reqData["title"] === "error")){
    //     reqData["title"] = "error";
    //     isValid = false;
    // }
    if (this.state.name.trim() === "" || reqData["name"] === "error") {
      reqData["name"] = "error";
      isValid = false;
    }
    // if((this.state.copy.trim() === "" || reqData["copy"] === "error")){
    //     reqData["copy"] = "error";
    //     isValid = false;
    // }
    this.setState({ required: reqData });
    if (isValid) {
      const savingData = this.state.tableData;
      const { name, imagePreviewUrl, file } = this.state;
      var result = Object.keys(savingData).map(function(key) {
        return savingData[key];
      });
      var insertData = {
        name: name
      };
      if (imagePreviewUrl) {
        insertData["imagePreviewUrl"] = imagePreviewUrl;
        insertData["file"] = file;
      }
      result.push(insertData);
      var data = {
        brands: result
      };
      this.props.actions.saveRetailer(data, this.props.UserKey);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        nextProps.List[0]["brands"] &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const brandsData = nextProps.List[0]["brands"];
        var result = Object.keys(brandsData).map(function(key) {
          return brandsData[key];
        });
        this.setState(() => ({
          tableData: result
        }));
      }
      if (nextProps.isSaved) {
        this.saveChanges();
      }
    }
  }

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({
      [key]: event.target.value,
      required: reqData
    });
  };

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      name: "",
      imagePreviewUrl: "",
      file: null,
      required: {
        name: ""
      }
    });
  };

  handelImageRemove = () => {
    var currState = this.state;
    currState["file"] = null;
    delete currState.imagePreviewUrl;
    this.setState({
      currState
    });
  };
  handelImageChange = file => {
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  saveChanges = () => {
    const { isAddNew } = this.state;
    if (isAddNew) {
      this.handelIsAddNew();
    }
  };

  getTableData = () => {
    const { tableData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(tableData, ({ name, imagePreviewUrl, image }, index) => {
      returnData.push([
        <div className={classes.imgContainer} style={{ width: "80px" }}>
          <img
            src={image ? image.secure_url : imagePreviewUrl}
            alt="..."
            className={classes.img}
            style={{ height: "70px", width: "auto" }}
          />
        </div>,
        <span>
          {
            //eslint-disable-next-line
                            <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
              {name}
            </a>
          }
        </span>,
        <Button simple className={classes.actionButton}>
          <Close className={classes.icon} />
        </Button>
      ]);
    });
    return returnData;
  };

  renderBrandsTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.loadingGetData}
              color="success"
              onClick={this.handelIsAddNew}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} /> Brand
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>Brand Table</p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <Table
                  tableHead={["", "BRAND Name", ""]}
                  tableData={this.getTableData()}
                  tableShopping
                  customHeadCellClasses={[
                    classes.center,
                    classes.description,
                    classes.description,
                    classes.right,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                  customCellClasses={[
                    classes.tdName,
                    classes.customFont,
                    classes.customFont,
                    classes.tdNumber,
                    classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                    classes.tdNumber
                  ]}
                  customClassesForCells={[1, 2, 3, 4, 5, 6]}
                />
                {(this.props.Loading || this.props.loadingGetData) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.loadingGetData} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderAddBrandsMember = () => {
    const { classes } = this.props;
    const { name, required, image } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <br />
                  <br />
                  <br />
                  <legend>
                    {" "}
                    Upload Logo&nbsp;
                    <span style={{ color: "#AAAAAA", fontSize: "13px" }}>
                      (Optimal logo size to upload should be 540x240 at 72 dpi)
                    </span>
                  </legend>
                  <ImageUpload
                    addButtonProps={{
                      round: true
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true
                    }}
                    imgUrl={image ? image.secure_url : null}
                    onimageRemove={this.handelImageRemove}
                    onChange={this.handelImageChange}
                  />
                  <br />
                  <br />
                  <br />
                  <Button color="info" onClick={this.saveReatiler}>
                    Save Changes
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {(this.props.Loading || this.props.loadingGetData) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    return this.state.isAddNew
      ? this.renderAddBrandsMember()
      : this.renderBrandsTable();
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.retailerState.list,
  Loading: state.retailerState.loading,
  ListError: state.retailerState.error,
  loadingGetData: state.retailerState.loadingData,
  isSaved: state.retailerState.isSaved,
  UserKey: state.authState.user.Token
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({ ...regularFormsStyle, ...extendedTablesStyle }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Brands);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Brands);
