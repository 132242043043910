// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const createPage = async (userId, partnerid, Savedata, files) => {
  try {
    console.log(JSON.stringify(Savedata));
    let data = new FormData();
    data.append("userid", userId);
    data.append("partnerid", partnerid);
    data.append("data", JSON.stringify(Savedata));
    files.forEach(file => {
      data.append(file.name, file);
    });
    const response = await axios.post("/api/CMS/Pages/Add/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPageList = async partnerid => {
  try {
    const response = await axios.get(
      "/api/partner/pages/list/?partnerid=" + partnerid
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPageById = async id => {
  try {
    const response = await axios.get(
      "/api/partner/page-by-id/get/?pageid=" + id
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
