import * as campignManagementServices from "../../services/campignManagementServices";
import * as actionTypes from "./actionTypes";
// import { REGION } from 'google-maps';

export const saveCampaign = (id, data, partnerid, files) => async dispatch => {
  dispatch(saveCampaignRequest());
  try {
    let successnew = await campignManagementServices.saveCampaignData(
      id,
      data,
      partnerid,
      files
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(saveCampaignSuccess(successnew.data));
    } else {
      dispatch(saveCampaignFailure(successnew.error));
    }
  } catch (error) {
    dispatch(saveCampaignFailure(error.message));
  }
};

const saveCampaignRequest = () => {
  return {
    type: actionTypes.SAVE_CAMPAIGN_DATA_REQUEST
  };
};

const saveCampaignSuccess = data => {
  return {
    type: actionTypes.SAVE_CAMPAIGN_DATA_SUCCESS,
    payload: data
  };
};

const saveCampaignFailure = error => {
  return {
    type: actionTypes.SAVE_CAMPAIGN_DATA_FAILURE,
    payload: error
  };
};

export const campaignList = (
  brandid,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(CampaignListRequest());
  try {
    var success = await campignManagementServices.getCampaignList(
      brandid,
      page,
      search
    );
    if (success.status && success.status === "1") {
      dispatch(CampaignListSuccess(success.data));
    } else {
      dispatch(CampaignListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(CampaignListFailure(error.message));
  }
};

const CampaignListRequest = () => {
  return {
    type: actionTypes.CAMPAIGN_LIST_REQUEST
  };
};

const CampaignListSuccess = data => {
  return {
    type: actionTypes.CAMPAIGN_LIST_SUCCESS,
    payload: data
  };
};

const CampaignListFailure = error => {
  return {
    type: actionTypes.CAMPAIGN_LIST_FAILURE,
    payload: error
  };
};

export const playPauseCampaign = (id, value, userId) => async dispatch => {
  dispatch(playPauseCampaignRequest());
  try {
    var success = await campignManagementServices.playPauseCampaign(
      id,
      value,
      userId
    );
    if (success.status && success.status === "1") {
      dispatch(playPauseCampaignSuccess(success.data));
    } else {
      dispatch(playPauseCampaignFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(playPauseCampaignFailure(error.message));
  }
};

const playPauseCampaignRequest = () => {
  return {
    type: actionTypes.PLAY_PAUSE_CAMPAIGN_REQUEST
  };
};

const playPauseCampaignSuccess = data => {
  return {
    type: actionTypes.PLAY_PAUSE_CAMPAIGN_SUCCESS,
    payload: data
  };
};

const playPauseCampaignFailure = error => {
  return {
    type: actionTypes.PLAY_PAUSE_CAMPAIGN_FAILURE,
    payload: error
  };
};

export const deleteCampaignAction = (id, userId) => async dispatch => {
  dispatch(deleteCampaignRequest());
  try {
    var success = await campignManagementServices.deleteCampaign(id, userId);
    if (success.status && success.status === "1") {
      dispatch(deleteCampaignSuccess(success.data));
    } else {
      dispatch(deleteCampaignFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(deleteCampaignFailure(error.message));
  }
};

const deleteCampaignRequest = () => {
  return {
    type: actionTypes.DELETE_CAMPAIGN_REQUEST
  };
};

const deleteCampaignSuccess = data => {
  return {
    type: actionTypes.DELETE_CAMPAIGN_SUCCESS,
    payload: data
  };
};

const deleteCampaignFailure = error => {
  return {
    type: actionTypes.DELETE_CAMPAIGN_FAILURE,
    payload: error
  };
};

export const channelList = () => async dispatch => {
  dispatch(ChannelListRequest());
  try {
    var success = await campignManagementServices.getChannelList();
    if (success.status && success.status === "1") {
      dispatch(ChannelListSuccess(success.data));
    } else {
      dispatch(ChannelListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(ChannelListFailure(error.message));
  }
};

const ChannelListRequest = () => {
  return {
    type: actionTypes.CHANNEL_LIST_REQUEST
  };
};

const ChannelListSuccess = data => {
  return {
    type: actionTypes.CHANNEL_LIST_SUCCESS,
    payload: data
  };
};

const ChannelListFailure = error => {
  return {
    type: actionTypes.CHANNEL_LIST_FAILURE,
    payload: error
  };
};

export const channelDistributionList = channelId => async dispatch => {
  dispatch(ChannelDistributionListRequest());
  try {
    var success = await campignManagementServices.getChannelDistributionList(
      channelId
    );
    if (success.status && success.status === "1") {
      dispatch(ChannelDistributionListSuccess(success.data));
    } else {
      dispatch(ChannelDistributionListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(ChannelDistributionListFailure(error.message));
  }
};

const ChannelDistributionListRequest = () => {
  return {
    type: actionTypes.CHANNEL_DIST_LIST_REQUEST
  };
};

const ChannelDistributionListSuccess = data => {
  return {
    type: actionTypes.CHANNEL_DIST_LIST_SUCCESS,
    payload: data
  };
};

const ChannelDistributionListFailure = error => {
  return {
    type: actionTypes.CHANNEL_DIST_LIST_FAILURE,
    payload: error
  };
};

export const publishCampaignAction = (id, userId) => async dispatch => {
  dispatch(publishCampaignRequest());
  try {
    var success = await campignManagementServices.publishCampaign(id, userId);
    if (success.status && success.status === "1") {
      dispatch(publishCampaignSuccess(success.data));
    } else {
      dispatch(publishCampaignFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(publishCampaignFailure(error.message));
  }
};

const publishCampaignRequest = () => {
  return {
    type: actionTypes.PUBLISH_CAMPAIGN_REQUEST
  };
};

const publishCampaignSuccess = data => {
  return {
    type: actionTypes.PUBLISH_CAMPAIGN_SUCCESS,
    payload: data
  };
};

const publishCampaignFailure = error => {
  return {
    type: actionTypes.PUBLISH_CAMPAIGN_FAILURE,
    payload: error
  };
};

export const getCampaignDataById = (id, partnerid) => async dispatch => {
  dispatch(CampaignDataByIdRequest());
  try {
    var success = await campignManagementServices.getCampaignById(
      id,
      partnerid
    );
    if (success.status && success.status === "1") {
      dispatch(CampaignDataByIdSuccess(success.data));
    } else {
      dispatch(CampaignDataByIdFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(CampaignDataByIdFailure(error.message));
  }
};

const CampaignDataByIdRequest = () => {
  return {
    type: actionTypes.CAMPAIGN_DATA_BY_ID_REQUEST
  };
};

const CampaignDataByIdSuccess = data => {
  return {
    type: actionTypes.CAMPAIGN_DATA_BY_ID_SUCCESS,
    payload: data
  };
};

const CampaignDataByIdFailure = error => {
  return {
    type: actionTypes.CAMPAIGN_DATA_BY_ID_FAILURE,
    payload: error
  };
};

export const getPartnersCampaign = (
  id,
  channel,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(PartnersCampaignRequest());
  try {
    var success = await campignManagementServices.getPartnersCampaignList(
      id,
      channel,
      page,
      search
    );
    if (success.status && success.status === "1") {
      dispatch(PartnersCampaignSuccess(success.data));
    } else {
      dispatch(PartnersCampaignFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(PartnersCampaignFailure(error.message));
  }
};

const PartnersCampaignRequest = () => {
  return {
    type: actionTypes.PARTNERS_CAMPAIGN_REQUEST
  };
};

const PartnersCampaignSuccess = data => {
  return {
    type: actionTypes.PARTNERS_CAMPAIGN_SUCCESS,
    payload: data
  };
};

const PartnersCampaignFailure = error => {
  return {
    type: actionTypes.PARTNERS_CAMPAIGN_FAILURE,
    payload: error
  };
};

export const getRetailersBrandList = (partnerid, role) => async dispatch => {
  dispatch(RetailersBrandListRequest());
  try {
    var success = await campignManagementServices.getRetailersBrandList(
      partnerid,
      role
    );
    if (success.status && success.status === "1") {
      dispatch(RetailersBrandListSuccess(success.data));
    } else {
      dispatch(RetailersBrandListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(RetailersBrandListFailure(error.message));
  }
};

const RetailersBrandListRequest = () => {
  return {
    type: actionTypes.GET_RETAILERS_BRAND_LIST_REQUEST
  };
};

const RetailersBrandListSuccess = data => {
  return {
    type: actionTypes.GET_RETAILERS_BRAND_LIST_SUCCESS,
    payload: data
  };
};

const RetailersBrandListFailure = error => {
  return {
    type: actionTypes.GET_RETAILERS_BRAND_LIST_FAILURE,
    payload: error
  };
};

export const getRetailersBrandAccessToPublishCampaign = partnerid => async dispatch => {
  dispatch(RetailersBrandAccessToPublishCampaignRequest());
  try {
    var success = await campignManagementServices.getRetailersBrandAccessToPublishCampaign(
      partnerid
    );
    if (success.status && success.status === "1") {
      dispatch(RetailersBrandAccessToPublishCampaignSuccess(success.data));
    } else {
      dispatch(
        RetailersBrandAccessToPublishCampaignFailure("Some Error Occured.")
      );
    }
  } catch (error) {
    dispatch(RetailersBrandAccessToPublishCampaignFailure(error.message));
  }
};

const RetailersBrandAccessToPublishCampaignRequest = () => {
  return {
    type: actionTypes.GET_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_REQUEST
  };
};

const RetailersBrandAccessToPublishCampaignSuccess = data => {
  return {
    type: actionTypes.GET_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_SUCCESS,
    payload: data
  };
};

const RetailersBrandAccessToPublishCampaignFailure = error => {
  return {
    type: actionTypes.GET_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_FAILURE,
    payload: error
  };
};

export const saveRetailersBrandAccessToPublishCampaign = (
  partnerid,
  data,
  userid
) => async dispatch => {
  dispatch(SaveRetailersBrandAccessToPublishCampaignRequest());
  try {
    var success = await campignManagementServices.SaveRetailersBrandAccessToPublishCampaign(
      partnerid,
      data,
      userid
    );
    if (success.status && success.status === "1") {
      dispatch(SaveRetailersBrandAccessToPublishCampaignSuccess(success.data));
    } else {
      dispatch(
        SaveRetailersBrandAccessToPublishCampaignFailure("Some Error Occured.")
      );
    }
  } catch (error) {
    dispatch(SaveRetailersBrandAccessToPublishCampaignFailure(error.message));
  }
};

const SaveRetailersBrandAccessToPublishCampaignRequest = () => {
  return {
    type: actionTypes.SAVE_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_REQUEST
  };
};

const SaveRetailersBrandAccessToPublishCampaignSuccess = data => {
  return {
    type: actionTypes.SAVE_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_SUCCESS,
    payload: data
  };
};

const SaveRetailersBrandAccessToPublishCampaignFailure = error => {
  return {
    type: actionTypes.SAVE_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_FAILURE,
    payload: error
  };
};
