export const validationTypes = {
  email: "email",
  number: "number",
  mobileNumber: "mobilenumber",
  required: "required",
  url: "url",
  name: "name",
  description: "description",
  charges: "charges"
};

export const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

export const verifyName = value => {
  if (isNaN(value)) {
    return true;
  }
  return false;
};

export const verifyDescriptions = value => {
  if (isNaN(value)) {
    return true;
  }
  return false;
};

export const verifyCharges = value => {
  if (isNaN(value)) {
    return false;
  }
  return true;
};

export const verifyURL = value => {

  if (value.startsWith("localhost")) {
    return true;
  }

  var urlRex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
  if (urlRex.test(value)) {
    return true;
  }
  return false;
};

export const verifyPhoneNumber = value => {
  var numberRex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};

export const verifyNumber = value => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};

export const validateData = (type, value) => {
  switch (type) {
    case validationTypes.email:
      return verifyEmail(value);
    case validationTypes.number:
      return verifyNumber(value);
    case validationTypes.mobileNumber:
      return verifyPhoneNumber(value);
    case validationTypes.url:
      return verifyURL(value);
    case validationTypes.name:
      return verifyName(value);
    case validationTypes.description:
      return verifyDescriptions(value);
    case validationTypes.charges:
      return verifyCharges(value);
    default:
      return true;
  }
};
