import { withRouter } from "react-router-dom";
import React from "react";
import AuthLayout from "./Auth.jsx";
import AdminLayout from "./Admin.jsx";
import withAuthentication from "../views/Session/withAuthentication";
class Layout extends React.Component {
  render() {
    return (
      (window.location.href.indexOf("login") > -1) || (window.location.href.indexOf("changepasswordpage") > -1) || (window.location.href.indexOf("validate") > -1) || (window.location.href.indexOf("landing") > -1) ? <AuthLayout /> : <AdminLayout />
    );
  }
}

export default withRouter(withAuthentication(Layout));
