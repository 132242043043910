import React, { Component } from "react";
import GetAppIcon from '@material-ui/icons/GetApp';

const Style = {
    icon: {
        color: "#615f5f",
        fontSize: "20px",
        cursor: "pointer"
    }
};

class ImportAgGridAction extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    invokeParentMethod() {
        const { data } = this.props;

        return (
            <div
                style={{
                    "line-height": "60px"
                }}
            >
                <span
                    onClick={() => this.handleDownLoad(data.id)}
                    title="Edit Details"
                    style={{ "padding-right": "4px" }}
                >
                    <GetAppIcon style={Style.icon} />
                </span>
            </div>
        );
    }

    handleDownLoad(id) {
        this.props.context.componentParent.props.context.componentParent.handleDownload(id);
    }

    render() {
        return (
            <div>
                <span>{this.invokeParentMethod()}</span>
            </div>
        );
    }

}
export default ImportAgGridAction;
