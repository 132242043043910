import * as offersService from "../../services/offersService";
import * as actionTypes from "./actionTypes";

export const createOffers = (id, data, files) => async dispatch => {
  dispatch(createOffersRequest());
  try {
    const success = await offersService.createOffers(id, data, files);
    if (success.status === "1") {
      dispatch(createOffersSuccess(success.data));
    } else {
      dispatch(createOffersFailure(success.error));
    }
  } catch (error) {
    dispatch(createOffersFailure(error.message));
  }
};

export const getOffersList = (
  partnerid,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getOffersRequest());
  try {
    var success = await offersService.getOffersList(partnerid, page, search);
    if (success.status && success.status === "1") {
      dispatch(getOffersSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getOffersFailure(success.error));
    } else {
      dispatch(getOffersFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getOffersFailure(error.message));
  }
};

export const getOfferTypeList = () => async dispatch => {
  dispatch(getOfferTypesRequest());
  try {
    var success = await offersService.getOfferType();
    if (success.status && success.status === "1") {
      dispatch(getOfferTypesSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getOfferTypesFailure(success.error));
    } else {
      dispatch(getOfferTypesFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getOfferTypesFailure(error.message));
  }
};

export const mapProductWithOffer = (
  user_id,
  offer_id,
  product_ids
) => async dispatch => {
  dispatch(mapOfferProductsRequest());
  try {
    var success = await offersService.mapOfferProducts(
      user_id,
      offer_id,
      product_ids
    );
    if (success.status && success.status === "1") {
      dispatch(mapOfferProductsSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(mapOfferProductsFailure(success.error));
    } else {
      dispatch(mapOfferProductsFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(mapOfferProductsFailure(error.message));
  }
};

const mapOfferProductsRequest = () => {
  return {
    type: actionTypes.MAP_OFFER_PRODUCT_REQUEST
  };
};

const mapOfferProductsSuccess = data => {
  return {
    type: actionTypes.MAP_OFFER_PRODUCT_SUCCESS,
    payload: data
  };
};

const mapOfferProductsFailure = error => {
  return {
    type: actionTypes.MAP_OFFER_PRODUCT_FAILURE,
    payload: error
  };
};

const createOffersRequest = () => {
  return {
    type: actionTypes.CREATE_OFFERS_REQUEST
  };
};

const createOffersSuccess = data => {
  return {
    type: actionTypes.CREATE_OFFERS_SUCCESS,
    payload: data
  };
};

const createOffersFailure = error => {
  return {
    type: actionTypes.CREATE_OFFERS_FAILURE,
    payload: error
  };
};

const getOffersRequest = () => {
  return {
    type: actionTypes.GET_OFFERS_REQUEST
  };
};

const getOffersSuccess = data => {
  return {
    type: actionTypes.GET_OFFERS_SUCCESS,
    payload: data
  };
};

const getOffersFailure = error => {
  return {
    type: actionTypes.GET_OFFERS_FAILURE,
    payload: error
  };
};

const getOfferTypesRequest = () => {
  return {
    type: actionTypes.GET_OFFERS_TYPE_REQUEST
  };
};

const getOfferTypesSuccess = data => {
  return {
    type: actionTypes.GET_OFFERS_TYPE_SUCCESS,
    payload: data
  };
};

const getOfferTypesFailure = error => {
  return {
    type: actionTypes.GET_OFFERS_TYPE_FAILURE,
    payload: error
  };
};

export const getOfferByIdAction = offer_id => async dispatch => {
  dispatch(getOfferByIdRequest());
  try {
    var success = await offersService.getOfferById(offer_id);
    if (success.status && success.status === "1") {
      dispatch(getOfferByIdSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getOfferByIdFailure(success.error));
    } else {
      dispatch(getOfferByIdFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getOfferByIdFailure(error.message));
  }
};

const getOfferByIdRequest = () => {
  return {
    type: actionTypes.GET_OFFER_BYID_REQUEST
  };
};

const getOfferByIdSuccess = data => {
  return {
    type: actionTypes.GET_OFFER_BYID_SUCCESS,
    payload: data
  };
};

const getOfferByIdFailure = error => {
  return {
    type: actionTypes.GET_OFFER_BYID_FAILURE,
    payload: error
  };
};
