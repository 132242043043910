import React, { Component } from "react";
import _ from "lodash";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import WbAutoIcon from "@material-ui/icons/WbAuto";

class PartnerPartnersMappingAction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.grid = this.props.context.componentParent.props.context.partnerAction;
  }

  chooseTitle(role) {
    let obj = { title1: "No title", title2: "No title" };
    switch (role) {
      case "PR":
        obj.title1 = "Assign Product";
        obj.title2 = "Assign Product";
        break;
      case "BR":
        obj.title1 = "Assign Brand and its Product";
        obj.title2 = "Assign Brand";
        break;
      case "WS":
        obj.title1 = "Assign Wholesaler, Brand and its Product";
        obj.title2 = "Assign Wholesaler";
        break;
      case "CAT":
        obj.title1 = "Assign Category Products";
        obj.title2 = "Assign Category Product";
        break;
    }
    return obj;
  }

  invokeParentMethod() {
    let mapped_list = this.grid.props.MappedDataList;
    const { data } = this.props;
    let index = -1;
    if (mapped_list) {
      index = mapped_list.findIndex(
        x => x.id == data.id && x.role == data.role
      );
    }

    let titleObj = this.chooseTitle(data.role);
    let title = titleObj.title1;
    let othertitle = titleObj.title2;
    return (
      <div
        style={{
          lineHeight: "60px"
        }}
      >
        {data.role !== undefined ? (
          <span span onClick={() => this.performAction(data, 1)} title={title}>
            <LinkIcon className="grid_action_btn" />
          </span>
        ) : (
          ""
        )}
        {data.role == "BR" && index == -1 ? (
          <span onClick={() => this.performAction(data, 2)} title={othertitle}>
            <FormatBoldIcon className="grid_action_btn" />
          </span>
        ) : (
          ""
        )}
        {data.role == "WS" && index == -1 ? (
          <span onClick={() => this.performAction(data, 2)} title={othertitle}>
            <WbAutoIcon className="grid_action_btn" />
            {/* <span className="grid_action_btn">W</span> */}
          </span>
        ) : (
          ""
        )}
        {data.role == "PR" && index == -1 ? (
          <span onClick={() => this.performAction(data, 2)} title={othertitle}>
            <LocalParkingIcon className="grid_action_btn" />
          </span>
        ) : (
          ""
        )}

        <span
          onClick={() => this.removeMapping(data, 3)}
          title="Remove Assignment"
        >
          <LinkOffIcon className="grid_action_btn" />
          {/* <span className="grid_action_btn">W</span> */}
        </span>
      </div>
    );
  }

  performAction(data, option) {
    let parameter = _.clone(data);
    if (option == 2) {
      delete parameter.children;
    }
    this.grid.mapActionBtnFunction(parameter);
  }

  removeMapping(data, option) {
    let parameter = _.clone(data);
    this.grid.removeMapping(parameter);
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default PartnerPartnersMappingAction;
