import React from 'react';
import { compose } from 'recompose';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox, FormLabel, CircularProgress } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { ActionCreators } from "../../../redux/actions";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const INITIAL_STATE = {
    id: '',
    name: '',
    description: '',
    color: '',
    is_active: false
}

class CreateEventType extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addEventType: {
                ...INITIAL_STATE
            },
        }
    }

    componentDidMount() {
        if (this.props.editId) {
            this.props.actions.getEventTypeAction(this.props.editId)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.eventypes) {
            this.setState({
                addEventType: nextProps.eventypes
            })
        }
    }

    handleChange = (key) => event => {
        let val = ''
        if (event.type === 'click') {
            val = event.target.checked
        } else {
            val = event.target.value
        }
        this.setState({
            ...this.state,
            addEventType: {
                ...this.state.addEventType,
                [key]: val
            }
        })
    }

    saveEventType = () => {
        this.props.save(this.state.addEventType)
    }

    closeCreateModal = () => {
        this.props.close()
    }

    render() {
        const { classes } = this.props;
        const { name, description, color, is_active } = this.state.addEventType;
        return (
            <form>
                <LoadingOverlay>
                    <CustomInput
                        labelText="Name"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            type: "text",
                            value: name,
                            onChange: this.handleChange("name")
                        }}
                    />
                    <CustomInput
                        labelText="Description"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            type: "text",
                            value: description,
                            onChange: this.handleChange("description")
                        }}
                    />
                    <CustomInput
                        labelText="Color Code"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            type: "text",
                            value: color,
                            onChange: this.handleChange("color")
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                tabIndex={-1}
                                checked={is_active}
                                onClick={this.handleChange('is_active')}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                }}

                            />
                        }
                        classes={{ label: classes.label }}
                        label="Active"
                    />
                    {this.props.ListError && this.props.ListError.trim() !== "" && (
                        <div>
                            <FormLabel className={classes.labelLeftHorizontal}>
                                <code>{this.state.error}</code>
                            </FormLabel>
                        </div>
                    )}

                    <br />
                    <Button color="info" onClick={this.saveEventType}>
                        {this.state.isEdit ? "Update Changes" : "Save Changes"}
                    </Button>
                    <Button onClick={this.closeCreateModal}>Cancel</Button>
                    {this.props.isSaving && (
                        <CircularProgress
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-25px",
                                marginLeft: "-12px"
                            }}
                        />
                    )}
                    <Loader loading={this.props.isSaving} text="Saving.." />
                </LoadingOverlay>
            </form>
        );
    }
}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
    UserId: state.authState.user.pk,
    User: state.authState.user,
    eventypes: state.eventState.event_type.data,
    loading: state.eventState.event_type.loading,
    error: state.eventState.event_type.error,
});


export default compose(
    withStyles({
        ...extendedTablesStyle,
        ...customInputStyle,
        ...regularFormsStyle,
        ...validationFormsStyle,
    }),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(CreateEventType);