import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormLabel from "@material-ui/core/FormLabel";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import basicsStyle from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import ProductTemplateWizard from "./ProductTemplateWizard.jsx";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import ProductTemplateAction from "../../components/AgGrid/ProductTemplateAction";
import axios from "../../utils/axios";
import fileDownload from "js-file-download";
import config from "../../constants/config";
import {
  Link
} from "react-router-dom";

const productInitialState = {
  id: "",
  name: "",
  description: "",
  disclaimer: "",
  template: {},
  is_consumable: false,
  is_template: false,
  images: [],
  attributes: [],
  segments: [],
  categories: [],
  classes: [],
  offers: [],
  brand: {},
  mrp: 0,
  msrp: 0,
  discount: "",
  editorState: "",
  variants: [],
  variantValues: [],
  loading: false
};

class ProductTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false
      },
      publishItemId: -1,
      isPublish: false,
      loading: false,
      addProductTemplate: {
        ...productInitialState
      },
      isAddNew: false,
      isEdit: false,
      isClone: false,
      productTemplates: [],
      ProductTypeData: [],
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 800,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        // {
        //     headerName: "Description",
        //     field: "description",
        //     width: 450,
        //     resizable: true,
        //     sortable: true,
        //     filter: false
        // },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ]
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.handleCloneProduct = this.handleCloneProduct.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleProductExport = this.handleProductExport.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentDidMount() {
    this.props.actions.getAllProductTemplates(
      this.props.PartnerId,
      this.state.pagination.page
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.productTemplates &&
        !_.isEqual(this.props.productTemplates, nextProps.productTemplates)
      ) {
        this.setData(nextProps.productTemplates, nextProps.pagination);
      }
      if (nextProps.producttemplatebyid) {
        this.setState({
          addProductTemplate: nextProps.producttemplatebyid
        });
      }

      let loading = false;
      if (nextProps.Loading) {
        loading = nextProps.Loading;
      }

      if (nextProps.productTemplatesLoading) {
        this.state.loading = nextProps.productTemplatesLoading;
      }

      this.setState({
        loading: loading
      });
      if (
        nextProps.ProductTemplateById &&
        !_.isEqual(
          this.props.ProductTemplateById,
          nextProps.ProductTemplateById
        )
      ) {
        let product = nextProps.ProductTemplateById;
        let data = {};
        data["id"] = product.id;
        data["name"] = product.name;
        data["description"] = product.description;
        data["mrp"] = product.msrp ? product.msrp : 0;
        data["brand"] = product.brand;
        if (product.is_consumable == 1) {
          product.is_consumable = true;
        } else {
          product.is_consumable = false;
        }
        data["is_consumable"] = product.is_consumable;
        let images = [];
        let imageObj = {
          id: "",
          image: "",
          imagePreviewUrl: ""
        };

        for (let i in product.images) {
          let new_image = {
            ...imageObj
          };
          new_image.id = product.images[i].id;
          new_image.image = product.images[i].url;
          new_image.imagePreviewUrl = product.images[i].url;
          images.push(new_image);
        }
        images.push(imageObj);
        data["images"] = images;
        data["partner_id"] = nextProps.partnerId;
        data["offers"] = product.offers;
        data["categories"] = product.categories;
        data["classes"] = product.classes;
        data["attributes"] = product.attributes;
        data["is_template"] = product.is_template;
        data["template"] = product.template;
        data["variants"] = product.variants;
        data["variantValues"] = product.variantValues;

        data["producttype"] = product.producttype ? product.producttype.id : "";
        data["attribute_groups"] = product.attribute_groups;
        data["segments"] = product.segments;
        this.setState({
          isAddNew: true,
          isEdit: true,
          addProductTemplate: data
        });
      }

      if (nextProps.isProductPublished) {
        this.closeDialog();
      }
    }
  }

  setData(data, pagination) {

    this.setState(() => ({
      productTemplates: data,
      pagination: {
        ...pagination
      }
    }));
  }

  render() {
    return this.state.isAddNew ? (
      <ProductTemplateWizard
        data={this.state.addProductTemplate}
        isEdit={this.state.isEdit}
        isClone={this.state.isClone}
        close={this.handleCloseNew}
      />
    ) : (
      this.renderProductsTable()
    );
  }

  renderProductsTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          {this.renderPublishConfirmation()}
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Products
                </p>
                {/* <p>
                                    <Button
                                        disabled={this.state.loading}
                                        color="success"
                                        onClick={this.handelIsAddNew}
                                        className={classes.marginRight + " grid-header-button"}>
                                        <AddIcon className="grid-header-button-icon" />New
                                    </Button>

                                </p> */}
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.productTemplates}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                handleCloneProduct={this.handleCloneProduct}
                handleExport={this.props.User.role === "AD" ? this.handleExport : null}
                handelPublish={this.handelPublish}
                handleProductExport={this.props.User.role === 'BR' ? this.handleProductExport : null}
                loading={this.state.loading}
                AgGridActionButton={ProductTemplateAction}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                search={this.searchText}
                new={this.handelIsAddNew}
                isAddBtn={true}
                isProductExportBtn={this.props.User.role === 'BR' ? true : false}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getAllProductTemplates(
      this.props.PartnerId,
      page,
      this.state.searchtext
    );
    // this.props.actions.getPartners(page)
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getAllProductTemplates(
          this.props.PartnerId,
          page,
          param
        );
      }
    );
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew
    });
  };

  handelEdit = templateid => {
    this.props.actions.getProductTemplateByIdAction(templateid);
  };

  handleCloneProduct = id => {
    this.setState({
      isClone: true
    })
    this.props.actions.getProductTemplateByIdAction(id);
  }

  handleExport = id => {
    console.log("AM I CALLED", id)

    let reqUrl = `${config.env.API_URL}api/product/export-master/?product_template_id=${id}`

    var win = window.open(reqUrl, '_blank');
    setTimeout(() => {
      // win.close();
    }, 500)
  }

  handleProductExport = () => {
    console.log("AM I CALLED", )

    let reqUrl = `${config.env.API_URL}api/product/export-product/?partner_id=${this.props.User.partner_id}&partner_type=${this.props.User.role}`

    var win = window.open(reqUrl, '_blank');
    setTimeout(() => {
      // win.close();
    }, 500)
  }

  handelPublish = templateid => {
    this.setState({
      publishItemId: templateid,
      isPublish: true
    });
  };

  renderPublishConfirmation() {
    const { classes } = this.props;
    const { isPublish } = this.state;
    return (
      <Dialog
        open={isPublish}
        disableBackdropClick={true}
        onClose={() => this.closeDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            <div>
              Are you sure you want to publish product changes to retailer's
              website?
            </div>
            {this.state.error && this.props.error.trim() !== "" && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code>{this.state.error}</code>
              </FormLabel>
            )}
          </DialogContentText>
          <DialogActions>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              color="primary"
              onClick={() => this.publishProductChanges()}
            >
              Yes
            </Button>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              onClick={() => this.closeDialog()}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  closeDialog() {
    this.setState({
      isPublish: false,
      publishItemId: -1
    });
  }

  publishProductChanges() {
    this.props.actions.publishProductAction(
      this.state.publishItemId,
      this.props.PartnerId,
      this.props.UserId
    );
  }

  handleCloseNew = () => {
    this.setState(
      {
        addProductTemplate: {
          ...productInitialState,
          images: [],
          categories: [],
          classes: [],
          segments: [],
          partner_id: this.props.partner_id
        },
        isAddNew: false,
        isEdit: false,
        isClone: false
      },
      () => {
        this.props.actions.getAllProductTemplates(
          this.props.PartnerId,
          this.state.pagination.page
        );
      }
    );
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  User: state.authState.user,
  PartnerId: state.authState.user.partner_id,
  productTemplates: state.brandState.productTemplate.products,
  pagination: state.brandState.productTemplate.pagination,
  productTemplatesLoading: state.brandState.productTemplate.isLoading,
  Loading: state.brandState.productTemplate.isLoading,
  UserId: state.authState.user.pk,
  ProductTemplateById: state.brandState.productTemplateById.data,
  publishError: state.brandState.publish_product.error,
  publishLoading: state.brandState.publish_product.loading,
  isProductPublished: state.brandState.publish_product.isPublished
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.ADMIN || rolesNames.RoleMaster.BRAND;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...dashboardStyle,
    ...userProfileStyles,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductTemplate);
