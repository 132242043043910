import axios from "../utils/axios.jsx";

export const savePartners = (userid, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post("/api/partner/import/", {
          userid: userid,
          records: data
        })
        .then(function (response) {
          console.log(response);
          if (response.data) {
            console.log(response);
            resolve(response.data);
          } else {
            reject("An error with post method");
          }
        });
    } catch (error) {
      reject(error);
    }
  });
};



export const getImportList = (type, partnerid) => {
  return new Promise((resolve, reject) => {
    try {
      let fetchUrl = "/mgmt/import/get/?1=1";
      fetchUrl = fetchUrl + "&partnerid=" + partnerid;

      if (type) {
        fetchUrl = fetchUrl + "&type=" + type;
      }

      axios.get(fetchUrl).then(function (response) {
        if (response.data) {
          resolve(response.data);
        } else {
          reject("An error has occured with import");
          console.log("An error with get method", response);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const uploadImportDocument = (type, file, partnerid) => {
  return new Promise((resolve, reject) => {
    try {

      const formData = new FormData();
      formData.append('type', type)
      formData.append('partnerid', partnerid)

      if (file) {
        file.forEach(e => {
          formData.append(e.name, e);
        });
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }

      axios
        .post("/mgmt/import/create/", formData, config)
        .then(function (response) {
          console.log(response);
          if (response.data) {
            resolve(response.data);
          } else {
            reject("An error has occured with import");
            console.log("An error with post method", response);
          }
        });
    } catch (error) {
      reject(error);
    }
  });
};
