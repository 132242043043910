import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "../../components/CustomInput/CustomInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import _ from "lodash";
import FormLabel from "@material-ui/core/FormLabel";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

class CreateProductComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      editBrandProductData: {
        sort_order: "",
        description: "",
        product_template: "",
        id: null,
        name: "",
        is_used: false,
        msrp: 0,
        disclaimer: ""
      },
      isselectedtmp: null,
      required: {
        sort_order: "",
        description: "",
        name: "",
        msrp: 0,
        disclaimer: ""
      }
    };
  }

  componentDidMount() {
    if (this.props.selectedTemp) {
      let data = this.state.editBrandProductData;
      data.product_template = this.props.selectedTemp;
      this.setState({
        editBrandProductData: data
      });
    }
    this.props.actions.getAllMapRetailerCategorisedProductTemplatesSelected(
      this.props.partner_id
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.product_template) {
      const brandProductTemplates = nextProps.product_template.products;

      const productsList = [];

      for (let i = 0; i < brandProductTemplates.length; i++) {
        brandProductTemplates.findIndex(
          p => p.ptid === brandProductTemplates[i].ptid
        );
        if (
          productsList.findIndex(
            p => p.ptid === brandProductTemplates[i].ptid
          ) > -1
        ) {
        } else {
          productsList.push(brandProductTemplates[i]);
        }
      }
      this.setState(
        {
          products: productsList
        },
        () => {
          console.log(brandProductTemplates);
        }
      );
    }
    this.setState({
      editorState: "",
      editBrandProductData: {
        disclaimer: "",
        sort_order: "",
        description: "",
        product_template: "",
        id: null,
        name: "",
        is_used: false,
        msrp: 0,
        product_template: nextProps.selectedTemp
      }
    });
  }

  handleTextChange = (key, type) => event => {
    var validData = "success";
    var reqData = this.state.required;
    reqData[key] = validData;
    var editdata = this.state.editBrandProductData;
    editdata[key] = event.target.value;
    this.props.formData(editdata);
    this.setState({
      editBrandProductData: editdata,
      required: reqData
    });
  };

  handleToggleStatus = curObj => event => {
    this.setState({
      editBrandProductData: {
        ...this.state.editBrandProductData,
        is_used: event.target.checked
      }
    });
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
    // var editdata = this.state.editBrandProductData;
    // editdata.disclaimer = event.target.value;
    // this.props.formData(editdata)
    this.setState(
      {
        editBrandProductData: {
          ...this.state.editBrandProductData,
          disclaimer: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }
      },
      () => {
        this.props.formData(this.state.editBrandProductData);
      }
    );
  };

  render() {
    const { classes } = this.props;
    const {
      isAddNew,
      required,
      isEdit,
      isselectedtmp,
      editorState
    } = this.state;
    const {
      sort_order,
      product_template,
      description,
      name,
      msrp,
      is_used
    } = this.state.editBrandProductData;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <p className={classes.cardIconTitle}>
              Make edits to the fields below, then save changes.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <form>
              <LoadingOverlay>
                <FormControl fullWidth style={customInputStyle.formControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.labelRoot + " "}
                    labelText="Product *"
                  >
                    Product *
                  </InputLabel>

                  <Select
                    success={required.segmentName === "success"}
                    error={required.segmentName === "error"}
                    value={product_template}
                    name="product_template"
                    onChange={this.handleTextChange("product_template", "")}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choose Product
                    </MenuItem>
                    {_.map(this.state.products, ({ ptid, ptname }, index) => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={ptid}
                          key={index + "_" + ptid}
                        >
                          {ptname}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <br />
                <CustomInput
                  success={required.name === "success"}
                  error={required.name === "error"}
                  labelText="Product name *"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: name,
                    onChange: this.handleTextChange("name", "")
                  }}
                  id={"name"}
                />
                <br />
                <CustomInput
                  success={required.sort_order === "success"}
                  error={required.sort_order === "error"}
                  labelText="Sort Order *"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: sort_order,
                    onChange: this.handleTextChange("sort_order", "")
                  }}
                  id={"sort_order"}
                />
                <br />
                <CustomInput
                  success={required.description === "success"}
                  error={required.description === "error"}
                  labelText="Description *"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: description,
                    onChange: this.handleTextChange("description", "")
                  }}
                  id={"Description"}
                />
                <br />

                <CustomInput
                  success={required.description === "success"}
                  error={required.description === "error"}
                  labelText="MSRP *"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value: msrp,
                    onChange: this.handleTextChange("msrp", "")
                  }}
                  id={"msrp"}
                />
                <br />

                <FormControl fullWidth style={customInputStyle.formControl}>
                  <InputLabel className={classes.label + " "} labelText="Notes">
                    Disclaimer
                  </InputLabel>
                  <br />
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapper"
                    editorClassName="editor"
                    onEditorStateChange={this.onEditorStateChange}
                  />
                </FormControl>
                <br />

                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={is_used}
                      onClick={this.handleToggleStatus(is_used)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{ label: classes.label }}
                  label="Used"
                />
                {/* {
                                    // isAddNew
                                    // &&
                                    <Button color="info" onClick={this.saveProducts}>{isEdit ? "Update Changes" : "Save Changes"}</Button>
                                }
                                {
                                    // isAddNew
                                    // &&
                                    <Button onClick={this.handelIsAddNew}> Cancel </Button>
                                } */}

                <br />
                {this.state.error && this.state.error.trim() !== "" && (
                  <FormLabel className={classes.labelLeftHorizontal}>
                    <code>{this.state.error}</code>
                  </FormLabel>
                )}
                <br />

                {(this.props.Loading || this.props.loadingGetData) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Saving.." />
                <Loader loading={this.props.loadingGetData} text="Loading.." />
              </LoadingOverlay>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.brandState.universalAttributes.attributes,
  partner_id: state.authState.user.partner_id,
  product_template: state.brandState.map_Retailer_brand_producttemplate_Selected
});

CreateProductComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  productTemp: PropTypes.object,
  selectedTemp: PropTypes.array.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateProductComponent);

// export default classComponent;
