import axios from "../utils/axios.jsx";

export const getStockLocationData = async (
  partnerid,
  optionalParam,
  page,
  search
) => {
  try {
    let url =
      "/api/retailer/stock-location/get/?partnerid=" +
      partnerid +
      "&optparam=" +
      optionalParam +
      "&page=" +
      page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStockLocationById = async id => {
  try {
    const response = await axios.get(
      "/api/retailer/stock-location/get-by-id/?id=" + id
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveStockLocation = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post(
      "/api/retailer/stock-location/save/",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteStockLocation = async id => {
  try {
    const response = await axios.get(
      "/api/retailer/stock-location/delete/?id=" + id
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStockData = async (partnerid, page, search) => {
  try {
    let url =
      "/api/retailer/stock/get/?partnerid=" + partnerid + "&page=" + page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStockById = async id => {
  try {
    const response = await axios.get("/api/retailer/stock/get-by-id/?id=" + id);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveStockData = async (Savedata, userId) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("/api/retailer/stock/save/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductsNetStock = async (partnerid, page, search) => {
  try {
    let url =
      "/api/retailer/products/net-stock/get/?partnerid=" +
      partnerid +
      "&page=" +
      page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductStockMoveData = async (productid, page) => {
  try {
    const response = await axios.get(
      "/api/retailer/products/stock-move/get/?productid=" +
        productid +
        "&page=" +
        page
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
