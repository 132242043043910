import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { LoadingOverlay, Loader } from "react-overlay-loader";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
// import { AllModules } from "@ag-grid-enterprise/all-modules";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { AgGridReact } from "ag-grid-react";
import "../../style/app.css";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import CreateFolderComponent from "./component/CreateFolderComponent";
import UploadFileComponent from "./component/UploadFileComponent";
import ActionButtonComponent from "./component/ActionButtonComponent";
import CustomPaginationPanel from "components/AgGrid/CustomPaginationPanel";

const intialFolderState = {
  id: "",
  folder_name: "",
  parent_folder_id: "",
  folder_quota: "",
  tag: "",
  type: 0, //0 for public and 1 private
  is_deleted: 0
};

const intialFileState = {
  id: "",
  file_name: "",
  original_file_name: "",
  document_object: "",
  folder_id: "",
  file_size: "",
  type: 0, //0 for public and 1 private
  is_deleted: 0
};
let _agGridAPI = null;

class AssetManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false
      },
      selectedTab: 0,
      clickedFolderID: 0,
      isAddNewFolder: false,
      isAddNewFile: false,
      addFile: {
        ...intialFileState
      },
      addFolder: {
        ...intialFolderState
      },
      isEdit: false,
      list: [],
      isPreview: false,
      previewImageData: {},
      // modules: AllModules,
      columnDefs: [
        {
          headerName: "Folder",
          field: "folder_name",
          width: 200,
          resizable: true,
          sortable: true,
          cellRenderer: "group"
        },
        {
          headerName: "Files",
          field: "file_name",
          width: 200,
          resizable: true,
          sortable: true
          // rowGroup: true,
          // hide: true
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "actionColumns",
          colId: "params",
          width: 180
        }
      ],
      defaultColDef: {
        width: 240,
        resizable: true
      },
      context: { assetManagerGrid: this },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      frameworkComponents: {
        actionColumns: ActionButtonComponent
      },
      autoGroupColumnDefViewMode: {
        headerName: "Folder/file",
        field: "",
        rowDrag: false,
        cellRendererParams: { checkbox: false },
        headerCheckboxSelection: false,
        width: 350,
        suppressSizeToFit: true
      },
      getNodeChildDetails: function(data) {
        if (
          (data && data.sub_folder && data.sub_folder.length > 0) ||
          (data && data.file && data.file.length > 0)
        ) {
          let children = [];

          if (data.sub_folder.length > 0 && data.file.length > 0) {
            children = _.union(data.sub_folder, data.file);
          } else if (data.sub_folder.length > 0) {
            children = data.sub_folder;
          } else if (data.file.length > 0) {
            children = data.file;
          }
          return {
            group: true,
            children: children,
            expanded: data.open
          };
        } else {
          return null;
        }
      },
      domLayout: "autoHeight",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
    this.updatedPageData = this.updatedPageData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props.List, nextProps.List)) {
        this.setState({
          list: nextProps && nextProps.List,
          pagination: nextProps.pagination
        });
      }
      if (nextProps.isDeleted) {
        let type = null;
        if (this.state.selectedTab == 1) {
          type = 0;
        }
        this.props.actions.getAssetListAction(
          this.props.User.user,
          this.props.User.folderId,
          type,
          this.state.pagination.page
        );
      }
      if (nextProps.File.isSaved || nextProps.Folder.isSaved) {
        this.setState(
          {
            isAddNewFolder: false,
            addFolder: {
              ...intialFileState
            },
            isAddNewFile: false,
            addFile: {
              ...intialFileState
            }
          },
          () => {
            let type = null;
            if (this.state.selectedTab == 1) {
              type = 0;
            }
            this.props.actions.getAssetListAction(
              this.props.User.user,
              this.props.User.folderId,
              type,
              this.state.pagination.page
            );
          }
        );
      }
    }
  }

  componentDidMount() {
    let folderId = "";
    let type = null;
    if (this.state.selectedTab == 1) {
      type = 0;
    }
    this.props.actions.getAssetListAction(
      this.props.User.user,
      this.props.User.folderId,
      type,
      this.state.pagination.page
    );
  }

  render() {
    const { isAddNewFolder, isAddNewFile } = this.state;
    return (
      <div>
        {isAddNewFolder ? (
          <CreateFolderComponent
            data={this.state.addFolder}
            close={this.closeCreateFolderDialog}
            save={this.saveFolderHandler}
          />
        ) : (
          [
            isAddNewFile ? (
              <UploadFileComponent
                data={this.state.addFile}
                close={this.closeCreateFileDialog}
                save={this.uploadFileHandler}
              />
            ) : (
              this.renderPromotionTable()
            )
          ]
        )}
      </div>
    );
  }

  renderPromotionTable() {
    const Style = {
      tabStyle: {
        cursor: "pointer",
        borderBottom: "1px solid #eae5e5",
        borderRight: "1px solid #eae5e5",
        textAlign: "center",
        padding: {
          value: "10px",
          important: "true"
        }
      }
    };
    const { classes } = this.props;
    const { selectedTab } = this.state;
    let TabList = [
      {
        tabName: "My Folders",
        tabContent: this.adsManagerRender()
      }
    ];
    if (this.props.User.role !== rolesNames.RoleMaster.ADMIN) {
      TabList.push({
        tabName: "Public Folders",
        tabContent: this.adsManagerRender()
      });
    }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader icon>
                <div>
                  <Button
                    disabled={this.props.Loading}
                    color="success"
                    onClick={() =>
                      this.handelIsAddNewFolder(this.props.User.folderId)
                    }
                    className={classes.marginRight + " grid-header-button"}
                    style={{
                      margin: "10px"
                    }}
                  >
                    <AddIcon className="grid-header-button-icon" /> Folder
                  </Button>
                  <Button
                    disabled={this.props.Loading}
                    color="success"
                    size="sm"
                    onClick={() =>
                      this.handelIsAddNewFile(this.props.User.folderId)
                    }
                    className={classes.marginRight + " grid-header-button"}
                    style={{
                      margin: "10px"
                    }}
                  >
                    <AddIcon className="grid-header-button-icon" /> File
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <div className="tab">
                  <GridContainer>
                    {_.map(TabList, (tab, index) => (
                      <GridItem
                        xs={2}
                        lg={6}
                        md={2}
                        onClick={() => this.changeTab(index)}
                        className={
                          "tab-style" +
                          (index === selectedTab ? " active-tab" : "")
                        }
                      >
                        <span
                          className={
                            "tab-header" +
                            (index === selectedTab ? " active-tab-header" : "")
                          }
                        >
                          {tab.tabName}
                        </span>
                      </GridItem>
                    ))}
                  </GridContainer>

                  {TabList[selectedTab].tabContent}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  changeTab = index => {
    this.setState(
      {
        selectedTab: index
      },
      () => {
        let type = null;
        if (this.state.selectedTab == 1) {
          type = 0;
        }
        this.props.actions.getAssetListAction(
          this.props.User.user,
          this.props.User.folderId,
          type,
          1
        );
      }
    );
  };

  handelIsAddNewFolder = id => {
    let folderID = "";
    if (id !== null) {
      folderID = id;
    }
    this.setState({
      isAddNewFolder: true,
      addFolder: {
        ...this.state.addFolder,
        parent_folder_id: folderID,
        partner_id: this.props.PartnerId
      }
    });
  };

  handelIsAddNewFile = id => {
    let folderID = "";
    if (id !== null) {
      folderID = id;
    }
    this.setState({
      isAddNewFile: true,
      addFile: {
        ...this.state.addFile,
        folder_id: folderID,
        partner_id: this.props.PartnerId
      }
    });
  };
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    _agGridAPI = _.clone(params.api, true);
    params.api.sizeColumnsToFit();
  };

  adsManagerRender = () => {
    const { list } = this.state;
    return (
      <GridContainer>
        {this.ImagePreviewDialog()}
        <GridItem xs={12} sm={12} md={12}>
          <LoadingOverlay>
            <div
              id="myGrid"
              style={{
                width: "100%"
              }}
              className="ag-theme-material"
            >
              <AgGridReact
                gAgGridReactridOptions={this.gridOptions}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.columnDefs}
                rowData={list}
                // treeData={true}
                // showToolPanel={true}
                frameworkComponents={this.state.frameworkComponents}
                floatingFilter={true}
                context={this.state.context}
                // statusBar={this.state.statusBar}
                groupSelectsChildren={true}
                // sideBar={this.state.sideBar}
                autoGroupColumnDef={this.state.autoGroupColumnDefViewMode}
                pagination={true}
                paginationPageSize={50}
                rowMultiSelectWithClick={true}
                icons={this.state.icons}
                rowSelection={this.state.rowSelection}
                onGridReady={this.onGridReady}
                domLayout={this.state.domLayout}
                rowSelection={"multiple"}
                // onCellClicked={this.onCellClicked.bind(this)}
                getNodeChildDetails={this.state.getNodeChildDetails}
                groupMultiAutoColumn={true}
                suppressRowClickSelection={true}
                enableCellChangeFlash={true}
                suppressPaginationPanel={true}
              />
            </div>
            <CustomPaginationPanel
              pagination={this.state.pagination}
              currentpage={this.state.pagination.page}
              totalpage={this.state.pagination.totalpage}
              nextpage={this.state.pagination.next}
              prevpage={this.state.pagination.prev}
              update={this.updatedPageData}
            />
            {this.props.Loading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Loading} text="Loading.." />
          </LoadingOverlay>
        </GridItem>
      </GridContainer>
    );
  };

  updatedPageData(page) {
    let type = null;
    if (this.state.selectedTab == 1) {
      type = 0;
    }
    this.props.actions.getAssetListAction(
      this.props.User.user,
      this.props.User.folderId,
      type,
      page
    );
  }

  closeCreateFolderDialog = () => {
    this.setState({
      isAddNewFolder: false
    });
  };

  saveFolderHandler = data => {
    this.props.actions.createFolderAction(this.props.User.pk, data);
  };

  uploadFileHandler = data => {
    this.props.actions.uploadFileAction(this.props.User.pk, data);
  };

  ImagePreviewDialog() {
    const { classes } = this.props;
    const { previewImageData } = this.state;
    const imageUrl =
      previewImageData &&
      previewImageData.document_object &&
      previewImageData.document_object.secure_url
        ? previewImageData.document_object.secure_url
        : "";
    return (
      <Dialog
        open={this.state.isPreview}
        disableBackdropClick={true}
        onClose={() => this.closeIndustriesComponentDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              <div
                style={{
                  width: "400px",
                  height: "300px"
                }}
              >
                <img
                  src={imageUrl}
                  style={{
                    width: "100%",
                    height: "100%"
                  }}
                />
              </div>
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.downloadImage(previewImageData)}
              >
                Download
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.closeImagePreview()}
                color="primary"
                autoFocus
              >
                CLose
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  }

  openImagePreview = data => {
    this.setState({
      isPreview: true,
      previewImageData: data
    });
  };

  closeCreateFileDialog = () => {
    this.setState({
      isAddNewFile: false
    });
  };

  closeImagePreview = () => {
    this.setState({
      isPreview: false
    });
  };

  downloadImage = data => {
    let url = data && data.document_object && data.document_object.secure_url;
    let fileName = data && data.file_name;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function() {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  };

  copyCodeToClipboard = data => {
    navigator.clipboard.writeText(data.document_object.secure_url);
  };

  deleteFileFolder = (id, type) => {
    this.props.actions.deleteAction(this.props.User.user, id, type);
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  User: state.authState.user,
  PartnerId: state.authState.user.partner_id,
  List: state.assetManagementReducer.list,
  Loading: state.assetManagementReducer.loading,
  ListError: state.assetManagementReducer.error,
  isSaved: state.assetManagementReducer.isSaved,
  File: state.assetManagementReducer.file,
  Folder: state.assetManagementReducer.folder,
  pagination: state.assetManagementReducer.pagination
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.ADMIN ||
  rolesNames.RoleMaster.BRAND ||
  rolesNames.RoleMaster.RETAILER ||
  rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AssetManager);
