export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";

export const GET_ORDER_DETAIL_REQUEST = "GET_ORDER_DETAIL_REQUEST";
export const GET_ORDER_DETAIL_FAILURE = "GET_ORDER_DETAIL_FAILURE";
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS";

export const CHANGE_ORDER_STATUS_REQUEST = "CHANGE_ORDER_STATUS_REQUEST";
export const CHANGE_ORDER_STATUS_FAILURE = "CHANGE_ORDER_STATUS_FAILURE";
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS";
