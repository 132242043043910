import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  list: null,
  detailslist: null,
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  paymentMethodCred: null
};

const paymentreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loadingData: true,
        error: null,
        isSaved: false,
        isSaving: true
      };
    case actionTypes.GET_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false,
        isSaving: false
      };
    case actionTypes.GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loadingData: false,
        error: null,
        list: action.payload,
        isSaved: true,
        isMapSaved: true,
        isSaving: false
      };
    case actionTypes.GET_PAYMENT_CRED_REQUEST:
      return {
        ...state,
        loadingData: true,
        error: null,
        isSaved: false,
        isSaving: true
      };
    case actionTypes.GET_PAYMENT_CRED_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false,
        isSaving: false
      };
    case actionTypes.GET_PAYMENT_CRED_SUCCESS:
      return {
        ...state,
        loadingData: false,
        error: null,
        detailslist: action.payload,
        isSaved: true,
        isMapSaved: true,
        isSaving: false
      };
    case actionTypes.CREATE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loadingData: true,
        error: null,
        isSaved: false,
        isSaving: true
      };
    case actionTypes.CREATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false,
        isSaving: false
      };
    case actionTypes.CREATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loadingData: false,
        error: null,
        paymentMethodCred: action.payload,
        isSaved: true,
        isMapSaved: true,
        isSaving: false
      };
    default:
      return state;
  }
};

export default paymentreducer;
