// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const createMenuMaster = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("api/menumaster/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMenuMasterList = async businesstypeid => {
  try {
    const response = await axios.get(
      "/api/menumaster/list/?businesstypeid=" + businesstypeid
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const copyMenu = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("api/menumaster/copymenu/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
