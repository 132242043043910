import * as businessTypeServices from "../../services/businessTypeService";
import * as actionTypes from "./actionTypes";

export const getBusinessType = (
  id,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getBusinessTypeRequest());
  try {
    const success = await businessTypeServices.getBusinessType(
      id,
      page,
      search
    );
    dispatch(getBusinessTypeSuccess(success.data));
  } catch (error) {
    dispatch(getBusinessTypeFailure(error.message));
  }
};
export const saveBusinessType = (id, data, partnerid) => async dispatch => {
  dispatch(saveBusinessTypeRequest());
  try {
    const success = await businessTypeServices.saveBusinessType(
      id,
      data,
      partnerid
    );

    if (success.status && success.status === "1") {
      dispatch(saveBusinessTypeSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(saveBusinessTypeFailure(success.error));
    } else {
      dispatch(saveBusinessTypeFailure("Some Error Occured.!!!"));
    }
  } catch (error) {
    dispatch(saveBusinessTypeFailure(error.message));
  }
};

export const deleteBusinessType = (id, BusinessType_id) => async dispatch => {
  dispatch(deleteBusinessTypeRequest());
  try {
    const success = await businessTypeServices.deleteBusinessType(
      id,
      BusinessType_id
    );
    if (success.status && success.status === "1") {
      dispatch(deleteBusinessTypeSuccess(success.message, BusinessType_id));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(deleteBusinessTypeFailure(success.error));
    } else {
      dispatch(deleteBusinessTypeFailure("Some Error Occured.!!!"));
    }
  } catch (error) {
    dispatch(deleteBusinessTypeFailure(error.message));
  }
};

export const mapBusinessIndustries = (id, mapData) => async dispatch => {
  dispatch(saveBusinessIndustriesRequest());
  try {
    const success = await businessTypeServices.mapBusinessIndustries(
      id,
      mapData
    );
    dispatch(saveBusinessTypeSuccess(success.data));
  } catch (error) {
    dispatch(saveBusinessIndustriesFailure(error.message));
  }
};

export const getIndustriesPartnerThemes = (
  ids,
  partnerType
) => async dispatch => {
  dispatch(getIndustriesPartnerThemesRequest());
  try {
    const success = await businessTypeServices.getIndustriesMetaData(
      ids,
      partnerType
    );
    dispatch(getIndustriesPartnerThemesSuccess(success.data));
  } catch (error) {
    dispatch(getIndustriesPartnerThemesFailure(error.message));
  }
};

const getIndustriesPartnerThemesRequest = () => {
  return {
    type: actionTypes.GET_INDUSTRIES_METADATA_REQUEST
  };
};

const getIndustriesPartnerThemesFailure = () => {
  return {
    type: actionTypes.GET_INDUSTRIES_METADATA_FAILURE
  };
};

const getIndustriesPartnerThemesSuccess = data => {
  return {
    type: actionTypes.GET_INDUSTRIES_METADATA_SUCCESS,
    payload: data
  };
};

export const getEconomicSectorsIndustries = id => async dispatch => {
  dispatch(getEconomicSectorsIndustriesRequest());
  try {
    const success = await businessTypeServices.getEconomicSectorsIndustries(id);
    dispatch(getEconomicSectorsIndustriesSuccess(success.data));
  } catch (error) {
    dispatch(getEconomicSectorsIndustriesFailure(error.message));
  }
};

const getEconomicSectorsIndustriesRequest = () => {
  return {
    type: actionTypes.GET_ECONOMIC_SECTORS_INDUSTRIES_REQUEST
  };
};

const getEconomicSectorsIndustriesFailure = () => {
  return {
    type: actionTypes.GET_ECONOMIC_SECTORS_INDUSTRIES_FAILURE
  };
};

const getEconomicSectorsIndustriesSuccess = data => {
  return {
    type: actionTypes.GET_ECONOMIC_SECTORS_INDUSTRIES_SUCCESS,
    payload: data
  };
};

export const afertMapBusinessIndustries = () => async dispatch => {
  dispatch(afertMapBusinessIndustriesRequest());
};

const afertMapBusinessIndustriesRequest = () => {
  return {
    type: actionTypes.AFTER_MAP_BUSINESS_INDUSTRIES_SUCCESS
  };
};

const saveBusinessIndustriesRequest = () => {
  return {
    type: actionTypes.MAP_BUSINESS_INDUSTRIES_REQUEST
  };
};

const saveBusinessIndustriesSuccess = data => {
  return {
    type: actionTypes.MAP_BUSINESS_INDUSTRIES_SUCCESS,
    payload: data
  };
};

const saveBusinessIndustriesFailure = error => {
  return {
    type: actionTypes.MAP_BUSINESS_INDUSTRIES_FAILURE,
    payload: error
  };
};

const saveBusinessTypeRequest = () => {
  return {
    type: actionTypes.SAVE_BUSINESS_TYPE_REQUEST
  };
};

const saveBusinessTypeSuccess = data => {
  return {
    type: actionTypes.SAVE_BUSINESS_TYPE_SUCCESS,
    payload: data
  };
};

const saveBusinessTypeFailure = error => {
  return {
    type: actionTypes.SAVE_BUSINESS_TYPE_FAILURE,
    payload: error
  };
};

const deleteBusinessTypeRequest = () => {
  return {
    type: actionTypes.DELETE_BUSINESS_TYPE_REQUEST
  };
};

const deleteBusinessTypeSuccess = (data, class_id) => {
  return {
    type: actionTypes.DELETE_BUSINESS_TYPE_SUCCESS,
    payload: data,
    class_id: class_id
  };
};

const deleteBusinessTypeFailure = error => {
  return {
    type: actionTypes.DELETE_BUSINESS_TYPE_FAILURE,
    payload: error
  };
};

const getBusinessTypeRequest = () => {
  return {
    type: actionTypes.GET_BUSINESS_TYPE_REQUEST
  };
};

const getBusinessTypeSuccess = data => {
  return {
    type: actionTypes.GET_BUSINESS_TYPE_SUCCESS,
    payload: data
  };
};

const getBusinessTypeFailure = error => {
  return {
    type: actionTypes.GET_BUSINESS_TYPE_FAILURE,
    payload: error
  };
};

export const getBusinessTypeByIdAction = id => async dispatch => {
  dispatch(getBusinessTypeByIdRequest());
  try {
    const success = await businessTypeServices.getBusinessTypeById(id);
    dispatch(getBusinessTypeByIdSuccess(success.data));
  } catch (error) {
    dispatch(getBusinessTypeByIdFailure(error.message));
  }
};

const getBusinessTypeByIdRequest = () => {
  return {
    type: actionTypes.GET_BUSINESS_TYPE_BYID_REQUEST
  };
};

const getBusinessTypeByIdSuccess = data => {
  return {
    type: actionTypes.GET_BUSINESS_TYPE_BYID_SUCCESS,
    payload: data
  };
};

const getBusinessTypeByIdFailure = error => {
  return {
    type: actionTypes.GET_BUSINESS_TYPE_BYID_FAILURE,
    payload: error
  };
};
