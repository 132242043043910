import React, { Component } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import GetAppIcon from "@material-ui/icons/GetApp";

const Style = {
  icon: {
    cursor: "pointer",
    padding: "2px"
  },
  input: {
    display: "none !important"
  },
  icon_size: {
    height: "20px",
    width: "20px"
  }
};

class ActionButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyUrl: ""
    };
  }
  invokeParentMethod() {
    const { data } = this.props;
    return (
      <div
        style={{
          "line-height": "60px"
        }}
      >
        {data.folder_name ? (
          <div>
            <span
              title="Upload File"
              onClick={() => this.performAction(data.id, "file")}
              style={Style.icon}
            >
              <InsertPhotoIcon style={Style.icon_size} />
            </span>
            <span
              title="Create Folder"
              onClick={() => this.performAction(data.id, "folder")}
              style={Style.icon}
            >
              <CreateNewFolderIcon style={Style.icon_size} />
            </span>
            <span
              title="Delete Folder"
              onClick={() => this.performAction(data.id, "delete", 1)}
              style={Style.icon}
            >
              <DeleteIcon style={Style.icon_size} />
            </span>
          </div>
        ) : (
          <div>
            <span
              title="Preview"
              onClick={() => this.performAction(data, "openPreview")}
              style={Style.icon}
            >
              <VisibilityIcon style={Style.icon_size} />
            </span>
            <span
              title="Copy to clipboard"
              onClick={() => this.performAction(data, "copy_path")}
              style={Style.icon}
            >
              <FileCopyIcon style={Style.icon_size} />
            </span>
            <span
              title="Download"
              onClick={() => this.performAction(data, "download")}
              style={Style.icon}
            >
              <GetAppIcon style={Style.icon_size} />
            </span>
            <span
              title="Delete File"
              onClick={() => this.performAction(data.id, "delete", 0)}
              style={Style.icon}
            >
              <DeleteIcon style={Style.icon_size} />
            </span>
          </div>
        )}
      </div>
    );
  }

  performAction(data, value, type) {
    switch (value) {
      case "file":
        this.props.context.assetManagerGrid.handelIsAddNewFile(data);
        break;
      case "folder":
        this.props.context.assetManagerGrid.handelIsAddNewFolder(data);
        break;
      case "openPreview":
        this.props.context.assetManagerGrid.openImagePreview(data);
        break;
      case "download":
        this.props.context.assetManagerGrid.downloadImage(data);
        break;
      case "copy_path":
        this.props.context.assetManagerGrid.copyCodeToClipboard(data);
        break;
      case "delete":
        this.props.context.assetManagerGrid.deleteFileFolder(data, type);
        break;
    }
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default ActionButtonComponent;
