import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {

    pagination: {
        page: "1",
        next: null,
        prev: null,
        totalpages: 1,
        trecords: 0,
        pagesize: 10
    },

    pagination_value: {
        page: "1",
        next: null,
        prev: null,
        totalpages: 1,
        trecords: 0,
        pagesize: 10
    },

    variant_details: {
        loading: false,
        error: null,
        variant: null,
    },

    add_variant: {
        saving: false,
        error: null,
    },

    list_variant: {
        loading: false,
        error: null,
        variants: []
    },

    delete_variant: {
        loading: false,
        error: null,
    },


    variant_value_details: {
        loading: false,
        error: null,
        variant_value: null,
    },

    add_variant_value: {
        saving: false,
        error: null,
    },

    list_variant_value: {
        loading: false,
        error: null,
        variant_values: []
    },

    delete_variant_value: {
        deleting: false,
        error: null,
    },

};

const variantReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case actionTypes.GET_VARIANT_DETAIL_REQUEST:
            return {
                ...state,
                variant_details: {
                    loading: true,
                    error: null,
                    variant: null,
                },
            };
        case actionTypes.GET_VARIANT_DETAIL_FAILURE:
            return {
                ...state,
                variant_details: {
                    loading: false,
                    error: action.payload,
                    variant: null,
                },
            };

        case actionTypes.GET_VARIANT_DETAIL_SUCCESS:
            return {
                ...state,
                variant_details: {
                    loading: false,
                    error: null,
                    variant: action.payload,
                },
            };

        case actionTypes.DELETE_VARIANT_REQUEST:
            return {
                ...state,
                delete_variant: {
                    deleting: true,
                    error: null
                }
            };

        case actionTypes.DELETE_VARIANT_FAILURE:
            return {
                ...state,
                delete_variant: {
                    deleting: false,
                    error: action.payload
                }
            };

        case actionTypes.DELETE_VARIANT_SUCCESS:
            return {
                ...state,
                delete_variant: {
                    deleting: false,
                    error: null
                }
            };


        case actionTypes.CREATE_VARIANT_REQUEST:
            return {
                ...state,
                add_variant: {
                    saving: true,
                    error: null
                },
            };
        case actionTypes.CREATE_VARIANT_FAILURE:
            return {
                ...state,
                add_variant: {
                    saving: false,
                    error: action.payload
                }
            };
        case actionTypes.CREATE_VARIANT_SUCCESS:

            return {
                ...state,
                add_variant: {
                    saving: false,
                    error: null
                }
            };

        case actionTypes.GET_VARIANT_REQUEST:
            return {
                ...state,
                list_variant: {
                    ...state.list_variant,
                    loading: true,
                    error: null,
                },
            };

        case actionTypes.GET_VARIANT_FAILURE:
            return {
                ...state,
                list_variant: {
                    ...state.list_variant,
                    loading: false,
                    error: action.payload,
                },
            };

        case actionTypes.GET_VARIANT_SUCCESS:

            let pagination = _.clone(state.pagination);
            let sdata = action.payload;

            if (sdata && sdata.results) {
                pagination.page = sdata.current;
                pagination.next = sdata.next;
                pagination.prev = sdata.prev;
                pagination.totalpage = sdata.total_pages;
                pagination.totalrecords = sdata.total_records;
                pagination.pagesize = sdata.page_size;
                sdata = sdata.results;
            }

            return {
                ...state,
                list_variant: {
                    ...state.list_variant,
                    loading: false,
                    error: null,
                    variants: sdata
                },

                pagination: {
                    ...pagination
                }
            };


        case actionTypes.GET_VARIANT_VALUE_DETAIL_REQUEST:
            return {
                ...state,
                variant_value_details: {
                    loading: true,
                    error: null,
                    variant_value: null,
                },
            };
        case actionTypes.GET_VARIANT_VALUE_DETAIL_FAILURE:
            return {
                ...state,
                variant_value_details: {
                    loading: false,
                    error: action.payload,
                    variant_value: null,
                },
            };

        case actionTypes.GET_VARIANT_VALUE_DETAIL_SUCCESS:
            return {
                ...state,
                variant_value_details: {
                    loading: false,
                    error: null,
                    variant_value: action.payload,
                },
            };

        case actionTypes.DELETE_VARIANT_VALUE_REQUEST:
            return {
                ...state,
                delete_variant_value: {
                    deleting: true,
                    error: null
                }
            };

        case actionTypes.DELETE_VARIANT_VALUE_FAILURE:
            return {
                ...state,
                delete_variant_value: {
                    deleting: false,
                    error: action.payload
                }
            };

        case actionTypes.DELETE_VARIANT_VALUE_SUCCESS:
            return {
                ...state,
                delete_variant_value: {
                    deleting: false,
                    error: null
                }
            };


        case actionTypes.CREATE_VARIANT_VALUE_REQUEST:
            return {
                ...state,
                add_variant_value: {
                    saving: true,
                    error: null
                },
            };
        case actionTypes.CREATE_VARIANT_VALUE_FAILURE:
            return {
                ...state,
                add_variant_value: {
                    saving: false,
                    error: action.payload
                }
            };
        case actionTypes.CREATE_VARIANT_VALUE_SUCCESS:

            return {
                ...state,
                add_variant_value: {
                    saving: false,
                    error: null
                }
            };

        case actionTypes.GET_VARIANT_VALUE_REQUEST:
            return {
                ...state,
                list_variant_value: {
                    ...state.list_variant_value,
                    loading: true,
                    error: null,
                },
            };

        case actionTypes.GET_VARIANT_VALUE_FAILURE:
            return {
                ...state,
                list_variant_value: {
                    ...state.list_variant_value,
                    loading: false,
                    error: action.payload,
                },
            };

        case actionTypes.GET_VARIANT_VALUE_SUCCESS:

            let pagination_value = _.clone(state.pagination_value);
            let sdata_value = action.payload;

            if (sdata_value && sdata_value.results) {
                pagination_value.page = sdata_value.current;
                pagination_value.next = sdata_value.next;
                pagination_value.prev = sdata_value.prev;
                pagination_value.totalpage = sdata_value.total_pages;
                pagination_value.totalrecords = sdata_value.total_records;
                pagination_value.pagesize = sdata_value.page_size;
                sdata_value = sdata_value.results;
            }


            return {
                ...state,
                list_variant_value: {
                    loading: false,
                    error: null,
                    variant_values: sdata_value
                },

                pagination_value: {
                    ...pagination_value
                }
            };


        default:
            return state;
    }
};

export default variantReducer;
