import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import cx from "classnames";
import { LoadingOverlay, Loader } from "react-overlay-loader";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FormLabel from "@material-ui/core/FormLabel";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedFormStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import ProductInfo from "./components/ProductInfo";
import ProductVariant from "./components/ProductVariant";
import ProductOtherDetails from "./components/ProductOtherDetails";
import ProductCategoryAttribute from "./components/ProductCategoryAttribute";
import Button from "components/CustomButtons/Button.jsx";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";

const Style = {
  pagination: {
    "font-weight": "normal",
    "font-size": "14px",
    "line-height": "20px",
    color: "#666666",
    float: "right"
  },
  questionHead: {
    "font-weight": "500"
  }
};

const initialState = {
  id: "",
  name: "",
  description: "",
  brand_description: "",
  disclaimer: "",
  is_used: false,
  is_consumable: false,
  images: [],
  attributes: [
    {
      id: "",
      name: "",
      value: ""
    }
  ],
  categories: [],
  retailercategories: [],
  is_default_variant_product: false,
  segments: [],
  offers: [],
  brand: [],
  mrp: "",
  retailer_srp: "",
  product_template: {},
  variants: [],
  variantValues: [],
  discount: "",
  editorState: ""
};
const wizardHeaders = [
  "Basic Info",
  "Variant Detail",
  "Classes & Attributes",
  "Offer & Other Details"
];

const paginationInitialState = {
  total_page: 1,
  current_page: 1
};
const imageObj = {
  id: "",
  image: null,
  imagePreviewUrl: null
};

class ProductWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewProductTemplate: false,
      variantSearch: "",
      addProduct: {
        ...initialState,
        partner_id: this.props.PartnerId
      },
      selectedTab: 0,
      error: null,
      setEditData: true,
      pagination: {
        ...paginationInitialState
      },
      isEditMode: false,
      isCloneMode: false,
      isTemplateSelected: false,
      saveRequested: false
    };
    this.clickPreviousBtn = this.clickPreviousBtn.bind(this);
    this.clickNextBtn = this.clickNextBtn.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.setProductData = this.setProductData.bind(this);
    this.handleRadioButton = this.handleRadioButton.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.isSaved && this.state.saveRequested) {
        this.setState(
          {
            saveRequested: false,
            addProduct: {
              ...initialState,
              partner_id: this.props.PartnerId
            }
          },
          () => {
            this.props.close();
          }
        );
      }

      if (
        nextProps.ProductData &&
        !_.isEqual(this.props.ProductData, nextProps.ProductData) &&
        this.state.isNewProductTemplate == "Yes"
      ) {
        let product = nextProps.ProductData;
        let images = [];
        for (let i in product.univ_images) {
          let new_image = {
            ...imageObj
          };
          new_image.id = product.univ_images[i].id;
          new_image.image = product.univ_images[i].url;
          new_image.imagePreviewUrl = product.univ_images[i].url;
          new_image.is_brand_product_image = true;
          images.push(new_image);
        }
        images.push(imageObj);
        let consumable = false;
        if (product.is_consumable == 1) {
          consumable = true;
        } else {
          consumable = false;
        }

        this.setState({
          addProduct: {
            ...this.state.addProduct,
            name: product.name,
            brand_description: product.description,
            images: images,
            mrp: product.msrp,
            is_consumable: consumable,
            // variants: product.variants,
            variantValues: product.variantValues,
            product_template: product
          },
          isTemplateSelected: true
        });
      }
      let error = "";
      if (nextProps.ListError) {
        error = nextProps.ListError;
      }
      this.setState({
        error: error
      });
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState(
        {
          addProduct: this.props.data,
          isEditMode: this.props.isEdit,
          isCloneMode: this.props.isClone
        },
        () => {
          this.setPaginationValues();
        }
      );
      if (this.props.isClone) {
        this.setState({
          addProduct: {
            ...this.props.data,
            id: ""
          }
        })
      }
    }

    this.props.actions.getVariants(null, this.state.variantSearch, true);
  }

  render() {
    return (
      <Grid>
        {!this.state.isEditMode && this.state.isNewProductTemplate == ""
          ? this.ProductTemplateSelectionView()
          : this.ProductWizardView()}
      </Grid>
    );
  }

  setPaginationValues() {
    let totalpagecount = wizardHeaders.length;
    this.setState({
      pagination: {
        ...this.state.pagination,
        total_page: totalpagecount,
        current_page: 1
      },
      selectedTab: 0
    });
  }

  ProductTemplateSelectionView() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <LoadingOverlay>
            <Card>
              <CardHeader>
                <div
                  style={{
                    color: "black",
                    fontWeight: "500",
                    float: "left",
                    fontSize: "16px",
                    lineHeight: "18px"
                  }}
                >
                  <span>New Product</span>
                </div>
                <div
                  className={cx("product-page-back-btn", "pagination")}
                  onClick={() => this.closeWizard()}
                >
                  Back
                </div>
              </CardHeader>
              <CardBody>
                <GridItem style={Style.questionHead}>Choose option</GridItem>
                <GridItem>
                  <Radio
                    checked={this.state.isNewProductTemplate === "Yes"}
                    onClick={this.handleRadioButton}
                    value="Yes"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot
                    }}
                    className="product-option-radio-btn"
                  />
                  <div className={cx("option-label", classes.label)}>
                    <span>Add from Existing Brand Products</span>
                  </div>
                </GridItem>
                <GridItem>
                  <Radio
                    checked={this.state.isNewProductTemplate === "No"}
                    onClick={this.handleRadioButton}
                    value="No"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot
                    }}
                    className="product-option-radio-btn"
                  />
                  <div className={cx("option-label", classes.label)}>
                    <span>Create a new Product</span>
                  </div>
                </GridItem>
              </CardBody>
            </Card>
          </LoadingOverlay>
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12} />
      </GridContainer>
    );
  }

  handleRadioButton = event => {
    this.setState(
      {
        isNewProductTemplate: event.target.value
      },
      () => {
        this.setPaginationValues();
      }
    );
  };

  ProductWizardView() {
    const { classes } = this.props;
    const { current_page, total_page } = this.state.pagination;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <LoadingOverlay>
            <Card>
              <CardHeader>
                <div
                  style={{
                    color: "black",
                    fontWeight: "500",
                    float: "left",
                    fontSize: "16px",
                    lineHeight: "18px"
                  }}
                >
                  <span>
                    {
                      this.state.isCloneMode ?
                        `Clone: ${this.state &&
                        this.state.addProduct &&
                        this.state.addProduct.name}` :
                        this.state.isEditMode
                          ? `Edit: ${this.state &&
                          this.state.addProduct &&
                          this.state.addProduct.name}`
                          : "New Product"}
                  </span>
                </div>
                <div style={Style.pagination}>
                  Page: {current_page}/{total_page}
                </div>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        {_.map(wizardHeaders, (model, index) => (
                          <GridItem
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{
                              "text-align": "center"
                            }}
                          >
                            <div
                              value={index}
                              onClick={() => this.handleChange(index)}
                              className={cx("wizard-tab", {
                                "wizard-selected-tab":
                                  this.state.selectedTab == index
                              })}
                            >
                              {model}
                            </div>
                          </GridItem>
                        ))}
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <Divider variant="center" />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      {this.showSelectedTabContent()}
                    </GridItem>
                  </GridItem>
                </GridContainer>
                {this.state.error && this.state.error.trim() !== "" && (
                  <FormLabel className={classes.labelLeftHorizontal}>
                    <code>{this.state.error}</code>
                  </FormLabel>
                )}
                <div
                  style={{
                    float: "right"
                  }}
                >
                  {current_page > 1 ? (
                    <Button
                      className="wizard-btn"
                      onClick={this.clickPreviousBtn}
                    >
                      Previous
                    </Button>
                  ) : (
                    ""
                  )}
                  {current_page < total_page ? (
                    <Button className="wizard-btn" onClick={this.clickNextBtn}>
                      Next
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button
                    disabled={this.props.isSaving}
                    className="wizard-btn"
                    onClick={() => this.createProduct()}
                  >
                    Save & Close
                  </Button>
                  <Button
                    className="wizard-btn"
                    onClick={() => this.closeWizard()}
                  >
                    Close
                  </Button>
                </div>
              </CardBody>
            </Card>
            {this.props.Loading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.isSaving} text="Saving..." />
          </LoadingOverlay>
        </GridItem>
      </GridContainer>
    );
  }

  onVariantGroupChange = (values) => {
    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variants: values
      }
    });
  }

  handleAddVariantRow = () => {
    let variantValues = this.state.addProduct.variantValues;

    if (!variantValues) {
      variantValues = new Array();
    }

    variantValues.push({
      variant_id: '',
      value_id: ''
    });

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: variantValues
      }
    });
  };

  handleVariantRemove = (index) => {
    let variantValues = this.state.addProduct.variantValues;
    variantValues.splice(index, 1);

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: variantValues
      }
    });
  }

  handleVariantChange = (index, value) => {
    if (!this.state.addProduct || !this.state.addProduct.variantValues) return;

    let existingValues = this.state.addProduct.variantValues;
    existingValues[index]["variant_id"] = value

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: existingValues
      }
    })
  }

  handleVariantValueChange = (index, value) => {
    if (!this.state.addProduct || !this.state.addProduct.variantValues) return;

    let existingValues = this.state.addProduct.variantValues;
    existingValues[index]["value_id"] = value

    this.setState({
      addProduct: {
        ...this.state.addProduct,
        variantValues: existingValues
      }
    })
  }

  showSelectedTabContent() {
    const { selectedTab, addProduct } = this.state;
    console.log("Hello",addProduct)
    switch (selectedTab) {
      case 0:
        return (
          <div>
            <ProductInfo
              data={addProduct}
              editState={this.state.isEditMode}
              cloneState={this.state.isCloneMode}
              templateCheck={this.state.isNewProductTemplate}
              setProductData={this.setProductData}
              isProductSelected={this.state.isTemplateSelected}
              productList={this.props.productList}
            // nameError={this.state.nameError}
            />
          </div>
        );
      case 1:
        return (
          <div>
            <ProductVariant
              variantMaster={this.props.variant_list}
              data={addProduct}
              templateCheck={this.state.isNewProductTemplate}
              isProductSelected={addProduct.product_template.template}
              setProductData={this.setProductData}
              partner={addProduct && addProduct.brand ? addProduct.brand : ''}
              onVariantGroupChange={this.onVariantGroupChange}
              handleAddVariantRow={this.handleAddVariantRow}
              handleVariantRemove={this.handleVariantRemove}
              handleVariantChange={this.handleVariantChange}
              handleVariantValueChange={this.handleVariantValueChange}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <ProductCategoryAttribute
              data={addProduct}
              editState={this.state.isEditMode}
              templateCheck={this.state.isNewProductTemplate}
              setProductData={this.setProductData}
              isProductSelected={this.state.isTemplateSelected}
            // nameError={this.state.nameError}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <ProductOtherDetails
              data={addProduct}
              editState={this.state.isEditMode}
              templateCheck={this.state.isNewProductTemplate}
              setProductData={this.setProductData}
              isProductSelected={this.state.isTemplateSelected}
            // nameError={this.state.nameError}
            />
          </div>
        );
    }
  }

  setProductData(key, value) {
    if (key == "isTemplateSelected") {
      this.setState({
        [key]: value
      });
      return;
    }
    if (key == "brand") {
      this.setState(
        {
          addProduct: {
            ...this.state.addProduct,
            template: "",
            name: "",
            description: "",
            images: [],
            [key]: value
          }
        },
        () => {
          this.props.actions.getAllMapRetailerCategorisedProductTemplatesSelected(
            this.props.PartnerId,'source'
          );
        }
      );
    } else {

      if (key == "template") {
        this.props.actions.getBrandProductByIdAction(value);
      }

      this.setState({
        addProduct: {
          ...this.state.addProduct,
          [key]: value
        }
      });

    }
  }

  clickPreviousBtn() {
    let { current_page } = this.state.pagination;
    let new_page = current_page - 1;
    let selected_tab = this.state.selectedTab - 1;
    if (new_page >= 1) {
      this.setState({
        ...this.state,
        selectedTab: selected_tab,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    }
  }

  clickNextBtn() {
    let { current_page, total_page } = this.state.pagination;
    let new_page = current_page + 1;
    let selected_tab = this.state.selectedTab + 1;
    if (new_page <= total_page) {
      this.setState({
        ...this.state,
        selectedTab: selected_tab,
        pagination: {
          ...this.state.pagination,
          current_page: new_page
        }
      });
    }
  }

  closeWizard = () => {
    this.setState({
      isNewProductTemplate: "",
      addProduct: {
        ...initialState,
        partner_id: this.props.PartnerId
      }
    });
    this.props.close();
  };

  handleChange(value) {
    let page = 1;
    switch (value) {
      case 0:
        page = 1;
        break;
      case 1:
        page = 2;
        break;
      case 2:
        page = 3;
        break;
    }
    this.setState({
      selectedTab: value,
      pagination: {
        ...this.state.pagination,
        current_page: page
      }
    });
  }

  createProduct() {
    let addProduct = this.state.addProduct;
    let isCloneMode = this.state.isCloneMode;

    if (isCloneMode) {
      addProduct.template = addProduct.product_template.id
    }
    if (addProduct.is_consumable) {
      addProduct.is_consumable = 1;
    } else {
      addProduct.is_consumable = 0;
    }

    if (addProduct.is_used) {
      addProduct.is_used = 1;
    } else {
      addProduct.is_used = 0;
    }
    addProduct.partner_id = this.props.PartnerId;

    let checkvalidation = this.validateData();
    if (checkvalidation.length === 0) {
      var files = [];
      if (addProduct.images && addProduct.images.length > 0) {
        for (var i in addProduct.images) {
          if (
            addProduct.images[i] &&
            addProduct.images[i].image instanceof Blob
          ) {
            files.push(addProduct.images[i].image);
          }
        }
      }

      this.setState({
        saveRequested: true
      });

      this.props.actions.saveProduct(addProduct, this.props.UserId, files);
    } else {
      this.setState({
        error: checkvalidation.toString()
      });
    }
  }

  validateData() {
    const {
      name,
      description,
      template,
      mrp,
      brand,
      images
    } = this.state.addProduct;
    let error = 0;
    let msg = [];

    if (name == "" || name == null || name.length < 0) {
      error = 1;
      msg.push("Please enter the name of the product");
    }

    if (mrp == "" || mrp == null || mrp == 0) {
      error = 1;
      msg.push("Please enter the Srp of the product");
    }

    if (description == "" || description == null || description.length < 0) {
      error = 1;
      msg.push("Please enter the description of the product");
    }

    if (images == null || images.length < 1) {
      error = 1;
      msg.push("Please enter the images of the product");
    }

    if (this.state.isNewProductTemplate == "Yes") {
      if (template == "" || template == null || template.length < 0) {
        error = 1;
        msg.push("Please select the brand product");
      }

      if (brand == "" || brand == null || brand.length < 0) {
        error = 1;
        msg.push("Please select the brand of the product");
      }
    }
    return msg;
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  Loading: state.productsAssignmentsState.loading,
  ListError: state.productsAssignmentsState.error,
  PartnerId: state.authState.user.partner_id,
  UserId: state.authState.user.pk,
  isSaved: state.productsAssignmentsState.isSaved,
  ProductData: state.productsAssignmentsState.brand_product.data,

  variant_list_error: state.variantReducer.list_variant.error,
  variant_list: state.variantReducer.list_variant.variants,
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...extendedFormStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductWizard);
