import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AgGrid from "../../../components/AgGrid/AgGrid.jsx";

import withAuthorization from "../../Session/withAuthorization";
import { rolesNames } from "../../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

let _agGridAPI = null;

const extractValues = mappings => {
  return Object.keys(mappings);
};
const lookupValue = (mappings, key) => {
  return mappings[key];
};

const lookupKey = (mappings, name) => {
  var keys = Object.keys(mappings);
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    if (mappings[key] === name) {
      return key;
    }
  }
};

const statusOption = {
  0: "Allowed",
  1: "Blocked"
};

class BrandManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMsg: false,
      isOpen: false,
      componentList: [],
      columnDefs: [
        {
          headerName: "Brand",
          field: "name",
          width: 500,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Access",
          field: "status",
          width: 500,
          resizable: true,
          sortable: true,
          editable: true,
          cellEditor: "agSelectCellEditor",
          // cellEditorParams: {
          //     values: ['Not Set', 'Allowed', 'Blocked'],
          // },
          cellEditorParams: {
            cellHeight: 50,
            values: extractValues(statusOption)
          },
          filterParams: {
            valueFormatter: function(params) {
              return lookupValue(statusOption, params.value);
            }
          },
          valueFormatter: function(params) {
            // convert code to value
            return lookupValue(statusOption, params.value);
          },
          valueParser: function(params) {
            // convert value to code
            return lookupKey(statusOption, params.newValue);
          }
        }
        // {
        //     headerName: "Access",
        //     field: "status",
        //     width: 200,
        //     minWidth: 150,
        //     cellEditor: 'agRichSelectCellEditor',
        //     cellEditorParams: {
        //         values: colours,
        //         cellRenderer: colourCellRenderer,
        //     },
        //     filter: 'agSetColumnFilter',
        //     filterParams: {
        //         values: colours,
        //         valueFormatter: function (params) {
        //             return lookupValue(statusOption, params.value);
        //         },
        //         cellRenderer: colourCellRenderer,
        //     },
        //     valueFormatter: function (params) {
        //         return lookupValue(statusOption, params.value);
        //     },
        //     valueParser: function (params) {
        //         return lookupKey(statusOption, params.newValue);
        //     },
        //     cellRenderer: colourCellRenderer,
        // },
      ]
    };
    this.openModal = this.openModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.saveBrandAcessSetting = this.saveBrandAcessSetting.bind(this);
    this.handleMsgModalClose = this.handleMsgModalClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.List && nextProps.setting) {
      let { List, setting } = nextProps;
      for (let i in List) {
        let index = setting.findIndex(x => x.brand_id == List[i].id);
        if (index > -1) {
          List[i].status = setting[index].status;
        } else {
          List[i].status = 0;
        }
      }
      this.setState({
        componentList: List
      });
    }

    if (nextProps.settingSaved) {
      this.setState(
        {
          isOpen: false,
          isMsg: true
        },
        () => {
          this.props.actions.getRetailersBrandAccessToPublishCampaign(
            this.props.PartnerId
          );
        }
      );
    }
  }

  componentDidMount() {
    let role = rolesNames.RoleMaster.BRAND;
    this.props.actions.getRetailersBrandList(this.props.PartnerId, role);
    this.props.actions.getRetailersBrandAccessToPublishCampaign(
      this.props.PartnerId
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.confirmationModal()}
        {this.successMsgModal()}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Partner's Campaign Access
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <div
                  id="myGrid"
                  style={{
                    width: "100%"
                  }}
                  className="ag-theme-material"
                >
                  <input type="hidden" name="abc" />
                  <AgGrid
                    rowData={this.state.componentList}
                    columnDefs={this.state.columnDefs}
                    // handelEdit={this.handelEdit}
                    // AgGridActionButton={ComponentEditAction}
                  />
                </div>
                <Button
                  color="info"
                  style={{ backgroundColor: "	 #248f24" }}
                  onClick={this.openModal}
                >
                  Save
                </Button>
                {this.props.settingLoading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.settingLoading} text="Saving..." />
              </LoadingOverlay>
            </CardBody>
            <CardBody />
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  openModal() {
    console.log(this.state.componentList);
    this.setState({
      isOpen: true
    });
  }

  handleModalClose() {
    this.setState({
      isOpen: false
    });
  }

  confirmationModal() {
    return (
      <Dialog
        open={this.state.isOpen}
        onClose={this.handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to change the settings?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#248f24" }}
            onClick={this.saveBrandAcessSetting}
            autoFocus
          >
            Yes
          </Button>
          <Button
            onClick={this.handleModalClose}
            style={{ backgroundColor: "#999" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  successMsgModal() {
    return (
      <Dialog
        open={this.state.isMsg}
        onClose={this.handleMsgModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Access Provided to the Brand saved Successfully...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#248f24" }}
            onClick={this.handleMsgModalClose}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleMsgModalClose() {
    this.setState({
      isMsg: false
    });
  }

  saveBrandAcessSetting() {
    this.props.actions.saveRetailersBrandAccessToPublishCampaign(
      this.props.PartnerId,
      this.state.componentList,
      this.props.UserId
    );
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.campignManagementReducer.partners.data,
  Loading: state.campignManagementReducer.partners.loading,
  ListError: state.campignManagementReducer.partners.error,
  setting: state.campignManagementReducer.brandaccesstopublish.data,
  settingLoading: state.campignManagementReducer.brandaccesstopublish.loading,
  settingSaved: state.campignManagementReducer.brandaccesstopublish.isSaved,
  settingError: state.campignManagementReducer.brandaccesstopublish.error,
  PartnerId: state.authState.user.partner_id,
  Role: state.authState.user.role,
  User: state.authState.user,
  UserId: state.authState.user.pk
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BrandManagement);
