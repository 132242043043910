import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmpassword: "",
      modalChangePassword: true,
      error: false,
      isPasswordLengthValidationerror: false,
      passwordConfirmPasswordMismatchError: false
    };
    this.isValidationRequired = this.isValidationRequired.bind(this);
  }
  handelDailogTextChange = key => event => {
    var isValid = true;
    // if (!validationUtils.validateData(type, event.target.value)) {
    //     isValid = false;
    // }
    let keys = event.target.id;
    this.setState({
      [keys]: event.target.value,
      isError: !isValid
    });
  };

  componentDidMount() {}
  isValidationRequired(password) {
    if (password.length >= 8) {
      this.setState({
        isPasswordLengthValidationerror: false
      });
      return true;
    } else {
      this.setState({
        isPasswordLengthValidationerror: true
      });
      return false;
    }
  }

  saveReatiler = () => {
    const { password, confirmpassword } = this.state;
    if (password && password != "" && password === confirmpassword) {
      this.setState({
        passwordConfirmPasswordMismatchError: false
      });
      if (this.isValidationRequired(password)) {
        let user_id = this.props.UserId.pk;
        if (this.props.isAdminChangingPartnerPassword) {
          user_id = this.props.userid;
        }
        if (!this.props.isAdminChangingPartnerPassword) {
          this.props.actions.savePassword(
            user_id,
            password,
            this.props.UserId.Token,
            this.props.UserId.pk
          );
        } else {
          this.props.actions.savePassword(
            user_id,
            password,
            this.props.api_key_partner,
            this.props.UserId.pk,
            true
          );
        }
      }
    } else if (!password || password === "") {
      this.setState({
        isPasswordLengthValidationerror: true,
        passwordConfirmPasswordMismatchError: false
      });
    } else if (password !== confirmpassword) {
      this.setState({
        passwordConfirmPasswordMismatchError: true,
        isPasswordLengthValidationerror: false
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        modalChangePassword: nextProps.open
      });
      if (nextProps.isFirstLogin === false && nextProps.landingPage) {
        this.props.actions.onNavigateTo(nextProps.landingPage);
      }
    }
  }
  render() {
    const { classes, open, onClose, userid } = this.props;
    const {
      isError,
      password,
      confirmpassword,
      modalChangePassword
    } = this.state;
    return (
      <GridContainer>
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={"xs"}
          disableBackdropClick={modalChangePassword}
          onClose={() => onClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <CustomInput
                success={password !== ""}
                error={password === "" || password.length < 8 || isError}
                labelText="Password *"
                id="password"
                name="txtpassword"
                autocomplete="off"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "password",
                  value: password,
                  onChange: this.handelDailogTextChange("password")
                }}
              />
              <CustomInput
                success={confirmpassword !== ""}
                error={confirmpassword === "" || password.length < 8 || isError}
                labelText="Confirm Password *"
                id="confirmpassword"
                name="txtconfirmpassword"
                autocomplete="off"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "password",
                  value: confirmpassword,
                  onChange: this.handelDailogTextChange("confirmpassword", "")
                }}
              />
              {this.state.isPasswordLengthValidationerror && (
                <h10>Please input at least 8 characters.</h10>
              )}
              {this.state.passwordConfirmPasswordMismatchError && (
                <h10>Password and Confirmation Password do not match.</h10>
              )}
              {this.props.passwordChangeError && (
                <h10>{this.props.passwordChangeError}</h10>
              )}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.saveReatiler()}
                color="primary"
              >
                {this.state.addNew ? "Add" : "Update"}
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => onClose()}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </GridContainer>
    );
  }
}

ChangePassword.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userid: PropTypes.string.isRequired
};

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserKey: state.authState.user.user,
  api_key: state.authState.user.id,
  partner_id: state.authState.user.partner_id,
  UserId: state.authState.user,
  passwordChangeError: state.authState.passwordChangeError
});

// const condition = (authUser) =>
//     authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  // withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...dashboardStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ChangePassword);
