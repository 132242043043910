import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import withAuthorization from "../Session/withAuthorization";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import AddIcon from "@material-ui/icons/Add";
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

import { rolesNames } from "../../constants";

const initial_state = {
  editsegmentsData: {
    name: "",
    image: "",
    segmentName: "",
    imagePreviewUrl: "",
    file: null
  },
  required: {
    name: "",
    segmentName: "",
    imagePreviewUrl: ""
  }
};
class SegmentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      segmentsData: [],
      segmentsMaster: null,
      isAddNew: false,
      ...initial_state
    };
  }

  componentDidMount() {
    if (
      this.props.List &&
      this.props.List.length > 0 &&
      this.props.List[0]["segmentsDetailsList"]
    ) {
      const segmentsData = this.props.List[0]["segmentsDetailsList"];
      this.setState(() => ({
        segmentsData: segmentsData,
        editsegmentsData: segmentsData
      }));
    } else {
      this.props.actions.getRetailer(this.props.UserKey);
    }
    if (
      this.props.List &&
      this.props.List.length > 0 &&
      this.props.List[0]["segmentsList"]
    ) {
      const segmentsList = this.props.List[0]["segmentsList"];
      this.setState({
        segmentsMaster: segmentsList
      });
    }
  }

  saveReatiler = () => {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      this.state.editsegmentsData.name.trim() === "" ||
      reqData["name"] === "error"
    ) {
      reqData["name"] = "error";
      isValid = false;
    }
    if (
      this.state.editsegmentsData.segmentName.trim() === "" ||
      reqData["segmentName"] === "error"
    ) {
      reqData["segmentName"] = "error";
      isValid = false;
    }
    if (
      this.state.editsegmentsData.imagePreviewUrl.trim() === "" ||
      reqData["imagePreviewUrl"] === "error"
    ) {
      reqData["imagePreviewUrl"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    if (isValid) {
      const segmentDetails = this.state.segmentsData;
      const savingData = this.state.editsegmentsData;
      var result = Object.keys(segmentDetails).map(function(key) {
        return segmentDetails[key];
      });

      var insertData = {
        name: savingData.name,
        segmentName: savingData.segmentName
      };
      if (savingData.imagePreviewUrl) {
        insertData["imagePreviewUrl"] = savingData.imagePreviewUrl;
      }

      result.push(insertData);
      var data = {
        segmentsDetailsList: result
      };
      this.props.actions.saveRetailer(data, this.props.UserKey);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        nextProps.List[0]["segmentsDetailsList"] &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const segmentsData = nextProps.List[0]["segmentsDetailsList"];
        this.setState(() => ({
          segmentsData: segmentsData,
          editsegmentsData: segmentsData
        }));
      }
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        nextProps.List[0]["segmentsList"]
      ) {
        const segmentsList = nextProps.List[0]["segmentsList"];
        this.setState({
          segmentsMaster: segmentsList
        });
      }
      if (nextProps.isSaved) {
        this.setState(() => ({
          isAddNew: false
        }));
      }
    }
  }

  addAnotherSegmentDetail = () => {
    this.setState({
      ...initial_state,
      isAddNew: !this.state.isAddNew
    });
  };
  getTableData = () => {
    const { segmentsData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(
      segmentsData,
      ({ name, segmentName, imagePreviewUrl, image }, index) => {
        returnData.push([
          <div className={classes.imgContainer} style={{ width: "80px" }}>
            <img
              src={image ? image.secure_url : imagePreviewUrl}
              alt="..."
              className={classes.img}
              style={{ height: "70px", width: "auto" }}
            />
          </div>,
          <span>
            {
              //eslint-disable-next-line
                            <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
                {name}
              </a>
            }
          </span>,
          <span>{segmentName}</span>,
          <Button simple className={classes.actionButton}>
            <Close className={classes.icon} />
          </Button>
        ]);
      }
    );
    return returnData;
  };
  renderSegmentDetailsTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.loadingGetData}
              color="success"
              onClick={this.addAnotherSegmentDetail}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} />
              Segment Details
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>Segment Details Table</p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <Table
                  tableHead={["", "Name", "Segment Name", ""]}
                  tableData={this.getTableData()}
                  tableShopping
                  customHeadCellClasses={[
                    classes.center,
                    classes.description,
                    classes.description,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                  customCellClasses={[
                    classes.tdName,
                    classes.customFont,
                    classes.customFont,
                    classes.customFont
                  ]}
                  customClassesForCells={[1, 2, 3, 4, 5, 6]}
                />
                {(this.props.Loading || this.props.loadingGetData) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.loadingGetData} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
  render() {
    return this.state.isAddNew
      ? this.renderAddSegmentDetails()
      : this.renderSegmentDetailsTable();
  }

  handleTextChange = (key, type) => event => {
    var validData = "success";
    var reqData = this.state.required;
    reqData[key] = validData;
    var editdata = this.state.editsegmentsData;
    editdata[key] = event.target.value;
    this.setState({
      editsegmentsData: editdata,
      required: reqData
    });
  };

  handelImageRemove = () => {
    var editsegmentsData = this.state;
    editsegmentsData["file"] = null;
    delete editsegmentsData.imagePreviewUrl;
    this.setState({
      editsegmentsData
    });
  };
  handelImageChange = file => {
    let reader = new FileReader();
    reader.onloadend = () => {
      var { editsegmentsData } = this.state;
      editsegmentsData["file"] = file;
      editsegmentsData["imagePreviewUrl"] = reader.result;
      this.setState({
        editsegmentsData: editsegmentsData
      });
    };
    reader.readAsDataURL(file);
  };

  renderAddSegmentDetails() {
    const { classes } = this.props;
    const { isAddNew, required } = this.state;
    const { name, image, segmentName } = this.state.editsegmentsData;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Icon style={{ fontSize: "36px" }}>info</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <br />
                  <br />
                  <br />
                  <legend>Segment Image</legend>
                  <ImageUpload
                    addButtonProps={{
                      round: true
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true
                    }}
                    imgUrl={image ? image.secure_url : null}
                    onimageRemove={this.handelImageRemove}
                    onChange={this.handelImageChange}
                  />
                  <br />
                  <br />
                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="Model *"
                    >
                      Model *
                    </InputLabel>

                    <Select
                      success={required.segmentName === "success"}
                      error={required.segmentName === "error"}
                      style={{ marginTop: "0px" }}
                      value={segmentName}
                      onChange={this.handleTextChange("segmentName", "")}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Segment
                      </MenuItem>
                      {_.map(
                        this.state.segmentsMaster,
                        ({ segmentName }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={segmentName}
                              key={index + "_" + segmentName}
                            >
                              {segmentName}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                  <br />
                  {isAddNew && (
                    <Button color="info" onClick={this.saveReatiler}>
                      Save Changes
                    </Button>
                  )}
                  {isAddNew && (
                    <Button onClick={this.addAnotherSegmentDetail}>
                      {" "}
                      Cancel{" "}
                    </Button>
                  )}
                  {(this.props.Loading || this.props.loadingGetData) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Saving.." />
                  <Loader
                    loading={this.props.loadingGetData}
                    text="Loading.."
                  />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.retailerState.list,
  Loading: state.retailerState.loading,
  ListError: state.retailerState.error,
  loadingGetData: state.retailerState.loadingData,
  isSaved: state.retailerState.isSaved,
  UserKey: state.authState.user.Token
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...customInputStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SegmentDetails);
