import * as retailerActions from "./retailerActions";
import * as authActions from "./authActions";
import * as navigationActions from "./navigationActions";
import * as brandActions from "./brandActions";
import * as productActions from "./productActions";
import * as productAttributesActions from "./productAttributesActions";
import * as productsAssignmentsAction from "./productsAssignmentsAction";
import * as SegmentActions from "./SegmentActions";
import * as Class from "./classActions";
import * as wholesalerActions from "./wholesalerActions";
import * as businessTypeActions from "./businessTypeActions";
import * as industriesPageActions from "./industriesPageActions";
import * as tagsTypeActions from "./tagsTypeActions";
import * as tagsActions from "./tagsActions";
import * as MenuActions from "./menumasterActions";
import * as partnerMenuActions from "./partnerMenuActions";
import * as cmsContentActions from "./cmsContentActions";
import * as servicesActions from "./servicesActions";
import * as messageActions from "./messageActions";
import * as offersActions from "./offersActions";
import * as NotificationType from "./NotificationtypeAction";
import * as OfferTypeActions from "./OfferTypeActions";
import * as ShippingActions from "./shippingActions";
import * as CampigManagementAction from "./CampigManagementAction";
import * as OrderAction from "./orderActions";
import * as ProductCMSAction from "./productCMSActions";
import * as assetManagementActions from "./assetManagementActions";
import * as paymentMethodActions from "./paymentMethodActions";
import * as changePasswordAction from "./changePasswordAction";
import * as templateActions from "./templateActions";
import * as dashboardActions from "./dashboardActions";
import * as reportActions from "./reportActions";
import * as settingActions from "./settingActions";
import * as appMenuActions from "./appMenuActions";
import * as userGroupActions from "./userGroupActions";
import * as usersActions from "./usersActions";
import * as inventoryManagementActions from "./inventoryManagementActions";
import * as categoryActions from "./categoryActions";
import * as ProductCreationActions from "./ProductCreationActions";
import * as partnerMappingActions from "./partnerMappingActions";
import * as customPageActions from "./customPageActions";
import * as cmsActions from "./cmsActions";
import * as variantActions from "./variantActions";
import * as productVariantActions from "./productVariantActions";
import * as eventActions from "./eventActions";

export const ActionCreators = Object.assign(
  {},
  retailerActions,
  authActions,
  navigationActions,
  brandActions,
  productActions,
  productAttributesActions,
  productsAssignmentsAction,
  SegmentActions,
  Class,
  wholesalerActions,
  businessTypeActions,
  industriesPageActions,
  tagsTypeActions,
  tagsActions,
  MenuActions,
  servicesActions,
  partnerMenuActions,
  cmsContentActions,
  messageActions,
  offersActions,
  NotificationType,
  OfferTypeActions,
  ShippingActions,
  CampigManagementAction,
  OrderAction,
  ProductCMSAction,
  assetManagementActions,
  paymentMethodActions,
  changePasswordAction,
  templateActions,
  dashboardActions,
  reportActions,
  settingActions,
  appMenuActions,
  userGroupActions,
  usersActions,
  inventoryManagementActions,
  categoryActions,
  ProductCreationActions,
  partnerMappingActions,
  customPageActions,
  cmsActions,
  variantActions,
  productVariantActions,
  eventActions,
);
