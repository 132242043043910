import * as locatorService from "../../services/menuMasterService";
import * as actionTypes from "./actionTypes";

export const createMenuMaster = (userId, data) => async dispatch => {
  dispatch(createMenuMasterRequest());
  try {
    var success = await locatorService.createMenuMaster(userId, data);
    if (success.status && success.status === "1") {
      dispatch(createMenuMasterSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(createMenuMasterFailure(success.error));
    } else {
      dispatch(createMenuMasterFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(createMenuMasterFailure(error.message));
  }
};

const createMenuMasterRequest = () => {
  return {
    type: actionTypes.CREATE_MENU_MASTER_REQUEST
  };
};

const createMenuMasterSuccess = data => {
  return {
    type: actionTypes.CREATE_MENU_MASTER_SUCCESS,
    payload: data
  };
};

const createMenuMasterFailure = error => {
  return {
    type: actionTypes.CREATE_MENU_MASTER_FAILURE,
    payload: error
  };
};

export const getMenuMasterList = (businesstypeid = 0) => async dispatch => {
  dispatch(getMenuMasterRequest());
  try {
    var success = await locatorService.getMenuMasterList(businesstypeid);
    if (success.status && success.status === "1") {
      dispatch(getmenuMasterSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getMenuMasterFailure(success.error));
    } else {
      dispatch(getMenuMasterFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getMenuMasterFailure(error.message));
  }
};

const getMenuMasterRequest = () => {
  return {
    type: actionTypes.GET_MENU_MASTER_REQUEST
  };
};

const getmenuMasterSuccess = data => {
  return {
    type: actionTypes.GET_MENU_MASTER_SUCCESS,
    payload: data
  };
};

const getMenuMasterFailure = error => {
  return {
    type: actionTypes.GET_MENU_MASTER_FAILURE,
    payload: error
  };
};

export const copyMenu = (userId, data) => async dispatch => {
  dispatch(copyMenuRequest());
  try {
    var success = await locatorService.copyMenu(userId, data);

    if (success.status && success.status === "1") {
      dispatch(copyMenuSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(copyMenuFailure(success.error));
    } else {
      dispatch(copyMenuFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(copyMenuFailure(error.message));
  }
};

const copyMenuRequest = () => {
  return {
    type: actionTypes.COPY_MENU_REQUEST
  };
};

const copyMenuSuccess = data => {
  return {
    type: actionTypes.COPY_MENU_SUCCESS,
    payload: data
  };
};

const copyMenuFailure = error => {
  return {
    type: actionTypes.COPY_MENU_FAILURE,
    payload: error
  };
};

export const copyStateupdate = (userId, data) => async dispatch => {
  dispatch(copyMenuUpadte());
};

const copyMenuUpadte = error => {
  return {
    type: actionTypes.COPY_MENU_UPDATE,
    payload: error
  };
};
