import React from 'react';
import moment from 'moment'
import { compose } from 'recompose';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AgGrid from "../../../components/AgGrid/AgGrid.jsx";
import EventAction from "../../../components/AgGrid/EventAction";
import { ActionCreators } from "../../../redux/actions";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

function dateFormatter(params) {
    if (params.value) {
        return moment(params.value).format("MM/DD/YYYY");
    } else {
        return "";
    }
}

class ListEvents extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            context: { eventGrid: this },
            columnDefs: [
                {
                    headerName: "Name",
                    field: "name",
                    width: 270,
                    resizable: true,
                    sortable: true,
                    filter: "agTextColumnFilter"
                },
                {
                    headerName: "Description",
                    field: "description",
                    width: 180,
                    resizable: true,
                    sortable: true,
                    filter: "agTextColumnFilter"
                },
                {
                    headerName: "Event Type",
                    field: "event_type.name",
                    width: 250,
                    resizable: true,
                    sortable: true,
                    filter: "agTextColumnFilter",
                },
                {
                    headerName: "Start Date",
                    field: "start_date",
                    width: 180,
                    resizable: true,
                    sortable: true,
                    valueFormatter: dateFormatter,
                    filter: "agTextColumnFilter"
                },
                {
                    headerName: "End Date",
                    field: "end_date",
                    width: 180,
                    resizable: true,
                    sortable: true,
                    valueFormatter: dateFormatter,
                    filter: "agTextColumnFilter"
                },
                {
                    headerName: "Actions",
                    field: "value",
                    cellRenderer: "AgGridActionButton",
                    colId: "params",
                    width: 200,
                    filter: false
                }
            ],
            isAddNew: false,
            isEdit: false,
        }
        this.handelIsAddNew = this.handelIsAddNew.bind(this);
        this.handelEdit = this.handelEdit.bind(this);
        this.handelDelete = this.handelDelete.bind(this);
        this.handelMap = this.handelMap.bind(this);
    }

    componentDidMount() {
        this.props.actions.getEventAction(this.props.PartnerId)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.events) {
            this.setState({
                list: nextProps.events
            })
        }
    }

    handelEdit(id) {
        this.props.edit(id)
    }

    handelDelete(id) {
        this.props.delete(id)
    }

    handelIsAddNew() {
        this.props.new(true)
    }

    handelMap(id) {
        this.props.map(id)
    }

    render() {
        return (
            <AgGrid
                rowData={this.state.list}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                context={this.state.context}
                handelDelete={this.handelDelete}
                handelMap={this.handelMap}
                loading={this.props.loading}
                AgGridActionButton={EventAction}
                new={this.handelIsAddNew}
                isAddBtn={true}
            />
        );
    }

    handleChange = (key) => event => {
        this.setState({
            ...this.state,
            addEventType: {
                ...this.state.addEventType,
                [key]: event.target.value
            }
        })
    }
}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
    UserId: state.authState.user.pk,
    PartnerId: state.authState.user.partner_id,
    Role: state.authState.user.role,
    events: state.eventState.event.data,
    loading: state.eventState.event.loading,
    error: state.eventState.event.error,
});


export default compose(
    withStyles({
        ...extendedTablesStyle,
        ...customInputStyle,
        ...regularFormsStyle,
        ...validationFormsStyle,
    }),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ListEvents);