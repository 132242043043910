import React from "react";
import _, { indexOf } from "lodash";
import ClearIcon from "@material-ui/icons/Clear";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";

const VariantSelector = props => {

    const handleAddVariantRow = () => {
        props.handleAddVariantRow();
    };

    const handleVariantRemove = index => {
        Swal.fire({
            title: "Are you sure?",
            text: "This action will remove the variant value.",
            icon: "warning",
            showCancelButton: true,
            focusCancel: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Remove"
        }).then(result => {
            if (result.isConfirmed) {
                props.handleVariantRemove(index);
            }
        });
    };

    const handleVariantChange = (index) => event => {
        props.handleVariantChange(index, event.target.value);
    };

    const handleVariantValueChange = (index) => event => {
        props.handleVariantValueChange(index, event.target.value);
    };

    const getVariant = (index) => {

        if (!props.variantMaster) return [];

        if (!props.data) return props.variantMaster;


        let result = props.variantMaster.filter(item => !props.data.find(element => {
            return element.variant_id === item.id && props.data[index]["variant_id"] !== item.id;
        }));

        if (!result) return []

        return result
    }

    const getValues = (index) => {

        if (!props.data || !props.data[index] || !props.variantMaster) return []

        const result = props.variantMaster.find((element) => props.data[index].variant_id === element.id);

        if (!result) return []

        return result.values
    }

    const getPrimaryTabTableData = () => {
        let rows = props.data || [];

        let sorted_row = rows;
        var returnData = [];

        _.map(sorted_row, (data, index) => {
            returnData.push([
                <FormControl fullWidth name="Component">
                    <Select
                        style={{ marginTop: "0px" }}
                        onChange={handleVariantChange(index)}
                        value={data.variant_id}
                        disabled={props.isProductSelected}
                    >
                        <MenuItem disabled>Choose Variant</MenuItem>
                        {_.map(getVariant(index), ({ id, name }, masterIndex) => {
                            return (
                                <MenuItem value={id} key={masterIndex + "_" + id}>
                                    {name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>,
                <FormControl fullWidth name="Component">
                    <Select
                        style={{ marginTop: "0px" }}
                        onChange={handleVariantValueChange(index)}
                        value={data.value_id}
                        disabled={props.isProductSelected}
                    >
                        <MenuItem disabled>Choose Value</MenuItem>
                        {_.map(getValues(index), ({ id, name }, valueIndex) => {
                            return (
                                <MenuItem value={id} key={valueIndex + "_" + id}>
                                    {name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>,
                <span style={{ width: "50px" }}>
                    {
                        !props.isProductSelected &&
                        <>
                            <ReactTooltip id="remove">
                                <span>Remove Varaiant</span>
                            </ReactTooltip>
                            <Button
                                size="sm"
                                color="success"
                                data-tip
                                data-for="remove"
                                onClick={() => handleVariantRemove(index)}
                            >
                                <ClearIcon />
                            </Button>
                        </>
                    }
                </span>
            ]);
        });

        return returnData;
    };

    const getPrimaryTab = () => {
        return (
            <GridContainer>
                {
                    !props.isProductSelected && <GridItem xs={12} sm={12} md={12}>
                        <div style={{ float: "right" }}>
                            <Button
                                size="sm"
                                color="info"
                                onClick={() => handleAddVariantRow()}
                            >
                                Add Variant
                            </Button>
                        </div>
                    </GridItem>
                }

                <GridItem xs={12} sm={12} md={12}>
                    <Table
                        tableShopping
                        tableHead={[
                            // "Sort #",
                            "Variant",
                            "Value",
                            ""
                        ]}
                        tableData={getPrimaryTabTableData()}
                    />
                </GridItem>
            </GridContainer >
        );
    };

    return getPrimaryTab();
};

export default VariantSelector;
