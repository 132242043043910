import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

// @material-ui/core components
import { LoadingOverlay, Loader } from "react-overlay-loader";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import AddIcon from "@material-ui/icons/Add";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgData: {
        services: []
      },
      deletedIndex: -1,
      openDialog: false,
      editData: {
        services: []
      },
      isEdit: false
    };
  }

  componentDidMount() {
    if (
      this.props.List &&
      this.props.List.length > 0 &&
      this.props.List[0]["serviceList"]
    ) {
      const aboutUSData = this.props.List[0]["serviceList"];
      this.setState(() => ({
        editData: { services: [...aboutUSData] },
        orgData: { services: [...aboutUSData] }
      }));
    } else {
      this.props.actions.getRetailer(this.props.UserKey);
    }
  }

  saveReatiler = () => {
    const savingData = this.state.editData.services;
    var data = {
      serviceList: [...savingData]
    };
    this.props.actions.saveRetailer(data, this.props.UserKey);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        nextProps.List[0]["serviceList"] &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const aboutUSData = nextProps.List[0]["serviceList"];
        this.setState(() => ({
          editData: { services: [...aboutUSData] },
          orgData: { services: [...aboutUSData] }
        }));
      }
      if (nextProps.isSaved) {
        this.setState(() => ({
          isEdit: false
        }));
      }
    }
  }
  handelEdit = () => {
    var { isEdit, orgData, editData } = this.state;
    if (isEdit) {
      editData.services = [...orgData.services];
      this.setState(() => ({
        editData: editData,
        isEdit: false
      }));
    } else {
      this.setState({
        isEdit: true
      });
    }
  };
  handleChange = (index, key) => event => {
    var editData = this.state["editData"];
    editData.services[index][key] = event.target.value;
    this.setState({ editData: editData });
  };

  addMoreService = () => {
    var editData = this.state.editData.services;
    editData.push({ subHead: "", copy: "" });
    this.setState(() => ({
      editData: { services: [...editData] }
    }));
  };

  deleteService = () => {
    var { editData, deletedIndex } = this.state;
    editData.services.splice(deletedIndex, 1);
    this.setState({
      editData: editData,
      openDialog: false
    });
  };

  openModalDialog = (modalName, isOpen, index) => {
    if (isOpen) {
    }
    this.setState({ [modalName]: isOpen, deletedIndex: index });
  };

  render() {
    const { classes } = this.props;
    const { services } = this.state.editData;
    const { isEdit } = this.state;
    return (
      <GridContainer>
        <Dialog
          open={this.state.openDialog}
          disableBackdropClick={true}
          onClose={() => this.openModalDialog("openDialog", false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this Service?
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.deleteService()}
              >
                Delete
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.openModalDialog("openDialog", false)}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
          </DialogContent>
        </Dialog>
        {!isEdit && (
          <GridItem xs={12}>
            <Grid
              container
              spacing={16}
              alignItems={"flex-end"}
              direction={"row"}
              justify={"flex-end"}
            >
              <Button
                disabled={this.props.loadingGetData}
                color="success"
                onClick={this.handelEdit}
                className={classes.marginRight}
              >
                <EditIcon className={classes.icons} />
                Edit
              </Button>
            </Grid>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>question_answer</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody style={{ minHeight: "150px" }}>
              <form>
                <LoadingOverlay>
                  {_.map(services, ({ subHead, copy }, index) => {
                    return (
                      <div key={index}>
                        <CustomInput
                          labelText="Sub Head"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: subHead,
                            onChange: this.handleChange(index, "subHead"),
                            disabled: !isEdit,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  simple
                                  className={classes.marginRight}
                                  style={{
                                    padding: "5px",
                                    display: isEdit ? "block" : "none"
                                  }}
                                  onClick={() =>
                                    this.openModalDialog(
                                      "openDialog",
                                      true,
                                      index
                                    )
                                  }
                                >
                                  <ClearIcon
                                    className={classes.icons}
                                    style={{
                                      color: "#f44336",
                                      marginRight: "0px"
                                    }}
                                  />
                                </Button>
                              </InputAdornment>
                            )
                          }}
                          id={"subHead_" + index}
                        />
                        <CustomInput
                          labelText="Copy"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            value: copy,
                            onChange: this.handleChange(index, "copy"),
                            disabled: !isEdit,
                            multiline: true,
                            rows: 4,
                            rowsMax: 10
                          }}
                          id={"copy_" + index}
                        />
                        <br />
                        <br />
                      </div>
                    );
                  })}
                  <br />
                  {isEdit && (
                    <Button
                      color="success"
                      onClick={this.addMoreService}
                      className={classes.marginRight}
                    >
                      <AddIcon className={classes.icons} /> Service
                    </Button>
                  )}
                  {isEdit && (
                    <Button color="info" onClick={this.saveReatiler}>
                      Save Changes
                    </Button>
                  )}
                  {isEdit && <Button onClick={this.handelEdit}>Cancel</Button>}
                  {(this.props.Loading || this.props.loadingGetData) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Saving.." />
                  <Loader
                    loading={this.props.loadingGetData}
                    text="Loading.."
                  />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.retailerState.list,
  Loading: state.retailerState.loading,
  ListError: state.retailerState.error,
  loadingGetData: state.retailerState.loadingData,
  isSaved: state.retailerState.isSaved,
  UserKey: state.authState.user.Token
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles(regularFormsStyle),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Service);
//export default withStyles(regularFormsStyle)(Service);
