import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";
const INITIAL_STATE = {
  attributes: null,
  list: null,
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  isSaving: false,
  deletingData: false
};

const productAttributesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PRODUCT_ATTRIBUTES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        // isSaved: false,
        isSaving: true
      };
    case actionTypes.SAVE_PRODUCT_ATTRIBUTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaving: false
      };
    case actionTypes.SAVE_PRODUCT_ATTRIBUTES_SUCCESS:
      var data = _.clone(state.attributes);
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data.splice(index, 1);
      }
      data.unshift(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
        isSaved: true,
        attributes: data,
        isSaving: false
      };
    case actionTypes.GET_PRODUCT_ATTRIBUTES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        // isSaved: false,
        isSaving: true,
        list: null
      };
    case actionTypes.GET_PRODUCT_ATTRIBUTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaving: false,
        // isSaved: false,
        list: null
      };
    case actionTypes.GET_PRODUCT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        // isSaved: false,
        list: action.payload,
        isSaving: false
      };

    case actionTypes.AFTER_SAVE_PRODUCT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        isSaved: false
      };

    case actionTypes.GET_ATTRIBUTE_BY_PRODUCT_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaving: false,
        attributes: null
      };
    case actionTypes.GET_ATTRIBUTE_BY_PRODUCT_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaving: false,
        attributes: null
      };
    case actionTypes.GET_ATTRIBUTE_BY_PRODUCT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        attributes: action.payload,
        isSaving: false
      };
    default:
      return state;
  }
};
export default productAttributesReducer;
