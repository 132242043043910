// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const getRetailerOrder = async (retailer_id, page, search) => {
  try {
    let url =
      "/api/retailer/order/list/?partner_key=" + retailer_id + "&page=" + page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRetailerOrderDetails = async order_id => {
  try {
    const response = await axios.get(
      "/api/retailer/order/detail/?order_id=" + order_id
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const changeOrderStatus = async (order_id, status, userId) => {
  try {
    let data = new FormData();
    data.append("order_id", order_id);
    data.append("status", status);
    data.append("userid", userId);
    const response = await axios.post(
      "/api/retailer/order/status/update/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
