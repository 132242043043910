import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";

import NavPills from "../../components/NavPills/NavPills.jsx";
import Accordion from "../../components/Accordion/Accordion.jsx";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import Table from "components/Table/Table.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import Radio from "@material-ui/core/Radio";
import { RadioGroup } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import basicsStyle from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import { validationUtils } from "../../utils";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const initialState = {
  name: "",
  price: null,
  newused: "",
  mileage: "",
  availability: "",
  location: "",
  primarycolor: "",
  vin: "",
  stock: "",
  title: "",
  condition: "",
  year: "",
  manufacturer: "",
  model: "",
  imagePreviewUrl: null,
  file: null,
  required: {
    name: "",
    price: ""
  },
  otherProductImages: [],
  modalProductImage: false,
  tabsAndAccordion: [],
  modalTabs: false,
  editTabData: {
    tabName: "",
    accordions: []
  },
  addNewTab: true,
  editTabIndex: -1
};
class ProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      selectedEnabled: "",
      tableData: [],
      selectedIndex: -1,
      deletedIndex: -1,
      conditionSelect: "",
      years: "",
      ...initialState
    };
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
    this.handleAvailableChangeEnabled = this.handleAvailableChangeEnabled.bind(
      this
    );
  }

  componentDidMount() {
    if (
      this.props.List &&
      this.props.List.length > 0 &&
      this.props.List[0]["productList"]
    ) {
      const productListData = this.props.List[0]["productList"];
      var result = Object.keys(productListData).map(function(key) {
        return productListData[key];
      });
      this.setState(() => ({
        tableData: result
      }));
    } else {
      this.props.actions.getRetailer(this.props.UserKey);
    }
    if (
      this.props.List &&
      this.props.List.length > 0 &&
      this.props.List[0]["brands"]
    ) {
      this.setState({
        brands: this.props.List[0]["brands"]
      });
    }
    var years = this.getYear();
    var yearArray = [];
    for (var i = years; i > 1950; i--) {
      yearArray.push(i);
    }
    this.setState({
      yearArray: yearArray
    });
  }

  handleChangeEnabled(event) {
    this.setState({ selectedEnabled: event.target.value });
  }

  handleAvailableChangeEnabled(event) {
    this.setState({ selectedEnabled: event.target.value });
  }

  handleCondition = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  saveReatiler = () => {
    var isValid = true;
    var data = {};
    if (isValid) {
      if (this.state.selectedIndex > -1) {
        const savingDataObj = this.state.tableData;
        const {
          selectedIndex,
          name,
          price,
          newused,
          mileage,
          availability,
          location,
          primarycolor,
          vin,
          stock,
          title,
          condition,
          year,
          manufacturer,
          model,
          imagePreviewUrl,
          file
        } = this.state;
        var savingData = {};
        savingData = _.cloneDeep(savingDataObj[selectedIndex]);
        if (savingData) {
          savingDataObj[selectedIndex].name = name;
          savingDataObj[selectedIndex].price = price;
          savingDataObj[selectedIndex].mileage = mileage;
          savingDataObj[selectedIndex].newused = newused;
          savingDataObj[selectedIndex].availability = availability;
          savingDataObj[selectedIndex].location = location;
          savingDataObj[selectedIndex].primarycolor = primarycolor;
          savingDataObj[selectedIndex].vin = vin;
          savingDataObj[selectedIndex].stock = stock;
          savingDataObj[selectedIndex].title = title;
          savingDataObj[selectedIndex].condition = condition;
          savingDataObj[selectedIndex].year = year;
          savingDataObj[selectedIndex].manufacturer = manufacturer;
          savingDataObj[selectedIndex].model = model;
        }
        if (imagePreviewUrl) {
          savingDataObj[selectedIndex]["imagePreviewUrl"] = imagePreviewUrl;
          savingDataObj[selectedIndex]["file"] = file;
        }
        data = {
          productList: savingDataObj
        };
      } else {
        const savingData = this.state.tableData;
        const {
          name,
          price,
          newused,
          mileage,
          availability,
          location,
          primarycolor,
          vin,
          stock,
          title,
          condition,
          year,
          manufacturer,
          model,
          imagePreviewUrl,
          file
        } = this.state;
        var insertData = {
          name: name,
          price: price,
          newused: newused,
          mileage: mileage,
          availability: availability,
          location: location,
          primarycolor: primarycolor,
          vin: vin,
          stock: stock,
          title: title,
          condition: condition,
          year: year,
          manufacturer: manufacturer,
          model: model
          // tabs: tabsAndAccordion
        };
        if (imagePreviewUrl) {
          insertData["imagePreviewUrl"] = imagePreviewUrl;
          insertData["file"] = file;
        }
        savingData.push(insertData);
        data = {
          productList: savingData
        };
      }

      this.props.actions.saveRetailer(data, this.props.UserKey);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        nextProps.List[0]["brands"]
      ) {
        this.setState({
          brands: nextProps.List[0]["brands"]
        });
      }
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        nextProps.List[0]["productList"] &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const productListData = nextProps.List[0]["productList"];
        var result = Object.keys(productListData).map(function(key) {
          return productListData[key];
        });
        this.setState(() => ({
          tableData: result
        }));
      }
      if (nextProps.isSaved) {
        this.saveChanges();
      }
    }
  }
  addAccordion = () => {
    var editData = this.state.editTabData;
    var result = Object.keys(editData.accordions).map(function(key) {
      return editData.accordions[key];
    });
    result.push({ subhead: "", copy: "" });
    editData.accordions = result;
    this.setState(() => ({
      editTabData: editData
    }));
  };
  handelTabTextChange = (key, value) => event => {
    var { editTabData } = this.state;
    editTabData[key] = event.target.value;
    this.setState({ editTabData: editTabData });
  };
  handleChange = (index, key) => event => {
    var { editTabData } = this.state;
    editTabData.accordions[index][key] = event.target.value;
    this.setState({ editTabData: editTabData });
  };
  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({
      [key]: event.target.value,
      required: reqData
    });
  };
  getAccordionArray = accordions => {
    var returnData = [];
    accordions.map(({ subhead, copy }, index) => {
      return returnData.push({
        title: <span>{subhead}</span>,
        content: <p>{copy}</p>
      });
    });
    return returnData;
  };
  editTabDataAndAccordions = index => {
    this.setState({
      editTabIndex: index,
      editTabData: this.state.tabsAndAccordion[index],
      modalTabs: true,
      addNewTab: false
    });
  };
  getTabsArray = () => {
    const { classes } = this.props;
    const { tabsAndAccordion } = this.state;
    var returnData = [];
    tabsAndAccordion.map(({ tabName, accordions }, index) => {
      return returnData.push({
        tabButton: (
          <span>
            {tabName}
            <Button
              simple
              color="success"
              onClick={() => this.editTabDataAndAccordions(index)}
              className={classes.actionButton}
            >
              <CreateIcon className={classes.icon} />
            </Button>
          </span>
        ),
        tabContent: (
          <Accordion
            active={0}
            activeColor="default"
            collapses={this.getAccordionArray(accordions)}
          />
        )
      });
    });
    return returnData;
  };
  handelIsAddNew = () => {
    this.setState(() => ({
      isAddNew: !this.state.isAddNew,
      ...initialState,
      tabsAndAccordion: []
    }));
  };

  handelImageRemove = () => {
    this.setState({
      file: null,
      imagePreviewUrl: null
    });
  };

  handelImageRemoveModal = () => {
    this.setState({
      fileModal: null,
      imagePreviewUrlModal: null
    });
  };
  handelImageChange = file => {
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };
  handelImageChangeModal = file => {
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        fileModal: file,
        imagePreviewUrlModal: reader.result
      });
    };
    reader.readAsDataURL(file);
  };
  saveChanges = () => {
    const { isAddNew } = this.state;
    if (isAddNew) {
      this.handelIsAddNew();
    } else {
      this.setState({
        isAddNew: false,
        openDialog: false
      });
    }
  };
  getYear() {
    return new Date().getFullYear();
  }

  getTableData = () => {
    const { tableData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(
      tableData,
      ({ name, price, manufacturer, imagePreviewUrl, image }, index) => {
        returnData.push([
          <div className={classes.imgContainer} style={{ width: "80px" }}>
            <img
              src={image ? image.secure_url : imagePreviewUrl}
              alt="..."
              className={classes.img}
              style={{ height: "70px", width: "auto" }}
            />
          </div>,
          <span>
            {
              //eslint-disable-next-line
                            <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
                {name}
              </a>
            }
          </span>,
          <span>$ {price}</span>,
          <span>{manufacturer}</span>,
          <span>
            <Button
              color="success"
              className={classes.actionButton}
              onClick={this.handelEdit(index)}
            >
              <CreateIcon className={classes.icon} />
            </Button>
            <Button
              color="danger"
              className={classes.marginRight}
              style={{ padding: "5px" }}
              onClick={() =>
                this.openDeleteModalDialog("openDialog", true, index)
              }
            >
              <ClearIcon
                className={classes.icons}
                style={{ marginRight: "0px" }}
              />
            </Button>
          </span>
        ]);
      }
    );
    return returnData;
  };

  openDeleteModalDialog = (modalName, isOpen, index) => {
    if (isOpen) {
    }
    this.setState({ [modalName]: isOpen, deletedIndex: index });
  };

  renderProductsTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <Dialog
          open={this.state.openDialog}
          disableBackdropClick={true}
          onClose={() => this.openModalDialog("openDialog", false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <LoadingOverlay>
              <DialogContentText>
                Are you sure you want to delete this Product?
              </DialogContentText>
              <DialogActions>
                <Button
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px"
                  }}
                  className={classes.marginRight}
                  simple
                  color="primary"
                  onClick={() => this.handelDelete()}
                >
                  Delete
                </Button>
                <Button
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px"
                  }}
                  className={classes.marginRight}
                  simple
                  onClick={() => this.openModalDialog("openDialog", false)}
                  color="primary"
                  autoFocus
                >
                  Cancel
                </Button>
              </DialogActions>
              {this.props.Deleting && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-12px"
                  }}
                />
              )}
              <Loader loading={this.props.Deleting} text="Deleting.." />
            </LoadingOverlay>
          </DialogContent>
        </Dialog>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.loadingGetData}
              color="success"
              onClick={this.handelIsAddNew}
              className={classes.marginRight}
            >
              <AddIcon className={classes.icons} /> Product
            </Button>
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <p className={classes.cardIconTitle}>Products Table</p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <Table
                  tableHead={["", "Name", "Price", "Manufacturer", ""]}
                  tableData={this.getTableData()}
                  tableShopping
                  customHeadCellClasses={[
                    classes.center,
                    classes.description,
                    classes.description,
                    classes.right,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                  customCellClasses={[
                    classes.tdName,
                    classes.customFont,
                    classes.customFont,
                    classes.tdNumber,
                    classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                    classes.tdNumber
                  ]}
                  customClassesForCells={[1, 2, 3, 4, 5, 6]}
                />
                {(this.props.Loading || this.props.loadingGetData) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.loadingGetData} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
  handelEdit = index => event => {
    var selectedData = this.state.tableData[index];
    this.setState({
      name: selectedData.name,
      price: selectedData.price,
      mileage: selectedData.mileage,
      location: selectedData.location,
      primarycolor: selectedData.primarycolor,
      vin: selectedData.vin,
      stock: selectedData.stock,
      title: selectedData.title,
      condition: selectedData.condition,
      year: selectedData.year,
      manufacturer: selectedData.manufacturer,
      model: selectedData.model,
      imagePreviewUrl: selectedData.imagePreviewUrl,
      copy: selectedData.copy,
      image: selectedData.image,
      selectedIndex: index,
      isAddNew: true
    });
  };

  handelDelete = () => {
    var { tableData, deletedIndex } = this.state;
    var productData = [...tableData];
    productData.splice(deletedIndex, 1);
    var data = {
      productList: productData
    };
    this.props.actions.deleteRetailer(data, this.props.UserKey);
  };

  openModalDialog = (modalName, isOpen) => {
    this.setState(() => ({
      [modalName]: isOpen
    }));
  };
  resetAddTabs = () => {
    this.setState({
      editTabData: {
        tabName: "",
        accordions: []
      },
      addNewTab: true,
      editTabIndex: -1
    });
  };
  saveTabsAndAccordion = () => {
    var { tabsAndAccordion, editTabData, addNewTab, editTabIndex } = this.state;
    if (addNewTab) {
      tabsAndAccordion.push({
        ...editTabData
      });
    } else if (editTabIndex >= 0) {
      tabsAndAccordion[editTabIndex] = editTabData;
    }
    this.setState({
      tabsAndAccordion: tabsAndAccordion,
      modalTabs: false
    });
    this.resetAddTabs();
  };
  renderAddProduct = () => {
    const { classes } = this.props;
    const {
      name,
      price,
      newused,
      mileage,
      availability,
      location,
      primarycolor,
      vin,
      stock,
      title,
      model,
      required,
      image,
      editTabData,
      modalTabs,
      yearArray
    } = this.state;
    return (
      <GridContainer>
        <Dialog
          open={modalTabs}
          fullWidth={true}
          maxWidth={"md"}
          disableBackdropClick={true}
          // onClose={() => this.openModalDialog("modalTabs", false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Tabs and Accordions"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <CustomInput
                labelText="Tab Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  value: editTabData.tabName,
                  onChange: this.handelTabTextChange("tabName", "")
                }}
                id={"tabsname_"}
              />
              <br />
              <br />
              {editTabData.accordions && editTabData.accordions.length > 0 && (
                <legend
                  style={{
                    marginBottom: "0px"
                  }}
                >
                  Accordions
                </legend>
              )}
              {_.map(editTabData.accordions, ({ subhead, copy }, index) => {
                return (
                  <div key={index}>
                    <CustomInput
                      labelText="Title"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: subhead,
                        onChange: this.handleChange(index, "subhead")
                      }}
                      id={"title_" + index}
                    />
                    <CustomInput
                      labelText="Copy"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: copy,
                        onChange: this.handleChange(index, "copy")
                      }}
                      id={"copy_" + index}
                    />
                    <br />
                    <br />
                  </div>
                );
              })}
            </DialogContentText>
            <DialogActions>
              <Button
                color="success"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                onClick={this.addAccordion}
                className={classes.marginRight}
              >
                <AddIcon className={classes.icons} /> Add Accordion
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.saveTabsAndAccordion()}
                color="primary"
              >
                {this.state.addNewTab ? "Add" : "Update"}
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => {
                  this.openModalDialog("modalTabs", false);
                  this.resetAddTabs();
                }}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.modalProductImage}
          disableBackdropClick={true}
          onClose={() => this.openModalDialog("modalProductImage", false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Add Product Image"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ImageUpload
                addButtonProps={{
                  round: true
                }}
                changeButtonProps={{
                  color: "rose",
                  round: true
                }}
                removeButtonProps={{
                  color: "danger",
                  round: true
                }}
                imgUrl={null}
                onimageRemove={this.handelImageRemoveModal}
                onChange={this.handelImageChange}
              />
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.saveImage()}
                color="primary"
              >
                {this.state.addNew ? "Add" : "Update"}
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.openModalDialog("modalProductImage", false)}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Product Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <GridContainer>
                    <GridItem md={6}>
                      <CustomInput
                        success={required.price === "success"}
                        error={required.price === "error"}
                        labelText="Product Price *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: price,
                          onChange: this.handleTextChange("price", "")
                        }}
                        id={"price_"}
                      />
                      <br />
                      <br />
                      <br />
                      <legend>Product Image</legend>
                      <ImageUpload
                        addButtonProps={{
                          round: true
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true
                        }}
                        imgUrl={image ? image.secure_url : null}
                        onimageRemove={this.handelImageRemove}
                        onChange={this.handelImageChange}
                      />
                      <br />
                      {/* <Button onClick={()=>this.openModalDialog("modalProductImage", true)}>
                                                <AddIcon className={classes.icons} />
                                                ADD More Prodcut Images
                                            </Button> */}
                    </GridItem>
                    <GridItem md={6}>
                      <legend
                        style={{
                          marginTop: "10px",
                          color: "#AAAAAA",
                          fontSize: "14px",
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          fontWeight: "400",
                          lineHeight: "1.42857",
                          marginBottom: "7px"
                        }}
                      >
                        New / Used
                      </legend>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <RadioGroup
                          aria-label="newused"
                          name="newused"
                          className={classes.group}
                          style={{
                            display: "block",
                            height: "35px"
                          }}
                          value={newused}
                          onChange={this.handleTextChange("newused", "")}
                        >
                          <FormControlLabel
                            value="New"
                            control={
                              <Radio
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="New"
                          />
                          <FormControlLabel
                            value="Used"
                            control={
                              <Radio
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="Used"
                          />
                        </RadioGroup>
                      </div>
                      <CustomInput
                        labelText="Mileage"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: mileage,
                          onChange: this.handleTextChange("mileage", "")
                        }}
                        id={"mileage_"}
                      />
                      <legend
                        style={{
                          marginTop: "10px",
                          color: "#AAAAAA",
                          fontSize: "14px",
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          fontWeight: "400",
                          lineHeight: "1.42857",
                          marginBottom: "7px"
                        }}
                      >
                        Availability
                      </legend>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <RadioGroup
                          aria-label="Availability"
                          name="Availability"
                          className={classes.group}
                          style={{
                            display: "block",
                            height: "35px"
                          }}
                          value={availability}
                          onChange={this.handleTextChange("availability", "")}
                        >
                          <FormControlLabel
                            value="In-Stock"
                            control={
                              <Radio
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="In-Stock"
                          />
                          <FormControlLabel
                            value="Out-of-Stock"
                            control={
                              <Radio
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="Out-of-Stock"
                          />
                        </RadioGroup>
                      </div>
                      <CustomInput
                        labelText="Location"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: location,
                          onChange: this.handleTextChange("location", "")
                        }}
                        id={"location_"}
                      />
                      <CustomInput
                        labelText="Primary Color"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: primarycolor,
                          onChange: this.handleTextChange("primarycolor", "")
                        }}
                        id={"primarycolor_"}
                      />
                      <CustomInput
                        labelText="VIN #"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: vin,
                          onChange: this.handleTextChange("vin", "")
                        }}
                        id={"vin_"}
                      />
                      <CustomInput
                        labelText="Stock #"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: stock,
                          onChange: this.handleTextChange("stock", "")
                        }}
                        id={"stock_"}
                      />
                      <CustomInput
                        labelText="Title"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: title,
                          onChange: this.handleTextChange("title", "")
                        }}
                        id={"title_"}
                      />
                      {/* <CustomInput
                                                labelText="Condition"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "text",
                                                    value: condition,
                                                    onChange: this.handleTextChange('condition', "")
                                                }}
                                                id={"condition_"}
                                            /> */}
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ marginTop: "16px" }}
                      >
                        <InputLabel
                          htmlFor="condition-select"
                          className={classes.selectLabel}
                          style={{ fontSize: "11px" }}
                        >
                          Condition
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.condition}
                          onChange={this.handleCondition}
                          inputProps={{
                            name: "condition",
                            id: "condition-select"
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Condition
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="Excellent"
                          >
                            Excellent
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="Good"
                          >
                            Good
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="Bad"
                          >
                            Bad
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {/* <CustomInput
                                                labelText="Year"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "text",
                                                    value: year,
                                                    onChange: this.handleTextChange('year', "")
                                                }}
                                                id={"year_"}
                                            /> */}
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ marginTop: "14px" }}
                      >
                        <InputLabel
                          htmlFor="condition-select"
                          className={classes.selectLabel}
                          style={{ fontSize: "14px" }}
                        >
                          Year
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.year}
                          onChange={this.handleCondition}
                          inputProps={{
                            name: "year",
                            id: "year-select"
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Year
                          </MenuItem>
                          {_.map(yearArray, (models, index) => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={models}
                              >
                                {models}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ marginTop: "14px" }}
                      >
                        <InputLabel
                          htmlFor="condition-select"
                          className={classes.selectLabel}
                          style={{ fontSize: "14px" }}
                        >
                          Manufacturer
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.manufacturer}
                          onChange={this.handleCondition}
                          inputProps={{
                            name: "manufacturer",
                            id: "manufacturer-select"
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Manufacturer
                          </MenuItem>
                          {_.map(this.state.brands, ({ name }, index) => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={name}
                              >
                                {name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      <CustomInput
                        labelText="Model"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: model,
                          onChange: this.handleTextChange("model", "")
                        }}
                        id={"model_"}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <br />
                      <br />
                      <Button
                        color="rose"
                        className={classes.updateProfileButton}
                        onClick={() => this.openModalDialog("modalTabs", true)}
                      >
                        <AddIcon className={classes.icons} />
                        Add Tab
                      </Button>
                    </GridItem>
                    <GridItem md={12} sm={12}>
                      <NavPills color="dark" tabs={this.getTabsArray()} />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <br />
                  <br />
                  <Button color="info" onClick={this.saveReatiler}>
                    Save Changes
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {(this.props.Loading || this.props.loadingGetData) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    return this.state.isAddNew
      ? this.renderAddProduct()
      : this.renderProductsTable();
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.retailerState.list,
  Loading: state.retailerState.loading,
  ListError: state.retailerState.error,
  loadingGetData: state.retailerState.loadingData,
  isSaved: state.retailerState.isSaved,
  UserKey: state.authState.user.Token,
  Deleting: state.retailerState.deletingData
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...dashboardStyle,
    ...userProfileStyles,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductPage);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
