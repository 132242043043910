import React from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import _ from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Slide from "@material-ui/core/Slide";
import Select from "@material-ui/core/Select";
import CloneSiteComponent from "./Component/CloneSiteComponent";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

let AggridApi;

const role_color = {
  BR: "#4caf50",
  WS: "#948d8d",
  RE: "'#00acc1''"
};
function getRole(role) {
  if (role == "RE") {
    return "Retailer";
  } else if (role == "BR") {
    return "Brand";
  } else if (role == "WS") {
    return "WholeSaler";
  }
}

const roles = [
  {
    name: "Retailer",
    key: "RE"
  },
  {
    name: "Brand",
    key: "BR"
  },
  {
    name: "Wholesaler",
    key: "WS"
  }
];

class CMSPagesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          sortable: true,
          filter: true,
          width: 50,
          checkboxSelection: true
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          filter: true,
          width: 180
        },
        {
          headerName: "Type",
          field: "role",
          width: 130,
          resizable: true,
          sortable: true,
          filter: false,
          cellRenderer: function (params) {
            return (
              '<span class="partner-role" style=background:' +
              role_color[params.value] +
              ">" +
              getRole(params.value) +
              "</span>"
            );
          }
        }
      ],
      rowData: [],
      rows: [],
      assignList: [],
      defaultColDef: {
        editable: true
      },
      autoGroupColumnDef: {
        minWidth: 200
      },
      partnerIdList: [],
      role: "RE"
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (nextProps.List && nextProps.List.length) {
          const classData = _.clone(nextProps.List); //.filter(a => a.role == "RE")
          let filteList = classData.filter(a => a.role == "RE");
          this.setState(() => ({
            rows: filteList
          }));

          if (nextProps.AssignList && nextProps.AssignList.length > 0) {
            this.setState(
              {
                assignList: nextProps.AssignList
              },
              () => {
                AggridApi.forEachNode(function (node) {
                  if (nextProps.AssignList.indexOf(node.data.id) > -1) {
                    node.setSelected(true);
                  } else {
                    node.setSelected(false);
                  }
                });
              }
            );
          } else {
            if (nextProps.menu && AggridApi) {
              AggridApi.forEachNode(function (node) {
                node.setSelected(false);
              });
            }
          }

          if (AggridApi) AggridApi.sizeColumnsToFit();
        }

        if (nextProps.Error && nextProps.Error.trim() !== "") {
          this.setState(() => ({
            error: nextProps.Error,
            isAddNew: true
          }));
        }
      }
      if (nextProps.isSaved) {
        this.props.onClose();
      }
    }
  }

  componentDidMount() {
    this.props.actions.getPartnersList();
    // this.props.actions.getMenuMasterList(this.props.UserId);

    // this.props.actions.getMenuMasterList();

    // this.props.actions.getCMSPages(null, this.props.partnerId, true);
    // this.props.actions.getAssignPartnerPages(this.props.partnerId);
  }

  closeDialog = () => {
    this.props.onClose();
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    AggridApi = _.clone(this.gridApi, true);
  };

  handelIsAddNew() {
    this.props.onClose();
  }
  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    var length = this.state.rows.length;
    var i = 0;
    const componentThis = this;
    for (i; i < length; i++) {
      componentThis.state.rows.pop();
    }
    selectedRows.forEach(function (selectedRow, index) {
      componentThis.state.rows.push(selectedRow.id);
    });
  }
  saveHandler = () => {
    const { assignList } = this.state;
    let selectedRows = this.gridApi.getSelectedRows();
    let pageId = _.map(selectedRows, "id");
    const componentThis = this.props;

    let pages = _.difference(pageId, assignList);

    componentThis.actions.copyPagePartner(pageId, componentThis.partnerId);
  };

  handelCloneSite = id => {

    //this.props.actions.getPartnersList();
    let selectedRows = this.gridApi.getSelectedRows();
    let pageId = _.map(selectedRows, "id");
    if (pageId.length > 0) {
      this.setState({
        openCopyDilog: true,
        partnerIdList: pageId
      });
    }
  };
  handelCloneSiteDilogClose = () => {
    this.setState({
      openCopyDilog: false
    });
  };

  handleToggleStatus = curObj => event => {
    this.setState({
      addClassData: {
        is_active: event.target.checked
      }
    });
  };

  cloneSiteDilog = () => {
    const { classes } = this.props;
    const { partnerIdList } = this.state;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.openCopyDilog}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.onHanndelClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle} style={{ width: "500px" }}>
            Clone Sites{" "}
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <DialogContentText>
            <CloneSiteComponent
              open={this.state.openCopyDilog}
              onClose={this.handelCloneSiteDilogClose}
              partnerList={this.state.tableData}
              partnerId={partnerIdList}
              role={this.state.role}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  handleTextChange = (key, type) => event => {
    let ptList = _.clone(this.props.List);
    //this.props.List.filter(a => a.role == event.target.value)
    let filterList = ptList.filter(a => a.role == event.target.value);
    this.setState({
      rows: filterList,
      role: event.target.value
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.cloneSiteDilog()}

        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <div
                style={{
                  padding: "0 0 5px 0"
                }}
              >
                <Select
                  variant="outlined"
                  style={{ width: "20%" }}
                  value={this.state.role ? this.state.role : null}
                  classes={{
                    root: "select-control-root"
                  }}
                  onChange={this.handleTextChange("role", "")}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Partner Type *
                  </MenuItem>
                  {_.map(roles, ({ key, name }, index) => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={key}
                        key={index + "_" + key}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>

                <div className="add-new-btn">
                  <Button
                    color="success"
                    onClick={() => this.handelCloneSite()}
                    className="aggrid-new-button"
                  >
                    Clone
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <div
                  className="ag-theme-material"
                  style={{
                    height: "440px",
                    width: "100%"
                  }}
                >
                  <AgGridReact
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    enableRangeSelection={true}
                    animateRows={true}
                    floatingFilter={true}
                    suppressAggFuncInHeader={true}
                    onGridReady={this.onGridReady}
                    rowData={this.state.rows}
                    rowMultiSelectWithClick={true}
                    rowSelection={"multiple"}
                  />
                  <Loader loading={this.props.copyloading} text="Saving.." />
                  {this.props.cloneSiteList && (
                    <Alert severity="success">{this.props.cloneSiteList}</Alert>
                  )}
                </div>
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserId: state.authState.user.user,
  isMapSaved: state.menuMasterReducer.save_mapping_menu_master.mapping_saved,

  // Loading: state.cmsReducer.get_CMS_Pages.loading,
  // LoadingError: state.cmsReducer.get_CMS_Pages.error,
  // List: state.cmsReducer.get_CMS_Pages.list,

  isSaved: state.cmsReducer.copy_cms_page.isSaved,
  copyloading: state.cmsReducer.copy_cms_page.loading,
  error: state.cmsReducer.copy_cms_page.error,
  SuccessMessage: state.cmsReducer.copy_cms_page.msg,

  AssignList: state.cmsReducer.assign_partner_page.list,

  UserId: state.authState.user.user,

  cloneSiteList: state.cmsReducer.cloneSite.list,
  cloneSiteisSaved: state.cmsReducer.cloneSite.isSaved,
  cloneSiteLoading: state.cmsReducer.cloneSite.loading,
  cloneSiteError: state.cmsReducer.cloneSite.error,

  List: state.cmsReducer.retailer_list.list,
  Loading: state.cmsReducer.retailer_list.loading,
  ListError: state.cmsReducer.retailer_list.error
});

CMSPagesComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  entityData: PropTypes.object,
  menu: PropTypes.array,
  selectedClassIndexes: PropTypes.array.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CMSPagesComponent);

// export default menuComponent;
