import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  map_offer: {
    saving: false,
    saved: false,
    error: null
  },
  create_offer: {
    saving: false,
    saved: false,
    error: null
  },
  get_offers: {
    loading: false,
    list: [],
    error: null,
    pagination: {
      page: "1",
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  },
  get_offers_type: {
    loading: false,
    list: [],
    error: null
  },
  offer: {
    data: null,
    loading: false,
    error: null
  }
};

const offersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.MAP_OFFER_PRODUCT_COMPLETED:
      return {
        ...state,
        map_offer: {
          saving: false,
          saved: false,
          error: null
        }
      };

    case actionTypes.MAP_OFFER_PRODUCT_REQUEST:
      return {
        ...state,
        map_offer: {
          saving: true,
          saved: false,
          error: null
        }
      };

    case actionTypes.MAP_OFFER_PRODUCT_SUCCESS:
      return {
        ...state,
        map_offer: {
          saving: false,
          saved: true,
          error: null
        }
      };
    case actionTypes.REMOVE_SAVED_DATA:
      return {
        ...state,
        map_offer: {
          saving: false,
          saved: false,
          error: null
        }
      };

    case actionTypes.MAP_OFFER_PRODUCT_FAILURE:
      return {
        ...state,
        map_offer: {
          saving: false,
          saved: false,
          error: action.payload
        }
      };
    case actionTypes.GET_OFFERS_TYPE_REQUEST:
      return {
        ...state,
        get_offers_type: {
          loading: true,
          list: [],
          error: null
        }
      };
    case actionTypes.GET_OFFERS_TYPE_FAILURE:
      return {
        ...state,
        get_offers_type: {
          loading: false,
          list: [],
          error: action.payload
        }
      };
    case actionTypes.GET_OFFERS_TYPE_SUCCESS:
      return {
        ...state,
        get_offers_type: {
          loading: false,
          list: action.payload,
          error: null
        }
      };
    case actionTypes.GET_OFFERS_REQUEST:
      return {
        ...state,
        get_offers: {
          loading: true,
          list: [],
          error: null,
          pagination: {
            page: "1",
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        },
        map_offer: {
          saving: false,
          saved: false,
          error: null
        },
        create_offer: {
          ...state.create_offer,
          saved: false
        }
      };
    case actionTypes.GET_OFFERS_FAILURE:
      return {
        ...state,
        get_offers: {
          loading: false,
          list: [],
          error: action.payload
        }
      };
    case actionTypes.GET_OFFERS_SUCCESS:
      let pagination = _.clone(state.get_offers.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        get_offers: {
          loading: false,
          list: sdata,
          error: null,
          pagination: {
            ...pagination
          }
        }
      };
    case actionTypes.CREATE_OFFERS_REQUEST:
      return {
        ...state,
        create_offer: {
          saving: true,
          saved: false,
          error: null
        }
      };

    case actionTypes.CREATE_OFFERS_SUCCESS:
      // var data = state.get_offers.list;
      // var index = data.findIndex(p => p.id === action.payload.id);
      // if (index > -1) {
      //     data[index] = action.payload;
      // } else {
      //     data.splice(0, 0, action.payload);
      // }

      return {
        ...state,
        create_offer: {
          saving: false,
          saved: true,
          error: null
        }
        // get_offers: {
        //     list: data
        // }
      };
    case actionTypes.CREATE_OFFERS_FAILURE:
      return {
        ...state,
        create_offer: {
          saving: false,
          saved: false,
          error: action.payload
        }
      };

    case actionTypes.GET_OFFER_BYID_REQUEST:
      return {
        ...state,
        offer: {
          data: null,
          loading: false,
          error: null
        },
        create_offer: {
          ...state.create_offer,
          saved: false
        }
      };

    case actionTypes.GET_OFFER_BYID_SUCCESS:
      return {
        ...state,
        offer: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    case actionTypes.GET_OFFER_BYID_FAILURE:
      return {
        ...state,
        offer: {
          data: null,
          loading: false,
          error: action.payload
        }
      };

    default:
      return state;
  }
};

export default offersReducer;
