export const LANDING = "/";
export const ROUTEMIDDLEWARE = "/landing";
export const SSOMIDDLEWARE = "/validate";
export const DASHBOARD = "/dashboard";
export const ABOUTUS = "/about-us";
export const FAQ = "/faq";
export const LOCATION = "/location";
export const STAFF = "/staff";
export const LOGIN = "/login";
export const AdminLayout = "/admin";
export const AuthLayout = "/auth";
export const CREATEWEBSITE = "/website";
export const LISTOFWEBSITE = "/websites-list";
export const SERVICES = "/services";
export const TERMSANDCONDITIONS = "/terms";
export const PRIVACYPOLICY = "/privacypolicy";
export const CONTACTUS = "/contactus";
export const SEGMENTS = "/segments";
export const PRODUCTPAGE = "/productPage";
export const BRANDS = "/brands";
export const NEWMODELAVAILABLE = "/newModelAvailable";
export const NEWMODELTYPE = "/newModelType";
export const NEWMODELTYPEMODEL = "/modelTypeModel";
export const SEGMENTSDETAILS = "/segmentDetails";
export const BRANDDETAILS = "/brandDetails";
export const SEGMENTPRODUCTDETAILS = "/segmentProductDetails";
export const PRODDUCTASSIGNMENTS = "/productAssignment";
export const BRANDASSIGNMENTS = "/brandAssignment";
export const CREATEBRANDS = "/createBrands";
export const CREATEPRODUCTS = "/createProducts";
export const CREATEATTRIBUTES = "/createAttributes";
export const CREATE_PRODUCT_TEMPLATE = "/createTemplate";
export const PRODUCTATTRIBUTES = "/productAttributes";
export const UNIVERSALATTRIBUTES = "/universalAttributes";
export const ATTRIBUTE_GROUPS = "/attributeGroups";
export const TEMPLATE_ATTRIBUTE_VALUE = "/templateAtrribute";
export const CLASS = "/class";
export const WHOLESALER = "/createwholesaler";
export const BUSINESSTYPE = "/businesstype";
export const INDUSTRIESPAGE = "/industriesPage";
export const PRODUCTTEMPLATECOMPONENT = "/producttemplates";
export const gridcomponent = "/grid";
export const BRANDCOMPONENT = "/brandcomponent";
export const PRODUCTTEMPLATESWHOLESALESALERCOMPONENT =
  "/producttemplateswholesalesalercomponent";
export const RBMAPCOMPONENT = "/RetailerBrandmapComponent";
export const RETAILERPRODUCTTEMPLATEMAPING = "/retailerproducttemplatemaping";
export const PRODUCTTEMPLATESRETAILERCOMPONENT =
  "/ProductTemplateRetailerComponent";
export const RETAILERWHOLESALERCOMPONENT =
  "/RetailerWholesalerMappingComponent";
export const TAGSTYPE = "/tagType";
export const TAGS = "/tags";
export const MENUMASTER = "/menumaster";
export const PRODUCTCMS = "/productcms";
export const PARTNERIMPORT = "/partner-import";
export const BRANDPRODUCTIMPORT = "/brand-product-import";
export const PRODUCTIMPORT = "/product-import";
export const CMS = "/cms_content";
export const SERVICE = "/service";
export const Message = "/message";
export const Offers = "/offers";
export const NOTIFICATIONTYPE = "/notificationtype";
export const CMSCOMPONENT = "/cms/components";
export const CMSPAGEBUILDER = "/cms/pages";
export const CMSMETADATA = "/cms/package";
export const PARTNERBANNERS = "/page-banner";
export const OFFERTYPE = "/offertype";
export const SHIPPINGMETHODS = "/shippingmethods";
export const ADMANAGER = "/ad-manager";
export const ORDER = "/order";
export const ADCHANNEL = "/ad-channel";
export const ASSETMANAGER = "/asset-manager";
export const PAYMENTMETHOD = "/payment-method";
export const ORDERDETAILS = "/orderdetails";
export const TEMPLATE = "/template";
export const CHANGEPASSWORDPAGE = "/changepasswordpage";
export const ORDERDETAILREPORT = "/orderreport";
export const PAYMENTDETAILREPORT = "/paymentreport";
export const SETTLEMENTREPORT = "/settlement-report";
export const SETTINGS = "/settings";
export const USERGROUP = "/usergroup";
export const USERS = "/users";
export const STOCKLOCATION = "/stock-location";
export const STOCKMANAGEMENT = "/stock-management";
export const NETSTOCK = "/net-stock";
export const PRODUCTTEMPLATE = "/product-wizard";
export const PRODUCTTEMPLATEPATH = "/product-template";
export const CATEGORY = "/category";
export const WHOLESALORHIERARCHY = "/wholesalors";
export const CLASSPRODUCTHEIRARCHY = "/class-product";
export const CLASSPRODUCTTEMPLATEHEIRARCHY = "/class-product-template";
export const PARTNERMAPPING = "/partner-mapping";
export const PARTNERSERVICE = "/partner-service";
export const CUSTOMPAGES = "/pages";
export const CMSHEADER = "/cms-header";
export const CMSFOOTER = "/cms-footer";
export const PARTNERCAMPAIGN = "/active-campaigns";
export const PARTNERBRANDCAMPAIGN = "/brand-setting";
export const CLONEPARTNERS = "/clone-partners";
export const VARIANTS = "/variants";
export const PRODUCTVARIANTS = "/product-variant";
export const PARTNERREPORT = "/partner-report";
export const EVENTS = "/events";
export const EVENTTYPE = "/event-type";
export const BRANDCATEGORYPRODUCT = "/brand-product"
