import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Slide from "@material-ui/core/Slide";
import AgGrid from "../../../components/AgGrid/AgGrid.jsx";
import Edit from "@material-ui/icons/Edit";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function dateFormatter(params) {
  if (params.value) {
    return moment(params.value).format("MM/DD/YYYY");
  } else {
    return "";
  }
}

class CampaignActionComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  invokeParentMethod() {
    const { data } = this.props;

    return (
      <div
        style={{
          lineHeight: "60px"
        }}
      >
        {
          <span onClick={() => this.handleEdit(data.id)} title="Edit">
            <Edit className="grid_action_btn" />
          </span>
        }
      </div>
    );
  }

  handleEdit(id) {
    this.props.context.componentParent.handelEdit(id);
  }

  handleDelete(id) {
    this.props.context.componentParent.handelDelete(id);
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}

class PartnerCampaignList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      componentList: [],
      columnDefs: [
        {
          headerName: "Brand",
          field: "brand_name",
          width: 150,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Campaign Name",
          field: "name",
          width: 180,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Availability",
          // field: "status",
          width: 150,
          resizable: true,
          sortable: true,
          valueGetter: function(params) {
            return params.data.is_brand_allowed ? "Available" : "Unavailable";
          }
        },
        {
          headerName: "Start date",
          field: "start_date",
          width: 140,
          resizable: true,
          sortable: true,
          valueFormatter: dateFormatter
        },
        {
          headerName: "End Date",
          field: "end_date",
          width: 140,
          resizable: true,
          sortable: true,
          valueFormatter: dateFormatter
        },
        {
          headerName: "Status",
          // field: "status",
          width: 120,
          resizable: true,
          sortable: true,
          valueGetter: function(params) {
            return params.data.partner_approved ? "Approved" : "Rejected";
          }
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 120
        }
      ]
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.List) {
      this.setState({
        componentList: nextProps.List,
        pagination: nextProps.pagination
      });
    }
  }

  componentDidMount() {
    this.props.actions.getPartnersCampaign(
      this.props.partnerid,
      null,
      this.state.pagination.page
    );
  }

  handelEdit = id => {
    this.props.handleEdit(id);
  };

  renderNotificationTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  View Active Campaigns below.
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.componentList}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                AgGridActionButton={CampaignActionComponents}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                search={this.searchText}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getPartnersCampaign(
      this.props.partnerid,
      null,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getPartnersCampaign(
          this.props.partnerid,
          null,
          page,
          this.state.searchtext
        );
      }
    );
  }

  render() {
    return this.renderNotificationTable();
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserId: state.authState.user.user,
  partnerid: state.authState.user.partner_id,
  List: state.campignManagementReducer.partnerscampaign.list,
  Loading: state.campignManagementReducer.partnerscampaign.loading,
  ListError: state.campignManagementReducer.partnerscampaign.error,
  pagination: state.campignManagementReducer.partnerscampaign.pagination
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PartnerCampaignList);
