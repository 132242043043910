import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { compose } from 'recompose';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Datetime from "react-datetime";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox, FormLabel, FormControl, InputLabel, CircularProgress, Select, MenuItem } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// import { TimePicker } from "../../../component/inputs/datetime"
import ImageUpload from "../../components/ImageUpload.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { ActionCreators } from "../../../redux/actions";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const INITIAL_STATE = {
    id: '',
    name: '',
    description: '',
    location: '',
    event_type_id: 0,
    start_date_time: moment(),
    end_date_time: moment(),
    images: [{
        id: "",
        url: ""
    }],
    is_active: false
}

const imgobj = {
    id: '',
    url: '',
}

class CreateEvent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addEvent: {
                ...INITIAL_STATE,
                partnerid: this.props.partnerid,
            },
            startdatetoggle: false,
            enddatetoggle: false,
        }
        this.startdatetoggle = this.startdatetoggle.bind(this);
        this.enddatetoggle = this.enddatetoggle.bind(this);
        this.saveEvent = this.saveEvent.bind(this);
    }

    componentDidMount() {
        let isActive = true
        this.props.actions.getEventTypeAction(null, isActive)
        if (this.props.editId) {
            this.props.actions.getEventAction(this.props.partnerid, this.props.editId)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isImageDeleted) {
            const { images } = this.state.addEvent;
            let index = images.findIndex(x => x.id === nextProps.selectedImageId);
            if (index > -1) {
                images.splice(index, 1);
            }
            this.setState(
                {
                    addEvent: {
                        ...this.state.addEvent,
                        images: images
                    }
                },
                () => {
                    this.props.actions.denyRemoveImageAction();
                }
            );
        }
        if (nextProps.events && !_.isEqual(this.props.events, nextProps.events) && this.props.editId) {
            let events = {
                ...nextProps.events
            }
            events.start_date_time = moment(events.start_date)
            events.end_date_time = moment(events.end_date)
            events.partnerid = this.props.partnerid
            let img = { ...imgobj }
            events.images.push(img)
            this.setState({
                addEvent: {
                    ...events,
                },

            })
        }
    }

    handleChange = (key) => event => {
        let val = ''
        if (event.type === 'click') {
            if (key === 'event_type_id') {
                val = event.target.value
            } else {
                val = event.target.checked
            }
        } else {
            val = event.target.value
        }
        this.setState({
            ...this.state,
            addEvent: {
                ...this.state.addEvent,
                [key]: val
            }
        })
    }

    startdatetoggle() {
        let startdatetoggle = !this.state.startdatetoggle;
        this.setState({
            startdatetoggle: startdatetoggle
        });
    }

    enddatetoggle() {
        let enddatetoggle = !this.state.enddatetoggle;
        this.setState({
            enddatetoggle: enddatetoggle
        });
    }

    handleDateChange(key, event) {
        this.setState({
            addEvent: {
                ...this.state.addEvent,
                [key]: event
            },
            startdatetoggle: false,
            enddatetoggle: false
        });
    }

    convertDateString(date) {
        return moment(date).format("MM/DD/YYYYTHH:mm:ssa")
    }

    handelImageChange = (file, index) => {
        var eventImages = this.state.addEvent.images;
        if (file instanceof Blob) {
            if (index > -1) {
                let clonnedObj = _.clone(eventImages[index]);
                clonnedObj.url = file;
                clonnedObj.file_size = file.size;
                clonnedObj.original_file_name = file.name;
                eventImages.splice(index, 0, clonnedObj);
            }
            this.setState({
                addEvent: {
                    ...this.state.addEvent,
                    images: [...eventImages]
                }
            });
        } else {
            if (index > -1) {
                let clonnedObj = _.clone(eventImages[index]);
                clonnedObj.url = file.url;
                if (file.is_asset) {
                    clonnedObj.asset_id = file.id;
                }
                clonnedObj.file_size = file.file_size;
                clonnedObj.original_file_name = file.original_file_name;
                eventImages.splice(index, 0, clonnedObj);
            }
            this.setState({
                ...this.state,
                addEvent: {
                    ...this.state.addEvent,
                    images: [...eventImages]
                }
            });
        }
    };

    handelImageRemove = index => {
        let { images } = this.state.addEvent;
        let selectedImage = images[index];

        if (selectedImage instanceof Object && selectedImage.id !== "") {
            this.props.actions.removeImageAction(selectedImage.id);
        } else {
            images.splice(index, 1);
            this.setState({
                addEvent: {
                    ...this.state.addEvent,
                    images: images
                }
            });
        }
    };

    saveEvent() {
        let form = _.cloneDeep(this.state.addEvent)
        if (form.start_date_time.isAfter(form.end_date_time)) {
            this.props.handleError("Start Date can't be after End Date")
            return
        }
        form.partnerid = this.props.partnerid
        var files = [];
        if (form.images && form.images.length > 0) {
            for (var i in form.images) {
                if (form.images[i] && form.images[i].url instanceof Blob) {
                    files.push(form.images[i].url);
                }
            }
        }
        this.props.save(form, files)
    }

    closeCreateModal = () => {
        this.props.close()
    }

    render() {
        const { classes } = this.props;
        const { name, description, location, event_type_id, start_date_time, end_date_time, images, is_active } = this.state.addEvent;
        return (
            <form>
                <LoadingOverlay>
                    <CustomInput
                        labelText="Name"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            type: "text",
                            value: name,
                            onChange: this.handleChange("name")
                        }}
                    />
                    <CustomInput
                        labelText="Description"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            type: "text",
                            value: description,
                            onChange: this.handleChange("description")
                        }}
                    />
                    <CustomInput
                        labelText="Location"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            type: "text",
                            value: location,
                            onChange: this.handleChange("location")
                        }}
                    />
                    <FormControl fullWidth style={customInputStyle.formControl}>
                        <InputLabel
                            htmlFor="simple-select"
                            className={classes.label + " "}
                            labelText="Event Type"
                        >
                            Event Type
                        </InputLabel>
                        <Select
                            style={{ marginTop: "0px" }}
                            value={event_type_id ? event_type_id : 0}
                            onChange={this.handleChange("event_type_id")}
                        >
                            <MenuItem
                                classes={{
                                    root: classes.selectMenuItem
                                }}
                            >
                                Select Event Type
                            </MenuItem>
                            {_.map(
                                this.props.eventypes,
                                ({ id, name }, index) => {
                                    return (
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={id}
                                            key={index + "_" + id}
                                        >
                                            {name}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel className={classes.label + " "}>
                            Start DateTime
                        </InputLabel>
                        <div style={{ marginTop: "40px" }}>
                            <Datetime
                                open={this.state.startdatetoggle}
                                inputProps={{
                                    placeholder: "Start Date",
                                    value: this.convertDateString(start_date_time),
                                    onClick: this.startdatetoggle
                                }}
                                value={start_date_time}
                                onChange={(e) => this.handleDateChange('start_date_time', e)}
                            />
                        </div>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel className={classes.label + " "}>
                            End DateTime
                        </InputLabel>
                        <div style={{ marginTop: "40px" }}>
                            <Datetime
                                open={this.state.enddatetoggle}
                                inputProps={{
                                    placeholder: "End Date",
                                    value: this.convertDateString(end_date_time),
                                    onClick: this.enddatetoggle
                                }}
                                value={end_date_time}
                                onChange={(e) => this.handleDateChange('end_date_time', e)}
                            />
                        </div>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                tabIndex={-1}
                                checked={is_active}
                                onClick={this.handleChange('is_active')}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                }}

                            />
                        }
                        classes={{ label: classes.label }}
                        label="Active"
                    />
                    <div>
                        {_.map(images, (model, index) => {
                            return (
                                <ImageUpload
                                    addButtonProps={{
                                        round: true
                                    }}
                                    changeButtonProps={{
                                        color: "rose",
                                        round: true
                                    }}
                                    removeButtonProps={{
                                        color: "danger",
                                        round: true
                                    }}
                                    imgUrl={
                                        model instanceof Object
                                            ? model instanceof Blob
                                                ? model
                                                : model.url
                                            : model
                                    }
                                    onimageRemove={() => this.handelImageRemove(index)}
                                    onChange={file => this.handelImageChange(file, index)}
                                />
                            );
                        })}
                    </div>
                    {this.props.ListError && this.props.ListError.trim() !== "" && (
                        <div>
                            <FormLabel className={classes.labelLeftHorizontal}>
                                <code>{this.state.error}</code>
                            </FormLabel>
                        </div>
                    )}

                    <br />
                    <Button color="info" onClick={this.saveEvent}>
                        {this.state.isEdit ? "Update Changes" : "Save Changes"}
                    </Button>
                    <Button onClick={this.closeCreateModal}>Cancel</Button>
                    {(this.props.isSaving || this.props.loading) && (
                        <CircularProgress
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-25px",
                                marginLeft: "-12px"
                            }}
                        />
                    )}
                    <Loader loading={(this.props.isSaving || this.props.loading)} text="Saving.." />
                </LoadingOverlay>
            </form>
        );
    }
}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
    partnerid: state.authState.user.partner_id,
    eventypes: state.eventState.event_type.data,
    events: state.eventState.event.data,
    loading: state.eventState.event.loading,
    error: state.eventState.event.error,
    isSaving: state.eventState.create_event.loading,
    isImageDeleted: state.appMenuReducer.removeImage.isDeleted,
    selectedImageId: state.appMenuReducer.removeImage.data,
});


export default compose(
    withStyles({
        ...extendedTablesStyle,
        ...customInputStyle,
        ...regularFormsStyle,
        ...validationFormsStyle,
    }),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(CreateEvent);