import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
// import { RowGroupingModule } from 'ag-grid-enterprise/row-grouping';
import "ag-grid-enterprise";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import BrandCategoryActionButton from "../../components/AgGrid/BrandCategoryActionButton";
import AgGrid from "components/AgGrid/AgGrid.jsx";

function getSimpleCellRenderer() {
    function SimpleCellRenderer() { }
    SimpleCellRenderer.prototype.init = function (params) {
        var tempDiv = document.createElement("div");
        let icon =
            "<span class='hierarchy-tab-label-initial product-label-color'>P</span>";
        switch (params.node.data.role) {
            case "WS":
                icon =
                    "<span class='hierarchy-tab-label-initial wholesalor-label-color'>W</span>";
                break;
            case "BR":
                icon =
                    "<span class='hierarchy-tab-label-initial brand-label-color'>B</span>";
                break;
            case "CAT":
                icon =
                    "<span class='hierarchy-tab-label-initial category-label-color'>C</span>";
                break;
            case "P":
                icon =
                    "<span class='hierarchy-tab-label-initial product-label-color'>P</span>";
                break;
        }
        tempDiv.innerHTML = "<span>" + icon + params.value + "</span>";
        this.eGui = tempDiv.firstChild;
    };

    SimpleCellRenderer.prototype.getGui = function () {
        return this.eGui;
    };

    return SimpleCellRenderer;
}

const initialCategory = {
    name: "",
    description: "",
    image: "",
    parent_category: "",
    products: [],
    brand: {}
};

class BrandProductHierarchy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isInitialSet: true,
            searchtext: null,
            pagination: {
                page: "1",
                totalpage: "1",
                next: false,
                prev: false,
                pagesize: 25
            },
            loading: false,
            brandcategorylist: [],
            selectedClassIndexes: [],
            selectedEntity: [],
            modalOpen: false,
            selectedCategory: {},
            isAddNew: false,
            addCategory: {
                ...initialCategory
            },
            required: {
                name: "",
                description: ""
            },
            categoryData: null,
            error: null,
            columnDefs: [
                {
                    headerName: "Name",
                    field: "name",
                    width: 350,
                    resizable: true,
                    sortable: true,
                    filter: "agTextColumnFilter"
                },
                {
                    headerName: "Description",
                    field: "description",
                    width: 450,
                    resizable: true,
                    sortable: true,
                    filter: false
                },
                {
                    headerName: "Actions",
                    field: "value",
                    cellRenderer: "AgGridActionButton",
                    colId: "params",
                    width: 200,
                    filter: false
                }
            ],
            domLayout: "autoHeight",
            defaultColDef: {
                sortable: true,
                resizable: true,
                filter: true
            },
            treeViewCol: [
                {
                    headerName: "Brand / Category / Product",
                    field: "name",
                    minWidth: 900,
                    width: 900,
                    resizable: true,
                    showRowGroup: true,
                    cellRenderer: "agGroupCellRenderer",
                    cellRendererParams: {
                        suppressCount: true,
                        checkbox: true,
                        innerRenderer: "checkboxCellRenderer",
                        suppressDoubleClickExpand: true,
                        suppressEnterExpand: true
                    }
                }
            ],
            treeViewColAdmin: [
                {
                    headerName: "Brand / Category / Product",
                    field: "name",
                    width: 750,
                    resizable: true,
                    flex: 1,
                    sortable: true,
                    filter: "agTextColumnFilter",
                    cellRenderer: "agGroupCellRenderer",
                    cellRendererParams: {
                        innerRenderer: "checkboxCellRenderer",
                        suppressDoubleClickExpand: true,
                        suppressEnterExpand: true
                    }
                }
            ],
            groupDefaultExpanded: 1,
            context: { categoryGrid: this },
            frameworkComponents: {
                AgGridActionButton: BrandCategoryActionButton
            },
            components: { simpleCellRenderer: getSimpleCellRenderer() },
            hierarchicalView: function (data) {
                if (data && data.children && data.children.length > 0) {
                    return {
                        group: true,
                        children: data.children,
                        expanded: data.open
                    };
                } else {
                    return null;
                }
            }
        };

        this.searchText = this.searchText.bind(this);
        this.setPagination = this.setPagination.bind(this);
        this.gridRefs = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            if (nextProps.categorylist) {
                this.setState({
                    brandcategorylist: nextProps.categorylist,
                    pagination: nextProps.pagination
                });
            }
        }
    }

    componentDidMount() {
        // this.props.actions.getCategoryListAction(this.props.PartnerId);
        if (this.props.authUser.role === "BR") {
            this.props.actions.getAllProductTemplates(this.props.PartnerId);
        }

        this.refreshList();
    }

    componentWillMount() {
        this.props.actions.getSavedBatchProductList(this.props.PartnerId);
    }

    render() {
        return this.renderClassTable()
    }

    saveHandler = () => {
        this.props.saveMapping(this.state.selectedEntity);
    }

    cancelMappingHandler = () => {
        this.props.cancelMapping();
    }


    renderClassTable = () => {
        const { classes, authRole } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <div>
                                <p className={classes.cardIconTitle + " grid-header-title"}>
                                    Add Products to "{this.props.variant.name}" variant group
                                </p>
                            </div>
                        </CardHeader>
                        <CardBody>

                            <AgGrid
                                ref={this.gridRefs}
                                rowData={this.state.brandcategorylist}
                                columnDefs={
                                    this.state.treeViewCol
                                }
                                AgGridActionButton={
                                    this.props.authRole === "RE"
                                        ? null
                                        : BrandCategoryActionButton
                                }
                                update={this.setPagination}
                                isServerPagination={true}
                                pagination={this.state.pagination}
                                loading={this.state.Loading}
                                search={this.searchText}
                                isAddBtn={false}
                                isHierarchyView={true}
                                getNodeChildDetails={this.state.hierarchicalView}
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                                rowSelection="multiple"
                                groupSelectsChildren={true}
                                isInitialSet={this.state.isInitialSet}
                            />

                            {this.state.Loading && (
                                <CircularProgress
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-25px",
                                        marginLeft: "-12px"
                                    }}
                                />
                            )}
                            <Loader loading={this.state.Loading} />
                        </CardBody>
                        <CardFooter>
                            <div>
                                <Button onClick={this.saveHandler} color="primary">
                                    Save
                                </Button>
                                <Button
                                    onClick={this.cancelMappingHandler}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    refreshList = () => {

        this.props.actions.getBrandCategoryProductHierarchyAction(
            this.props.PartnerId,
            0,
            this.state.pagination.page,
            this.state.searchtext
        );

    }

    setPagination(page) {

        this.setState({
            pagination: {
                ...this.state.pagination,
                page: page
            }
        }, () => {
            this.refreshList()
        })

    }


    searchText(params) {
        this.setState({
            searchtext: params,
            pagination: {
                ...this.state.pagination,
                page: 1
            }
        },
            () => {
                this.refreshList()
            }
        );
    }

    onSelectionChanged() {
        let selectedRows = this.gridRefs.current.agGridApi.getSelectedRows();
        const componentThis = this;

        var length = this.state.selectedEntity.length;
        var i = 0;

        for (i = 0; i < length; i++) {
            componentThis.state.selectedEntity.pop();
        }

        let selectedEntity = _.cloneDeep(this.state.selectedEntity);
        selectedRows.forEach(function (selectedRow, index) {
            if (selectedRow.role === "PR") {
                let data = {
                    id: ""
                };
                data.id = selectedRow.id;
                selectedEntity.push(data);
            }
        });

        this.setState(
            {
                selectedEntity: selectedEntity
            },
            () => {
                this.gridRefs.current.setSelections(this.state.selectedEntity, "other");
            }
        );
    }

    getSelectedNodes(filter = null) {
        let selectedRows = this.gridRefs.current.agGridApi.getSelectedNodes();
        let selectednodes = [];
        selectednodes = this.getAllNestedNodeWithParent(
            selectedRows,
            selectednodes
        );
        if (filter) {
            selectednodes = selectednodes.filter(x => x.role === filter);
        }
        return selectednodes;
    }

    getAllNestedNodeWithParent(nodes, selectedEntity) {
        const componentThis = this;
        if (_.isArray(nodes)) {
            nodes.forEach(function (selectedRow, index) {
                if (
                    selectedRow.data.role === "BR" ||
                    selectedRow.data.role === "WS" ||
                    selectedRow.data.role === "PR" ||
                    selectedRow.data.role === "CAT" ||
                    selectedRow.data.role === undefined
                ) {
                    let data = {
                        id: "",
                        role: "PR"
                    };
                    data.id = selectedRow.data.id;
                    data.role = selectedRow.data.role ? selectedRow.data.role : "PR";
                    if (
                        selectedEntity.findIndex(
                            x => x.id === data.id && x.role === data.role
                        ) === -1
                    ) {
                        selectedEntity.push(data);
                    }
                }
                if (selectedRow.parent) {
                    componentThis.getAllNestedNodeWithParent(
                        selectedRow.parent,
                        selectedEntity
                    );
                }
            });
        }
        if (_.isObject(nodes)) {
            if (
                nodes.data &&
                (nodes.data.role === "BR" ||
                    nodes.data.role === "CAT" ||
                    nodes.data.role === "WS" ||
                    nodes.data.role === "PR" ||
                    nodes.data.role === undefined)
            ) {
                let data = {
                    id: "",
                    role: "PR"
                };
                data.id = nodes.data.id;
                data.role = nodes.data.role ? nodes.data.role : "PR";
                if (
                    selectedEntity.findIndex(
                        x => x.id === data.id && x.role === data.role
                    ) === -1
                ) {
                    selectedEntity.push(data);
                }
            }
            if (nodes.parent) {
                componentThis.getAllNestedNodeWithParent(nodes.parent, selectedEntity);
            }
        }

        return selectedEntity;
    }

    getNodeChildDetails(data) {
        if (data && data.children && data.children.length > 0) {
            return {
                group: true,
                children: data.children,
                expanded: data.open
            };
        } else {
            return null;
        }
    }

    onGridEditReady = params => {
        this.gridApi = params.api;
    };


}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
    List: state.categoryReducer.categorydata.data,

    ListError: state.categoryReducer.categorydata.error,

    CategoryById: state.categoryReducer.categoryById.data,
    assignProductList: state.productsAssignmentsState.list,

    UserId: state.authState.user.pk,
    PartnerId: state.authState.user.partner_id,
    authUser: state.authState.user,
    authRole: state.authState.user.role,

    Loading: state.categoryReducer.brandCategoryProductHierarchy.loading,
    categorylist: state.categoryReducer.brandCategoryProductHierarchy.data,
    pagination: state.categoryReducer.brandCategoryProductHierarchy.pagination,

});

export default compose(
    withStyles({
        ...regularFormsStyle,
        ...extendedTablesStyle,
        ...validationFormsStyle
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(BrandProductHierarchy);
