import React, { Component } from "react";
import {TextField} from "@material-ui/core";

class SubHeaderSortCompnent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.grid = this.props.context.componentParent.props.context.submenu;
  }

  render() {
    const { data } = this.props;
    return (
        <TextField
        value={data?.sort_order}
        variant="outlined"
        name="sort_order"
        fullWidth
        onChange={this.handleTextField}
      />
    );
  }

  handleTextField = (event) => {
    this.grid.handelSortOrder(this.props.data, event.target.value)
  }
}
export default SubHeaderSortCompnent;
