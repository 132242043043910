export const GET_VARIANT_REQUEST = "GET_VARIANT_REQUEST";
export const GET_VARIANT_FAILURE = "GET_VARIANT_FAILURE";
export const GET_VARIANT_SUCCESS = "GET_VARIANT_SUCCESS";

export const GET_VARIANT_DETAIL_REQUEST = "GET_VARIANT_DETAIL_REQUEST";
export const GET_VARIANT_DETAIL_FAILURE = "GET_VARIANT_DETAIL_FAILURE";
export const GET_VARIANT_DETAIL_SUCCESS = "GET_VARIANT_DETAIL_SUCCESS";

export const CREATE_VARIANT_REQUEST = "CREATE_VARIANT_REQUEST";
export const CREATE_VARIANT_FAILURE = "CREATE_VARIANT_FAILURE";
export const CREATE_VARIANT_SUCCESS = "CREATE_VARIANT_SUCCESS";

export const DELETE_VARIANT_REQUEST = "DELETE_VARIANT_REQUEST";
export const DELETE_VARIANT_FAILURE = "DELETE_VARIANT_FAILURE";
export const DELETE_VARIANT_SUCCESS = "DELETE_VARIANT_SUCCESS";



////// Variant Values

export const GET_VARIANT_VALUE_REQUEST = "GET_VARIANT_VALUE_REQUEST";
export const GET_VARIANT_VALUE_FAILURE = "GET_VARIANT_VALUE_FAILURE";
export const GET_VARIANT_VALUE_SUCCESS = "GET_VARIANT_VALUE_SUCCESS";

export const GET_VARIANT_VALUE_DETAIL_REQUEST = "GET_VARIANT_VALUE_DETAIL_REQUEST";
export const GET_VARIANT_VALUE_DETAIL_FAILURE = "GET_VARIANT_VALUE_DETAIL_FAILURE";
export const GET_VARIANT_VALUE_DETAIL_SUCCESS = "GET_VARIANT_VALUE_DETAIL_SUCCESS";

export const CREATE_VARIANT_VALUE_REQUEST = "CREATE_VARIANT_VALUE_REQUEST";
export const CREATE_VARIANT_VALUE_FAILURE = "CREATE_VARIANT_VALUE_FAILURE";
export const CREATE_VARIANT_VALUE_SUCCESS = "CREATE_VARIANT_VALUE_SUCCESS";

export const DELETE_VARIANT_VALUE_REQUEST = "DELETE_VARIANT_VALUE_REQUEST";
export const DELETE_VARIANT_VALUE_FAILURE = "DELETE_VARIANT_VALUE_FAILURE";
export const DELETE_VARIANT_VALUE_SUCCESS = "DELETE_VARIANT_VALUE_SUCCESS";

