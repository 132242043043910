import * as CMSContantService from "../../services/CMSContantService";
import * as actionTypes from "./actionTypes";

export const getCMSComponentDetail = id => async dispatch => {
  dispatch(getComponentDetailRequest());
  try {
    const success = await CMSContantService.getCMSComponents(id);
    if (success.status === "1") {
      dispatch(getComponentDetailSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(getComponentDetailFailure(success.error));
    } else {
      dispatch(
        getComponentDetailFailure(
          "An error has occured while loading data. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(getComponentDetailFailure(error.message));
  }
};

const getComponentDetailRequest = () => {
  return {
    type: actionTypes.COMPONENT_DETAIL_REQUEST
  };
};

const getComponentDetailSuccess = data => {
  return {
    type: actionTypes.COMPONENT_DETAIL_SUCCESS,
    payload: data
  };
};

const getComponentDetailFailure = error => {
  return {
    type: actionTypes.COMPONENT_DETAIL_FAILURE,
    payload: error
  };
};

export const getCMSComponents = (
  id,
  isSelect = false,
  page = 0,
  search = null,
  partner_id = null
) => async dispatch => {
  dispatch(getRequest());
  try {
    const success = await CMSContantService.getCMSComponents(
      id,
      isSelect,
      page,
      search,
      partner_id
    );
    if (success.status === "1") {
      dispatch(getSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(getFailure(success.error));
    } else {
      dispatch(getFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getFailure(error.message));
  }
};

const getRequest = () => {
  return {
    type: actionTypes.GET_COMPONENT_REQUEST
  };
};

const getSuccess = data => {
  return {
    type: actionTypes.GET_COMPONENT_SUCCESS,
    payload: data
  };
};

const getFailure = error => {
  return {
    type: actionTypes.GET_COMPONENT_FAILURE,
    payload: error
  };
};

export const deleteComponent = (userid, componentid) => async dispatch => {
  dispatch(deleteRequest());
  try {
    const success = await CMSContantService.deleteComponent(
      userid,
      componentid
    );
    if (success.status === "1") {
      dispatch(deleteSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(deleteFailure(success.error));
    } else {
      dispatch(
        deleteFailure(
          "An error has occured while deleting component. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(deleteFailure(error.message));
  }
};

const deleteRequest = () => {
  return {
    type: actionTypes.DELETE_COMPONENT_REQUEST
  };
};

const deleteSuccess = data => {
  return {
    type: actionTypes.DELETE_COMPONENT_SUCCESS,
    payload: data
  };
};

const deleteFailure = error => {
  return {
    type: actionTypes.DELETE_COMPONENT_FAILURE,
    payload: error
  };
};

export const saveComponent = (userid, components, files) => async dispatch => {
  dispatch(saveRequest());
  try {
    const success = await CMSContantService.saveComponentData(
      userid,
      components,
      files
    );
    if (success.status === "1") {
      dispatch(saveSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(saveFailure(success.error));
    } else {
      dispatch(
        saveFailure(
          "An error has occured while saving component. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(saveFailure(error.message));
  }
};

const saveRequest = () => {
  return {
    type: actionTypes.SAVE_COMPONENT_REQUEST
  };
};

const saveSuccess = data => {
  return {
    type: actionTypes.SAVE_COMPONENT_SUCCESS,
    payload: data
  };
};

const saveFailure = error => {
  return {
    type: actionTypes.SAVE_COMPONENT_FAILURE,
    payload: error
  };
};

export const addingNewPage = (userid, step, data) => async dispatch => {
  dispatch(resetDetail());
};

const resetDetail = () => {
  return {
    type: actionTypes.RESET_PAGE_DATA
  };
};

export const savePartnerPageDetails = (
  userid,
  step,
  data,
  partner_id,
  files = null
) => async dispatch => {
  dispatch(savePageRequest());
  try {
    const success = await CMSContantService.savePartnerPageService(
      userid,
      step,
      data,
      partner_id,
      files
    );
    if (success.status === "1") {
      dispatch(savePageSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(savePageFailure(success.error));
    } else {
      dispatch(
        savePageFailure(
          "An error has occured while saving page. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(savePageFailure(error.message));
  }
};

export const saveBasicDetails = (
  userid,
  step,
  data,
  partnerid,
  files = null
) => async dispatch => {
  dispatch(savePageRequest());
  try {
    const success = await CMSContantService.saveBasicDetails(
      userid,
      step,
      data,
      partnerid,
      files
    );
    if (success.status === "1") {
      dispatch(savePageSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(savePageFailure(success.error));
    } else {
      dispatch(
        savePageFailure(
          "An error has occured while saving page. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(savePageFailure(error.message));
  }
};

const savePageRequest = () => {
  return {
    type: actionTypes.SAVE_PAGE_REQUEST
  };
};

const savePageSuccess = data => {
  return {
    type: actionTypes.SAVE_PAGE_SUCCESS,
    payload: data
  };
};

const savePageFailure = error => {
  return {
    type: actionTypes.SAVE_PAGE_FAILURE,
    payload: error
  };
};

export const getPartnerPages = (
  partner_id,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getPageRequest());
  try {
    const success = await CMSContantService.getPartnerPages(
      partner_id,
      page,
      search
    );
    if (success.status === "1") {
      dispatch(getPageSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(getPageFailure(success.error));
    } else {
      dispatch(getPageFailure("An error has occured while loading pages."));
    }
  } catch (error) {
    dispatch(getPageFailure(error.message));
  }
};

export const getCMSPages = (
  id,
  partnerId,
  onlyActive = false,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getPageRequest());
  try {
    const success = await CMSContantService.getCMSPages(
      id,
      partnerId,
      onlyActive,
      page,
      search
    );
    if (success.status === "1") {
      dispatch(getPageSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(getPageFailure(success.error));
    } else {
      dispatch(getPageFailure("An error has occured while loading pages."));
    }
  } catch (error) {
    dispatch(getPageFailure(error.message));
  }
};

const getPageRequest = () => {
  return {
    type: actionTypes.GET_PAGE_REQUEST
  };
};

const getPageSuccess = data => {
  return {
    type: actionTypes.GET_PAGE_SUCCESS,
    payload: data
  };
};

const getPageFailure = error => {
  return {
    type: actionTypes.GET_PAGE_FAILURE,
    payload: error
  };
};

export const getPartnerCMSPageDetail = (partner_id, id) => async dispatch => {
  dispatch(getPageDetailRequest());
  try {
    const success = await CMSContantService.getPartnerCMSPages(partner_id, id);
    if (success.status === "1") {
      dispatch(getPageDetailSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(getPageDetailFailure(success.error));
    } else {
      dispatch(
        getPageDetailFailure(
          "An error has occured while loading data. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(getPageDetailFailure(error.message));
  }
};

export const getCMSPageDetail = id => async dispatch => {
  dispatch(getPageDetailRequest());
  try {
    const success = await CMSContantService.getCMSPages(id);
    if (success.status === "1") {
      dispatch(getPageDetailSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(getPageDetailFailure(success.error));
    } else {
      dispatch(
        getPageDetailFailure(
          "An error has occured while loading data. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(getPageDetailFailure(error.message));
  }
};

const getPageDetailRequest = () => {
  return {
    type: actionTypes.PAGE_DETAIL_REQUEST
  };
};

const getPageDetailSuccess = data => {
  return {
    type: actionTypes.PAGE_DETAIL_SUCCESS,
    payload: data
  };
};

const getPageDetailFailure = error => {
  return {
    type: actionTypes.PAGE_DETAIL_FAILURE,
    payload: error
  };
};

export const saveCMSMenu = (data, partnerId, menuType) => async dispatch => {
  dispatch(saveCMSMenuRequest());
  try {
    const success = await CMSContantService.saveCMSMenu(
      data,
      partnerId,
      menuType
    );

    if (success.status === "1") {
      dispatch(saveCMSMenuSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(saveCMSMenuFailure(success.error));
    } else {
      dispatch(
        saveCMSMenuFailure(
          "An error has occured while loading data. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(saveCMSMenuFailure(error.message));
  }
};

const saveCMSMenuRequest = () => {
  return {
    type: actionTypes.SAVE_CMS_MENU_REQUEST
  };
};

const saveCMSMenuSuccess = data => {
  return {
    type: actionTypes.SAVE_CMS_MENU_SUCCESS,
    payload: data
  };
};

const saveCMSMenuFailure = error => {
  return {
    type: actionTypes.SAVE_CMS_MENU_FAILURE,
    payload: error
  };
};

export const copyPagePartner = (pageId, partnerId) => async dispatch => {
  dispatch(copyPagePartnerRequest());
  try {
    var success = await CMSContantService.copyPagePartner(pageId, partnerId);
    if (success.status && success.status === "1") {
      dispatch(copyPagePartnerSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(copyPagePartnerFailure(success.error));
    } else {
      dispatch(copyPagePartnerFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(copyPagePartnerFailure(error.message));
  }
};

const copyPagePartnerRequest = () => {
  return {
    type: actionTypes.COPY_PAGE_PARTNER_REQUEST
  };
};

const copyPagePartnerSuccess = data => {
  return {
    type: actionTypes.COPY_PAGE_PARTNER_SUCCESS,
    payload: data
  };
};

const copyPagePartnerFailure = error => {
  return {
    type: actionTypes.COPY_PAGE_PARTNER_FAILURE,
    payload: error
  };
};

export const getAssignPartnerPages = (pageId, partnerId) => async dispatch => {
  dispatch(getAssignPartnerPagesRequest());
  try {
    var success = await CMSContantService.getAssignPartnerPages(
      pageId,
      partnerId
    );
    if (success.status && success.status === "1") {
      dispatch(getAssignPartnerPagesSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getAssignPartnerPagesFailure(success.error));
    } else {
      dispatch(getAssignPartnerPagesFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getAssignPartnerPagesFailure(error.message));
  }
};

const getAssignPartnerPagesRequest = () => {
  return {
    type: actionTypes.GET_ASSIGN_PARTNER_PAGE_REQUEST
  };
};

const getAssignPartnerPagesSuccess = data => {
  return {
    type: actionTypes.GET_ASSIGN_PARTNER_PAGE_SUCCESS,
    payload: data
  };
};

const getAssignPartnerPagesFailure = error => {
  return {
    type: actionTypes.GET_ASSIGN_PARTNER_PAGE_FAILURE,
    payload: error
  };
};

export const saveBanners = (userid, components, files) => async dispatch => {
  dispatch(saveBannerRequest());
  try {
    const success = await CMSContantService.saveBanner(
      userid,
      components,
      files
    );
    if (success.status === "1") {
      dispatch(saveBannerSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(saveBannerFailure(success.error));
    } else {
      dispatch(
        saveBannerFailure(
          "An error has occured while saving component. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(saveBannerFailure(error.message));
  }
};

const saveBannerRequest = () => {
  return {
    type: actionTypes.SAVE_BANNER_REQUEST
  };
};

const saveBannerSuccess = data => {
  return {
    type: actionTypes.SAVE_BANNER_SUCCESS,
    payload: data
  };
};

const saveBannerFailure = error => {
  return {
    type: actionTypes.SAVE_BANNER_FAILURE,
    payload: error
  };
};

////// Get Banner List
export const getBannerList = (id, partnerId) => async dispatch => {
  dispatch(getBannerRequest());
  try {
    const success = await CMSContantService.getBanners(id, partnerId);
    if (success.status === "1") {
      dispatch(getBannerSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(getBannerFailure(success.error));
    } else {
      dispatch(getBannerFailure("An error has occured while loading details."));
    }
  } catch (error) {
    dispatch(getBannerFailure(error.message));
  }
};

const getBannerRequest = () => {
  return {
    type: actionTypes.GET_BANNER_REQUEST
  };
};

const getBannerSuccess = data => {
  return {
    type: actionTypes.GET_BANNER_SUCCESS,
    payload: data
  };
};

const getBannerFailure = error => {
  return {
    type: actionTypes.GET_BANNER_FAILURE,
    payload: error
  };
};

export const getCMSBannerDetail = id => async dispatch => {
  dispatch(getBannerDetailRequest());
  try {
    const success = await CMSContantService.getBannerDetails(id);
    if (success.status === "1") {
      dispatch(getBannerDetailSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(getBannerDetailFailure(success.error));
    } else {
      dispatch(
        getBannerDetailFailure(
          "An error has occured while loading data. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(getBannerDetailFailure(error.message));
  }
};

const getBannerDetailRequest = () => {
  return {
    type: actionTypes.BANNER_DETAIL_REQUEST
  };
};

const getBannerDetailSuccess = data => {
  return {
    type: actionTypes.BANNER_DETAIL_SUCCESS,
    payload: data
  };
};

const getBannerDetailFailure = error => {
  return {
    type: actionTypes.BANNER_DETAIL_FAILURE,
    payload: error
  };
};

export const saveClonePages = Savedata => async dispatch => {
  dispatch(saveClonePagesRequest());
  try {
    const success = await CMSContantService.cloneCmsPages(Savedata);
    if (success.status === "1") {
      dispatch(saveClonePagesSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(saveClonePagesFailure(success.error));
    } else {
      dispatch(
        saveClonePagesFailure(
          "An error has occured while loading data. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(saveClonePagesFailure(error.message));
  }
};

export const clonePartnerPages = (Savedata, partnerId) => async dispatch => {
  dispatch(saveClonePagesRequest());
  try {
    const success = await CMSContantService.clonePartnerPages(
      Savedata,
      partnerId
    );
    if (success.status === "1") {
      dispatch(saveClonePagesSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(saveClonePagesFailure(success.error));
    } else {
      dispatch(
        saveClonePagesFailure(
          "An error has occured while loading data. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(saveClonePagesFailure(error.message));
  }
};

const saveClonePagesRequest = () => {
  return {
    type: actionTypes.CLONE_CMS_PAGES_REQUEST
  };
};

const saveClonePagesSuccess = data => {
  return {
    type: actionTypes.CLONE_CMS_PAGES_SUCCESS,
    payload: data
  };
};

const saveClonePagesFailure = error => {
  return {
    type: actionTypes.CLONE_CMS_PAGES_FAILURE,
    payload: error
  };
};

export const deleteBanner = (userid, componentid) => async dispatch => {
  dispatch(deleteBannerRequest());
  try {
    const success = await CMSContantService.deleteBanner(userid, componentid);
    if (success.status === "1") {
      dispatch(deleteBannerSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(deleteBannerFailure(success.error));
    } else {
      dispatch(
        deleteBannerFailure(
          "An error has occured while deleting banner. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(deleteBannerFailure(error.message));
  }
};

const deleteBannerRequest = () => {
  return {
    type: actionTypes.DELETE_BANNER_REQUEST
  };
};

const deleteBannerSuccess = data => {
  return {
    type: actionTypes.DELETE_BANNER_SUCCESS,
    payload: data
  };
};

const deleteBannerFailure = error => {
  return {
    type: actionTypes.DELETE_BANNER_FAILURE,
    payload: error
  };
};

export const deleteAdminPage = (userid, pageId) => async dispatch => {
  dispatch(deletePageRequest());
  try {
    const success = await CMSContantService.deleteAdminPage(userid, pageId);
    if (success.status === "1") {
      dispatch(deletePageSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(deletePageFailure(success.error));
    } else {
      dispatch(
        deletePageFailure(
          "An error has occured while deleting banner. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(deletePageFailure(error.message));
  }
};

export const deletePartnerPage = (userid, pageId) => async dispatch => {
  dispatch(deletePageRequest());
  try {
    const success = await CMSContantService.deletePartnerPage(userid, pageId);
    if (success.status === "1") {
      dispatch(deletePageSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(deletePageFailure(success.error));
    } else {
      dispatch(
        deletePageFailure(
          "An error has occured while deleting banner. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(deletePageFailure(error.message));
  }
};

const deletePageRequest = () => {
  return {
    type: actionTypes.DELETE_PAGE_REQUEST
  };
};

const deletePageSuccess = data => {
  return {
    type: actionTypes.DELETE_PAGE_SUCCESS,
    payload: data
  };
};

const deletePageFailure = error => {
  return {
    type: actionTypes.DELETE_PAGE_FAILURE,
    payload: error
  };
};

// export const clonePartnerSite = (partner_id, copyBy_partnerId) =>
//     async dispatch => {

//         dispatch(getClonePartnerRequest());
//         try {
//             const success = await retailerServices.clonePartnerSite(partner_id, copyBy_partnerId);
//             if (success.status && success.status === "0") {
//                 dispatch(getClonePartnerFailure(success.error ? success.error : "Some Error Occcured"));
//             } else {
//                 var data = success.data;

//                 dispatch(getClonePartnerSuccess(data));
//             }
//         } catch (error) {
//             dispatch(getClonePartnerFailure(error.message));
//         }
//     }

// const getClonePartnerRequest = () => {
//     return {
//         type: actionTypes.CLONE_PARTNER_SITE_REQUEST
//     }
// }

// const getClonePartnerSuccess = (data) => {
//     return {
//         type: actionTypes.CLONE_PARTNER_SITE_SUCCESS,
//         payload: data
//     }
// }

// const getClonePartnerFailure = (error) => {
//     return {
//         type: actionTypes.CLONE_PARTNER_SITE_FAILURE,
//         payload: error
//     }
// }

export const getPartnersList = (page = 0) => async dispatch => {
  dispatch(getPatrnerRequest());
  try {
    const success = await CMSContantService.getPartnerData(page);

    dispatch(getPatrnerSuccess(success.data));
  } catch (error) {
    dispatch(getPatrnerFailure(error.message));
  }
};

const getPatrnerRequest = () => {
  return {
    type: actionTypes.GET_PARTNER_LIST_REQUEST
  };
};

const getPatrnerSuccess = data => {
  return {
    type: actionTypes.GET_PARTNER_LIST_SUCCESS,
    payload: data
  };
};

const getPatrnerFailure = error => {
  return {
    type: actionTypes.GET_PARTNER_LIST_FAILURE,
    payload: error
  };
};

////// Get Banner List
export const getIndustryPagesGroupList = () => async dispatch => {
  dispatch(getIndustryPageRequest());

  try {
    const success = await CMSContantService.getIndustryPageGroup();

    if (success.status === "1") {
      dispatch(getIndustryPageSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(getIndustryPageFailure(success.error));
    } else {
      dispatch(
        getIndustryPageFailure("An error has occured while loading details.")
      );
    }
  } catch (error) {
    dispatch(getIndustryPageFailure(error.message));
  }
};

const getIndustryPageRequest = () => {
  return {
    type: actionTypes.GET_INDUSTRY_PAGE_GROUP_REQUEST
  };
};

const getIndustryPageSuccess = data => {
  return {
    type: actionTypes.GET_INDUSTRY_PAGE_GROUP_SUCCESS,
    payload: data
  };
};

const getIndustryPageFailure = error => {
  return {
    type: actionTypes.GET_INDUSTRY_PAGE_GROUP_FAILURE,
    payload: error
  };
};

export const clonePackage = Savedata => async dispatch => {
  dispatch(saveClonePackageRequest());
  try {
    const success = await CMSContantService.clonePackage(Savedata);
    if (success.status === "1") {
      dispatch(saveClonePackageSuccess(success.data));
    } else if (success.status === "0" && success.error) {
      dispatch(saveClonePackageFailure(success.error));
    } else {
      dispatch(
        saveClonePackageFailure(
          "An error has occured while cloning package. Please try again."
        )
      );
    }
  } catch (error) {
    dispatch(saveClonePackageFailure(error.message));
  }
};

const saveClonePackageRequest = () => {
  return {
    type: actionTypes.CLONE_PACKAGE_REQUEST
  };
};

const saveClonePackageSuccess = data => {
  return {
    type: actionTypes.CLONE_PACKAGE_SUCCESS,
    payload: data
  };
};

const saveClonePackageFailure = error => {
  return {
    type: actionTypes.CLONE_PACKAGE_FAILURE,
    payload: error
  };
};
