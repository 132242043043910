import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import FormLabel from "@material-ui/core/FormLabel";
import ClearIcon from "@material-ui/icons/Clear";

// core components
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import AgGridActionButton from "../../components/AgGrid/AgGridActionButton.jsx";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import ReactTooltip from "react-tooltip";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

const initialState = {
  attributeGroupData: {
    id: null,
    name: "",
    description: "",
    attributes: [
      {
        name: "",
        description: ""
      }
    ]
  },
  required: {
    name: "",
    description: ""
  },
  columnDefs: [
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      resizable: true,
      width: 750
    },
    {
      headerName: "Actions",
      field: "value",
      cellRenderer: "AgGridActionButton",
      colId: "params",
      width: 240,
      filter: false
    }
  ]
};

class UniversalAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      deleteElementId: "",
      isAddNew: false,
      isEditMode: false,
      attributeGroups: [],
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 800,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        // {
        //     headerName: "Description",
        //     field: "description",
        //     width: 450,
        //     resizable: true,
        //     sortable: true,
        //     filter: false
        // },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ],
      attributeData: {
        name: "",
        description: ""
      },
      error: "",
      loading: ""
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentDidMount() {
    this.props.actions.getAttributeGroups(this.state.pagination.page);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      let attributeGroups = [];
      if (nextProps.List && nextProps.List.length) {
        attributeGroups = nextProps.List;
      }
      this.setState(() => ({
        attributeGroups: attributeGroups,
        pagination: nextProps.pagination
      }));
      // if (!nextProps.Saving && this.props.Saving && nextProps.Error === null) {
      //     this.handelIsAddNew();
      // }

      if (nextProps.isSaved) {
        this.setState(
          {
            isAddNew: !this.state.isAddNew,
            isEditMode: false,
            error: null,
            ...initialState
          },
          () => {
            this.props.actions.getAttributeGroups(this.state.pagination.page);
          }
        );
      }

      if (
        nextProps.AttributeGroupData &&
        !_.isEqual(this.props.AttributeGroupData, nextProps.AttributeGroupData)
      ) {
        let data = nextProps.AttributeGroupData;
        if (data && data.attributes && data.attributes.length == 0) {
          data.attributes.push(this.state.attributeData);
        }
        this.setState({
          attributeGroupData: data,
          isAddNew: true,
          isEditMode: true
        });
      }

      if (nextProps.AttributeDelete) {
        let { attributes } = this.state.attributeGroupData;
        const { deleteElementId } = this.state;
        let index = attributes.findIndex(x => x.id == deleteElementId);
        if (index > -1) {
          attributes.splice(index, 1);
        }
        if (attributes && attributes.length == 0) {
          attributes.push(this.state.attributeData);
        }
        this.setState({
          ...this.state,
          deleteElementId: "",
          attributeGroupData: {
            ...this.state.attributeGroupData,
            attributes: attributes
          }
        });
      }

      let error = "";
      if (nextProps.Error || nextProps.AttributeGroupError) {
        error = nextProps.Error || nextProps.AttributeGroupError;
      }
      this.setState({
        error: error
      });

      let loading = false;
      if (nextProps.AttributeGroupLoding) {
        loading = nextProps.AttributeGroupLoding;
      }
      this.setState({
        loading: loading
      });
    }
  }

  handleTextChange = key => event => {
    let req = _.cloneDeep(this.state.required);
    if (event.target.value === null || event.target.value === "") {
      req[key] = "error";
    } else {
      req[key] = "success";
    }
    this.setState({
      attributeGroupData: {
        ...this.state.attributeGroupData,
        [key]: event.target.value
      },
      required: req
    });
  };

  handelIsAddNew = () => {
    console.log(initialState);
    this.setState({
      isAddNew: !this.state.isAddNew,
      isEditMode: false,
      error: null,
      ...initialState
    });
  };

  handelEdit = id => {
    this.props.actions.getAttributeGroupByIdAction(id);
  };

  changeRowData = index => event => {
    let { attributes } = this.state.attributeGroupData;
    let row = _.clone(attributes[index]);
    row[event.target.name] = event.target.value;
    attributes[index] = row;
    this.setState({
      ...this.state,
      attributeGroupData: {
        ...this.state.attributeGroupData,
        attributes: attributes
      }
    });
  };

  handelNewRow = () => {
    let { attributes } = this.state.attributeGroupData;
    const { attributeData } = this.state;
    attributes.push(attributeData);
    this.setState({
      attributeGroupData: {
        ...this.state.attributeGroupData,
        attributes: attributes
      }
    });
  };

  removeRow = index => {
    let { attributes } = this.state.attributeGroupData;
    let attributeId = attributes[index].id;
    if (attributeId == undefined || attributeId == "") {
      if (attributes.length === 1) {
        attributes[0].name = "";
        attributes[0].description = "";
        delete attributes[0].id;
      } else {
        attributes.splice(index, 1);
        this.setState({
          attributeGroupData: {
            ...this.state.attributeGroupData,
            attributes: attributes
          }
        });
      }
    } else {
      this.setState(
        {
          deleteElementId: attributeId
        },
        () => {
          this.props.actions.deleteAttributeByIdAction(
            this.state.deleteElementId
          );
        }
      );
    }
  };

  isValid = data => {
    let isValid = true;
    let req = _.cloneDeep(this.state.required);
    if (data.name === "") {
      req.name = "error";
      isValid = false;
    }
    if (data.description === "") {
      req.description = "error";
      isValid = false;
    }
    this.setState({
      required: req
    });
    return isValid;
  };

  saveAttributeGroup = () => {
    const { id, name, description, attributes } = this.state.attributeGroupData;
    let data = _.cloneDeep(this.state.attributeGroupData);
    if (!this.isValid(data)) {
      return;
    }
    this.props.actions.createAttributeGroup(this.props.UserEmail, {
      id: id,
      name: name,
      description: description,
      attributes: attributes
    });
  };

  renderAttributeGroupTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem lg={12} />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Attribute Group
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <AgGrid
                  rowData={this.state.attributeGroups}
                  columnDefs={this.state.columnDefs}
                  handelEdit={this.handelEdit}
                  loading={this.state.loading}
                  AgGridActionButton={AgGridActionButton}
                  update={this.updateGridData}
                  isServerPagination={true}
                  pagination={this.state.pagination}
                  search={this.searchText}
                  new={this.handelIsAddNew}
                  isAddBtn={true}
                />
                {this.state.error && this.state.error.trim() !== "" && (
                  <FormLabel className={classes.labelLeftHorizontal}>
                    <code>{this.state.error}</code>
                  </FormLabel>
                )}
                {this.state.loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
  updateGridData(page) {
    this.props.actions.getAttributeGroups(page, this.state.searchtext);
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getAttributeGroups(page, this.state.searchtext);
      }
    );
  }

  renderAttributeGroups = () => {
    const { classes } = this.props;
    const { name, description } = this.state.attributeGroupData;
    let required = _.cloneDeep(this.state.required);
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <p
                className={
                  classes.cardIconTitle + " wholesalor-hierarchy-title"
                }
              >
                {this.state.isEditMode
                  ? `Edit: ${this.state &&
                      this.state.attributeGroupData &&
                      this.state.attributeGroupData.name}`
                  : "New Attribute Group"}
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <CustomInput
                        labelText="Name *"
                        success={required.name === "success"}
                        error={required.name === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: name,
                          onChange: this.handleTextChange("name")
                        }}
                        id={"name_"}
                      />
                    </GridItem>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <CustomInput
                        labelText="Description *"
                        success={required.description === "success"}
                        error={required.description === "error"}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: description,
                          onChange: this.handleTextChange("description", "")
                        }}
                        id={"email_"}
                      />
                    </GridItem>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <InputLabel className={classes.label + " "}>
                        Attributes
                      </InputLabel>
                      <Table
                        tableHead={["Name", "Description"]}
                        tableData={this.getAttributeTableData()}
                        tableShopping
                        customHeadCellClasses={[
                          classes.description,
                          classes.description,
                          classes.description,
                          classes.right,
                          classes.right,
                          classes.right
                        ]}
                        customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                        customCellClasses={[
                          classes.customFont,
                          classes.tdName,
                          classes.customFont,
                          classes.tdNumber,
                          classes.tdNumber +
                            " " +
                            classes.tdNumberAndButtonGroup,
                          classes.tdNumber
                        ]}
                        customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                      />
                    </GridItem>
                    <br />
                    <br />

                    {this.state.error && this.state.error.trim() !== "" && (
                      <GridItem lg={12} md={12} sm={12} xs={12}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>{this.state.error}</code>
                        </FormLabel>
                      </GridItem>
                    )}
                    <br />
                    <br />
                    <br />
                    <Button color="info" onClick={this.saveAttributeGroup}>
                      Save Changes
                    </Button>
                    <Button onClick={this.handelIsAddNew}>Cancel</Button>
                    {this.props.Saving && (
                      <CircularProgress
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-25px",
                          marginLeft: "-12px"
                        }}
                      />
                    )}
                    <Loader loading={this.props.Saving} text="Saving.." />
                  </GridContainer>
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  getAttributeTableData = () => {
    const { attributes } = this.state.attributeGroupData;
    const { classes } = this.props;
    var returnData = [];
    _.map(attributes, ({ id, name, description }, index) => {
      returnData.push([
        <span>
          <TextField
            value={name ? name : ""}
            name="name"
            fullWidth
            classes={{
              root: "attribute-txtbox"
            }}
            onChange={this.changeRowData(index)}
          />
        </span>,
        <span>
          <TextField
            value={description ? description : ""}
            name="description"
            fullWidth
            classes={{
              root: "attribute-txtbox"
            }}
            onChange={this.changeRowData(index)}
          />
        </span>,
        <span>
          <ReactTooltip id="editRow">
            <span>New Row</span>
          </ReactTooltip>

          <Button
            color="success"
            data-tip
            data-for="editInd"
            className={classes.actionButton}
            onClick={() => this.handelNewRow(index)}
          >
            <AddIcon className={classes.icons} />
          </Button>

          <ReactTooltip id="deleteRow">
            <span>Delete Row</span>
          </ReactTooltip>
          <Button
            color="danger"
            data-tip
            data-for="deleteInd"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.removeRow(index)}
          >
            <ClearIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>
        </span>
      ]);
    });
    return returnData;
  };

  render() {
    return this.state.isAddNew
      ? this.renderAttributeGroups()
      : this.renderAttributeGroupTable();
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.brandState.attributeGroups.attributeGroups,
  Loading: state.brandState.attributeGroups.isLoading,
  Error: state.brandState.error,
  Saving: state.brandState.attributeGroups.isSaving,
  UserEmail: state.authState.user.user,
  AttributeGroupData: state.brandState.attributeGroupById.data,
  AttributeGroupError: state.brandState.attributeGroupById.error,
  AttributeGroupLoding: state.brandState.attributeGroupById.loading,
  AttributeDelete: state.brandState.attributeGroupById.isAttributeDelete,
  pagination: state.brandState.attributeGroups.pagination,
  isSaved: state.brandState.attributeGroups.isSaved
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...customInputStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UniversalAttributes);
