import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";

import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import PublishIcon from "@material-ui/icons/Publish";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Button from "components/CustomButtons/Button.jsx";
import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";
import { rolesNames } from "../../constants";
import * as routesNames from "../../constants/routes";
import appDomain from "../../constants/domains";

import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    onClick={props.onClick}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "& li": {
      fontSize: "10px"
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-secondary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      profileOpen: false,
      messageLinkOpen: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
      }
    }
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleProfileClick = () => {
    this.setState({ profileOpen: !this.state.profileOpen });
  };

  handleMessageClick = () => {
    this.props.actions.onNavigateTo(routesNames.Message);
    // this.setState({ messageLinkOpen: !this.state.messageLinkOpen });
  };

  handleProfileClose = () => {
    this.setState({ profileOpen: false });
  };

  handleProfileSection = () => {
    this.props.actions.sso_to_link(appDomain.CONSUMER, "profile");
    this.handleProfileClose();
  };

  handleSiteSection = () => {
    this.props.actions.sso_to_link(
      appDomain.PARTNER,
      window.location.protocol + "//" + this.props.authUser.businessUrl
    );
    this.handleProfileClose();
  };

  handleTestSiteSection = () => {
    this.props.actions.sso_to_link(
      appDomain.PARTNER,
      window.location.protocol + "//" + this.props.authUser.test_deploy_url
    );
    this.handleProfileClose();
  };

  handleProfileRedirect = () => {
    this.props.history.push("/website");
    this.handleProfileClose();
  };

  handleLogout = () => {
    this.setState({ profileOpen: false });
    this.props.actions.signOutUser();
    this.handleProfileClose();
  };

  switchUser = partner_email_id => {
    this.props.actions.signInUser(partner_email_id, "", true, partner_email_id);
    this.handleProfileClose();
  };

  handlePublish = mode => {
    this.props.actions.publishRetailerSite(this.props.partner.partner_id, mode);
    this.handlePublishMenuClose();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handlePublishLinkClick = event => {
    const anchorEl = event.currentTarget;
    this.setState({ ...this.state, menuOpen: !this.state.menuOpen, anchorEl });
  };

  handlePublishMenuClose = () => {
    const anchorEl = null;
    this.setState({ ...this.state, menuOpen: !this.state.menuOpen, anchorEl });
  };

  render() {
    const { classes, rtlActive } = this.props;

    const { profileOpen } = this.state;

    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );

    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });

    const impersonatingUser = localStorage.getItem("session")
      ? JSON.parse(localStorage.getItem("session"))
      : null;
    const userEmail = localStorage.getItem("userid");

    return (
      <div className={wrapper}>
        {this.props.authUser &&
          this.props.authUser.role &&
          (this.props.authUser.role == rolesNames.RoleMaster.RETAILER ||
            this.props.authUser.role == rolesNames.RoleMaster.BRAND ||
            this.props.authUser.role == rolesNames.RoleMaster.WHOLESALER) &&
          (this.props.authUser.isAdminUser ||
            this.props.authUser.user_group == 5) && (
            <div className={classes.managerClasses}>
              {!this.props.publishState.isSaving ? (
                <>
                  {" "}
                  <Button
                    color="transparent"
                    aria-label="Publish"
                    aria-haspopup="true"
                    size="sm"
                    onClick={this.handlePublishLinkClick}
                    className={
                      rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                    }
                    color="info"
                    muiClasses={{
                      label: rtlActive ? classes.labelRTL : ""
                    }}
                    buttonRef={node => {
                      this.anchorEl = node;
                    }}
                  >
                    <PublishIcon
                      className={
                        classes.headerLinksSvg +
                        " " +
                        (rtlActive
                          ? classes.links + " " + classes.linksRTL
                          : classes.links)
                      }
                    />
                    <span>Publish</span>&nbsp;
                    {this.props.publishState.error && (
                      <Tooltip
                        id="tooltip-bottom"
                        title={this.props.publishState.error}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <ErrorOutlineIcon
                          className={
                            classes.headerLinksSvg +
                            " " +
                            (rtlActive
                              ? classes.links + " " + classes.linksRTL
                              : classes.links)
                          }
                        />
                      </Tooltip>
                    )}
                  </Button>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handlePublishMenuClose}
                  >
                    {this.props.authUser && this.props.authUser.isAdminUser && (
                      <StyledMenuItem onClick={() => this.handlePublish(0)}>
                        {/* 0 for publish to master */}
                        <ListItemText
                          secondary={
                            this.props.authUser.approval_status === 0
                              ? "Approve and Publish to site"
                              : "Publish to site"
                          }
                        />
                      </StyledMenuItem>
                    )}
                    <StyledMenuItem onClick={() => this.handlePublish(1)}>
                      {/* 1 for publish to test site */}
                      <ListItemText secondary="Publish to Test site" />
                    </StyledMenuItem>
                  </StyledMenu>
                </>
              ) : (
                <Button
                  color="transparent"
                  aria-label="Publishing"
                  aria-haspopup="true"
                  size="sm"
                  className={
                    rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                  }
                  color="warning"
                  muiClasses={{
                    label: rtlActive ? classes.labelRTL : ""
                  }}
                  buttonRef={node => {
                    this.anchorEl = node;
                  }}
                >
                  <PublishIcon
                    className={
                      classes.headerLinksSvg +
                      " " +
                      (rtlActive
                        ? classes.links + " " + classes.linksRTL
                        : classes.links)
                    }
                  />
                  <span>Publishing..</span>
                </Button>
              )}
            </div>
          )}
        {/* {
          this.props.authUser && this.props.authUser.businessUrl && <Button
            color="transparent"
            justIcon
            aria-label="Visit Site"
            aria-owns={profileOpen ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleSiteSection}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            buttonRef={node => {
              this.anchorElProfile = node;
            }}
          >
            <LanguageIcon
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
          </Button>
        } */}
        <Button
          color="transparent"
          justIcon
          aria-label="Messages"
          aria-owns={profileOpen ? "menu-list" : null}
          aria-haspopup="true"
          onClick={this.handleMessageClick}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
          buttonRef={node => {
            this.anchorElProfile = node;
          }}
        >
          <MailOutlineIcon
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : "Profile"}
            </span>
          </Hidden>
        </Button>
        &nbsp;&nbsp;&nbsp;
        <span className={classes.linkText}>
          <b> {this.props.authUser && this.props.authUser.name} </b>
        </span>
        <Button
          color="transparent"
          justIcon
          aria-label="Person"
          aria-owns={profileOpen ? "menu-list" : null}
          aria-haspopup="true"
          onClick={this.handleProfileClick}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
          buttonRef={node => {
            this.anchorElProfile = node;
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
        </Button>
        <Popper
          open={profileOpen}
          anchorEl={this.anchorElProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !profileOpen,
            [classes.pooperResponsive]: true,
            [classes.pooperNav]: true
          })}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={this.handleProfileClose}>
                  <MenuList role="menu">
                    <MenuItem
                      // onClick={this.handleLogout}
                      className={dropdownItem}
                    >
                      {this.props.authUser && this.props.authUser.name}
                    </MenuItem>
                    {impersonatingUser && (
                      <MenuItem className={dropdownItem}>
                        Logged in as {userEmail}
                      </MenuItem>
                    )}
                    {impersonatingUser && (
                      <MenuItem
                        onClick={() => this.switchUser(impersonatingUser.email)}
                        className={dropdownItem}
                      >
                        Back to {impersonatingUser.email}
                      </MenuItem>
                    )}
                    {(this.props.authUser.role === "RE" ||
                      this.props.authUser.role === "WS" ||
                      this.props.authUser.role === "BR") && (
                        <MenuItem
                          onClick={this.handleProfileRedirect}
                          className={dropdownItem}
                        >
                          Partner Profile
                        </MenuItem>
                      )}

                    <MenuItem
                      onClick={this.handleProfileSection}
                      className={dropdownItem}
                    >
                      Consumer Profile
                    </MenuItem>

                    {this.props.authUser.businessUrl && (
                      <MenuItem
                        onClick={this.handleSiteSection}
                        className={dropdownItem}
                      >
                        Browse site
                      </MenuItem>
                    )}

                    {this.props.authUser.test_deploy_url && (
                      <MenuItem
                        onClick={this.handleTestSiteSection}
                        className={dropdownItem}
                      >
                        Browse Test site
                      </MenuItem>
                    )}

                    <MenuItem
                      onClick={this.handleLogout}
                      className={dropdownItem}
                    >
                      {rtlActive ? "إجلاء أوزار الأسيوي حين بل, كما" : "Logout"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  authUser: state.authState.user,
  publishState: state.partnerSate.publish,
  partner: state.authState.user

  // publish: {
  // 	isSaving: false,
  // 	isSavingSuccess: false,
  // 	error: null,
  // 	success: null
  // }
});

export default compose(
  withStyles(adminNavbarLinksStyle),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HeaderLinks);

//export default withStyles(adminNavbarLinksStyle)(HeaderLinks);
