export const GET_SEGMENT_REQUEST = "get_segment_request";
export const GET_SEGMENT_FAILURE = "get_segment_failure";
export const GET_SEGMENT_SUCCESS = "get_segment_success";

export const SAVE_SEGMENT_REQUEST = "save_asegment_request";
export const SAVE_SEGMENT_FAILURE = "save_segment_failure";
export const SAVE_SEGMENT_SUCCESS = "save_segment_success";

export const DELETE_SEGMENT_REQUEST = "delete_segment_request";
export const DELETE_SEGMENT_FAILURE = "delete_segment_failure";
export const DELETE_SEGMENT_SUCCESS = "delete_segment_success";

export const MAP_CLASS_SEGMENT_REQUEST = "map_class_segment_request";
export const MAP_CLASS_SEGMENT_FAILURE = "map_class_segment_failure";
export const MAP_CLASS_SEGMENT_SUCCESS = "map_class_segment_success";

export const MAP_SERVICE_SEGMENT_REQUEST = "map_service_segment_request";
export const MAP_SERVICE_SEGMENT_FAILURE = "map_service_segment_failure";
export const MAP_SERVICE_SEGMENT_SUCCESS = "map_service_segment_success";

export const AFTER_MAP_CLASS_SEGMENT_SUCCESS =
  "after_map_class_segment_success";

export const LIST_UNASSIGNED_WHOLESALER_REQUEST =
  "list_unassigned_wholesaler_request";
export const LIST_UNASSIGNED_WHOLESALER_FAILURE =
  "list_unassigned_wholesaler_failure";
export const LIST_UNASSIGNED_WHOLESALER_SUCCESS =
  "list_unassigned_wholesaler_success";

export const SAVE_RETAILER_WHOLESALER_MAPPING_REQUEST =
  "save_retailer_wholesaler_mapping_request";
export const SAVE_RETAILER_WHOLESALER_MAPPING_FAILURE =
  "save_retailer_wholesaler_mapping_failure";
export const SAVE_RETAILER_WHOLESALER_MAPPING_SUCCESS =
  "save_retailer_wholesaler_mapping_success";

export const GET_PARTNERS_INDUSTRY_SEGMENT_REQUEST = "GET_PARTNERS_INDUSTRY_SEGMENT_REQUEST"
export const GET_PARTNERS_INDUSTRY_SEGMENT_FAILURE = "GET_PARTNERS_INDUSTRY_SEGMENT_FAILURE"
export const GET_PARTNERS_INDUSTRY_SEGMENT_SUCCESS = "GET_PARTNERS_INDUSTRY_SEGMENT_SUCCESS"

