import * as appMenuService from "../../services/appMenuService";
import * as actionTypes from "./actionTypes";

export const getUsersMenuListAction = (user, type) => async dispatch => {
  dispatch(getUsersMenuListRequest(type));
  try {
    var success = await appMenuService.getUsersMenuList(user, type);

    if (success.status && success.status === "1") {
      dispatch(getUsersMenuListSuccess(success.data, type));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getUsersMenuListFailure(success.error, type));
    } else {
      dispatch(getUsersMenuListFailure("Some Error Occured.", type));
    }
  } catch (error) {
    dispatch(getUsersMenuListFailure(error.message, type));
  }
};

const getUsersMenuListRequest = type => {
  if (type) {
    return {
      type: actionTypes.MENU_LIST_REQUEST
    };
  } else {
    return {
      type: actionTypes.USER_BASED_MENU_LIST_REQUEST
    };
  }
};

const getUsersMenuListSuccess = (data, type) => {
  if (type) {
    return {
      type: actionTypes.MENU_LIST_SUCCESS,
      payload: data
    };
  } else {
    return {
      type: actionTypes.USER_BASED_MENU_LIST_SUCCESS,
      payload: data
    };
  }
};

const getUsersMenuListFailure = (error, type) => {
  if (type) {
    return {
      type: actionTypes.MENU_LIST_FAILURE,
      payload: error
    };
  } else {
    return {
      type: actionTypes.USER_BASED_MENU_LIST_FAILURE,
      payload: error
    };
  }
};


export const getPackagesMenuListAction = (user, type) => async dispatch => {
  dispatch(getPackagesMenuListRequest(type));
  try {
    var success = await appMenuService.getUsersMenuList(user, type);

    if (success.status && success.status === "1") {
      dispatch(getPackagesMenuListSuccess(success.data, type));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getPackagesMenuListFailure(success.error, type));
    } else {
      dispatch(getPackagesMenuListFailure("Some Error Occured.", type));
    }
  } catch (error) {
    dispatch(getPackagesMenuListFailure(error.message, type));
  }
};

const getPackagesMenuListRequest = () => {
  return {
    type: actionTypes.PACKAGE_MENU_LIST_REQUEST
  };
};

const getPackagesMenuListSuccess = (data) => {
  return {
    type: actionTypes.PACKAGE_MENU_LIST_SUCCESS,
    payload: data
  };
};

const getPackagesMenuListFailure = (error) => {
  return {
    type: actionTypes.PACKAGE_MENU_LIST_FAILURE,
    payload: error
  };
};

export const removeImageAction = entityid => async dispatch => {
  dispatch({
    type: actionTypes.IMAGE_REMOVE_REQUEST,
    payload: entityid
  });
};

export const denyRemoveImageAction = () => async dispatch => {
  dispatch({
    type: actionTypes.DENY_IMAGE_REMOVE_REQUEST
  });
};

export const confirmRemoveImageAction = entityid => async dispatch => {
  dispatch(removeImageRequest());
  try {
    var success = await appMenuService.removeImage(entityid);
    if (success.status && success.status === "1") {
      dispatch(removeImageSuccess(success.msg));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(removeImageFailure(success.error));
    } else {
      dispatch(removeImageFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(removeImageFailure(error.message));
  }
};

const removeImageRequest = () => {
  return {
    type: actionTypes.CONFIRM_IMAGE_REMOVE_REQUEST
  };
};

const removeImageSuccess = data => {
  return {
    type: actionTypes.CONFIRM_IMAGE_REMOVE_SUCCESS,
    payload: data
  };
};

const removeImageFailure = error => {
  return {
    type: actionTypes.CONFIRM_IMAGE_REMOVE_FAILURE,
    payload: error
  };
};
