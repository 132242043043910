import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import { DialogActions, DialogContent, DialogContentText, Dialog, Button } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/scss/react-draft-wysiwyg-wrapper.css";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import extendedFormStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import * as routesNames from "../../constants/routes";
import "./box.css";
import ProductWizard from "../ProductWizard/ProductWizard.jsx";
import AgGridActionButton from "../../components/AgGrid/RetailerProductAssignment";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import config from "../../constants/config";


const initialState = {
  isUpdateMode: false,
  selectedBrands: [],
  seacrhText: "",
  brands: [],
  products: [],
  addProduct: {
    id: "",
    name: "",
    brand_description: "",
    description: "",
    disclaimer: "",
    is_used: false,
    is_consumable: false,
    images: [],
    attributes: [
      {
        id: "",
        name: "",
        attribute_value: ""
      }
    ],
    categories: [],
    retailercategories: [],
    is_default_variant_product: false,
    segments: [],
    offers: [],
    brand: "",
    template: "",
    mrp: "",
    retailer_srp: "",
    msrp: 0,
    discount: "",
    editorState: "",
    product_template: {},
    variants: [],
    variantValues: []
  },
  required: {
    sort_order: "",
    description: "",
    name: "",
    msrp: 0,
    disclaimer: ""
  },
  isselectedtmp: null
};
class ProductsAssignmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      deletedId: null,
      isDeleted: false,
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false
      },
      assignedProducts: [],
      orgProducts: [],
      currentPageData: [],
      isAddNew: false,
      isClone: false,
      ChangePassword: {
        open: false,
        userid: null
      },
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 250,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "MSRP",
          field: "msrp",
          width: 150,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Product Type",
          field: "type",
          width: 150,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Description",
          field: "description",
          width: 300,
          resizable: true,
          sortable: true,
          filter: false,
          cellRenderer: function (params) {
            return (
              " <div dangerouslySetInnerHTML={{ __html:" +
              params.value +
              "}} />"
            );
          }
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 150,
          filter: false
        }
      ],
      ...initialState
    };
    this.handelEdit = this.handelEdit.bind(this);
    this.handleCloneProduct = this.handleCloneProduct.bind(this);
    this.handelActive = this.handelActive.bind(this);
    this.handelIsAddNew = this.handelIsAddNew.bind(this);
    this.handleProductExport = this.handleProductExport.bind(this);
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
    this.deletePopUp = this.deletePopUp.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }
  handleToggleBrands = selectedBrands => {
    this.setState({
      selectedBrands: [...selectedBrands]
    });
  };

  refreshList = () => {
    this.props.actions.getProductAssignmentList(
      this.props.partner_id,
      null,
      this.state.pagination.page
    );
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let targetPage = params.get("source");

    if (
      targetPage &&
      targetPage == "ptamap" &&
      this.props.User_Id.role === rolesNames.RoleMaster.RETAILER
    ) {
      this.props.actions.onNavigateTo(
        routesNames.PRODUCTTEMPLATESRETAILERCOMPONENT +
        "?entityType=retailer&id=" +
        this.props.partner_id
      );
      return;
    } else if (
      targetPage &&
      targetPage == "wsmap" &&
      this.props.User_Id.role === rolesNames.RoleMaster.RETAILER
    ) {
      this.props.actions.onNavigateTo(
        routesNames.RETAILERWHOLESALERCOMPONENT +
        "?entityType=retailer&id=" +
        this.props.partner_id
      );
      return;
    }

    this.refreshList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let targetPage = params.get("source");

        if (
          targetPage &&
          targetPage == "ptamap" &&
          this.props.User_Id.role === rolesNames.RoleMaster.RETAILER
        ) {
          this.props.actions.onNavigateTo(
            routesNames.PRODUCTTEMPLATESRETAILERCOMPONENT +
            "?entityType=retailer&id=" +
            this.props.partner_id
          );
          return;
        } else if (
          targetPage &&
          targetPage == "wsmap" &&
          this.props.User_Id.role === rolesNames.RoleMaster.RETAILER
        ) {
          this.props.actions.onNavigateTo(
            routesNames.RETAILERWHOLESALERCOMPONENT +
            "?entityType=retailer&id=" +
            this.props.partner_id
          );
          return;
        }
      }

      if (nextProps.delete && this.state.isDeleted) {
        this.setState({
          isDeleted: false,
          deletedId: null,
        }, () => {
          this.refreshList()
        })
      }
      let error = ''
      if (nextProps.ListError && this.state.isDeleted) {
        error = nextProps.ListError
      }
      this.setState({
        error: error
      })
      if (
        nextProps.productdata &&
        !_.isEqual(this.props.productdata, nextProps.productdata)
      ) {
        let product = nextProps.productdata;
        let data = {};
        data["id"] = product.id;
        data["name"] = product.name;
        data["description"] = product.description;
        data["brand"] = product.brand;
        data["retailer_srp"] =
          product.retailer_srp && product.retailer_srp > 0
            ? product.retailer_srp
            : "0";
        data["brand_description"] = product.brand_description
          ? product.brand_description
          : "";
        data["disclaimer"] = product.disclaimer;

        if (product.is_used == 1) {
          product.is_used = true;
        } else {
          product.is_used = false;
        }

        if (product.is_consumable == 1) {
          product.is_consumable = true;
        } else {
          product.is_consumable = false;
        }

        data["is_used"] = product.is_used;
        data["is_consumable"] = product.is_consumable;
        data["is_default_variant_product"] = product.is_default_variant_product
        data["mrp"] = product.msrp;

        let images = [];
        let imageObj = {
          id: "",
          image: "",
          imagePreviewUrl: ""
        };
        for (let i in product.images) {
          let new_image = {
            ...imageObj
          };
          new_image.id = product.images[i].id;
          new_image.image = product.images[i].url;
          new_image.imagePreviewUrl = product.images[i].url;
          new_image.is_brand_product_image =
            product.images[i].is_brand_product_image;
          images.push(new_image);
        }
        images.push(imageObj);

        data["images"] = images;
        data["attributes"] = product.attributes;
        data["product_template"] = product.product_template;
        data["variants"] = product.variants;
        data["variantValues"] = product.variantValues;

        let offers = [];
        for (let i in product.offers) {
          if (offers.indexOf(product.offers[i].offer.id) == -1) {
            offers.push(product.offers[i].offer.id);
          }
        }

        data["partner_id"] = nextProps.partnerId;
        data["offers"] = offers;
        data["retailercategories"] = product.retailercategories && product.retailercategories.length ? product.retailercategories : [];
        data["categories"] = product.categories && product.categories.length ? product.categories : [];
        data["segments"] = product.segments && product.segments.length ? product.segments : [];
        this.setState({
          isAddNew: true,
          isEdit: true,
          // isClone: false,
          addProduct: data
        });
      }
      if (
        nextProps.assignProductList &&
        nextProps.assignProductList.length > 0
      ) {
        const productListData = nextProps.assignProductList;
        this.setState({
          assignedProducts: productListData,
          pagination: nextProps.pagination
        });
      }
      if (nextProps.brandProductTemplates) {
        const brandProductTemplates = nextProps.brandProductTemplates.products;
        const productsList = [];
        for (let i = 0; i < brandProductTemplates.length; i++) {
          brandProductTemplates.findIndex(
            p => p.ptid === brandProductTemplates[i].ptid
          );
          if (
            productsList.findIndex(
              p => p.ptid === brandProductTemplates[i].ptid
            ) > -1
          ) {
          } else {
            productsList.push(brandProductTemplates[i]);
          }
        }
        this.setState(
          {
            products: productsList
          }
        );
      }
      if (nextProps.User_Id.status == 0) {
        this.setState({
          ChangePassword: {
            ...this.state.ChangePassword,
            open: true,
            userid: nextProps.User_Id.pk
          }
        });
      } else {
        this.setState({
          ChangePassword: {
            ...this.state.ChangePassword,
            open: false,
            userid: nextProps.User_Id.pk
          }
        });
      }
    }
  }

  assignToggelProduct = productName => {
    var { orgProducts } = this.state;
    var findProduct_index = orgProducts.findIndex(
      obj => obj.product_template.name.trim() === productName.trim()
    );
    if (findProduct_index >= 0) {
      orgProducts[findProduct_index].assigned = !orgProducts[findProduct_index]
        .assigned;
    }
    this.setState({
      orgProducts: orgProducts
    });
  };

  drawProductList = () => {
    const { selectedBrands, orgProducts, seacrhText } = this.state;
    var filteredTextProducts = orgProducts;
    if (seacrhText && seacrhText.trim().length > 0) {
      filteredTextProducts = orgProducts.filter(item =>
        item.name.toLowerCase().includes(seacrhText.trim().toLowerCase())
      );
    }
    var filterdProducts = filteredTextProducts;
    if (selectedBrands && selectedBrands.length > 0) {
      filterdProducts = filteredTextProducts.filter(item => {
        return (
          selectedBrands.filter(obj => obj.name === item.brandName).length > 0
        );
      });
    }
    return (
      <GridContainer>
        {filterdProducts.map((el, index) => {
          return (
            <GridItem md={4} sm={6}>
              <div
                style={{
                  border: "1px solid #D1D1D1",
                  boxSizing: "border-box",
                  borderRadius: "6px",
                  marginTop: "10px",
                  cursor: "pointer"
                }}
                className={el.assigned ? "box" : ""}
                onClick={() =>
                  this.assignToggelProduct(el.product_template.name)
                }
              >
                <div
                  style={{
                    minHeight: "61px",
                    marginTop: "12px"
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "20px",
                      lineHeight: "28px",
                      textAlign: "center",
                      color: "#EB9514",
                      display: "block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%"
                    }}
                    title={el.product_template.name}
                  >
                    {el.product_template.name}
                  </span>
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "21px",
                      textAlign: "center",
                      color: "#000000",
                      display: "block"
                    }}
                  >
                    {el.partner.name}
                  </span>
                </div>
                <div style={{ height: "162px" }}>
                  <img
                    style={{
                      boxShadow: "0px 0px 0px 0px",
                      padding: "5px",
                      maxHeight: "100%",
                      maxWidth: "100%"
                    }}
                    src={
                      el.images && el.images.length > 0
                        ? el.images[0].secure_url
                        : ""
                    }
                    alt="..."
                  />
                </div>
                <div style={{ height: "30px" }}>
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "18px",
                      lineHeight: "21px",
                      textAlign: "center",
                      color: "#666666",
                      display: "block"
                    }}
                  >
                    $ {el.price}
                  </span>
                </div>
              </div>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  changePasswordModelClose = () => {
    this.setState({
      ...this.state,
      ChangePassword: {
        ...this.state.ChangePassword,
        open: false
      }
    });
  };
  handleProductExport = () => {
    console.log("AM I CALLED", )

    let reqUrl = `${config.env.API_URL}api/product/export-product/?partner_id=${this.props.User_Id.partner_id}&partner_type=${this.props.User_Id.role}`

    var win = window.open(reqUrl, '_blank');
    setTimeout(() => {
      // win.close();
    }, 500)
  }
  renderSegmentDetailsTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.deletePopUp()}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Products Unit
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.assignedProducts}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                handleProductExport={this.props.User_Id.role === 'RE' ? this.handleProductExport : null}
                handleCloneProduct={this.handleCloneProduct}
                handelView={this.handelActive}
                loading={this.props.Loading}
                AgGridActionButton={AgGridActionButton}
                handleDelete={this.handleDelete}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                search={this.searchText}
                new={this.handelIsAddNew}
                isAddBtn={true}
                isProductExportBtn={this.props.User_Id.role === 'RE' ? true : false}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  handleDelete(id) {
    this.setState({
      deletedId: id,
      isDeleted: true
    })
  }

  deletePopUp() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.isDeleted}
        disableBackdropClick={true}
        onClose={() => this.closeDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete?{" "}
          </DialogContentText>
          {this.state.error && <code>
            {this.state.error}
          </code>}
          <DialogActions>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              color="primary"
              onClick={() => this.deleteAction()}
            >
              Delete
            </Button>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              onClick={() => this.closeDialog()}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }

  deleteAction() {
    this.props.actions.deleteProductAction(this.state.deletedId)
  }

  closeDialog() {
    this.setState({
      isDeleted: false,
      deletedId: null
    })
  }

  updateGridData(page) {
    this.props.actions.getProductAssignmentList(
      this.props.partner_id,
      null,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getProductAssignmentList(
          this.props.partner_id,
          null,
          page,
          param
        );
      }
    );
  }

  handelEdit = id => {
    this.props.actions.getProductByIdAction(id);
  };
  handleCloneProduct = id => {
    this.setState({
      //   isAddNew: true,
      //   isEdit: true,
      isClone: true
    })
    this.props.actions.getProductByIdAction(id);
  }
  handelActive = id => {
    this.props.actions.markProductActiveAction(id, this.props.User_Id.pk);
  };

  handelIsAddNew = () => {
    this.setState({
      addProduct: {
        ...initialState.addProduct,
        partner_id: this.props.partner_id
      },
      isAddNew: true,
      isEdit: false,
      isClone: false,
    });
  };

  handleCloseNew = () => {
    this.setState({
      addProduct: {
        ...initialState.addProduct,
        attributes: [
          {
            id: "",
            name: "",
            attribute_value: ""
          }
        ],
        images: [],
        partner_id: this.props.partner_id
      },
      isAddNew: false,
      isEdit: false,
      isClone: false,
    }, () => {
      this.refreshList();
    });
  };

  render() {
    return this.state.isAddNew ? (
      <ProductWizard
        data={this.state.addProduct}
        isEdit={this.state.isEdit}
        isClone={this.state.isClone}
        close={this.handleCloseNew}
        productList={this.state.assignedProducts}
      />
    ) : (
      this.renderSegmentDetailsTable()
    );
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.retailerState.list,
  Loading: state.productsAssignmentsState.loading,
  delete: state.productsAssignmentsState.isdeleted,
  ListError: state.productsAssignmentsState.error,
  loadingGetData: state.retailerState.loadingData,
  isSaved: state.productsAssignmentsState.isSaved,
  UserKey: state.authState.user.Token,
  brandProduct: state.retailerState.brandPrpductList,
  isSaving: state.productsAssignmentsState.isSaving,
  assignProductList: state.productsAssignmentsState.list,
  UserId: state.authState.user.user,
  User_Id: state.authState.user,
  partner_id: state.authState.user.partner_id,
  brandProductTemplates:
    state.brandState.map_Retailer_brand_producttemplate_Selected,
  productdata: state.productsAssignmentsState.productById.data,
  pagination: state.productsAssignmentsState.pagination
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({ ...regularFormsStyle, ...extendedFormStyle }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductsAssignmentPage);
