// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const savePartnerMapping = async (
  selected_nodes,
  partnerid,
  userid,
  actionid
) => {
  try {
    let data = new FormData();
    data.append("selected_nodes", JSON.stringify(selected_nodes));
    data.append("partnerid", partnerid);
    data.append("actionid", actionid);
    data.append("userid", userid);
    const response = await axios.post(
      "/api/partner/map-brand-wholesalor-reatiler/save/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removePartnerMapping = async (
  selected_nodes,
  partnerid,
  userid,
  actionid
) => {
  try {
    let data = new FormData();
    data.append("selected_nodes", JSON.stringify(selected_nodes));
    data.append("partnerid", partnerid);
    data.append("actionid", actionid);
    data.append("userid", userid);
    const response = await axios.post(
      "/api/partner/map-brand-wholesalor-reatiler/delete/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPartnerMapping = async partnerid => {
  try {
    const response = await axios.get(
      "/api/partner/partners-mapping/get/?partnerid=" + partnerid,
      null,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
