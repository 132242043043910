import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";

// core components
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ImageUpload from "../../components/ImageUpload.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import InputLabel from "@material-ui/core/InputLabel";

import FormControl from "@material-ui/core/FormControl";

const Style = {
  cardTitle: {
    "font-size": "x-large",
    "font-weight": "normal"
  },
  title: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "bold",
    "font-size": "36px",
    "line-height": "42px",
    "text-align": "center",
    color: "#666666"
  },
  titlePara: {
    "text-align": "center"
  },
  tabHeader: {
    color: "#919699"
  },
  selectedTabHeader: {
    color: "#F44336"
  },
  tabHeaderDiv: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "14px",
    "line-height": "16px",
    display: "flex",
    "align-items": "center",
    "text-align": "center",
    "letter-spacing": "0.75px",
    "text-transform": "uppercase",
    display: "inline-block",
    padding: "15px",
    cursor: "pointer",
    width: "16.66%"
  },
  questionHead: {
    "font-weight": "500"
  },
  pagination: {
    "font-weight": "normal",
    "font-size": "14px",
    "line-height": "20px",
    color: "#666666"
  }
};

const initialState = {
  total_page: 2,
  current_page: 1
};

class ContentTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        ...initialState
      },
      campaignData: {},
      image: ""
    };
    this.clickNextBtn = this.clickNextBtn.bind(this);
    this.clickPreviousBtn = this.clickPreviousBtn.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.saveCampign = this.saveCampign.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (!_.isEqual(this.props.data, nextProps.data)) {
          this.setState({
            campaignData: nextProps.data
          });
        }
      }

      if (!_.isEqual(this.props.data, nextProps.data)) {
        this.setState({
          campaignData: nextProps.data
        });
      }

      const channels_count = nextProps.data.channels.length;
      if (channels_count > 0) {
        this.setState({
          ...this.state,
          pagination: {
            ...this.state.pagination,
            total_page: channels_count
          }
        });
      }

      if (nextProps.isImageDeleted) {
        const { channels } = this.state.campaignData;
        const { current_page } = this.state.pagination;
        channels[current_page - 1].image = "";
        channels[current_page - 1].file = null;
        delete channels[current_page - 1].file_size;
        delete channels[current_page - 1].original_file_name;
        this.setState(
          {
            ...this.state,
            campaignData: {
              ...this.state.campaignData,
              channels: channels
            }
          },
          () => {
            let { channels } = this.state.campaignData;
            this.props.setPromotionData("channel", channels);
            this.props.actions.denyRemoveImageAction();
          }
        );
      }
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState(
        {
          ...this.state,
          campaignData: this.props.data
        },
        () => {
          const channels_count = this.state.campaignData.channels.length;
          if (channels_count > 0) {
            this.setState({
              ...this.state,
              pagination: {
                ...this.state.pagination,
                total_page: channels_count
              }
            });
          }
        }
      );
    }
  }

  render() {
    const { current_page, total_page } = this.state.pagination;
    return (
      <Grid>
        <GridContainer>
          <GridItem lg={2} md={2} />
          <GridItem lg={8} md={8} sm={12} xs={12}>
            <GridContainer>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <div style={Style.pagination}>
                  Page: {current_page}/{total_page}
                </div>
                {this.showContentDataBasedOnPage()}
              </GridItem>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <GridContainer>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button onClick={this.clickPreviousBtn}>Previous</Button>
                  </GridItem>
                  <GridItem lg={2} md={2} sm={2} xs={2}>
                    {current_page < this.state.pagination.total_page ? (
                      <Button onClick={this.clickNextBtn}>Next</Button>
                    ) : (
                      ""
                    )}
                  </GridItem>
                  <GridItem lg={4} md={4} sm={4} xs={4}>
                    <Button
                      disabled={this.props.isSaving}
                      onClick={() => this.saveCampign(1)}
                    >
                      {this.props.editState ? "Update & Close" : "Save & Close"}
                    </Button>
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button
                      onClick={this.closeWizard}
                      style={{
                        "margin-left": "10px"
                      }}
                    >
                      Close
                    </Button>
                  </GridItem>
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    <GridContainer>
                      <GridItem lg={3} md={3} sm={3} xs={3}>
                        {!this.state.campaignData.is_active ? (
                          <Button
                            disabled={this.props.isSaving}
                            onClick={() => this.saveCampign(2)}
                          >
                            Publish
                          </Button>
                        ) : (
                          ""
                        )}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem lg={2} md={2} />
        </GridContainer>
      </Grid>
    );
  }

  closeWizard() {
    this.props.closePromotion();
  }

  clickNextBtn() {
    // this.refs.myRefs.value=''

    let { current_page, total_page } = this.state.pagination;
    let new_page = current_page + 1;
    if (new_page <= total_page) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            current_page: new_page
          }
        },
        () => {}
      );
    } else {
      this.props.changeSelectedTab(1);
    }
  }

  clickPreviousBtn() {
    let { current_page } = this.state.pagination;
    let new_page = current_page - 1;
    if (new_page >= 1) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            current_page: new_page
          }
        },
        () => {}
      );
    } else {
      if (this.props.hidePartner) {
        this.props.changeSelectedTab(-2);
      } else {
        this.props.changeSelectedTab(-1);
      }
    }
  }

  showContentDataBasedOnPage() {
    const { classes } = this.props;
    const { current_page } = this.state.pagination;
    const { channels } = this.state.campaignData;
    let imageUrl =
      channels && channels[current_page - 1] && channels[current_page - 1].image
        ? channels[current_page - 1].image instanceof Object
          ? channels[current_page - 1].image instanceof Blob
            ? channels[current_page - 1].image
            : channels[current_page - 1].image.url
          : channels[current_page - 1].image
        : "";
    return (
      <GridContainer>
        {channels &&
        channels.length > 0 &&
        channels[current_page - 1] &&
        channels[current_page - 1].channelName &&
        channels[current_page - 1].channelDistName ? (
          <GridItem>
            <div className="campaign-info-label">
              <div style={Style.questionHead}>
                {" "}
                Upload Image for{" "}
                {channels &&
                channels[current_page - 1] &&
                channels[current_page - 1].channelDistName
                  ? channels[current_page - 1].channelDistName
                  : "abc"}{" "}
                in{" "}
                {channels &&
                channels[current_page - 1] &&
                channels[current_page - 1].channelName
                  ? channels[current_page - 1].channelName
                  : "pqr"}
              </div>
            </div>
            <GridContainer>
              <FormControl
                style={{
                  "margin-left": "17px"
                }}
              >
                <InputLabel
                  // htmlFor="simple-select"
                  className={classes.label + " "}
                  // labelText="Offers *"
                >
                  Image
                </InputLabel>
                <br />
                <br />
                <ImageUpload
                  ref="image"
                  // id={index}
                  addButtonProps={{
                    round: true
                  }}
                  changeButtonProps={{
                    color: "rose",
                    round: true
                  }}
                  removeButtonProps={{
                    color: "danger",
                    round: true
                  }}
                  imgUrl={imageUrl}
                  onimageRemove={() => this.handelImageRemove()}
                  onChange={file => this.handelImageChange(file)}
                />
              </FormControl>
            </GridContainer>
          </GridItem>
        ) : (
          <GridItem>
            {channels && channels.length && (
              <span style={{ color: "red" }}>
                {channels[current_page - 1].channelName
                  ? "Please Select the Channels for which you want to Upload Image"
                  : "Please Select the Channel Distribution for which you want to Upload Image"}
              </span>
            )}
          </GridItem>
        )}
      </GridContainer>
    );
  }

  handelImageRemove = () => {
    let { current_page } = this.state.pagination;
    let { channels } = this.state.campaignData;
    let image = channels[current_page - 1].image;

    if (image instanceof Blob || typeof image == "string") {
      channels[current_page - 1].image = "";
      channels[current_page - 1].file = null;
      delete channels[current_page - 1].file_size;
      delete channels[current_page - 1].original_file_name;
      this.setState(
        {
          ...this.state,
          campaignData: {
            ...this.state.campaignData,
            channels: channels
          }
        },
        () => {
          let { channels } = this.state.campaignData;
          this.props.setPromotionData("channel", channels);
        }
      );
    } else if (image instanceof Object) {
      this.props.actions.removeImageAction(image.id);
    }
  };

  // handelImageRemove = () => {
  //     let { current_page } = this.state.pagination
  //     let { channels } = this.state.campaignData;
  //     channels[current_page-1].image = ""
  //     channels[current_page-1].file = null
  //     delete channels[current_page-1].file_size
  //     delete channels[current_page-1].original_file_name
  //     this.setState({
  //         ...this.state,
  //         campaignData: {
  //             ...this.state.campaignData,
  //             channels: channels,
  //         }
  //     }, () =>{
  //         let { channels } = this.state.campaignData;
  //         this.props.setPromotionData('channel', channels)
  //     });
  // }

  handelImageChange = file => {
    if (file instanceof Blob) {
      const { channels } = this.state.campaignData;
      let { current_page } = this.state.pagination;
      channels[current_page - 1].image = file;
      channels[current_page - 1].file = file;
      channels[current_page - 1].file_size = file.size;
      channels[current_page - 1].original_file_name = file.name;
      this.setState(
        {
          ...this.state,
          campaignData: {
            ...this.state.campaignData,
            channels: channels
          }
        },
        () => {
          this.props.setPromotionData("channel", channels);
        }
      );
    } else {
      const { channels } = this.state.campaignData;
      let { current_page } = this.state.pagination;
      channels[current_page - 1].image = file.url;
      if (file.is_asset) {
        channels[current_page - 1].asset_id = file.id;
      }
      // channels[current_page-1].file = reader.file;
      channels[current_page - 1].file_size = file.file_size;
      channels[current_page - 1].original_file_name = file.original_file_name;
      this.setState(
        {
          ...this.state,
          campaignData: {
            ...this.state.campaignData,
            channels: channels
          }
        },
        () => {
          this.props.setPromotionData("channel", channels);
        }
      );
    }
  };

  saveCampign = filter => {
    this.props.save(filter);
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  retailers: state.partnerSate.brandPartner.list,
  channels: state.campignManagementReducer.channels.list,
  channelDistribution: state.campignManagementReducer.channeldistribution.list,
  List: state.campignManagementReducer.list,
  Loading: state.campignManagementReducer.loading,
  ListError: state.campignManagementReducer.error,
  isSaved: state.campignManagementReducer.isSaved,
  PartnerId: state.authState.user.partner_id,
  User: state.authState.user,
  UserId: state.authState.user.user,
  playPause: state.campignManagementReducer.playPause,
  deleteCampaign: state.campignManagementReducer.deleteCampaign,
  isSaving: state.campignManagementReducer.isSaving,
  isImageDeleted: state.appMenuReducer.removeImage.isDeleted,
  selectedImageId: state.appMenuReducer.removeImage.data
});

ContentTab.propTypes = {
  closePromotion: PropTypes.func.isRequired,
  changeSelectedTab: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setPromotionData: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  hidePartner: PropTypes.bool.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ContentTab);
