import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  list: null,
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  isSaving: false,
  isDeleted: false,
  isMapSaved: false,
  category: {
    data: null,
    loading: false,
    loadingData: false
  },
  class_by_id: {
    data: null,
    loading: false,
    error: null
  },
  pagination: {
    page: "1",
    next: null,
    prev: null,
    totalpages: 1,
    trecords: 0,
    pagesize: 10
  },
  partner_classes: {
    data: null,
    loading: false,
    error: null,
  }
};

const classReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_CLASS_REQUEST:
      return {
        ...state,
        list: null,
        loadingData: true,
        error: null,
        isSaved: false,
        pagination: {
          page: "1",
          next: null,
          prev: null,
          totalpages: 1,
          trecords: 0,
          pagesize: 10
        }
      };
    case actionTypes.GET_CLASS_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false
      };
    case actionTypes.GET_CLASS_SUCCESS:
      let pagination = _.clone(state.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        loadingData: false,
        error: null,
        list: sdata,
        isSaved: false,
        pagination: {
          ...pagination
        }
      };
    case actionTypes.SAVE_CLASS_REQUEST:
      return {
        ...state,
        loadingData: true,
        error: null,
        isSaved: false,
        isSaving: true
      };
    case actionTypes.SAVE_CLASS_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false,
        isSaving: false
      };
    case actionTypes.SAVE_CLASS_SUCCESS:
      // var data = state.list;
      // var index = data.findIndex(p => p.id === action.payload.id);
      // let list = []

      // if (index > -1) {
      // 	// data[index] = action.payload;
      // 	list.push(action.payload)
      // 	for (let i = 0; i < data.length; i++) {
      // 		if (i !== index) {
      // 			list.push(data[i])
      // 		}

      // 	}
      // } else {

      // 	list.push(action.payload)
      // 	for (let i = 0; i < data.length; i++) {
      // 		list.push(data[i])
      // 	}
      // }
      return {
        ...state,
        loadingData: false,
        error: null,
        // list: list,
        isSaved: true,
        isMapSaved: false,
        isSaving: false
      };
    case actionTypes.DELETE_CLASS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        isDeleted: false
      };
    case actionTypes.DELETE_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        isDeleted: false
      };
    case actionTypes.DELETE_CLASS_SUCCESS:
      var data = state.list;
      let segmentIndex = data.findIndex(o => o.id === action.class_id);
      data.splice(segmentIndex, 1);
      return {
        ...state,
        loading: false,
        error: null,
        list: data,
        isSaved: false,
        isDeleted: true
      };
    case actionTypes.MAP_CLASS_UA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        isDeleted: false,
        isMapSaved: false
      };
    case actionTypes.MAP_CLASS_UA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        isDeleted: false,
        isMapSaved: false
      };
    case actionTypes.MAP_CLASS_UA_SUCCESS:
      var data = state.list;
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data[index] = action.payload;
      }
      return {
        ...state,
        loading: false,
        error: null,
        isSaved: true,
        isDeleted: false,
        isMapSaved: true,
        list: data
      };
    case actionTypes.AFTER_MAP_CLASS_UA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isSaved: true,
        isDeleted: false,
        isMapSaved: false
      };

    case actionTypes.SAVE_CATEGORY_REQUEST:
      return {
        ...state,
        category: {
          data: null,
          error: null,
          loading: true
        }
      };
    case actionTypes.SAVE_CATEGORY_FAILURE:
      return {
        ...state,
        category: {
          data: null,
          error: action.payload,
          loading: false
        }
      };
    case actionTypes.SAVE_CATEGORY_SUCCESS:
      var data = _.clone(state.list);
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data[index] = action.payload;
      } else {
        data.push(action.payload);
      }
      return {
        ...state,
        list: data,
        category: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case actionTypes.GET_CLASS_BYID_REQUEST:
      return {
        ...state,
        class_by_id: {
          data: null,
          loading: true,
          error: null
        },
        isSaved: false
      };
    case actionTypes.GET_CLASS_BYID_FAILURE:
      return {
        ...state,
        class_by_id: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_CLASS_BYID_SUCCESS:
      return {
        ...state,
        class_by_id: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    case actionTypes.GET_PARTNER_CLASSES_REQUEST:
      return {
        ...state,
        partner_classes: {  
          ...state.partner_classes,
          data: null,
          loading: true,
          error: null,
        }
      };
    case actionTypes.GET_PARTNER_CLASSES_FAILURE:
      return {
        ...state,
        partner_classes: {
          ...state.partner_classes,
          data: null,
          loading: false,
          error: action.payload,
        }
      };
    case actionTypes.GET_PARTNER_CLASSES_SUCCESS:
      return {
        ...state,
        partner_classes: {
          ...state.partner_classes,
          data: action.payload,
          loading: false,
          error: null,
        }
      };
    default:
      return state;
  }
};

export default classReducer;
