import React, { Component } from "react";

class TextBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
    this.performAction = this.performAction.bind(this);
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.props.data
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({
        data: nextProps.data
      });
    }
  }

  performAction = event => {
    console.log(event);
    let { data } = this.state;
    this.props.context.attributes.setData(data);
  };

  handleChange = event => {
    this.setState({
      data: {
        ...this.state.data,
        description: event.target.value
      }
    });
  };

  render() {
    console.log(this.props.data);
    const { description } = this.state.data;
    return (
      <input
        type="text"
        value={description ? description : ""}
        className="ag-grid-textbox"
        onBlur={this.performAction}
        onChange={this.handleChange}
      />
    );
  }
}
export default TextBoxComponent;
