import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import withAuthorization from "../Session/withAuthorization";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { rolesNames } from "../../constants";

class ListWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgData: {
        subhead: "",
        bodyCopy: "",
        file: null,
        imagePreviewUrl: ""
      },
      editData: {
        subhead: "",
        bodyCopy: "",
        file: null,
        imagePreviewUrl: ""
      },
      isEdit: false
    };
  }

  handleTextChange = key => event => {
    var editData = this.state["editData"];
    editData[key] = event.target.value;
    this.setState({ editData: editData });
  };

  componentDidMount() {
    if (
      this.props.List &&
      this.props.List.length > 0 &&
      this.props.List[0]["about"]
    ) {
      const aboutUSData = this.props.List[0]["about"];
      this.setState(() => ({
        editData: { ...aboutUSData },
        orgData: { ...aboutUSData }
      }));
    } else {
      this.props.actions.getRetailer(this.props.UserKey);
    }
  }
  saveReatiler = () => {
    var data = {
      about: this.state.editData
    };
    this.props.actions.saveRetailer(
      data,
      this.props.UserKey,
      null,
      "",
      "about"
    );
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        nextProps.List[0]["about"] &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const aboutUSData = nextProps.List[0]["about"];
        this.setState(() => ({
          editData: { ...aboutUSData },
          orgData: { ...aboutUSData }
        }));
      }
      if (nextProps.isSaved) {
        const stateAoutUSData = this.state.editData;
        this.setState(() => ({
          orgData: { ...stateAoutUSData },
          isEdit: false
        }));
      }
    }
  }
  handelEdit = () => {
    const { isEdit, orgData } = this.state;
    if (isEdit) {
      this.setState(() => ({
        editData: { ...orgData }
      }));
    }
    this.setState({
      isEdit: !this.state.isEdit
    });
  };

  handelImageRemove = () => {
    var { editData } = this.state;
    editData["file"] = null;
    delete editData.imagePreviewUrl;
    this.setState({
      editData: editData
    });
  };
  handelImageChange = file => {
    let reader = new FileReader();

    reader.onloadend = () => {
      var { editData } = this.state;
      editData["file"] = file;
      editData["imagePreviewUrl"] = reader.result;
      this.setState({
        editData: editData
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const { classes } = this.props;
    const { subhead, bodyCopy, image } = this.state.editData;
    const { isEdit } = this.state;
    return (
      <GridContainer>
        {!isEdit && (
          <GridItem xs={12}>
            <Grid
              container
              spacing={16}
              alignItems={"flex-end"}
              direction={"row"}
              justify={"flex-end"}
            >
              <Button
                color="success"
                onClick={this.handelEdit}
                className={classes.marginRight}
              >
                <EditIcon className={classes.icons} />
                Edit
              </Button>
            </Grid>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <Loader fullPage loading={false} text="save" />

          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Icon style={{ fontSize: "36px" }}>info</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    labelText="Subhead"
                    id="subhead"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: subhead,
                      onChange: this.handleTextChange("subhead"),
                      disabled: !isEdit
                    }}
                  />
                  <CustomInput
                    labelText="Body Copy"
                    id="body_copy"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: bodyCopy,
                      onChange: this.handleTextChange("bodyCopy"),
                      disabled: !isEdit
                    }}
                  />
                  <br />
                  <br />
                  <br />
                  <legend>Photo</legend>

                  {isEdit ? (
                    <ImageUpload
                      addButtonProps={{
                        round: true
                      }}
                      changeButtonProps={{
                        color: "rose",
                        round: true
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true
                      }}
                      imgUrl={image ? image.secure_url : null}
                      onimageRemove={this.handelImageRemove}
                      onChange={this.handelImageChange}
                    />
                  ) : (
                    <div
                      className={classes.imgContainer}
                      style={{ width: "80px" }}
                    >
                      <img
                        src={image ? image.secure_url : ""}
                        alt="..."
                        className={classes.img}
                        style={{ height: "80px", width: "auto" }}
                      />
                    </div>
                  )}

                  <br />
                  <br />

                  {isEdit && (
                    <Button color="info" onClick={this.saveReatiler}>
                      Save Changes
                    </Button>
                  )}
                  {isEdit && (
                    <Button onClick={this.handelEdit}> Cancel </Button>
                  )}
                  {(this.props.Loading || this.props.loadingGetData) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Saving.." />
                  <Loader
                    loading={this.props.loadingGetData}
                    text="Loading.."
                  />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.retailerState.list,
  Loading: state.retailerState.loading,
  ListError: state.retailerState.error,
  loadingGetData: state.retailerState.loadingData,
  isSaved: state.retailerState.isSaved,
  UserKey: state.authState.user.Token
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles(regularFormsStyle),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ListWebsite); //withStyles(regularFormsStyle)(AboutUs);
