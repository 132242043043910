import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";
import AssetManagementComponent from "../../views/components/assetManagementComponent.jsx";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      openAssetModal: false,
      imagePreviewUrl: this.props.imgUrl
        ? this.props.imgUrl
        : this.props.avatar
        ? defaultAvatar
        : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getSelectedImage = this.getSelectedImage.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
    this.props.onChange(file);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleAssetClick() {
    this.setState({
      openAssetModal: true
    });
  }
  closeModal() {
    this.setState({
      openAssetModal: false
    });
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.imgUrl
        ? this.props.imgUrl
        : this.props.avatar
        ? defaultAvatar
        : defaultImage
    });
    this.refs.fileInput.value = null;
    this.props.onimageRemove();
  }
  getSelectedImage(data) {
    this.setState(
      {
        file: data,
        imagePreviewUrl:
          data &&
          data[0] &&
          data[0].document_object &&
          data[0].document_object.secure_url
            ? data[0].document_object.secure_url
            : ""
      },
      () => {
        this.props.onChange(data);
        this.closeModal();
      }
    );
  }
  render() {
    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      hideAssetBtn
    } = this.props;
    return (
      <div className="fileinput text-center">
        {this.state.openAssetModal ? (
          <AssetManagementComponent
            open={this.state.openAssetModal}
            close={this.closeModal}
            onSelection={this.getSelectedImage}
          />
        ) : (
          ""
        )}
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
          <img src={this.state.imagePreviewUrl} alt="..." />
        </div>
        <div>
          {this.state.file === null ? (
            <div>
              <Button {...addButtonProps} onClick={() => this.handleClick()}>
                {avatar ? "Add Photo" : "Select image"}
              </Button>
              {hideAssetBtn ? (
                ""
              ) : (
                <Button
                  {...addButtonProps}
                  onClick={() => this.handleAssetClick()}
                >
                  {avatar ? "Add Photo" : "Select Asset"}
                </Button>
              )}
            </div>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                Change
              </Button>
              {avatar ? <br /> : null}
              <Button
                {...removeButtonProps}
                onClick={() => this.handleRemove()}
              >
                <i className="fas fa-times" /> Remove
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default ImageUpload;
