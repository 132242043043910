export const GET_PAGE_LIST_REQUEST = "get_page_list_request";
export const GET_PAGE_LIST_FAILURE = "get_page_list_failure";
export const GET_PAGE_LIST_SUCCESS = "get_page_list_success";

export const GET_PAGE_BYID_REQUEST = "get_page_byid_request";
export const GET_PAGE_BYID_FAILURE = "get_page_byid_failure";
export const GET_PAGE_BYID_SUCCESS = "get_page_byid_success";

export const CREATE_PAGE_REQUEST = "create_page_request";
export const CREATE_PAGE_FAILURE = "create_page_failure";
export const CREATE_PAGE_SUCCESS = "create_page_success";

// export const CREATE_PAGE_REQUEST = 'create_page_request';
// export const CREATE_PAGE_FAILURE = 'create_page_failure';
// export const CREATE_PAGE_SUCCESS = 'create_page_success';

export const CREATE_CMS_MENU_REQUEST = "create_cms_menu_request";
export const CREATE_CMS_MENU_FAILURE = "create_cms_menu_failure";
export const CREATE_CMS_MENU_SUCCESS = "create_cms_menu_success";

export const COPY_PAGE_PARTNER_REQUEST = "copy_page_partner_request";
export const COPY_PAGE_PARTNER_FAILURE = "copy_page_partner_failure";
export const COPY_PAGE_PARTNER_SUCCESS = "copy_page_partner_success";

export const GET_ASSIGN_PARTNER_PAGE_REQUEST =
  "get_assign_partner_page_request";
export const GET_ASSIGN_PARTNER_PAGE_FAILURE =
  "get_assign_partner_page_failure";
export const GET_ASSIGN_PARTNER_PAGE_SUCCESS =
  "get_assign_partner_page_success";
