import * as reportServices from "../../services/reportServices";
import * as actionTypes from "./actionTypes";

export const settlementReportAction = (
  userid,
  start_date,
  end_date,
  status
) => async dispatch => {
  dispatch(settlementReportRequest());
  try {
    let successnew = await reportServices.settlementReport(
      userid,
      start_date,
      end_date,
      status
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(settlementReportSuccess(successnew.data));
    } else {
      dispatch(settlementReportFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(settlementReportFailure(error.message));
  }
};

const settlementReportRequest = () => {
  return {
    type: actionTypes.SETTLEMENT_REPORT_REQUEST
  };
};

const settlementReportSuccess = data => {
  return {
    type: actionTypes.SETTLEMENT_REPORT_SUCCESS,
    payload: data
  };
};

const settlementReportFailure = error => {
  return {
    type: actionTypes.SETTLEMENT_REPORT_FAILURE,
    payload: error
  };
};

export const paymentReportAction = (
  userid,
  start_date,
  end_date,
  status
) => async dispatch => {
  dispatch(paymentReportRequest());
  try {
    let successnew = await reportServices.paymentReport(
      userid,
      start_date,
      end_date,
      status
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(paymentReportSuccess(successnew.data));
    } else {
      dispatch(paymentReportFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(paymentReportFailure(error.message));
  }
};

const paymentReportRequest = () => {
  return {
    type: actionTypes.PAYMENT_REPORT_REQUEST
  };
};

const paymentReportSuccess = data => {
  return {
    type: actionTypes.PAYMENT_REPORT_SUCCESS,
    payload: data
  };
};

const paymentReportFailure = error => {
  return {
    type: actionTypes.PAYMENT_REPORT_FAILURE,
    payload: error
  };
};

export const orderReportAction = (
  userid,
  start_date,
  end_date,
  status
) => async dispatch => {
  dispatch(orderReportRequest());
  try {
    let successnew = await reportServices.orderReport(
      userid,
      start_date,
      end_date,
      status
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(orderReportSuccess(successnew.data));
    } else {
      dispatch(orderReportFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(orderReportFailure(error.message));
  }
};

const orderReportRequest = () => {
  return {
    type: actionTypes.ORDER_REPORT_REQUEST
  };
};

const orderReportSuccess = data => {
  return {
    type: actionTypes.ORDER_REPORT_SUCCESS,
    payload: data
  };
};

const orderReportFailure = error => {
  return {
    type: actionTypes.ORDER_REPORT_FAILURE,
    payload: error
  };
};

export const downloadProductReportAction = (
  report_type,
  partner_id
) => async dispatch => {
  dispatch(downloadProductReportRequest());
  try {
    let successnew = await reportServices.downloadProductReport(
      report_type,
      partner_id
    );
    if (successnew.data) {
      if (successnew.data.status && successnew.data.status == 0 ){
        dispatch(downloadProductReportFailure(successnew.data.error));
      }else{
        dispatch(downloadProductReportSuccess(successnew.data));
      }
    } else {
      dispatch(downloadProductReportFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(downloadProductReportFailure(error.message));
  }
};

const downloadProductReportRequest = () => {
  return {
    type: actionTypes.DOWNLOAD_REPORT_REQUEST,
  };
};

const downloadProductReportSuccess = data => {
  return {
    type: actionTypes.DOWNLOAD_REPORT_SUCCESS,
    payload: data
  };
};

const downloadProductReportFailure = error => {
  return {
    type: actionTypes.DOWNLOAD_REPORT_FAILURE,
    payload: error
  };
};

export const partnerReportOptionAction = (
  partner_type
  
) => async dispatch => {
  dispatch(partnerReportOptionRequest());
  try {
    let successnew = await reportServices.partnerReportOptions(
      partner_type,
    );
    if (successnew.status && successnew.status == 1 ){
      dispatch(partnerReportReportSuccess(successnew.data));
    }else{
      dispatch(partnerReportOptionFailure(successnew.error));
    }
  } catch (error) {
    dispatch(partnerReportOptionFailure(error.message));
  }
};

const partnerReportOptionRequest = () => {
  return {
    type: actionTypes.REPORT_OPTION_REQUEST,
  };
};

const partnerReportReportSuccess = data => {
  return {
    type: actionTypes.REPORT_OPTION_SUCCESS,
    payload: data
  };
};

const partnerReportOptionFailure = error => {
  return {
    type: actionTypes.REPORT_OPTION_FAILURE,
    payload: error
  };
};