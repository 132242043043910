import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

import { FormLabel, Select, MenuItem, Grid, Button } from "@material-ui/core";

import Swal from "sweetalert2";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import ProductVariantView from "./product-variant-group-view";
import { PartnerRoles } from "../../constants/roles";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import ProductVariantAction from "../../components/AgGrid/productVariantAction";
import BrandProductHierarchy from "../../components/BrandProductHierarchy/brand-product-hierarchy";

class ProdcutVariantGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNew: false,
      isMapping: false,
      ViewId: "",
      partner_type: "BR",
      partner: "",
      variant_list: [],
      searchtext: null,
      variant: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false
      },
      columnDefs: [
        {
          headerName: "id",
          field: "id",
          width: 150,
          resizable: true,
          sortable: false
        },
        {
          headerName: "NAME",
          field: "name",
          width: 200,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter"
        },
        {
          headerName: "ACTIVE",
          field: "is_active",
          width: 120,
          resizable: true,
          sortable: true
        },
        {
          headerName: "ACTIONS",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ],
    };
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps && !_.isEqual(nextProps, this.props)) {

      if (nextProps.list) {
        this.setState({
          variant_list: nextProps.list,
          pagination: nextProps.pagination
        });
      }

      if (
        this.state.openDeleteDialog &&
        !nextProps.deleting &&
        !nextProps.deleteError
      ) {
        this.setState(
          {
            openDeleteDialog: false
          },
          () => {
            this.refreshList();
          }
        );
      }

    }
  }

  componentDidMount() {
    if (this.props.authUser.role == rolesNames.RoleMaster.ADMIN) {
      this.refreshBrand();
    }
    else {
      this.setState({
        partner: this.props.PartnerId
      }, () => {
        this.refreshList();
      })
    }
  }


  toggleFormView = visible => {

    this.setState({
      isAddNew: visible,
      ViewId: "",
    });

    if (!visible) {
      this.refreshList();
    }
  };

  handleEdit = id => {
    this.setState({
      isAddNew: true,
      ViewId: id,
    });
  };

  addNew = id => {
    this.setState({
      isAddNew: true,
      ViewId: '',
    });
  };

  handleMap = id => {
    let variant = this.props.list.find(element => element.id === id);
    this.setState({
      isMapping: true,
      ViewId: id,
      variant: variant
    });
  };



  refreshBrand = () => {
    this.props.actions.getBrand(this.state.partner_type, true);
  }

  deleteDialog = (Id) => {

    Swal.fire({
      title: "Are you sure?",
      text: "This action will delete the variant.",
      icon: "warning",
      showCancelButton: true,
      focusCancel: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete"
    }).then(result => {
      if (result.isConfirmed) {
        this.setState({
          openDeleteDialog: true
        })
        this.props.actions.deleteProductVariant(Id);
      }
    });
  };


  refreshList = () => {
    if (this.state.partner)
      this.props.actions.getProductVariants(this.state.partner, this.state.pagination.page, this.state.searchtext);
  }

  updateGridData = (page) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        page: page
      }
    }, () => {
      this.refreshList();
    })
  }

  searchText = (param) => {
    this.setState({
      searchtext: param,
      pagination: {
        ...this.state.pagination,
        page: 1,
      }
    }, () => {
      this.refreshList();
    });
  }

  handleRoleChange = (key, type) => event => {
    this.setState({
      partner_type: event.target.value,
      partner: '',
    }, () => {
      this.refreshBrand()
    })
  };

  handlePartnerChange = (key, type) => event => {
    this.setState({
      partner: event.target.value
    }, () => {
      this.refreshList();
    })
  };

  handleGetPartnerList = () => (event) => {
    this.setState({
      isMapping: false,
      isAddNew: false,
      ViewId: ''
    }, () => {
      this.refreshList();
    })
  }

  cancelMapping = () => {
    this.setState({
      isMapping: false,
      ViewId: '',
      variant: null
    });
  };

  saveMapping = (ids) => {
    if (!ids || ids.length === 0) {
      return
    }

    this.setState({
      isMapping: false,
      ViewId: '',
      variant: null
    });

  };



  renderComponentsList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12}>
              {this.state.partner && <>

                {
                  this.state.isMapping && this.state.ViewId ?
                    <BrandProductHierarchy
                      partner_id={this.state.partner}
                      variant={this.state.variant}
                      saveMapping={this.saveMapping}
                      cancelMapping={this.cancelMapping}
                    />
                    :
                    this.renderVariants()
                }
              </>
              }
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  renderVariants = () => {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="rose" icon>

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="start">
                <div>
                  <p className={classes.cardIconTitle + " grid-header-title"}>
                    View, edit or delete Product variant groups below.
                      </p>
                </div>
              </Grid>
            </Grid>

          </Grid>
        </CardHeader>
        <CardBody>

          <AgGrid
            rowData={this.state.variant_list}
            columnDefs={this.state.columnDefs}
            handelEdit={this.handleEdit}
            handleDelete={this.deleteDialog}
            AgGridActionButton={ProductVariantAction}
            update={this.updateGridData}
            isServerPagination={true}
            pagination={this.state.pagination}
            loading={this.props.loading}
            search={this.searchText}
            new={this.addNew}
            isAddBtn={true}
            is_map={true}
            handelMap={this.handleMap}
          />

          {this.props.deleteError && (
            <FormLabel className={classes.labelLeftHorizontal}>
              <code>{this.props.deleteError}</code>
            </FormLabel>
          )}

        </CardBody>

      </Card>
    )
  }

  renderComponent = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ProductVariantView
            variant_id={this.state.ViewId}
            addNew={this.toggleFormView}
            partnerType={this.state.partner_type}
            partnerId={this.state.partner}
          />
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>

              {
                this.props.authUser.role == rolesNames.RoleMaster.ADMIN &&

                <Grid container className={classes.root} spacing={2}>
                  <Card>
                    <CardBody>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>

                          <Grid item xs={4}>
                            <Select
                              variant="outlined"
                              style={{ width: '100%' }}
                              value={this.state.partner_type ? this.state.partner_type : 'BR'}
                              classes={{
                                root: "select-control-root"
                              }}
                              onChange={this.handleRoleChange("role", "")}
                              disabled={this.state.isAddNew || this.state.isMapping}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Partner Type *
                  </MenuItem>
                              {_.map(PartnerRoles, ({ key, name }, index) => {
                                return (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={key}
                                    key={index + "_" + key}
                                  >
                                    {name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>
                          <Grid item xs={4}>
                            <Select
                              style={{ width: '100%' }}
                              variant="outlined"
                              value={this.state.partner ? this.state.partner : null}
                              classes={{
                                root: "select-control-root"
                              }}
                              onChange={this.handlePartnerChange("role", "")}
                              disabled={this.state.isAddNew || this.state.isMapping}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Partner *
                  </MenuItem>
                              {_.map(this.props.Brands, ({ id, name }, index) => {
                                return (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={id}
                                    key={index + "_" + id}
                                  >
                                    {name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={this.handleGetPartnerList}
                              size="small"
                              fullWidth
                              disabled={this.state.isAddNew || this.state.isMapping}
                            >
                              Refresh partner's variant groups
                          </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardBody>
                  </Card>
                </Grid>

              }
            </CardHeader>
            <CardBody>
              {

                this.state.isAddNew
                  ? this.renderComponent()
                  : this.renderComponentsList()
              }
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>
    );

  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  authUser: state.authState.user,
  UserId: state.authState.user.user,
  PartnerId: state.authState.user.partner_id,
  Brands: state.brandState.list,

  loading: state.productVariantState.list_product_variant.loading,
  error: state.productVariantState.list_product_variant.error,
  list: state.productVariantState.list_product_variant.variants,

  deleting: state.productVariantState.delete_product_variant.deleting,
  deleteError: state.productVariantState.delete_product_variant.error,

  pagination: state.productVariantState.pagination,
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.ADMIN || authUser.role === rolesNames.RoleMaster.RETAILER || authUser.role === rolesNames.RoleMaster.BRAND

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProdcutVariantGroup);
