import * as retailerServices from "../../services/retailerService";
import * as actionTypes from "./actionTypes";

export const publishRetailerSite = (id, mode) => async dispatch => {
  dispatch(PublishRetailerRequest(id));
  try {
    const resp = await retailerServices.publishRetailerSite(id, mode);
    if (resp) {
      if (resp.success) {
        dispatch(PublishRetailerSuccess(id, resp.data));
      } else {
        dispatch(PublishRetailerFailure(id, resp.error));
      }
    } else {
      dispatch(
        PublishRetailerFailure(
          id,
          "An error has occured while publishing, please contact to support."
        )
      );
    }
  } catch (error) {
    console.log("Publish Error", error);
    dispatch(
      PublishRetailerFailure(
        id,
        "An error has occured while publishing, please contact to support."
      )
    );
  }
};

export const getRetailer = id => async dispatch => {
  dispatch(getRetailerRequest());
  try {
    const success = await retailerServices.getReatilerData(id);
    dispatch(getRetailerSuccess(success));
  } catch (error) {
    dispatch(getRetailerFailure(error.message));
  }
};

export const saveRetailer = (data, id) => async dispatch => {
  dispatch(saveRetailerRequest());
  try {
    var success = await retailerServices.saveReatilerAboutUs(data, id);
    if (success.status && success.status === "1") {
      dispatch(saveRetailerSuccess(success.data));
    } else {
      dispatch(saveRetailerFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(saveRetailerFailure(error.message));
  }
};

export const deleteRetailer = (data, id) => async dispatch => {
  dispatch(deleteRetailerRequest());
  try {
    var success = await retailerServices.saveReatilerAboutUs(data, id);
    if (success.status && success.status === "1") {
      dispatch(deleteRetailerSuccess(success.data));
    } else {
      dispatch(deleteRetailerFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(deleteRetailerFailure(error.message));
  }
};

export const getPartners = (page = 0, searchtext = null) => async dispatch => {
  dispatch(getPatrnerRequest());
  try {
    const success = await retailerServices.getPartnerData(page, searchtext);
    // var data = JSON.parse(success.data);
    // data.forEach(function (entry) {
    //     entry.fields.data = JSON.parse(entry.fields.data)
    // });
    dispatch(getPatrnerSuccess(success.data));
  } catch (error) {
    dispatch(getPatrnerFailure(error.message));
  }
};

export const savePartner = (
  dataPartner,
  selectedTab,
  partner_api_key,
  files
) => async dispatch => {
  dispatch(savePatrnerRequest());
  try {
    const success = await retailerServices.savePartnerData(
      dataPartner,
      partner_api_key,
      null,
      selectedTab,
      null,
      files
    );
    if (success.status && success.status === "0") {
      dispatch(
        savePatrnerFailure(
          success.error ? success.error : "Some Error Occcured"
        )
      );
    } else {
      var data = JSON.parse(success.data);
      data.fields.data = JSON.parse(data.fields.data);
      dispatch(savePatrnerSuccess(data));
    }
  } catch (error) {
    dispatch(savePatrnerFailure(error.message));
  }
};

export const updatePartner = (
  data,
  api_key,
  websiteData,
  selectedTab,
  partner_api_key,
  files
) => async dispatch => {
  dispatch(updatePatrnerRequest());
  try {
    const success = await retailerServices.savePartnerData(
      data,
      api_key,
      websiteData,
      selectedTab,
      partner_api_key,
      files
    );
    //var dataSuccess = JSON.parse(success);

    if (success.status && success.status === "0") {
      dispatch(
        updatePatrnerFailure(
          success.error ? success.error : "Some Error Occcured"
        )
      );
    } else {
      var updatedData = JSON.parse(success.data);
      updatedData.fields.data = JSON.parse(updatedData.fields.data);
      dispatch(updatePatrnerSuccess(updatedData, api_key));
    }
  } catch (error) {
    dispatch(updatePatrnerFailure(error.message));
  }
};

export const getSegmentsImages = () => async dispatch => {
  dispatch(getSegmentImagesRequest());
  try {
    const success = await retailerServices.getSegmentsImages();
    console.log(success);
    console.log("--------");
    //var dataSuccess = JSON.parse(success);

    if (success.status && success.status === "0") {
      dispatch(
        getSegmentImagesFailure(
          success.error ? success.error : "Some Error Occcured"
        )
      );
    } else {
      var updatedData = success.data;
      dispatch(getSegmentImagesSuccess(updatedData));
    }
  } catch (error) {
    dispatch(getSegmentImagesFailure(error.message));
  }
};

export const getAllBrandProduct = productKey => async dispatch => {
  dispatch(getBrandProductTemplaterequest());
  try {
    const success = await retailerServices.getBrandProductTemplate(productKey);

    if (success.status && success.status === "0") {
      dispatch(getBrandProductTemplatereFailure(null));
    } else {
      var updatedData = success.data;
      dispatch(getBrandProductTemplatereSuccess(updatedData));
    }
  } catch (error) {
    dispatch(getSegmentImagesFailure(error.message));
  }
};

//PT Mapping
export const getAllRetailerProductTemplatesSelectedMapping = partnerid => async dispatch => {
  dispatch(getRetailerProductTemplateSelectedRequest());
  try {
    const response = await retailerServices.getAllRetailerProductTemplatesSelectedMapping(
      partnerid
    );
    if (response.status === "1") {
      dispatch(getRetailerProductTemplateSelectedSuccess(response.data));
    } else {
      dispatch(getRetailerProductTemplateSelectedFailure(response.error));
    }
  } catch (error) {
    dispatch(getRetailerProductTemplateSelectedFailure(error.message));
  }
};

export const getBrandRelatedPartners = api_key => async dispatch => {
  dispatch(getBrandRelatedPartnerRequest());
  try {
    const success = await retailerServices.getBrandRelatedPartnerData(api_key);
    // var data = JSON.parse(success.data);
    // data.forEach(function (entry) {
    //     entry.fields.data = JSON.parse(entry.fields.data)
    // });
    dispatch(getBrandRelatedPartnerSuccess(success.data));
  } catch (error) {
    dispatch(getBrandRelatedPartnerFailure(error.message));
  }
};

export const approveRetailerStatement = partner_id => async dispatch => {
  dispatch(approveRetailerStatementRequest());
  try {
    const success = await retailerServices.approveSettlemts(partner_id);
    dispatch(approveRetailerStatementSuccess(success.data));
  } catch (error) {
    dispatch(approveRetailerStatementFailure(error.message));
  }
};

const approveRetailerStatementRequest = () => {
  return {
    type: actionTypes.APPROVE_PARTNER_SETTLEMENT_REQUEST
  };
};

const approveRetailerStatementSuccess = data => {
  return {
    type: actionTypes.APPROVE_PARTNER_SETTLEMENT_SUCCESS,
    payload: data
  };
};

const approveRetailerStatementFailure = error => {
  return {
    type: actionTypes.APPROVE_PARTNER_SETTLEMENT_FAILURE,
    payload: error
  };
};

export const getRetailerSettlement = partner_id => async dispatch => {
  dispatch(getPartnerSettlementRequest());
  try {
    const success = await retailerServices.getRetailerSettlemts(partner_id);
    dispatch(getPartnerSettlementSuccess(success.data));
  } catch (error) {
    dispatch(getPartnerSettlementFailure(error.message));
  }
};

const getPartnerSettlementRequest = () => {
  return {
    type: actionTypes.GET_PARTNER_SETTLEMENT_REQUEST
  };
};

const getPartnerSettlementSuccess = data => {
  return {
    type: actionTypes.GET_PARTNER_SETTLEMENT_SUCCESS,
    payload: data
  };
};

const getPartnerSettlementFailure = error => {
  return {
    type: actionTypes.GET_PARTNER_SETTLEMENT_FAILURE,
    payload: error
  };
};

const getBrandRelatedPartnerRequest = () => {
  return {
    type: actionTypes.GET_BRAND_PARTNER_REQUEST
  };
};

const getBrandRelatedPartnerSuccess = data => {
  return {
    type: actionTypes.GET_BRAND_PARTNER_SUCCESS,
    payload: data
  };
};

const getBrandRelatedPartnerFailure = error => {
  return {
    type: actionTypes.GET_BRAND_PARTNER_FAILURE,
    payload: error
  };
};

const PublishRetailerRequest = id => {
  return {
    type: actionTypes.RETAILER_PUBLISH_REQUEST,
    payload: {
      id
    }
  };
};

const PublishRetailerSuccess = (id, data) => {
  return {
    type: actionTypes.RETAILER_PUBLISH_SUCCESS,
    payload: {
      id: id,
      data: data
    }
  };
};

const PublishRetailerFailure = (id, error) => {
  return {
    type: actionTypes.RETAILER_PUBLISH_FAILURE,
    payload: { id: id, error: error }
  };
};

const getRetailerProductTemplateSelectedRequest = () => {
  return {
    type: actionTypes.GET_RETAILER_PRODUCT_TEMPLATE_SELECTED_REQUEST
  };
};

const getRetailerProductTemplateSelectedSuccess = data => {
  return {
    type: actionTypes.GET_RETAILER_PRODUCT_TEMPLATE_SELECTED_SUCCESS,
    payload: data
  };
};

const getRetailerProductTemplateSelectedFailure = error => {
  return {
    type: actionTypes.GET_RETAILER_PRODUCT_TEMPLATE_SELECTED_FAILURE,
    payload: error
  };
};

const saveRetailerRequest = () => {
  return {
    type: actionTypes.SAVE_ABOUTUS_REQUEST
  };
};

const saveRetailerSuccess = data => {
  return {
    type: actionTypes.SAVE_ABOUTUS_SUCCESS,
    payload: data
  };
};

const saveRetailerFailure = error => {
  return {
    type: actionTypes.SAVE_ABOUTUS_FAILURE,
    payload: error
  };
};

const deleteRetailerRequest = () => {
  return {
    type: actionTypes.DELETE_ABOUTUS_REQUEST
  };
};

const deleteRetailerSuccess = data => {
  return {
    type: actionTypes.DELETE_ABOUTUS_SUCCESS,
    payload: data
  };
};

const deleteRetailerFailure = error => {
  return {
    type: actionTypes.DELETE_ABOUTUS_FAILURE,
    payload: error
  };
};

const getRetailerRequest = () => {
  return {
    type: actionTypes.GET_ABOUTUS_REQUEST
  };
};

const getRetailerSuccess = data => {
  return {
    type: actionTypes.GET_ABOUTUS_SUCCESS,
    payload: data
  };
};

const getRetailerFailure = error => {
  return {
    type: actionTypes.GET_ABOUTUS_FAILURE,
    payload: error
  };
};

const savePatrnerRequest = () => {
  return {
    type: actionTypes.SAVE_PARTNER_REQUEST
  };
};

const savePatrnerSuccess = data => {
  return {
    type: actionTypes.SAVE_PARTNER_SUCCESS,
    payload: data
  };
};

const savePatrnerFailure = error => {
  return {
    type: actionTypes.SAVE_PARTNER_FAILURE,
    payload: error
  };
};

const getPatrnerRequest = () => {
  return {
    type: actionTypes.GET_PARTNER_REQUEST
  };
};

const getPatrnerSuccess = data => {
  return {
    type: actionTypes.GET_PARTNER_SUCCESS,
    payload: data
  };
};

const getPatrnerFailure = error => {
  return {
    type: actionTypes.GET_PARTNER_FAILURE,
    payload: error
  };
};

const updatePatrnerRequest = () => {
  return {
    type: actionTypes.UPDATE_PARTNER_REQUEST
  };
};

const updatePatrnerSuccess = (data, id) => {
  return {
    type: actionTypes.UPDATE_PARTNER_SUCCESS,
    payload: {
      id: id,
      updatedPartner: data
    }
  };
};

const updatePatrnerFailure = error => {
  return {
    type: actionTypes.UPDATE_PARTNER_FAILURE,
    payload: error
  };
};

const getSegmentImagesRequest = () => {
  return {
    type: actionTypes.SEGMET_IMAGES_REQUEST
  };
};

const getSegmentImagesSuccess = data => {
  return {
    type: actionTypes.SEGMET_IMAGES_SUCCESS,
    payload: data
  };
};

const getSegmentImagesFailure = error => {
  return {
    type: actionTypes.SEGMET_IMAGES_FAILURE,
    payload: error
  };
};
const getBrandProductTemplaterequest = () => {
  return {
    type: actionTypes.BRAND_PRODUCT_TEMPLATE_REQUEST
  };
};

const getBrandProductTemplatereSuccess = data => {
  return {
    type: actionTypes.BRAND_PRODUCT_TEMPLATE_SUCCESS,
    payload: data
  };
};

const getBrandProductTemplatereFailure = error => {
  return {
    type: actionTypes.BRAND_PRODUCT_TEMPLATE_FAILURE,
    payload: error
  };
};

export const getPartnerDetail = partner_id => async dispatch => {
  dispatch(getPratnerDetailRequest());
  try {
    const success = await retailerServices.getPartnerDetail(partner_id);

    dispatch(getPratnerDetailSuccess(success.data));
  } catch (error) {
    dispatch(getPratnerDetailFailure(error.message));
  }
};

const getPratnerDetailRequest = () => {
  return {
    type: actionTypes.GET_PARTNER_DETAIL_REQUEST
  };
};

const getPratnerDetailSuccess = data => {
  return {
    type: actionTypes.GET_PARTNER_DETAIL_SUCCESS,
    payload: data
  };
};

const getPratnerDetailFailure = error => {
  return {
    type: actionTypes.GET_PARTNER_DETAIL_FAILURE,
    payload: error
  };
};

export const getUpadtepartnerdetail = () => async dispatch => {
  dispatch(updateParterDetel());
};

const updateParterDetel = error => {
  return {
    type: actionTypes.UPDATE_PARTNER_DETAIL_SUCCESS,
    payload: error
  };
};

export const clonePartnerSite = (
  partner_id,
  copyBy_partnerId,
  config
) => async dispatch => {
  dispatch(getClonePartnerRequest());
  try {
    const success = await retailerServices.clonePartnerSite(
      partner_id,
      copyBy_partnerId,
      config
    );
    if (success.status && success.status === "0") {
      dispatch(
        getClonePartnerFailure(
          success.error ? success.error : "Some Error Occcured"
        )
      );
    } else {
      var data = success.data;

      dispatch(getClonePartnerSuccess(data));
    }
  } catch (error) {
    dispatch(getClonePartnerFailure(error.message));
  }
};

const getClonePartnerRequest = () => {
  return {
    type: actionTypes.CLONE_PARTNER_SITE_REQUEST
  };
};

const getClonePartnerSuccess = data => {
  return {
    type: actionTypes.CLONE_PARTNER_SITE_SUCCESS,
    payload: data
  };
};

const getClonePartnerFailure = error => {
  return {
    type: actionTypes.CLONE_PARTNER_SITE_FAILURE,
    payload: error
  };
};
