export const CREATE_USER_REQUEST = "create_user_request";
export const CREATE_USER_FAILURE = "create_user_failure";
export const CREATE_USER_SUCCESS = "create_user_success";

export const GET_USERSLIST_REQUEST = "get_userlist_request";
export const GET_USERSLIST_FAILURE = "get_userlist_failure";
export const GET_USERSLIST_SUCCESS = "get_userlist_success";

export const DELETE_USER_REQUEST = "delete_user_request";
export const DELETE_USER_FAILURE = "delete_user_failure";
export const DELETE_USER_SUCCESS = "delete_user_success";
