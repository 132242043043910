export const GET_CATEGORY_REQUEST = "get_category_request";
export const GET_CATEGORY_FAILURE = "get_category_failure";
export const GET_CATEGORY_SUCCESS = "get_category_success";

export const GET_CATEGORY_BYID_REQUEST = "get_category_byid_request";
export const GET_CATEGORY_BYID_FAILURE = "get_category_byid_failure";
export const GET_CATEGORY_BYID_SUCCESS = "get_category_byid_success";

export const CREATE_CATEGORY_REQUEST = "create_category_request";
export const CREATE_CATEGORY_FAILURE = "create_category_failure";
export const CREATE_CATEGORY_SUCCESS = "create_category_success";

export const CHANGE_CATEGORY_SAVE_CHECK = "change_category_save_check";

export const GET_CATEGORY_PRODUCT_MAPPING_REQUEST =
  "get_category_product_mapping_request";
export const GET_CATEGORY_PRODUCT_MAPPING_FAILURE =
  "get_category_product_mapping_failure";
export const GET_CATEGORY_PRODUCT_MAPPING_SUCCESS =
  "get_category_product_mapping_success";

export const MAP_PRODUCT_WITH_CATEGORY_REQUEST =
  "map_product_with_category_request";
export const MAP_PRODUCT_WITH_CATEGORY_SUCCESS =
  "map_product_with_category_success";
export const MAP_PRODUCT_WITH_CATEGORY_FAILURE =
  "map_product_with_category_failure";

export const GET_BRAND_CATEGORY_PRODUCT_REQUEST =
  "get_brand_category_product_request";
export const GET_BRAND_CATEGORY_PRODUCT_SUCCESS =
  "get_brand_category_product_success";
export const GET_BRAND_CATEGORY_PRODUCT_FAILURE =
  "get_brand_category_product_failure";

export const GET_WHOLESALOR_BRAND_CATEGORY_PRODUCT_REQUEST =
  "get_wholesalor_brand_category_product_request";
export const GET_WHOLESALOR_BRAND_CATEGORY_PRODUCT_SUCCESS =
  "get_wholesalor_brand_category_product_success";
export const GET_WHOLESALOR_BRAND_CATEGORY_PRODUCT_FAILURE =
  "get_wholesalor_brand_category_product_failure";

export const GET_RETAILER_CLASS_PRODUCT_REQUEST =
  "get_retailer_class_product_request";
export const GET_RETAILER_CLASS_PRODUCT_SUCCESS =
  "get_retailer_class_product_success";
export const GET_RETAILER_CLASS_PRODUCT_FAILURE =
  "get_retailer_class_product_failure";

export const GET_RETAILER_CLASS_PRODUCT_TEMPLATE_REQUEST =
  "get_retailer_class_product_template_request";
export const GET_RETAILER_CLASS_PRODUCT_TEMPLATE_SUCCESS =
  "get_retailer_class_product_template_success";
export const GET_RETAILER_CLASS_PRODUCT_TEMPLATE_FAILURE =
  "get_retailer_class_product_template_failure";


export const GET_PARTNER_CATEGORY_HIERARCHY_REQUEST =
  "GET_PARTNER_CATEGORY_HIERARCHY_REQUEST";
export const GET_PARTNER_CATEGORY_HIERARCHY_SUCCESS =
  "GET_PARTNER_CATEGORY_HIERARCHY_SUCCESS";
export const GET_PARTNER_CATEGORY_HIERARCHY_FAILURE =
  "GET_PARTNER_CATEGORY_HIERARCHY_FAILURE";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";
