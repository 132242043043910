import axios from "../utils/axios.jsx";

export const getEventType = async (id, isActive) => {
    try {
        let url = "/api/event/type/get/?1=1"
        if (id) {
            url = url + '&id=' + id
        }

        if (isActive) {
            url = url + '&isactive=' + isActive
        }
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteEventType = async (id) => {
    try {
        let url = "/api/event/type/delete/?id=" + id
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const saveEventType = async (postdata, userid) => {
    try {
        let data = new FormData();
        data.append("userid", userid);
        data.append("data", JSON.stringify(postdata));
        const response = await axios.post("/api/event/type/save/", data, {
            "content-type": "multipart/form-data"
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getEvents = async (partnerid, id) => {
    try {
        let url = "/api/event/get/?1=1"
        if (id) {
            url = url + '&id=' + id
        }
        if (partnerid) {
            url = url + '&partnerid=' + partnerid
        }
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const saveEvents = async (postdata, imagefiles, userid) => {
    try {
        let data = new FormData();
        data.append("userid", userid);
        data.append("data", JSON.stringify(postdata));
        imagefiles.forEach(file => {
            data.append(file.name, file);
        });
        const response = await axios.post("/api/event/save/", data, {
            "content-type": "multipart/form-data"
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteEvent = async (id) => {
    try {
        let url = "/api/event/delete/?id=" + id
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getEventPartnerMapping = async (eventid, partnerid) => {
    try {
        const response = await axios.get(
            "/api/event/partner-mapping/get/?eventid=" + eventid + "&partnerid=" + partnerid
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const saveEventPartnerMapping = async (selections, eventid, userid) => {
    try {
        let data = new FormData();
        data.append("userid", userid);
        data.append("eventid", eventid);
        data.append("selections", JSON.stringify(selections));
        const response = await axios.post("/api/event/partner-mapping/save/", data, {
            "content-type": "multipart/form-data"
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

