import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  list: null,
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  isSaving: false,
  isDeleted: false,
  isMapSaved: false,
  pagination: {
    page: 1,
    next: null,
    prev: null,
    totalpages: 1,
    trecords: 0,
    pagesize: 25
  }
};

const NotificationtypeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        list: null,
        loadingData: true,
        error: null,
        isSaved: false,
        pagination: {
          page: 1,
          next: null,
          prev: null,
          totalpages: 1,
          trecords: 0,
          pagesize: 25
        }
      };
    case actionTypes.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false
      };
    case actionTypes.GET_NOTIFICATION_SUCCESS:
      let pagination = _.clone(state.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        loadingData: false,
        error: null,
        list: sdata,
        isSaved: false,
        pagination: {
          ...pagination
        }
      };
    case actionTypes.SAVE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loadingData: true,
        error: null,
        isSaved: false,
        isSaving: true
      };
    case actionTypes.SAVE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false,
        isSaving: false
      };
    case actionTypes.SAVE_NOTIFICATION_SUCCESS:
      // var data = state.list;
      // var index = data.findIndex(p => p.id === action.payload.id);
      // if (index > -1) {
      // 	data[index] = action.payload;
      // } else {

      // 	data.push(action.payload);
      // }
      return {
        ...state,
        loadingData: false,
        error: null,
        // list: data,
        isSaved: true,
        isMapSaved: true,
        isSaving: false
      };

    default:
      return state;
  }
};

export default NotificationtypeReducer;
