export const GET_INDUSTRIES_REQUEST = "get_industries_request";
export const GET_INDUSTRIES_FAILURE = "get_industries_failure";
export const GET_INDUSTRIES_SUCCESS = "get_industries_success";

export const SAVE_INDUSTRIES_REQUEST = "save_industries_request";
export const SAVE_INDUSTRIES_FAILURE = "save_industries_failure";
export const SAVE_INDUSTRIES_SUCCESS = "save_industries_success";

export const DELETE_INDUSTRIES_REQUEST = "delete_industries_request";
export const DELETE_INDUSTRIES_FAILURE = "delete_industries_failure";
export const DELETE_INDUSTRIES_SUCCESS = "delete_industries_success";

export const MAP_INDUSTRIES_SEGMET_REQUEST = "map_industries_segmet_request";
export const MAP_INDUSTRIES_SEGMET_FAILURE = "map_industries_segmet_failure";
export const MAP_INDUSTRIES_SEGMET_SUCCESS = "map_industries_segmet_success";

export const AFTER_MAP_INDUSTRIES_SEGMET_SUCCESS =
  "after_map_industries_segmet_success";
