import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import retailerReducer from "./retailerReducer";
import authReducer from "./authReducer";
import partnerReducer from "./partnerReducer";
import brandReducer from "./brandReducer";
import productAttributesReducer from "./productAttributesReducer";
import productsAssignmentsReducer from "./productsAssignmentsReducer";
import SegmentsReducer from "./SegmentsReducer";
import classReducer from "./classReducer";
import businessTypeReducer from "./businessTypeReducer";
import industriesReducer from "./industriesReducer";
import tagsTypeReducer from "./tagsTypeReducer";
import tagsReducer from "./tagsReducer";
import menuMasterReducer from "./menuReducer";
import partnerMenuReducer from "./partnerMenuReducer";
import CMSContentReducer from "./CMSContentReducer";
import serviceReducer from "./serviceReducer";
import messageReducer from "./messageReducer";
import offersReducer from "./offersReducer";
import NotificationtypeReducer from "./NotificationTypeReducer";
import OfferTypeReducer from "./OfferTypeReducer";
import ShippingTypeReducer from "./shippingReducer";
import campignManagementReducer from "./campignManagementReducer";
import orderReducer from "./orderReducer";
import productCMSReducer from "./productcmsReducer";
import assetManagementReducer from "./assetManagementReducer";
import paymentreducer from "./paymentMethodReducer";
import templateReducer from "./templateReducer";
import changePasswordReducer from "./changePasswordReducer";
import dashboardReducer from "./dashboardReducer";
import reportReducer from "./reportReducer";
import settingReducer from "./settingReducer";
import appMenuReducer from "./appMenuReducer";
import userGroupReducer from "./userGroupReducer";
import usersReducer from "./usersReducer";
import inventoryManagementReducer from "./inventoryManagementReducer";
import categoryReducer from "./categoryReducer";
import batchProductCreationReducer from "./batchProductCreationReducer";
import partnerMappingReducer from "./partnerMappingReducer";
import customPageReducer from "./customPageReducer";
import cmsReducer from "./cmsReducer";
import variantReducer from "./variantReducer";
import productVariantReducer from "./productVariantReducer";
import EventReducers from "./eventReducer"

const appReducer = combineReducers({
  routerState: routerReducer,
  retailerState: retailerReducer,
  authState: authReducer,
  partnerSate: partnerReducer,
  brandState: brandReducer,
  productAttributesReducer: productAttributesReducer,
  productsAssignmentsState: productsAssignmentsReducer,
  SegmentsReducer: SegmentsReducer,
  classReducer: classReducer,
  businessTypeReducer: businessTypeReducer,
  industriesReducer: industriesReducer,
  tagsTypeReducer: tagsTypeReducer,
  tagsReducer: tagsReducer,
  menuMasterReducer: menuMasterReducer,
  partnerMenuReducer: partnerMenuReducer,
  CMSContentReducer: CMSContentReducer,
  serviceReducer: serviceReducer,
  messageReducer: messageReducer,
  offersReducer: offersReducer,
  NotificationtypeReducer: NotificationtypeReducer,
  OfferTypeReducer: OfferTypeReducer,
  ShippingTypeReducer: ShippingTypeReducer,
  campignManagementReducer: campignManagementReducer,
  orderReducer: orderReducer,
  productCMSReducer: productCMSReducer,
  assetManagementReducer: assetManagementReducer,
  paymentReducer: paymentreducer,
  templateReducer: templateReducer,
  changePasswordReducer: changePasswordReducer,
  dashboardReducer: dashboardReducer,
  reportReducer: reportReducer,
  settingReducer: settingReducer,
  appMenuReducer: appMenuReducer,
  userGroupReducer: userGroupReducer,
  usersReducer: usersReducer,
  inventoryManagementReducer: inventoryManagementReducer,
  categoryReducer: categoryReducer,
  batchProductCreationReducer: batchProductCreationReducer,
  partnerMappingReducer: partnerMappingReducer,
  customPageReducer: customPageReducer,
  cmsReducer: cmsReducer,
  variantReducer: variantReducer,
  productVariantState: productVariantReducer,
  eventState: EventReducers,
});

const rootReducer = (state, action) => {
  // if (action.type === actionTypes.SET_USER_AUTH && action.payload === null) {
  // 	state = undefined;
  // }

  return appReducer(state, action);
};

export default rootReducer;
