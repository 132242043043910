import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Tooltip from "@material-ui/core/Tooltip";
import withAuthorization from "../Session/withAuthorization";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CreateIcon from "@material-ui/icons/Create";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { rolesNames } from "../../constants";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const INITIAL_STATE = {
  attribute: {
    id: null,
    product_template: null,
    universal_attribute: null,
    sort_order: null,
    attribute_value: null
  }
};
class TemplateUniversalAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      productTemplates: null,
      productTemplateID: null,
      modalAttribute: false
    };
  }

  componentDidMount() {
    this.props.actions.getAllProductTemplates(this.props.PartnerId);
    this.props.actions.getUniversalAttributes();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.productTemplates &&
        nextProps.productTemplates.length > 0 &&
        !_.isEqual(this.props.productTemplates, nextProps.productTemplates)
      ) {
        this.setState({
          productTemplates: nextProps.productTemplates
        });
      }
    }
    if (!nextProps.Saving && this.props.Saving && nextProps.error === null) {
      this.openModalDialog("modalAttribute", false);
    }
  }

  handelOnDropDownChange = () => event => {
    this.setState({
      productTemplateID: event.target.value
    });
  };

  getUniversalAtrributeTableData = () => {
    const { productTemplateID, productTemplates } = this.state;
    var attributes = [];
    if (productTemplates) {
      var index = productTemplates.findIndex(
        item => item.id === productTemplateID
      );
      if (index > -1) {
        attributes = productTemplates[index].xref_univattribs;
      }
    }
    const { classes } = this.props;
    var returnData = [];
    _.map(attributes, ({ universal_attribute, attribute_value }, index) => {
      returnData.push([
        <span>{index + 1}</span>,
        <span>{universal_attribute.name}</span>,
        <span>{attribute_value}</span>,
        <span>
          <Button
            simple
            color="success"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.handelAttributeEdit("modalAttribute", index)}
          >
            <CreateIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>

          <Button
            simple
            className={classes.marginRight}
            style={{ padding: "5px" }}
          >
            <ClearIcon
              className={classes.icons}
              style={{ color: "#f44336", marginRight: "0px" }}
            />
          </Button>
        </span>
      ]);
    });
    return returnData;
  };
  openModalDialog = (modalName, isOpen) => {
    this.setState({
      ...INITIAL_STATE,
      [modalName]: isOpen
    });
  };
  handelAttributeEdit = (modalName, index) => {
    const { productTemplateID, productTemplates } = this.state;
    var index_temp = productTemplates.findIndex(
      item => item.id === productTemplateID
    );
    var attributes = [];
    if (index_temp > -1) {
      attributes = productTemplates[index_temp].xref_univattribs;
    }
    const universalAttribute = attributes[index];

    this.setState({
      [modalName]: true,
      attribute: {
        id: universalAttribute.id,
        product_template: universalAttribute.product_template.id,
        universal_attribute: universalAttribute.universal_attribute.id,
        sort_order: universalAttribute.sort_order,
        attribute_value: universalAttribute.attribute_value
      }
    });
  };
  handelTextChange = key => event => {
    this.setState({
      attribute: {
        ...this.state.attribute,
        [key]: event.target.value
      }
    });
  };
  saveAttribute = () => {
    const {
      id,
      universal_attribute,
      sort_order,
      attribute_value
    } = this.state.attribute;
    const { productTemplateID } = this.state;
    this.props.actions.saveUniversalAttribute(this.props.UserKey, {
      id: id,
      product_template: productTemplateID,
      universal_attribute: universal_attribute,
      sort_order: sort_order,
      attribute_value: attribute_value
    });
  };
  render() {
    const { classes } = this.props;
    const { productTemplates, productTemplateID } = this.state;
    const {
      universal_attribute,
      sort_order,
      attribute_value
    } = this.state.attribute;
    const { UniversalAttributes } = this.props;
    return (
      <GridContainer>
        <Dialog
          open={this.state.modalAttribute}
          disableBackdropClick={true}
          onClose={() => this.openModalDialog("modalAttribute", false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Universal Attribute</DialogTitle>
          <LoadingOverlay>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                  style={{ marginTop: "14px" }}
                >
                  <InputLabel
                    htmlFor="condition-select"
                    className={classes.selectLabel}
                    style={{ fontSize: "14px" }}
                  >
                    Universal Attribute
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={universal_attribute}
                    onChange={this.handelTextChange("universal_attribute")}
                    inputProps={{
                      name: "universal_attribute",
                      id: "universal_attribute"
                    }}
                    style={{ fontSize: "14px" }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choose Universal Attribute
                    </MenuItem>
                    {UniversalAttributes &&
                      _.map(UniversalAttributes, ({ id, name }, index) => {
                        return (
                          <MenuItem
                            id={id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={id}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <CustomInput
                  labelText="Sort Order"
                  id="sort_order"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value: sort_order,
                    onChange: this.handelTextChange("sort_order")
                  }}
                />
                <CustomInput
                  labelText="Attribute Value"
                  id="attribute_value"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: attribute_value,
                    onChange: this.handelTextChange("attribute_value")
                  }}
                />
              </DialogContentText>
              <DialogActions>
                <Button
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px"
                  }}
                  className={classes.marginRight}
                  simple
                  onClick={() => this.saveAttribute()}
                  color="primary"
                >
                  Save Changes
                </Button>
                <Button
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px"
                  }}
                  className={classes.marginRight}
                  simple
                  onClick={() => this.openModalDialog("modalAttribute", false)}
                  color="primary"
                  autoFocus
                >
                  Cancel
                </Button>
              </DialogActions>
            </DialogContent>
            {this.props.Saving && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Saving} text="Saving.." />
          </LoadingOverlay>
        </Dialog>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Icon style={{ fontSize: "36px" }}>info</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    style={{ marginTop: "14px" }}
                  >
                    <InputLabel
                      htmlFor="condition-select"
                      className={classes.selectLabel}
                      style={{ fontSize: "14px" }}
                    >
                      Product Templates
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={productTemplateID}
                      onChange={this.handelOnDropDownChange()}
                      inputProps={{
                        name: "productTemplateID",
                        id: "productTemplateID"
                      }}
                      style={{ fontSize: "14px" }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Product Template
                      </MenuItem>
                      {_.map(productTemplates, ({ id, name }, index) => {
                        return (
                          <MenuItem
                            id={id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={id}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <br />
                  <br />
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <Card
                        style={{
                          border: "1px solid #D1D1D1",
                          boxShadow: "2px 2px 8px rgba(140, 140, 140, 0.25)",
                          borderRadius: "6px"
                        }}
                      >
                        <CardHeader color="primary" icon>
                          <CardIcon color="primary">
                            <Icon style={{ fontSize: "28px" }}>phone</Icon>
                          </CardIcon>
                          <p className={classes.cardIconTitle}>
                            Template Attributes
                            {
                              <Tooltip
                                id="tooltip-topContactUs"
                                title="Add New"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  style={{
                                    float: "right",
                                    padding: "0",
                                    margin: "0"
                                  }}
                                  disabled={productTemplateID ? false : true}
                                  simple
                                  color="info"
                                  justIcon
                                  onClick={() =>
                                    this.openModalDialog("modalAttribute", true)
                                  }
                                >
                                  <AddIcon
                                    className={classes.underChartIcons}
                                  />
                                </Button>
                              </Tooltip>
                            }
                          </p>
                        </CardHeader>
                        <CardBody>
                          <Table
                            tableHead={["Sr.", "Attributes Name", "Value", ""]}
                            tableData={this.getUniversalAtrributeTableData()}
                            tableShopping
                            customHeadCellClasses={[
                              classes.description,
                              classes.description,
                              classes.description,
                              classes.right,
                              classes.right,
                              classes.right
                            ]}
                            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                            customCellClasses={[
                              classes.customFont,
                              classes.tdName,
                              classes.customFont,
                              classes.tdNumber +
                                " " +
                                classes.tdNumberAndButtonGroup,
                              classes.tdNumber
                            ]}
                            customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                          />
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                  {(this.props.Loading || this.props.LoadingUniversal) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader
                    loading={this.props.Loading | this.props.LoadingUniversal}
                    text="Loading.."
                  />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  PartnerId: state.authState.user.partner_id,
  UserKey: state.authState.user.user,
  productTemplates: state.brandState.productTemplate.products,
  Loading: state.brandState.productTemplate.isLoading,
  error: state.brandState.error,
  Saving: state.brandState.productTemplate.isSaving,
  UniversalAttributes: state.brandState.universalAttributes.attributes,
  LoadingUniversal: state.brandState.universalAttributes.isLoading
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...dashboardStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TemplateUniversalAttributes);
