import * as actionTypes from "./actionTypes";
import * as paymentService from "../../services/paymentService";

export const getPayMethod = () => async dispatch => {
  dispatch(getPaymentRequest());
  try {
    const success = await paymentService.getPayMethod();
    if (success.status === "1") {
      dispatch(getPaymentSuccess(success.data));
    } else {
      dispatch(getPaymentFailure(success.error));
    }
  } catch (error) {
    dispatch(getPaymentFailure(error.message));
  }
};

export const createPayMethod = (id, payment) => async dispatch => {
  dispatch(createPaymentRequest());
  try {
    const success = await paymentService.savePayCred(id, payment);
    if (success.status === "1") {
      dispatch(createPaymentSuccess(success.data));
    } else {
      dispatch(createPaymentFailure(success.error));
    }
  } catch (error) {
    dispatch(createPaymentFailure(error.message));
  }
};

export const getPayCred = () => async dispatch => {
  dispatch(getPaymentCredRequest());
  try {
    const success = await paymentService.getPayCred();
    if (success.status === "1") {
      dispatch(getPaymentCredSuccess(success.data));
    } else {
      dispatch(getPaymentCredFailure(success.error));
    }
  } catch (error) {
    dispatch(getPaymentCredFailure(error.message));
  }
};
const createPaymentRequest = () => {
  return {
    type: actionTypes.CREATE_PAYMENT_METHOD_REQUEST
  };
};

const createPaymentSuccess = data => {
  return {
    type: actionTypes.CREATE_PAYMENT_METHOD_SUCCESS,
    payload: data
  };
};

const createPaymentFailure = error => {
  return {
    type: actionTypes.CREATE_PAYMENT_METHOD_FAILURE,
    payload: error
  };
};
const getPaymentRequest = () => {
  return {
    type: actionTypes.GET_PAYMENT_METHOD_REQUEST
  };
};

const getPaymentSuccess = data => {
  return {
    type: actionTypes.GET_PAYMENT_METHOD_SUCCESS,
    payload: data
  };
};

const getPaymentFailure = error => {
  return {
    type: actionTypes.GET_PAYMENT_METHOD_FAILURE,
    payload: error
  };
};

const getPaymentCredRequest = () => {
  return {
    type: actionTypes.GET_PAYMENT_CRED_REQUEST
  };
};

const getPaymentCredSuccess = data => {
  return {
    type: actionTypes.GET_PAYMENT_CRED_SUCCESS,
    payload: data
  };
};

const getPaymentCredFailure = error => {
  return {
    type: actionTypes.GET_PAYMENT_CRED_FAILURE,
    payload: error
  };
};
