import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  categorydata: {
    data: null,
    error: null,
    loading: false,
    isSaved: false
  },
  categoryById: {
    data: null,
    error: null,
    loading: false,
    isDeleted: false,
  },
  categoryProductMappingData: {
    loading: false,
    error: null,
    data: null
  },
  saveCategoryProductMapping: {
    loading: false,
    error: null,
    isSaved: false
  },
  brandCategoryProductHierarchy: {
    data: [],
    error: null,
    loading: false,
    pagination: {
      page: 1,
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  },
  wholesalorBrandCategoryProductHierarchy: {
    loading: false,
    error: null,
    data: null,
    pagination: {
      page: 1,
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  },
  retailerClassProductHierarchy: {
    loading: false,
    error: null,
    data: null,
    pagination: {
      page: 1,
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  },
  retailerClassProductTemplateHierarchy: {
    loading: false,
    error: null,
    data: null,
    pagination: {
      page: "1",
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  },
  partnerCategory: {
    loading: false,
    error: null,
    data: null,
    pagination: {
      page: "1",
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  }
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORY_REQUEST:
      return {
        ...state,
        categorydata: {
          ...state.categorydata,
          loading: true,
          data: null,
          error: null,
          isSaved: false
        },
        saveCategoryProductMapping: {
          ...state.saveCategoryProductMapping,
          isSaved: false
        }
      };
    case actionTypes.GET_CATEGORY_FAILURE:
      return {
        ...state,
        categorydata: {
          ...state.categorydata,
          loading: false,
          data: null,
          error: action.payload
        }
      };
    case actionTypes.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categorydata: {
          ...state.categorydata,
          loading: false,
          error: null,
          data: action.payload
        }
      };
    case actionTypes.CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        categorydata: {
          ...state.categorydata,
          loading: true,
          error: null,
          isSaved: false
        }
      };
    case actionTypes.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        categorydata: {
          ...state.categorydata,
          loading: false,
          error: action.payload,
          isSaved: false
        }
      };
    case actionTypes.CREATE_CATEGORY_SUCCESS:
      var data = _.clone(state.categorydata.data);
      var index = data.findIndex(p => p.id === action.payload.id);
      if (index > -1) {
        data[index] = action.payload;
      } else {
        data.push(action.payload);
      }
      return {
        ...state,
        categorydata: {
          ...state.categorydata,
          loading: false,
          data: data,
          error: null,
          isSaved: true
        }
      };
    case actionTypes.GET_CATEGORY_BYID_REQUEST:
      return {
        ...state,
        categoryById: {
          ...state.categoryById,
          loading: true,
          data: null,
          error: null,
          isDeleted: false
        }
      };
    case actionTypes.GET_CATEGORY_BYID_FAILURE:
      return {
        ...state,
        categoryById: {
          ...state.categoryById,
          loading: false,
          data: null,
          error: action.payload,
          isDeleted: false
        }
      };
    case actionTypes.GET_CATEGORY_BYID_SUCCESS:
      return {
        ...state,
        categoryById: {
          ...state.categoryById,
          loading: true,
          error: null,
          data: action.payload,
          isDeleted: false
        }
      };

    case actionTypes.GET_CATEGORY_PRODUCT_MAPPING_REQUEST:
      return {
        ...state,
        categoryProductMappingData: {
          ...state.categoryProductMappingData,
          loading: true,
          error: null,
          data: null
        }
      };
    case actionTypes.GET_CATEGORY_PRODUCT_MAPPING_FAILURE:
      return {
        ...state,
        categoryProductMappingData: {
          ...state.categoryProductMappingData,
          loading: false,
          error: action.payload,
          data: null
        }
      };
    case actionTypes.GET_CATEGORY_PRODUCT_MAPPING_SUCCESS:
      return {
        ...state,
        categoryProductMappingData: {
          ...state.categoryProductMappingData,
          loading: false,
          error: null,
          data: action.payload
        }
      };

    case actionTypes.MAP_PRODUCT_WITH_CATEGORY_REQUEST:
      return {
        ...state,
        saveCategoryProductMapping: {
          ...state.saveCategoryProductMapping,
          loading: true,
          error: null,
          isSaved: false
        }
      };
    case actionTypes.MAP_PRODUCT_WITH_CATEGORY_FAILURE:
      return {
        ...state,
        saveCategoryProductMapping: {
          ...state.saveCategoryProductMapping,
          loading: false,
          error: action.payload,
          isSaved: false
        }
      };
    case actionTypes.MAP_PRODUCT_WITH_CATEGORY_SUCCESS:
      return {
        ...state,
        saveCategoryProductMapping: {
          ...state.saveCategoryProductMapping,
          loading: false,
          error: null,
          isSaved: true
        }
      };

    case actionTypes.GET_BRAND_CATEGORY_PRODUCT_REQUEST:
      return {
        ...state,
        brandCategoryProductHierarchy: {
          ...state.brandCategoryProductHierarchy,
          loading: true,
          error: null,
          data: null,
          pagination: {
            page: 1,
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        }
      };
    case actionTypes.GET_BRAND_CATEGORY_PRODUCT_FAILURE:
      return {
        ...state,
        brandCategoryProductHierarchy: {
          ...state.brandCategoryProductHierarchy,
          loading: false,
          error: action.payload,
          data: null
        }
      };
    case actionTypes.GET_BRAND_CATEGORY_PRODUCT_SUCCESS:
      let bcp_pagination = _.clone(
        state.retailerClassProductTemplateHierarchy.pagination
      );
      let bcp_data = action.payload;
      if (bcp_data && bcp_data.results) {
        bcp_pagination.page = bcp_data.current;
        bcp_pagination.next = bcp_data.next;
        bcp_pagination.prev = bcp_data.prev;
        bcp_pagination.totalpage = bcp_data.total_pages;
        bcp_pagination.totalrecords = bcp_data.total_records;
        bcp_pagination.pagesize = bcp_data.page_size;
        bcp_data = bcp_data.results;
      }
      return {
        ...state,
        brandCategoryProductHierarchy: {
          ...state.brandCategoryProductHierarchy,
          loading: false,
          error: null,
          data: bcp_data,
          pagination: {
            ...bcp_pagination
          }
        }
      };

    case actionTypes.GET_WHOLESALOR_BRAND_CATEGORY_PRODUCT_REQUEST:
      return {
        ...state,
        wholesalorBrandCategoryProductHierarchy: {
          ...state.wholesalorBrandCategoryProductHierarchy,
          loading: true,
          error: null,
          data: null,
          pagination: {
            page: 1,
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        }
      };
    case actionTypes.GET_WHOLESALOR_BRAND_CATEGORY_PRODUCT_FAILURE:
      return {
        ...state,
        wholesalorBrandCategoryProductHierarchy: {
          ...state.wholesalorBrandCategoryProductHierarchy,
          loading: false,
          error: action.payload,
          data: null
        }
      };
    case actionTypes.GET_WHOLESALOR_BRAND_CATEGORY_PRODUCT_SUCCESS:
      let wbcp_pagination = _.clone(
        state.retailerClassProductTemplateHierarchy.pagination
      );
      let wbcp_data = action.payload;
      if (wbcp_data && wbcp_data.results) {
        wbcp_pagination.page = wbcp_data.current;
        wbcp_pagination.next = wbcp_data.next;
        wbcp_pagination.prev = wbcp_data.prev;
        wbcp_pagination.totalpage = wbcp_data.total_pages;
        wbcp_pagination.totalrecords = wbcp_data.total_records;
        wbcp_pagination.pagesize = wbcp_data.page_size;
        wbcp_data = wbcp_data.results;
      }
      return {
        ...state,
        wholesalorBrandCategoryProductHierarchy: {
          ...state.wholesalorBrandCategoryProductHierarchy,
          loading: false,
          error: null,
          data: wbcp_data,
          pagination: {
            ...wbcp_pagination
          }
        }
      };

    case actionTypes.GET_RETAILER_CLASS_PRODUCT_REQUEST:
      return {
        ...state,
        retailerClassProductHierarchy: {
          ...state.retailerClassProductHierarchy,
          loading: true,
          error: null,
          data: null,
          pagination: {
            page: 1,
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        }
      };
    case actionTypes.GET_RETAILER_CLASS_PRODUCT_FAILURE:
      return {
        ...state,
        retailerClassProductHierarchy: {
          ...state.retailerClassProductHierarchy,
          loading: false,
          error: action.payload,
          data: null
        }
      };
    case actionTypes.GET_RETAILER_CLASS_PRODUCT_SUCCESS:
      let rt_pagination = _.clone(
        state.retailerClassProductTemplateHierarchy.pagination
      );
      let srt_data = action.payload;
      if (srt_data && srt_data.results) {
        rt_pagination.page = srt_data.current;
        rt_pagination.next = srt_data.next;
        rt_pagination.prev = srt_data.prev;
        rt_pagination.totalpage = srt_data.total_pages;
        rt_pagination.totalrecords = srt_data.total_records;
        rt_pagination.pagesize = srt_data.page_size;
        srt_data = srt_data.results;
      }
      return {
        ...state,
        retailerClassProductHierarchy: {
          ...state.retailerClassProductHierarchy,
          loading: false,
          error: null,
          data: srt_data,
          pagination: {
            ...rt_pagination
          }
        }
      };

    case actionTypes.GET_RETAILER_CLASS_PRODUCT_TEMPLATE_REQUEST:
      return {
        ...state,
        retailerClassProductTemplateHierarchy: {
          ...state.retailerClassProductTemplateHierarchy,
          loading: true,
          error: null,
          data: null,
          pagination: {
            page: "1",
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        }
      };
    case actionTypes.GET_RETAILER_CLASS_PRODUCT_TEMPLATE_FAILURE:
      return {
        ...state,
        retailerClassProductTemplateHierarchy: {
          ...state.retailerClassProductTemplateHierarchy,
          loading: false,
          error: action.payload,
          data: null
        }
      };
    case actionTypes.GET_RETAILER_CLASS_PRODUCT_TEMPLATE_SUCCESS:
      let pagination = _.clone(
        state.retailerClassProductTemplateHierarchy.pagination
      );
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        retailerClassProductTemplateHierarchy: {
          ...state.retailerClassProductTemplateHierarchy,
          loading: false,
          error: null,
          data: sdata,
          pagination: {
            ...pagination
          }
        }
      };

    case actionTypes.CHANGE_CATEGORY_SAVE_CHECK:
      return {
        ...state,
        categorydata: {
          ...state.retailerClassProductTemplateHierarchy,
          isSaved: false
        }
      };

    case actionTypes.GET_PARTNER_CATEGORY_HIERARCHY_REQUEST:
      return {
        ...state,
        partnerCategory: {
          ...state.partnerCategory,
          loading: false,
          error: null,
          data: null,
          pagination: {
            page: "1",
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        }
      };
    case actionTypes.GET_PARTNER_CATEGORY_HIERARCHY_FAILURE:
      return {
        ...state,
        partnerCategory: {
          ...state.partnerCategory,
          loading: false,
          error: action.payload,
          data: null
        }
      };
    case actionTypes.GET_PARTNER_CATEGORY_HIERARCHY_SUCCESS:
      let _pagination = _.clone(
        state.retailerClassProductTemplateHierarchy.pagination
      );
      let _data = action.payload;
      if (_data && _data.results) {
        _pagination.page = _data.current;
        _pagination.next = _data.next;
        _pagination.prev = _data.prev;
        _pagination.totalpage = _data.total_pages;
        _pagination.totalrecords = _data.total_records;
        _pagination.pagesize = _data.page_size;
        _data = _data.results;
      }
      return {
        ...state,
        partnerCategory: {
          ...state.partnerCategory,
          loading: false,
          error: null,
          data: _data,
          pagination: {
            ..._pagination
          }
        },
      };
    case actionTypes.DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        categoryById: {
          ...state.categoryById,
          loading: true,
          error: null,
          isDeleted: false
        }
      };
    case actionTypes.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        categoryById: {
          ...state.categoryById,
          loading: false,
          error: action.payload,
          isDeleted: false
        }
      };
    case actionTypes.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryById: {
          ...state.categoryById,
          loading: true,
          error: null,
          isDeleted: true
        }
      };

    default:
      return state;
  }
};

export default categoryReducer;
