export const SAVE_CAMPAIGN_DATA_REQUEST = "save_campaign_data_request";
export const SAVE_CAMPAIGN_DATA_FAILURE = "save_campaign_data_failure";
export const SAVE_CAMPAIGN_DATA_SUCCESS = "save_campaign_data_success";

export const PLAY_PAUSE_CAMPAIGN_REQUEST = "play_pause_campaign_request";
export const PLAY_PAUSE_CAMPAIGN_FAILURE = "play_pause_campaign_failure";
export const PLAY_PAUSE_CAMPAIGN_SUCCESS = "play_pause_campaign_success";

export const DELETE_CAMPAIGN_REQUEST = "delete_campaign_request";
export const DELETE_CAMPAIGN_FAILURE = "delete_campaign_failure";
export const DELETE_CAMPAIGN_SUCCESS = "delete_campaign_success";

export const CAMPAIGN_LIST_REQUEST = "campaign_list_request";
export const CAMPAIGN_LIST_FAILURE = "campaign_list_failure";
export const CAMPAIGN_LIST_SUCCESS = "campaign_list_success";

export const CHANNEL_LIST_REQUEST = "channel_list_request";
export const CHANNEL_LIST_FAILURE = "channel_list_failure";
export const CHANNEL_LIST_SUCCESS = "channel_list_success";

export const CHANNEL_DIST_LIST_REQUEST = "channel_dist_list_request";
export const CHANNEL_DIST_LIST_FAILURE = "channel_dist_list_failure";
export const CHANNEL_DIST_LIST_SUCCESS = "channel_dist_list_success";

export const PUBLISH_CAMPAIGN_REQUEST = "publish_campaign_request";
export const PUBLISH_CAMPAIGN_FAILURE = "publish_campaign_failure";
export const PUBLISH_CAMPAIGN_SUCCESS = "publish_campaign_success";

export const CAMPAIGN_DATA_BY_ID_REQUEST = "campaign_data_by_id_request";
export const CAMPAIGN_DATA_BY_ID_FAILURE = "campaign_data_by_id_failure";
export const CAMPAIGN_DATA_BY_ID_SUCCESS = "campaign_data_by_id_success";

export const SAVE_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_REQUEST =
  "save_retailers_brand_access_to_publish_campaign_request";
export const SAVE_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_FAILURE =
  "save_retailers_brand_access_to_publish_campaign_failure";
export const SAVE_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_SUCCESS =
  "save_retailers_brand_access_to_publish_campaign_success";

export const GET_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_REQUEST =
  "get_retailers_brand_access_to_publish_campaign_request";
export const GET_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_FAILURE =
  "get_retailers_brand_access_to_publish_campaign_failure";
export const GET_RETAILERS_BRAND_ACCESS_TO_PUBLISH_CAMPAIGN_SUCCESS =
  "get_retailers_brand_access_to_publish_campaign_success";

export const GET_RETAILERS_BRAND_LIST_REQUEST =
  "get_retailers_brand_list_request";
export const GET_RETAILERS_BRAND_LIST_FAILURE =
  "get_retailers_brand_list_failure";
export const GET_RETAILERS_BRAND_LIST_SUCCESS =
  "get_retailers_brand_list_success";

export const PARTNERS_CAMPAIGN_REQUEST = "partners_campaign_request";
export const PARTNERS_CAMPAIGN_FAILURE = "partners_campaign_failure";
export const PARTNERS_CAMPAIGN_SUCCESS = "partners_campaign_success";
