import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AgGrid from "components/AgGrid/AgGrid.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

import CreateEditCampaign from "./component/CreateEditCampaign";
import FormLabel from "@material-ui/core/FormLabel";
import campaignActionButtonsComponent from "../components/campaignActionButtonsComponent.jsx";
import moment from "moment";

function dateFormatter(params) {
  if (params.value) {
    return moment(params.value).format("MM/DD/YYYY");
  } else {
    return "";
  }
}

const initialState = {
  id: "",
  name: "",
  description: "",
  start_date: moment().format("MM/DD/YYYY"),
  end_date: moment().format("MM/DD/YYYY"),
  retailers: [],
  is_active: false,
  is_deleted: false,
  channels: [],
  navigation_url: "",
  status: 1,
  required: {
    name: "",
    retailers: [],
    image: "",
    start_date: "",
    end_date: "",
    channels: []
  }
};

let _agGridAPI = null;

class AdManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      campaignData: {
        ...initialState
      },
      showChannels: {
        retailerChannel: "No",
        teamlocalChannel: "No"
      },
      hidePartnerTab: false,
      campaignList: [],
      isAddNew: false,
      isEdit: false,
      start_date: moment().format("MM/DD/YYYY"),
      end_date: moment().format("MM/DD/YYYY"),
      selectedCampaignId: -1,
      error: null,
      loading: false,
      columnDefs: [
        {
          headerName: "Campaigns Name",
          field: "name",
          width: 200,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Start date",
          field: "start_date",
          width: 200,
          resizable: true,
          sortable: true,
          valueFormatter: dateFormatter
        },
        {
          headerName: "End Date",
          field: "end_date",
          width: 200,
          resizable: true,
          sortable: true,
          valueFormatter: dateFormatter
        },
        {
          headerName: "Status",
          // field: "status",
          width: 200,
          resizable: true,
          sortable: true,
          valueGetter: function(params) {
            return params.data.status === 1 ? "Incomplete" : "Complete";
          }
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 180
        }
      ],
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        floatingFilter: true
      },
      context: { componentParent: this },
      frameworkComponents: {
        campaignActionButtonsComponent: campaignActionButtonsComponent
      },
      domLayout: "autoHeight"
    };
    this.closePromotionWizard = this.closePromotionWizard.bind(this);
    this.updateCampaignIntialState = this.updateCampaignIntialState.bind(this);
    this.searchText = this.searchText.bind(this);
    this.updateGridData = this.updateGridData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (
          nextProps.List &&
          nextProps.List.length > 0 &&
          !_.isEqual(nextProps.List, this.props.List)
        ) {
          this.setState({
            campaignList: nextProps.List,
            pagination: nextProps.pagination
          });
        }

        if (
          (nextProps.playPause && nextProps.playPause.isSaved) ||
          (nextProps.deleteCampaign && nextProps.deleteCampaign.isDeleted) ||
          (nextProps.publishCampaign && nextProps.publishCampaign.isPublished)
        ) {
          this.setState(
            {
              campaignList: nextProps.List
            },
            () => {
              if (_agGridAPI) {
                _agGridAPI.setRowData(this.state.campaignList);
              }
            }
          );
        }

        let error = "";
        if (nextProps.ListError) {
          error = nextProps.ListError;
        }
        this.setState({
          error: error
        });

        let loading = false;
        if (
          nextProps.Loading ||
          nextProps.playPause.loading ||
          nextProps.deleteCampaign.loading ||
          nextProps.publishCampaign.loading
        ) {
          loading =
            nextProps.Loading ||
            nextProps.playPause.loading ||
            nextProps.deleteCampaign.loading ||
            nextProps.publishCampaign.loading;
        }
        this.setState({
          loading: loading
        });

        if (nextProps.data && !_.isEqual(this.props.data, nextProps.data)) {
          let { data } = nextProps;
          let editData = {
            ...initialState
          };
          editData.id = data.id;
          editData.name = data.name;
          editData.start_date = moment(data.start_date).format("MM/DD/YYYY");
          editData.end_date = moment(data.end_date).format("MM/DD/YYYY");
          editData.is_active = data.is_active;
          editData.is_deleted = data.is_deleted;
          let retailers = [];
          for (var i in data.retailers) {
            const retailerIndex = retailers.indexOf(data.retailers[i].partner);
            if (retailerIndex === -1) {
              retailers.push(data.retailers[i].partner);
            }
          }

          let channels = [];
          let { showChannels } = this.state;
          for (var i in data.channels) {
            if (!data.channels[i].channel_distribution) continue;

            const channnelObjIndex = channels.findIndex(
              x => x.channelId === data.channels[i].channel_distribution.id
            );
            if (channnelObjIndex === -1) {
              let obj = {};
              let channelObj = {};
              channelObj.channelDistId =
                data.channels[i].channel_distribution.id;
              channelObj.channelDistName =
                data.channels[i].channel_distribution.name;
              channelObj.channelId =
                data.channels[i].channel_distribution.channel.id;
              channelObj.channelName =
                data.channels[i].channel_distribution.channel.name;
              channelObj.image = data.channels[i].image
                ? data.channels[i].image
                : null;
              if (
                data.channels[
                  i
                ].channel_distribution.channel.key.toLowerCase() ==
                "partner-web-banners"
              ) {
                channelObj.page = 1;
                showChannels.retailerChannel = "Yes";
              }
              if (
                data.channels[
                  i
                ].channel_distribution.channel.key.toLowerCase() ==
                "teamlocal-web-banners"
              ) {
                channelObj.page = 2;
                showChannels.teamlocalChannel = "Yes";
              }

              channels.push(channelObj);
            }
          }
          editData.retailers = retailers;
          editData.channels = channels;
          editData.navigation_url = data.navigation_url;
          this.setState({
            ...this.state,
            campaignData: editData,
            showChannels: showChannels,
            isEdit: true,
            isAddNew: true
          });
        }
      }
    }
  }

  componentDidMount() {
    this.props.actions.campaignList(
      this.props.PartnerId,
      this.state.pagination.page
    );
  }

  render() {
    const { isAddNew } = this.state;
    return (
      <div>
        {isAddNew ? (
          <CreateEditCampaign
            data={this.state.campaignData}
            channels={this.state.showChannels}
            closePromotionWizard={this.closePromotionWizard}
            isEditMode={this.state.isEdit}
            updateData={this.updateCampaignIntialState}
            hidePartnerTab={this.state.hidePartnerTab}
          />
        ) : (
          this.renderPromotionTable()
        )}
      </div>
    );
  }

  renderPromotionTable = () => {
    const { classes } = this.props;
    const { loading } = this.state;
    return (
      <GridContainer>
        <GridItem lg={12} />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Ad Manager
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <AgGrid
                  rowData={this.state.campaignList}
                  columnDefs={this.state.columnDefs}
                  handelEdit={this.handleEdit.bind(this)}
                  publish={this.publishCampaign.bind(this)}
                  performAction={this.performAction.bind(this)}
                  handelDelete={this.deleteCampaign.bind(this)}
                  AgGridActionButton={campaignActionButtonsComponent}
                  update={this.updateGridData}
                  isServerPagination={true}
                  pagination={this.state.pagination}
                  loading={loading}
                  search={this.searchText}
                  new={this.handelIsAddNew}
                  isAddBtn={true}
                />
                {this.state.error && this.state.error.trim() !== "" && (
                  <FormLabel className={classes.labelLeftHorizontal}>
                    <code>{this.state.error}</code>
                  </FormLabel>
                )}

                {loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.campaignList(
      this.props.PartnerId,
      page,
      this.state.searchtext
    );
  }

  searchText(params) {
    this.setState(
      {
        searchtext: params
      },
      () => {
        let page = 1;
        this.props.actions.campaignList(
          this.props.PartnerId,
          page,
          this.state.searchtext
        );
      }
    );
  }

  handelIsAddNew = () => {
    this.props.actions.getBrandRelatedPartners(this.props.PartnerId);
    this.props.actions.channelList();
    this.setState(() => ({
      ...this.state,
      isAddNew: true
    }));
  };

  handleEdit = id => {
    this.props.actions.getBrandRelatedPartners(this.props.PartnerId);
    this.props.actions.channelList();
    this.props.actions.getCampaignDataById(id);
  };

  updateCampaignIntialState(key, value, optional_value, channelid) {
    switch (key) {
      case "name":
        this.setState({
          ...this.state,
          campaignData: {
            ...this.state.campaignData,
            name: value
          }
        });
        break;

      case "navigation_url":
        this.setState({
          ...this.state,
          campaignData: {
            ...this.state.campaignData,
            navigation_url: value
          }
        });
        break;

      case "start_date":
        this.setState({
          ...this.state,
          campaignData: {
            ...this.state.campaignData,
            start_date: value
          }
        });
        break;

      case "end_date":
        this.setState({
          ...this.state,
          campaignData: {
            ...this.state.campaignData,
            end_date: value
          }
        });
        break;

      case "channel":
        let new_channel = [...this.state.campaignData.channels];
        new_channel = value;
        this.setState({
          ...this.state,
          campaignData: {
            ...this.state.campaignData,
            channels: new_channel
          }
        });
        break;

      case "showChannels":
        if (optional_value == 1) {
          let { hidePartnerTab } = this.state;
          let channels = null;
          if (value == "No") {
            hidePartnerTab = true;
            let campaignChannels = [...this.state.campaignData.channels];
            channels = campaignChannels.filter(x => x.channelId !== channelid);
            // this.props.setPromotionData('channel', channelRelatedItems)
          } else {
            hidePartnerTab = false;
            channels = [...this.state.campaignData.channels];
          }
          this.setState({
            ...this.state,
            hidePartnerTab: hidePartnerTab,
            showChannels: {
              ...this.state.showChannels,
              retailerChannel: value
            },
            campaignData: {
              ...this.state.campaignData,
              channels: channels
            }
          });
        } else {
          let channels = null;
          if (value == "No") {
            let campaignChannels = [...this.state.campaignData.channels];
            channels = campaignChannels.filter(x => x.channelId !== channelid);
            // this.props.setPromotionData('channel', channelRelatedItems)
          } else {
            channels = [...this.state.campaignData.channels];
          }
          this.setState({
            ...this.state,
            showChannels: {
              ...this.state.showChannels,
              teamlocalChannel: value
            },
            campaignData: {
              ...this.state.campaignData,
              channels: channels
            }
          });
        }

        break;
    }
  }

  closePromotionWizard() {
    this.setState({
      ...this.state,
      isAddNew: false,
      isEdit: false,
      campaignData: {
        ...initialState,
        channels: [],
        retailers: []
      },
      showChannels: {
        retailerChannel: "No",
        teamlocalChannel: "No"
      }
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    _agGridAPI = _.clone(params.api, true);
    params.api.sizeColumnsToFit();
  };

  performAction(campaignId, value) {
    this.props.actions.playPauseCampaign(campaignId, value, this.props.UserId);
  }

  deleteCampaign(campaignId) {
    this.props.actions.deleteCampaignAction(campaignId, this.props.UserId);
  }

  publishCampaign(campaignId) {
    this.props.actions.publishCampaignAction(campaignId, this.props.UserId);
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  data: state.campignManagementReducer.campaign.data,
  List: state.campignManagementReducer.list,
  pagination: state.campignManagementReducer.pagination,
  Loading: state.campignManagementReducer.loading,
  ListError: state.campignManagementReducer.error,
  isSaved: state.campignManagementReducer.isSaved,
  PartnerId: state.authState.user.partner_id,
  User: state.authState.user,
  UserId: state.authState.user.user,
  playPause: state.campignManagementReducer.playPause,
  deleteCampaign: state.campignManagementReducer.deleteCampaign,
  publishCampaign: state.campignManagementReducer.publishCampaign,
  isSaving: state.campignManagementReducer.isSaving
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.BRAND;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AdManager);
