import React, { Component } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";

class StockLocationActionBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  invokeParentMethod() {
    const { data } = this.props;
    return (
      <div
        style={{
          "line-height": "60px"
        }}
      >
        <span span onClick={() => this.performAction(data.id, 0)} title="Edit">
          <Edit />
        </span>
        <span onClick={() => this.performAction(data.id, 1)} title="Delete">
          <DeleteIcon />
        </span>
      </div>
    );
  }

  performAction(id, value) {
    switch (value) {
      case 0:
        this.props.context.componentParent.handelEdit(id);
        break;
      case 1:
        this.props.context.componentParent.deleteLocation(id);
        break;
    }
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default StockLocationActionBtn;
