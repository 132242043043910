import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import _ from "lodash";
import moment from "moment";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import Datetime from "react-datetime";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CircularProgress,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from "../components/ImageUpload.jsx";
import RetailerOfferActionButton from "../../../src/components/AgGrid/RetailerOfferActionButton";
import BrandOfferAction from "../../../src/components/AgGrid/BrandOfferAction";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { validationUtils } from "../../utils";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import extendedFormStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import OfferActions from "../components/offerActionComponent";
import SelectProductTemplate from "../components/selectProductTemplatesComponent";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import { ActionCreators } from "../../redux/actions";

function dateFormatter(params) {
  if (params.value) {
    return moment(params.value).format("MM/DD/YYYY");
  } else {
    return "";
  }
}

const initialState = {
  id: "",
  name: "",
  coupan_code: "",
  offer_type_id: null,
  descriptions: "",
  discount: 0.0,
  image: [],
  is_active: true,
  imagePreviewUrl: null,
  start_date: moment().format("MM/DD/YYYY"),
  end_date: moment().format("MM/DD/YYYY"),
  required: {
    name: "",
    descriptions: "",
    offer_type_id: null,
    discount: "",
    start_date: "",
    end_date: ""
  }
};

class OffersList extends React.Component {
  constructor(props) {
    super(props);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.addAnotherSegmentDetail = this.addAnotherSegmentDetail.bind(this);
    this.closeMappingHandler = this.closeMappingHandler.bind(this);
    this.handelEdit = this.handelEdit.bind(this);
    this.MapOfferToProductUnit = this.MapOfferToProductUnit.bind(this);
    this.startdatetoggle = this.startdatetoggle.bind(this);
    this.enddatetoggle = this.enddatetoggle.bind(this);
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
    this.state = {
      searchtext: null,
      offerList: [],
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      offer_id: "",
      startdatetoggle: false,
      enddatetoggle: false,
      add_products: false,
      isAddNew: false,
      addEditOffer: {
        ...initialState
      },
      columnDefs: [
        // {
        //     headerName: "ID",
        //     field: "id",
        //     hide: true
        // },
        // {
        //     headerName: "",
        //     field: "value",
        //     cellRenderer: "OfferActions",
        //     colId: "params",
        //     width: 95,
        //     resizable: false,
        //     sortable: false,
        // },
        {
          headerName: "Name",
          field: "name",
          width: 200,
          resizable: true,
          sortable: true
        },

        {
          headerName: "Descriptions",
          field: "descriptions",
          width: 200,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Value",
          field: "discount",
          width: 100,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Offer Type",
          field: "OfferType.name",
          width: 150,
          sortable: true,
          resizable: true
          // rowGroup: true,
          // hide: true
        },
        {
          headerName: "Start Date",
          field: "start_date",
          sortable: true,
          resizable: true,
          width: 120,
          valueFormatter: dateFormatter
        },
        {
          headerName: "End Date",
          field: "end_date",
          sortable: true,
          width: 120,
          resizable: true,
          valueFormatter: dateFormatter
        },
        // ,
        // {
        //     headerName: "Created Date",
        //     field: "created_at",
        //     sortable: true,
        //     width: 120,
        //     resizable: true,
        //     valueFormatter: dateFormatter
        // }
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 100,
          filter: false
        }
      ],
      context: { componentParent: this },
      frameworkComponents: {
        OfferActions: OfferActions
      },
      autoGroupColumnDef: {
        headerName: "Offer Type",
        field: "",
        rowDrag: false,
        cellRendererParams: { checkbox: false },
        headerCheckboxSelection: false,
        width: 200,
        suppressSizeToFit: true
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      domLayout: "autoHeight",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
  }

  addAnotherSegmentDetail = () => {
    this.setState(
      {
        isAddNew: false,
        addEditOffer: {
          ...initialState
        }
      },
      () => {}
    );
  };

  MapOfferToProductUnit(index) {
    let editData = this.props.List.find(o => o.id === index);
    this.setState({
      ...this.state,
      offer_id: editData.id,
      isEdit: false,
      isAddNew: false,
      add_products: true
    });
  }

  handelEdit(id) {
    this.props.actions.getOfferByIdAction(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (nextProps.isSaved) {
          this.setState(
            {
              ...this.state,
              isAddNew: false,
              addEditOffer: {
                ...initialState
              }
            },
            () => {
              this.props.actions.getOffersList(
                this.props.authUser.partner_id,
                this.state.pagination.page
              );
            }
          );
        }
      }

      if (nextProps.List && !_.isEqual(this.props.List, nextProps.List)) {
        this.setState({
          offerList: nextProps.List,
          pagination: nextProps.pagination
        });
      }

      if (nextProps.isImageDeleted) {
        const { image } = this.state.addEditOffer;
        let index = image.findIndex(x => x.id == nextProps.selectedImageId);
        if (index > -1) {
          image.splice(index, 1);
        }
        this.setState(
          {
            addEditOffer: {
              ...this.state.addEditOffer,
              image: image
            }
          },
          () => {
            this.props.actions.denyRemoveImageAction();
          }
        );
      }

      if (
        nextProps.offerData &&
        !_.isEqual(this.props.offerData, nextProps.offerData)
      ) {
        let image = nextProps.offerData.image;
        let newItemIndex = image.findIndex(x => x.id == "");
        if (newItemIndex == -1) {
          image.push({
            id: "",
            url: ""
          });
        }
        this.setState({
          addEditOffer: {
            ...this.state.addEditOffer,
            id: nextProps.offerData.id,
            name: nextProps.offerData.name,
            coupan_code: nextProps.offerData.coupan_code,
            offer_type_id: nextProps.offerData.OfferType.id,
            descriptions: nextProps.offerData.descriptions,
            discount: nextProps.offerData.discount,
            image: image,
            is_active: nextProps.offerData.is_active,
            imagePreviewUrl: null,
            start_date: moment(nextProps.offerData.start_date).format(
              "MM/DD/YYYY"
            ),
            end_date: moment(nextProps.offerData.end_date).format("MM/DD/YYYY")
          },
          isEdit: true,
          isAddNew: true
        });
      }
    }
  }

  closeMappingHandler() {
    this.setState(() => ({
      ...this.state,
      isAddNew: false,
      add_products: false,
      offer_id: ""
    }));
  }

  componentWillMount() {
    this.props.actions.getOffersList(
      this.props.authUser.partner_id,
      this.state.pagination.page
    );
  }

  componentDidMount() {
    this.props.actions.getOfferTypeList();
  }

  render() {
    return (
      <div>
        {!this.state.add_products &&
          (this.state.isAddNew
            ? this.renderAddOffers()
            : this.renderTagsTypeTable())}
        {this.state.add_products && this.props.authUser.role === "RE" && (
          <SelectProductTemplate
            AgGridActionButton={RetailerOfferActionButton}
            role={this.props.authUser.role}
            offer_id={this.state.offer_id}
            onClose={this.closeMappingHandler}
          />
        )}
        {this.state.add_products && this.props.authUser.role === "BR" && (
          <SelectProductTemplate
            AgGridActionButton={BrandOfferAction}
            role={this.props.authUser.role}
            offer_id={this.state.offer_id}
            onClose={this.closeMappingHandler}
            api_key={this.props.authUser.id}
          />
        )}
      </div>
    );
  }

  handelIsAddNew = () => {
    let image = [];
    image.push({
      id: "",
      url: ""
    });
    this.setState(() => ({
      ...this.state,
      isAddNew: true,
      addEditOffer: {
        ...initialState,
        image: image,
        start_date: "",
        end_date: "",
        required: {
          name: "",
          descriptions: "",
          offer_type_id: null,
          discount: "",
          start_date: "",
          end_date: ""
        }
      }
    }));
  };

  handleToggleStatus = curObj => event => {
    this.setState({
      addEditOffer: {
        ...this.state.addEditOffer,
        is_active: event.target.checked
      }
    });
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.addEditOffer.required;
    reqData[key] = validData;
    this.setState({
      addEditOffer: {
        required: reqData
      }
    });

    this.setState({
      addEditOffer: {
        ...this.state.addEditOffer,
        [key]: event.target.value
      }
    });
  };

  handleStartDateChange(event) {
    console.log(event);
    this.setState({
      addEditOffer: {
        ...this.state.addEditOffer,
        start_date: moment(event).format("MM/DD/YYYY")
      },
      startdatetoggle: false
    });
  }

  startdatetoggle(event) {
    let startdatetoggle = !this.state.startdatetoggle;
    this.setState({
      startdatetoggle: startdatetoggle
    });
  }

  enddatetoggle(event) {
    let enddatetoggle = !this.state.enddatetoggle;
    this.setState({
      enddatetoggle: enddatetoggle
    });
  }
  handleEndDateChange(event) {
    console.log(event);
    this.setState({
      addEditOffer: {
        ...this.state.addEditOffer,
        end_date: moment(event).format("MM/DD/YYYY")
      },
      enddatetoggle: false
    });
  }

  handelAddAnotherImage = () => {
    var productImages = this.state.addEditOffer.image;
    if (productImages) {
    } else {
      productImages = [];
    }

    productImages.push({
      id: "img_" + (productImages.length + 1),
      image: null,
      imagePreviewUrl: null
    });

    this.setState(() => ({
      addEditOffer: {
        ...this.state.addEditOffer,
        image: [...productImages]
      }
    }));
  };

  // handelImageRemove = (id) => {
  //     var productImages = this.state.addEditOffer.image;
  //     var index = productImages.findIndex(item => item.id === id);
  //     if (index > -1) {
  //         productImages[index].imagePreviewUrl = null;
  //         delete productImages[index].file_size
  //         delete productImages[index].original_file_name
  //     }
  //     this.setState({
  //         addEditOffer: {
  //             ...this.state.addEditOffer,
  //             image: [
  //                 ...productImages
  //             ]
  //         }
  //     });
  // }

  handelImageRemove = index => {
    let { image } = this.state.addEditOffer;
    let selectedImage = image[index];

    if (selectedImage instanceof Object && selectedImage.id !== "") {
      this.props.actions.removeImageAction(selectedImage.id);
    } else {
      image.splice(index, 1);
      this.setState({
        addEditOffer: {
          ...this.state.addEditOffer,
          image: image
        }
      });
    }
  };

  handelImageChange = (file, index) => {
    if (file instanceof Blob) {
      var productImages = this.state.addEditOffer.image;
      if (index > -1) {
        let clonnedObj = _.clone(productImages[index]);
        clonnedObj.url = file;
        clonnedObj.file_size = file.size;
        clonnedObj.original_file_name = file.name;
        productImages.splice(index, 0, clonnedObj);
      }
      this.setState({
        productData: {
          ...this.state.addEditOffer,
          image: [...productImages]
        }
      });
    } else {
      var productImages = this.state.addEditOffer.image;
      // var index = productImages.findIndex(item => item.id === id);
      if (index > -1) {
        let clonnedObj = _.clone(productImages[index]);
        clonnedObj.url = file.url;
        if (file.is_asset) {
          clonnedObj.asset_id = file.id;
        }
        clonnedObj.file_size = file.file_size;
        clonnedObj.original_file_name = file.original_file_name;
        productImages.splice(index, 0, clonnedObj);
      }
      this.setState({
        ...this.state,
        productData: {
          ...this.state.addEditOffer,
          image: [...productImages]
        }
      });
    }
  };

  validateIsRequired() {
    var reqData = this.state.addEditOffer["required"];
    var isValid = true;
    if (
      !this.state.addEditOffer.hasOwnProperty("name") ||
      (this.state.addEditOffer.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addEditOffer.hasOwnProperty("offer_type_id") ||
      (this.state.addEditOffer.offer_type_id === null ||
        reqData["offer_type_id"] === "error")
    ) {
      reqData["offer_type_id"] = "error";
      isValid = false;
    }

    if (
      !this.state.addEditOffer.hasOwnProperty("descriptions") ||
      (this.state.addEditOffer.descriptions.trim() === "" ||
        reqData["descriptions"] === "error")
    ) {
      reqData["descriptions"] = "error";
      isValid = false;
    }

    if (
      !this.state.addEditOffer.hasOwnProperty("discount") ||
      (this.state.addEditOffer.discount === 0 ||
        reqData["discount"] === "error")
    ) {
      reqData["discount"] = "error";
      isValid = false;
    }

    this.setState({
      addEditOffer: {
        ...this.state.addEditOffer,
        required: reqData
      }
    });

    return isValid;
  }

  SaveOffer = () => {
    if (this.validateIsRequired()) {
      console.log(this.props.authUser);
      this.setState(
        {
          addEditOffer: {
            ...this.state.addEditOffer,
            start_date: moment(this.state.addEditOffer.start_date),
            end_date: moment(this.state.addEditOffer.end_date),
            partner_id: this.props.authUser.partner_id
          }
        },
        () => {
          const { addEditOffer } = this.state;

          var files = [];
          if (addEditOffer.image && addEditOffer.image.length > 0) {
            for (var i in addEditOffer.image) {
              if (
                addEditOffer.image[i] &&
                addEditOffer.image[i].url instanceof Blob
              ) {
                files.push(addEditOffer.image[i].url);
              }
            }
          }
          this.props.actions.createOffers(
            this.props.authUser.pk,
            addEditOffer,
            files
          );
        }
      );
    }
  };

  renderAddOffers = () => {
    const { classes } = this.props;
    const { isAddNew } = this.state;
    const { required } = this.state.addEditOffer;
    const {
      name,
      offer_type_id,
      descriptions,
      discount,
      image,
      is_active,
      start_date,
      end_date,
      coupan_code
    } = this.state.addEditOffer;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Offers
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />

                  <CustomInput
                    labelText="Coupan Code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: coupan_code,
                      onChange: this.handleTextChange("coupan_code", "")
                    }}
                    id={"coupan_code_"}
                  />

                  <CustomInput
                    success={required.descriptions === "success"}
                    error={required.descriptions === "error"}
                    labelText="Descriptions *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: descriptions,
                      onChange: this.handleTextChange("descriptions", "")
                    }}
                  />

                  <CustomInput
                    success={required.discount === "success"}
                    error={required.discount === "error"}
                    labelText="Value *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      value: discount,
                      onChange: this.handleTextChange("discount", "")
                    }}
                  />

                  <FormControl fullWidth>
                    <InputLabel className={classes.label + " "}>
                      Start Date *
                    </InputLabel>
                    {/* <br />
                                        <br /> */}

                    <div style={{ marginTop: "40px" }}>
                      <Datetime
                        timeFormat={false}
                        open={this.state.startdatetoggle}
                        inputProps={{
                          placeholder: "Start Date",
                          value: start_date,
                          onClick: this.startdatetoggle
                        }}
                        value={start_date}
                        onChange={this.handleStartDateChange}
                      />
                    </div>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel className={classes.label + " "}>
                      End Date *
                    </InputLabel>
                    {/* <br />
                                        <br /> */}
                    <div style={{ marginTop: "40px" }}>
                      <Datetime
                        timeFormat={false}
                        open={this.state.enddatetoggle}
                        inputProps={{
                          placeholder: "End Date",
                          value: end_date,
                          onClick: this.enddatetoggle
                        }}
                        onChange={this.handleEndDateChange}
                      />
                    </div>
                  </FormControl>
                  <br />
                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.label + " "}
                      labelText="Offers *"
                    >
                      Offer Types*
                    </InputLabel>

                    <Select
                      success={required.offer_type_id === "success"}
                      error={required.offer_type_id === "error"}
                      style={{ marginTop: "0px" }}
                      value={offer_type_id}
                      onChange={this.handleTextChange("offer_type_id", "")}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Select Offer Type
                      </MenuItem>
                      {_.map(
                        this.props.OfferTypeList,
                        ({ id, name }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                              key={index + "_" + name}
                            >
                              {name}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>

                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus(is_active)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />
                  <br />
                  <br />
                  <br />
                  <legend>Offer Images</legend>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Grid
                        container
                        spacing={16}
                        alignItems={"flex-end"}
                        direction={"row"}
                        justify={"flex-end"}
                      />
                    </GridItem>
                    <br />
                    <br />
                    <br />
                    {_.map(image, (model, index) => {
                      return (
                        <ImageUpload
                          addButtonProps={{
                            round: true
                          }}
                          changeButtonProps={{
                            color: "rose",
                            round: true
                          }}
                          removeButtonProps={{
                            color: "danger",
                            round: true
                          }}
                          imgUrl={
                            model instanceof Object
                              ? model instanceof Blob
                                ? model
                                : model.url
                              : model
                          }
                          onimageRemove={() => this.handelImageRemove(index)}
                          onChange={file => this.handelImageChange(file, index)}
                        />
                      );
                    })}
                  </GridContainer>
                  <br />
                  {isAddNew && (
                    <Button color="info" onClick={this.SaveOffer}>
                      Save Changes
                    </Button>
                  )}
                  {isAddNew && (
                    <Button onClick={this.addAnotherSegmentDetail}>
                      {" "}
                      Cancel{" "}
                    </Button>
                  )}
                  {(this.props.saving || this.props.saving) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.saving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderTagsTypeTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Offers
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <AgGrid
                  rowData={this.state.offerList}
                  columnDefs={this.state.columnDefs}
                  handelEdit={this.handelEdit}
                  handelView={this.MapOfferToProductUnit}
                  Loading={this.props.Loading}
                  AgGridActionButton={
                    this.props.authUser.role === "RE"
                      ? RetailerOfferActionButton
                      : BrandOfferAction
                  }
                  update={this.updateGridData}
                  isServerPagination={true}
                  pagination={this.state.pagination}
                  search={this.searchText}
                  new={this.handelIsAddNew}
                  isAddBtn={true}
                />
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getOffersList(
      this.props.authUser.partner_id,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getOffersList(
          this.props.authUser.partner_id,
          page,
          this.state.searchtext
        );
      }
    );
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.offersReducer.get_offers.list,
  Loading: state.offersReducer.get_offers.loading,
  ListError: state.offersReducer.get_offers.error,
  saving: state.offersReducer.create_offer.saving,
  isSaved: state.offersReducer.create_offer.saved,
  saveError: state.offersReducer.create_offer.error,
  UserId: state.authState.user.user,
  OfferTypeList: state.offersReducer.get_offers_type.list,
  authUser: state.authState.user,
  isImageDeleted: state.appMenuReducer.removeImage.isDeleted,
  selectedImageId: state.appMenuReducer.removeImage.data,
  offerData: state.offersReducer.offer.data,
  offerDataLoading: state.offersReducer.offer.loading,
  offerDataError: state.offersReducer.offer.error,
  pagination: state.offersReducer.get_offers.pagination
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.ADMIN ||
  rolesNames.RoleMaster.BRAND ||
  rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...extendedFormStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(OffersList);
