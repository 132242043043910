import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Loader } from "react-overlay-loader";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import "ag-grid-enterprise";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import AgGrid from "components/AgGrid/AgGrid.jsx";

let agGridEditApi = null;

function getSimpleCellRenderer() {
  function SimpleCellRenderer() {}
  SimpleCellRenderer.prototype.init = function(params) {
    var tempDiv = document.createElement("div");
    if (params.node.group) {
      tempDiv.innerHTML = "<span>" + params.value + "</span>";
    } else {
      tempDiv.innerHTML = "<span>" + params.value + "</span>";
    }
    this.eGui = tempDiv.firstChild;
  };
  SimpleCellRenderer.prototype.getGui = function() {
    return this.eGui;
  };
  return SimpleCellRenderer;
}

var innerCellRenderer = function(params) {
  return params.value;
};

class ClassProductHeirarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      hierarchyList: [],
      selectedClassIndexes: [],
      selectedEntity: [],
      modalOpen: false,
      domLayout: "autoHeight",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      treeViewCol: [
        {
          headerName: "Class",
          field: "name",
          resizable: true,
          minWidth: 400,
          width: 800,
          showRowGroup: true,
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            suppressCount: false,
            checkbox: false,
            innerRenderer: innerCellRenderer,
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true
          }
        }
      ],

      groupDefaultExpanded: 0,
      components: { simpleCellRenderer: getSimpleCellRenderer() },
      context: { categoryGrid: this },
      getNodeChildDetails: function(data) {
        if (data && data.children && data.children.length > 0) {
          return {
            group: true,
            children: data.children,
            expanded: data.open
          };
        } else {
          return null;
        }
      }
    };
    this.openModal = this.openModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.createProduct = this.createProduct.bind(this);
    this.setSelectedIndexes = this.setSelectedIndexes.bind(this);
    this.onGridRowSelected = this.onGridRowSelected.bind(this);
    this.updatedPageData = this.updatedPageData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.batchSaved) {
        this.setState({
          modalOpen: false
        });
      }

      if (nextProps.hierarachy) {
        this.setState({
          hierarchyList: nextProps.hierarachy,
          pagination: nextProps.pagination
        });
      }

      if (
        !nextProps.savedTemplatesLoading &&
        nextProps.product_template_list &&
        nextProps.product_template_list.length > 0 &&
        nextProps.hierarachy &&
        !nextProps.batchSaved &&
        !nextProps.batchSaving
      ) {
        this.setSelectedIndexes(
          nextProps.product_template_list,
          nextProps.hierarachy
        );
        if (agGridEditApi) agGridEditApi.sizeColumnsToFit();
      }
    }
  }

  componentDidMount() {
    this.props.actions.getRetailerClassProductHierarchy(
      this.props.PartnerId,
      this.state.pagination.page
    );
  }

  componentWillMount() {
    // this.props.actions.getSavedBatchProductList(this.props.PartnerId);
  }

  setSelectedIndexes = (product_template_list, categorylist) => {
    let product_template = _.clone(product_template_list, true);
    this.setState({ selectedClassIndexes: product_template }, function() {
      this.onGridRowSelected(product_template);
    });
  };

  onGridRowSelected(product_template) {
    const componentThis = this;
    agGridEditApi.forEachNode(function(node) {
      if (node.data) {
        if (
          componentThis.state.selectedClassIndexes.indexOf(node.data.id) > -1 &&
          (node.data.role == undefined || node.data.role == null)
        ) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      }
    });
  }

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    const componentThis = this;
    var length = this.state.selectedEntity.length;
    var i = 0;
    for (i = 0; i < length; i++) {
      componentThis.state.selectedEntity.pop();
    }
    let selectedEntity = _.cloneDeep(this.state.selectedEntity);
    selectedRows.forEach(function(selectedRow, index) {
      if (selectedRow.role == undefined || selectedRow.role == null) {
        let data = {
          id: ""
        };
        data.id = selectedRow.id;
        selectedEntity.push(data);
      }
    });
    this.setState({
      selectedEntity: selectedEntity
    });
  }
  openModal() {
    this.setState({
      modalOpen: true
    });
  }

  handleModalClose() {
    this.gridApi.deselectAll();
    this.setState({
      modalOpen: false,
      selectedEntity: []
    });
  }

  createProduct() {
    this.props.actions.batchProductCreate(
      this.state.selectedEntity,
      this.props.UserId,
      this.props.PartnerId
    );
  }

  render() {
    return this.renderHierarchyView();
  }

  renderHierarchyView = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <Dialog
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to create the products?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ backgroundColor: "#248f24" }}
              onClick={this.createProduct}
              autoFocus
            >
              Yes
            </Button>
            <Button
              onClick={this.handleModalClose}
              style={{ backgroundColor: "#999" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <GridItem lg={12} />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p
                  className={
                    classes.cardIconTitle +
                    " grid-header-title" +
                    " wholesalor-hierarchy-title"
                  }
                  style={{
                    marginTop: "15px"
                  }}
                >
                  Class Product Hierarchy
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.hierarchyList}
                columnDefs={this.state.treeViewCol}
                update={this.updatedPageData}
                isServerPagination={true}
                pagination={this.state.pagination}
                loading={this.props.Loading}
                search={this.searchText}
                isHierarchyView={true}
                getNodeChildDetails={this.state.getNodeChildDetails}
              />
              {this.props.Loading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-12px"
                  }}
                />
              )}
              <Loader loading={this.props.Loading} />
            </CardBody>
            <CardBody>
              {false && this.props.authRole === "RE" && (
                <div style={{ float: "right" }}>
                  <Button
                    color="info"
                    disabled={
                      this.state.selectedEntity.length > 0 ? false : true
                    }
                    style={{ backgroundColor: "#248f24" }}
                    onClick={this.openModal}
                  >
                    Create Product
                  </Button>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updatedPageData(page) {
    this.props.actions.getRetailerClassProductHierarchy(
      this.props.PartnerId,
      page,
      this.state.searchtext
    );
    // this.props.actions.getClassProductTemplateHierarchy(null, page);
  }

  searchText(params) {
    this.setState(
      {
        searchtext: params
      },
      () => {
        let page = 1;
        this.props.actions.getRetailerClassProductHierarchy(
          this.props.PartnerId,
          page,
          this.state.searchtext
        );
      }
    );
  }

  getNodeChildDetails(data) {
    if (data && data.children && data.children.length > 0) {
      return {
        group: true,
        children: data.children,
        expanded: data.open
      };
    } else {
      return null;
    }
  }

  onGridEditReady = params => {
    this.gridApi = params.api;
    agGridEditApi = _.clone(params.api, true);
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  Loading: state.categoryReducer.retailerClassProductHierarchy.loading,
  savedTemplatesLoading: state.batchProductCreationReducer.loading,
  product_template_list:
    state.batchProductCreationReducer.product_template_list,
  ListError: state.categoryReducer.categorydata.error,
  UserId: state.authState.user.pk,
  PartnerId: state.authState.user.partner_id,
  authUser: state.authState.user,
  authRole: state.authState.user.role,
  batchSaved: state.batchProductCreationReducer.saved,
  batchSaving: state.batchProductCreationReducer.saving,
  hierarachy: state.categoryReducer.retailerClassProductHierarchy.data,
  pagination: state.categoryReducer.retailerClassProductHierarchy.pagination
});

const condition = authUser =>
  true || authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ClassProductHeirarchy);
