import axios from "../utils/axios.jsx";

export const getDashboardData = async partnerid => {
  try {
    let data = new FormData();
    data.append("partnerid", partnerid);
    const response = await axios.post(
      "api/retailer/dashboard-data/get/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
