import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ActionCreators } from "../../redux/actions";
import * as routesNames from "../../constants/routes";

const INITIAL_STATE = {
  isLoading: true
};

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = { ...INITIAL_STATE };
    }

    initApp = authenticated => {
      this.setState(() => ({
        isLoading: false
      }));
      if (window.INVITE) {
        if (authenticated) {
          this.props.actions.onNavigateTo(routesNames.LANDING);
        } else if (window.INVITE.registered) {
          this.props.actions.onNavigateTo(routesNames.LOGIN);
        } // else {
        // 	this.props.actions.onNavigateTo(routesNames.SIGN_UP);
        // }
      }
    };

    componentWillReceiveProps(nextProps) {
      if (nextProps) {
        if (
          this.props.authenticated === null &&
          nextProps.authenticated !== null
        ) {
          this.initApp(nextProps.authenticated);
        }
      }
    }

    componentDidMount() {
      setTimeout(() => {
        this.props.actions.getCurentUser();
      }, 2000);
    }

    render() {
      if (this.state.isLoading) {
        return (
          <LoadingOverlay>
            <CircularProgress
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                marginTop: "-25px",
                marginLeft: "-12px"
              }}
            />
            <Loader text="Loading.." />
          </LoadingOverlay>
        );
      }
      return <Component />;
    }
  }

  const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
  });

  const mapStateToProps = state => ({
    authenticated: state.authState.authenticated
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithAuthentication);
};

export default withAuthentication;
