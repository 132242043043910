import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import { LoadingOverlay, Loader } from "react-overlay-loader";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
//additional components
import Paginations from "../NewComponents/Pagination";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import extendedFormStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import * as routesNames from "../../constants/routes";
import "./box.css";

const initialState = {
  isUpdateMode: false,
  selectedBrands: [],
  assignedBrands: [],
  searchText: "",
  currentPageData: [],
  orgBrands: []
};
class BrandsAssignmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState
    };
  }
  handleToggleBrands = selectedBrands => {
    this.setState({
      selectedBrands: [...selectedBrands]
    });
  };
  componentDidMount() {
    if (this.props.UserId.role === rolesNames.RoleMaster.RETAILER) {
      this.props.actions.onNavigateTo(
        routesNames.RBMAPCOMPONENT +
          "?entityType=retailer&id=" +
          this.props.partner_id
      );
      return;
    }

    if (
      this.props.List &&
      this.props.List.length > 0 &&
      this.props.List[0]["brandsList"]
    ) {
      this.setState({
        assignedBrands: this.props.List[0]["brandsList"]
      });
    } else {
      this.props.actions.getRetailer(this.props.UserKey);
      // this.props.actions.getBrand();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        nextProps.List[0]["brandsList"] &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        this.setState({
          assignedBrands: nextProps.List[0]["brandsList"]
        });
      }

      if (!nextProps.isSaving && this.props.isSaving) {
        this.setState(() => ({
          isUpdateMode: false,
          selectedBrands: [],
          searchText: ""
        }));
      }
      if (nextProps.brandList && nextProps.brandList.length > 0) {
        this.setState(() => ({
          orgBrands: nextProps.brandList
          // isUpdateMode:!this.state.isUpdateMode
        }));
        this.setIsSelected(nextProps.brandList);
      }
    }
  }
  assignToggelBrands = brandName => {
    var { orgBrands } = this.state;
    var findBrand_index = orgBrands.findIndex(
      obj => obj.name.trim() === brandName.trim()
    );
    if (findBrand_index >= 0) {
      orgBrands[findBrand_index].assigned = !orgBrands[findBrand_index]
        .assigned;
    }
    this.setState({
      orgBrands: orgBrands
    });
  };
  drawBrandsList = () => {
    const { orgBrands, searchText } = this.state;
    var filteredTextBrands = orgBrands;
    if (searchText && searchText.trim().length > 0) {
      filteredTextBrands = orgBrands.filter(item =>
        item.name.toLowerCase().includes(searchText.trim().toLowerCase())
      );
    }
    return (
      <GridContainer>
        {filteredTextBrands.map((el, index) => {
          return (
            <GridItem md={3} sm={4}>
              <div
                style={{
                  border: "1px solid #D1D1D1",
                  boxSizing: "border-box",
                  borderRadius: "6px",
                  marginTop: "10px",
                  cursor: "pointer"
                }}
                className={el.assigned ? "box" : ""}
                onClick={() => this.assignToggelBrands(el.name)}
              >
                <div
                  style={{
                    minHeight: "28px",
                    marginTop: "12px"
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "20px",
                      lineHeight: "28px",
                      textAlign: "center",
                      color: "#EB9514",
                      display: "block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%"
                    }}
                    title={el.name}
                  >
                    {el.name}
                  </span>
                </div>
                <div style={{ height: "162px", textAlign: "center" }}>
                  <img
                    style={{
                      boxShadow: "0px 0px 0px 0px",
                      padding: "5px",
                      maxHeight: "100%",
                      maxWidth: "100%"
                    }}
                    src={
                      el && el.data && el.data.image
                        ? el.data.image.secure_url
                        : ""
                    }
                    alt="..."
                  />
                </div>
              </div>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };
  handleTextChange = () => event => {
    var searchText = event.target.value;
    this.setState({
      searchText: searchText
    });
  };
  saveBrands = () => {
    const { orgBrands } = this.state;
    var selectedBrands = orgBrands.filter(item => item.assigned);
    var data = {
      brandsList: selectedBrands
    };
    this.props.actions.saveRetailer(data, this.props.UserKey);
  };
  renderAddBrands = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Icon style={{ fontSize: "36px" }}>info</Icon>
            </CardIcon>
            <p className={classes.cardIconTitle}>
              Make edits to the fields below, then save changes.
            </p>
          </CardHeader>
          <CardBody>
            <LoadingOverlay>
              <GridContainer>
                <GridItem md={12} sm={12} xs={12}>
                  <GridContainer>
                    <GridItem md={8} sm={7} xs={12}>
                      <CustomInput
                        labelText="Search Brands"
                        id="filterBox"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          onChange: this.handleTextChange(),
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon
                                className={classes.icons}
                                style={{ color: "#555555", marginRight: "0px" }}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem md={4} sm={5} xs={12}>
                      <Grid
                        container
                        alignItems={"flex-end"}
                        direction={"row"}
                        justify={"flex-end"}
                      >
                        <Button color="info" onClick={() => this.saveBrands()}>
                          Save Changes
                        </Button>
                        <Button onClick={() => this.handelEdit()}>
                          Cancel
                        </Button>
                      </Grid>
                    </GridItem>
                    <GridItem md={12}>{this.drawBrandsList()}</GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              {this.props.Loading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-12px"
                  }}
                />
              )}
              <Loader loading={this.props.Loading} text="Saving.." />
            </LoadingOverlay>
          </CardBody>
        </Card>
      </GridContainer>
    );
  };
  handelDataOnPage = data => {
    this.setState({
      currentPageData: data
    });
  };
  renderBrandsTable = () => {
    const { classes } = this.props;
    const { assignedBrands, currentPageData } = this.state;
    const renderData = currentPageData ? currentPageData : [];
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              disabled={this.props.loadingGetData}
              color="success"
              onClick={this.handelEdit}
              className={classes.marginRight}
            >
              <EditIcon className={classes.icons} />
              Edit
            </Button>
          </Grid>
        </GridItem>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Icon style={{ fontSize: "36px" }}>info</Icon>
            </CardIcon>
            <p className={classes.cardIconTitle}>
              Click Edit above to make edits.
            </p>
          </CardHeader>
          <CardBody
            style={{
              minHeight: "150px"
            }}
          >
            <LoadingOverlay>
              <GridContainer
                style={{
                  justifyContent: "center"
                }}
              >
                {_.map(renderData, (el, index) => {
                  return (
                    <GridItem md={3} sm={4}>
                      <div
                        style={{
                          border: "1px solid #D1D1D1",
                          boxSizing: "border-box",
                          borderRadius: "6px",
                          marginTop: "10px",
                          cursor: "pointer"
                        }}
                        className={"box"}
                      >
                        <div
                          style={{
                            minHeight: "61px",
                            marginTop: "12px"
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "bold",
                              fontSize: "20px",
                              lineHeight: "28px",
                              textAlign: "center",
                              color: "#EB9514",
                              display: "block",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%"
                            }}
                            title={el.name}
                          >
                            {el.name}
                          </span>
                        </div>
                        <div style={{ height: "162px" }}>
                          <img
                            style={{
                              boxShadow: "0px 0px 0px 0px",
                              padding: "5px",
                              height: "inherit",
                              width: "100%"
                            }}
                            src={
                              el && el.data && el.data.image
                                ? el.data.image.secure_url
                                : ""
                            }
                            alt="..."
                          />
                        </div>
                      </div>
                    </GridItem>
                  );
                })}
              </GridContainer>
              <GridContainer key="row_brands_pages">
                <GridItem
                  md={4}
                  sm={4}
                  xs={12}
                  className={classNames(
                    classes.mlAuto,
                    classes.mrAuto,
                    classes.textAlign
                  )}
                >
                  {assignedBrands && assignedBrands.length > 0 && (
                    <Paginations
                      getPageData={this.handelDataOnPage}
                      data={assignedBrands}
                      dataPerPage={15}
                    />
                  )}
                </GridItem>
              </GridContainer>
              {this.props.loadingGetData && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-12px"
                  }}
                />
              )}
              <Loader loading={this.props.loadingGetData} text="Loading.." />
            </LoadingOverlay>
          </CardBody>
        </Card>
      </GridContainer>
    );
  };

  setIsSelected = orgBrandsData => {
    const { assignedBrands } = this.state;
    orgBrandsData.forEach(function(item) {
      var assigned_brands = assignedBrands.filter(
        element => element.name.trim() === item.name.trim()
      );
      if (assigned_brands && assigned_brands.length > 0) {
        item.assigned = true;
      } else {
        item.assigned = false;
      }
    });
    this.setState({
      orgBrands: orgBrandsData
    });
  };

  handelEdit = () => {
    this.props.actions.getBrand();
    const { orgBrands, assignedBrands } = this.state;
    orgBrands.forEach(function(item) {
      var assigned_brands = assignedBrands.filter(
        element => element.name.trim() === item.name.trim()
      );
      if (assigned_brands && assigned_brands.length > 0) {
        item.assigned = true;
      } else {
        item.assigned = false;
      }
    });
    this.setState({
      isUpdateMode: !this.state.isUpdateMode,
      orgBrands: orgBrands,
      selectedBrands: [],
      searchText: ""
    });
  };
  render() {
    return (
      <div />
      // this.state.isUpdateMode ? this.renderAddBrands() : this.renderBrandsTable()
    );
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.retailerState.list,
  Loading: state.retailerState.loading,
  ListError: state.retailerState.error,
  loadingGetData: state.retailerState.loadingData,
  isSaved: state.retailerState.isSaved,
  UserKey: state.authState.user.Token,
  brandList: state.brandState.list,
  isSaving: state.retailerState.isSaving,
  UserId: state.authState.user,
  partner_id: state.authState.user.partner_id
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({ ...regularFormsStyle, ...extendedFormStyle }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BrandsAssignmentPage);
