import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import _ from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import * as routesNames from "../../constants/routes";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";


class BrandComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  handleMode = () => {
    let clickedAction = "brand"
    let entityType = "wholesaler";
    this.props.actions.onNavigateTo(
      routesNames.PARTNERMAPPING +
      "?entityType=" +
      btoa(entityType) +
      "&id=" +
      btoa(this.props.partnerId) +
      "&name=" +
      btoa(this.props.UserId.name) +
      "&action=" +
      btoa(clickedAction)
    );
  };



  render() {
    return (
      <div>
        {" "}
        {this.handleMode()}
      </div>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.brandState.list,
  Loading: state.brandState.loading,
  ListError: state.brandState.error,
  isSaved: state.brandState.isSaved,
  isDeleted: state.classReducer.isDeleted,
  UserId: state.authState.user,
  partnerId: state.authState.user.partner_id,
  brandList: state.brandState.map_wholesaler_brand,
  isMapSaved: state.industriesReducer.isMapSaved
});

BrandComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  entityData: PropTypes.object,
  selectedIndexes: PropTypes.array.isRequired
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BrandComponent);