import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Typography from "@material-ui/core/Typography";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import moment from "moment";
import { LoadingOverlay, Loader } from "react-overlay-loader";

class RetailerSettlement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmpassword: "",
      modalChangePassword: true,
      error: false,
      isPasswordLengthValidationerror: false,
      passwordConfirmPasswordMismatchError: false,
      settlement: 0.0
    };
  }
  handelDailogTextChange = key => event => {
    var isValid = true;
    // if (!validationUtils.validateData(type, event.target.value)) {
    //     isValid = false;
    // }
    let keys = event.target.id;
    this.setState({
      [keys]: event.target.value,
      isError: !isValid
    });
  };

  componentWillMount() {
    this.props.actions.getRetailerSettlement(this.props.partner);
  }

  componentDidMount() {}

  saveReatiler = () => {
    this.props.actions.approveRetailerStatement(this.props.partner);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        modalChangePassword: nextProps.open
      });

      this.setState({
        settlement: nextProps.settlement.settlement.pending_settlement
      });
    }
  }

  render() {
    const { classes, open, onClose, userid } = this.props;
    const {
      isError,
      password,
      confirmpassword,
      modalChangePassword
    } = this.state;
    return (
      <GridContainer>
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={"xs"}
          disableBackdropClick={modalChangePassword}
          onClose={() => onClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Pending Settlement</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <LoadingOverlay>
                <Typography variant="h6" gutterBottom>
                  ${this.state.settlement}
                </Typography>

                <Typography variant="caption" display="block" gutterBottom>
                  As on date: {moment().format("MMM Do[,] YYYY")}
                </Typography>
                {this.props.settlement.loading && (
                  <Loader
                    loading={this.props.settlement.loading}
                    text="Loading.."
                  />
                )}
                {this.props.settlement.saving && (
                  <Loader
                    loading={this.props.settlement.saving}
                    text="Processing.."
                  />
                )}
              </LoadingOverlay>
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.saveReatiler()}
                color="primary"
              >
                {"Process Settlement"}
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => onClose()}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </GridContainer>
    );
  }
}

RetailerSettlement.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userid: PropTypes.string.isRequired
};

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserKey: state.authState.user.user,
  api_key: state.authState.user.id,
  partner_id: state.authState.user.partner_id,
  UserId: state.authState.user,
  settlement: state.retailerState.retailer_settlement
});

// const condition = (authUser) =>
//     authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  // withAuthorization(condition),
  withStyles({ ...regularFormsStyle, ...extendedTablesStyle }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RetailerSettlement);
