import * as shippingService from "../../services/shippingService";
import * as actionTypes from "./actionTypes";

export const createShipping = (id, data, partnerId) => async dispatch => {
  dispatch(createShippingRequest());
  try {
    const success = await shippingService.createShipping(id, data, partnerId);
    if (success.status === "1") {
      dispatch(createShippingSuccess(success.data));
    } else {
      dispatch(createShippingFailure(success.error));
    }
  } catch (error) {
    dispatch(createShippingFailure(error.message));
  }
};

export const getShippingList = () => async dispatch => {
  dispatch(getShippingRequest());
  try {
    var success = await shippingService.getShippingList();
    if (success.status && success.status === "1") {
      dispatch(getShippingSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getShippingFailure(success.error));
    } else {
      dispatch(getShippingFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getShippingFailure(error.message));
  }
};

export const Aftergetdata = role => async dispatch => {
  dispatch(afterRequest());
};

const afterRequest = () => {
  return {
    type: actionTypes.AFTER_SAVE_SUCCESS
  };
};
const createShippingRequest = () => {
  return {
    type: actionTypes.CREATE_SHIPPING_REQUEST
  };
};

const createShippingSuccess = data => {
  return {
    type: actionTypes.CREATE_SHIPPING_SUCCESS,
    payload: data
  };
};

const createShippingFailure = error => {
  return {
    type: actionTypes.CREATE_SHIPPING_FAILURE,
    payload: error
  };
};

const getShippingRequest = () => {
  return {
    type: actionTypes.GET_SHIPPING_REQUEST
  };
};

const getShippingSuccess = data => {
  return {
    type: actionTypes.GET_SHIPPING_SUCCESS,
    payload: data
  };
};

const getShippingFailure = error => {
  return {
    type: actionTypes.GET_SHIPPING_FAILURE,
    payload: error
  };
};
