import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import PropTypes from "prop-types";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { validationUtils } from "../../../utils";

// @material-ui/core components/Dialog
import Slide from "@material-ui/core/Slide";

import InputLabel from "@material-ui/core/InputLabel";
import ImageUpload from "../../../../src/views/components/ImageUpload";
import AgGrid from "../../../components/AgGrid/AgGrid.jsx";
import AddressActionButton from "../Component/addressActionButton";

import Hours from "../Component/Hours";

import TextField from "@material-ui/core/TextField";

// import withScriptjs from "react-google-maps/lib/async/withScriptjs";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

// If you want to use the provided css
import "react-google-places-autocomplete/dist/index.min.css";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.handelAddAddress = this.handelAddAddress.bind(this);
    this.handelImageRemove = this.handelImageRemove.bind(this);
    this.state = {
      isAddNew: false,
      addAddress: {
        id: "",
        name: "",
        phone: "",
        pincode: "",
        locality: "",
        country: "",
        address: "",
        city: "",
        state: "",
        image: {},
        lat: "",
        lng: "",
        address_type: "Store",
        hours: [],
        email: ""
      },
      required: {
        name: "",
        phone: "",
        pincode: "",
        locality: "",
        address: "",
        city: "",
        state: "",
        address_type: "Home"
      },
      list: [],
      isHours: false,
      hoursList: [],
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 237,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Phone",
          field: "phone",
          width: 237,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Pincode",
          field: "pincode",
          width: 237,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ]
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        loading: nextProps.PartnerSaving
      });
    }
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      index: null,
      addAddress: {
        name: "",
        phone: "",
        pincode: "",
        locality: "",
        country: "",
        address: "",
        city: "",
        state: "",
        image: "",
        lat: "",
        email: "",
        lng: "",
        is_pickup: true,
        is_primary: false
      }
    });
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      addAddress: {
        ...this.state.addAddress,
        [key]: event.target.value
      }
    });
  };

  saveClass = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addClassData;
      this.props.actions.saveClass(
        this.props.UserId,
        savingData,
        this.props.PartnerId
      );
    }
  };

  handelEdit = index => {
    const { list } = this.props;
    const { isEdit } = this.props;
    let data = list;
    if (data[index]) {
      this.setState({
        addAddress: {
          id: data[index].id,
          name: data[index].name,
          phone: data[index].phone,
          pincode: data[index].pincode,
          locality: data[index].locality,
          country: data[index].country,
          address: data[index].address,
          city: data[index].city,
          state: data[index].state,
          image: data[index].image,
          lat: data[index].lat,
          lng: data[index].lng,
          is_pickup: data[index].is_pickup,
          is_primary: data[index].is_primary,
          email: data[index].email
        },
        isAddNew: true,
        isEdit: true,
        index: index
      });
    }
  };

  handelAddAddress = () => {
    if (this.validateIsRequired()) {
      const { addAddress, index } = this.state;
      this.setState({
        isAddNew: false
      });

      this.props.onHandleAdd(addAddress, index);
    }
  };

  deleteDialog = (state, Id, Index) => {
    this.setState({
      openDeleteDialog: state,
      classId: Id
    });
  };

  deleteSegment = Id => {
    this.props.actions.deleteClass(this.props.UserId, this.state.classId);
  };

  render() {
    return this.state.isAddNew ? this.renderClass() : this.renderClassTable();
  }

  saveHandler = () => {
    this.state.mappingData.classes = this.state.classValue.id;
    this.state.mappingData.attribute_group = this.state.selectedUAIndexes;
    this.props.actions.mapClassUA(this.props.UserId, this.state.mappingData);
  };

  handelCloseHoursComponent = () => {
    this.setState({
      isHours: false
    });
  };

  handleHoursAdd = (value, index) => {
    this.setState({
      isHours: false
    });
    this.props.addHoursList(index, value);
  };
  handleHoursClose = () => {
    this.setState({
      isHours: false
    });
  };
  hoursComponent = () => {
    const { classes } = this.props;
    var title = "";
    if (this.state.classValue != null) {
      title = "Assign Attribute Groups to Classs";
    }
    return (
      <Hours
        index={this.state.AddressIndex}
        onHandleAdd={this.handleHoursAdd}
        isOpen={this.state.isHours}
        onClose={this.handleHoursClose}
        hourList={this.state.hoursList}
      />
    );
  };

  handelImageChange = file => {
    let { addAddress } = this.state;
    if (file instanceof Blob) {
      // let reader = new FileReader();

      let original_name = file.name;
      let fileSize = file.size;
      addAddress["image"] = file;
      addAddress["file_size"] = fileSize;
      addAddress["original_file_name"] = original_name;
      this.props.handleFiles(file, 1);
      // reader.onloadend = () => {
      //     addAddress['image'] = reader.result;
      //     addAddress['file_size'] = fileSize;
      //     addAddress['original_file_name'] = original_name;
      //     this.setState({
      //         addAddress: addAddress
      //     });
      // };
      // reader.readAsDataURL(file);
    } else {
      addAddress.image = file.url;
      if (file.is_asset) {
        addAddress.asset_id = file.id;
      }
      addAddress.file_size = file.file_size;
      addAddress.original_file_name = file.original_file_name;
    }
    this.setState({
      ...this.state,
      addAddress: addAddress
    });
  };

  handelImageRemove = () => {
    let { image } = this.state.addAddress;
    if (image instanceof Blob || typeof image == "string") {
      this.setState({
        addAddress: {
          ...this.state.addAddress,
          image: null
        }
      });
      this.props.handleFiles(image, 0);
    } else if (image instanceof Object) {
      this.props.actions.removeImageAction(image.id);
    }
  };

  handleSelect = address => {
    geocodeByAddress(address.description).then(results =>
      this.setAddress(results[0])
    );
  };

  setAddress = data => {
    getLatLng(data).then(({ lat, lng }) =>
      this.setState({
        ...this.state,
        addAddress: {
          ...this.state.addAddress,
          lat: lat,
          lng: lng
        }
      })
    );

    let state = "";
    let zip = "";
    let country = "";
    let locality = "";
    let city = "";
    for (var index in data.address_components) {
      if (
        data.address_components[index] &&
        data.address_components[index].types &&
        data.address_components[index].types.length > 0
      )
        if (data.address_components[index].types.includes("postal_code")) {
          zip = data.address_components[index].long_name;
        }
      if (
        data.address_components[index].types.includes(
          "administrative_area_level_1"
        )
      ) {
        state = data.address_components[index].long_name;
      }
      if (data.address_components[index].types.includes("country")) {
        country = data.address_components[index].long_name;
      }
      if (data.address_components[index].types.includes("locality")) {
        city = data.address_components[index].long_name;
      }
      if (
        data.address_components[index].types.includes(
          "administrative_area_level_2"
        )
      ) {
        locality = data.address_components[index].long_name;
      }
    }
    if (data && data.formatted_address) {
      this.setState({
        ...this.state,
        addAddress: {
          ...this.state.addAddress,
          address: data.formatted_address,
          pincode: zip,
          country: country,
          locality: locality,
          city: city,
          state: state
        }
      });
    }
  };

  handleToggleStatus = (key, curObj) => event => {
    this.setState({
      addAddress: {
        ...this.state.addAddress,
        [key]: event.target.checked
      }
    });
  };

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;

    if (
      !this.state.addAddress.hasOwnProperty("name") ||
      (this.state.addAddress.name.trim() === "" || reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addAddress.hasOwnProperty("phone") ||
      (this.state.addAddress.phone.trim() === "" ||
        reqData["phone"] === "error")
    ) {
      reqData["phone"] = "error";
      isValid = false;
    }

    if (
      !this.state.addAddress.hasOwnProperty("pincode") ||
      (this.state.addAddress.pincode.trim() === "" ||
        reqData["pincode"] === "error")
    ) {
      reqData["pincode"] = "error";
      isValid = false;
    }

    if (
      !this.state.addAddress.hasOwnProperty("email") ||
      (this.state.addAddress.email.trim() === "" ||
        reqData["email"] === "error")
    ) {
      reqData["email"] = "error";
      isValid = false;
    }

    this.setState({ required: reqData });
    return isValid;
  }

  renderClass = () => {
    const { classes } = this.props;

    const {
      name,
      phone,
      pincode,
      locality,
      address,
      city,
      state,
      image,
      country,
      address_type,
      is_pickup,
      email,
      is_primary
    } = this.state.addAddress;

    const { isEdit, required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <InputLabel className={classes.label + " "}>
                        Address
                      </InputLabel>
                      <GooglePlacesAutocomplete
                        onSelect={this.handleSelect}
                        className="autofill"
                        value={address}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <InputLabel className={classes.label + " "}>
                        Name
                      </InputLabel>

                      <br />
                      <TextField
                        value={name}
                        success={required.name === "success"}
                        error={required.name === "error"}
                        variant="outlined"
                        fullWidth
                        classes={{
                          root: "attribute-txtbox"
                        }}
                        onChange={this.handleTextChange("name", "")}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <InputLabel className={classes.label + " "}>
                        Phone
                      </InputLabel>
                      <br />
                      <TextField
                        value={phone}
                        success={required.phone === "success"}
                        error={required.phone === "error"}
                        variant="outlined"
                        fullWidth
                        classes={{
                          root: "attribute-txtbox"
                        }}
                        onChange={this.handleTextChange(
                          "phone",
                          validationUtils.validationTypes.mobileNumber
                        )}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <InputLabel className={classes.label + " "}>
                        Pincode
                      </InputLabel>

                      <br />
                      <TextField
                        value={pincode}
                        success={required.pincode === "success"}
                        error={required.pincode === "error"}
                        variant="outlined"
                        fullWidth
                        classes={{
                          root: "attribute-txtbox"
                        }}
                        onChange={this.handleTextChange("pincode", "")}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <InputLabel className={classes.label + " "}>
                        Locality
                      </InputLabel>

                      <br />
                      <TextField
                        value={locality}
                        success={required.locality === "success"}
                        error={required.locality === "error"}
                        variant="outlined"
                        fullWidth
                        classes={{
                          root: "attribute-txtbox"
                        }}
                        onChange={this.handleTextChange("locality", "")}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <InputLabel className={classes.label + " "}>
                        Country
                      </InputLabel>

                      <br />
                      <TextField
                        value={country}
                        success={required.country === "success"}
                        error={required.country === "error"}
                        variant="outlined"
                        fullWidth
                        classes={{
                          root: "attribute-txtbox"
                        }}
                        onChange={this.handleTextChange("country", "")}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <InputLabel className={classes.label + " "}>
                        City *
                      </InputLabel>

                      <br />
                      <TextField
                        value={city}
                        success={required.city === "success"}
                        error={required.city === "error"}
                        variant="outlined"
                        fullWidth
                        classes={{
                          root: "attribute-txtbox"
                        }}
                        onChange={this.handleTextChange("city", "")}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <InputLabel className={classes.label + " "}>
                        state
                      </InputLabel>

                      <br />
                      <TextField
                        value={state}
                        success={required.state === "success"}
                        error={required.state === "error"}
                        variant="outlined"
                        fullWidth
                        classes={{
                          root: "attribute-txtbox"
                        }}
                        onChange={this.handleTextChange("state")}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <InputLabel className={classes.label + " "}>
                        Email *
                      </InputLabel>

                      <br />
                      <TextField
                        value={email}
                        success={required.email === "success"}
                        error={required.email === "error"}
                        variant="outlined"
                        fullWidth
                        classes={{
                          root: "attribute-txtbox"
                        }}
                        onChange={this.handleTextChange(
                          "email",
                          validationUtils.validationTypes.email
                        )}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked={is_pickup}
                            onClick={this.handleToggleStatus(
                              "is_pickup",
                              is_pickup
                            )}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{ label: classes.label }}
                        label="Pickup Location"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked={is_primary}
                            onClick={this.handleToggleStatus(
                              "is_primary",
                              is_primary
                            )}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{ label: classes.label }}
                        label="Primary Location"
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <ImageUpload
                        addButtonProps={{
                          round: true
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true
                        }}
                        imgUrl={
                          image instanceof Object
                            ? image instanceof Blob
                              ? image
                              : image.url
                            : image
                        }
                        onimageRemove={this.handelImageRemove}
                        onChange={this.handelImageChange}
                      />
                    </GridItem>
                  </GridContainer>

                  {(this.props.Loading || this.props.PartnerSaving) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader
                    loading={
                      this.props.PartnerSaving ||
                      this.props.partnerDetailLoading
                    }
                    text=""
                  />

                  <Button color="info" onClick={() => this.handelAddAddress()}>
                    Add
                  </Button>
                  <Button onClick={() => this.handelIsAddNew(false)}>
                    Cancel
                  </Button>
                  {(this.props.Loading || this.props.PartnerSaving) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader
                    loading={
                      this.props.PartnerSaving ||
                      this.props.partnerDetailLoading
                    }
                    text=""
                  />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  handelHours = index => {
    const { list } = this.props;
    let hoursList = [];
    if (list[index].hours) {
      hoursList = list[index].hours;
    }
    this.setState({
      isHours: true,
      AddressIndex: index,
      hoursList: hoursList
    });
  };

  renderClassTable = () => {
    const { classes } = this.props;

    return (
      <GridContainer>
        {this.hoursComponent()}
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          />
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p>
                  <Button
                    disabled={this.props.Loading}
                    color="success"
                    onClick={() => this.handelIsAddNew()}
                    className={classes.marginRight + " grid-header-button"}
                  >
                    <AddIcon className="grid-header-button-icon" /> New
                  </Button>
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <div
                  id="myGrid"
                  style={{
                    width: "100%"
                  }}
                  className="ag-theme-material"
                >
                  <AgGrid
                    rowData={this.props.list}
                    columnDefs={this.state.columnDefs}
                    handelEdit={this.handelEdit}
                    AgGridActionButton={AddressActionButton}
                    handelAdd={this.handelHours}
                  />
                </div>
                {this.props.PartnerSaving && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.PartnerSaving} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.partnerSate.list,
  Loading: state.partnerSate.loading,
  ListError: state.partnerSate.error,
  Partner: state.partnerSate.addingPartner.partner,
  PartnerSaving: state.partnerSate.addingPartner.loading,
  isSaved: state.partnerSate.addingPartner.isSaved,
  PartnerError: state.partnerSate.addingPartner.error
});

Address.propTypes = {
  list: PropTypes.array.isRequired,
  onHandleAdd: PropTypes.func.isRequired,
  addHoursList: PropTypes.func.isRequired
};

export default compose(
  // withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Address);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
