import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@material-ui/core/FormLabel";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

import { validationUtils } from "../../utils";

// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Slide from "@material-ui/core/Slide";

import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import UserAction from "../../components/AgGrid/UserAction";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      isAddNew: false,
      addUserData: {
        id: null,
        email: "",
        password: "",
        user_group_id: "",
        is_active: false
      },
      required: {
        email: "",
        password: "",
        user_group_id: ""
      },
      usersList: [],
      userGroupData: null,
      error: null,
      openDeleteDialog: false,
      userGroupId: null,
      columnDefs: [
        {
          headerName: "Email",
          field: "email",
          width: 300,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "User Group",
          field: "user_group.name",
          width: 470,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        // {
        //     headerName: "Status",
        //     field: "is_active",
        //     width: 270,
        //     resizable: true,
        //     sortable: true,
        //     filter: "agTextColumnFilter",
        //     cellRenderer: function(params) {
        //
        //         if(params.data && params.data.is_active == true){
        //             return 'Active'
        //         }else{
        //             return 'InActive'
        //         }
        //     },
        // },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ]
    };
    this.handelEdit = this.handelEdit.bind(this);
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const userGroupData = nextProps.List;
        this.setState(() => ({
          userGroupData: userGroupData
        }));
      }
      if (
        nextProps.userList &&
        nextProps.userList.length &&
        !_.isEqual(this.props.userList, nextProps.userList)
      ) {
        this.setState({
          usersList: nextProps.userList,
          pagination: nextProps.pagination
        });
      }

      if (nextProps.ListError && nextProps.ListError.trim() !== "") {
        this.setState(() => ({
          error: nextProps.ListError,
          isAddNew: true
        }));
      }
      if (nextProps.isSaved) {
        this.setState(
          {
            isAddNew: false,
            isEdit: false
          },
          () => {
            this.props.actions.getUsersListAction(
              this.props.User.partner_id,
              this.state.pagination.page
            );
          }
        );
      }
      if (nextProps.isDeleted) {
        this.setState(() => ({
          openDeleteDialog: false
        }));
      }
    }
  }

  componentDidMount() {
    this.props.actions.getUsersListAction(
      this.props.User.partner_id,
      this.state.pagination.page
    );
    this.props.actions.getUserGroupList(this.props.User.partner_id);
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      addUserData: {
        email: "",
        password: "",
        user_group_id: "",
        is_active: false,
        is_Deleted: false
      }
    });
  };
  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      addUserData: {
        ...this.state.addUserData,
        [key]: event.target.value
      }
    });
  };
  handleToggleStatus = curObj => event => {
    this.setState({
      addUserData: {
        ...this.state.addUserData,
        is_active: event.target.checked
      }
    });
  };

  saveUsers = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addUserData;
      this.setState(
        {
          addUserData: {
            ...this.state.addUserData,
            partner_id: this.props.User.partner_id
          }
        },
        () => {
          this.props.actions.createUserAction(
            this.props.User.partner_id,
            this.state.addUserData
          );
        }
      );
      // this.props.actions.createUserGroup(this.props.UserId, savingData);
    }
  };

  handelEdit = id => {
    const { isEdit, usersList } = this.state;
    const data = usersList;
    let index = data.findIndex(a => a.id == id);
    if (data[index]) {
      this.setState({
        addUserData: {
          email: data[index].email,
          user_group_id: data[index].user_group.id,
          index: index,
          id: data[index].id,
          is_active: data[index].is_active,
          is_Deleted: false
        },
        isAddNew: true,
        isEdit: true
      });
    }
  };

  closeCreateModal = () => {
    this.setState({
      addUserData: {
        email: "",
        description: "",
        index: "",
        id: "",
        is_active: "",
        is_Deleted: ""
      },
      isAddNew: false,
      isEdit: false
    });
  };

  deleteDialog = (state, Id, Index) => {
    this.setState({
      openDeleteDialog: state,
      userGroupId: Id
    });
  };

  deleteSegment = Id => {
    this.props.actions.deleteClass(this.props.UserId, this.state.userGroupId);
  };

  render() {
    return this.state.isAddNew
      ? this.renderUserGroup()
      : this.renderUserGroupTable();
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addUserData.hasOwnProperty("email") ||
      (this.state.addUserData.email.trim() === "" ||
        reqData["email"] === "error")
    ) {
      reqData["email"] = "error";
      isValid = false;
    }

    if (
      !this.state.addUserData.hasOwnProperty("user_group_id") ||
      (this.state.addUserData.user_group_id === "" ||
        reqData["user_group_id"] === "error")
    ) {
      reqData["user_group_id"] = "error";
      isValid = false;
    }

    if (!this.state.isEdit) {
      if (
        !this.state.addUserData.hasOwnProperty("password") ||
        (this.state.addUserData.password.trim() === "" ||
          reqData["password"] === "error")
      ) {
        reqData["password"] = "error";
        isValid = false;
      }
    }
    this.setState({ required: reqData });
    return isValid;
  }

  deleteDilog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        disableBackdropClick={true}
        onClose={() => this.openModalDialog("openDialog", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              Are you sure you want to delete?{" "}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.deleteSegment()}
              >
                Delete
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.deleteDialog(false, "", "")}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  };

  renderUserGroup = () => {
    const { classes } = this.props;
    const {
      email,
      password,
      user_group_id,
      is_active
    } = this.state.addUserData;
    const { required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              {/* <CardIcon color="info">
                                <Icon style={{ fontSize: '36px' }}>account_circle</Icon>
                            </CardIcon> */}
              <p
                className={
                  classes.cardIconTitle + " wholesalor-hierarchy-title"
                }
              >
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.email === "success"}
                    error={required.email === "error"}
                    labelText="Email *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: email,
                      onChange: this.handleTextChange("email", "")
                    }}
                    id={"email_"}
                  />
                  {!this.state.isEdit ? (
                    <CustomInput
                      success={required.password === "success"}
                      error={required.password === "error"}
                      labelText="Password*"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        value: password,
                        onChange: this.handleTextChange("password", "")
                      }}
                      id={"password_"}
                    />
                  ) : (
                    ""
                  )}
                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="User Group *"
                    >
                      User Group
                    </InputLabel>
                    <Select
                      success={required.user_group_id === "success"}
                      error={required.user_group_id === "error"}
                      style={{ marginTop: "0px" }}
                      value={this.state.addUserData.user_group_id}
                      onChange={this.handleTextChange("user_group_id", "")}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose User Group
                      </MenuItem>
                      {_.map(
                        this.state.userGroupData,
                        ({ id, name }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                              key={index + "_" + id}
                            >
                              {name}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                  <br />
                  {/* <FormControlLabel
                                        control={
                                            <Checkbox
                                                tabIndex={-1}
                                                checked={is_active}
                                                onClick={this.handleToggleStatus(is_active)}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot
                                                }}

                                            />
                                        }

                                        classes={{ label: classes.label }}
                                        label="Active"
                                    /> */}
                  {this.props.ListError && this.props.ListError.trim() !== "" && (
                    <div>
                      <FormLabel className={classes.labelLeftHorizontal}>
                        <code>{this.state.error}</code>
                      </FormLabel>
                    </div>
                  )}

                  <br />
                  <Button color="info" onClick={this.saveUsers}>
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.closeCreateModal}>Cancel</Button>
                  {this.props.isSaving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.isSaving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderUserGroupTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Associate User{" "}
                </p>
                {/* <p>
                                    <Button
                                        disabled={this.props.Loading}
                                        color="success"
                                        onClick={this.handelIsAddNew}
                                        size='sm'
                                        className={classes.marginRight + " grid-header-button"}>
                                        <AddIcon className="grid-header-button-icon" />   New
                        </Button>
                                </p> */}
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.usersList}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                loading={this.props.Loading}
                AgGridActionButton={UserAction}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                search={this.searchText}
                new={this.handelIsAddNew}
                isAddBtn={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getUsersListAction(
      this.props.User.partner_id,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getUsersListAction(
          this.props.User.partner_id,
          page,
          this.state.searchtext
        );
      }
    );
  }

  userGroupMenuMapping(userGroupId) {
    this.setState({
      isMenuMapping: true
    });
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.userGroupReducer.list,
  Loading: state.usersReducer.loading,
  ListError: state.usersReducer.error,
  userList: state.usersReducer.list,
  isSaved: state.usersReducer.isSaved,
  isDeleted: state.userGroupReducer.isDeleted,
  UserId: state.authState.user.pk,
  User: state.authState.user,
  isSaving: state.userGroupReducer.isSaving,
  List: state.userGroupReducer.list,
  pagination: state.usersReducer.pagination
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.RETAILER ||
  rolesNames.RoleMaster.WHOLESALOR ||
  rolesNames.RoleMaster.BRAND ||
  rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Users);
