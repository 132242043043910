import * as locatorService from "../../services/tagsService";
import * as actionTypes from "./actionTypes";

export const createTags = (id, data) => async dispatch => {
  dispatch(createTagsRequest());
  try {
    const success = await locatorService.createTags(id, data);
    if (success.status === "1") {
      dispatch(createTagsSuccess(success.data));
    } else {
      dispatch(createTagsFailure(success.error));
    }
  } catch (error) {
    dispatch(createTagsFailure(error.message));
  }
};

export const getTagsList = role => async dispatch => {
  dispatch(getTagsRequest());
  try {
    var success = await locatorService.getTagsList(role);
    if (success.status && success.status === "1") {
      dispatch(getTagsSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getTagsFailure(success.error));
    } else {
      dispatch(getTagsFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getTagsFailure(error.message));
  }
};

export const Aftergetdata = role => async dispatch => {
  dispatch(afterRequest());
};

const afterRequest = () => {
  return {
    type: actionTypes.AFTER_SAVE_SUCCESS
  };
};
const createTagsRequest = () => {
  return {
    type: actionTypes.CREATE_TAGS_REQUEST
  };
};

const createTagsSuccess = data => {
  return {
    type: actionTypes.CREATE_TAGS_SUCCESS,
    payload: data
  };
};

const createTagsFailure = error => {
  return {
    type: actionTypes.CREATE_TAGS_FAILURE,
    payload: error
  };
};

const getTagsRequest = () => {
  return {
    type: actionTypes.GET_TAGS_REQUEST
  };
};

const getTagsSuccess = data => {
  return {
    type: actionTypes.GET_TAGS_SUCCESS,
    payload: data
  };
};

const getTagsFailure = error => {
  return {
    type: actionTypes.GET_TAGS_FAILURE,
    payload: error
  };
};
