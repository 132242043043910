import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import CircularProgress from "@material-ui/core/CircularProgress";
// @material-ui/icons
//import Face from "@material-ui/icons/Face";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      password: "",
      confirmpassword: "",
      modalChangePassword: true,
      error: false
    };
  }

  handelDailogTextChange = key => event => {
    var isValid = true;
    // if (!validationUtils.validateData(type, event.target.value)) {
    //     isValid = false;
    // }
    let keys = event.target.id;
    this.setState({
      [keys]: event.target.value,
      isError: !isValid
    });
  };

  componentDidMount() {}

  saveReatiler = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get("id");
    const { password, confirmpassword } = this.state;

    if (password && password != "" && password == confirmpassword) {
      this.props.actions.savePassword(id, password, true);
    } else {
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        modalChangePassword: nextProps.open
      });
    }
  }
  render() {
    const { classes } = this.props;
    const {
      isError,
      password,
      confirmpassword,
      modalChangePassword
    } = this.state;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.onSave}>
              <LoadingOverlay>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader>
                    <h4>
                      <b>Change Password</b>
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      success={password !== ""}
                      error={password === ""}
                      labelText="Password *"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        value: password,
                        onChange: this.handelDailogTextChange("password")
                      }}
                    />
                    <CustomInput
                      success={confirmpassword !== ""}
                      error={confirmpassword === "" || isError}
                      labelText="Confirm Password *"
                      id="confirmpassword"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        value: confirmpassword,
                        onChange: this.handelDailogTextChange(
                          "confirmpassword",
                          ""
                        )
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <GridContainer>
                      <GridItem lg={12} md={12}>
                        {" "}
                        <Button
                          color="rose"
                          block
                          onClick={() => this.saveReatiler()}
                        >
                          Save
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                </Card>
                {(this.props.Loading || this.props.loadingGetData) && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Verifying.." />
              </LoadingOverlay>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ChangePasswordPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  Loading: state.authState.loginPending,
  ListError: state.authState.loginError
});

export default compose(
  withStyles(loginPageStyle),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ChangePasswordPage);

//export default withStyles(loginPageStyle)(LoginPage);
