import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, InputLabel, TextField, Tooltip, Checkbox, withStyles ,IconButton, InputAdornment } from "@material-ui/core";
import { HelpOutlineRounded, Check } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import ImageUpload from "../../components/ImageUpload.jsx";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class ProductInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      product_template: [],
      brands: [],
      categorieslist: [],
      imageObj: {
        id: "",
        image: null,
        imagePreviewUrl: null
      },
      error: null,
      isBrandSelected: false,
      descriptionState: EditorState.createEmpty(),
      brandDescriptionState: EditorState.createEmpty()
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleCatgoryChange = this.handleCatgoryChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.categorieslist && nextProps.categorieslist.length) {
        this.setState({
          categorieslist: nextProps.categorieslist
        })
      }
      if (!_.isEqual(this.props, nextProps)) {
        let data = nextProps.data;
        if (!nextProps.editState && data.images.length == 0) {
          data.images.push(this.state.imageObj);
        }
        if (nextProps.isProductSelected && nextProps.templateCheck == "Yes") {
          const content = htmlToDraft(data.brand_description);
          const contentState = ContentState.createFromBlockArray(
            content.contentBlocks,
            content.entityMap
          );
          const editorState = EditorState.createWithContent(contentState);
          this.setState(
            {
              brandDescriptionState: editorState,
              data: data
            },
            () => {
              this.props.setProductData("isTemplateSelected", false);
            }
          );
        } else {
          this.setState({
            data: data
          });
        }
      }
      if (nextProps.ProductTemplates && nextProps.templateCheck == "Yes") {
        const ProductTemplates = nextProps.ProductTemplates.products;

        const productsList = [];

        for (let i = 0; i < ProductTemplates.length; i++) {
          ProductTemplates.findIndex(p => p.ptid === ProductTemplates[i].ptid);
          if (
            productsList.findIndex(p => p.ptid === ProductTemplates[i].ptid) >
            -1
          ) {
          } else {
            productsList.push(ProductTemplates[i]);
          }
        }
        let list = _.clone(productsList);
        let selectedbrand = nextProps.data.brand;
        let productlist = list.filter(x => x.brandname === selectedbrand.name);
        this.setState({
          product_template: productlist
        });
      }
      if (nextProps.Brands && nextProps.templateCheck == "Yes") {
        this.setState({
          brands: nextProps.Brands
        });
      }

      if (nextProps.isImageDeleted) {
        const { images } = this.state.data;
        let index = images.findIndex(x => x.id == nextProps.selectedImageId);
        images.splice(index, 1);
        this.setState(
          {
            data: {
              ...this.state.data,
              images: images
            }
          },
          () => {
            this.props.actions.denyRemoveImageAction();
          }
        );
      }
    }
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      if (data.images.length == 0) {
        data.images.push(this.state.imageObj);
      }
      const descriptioncontent = htmlToDraft(data.description);
      const contentState = ContentState.createFromBlockArray(
        descriptioncontent.contentBlocks,
        descriptioncontent.entityMap
      );
      const descriptionState = EditorState.createWithContent(contentState);

      const branddescriptioncontent = htmlToDraft(data.brand_description);
      const brandcontentState = ContentState.createFromBlockArray(
        branddescriptioncontent.contentBlocks,
        branddescriptioncontent.entityMap
      );
      const brandDescriptionState = EditorState.createWithContent(
        brandcontentState
      );
      this.setState({
        brandDescriptionState: brandDescriptionState,
        descriptionState: descriptionState,
        data: data
      });
    }
    if (this.props.templateCheck == "Yes") {
      this.props.actions.getRetailerRelatedBrandAction(this.props.PartnerId);
    }
    this.props.actions.getCategoryListAction(this.props.PartnerId);
  }

  render() {
    const { brandDescriptionState, descriptionState } = this.state;
    const { classes, editState, cloneState } = this.props;
    console.log(cloneState, editState)
    const {
      name,
      mrp,
      images,
      is_used,
      is_consumable,
      brand,
      template,
      retailer_srp,
      retailercategories
    } = this.state.data;

    console.log("this.state.data", this.state.data);
    return (
      <GridContainer>
        <GridItem lg={1} md={1} />
        <GridItem lg={10} md={10}>
          <form>
            <GridContainer className="grid-container-control">
            {this.props.templateCheck == "Yes" ? (
                <GridItem lg={6} md={6} className="griditem-control">
                  <InputLabel
                    className={classes.label + " "}
                    labelText="Product"
                  >
                    Product
                    <Tooltip
                      className="control-tooltip"
                      title="Please select Product"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>
                  <br />
                  <Autocomplete
                    options={this.state.product_template}
                    getOptionLabel={option => option.ptname}
                    filterSelectedOptions
                    value={this.setSelectedTemplate(template)}
                    onChange={(event, value) => this.handleChange(value)}
                    classes={{
                      root: "custom-autocomplete",
                      inputRoot: "custom-autocomplete-input-root",
                      tag: "custom-autocomplete-tag"
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Please select Product"
                      />
                    )}
                  />
                </GridItem>
              ) : (
                ""
              )}
              {this.props.templateCheck == "Yes" || this.props.editState ? (
                <GridItem lg={6} md={6} className="griditem-control">
                  <InputLabel className={classes.label + " "} labelText="Brand">
                    Brand
                    <Tooltip
                      className="control-tooltip"
                      title="Please select Brand"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>
                  <br />
                  <Autocomplete
                    options={this.state.brands}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    value={brand ? brand : {}}
                    onChange={(event, value) => this.handleBrandChange(value)}
                    classes={{
                      root: "custom-autocomplete",
                      inputRoot: "custom-autocomplete-input-root",
                      tag: "custom-autocomplete-tag"
                    }}
                    disabled={this.props.editState}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Please select Brand"
                      />
                    )}
                  />
                </GridItem>
              ) : (
                ""
              )}
              {this.props.templateCheck == "Yes" ? (
                <GridItem lg={6} md={6} className="griditem-control">
                  <InputLabel
                    className={classes.label + " "}
                    labelText="Product"
                  >
                    Product
                    <Tooltip
                      className="control-tooltip"
                      title="Please select Product"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>
                  <br />
                  <Autocomplete
                    options={this.state.product_template}
                    getOptionLabel={option => option.ptname}
                    filterSelectedOptions
                    value={this.setSelectedTemplate(template)}
                    onChange={(event, value) => this.handleChange(value)}
                    classes={{
                      root: "custom-autocomplete",
                      inputRoot: "custom-autocomplete-input-root",
                      tag: "custom-autocomplete-tag"
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Please select Product"
                      />
                    )}
                  />
                </GridItem>
              ) : (
                ""
              )}
              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "} labelText="Name">
                  Name
                  <Tooltip
                    className="control-tooltip"
                    title="Please select Name"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <TextField
                  value={name ? name : ""}
                  variant="outlined"
                  name="name"
                  fullWidth
                  placeholder="Name"
                  classes={{
                    root: "attribute-txtbox"
                  }}
                  disabled={
                    !cloneState && editState && brand && brand.id !== this.props.PartnerId
                      ? true
                      : false
                  }
                  error={this.state.error}
                  helperText={this.state.error ? this.state.error : ""}
                  onChange={this.handleTextChange()}
                />
              </GridItem>
              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "} labelText="SRP">
                  SRP
                  <Tooltip
                    className="control-tooltip"
                    title="Please select SRP"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <TextField
                  value={mrp ? mrp : ""}
                  variant="outlined"
                  name="mrp"
                  fullWidth
                  placeholder="SRP"
                  classes={{
                    root: "attribute-txtbox"
                  }}
                  disabled={
                    editState && brand && brand.id !== this.props.PartnerId
                      ? true
                      : false
                  }
                  onChange={this.handleTextChange()}
                />
              </GridItem>
              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "} labelText="SRP">
                  Retailer's SRP
                  <Tooltip
                    className="control-tooltip"
                    title="Please select SRP"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <TextField
                  value={retailer_srp ? retailer_srp : ""}
                  variant="outlined"
                  name="retailer_srp"
                  fullWidth
                  placeholder="Retailer's SRP"
                  classes={{
                    root: "attribute-txtbox"
                  }}
                  onChange={this.handleTextChange()}
                />
              </GridItem>
              <GridItem lg={6} md={6} className="griditem-control">
                <InputLabel className={classes.label + " "} labelText="Category">
                  Category
                  <Tooltip
                    className="control-tooltip"
                    title="Please select Category"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>
                <br />
                <Autocomplete
                  options={this.state.categorieslist}
                  getOptionLabel={option => option.name}
                  filterSelectedOptions
                  value={retailercategories ? retailercategories : []}
                  multiple={true}
                  onChange={(event, value) => this.handleCatgoryChange(value)}
                  classes={{
                    root: "custom-autocomplete",
                    inputRoot: "custom-autocomplete-input-root",
                    tag: "custom-autocomplete-tag"
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Please select Category"
                    />
                  )}
                />
              </GridItem>
              <GridItem lg={12} md={12} className="griditem-control">
                <InputLabel className={classes.label + " "} labelText="Image">
                  Image
                </InputLabel>
                <br />
                {_.map(
                  images,
                  ({ id, image, is_brand_product_image }, index) => {
                    return (
                      <ImageUpload
                        key={index}
                        ref={"image_" + index}
                        addButtonProps={{
                          round: true
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true
                        }}
                        disabled={is_brand_product_image}
                        disableText="Brand Image can't be removed"
                        imgUrl={
                          image instanceof Object
                            ? image instanceof Blob
                              ? image
                              : image.url
                            : image
                        }
                        // onComponentCreate={this.setImageUploadState(index)}
                        onimageRemove={() => this.handelImageRemove(index)}
                        onChange={file => this.handelImageChange(file, id)}
                      />
                    );
                  }
                )}
              </GridItem>
              <GridItem lg={6} md={6} className="griditem-control">
                <Checkbox
                  className="checkbox-control"
                  tabIndex={-1}
                  checked={is_used ? true : false}
                  onClick={this.handleToggleStatus()}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                  name="is_used"
                />
                {/* {this.setImageUploadState()} */}
                <div className={classes.label}>
                  <span>Used</span>
                  <Tooltip
                    className="control-tooltip"
                    title="Check if the product is already in use"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </div>
              </GridItem>
              <GridItem lg={6} md={6} className="griditem-control">
                <Checkbox
                  disabled={
                    editState && brand && brand.id !== this.props.PartnerId
                      ? true
                      : false
                  }
                  className="checkbox-control"
                  tabIndex={-1}
                  checked={is_consumable ? true : false}
                  onClick={this.handleToggleStatus()}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                  name="is_consumable"
                />
                <div className={classes.label}>
                  <span>Consumable</span>
                  <Tooltip
                    className="control-tooltip"
                    title="Inventory is not managed for consumable products"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </div>
              </GridItem>
              {this.props.templateCheck == "Yes" || this.props.editState ? (
                <GridItem lg={12} md={12} className="griditem-control">
                  <InputLabel className={classes.label + " "} labelText="Notes">
                    Brand Description
                    <Tooltip
                      className="control-tooltip"
                      title="Add Description for product"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>

                  <br />
                  <Editor
                    readOnly={true}
                    editorState={brandDescriptionState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapper"
                    editorClassName="editor"
                  />
                </GridItem>
              ) : (
                ""
              )}
              <GridItem lg={12} md={12} className="griditem-control">
                <InputLabel className={classes.label + " "} labelText="Notes">
                  Description
                  <Tooltip
                    className="control-tooltip"
                    title="Add Description for product"
                  >
                    <HelpOutlineRounded />
                  </Tooltip>
                </InputLabel>

                <br />
                <Editor
                  editorState={descriptionState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapper"
                  editorClassName="editor"
                  onEditorStateChange={this.onEditorStateChange}
                />
              </GridItem>
            </GridContainer>
            {(this.props.Loading || this.props.LoadingData) && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
          </form>
        </GridItem>
        <GridItem lg={1} md={1} />
      </GridContainer>
    );
  }

  setImageUploadState(index) {
    let ref_key = "image_" + index;
    const { data } = this.state;
    if (this.refs && this.refs[ref_key]) {
      const imageUpload = this.refs[ref_key];
      if (this.props.editState || this.props.templateCheck === "Yes") {
        if (
          data.images[index].image == undefined &&
          data.images[index].image == ""
        ) {
          imageUpload.refs.fileInput.value = null;
          imageUpload.setState({
            file: null,
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
          });
        } else {
          if (imageUpload.refs && imageUpload.refs.fileInput) {
            imageUpload.refs.fileInput.value = "";
            imageUpload.setState({
              file: data.images[index].image
                ? data.images[index].image instanceof Object
                  ? data.images[index].image.secure_url
                  : data.images[index].image
                : null,
              imagePreviewUrl: data.images[index].image
                ? data.images[index].image instanceof Object
                  ? data.images[index].image.secure_url
                  : data.images[index].image
                : this.props.avatar
                  ? defaultAvatar
                  : defaultImage
            });
          }
        }
      }
    }
  }

  setSelectedTemplate(id) {
    let selection = {}
    if (id) {
      let { product_template } = this.state
      let index = product_template.findIndex(x => x.ptid === id)
      if (index > -1) {
        selection = product_template[index]
      }
    }
    return selection
  }

  handleBrandChange(value) {
    this.props.setProductData("brand", value);
  };

  handleCatgoryChange(value) {
    this.props.setProductData("retailercategories", value);
  };

  handleChange(value) {
    this.setState(
      {
        isBrandSelected: true
      },
      () => {
        this.props.setProductData("template", value.ptid);
      }
    );
  };

  handleTextChange = () => event => {
    if (
      event.target.name == "name" &&
      event.target.value &&
      event.target.value.length > 255
    ) {
      this.setState({
        error: "The length of name should be less than 255 character"
      });
      return;
    } else {
      if (
        event.target.name == "name" &&
        event.target.value &&
        event.target.value.length <= 255
      ) {
        this.setState({
          error: null
        });
      }
    }
    this.props.setProductData(event.target.name, event.target.value);
  };

  handleToggleStatus = () => event => {
    this.props.setProductData(event.target.name, event.target.checked);
  };

  onEditorStateChange = editorState => {
    this.setState({
      descriptionState: editorState
    });
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.setProductData("description", value);
  };

  handelImageChange = (file, id) => {
    if (file instanceof Blob) {
      let { imageObj, data } = this.state;
      let clonnedObj = _.clone(imageObj);
      // clonnedObj.id = clonnedObj.id + data.images.length
      clonnedObj.image = file;
      clonnedObj.imagePreviewUrl = file;
      clonnedObj.file_size = file.size;
      clonnedObj.original_file_name = file.name;
      data.images.splice(data.images.length - 1, 0, clonnedObj);
      this.setState({
        data: data
      });

      // let reader = new FileReader();
      //
      // reader.onloadend = () => {
      //     let { imageObj, data } = this.state;
      //     let clonnedObj = _.clone(imageObj)
      //     // clonnedObj.id = clonnedObj.id + data.images.length
      //     clonnedObj.image = reader.result;
      //     clonnedObj.imagePreviewUrl = reader.result;
      //     clonnedObj.file_size = fileSize
      //     clonnedObj.original_file_name = original_name
      //     data.images.splice(data.images.length -1, 0, clonnedObj)
      //     this.setState({
      //         data: data
      //     });
      // };
      // reader.readAsDataURL(file);
    } else {
      let { imageObj, data } = this.state;
      let clonnedObj = _.clone(imageObj);
      clonnedObj.image = file.url;
      clonnedObj.imagePreviewUrl = file.url;
      if (file.is_asset) {
        clonnedObj.asset_id = file.id;
      }
      clonnedObj.file_size = file.file_size;
      clonnedObj.original_file_name = file.original_file_name;
      data.images.splice(data.images.length - 1, 0, clonnedObj);
      this.setState({
        data: data
      });
    }
  };

  handelImageRemove = index => {
    let { images } = this.state.data;
    let selectedImage = images[index];
    let is_universal_image = false;
    if (selectedImage.is_brand_product_image) {
      is_universal_image = true;
    }

    if (selectedImage instanceof Object && selectedImage.id !== "") {
      if (is_universal_image) {
        images.splice(index, 1);
        this.setState({
          data: {
            ...this.state.data,
            images: images
          }
        });
      } else {
        this.props.actions.removeImageAction(selectedImage.id);
      }
    } else {
      images.splice(index, 1);
      this.setState({
        data: {
          ...this.state.data,
          images: images
        }
      });
    }
  };

}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  categorieslist: state.categoryReducer.categorydata.data,
  Brands: state.productsAssignmentsState.brands.data,
  PartnerId: state.authState.user.partner_id,
  UserId: state.authState.user.user,
  ProductTemplates:
    state.brandState.map_Retailer_brand_producttemplate_Selected,
  ProductData: state.productsAssignmentsState.brand_product.data,
  Loading: state.productsAssignmentsState.brand_product.loading,
  LoadingData:
    state.brandState.map_Retailer_brand_producttemplate_Selected.loadingData,
  isImageDeleted: state.appMenuReducer.removeImage.isDeleted,
  selectedImageId: state.appMenuReducer.removeImage.data
});

ProductInfo.propTypes = {
  templateCheck: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  editState: PropTypes.bool.isRequired,
  cloneState: PropTypes.bool.isRequired
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductInfo);
