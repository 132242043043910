// import axios from 'axios';
import axios from "../utils/axios.jsx";
import { localStorageUtils } from "../utils";

export const saveCampaignData = async (userId, Savedata, partnerId, files) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("partnerid", partnerId);
    data.append("data", JSON.stringify(Savedata));
    files.forEach(file => {
      data.append(file.name, file);
    });
    const response = await axios.post("/api/admin/campaign/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCampaignList = async (partnerid, page, search) => {
  try {
    let url = "/api/admin/campaign/list/?partnerid=" + partnerid;

    if (page) {
      url = url + "&page=" + page;
    }

    if (search) {
      url = url + "&search=" + search;
    }

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const playPauseCampaign = async (id, value, userId) => {
  try {
    let data = new FormData();
    data.append("campaignId", id);
    data.append("playValue", value);
    data.append("userId", userId);

    const response = await axios.post(
      "/api/admin/campaign/play-pause/",
      data,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCampaign = async (id, userId) => {
  try {
    let data = new FormData();
    data.append("campaignId", id);
    data.append("userId", userId);
    const response = await axios.post(
      "/api/admin/campaign/delete/",
      data,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChannelList = async () => {
  try {
    const response = await axios.get(
      "/api/admin/campaign-channel/list/",
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChannelDistributionList = async id => {
  try {
    let data = new FormData();
    data.append("channel_id", id);

    const response = await axios.post(
      "/api/admin/channel-distribution/list/",
      data,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const publishCampaign = async (id, userId) => {
  try {
    let data = new FormData();
    data.append("campaignId", id);
    data.append("userId", userId);
    const response = await axios.post(
      "/api/admin/campaign/publish/",
      data,
      localStorageUtils.getHeader()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCampaignById = async (id, partner_id) => {
  try {
    let url = "/api/admin/campaign/getbyid/?campaignId=" + id;

    if (partner_id) url += "&partner_id=" + partner_id;

    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPartnersCampaignList = async (id, channel, page, search) => {
  try {
    let url = "/api/retailer/campaign/list/?partnerid=" + String(id);
    if (channel) {
      url = url + "&channel=" + String(channel);
    }
    if (page) {
      url = url + "&page=" + String(page);
    }

    if (search) {
      url = url + "&search=" + search;
    }

    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRetailersBrandList = async (retailerid, role) => {
  try {
    const response = await axios.get(
      "/api/partners/partner/get/?partnerid=" +
        String(retailerid) +
        "&role=" +
        String(role)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRetailersBrandAccessToPublishCampaign = async retailerid => {
  try {
    const response = await axios.get(
      "/api/campaign/brands-setting/get/?partnerid=" + String(retailerid)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SaveRetailersBrandAccessToPublishCampaign = async (
  partnerId,
  Savedata,
  userid
) => {
  try {
    let data = new FormData();
    data.append("partnerid", partnerId);
    data.append("data", JSON.stringify(Savedata));
    data.append("userid", userid);
    const response = await axios.post(
      "/api/campaign/brands-setting/save/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
