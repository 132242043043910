export const SAVE_PARTNER_PARTNERS_MAPPING_REQUEST =
  "save_partner_partners_mapping_request";
export const SAVE_PARTNER_PARTNERS_MAPPING_FAILURE =
  "save_partner_partners_mapping_failure";
export const SAVE_PARTNER_PARTNERS_MAPPING_SUCCESS =
  "save_partner_partners_mapping_success";

export const GET_PARTNER_MAPPING_REQUEST = "get_partner_mapping_request";
export const GET_PARTNER_MAPPING_FAILURE = "get_partner_mapping_failure";
export const GET_PARTNER_MAPPING_SUCCESS = "get_partner_mapping_success";

export const REMOVE_PARTNER_PARTNERS_MAPPING_REQUEST =
  "remove_partner_partners_mapping_request";
export const REMOVE_PARTNER_PARTNERS_MAPPING_FAILURE =
  "remove_partner_partners_mapping_failure";
export const REMOVE_PARTNER_PARTNERS_MAPPING_SUCCESS =
  "remove_partner_partners_mapping_success";
