import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import cx from "classnames";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormLabel, CircularProgress } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AgGrid from "components/AgGrid/AgGrid.jsx";
import PartnerPartnersMappingAction from "../../components/AgGrid/PartnerPartnersMappingAction";
import * as routesNames from "../../constants/routes";
import { ActionCreators } from "../../redux/actions";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

let isInitialSet = true;
class PartnerPartnersMappingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialSet: true,
      isUnselectionPopup: false,
      nextpage: null,
      isSaveNextModal: false,
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      error: null,
      isDeleteAction: false,
      actionBtnData: {},
      loading: false,
      actionName: "",
      modalOpen: false,
      modalRemoveOpen: false,
      isActionClicked: false,
      list: [],
      partnerid: "",
      partnername: "",
      selectedEntity: [],
      colDefs: [
        {
          field: "name",
          minWidth: 800,
          width: 800,
          resizable: true,
          showRowGroup: true,
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            suppressCount: true,
            checkbox: true,
            innerRenderer: "checkboxCellRenderer",
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true
          },
          headerValueGetter: params => {
            let search = window.location.search;
            let urlParams = new URLSearchParams(search);
            let action = urlParams.get("action");
            let actionName = atob(action);
            return actionName == "brand"
              ? "Brand / Category / Product"
              : "Wholesalor / Brand / Category / Product";
          }
        },
        {
          headerName: "Actions",
          field: "",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ],
      context: { partnerAction: this },
      hierarchicalView: function (data) {
        if (data && data.children && data.children.length > 0) {
          return {
            group: true,
            children: data.children,
            expanded: data.open
          };
        } else {
          return null;
        }
      },
      domLayout: "autoHeight",
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        floatingFilter: true
      },
      frameworkComponents: {
        AgGridActionButton: PartnerPartnersMappingAction
      }
    };
    this.openModal = this.openModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.savePartnerMapping = this.savePartnerMapping.bind(this);
    this.removePartnerMapping = this.removePartnerMapping.bind(this);
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
    this.saveNextPagePopup = this.saveNextPagePopup.bind(this);
    this.performSaveNext = this.performSaveNext.bind(this);
    this.gridRefs = React.createRef();
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let entityId = params.get("id");
    let partnerid = atob(entityId);
    let entityName = params.get("name");
    let partnername = atob(entityName);
    let action = params.get("action");
    let actionName = atob(action);
    this.setState(
      {
        partnerid: partnerid,
        partnername: partnername,
        actionName: actionName
      },
      () => {
        if (this.state.actionName.toUpperCase() == "BRAND") {
          this.props.actions.getBrandCategoryProductHierarchyAction(
            this.props.PartnerId,
            this.state.partnerid,
            this.state.pagination.page
          );
        }
        if (this.state.actionName.toUpperCase() == "WHOLESALER") {
          this.props.actions.getWholesalorBrandCategoryProductHierarchyAction(
            this.state.partnerid,
            true,
            this.state.pagination.page
          );
        }
        this.props.actions.getPartnerMappingAction(this.state.partnerid);
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!this.state.modalOpen) {
        let list = [];
        let { pagination } = this.state;
        if (
          nextProps.BrandList &&
          nextProps.BrandList.length > 0 &&
          !_.isEqual(this.props.BrandList, nextProps.BrandList)
        ) {
          list = nextProps.BrandList;
          pagination = nextProps.BrandPagination;
        }

        if (
          nextProps.WholesalerList &&
          nextProps.WholesalerList.length > 0 &&
          !_.isEqual(this.props.WholesalerList, nextProps.WholesalerList)
        ) {
          list = nextProps.WholesalerList;
          pagination = nextProps.WholesalerPagination;
        }

        let loading = false;
        if (nextProps.BrandListLoading || nextProps.WholesalerListLoading) {
          loading = true;
        }
        this.setState(
          {
            isSaveNextModal: false,
            list: list,
            pagination: pagination,
            loading: loading
          },
          () => {
            if (this.state.selectedEntity && this.state.selectedEntity.length) {
              this.gridRefs.current.setSelections(
                this.state.selectedEntity,
                "partner_mapping"
              );
            }
          }
        );

        if (
          nextProps.MappedDataList &&
          nextProps.MappedDataList.length > 0 &&
          !_.isEqual(nextProps.MappedDataList, this.props.MappedDataList)
        ) {
          this.setState(
            {
              selectedEntity: nextProps.MappedDataList
            },
            () => {
              this.gridRefs.current.setSelections(
                this.state.selectedEntity,
                "partner_mapping"
              );
            }
          );
        }
      }

      if (nextProps.MappedDataSaved || nextProps.isDeleted) {
        this.setState(
          {
            modalOpen: false,
            isSaveNextModal: false,
            isActionClicked: false,
            isDeleteAction: false
          },
          () => {
            if (this.state.actionName.toUpperCase() == "BRAND") {
              this.props.actions.getBrandCategoryProductHierarchyAction(
                this.props.PartnerId,
                this.state.partnerid,
                this.state.pagination.page
              );
            }
            if (this.state.actionName.toUpperCase() == "WHOLESALER") {
              this.props.actions.getWholesalorBrandCategoryProductHierarchyAction(
                this.state.partnerid,
                this.state.pagination.page
              );
            }
            this.props.actions.getPartnerMappingAction(this.state.partnerid);
          }
        );
      }

      let error = "";
      if (nextProps.Error) {
        error = nextProps.Error;
      }

      this.setState({
        error: error
      });
    }
  }

  renderMappingConfirmation() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.modalOpen}
        onClose={this.handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!this.state.isDeleteAction
              ? "Are you sure you want to map selected entity with the Partner?"
              : "Are you sure you want to remove selected entity mapping with the Partner?"}
            {this.state.error && this.state.error.trim() !== "" && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code>{this.state.error}</code>
              </FormLabel>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!this.state.isDeleteAction ? (
            <Button
              style={{ backgroundColor: "#248f24" }}
              onClick={this.savePartnerMapping}
              autoFocus
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{ backgroundColor: "#248f24" }}
              onClick={this.removePartnerMapping}
              autoFocus
            >
              Yes
            </Button>
          )}
          <Button
            onClick={this.handleModalClose}
            style={{ backgroundColor: "#999" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderSaveNextConfirmation() {
    const { classes } = this.props;
    const { nextpage } = this.state;
    return (
      <Dialog
        open={this.state.isSaveNextModal}
        onClose={this.handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to save the changes you have made???
            {this.state.error && this.state.error.trim() !== "" && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code>{this.state.error}</code>
              </FormLabel>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#248f24" }}
            onClick={this.performSaveNext}
            autoFocus
          >
            Yes
          </Button>
          <Button
            onClick={() => this.updateGridData(nextpage)}
            style={{ backgroundColor: "#999" }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.renderMappingConfirmation()}
        {this.renderSaveNextConfirmation()}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div
                style={{
                  marginTop: "12px"
                }}
              >
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  View, Edit or Assign{" "}
                  {this.state.actionName == "brand" ? "Brands" : "Wholesalers"}{" "}
                  to {this.state.partnername}
                </p>
                {this.props.PartnerRole == "AD" ? <div
                  className={cx("product-page-back-btn", "pagination")}
                  onClick={() => this.backToPartnerAction()}
                >
                  Back
                </div> : ""}
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <AgGrid
                  ref={this.gridRefs}
                  columnDefs={this.state.colDefs}
                  rowSelection="multiple"
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.list}
                  AgGridActionButton={PartnerPartnersMappingAction}
                  floatingFilter={true}
                  context={this.state.context}
                  groupSuppressAutoColumn={true}
                  suppressRowClickSelection={true}
                  groupSelectsChildren={true}
                  pagination={this.state.pagination}
                  domLayout={this.state.domLayout}
                  groupDefaultExpanded={this.state.groupDefaultExpanded}
                  getNodeChildDetails={this.state.hierarchicalView}
                  isServerPagination={true}
                  search={this.searchText}
                  update={this.saveNextPagePopup}
                  isHierarchyView={true}
                  isInitialSet={this.state.isInitialSet}
                />
                <div style={{ float: "right" }}>
                  <Button
                    color="info"
                    style={{ backgroundColor: "#248f24" }}
                    onClick={this.openModal}
                  >
                    Assign Brands & Products
                  </Button>
                </div>

                {this.state.loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.state.loading} />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  saveNextPagePopup(page) {
    let seletedentities = this.getSelectedNodes();
    if (seletedentities && seletedentities.length) {
      this.setState(
        {
          nextpage: page,
          isSaveNextModal: true
        },
        () => {
          this.gridRefs.current.setSelections(
            seletedentities,
            "partner_mapping"
          );
        }
      );
    } else {
      this.updateGridData(page);
    }
  }

  performSaveNext() {
    let seletedentities = this.getSelectedNodes("CAT");
    this.setState(
      {
        ...this.state,
        pagination: {
          ...this.state.pagination,
          page: this.state.nextpage
        }
      },
      () => {
        let action = "";
        this.props.actions.savePartnerMappingAction(
          seletedentities,
          this.state.partnerid,
          this.props.UserId.pk,
          action
        );
      }
    );
  }

  updateGridData(page) {
    if (this.state.actionName.toUpperCase() == "BRAND") {
      this.props.actions.getBrandCategoryProductHierarchyAction(
        this.props.PartnerId,
        this.state.partnerid,
        page,
        this.state.searchtext
      );
    }
    if (this.state.actionName.toUpperCase() == "WHOLESALER") {
      this.props.actions.getWholesalorBrandCategoryProductHierarchyAction(
        this.state.partnerid,
        true,
        page,
        this.state.searchtext
      );
    }
    // this.props.actions.getPartners(page, this.state.searchtext)
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        if (this.state.actionName.toUpperCase() == "BRAND") {
          this.props.actions.getBrandCategoryProductHierarchyAction(
            this.props.PartnerId,
            this.state.partnerid,
            page,
            this.state.searchtext
          );
        }
        if (this.state.actionName.toUpperCase() == "WHOLESALER") {
          this.props.actions.getWholesalorBrandCategoryProductHierarchyAction(
            this.state.partnerid,
            true,
            page,
            this.state.searchtext
          );
        }
      }
    );
  }

  backToPartnerAction = () => {
    this.setState(
      {
        list: [],
        partnerid: "",
        partnername: "",
        actionName: ""
      },
      () => {
        this.props.actions.onNavigateTo(routesNames.CREATEWEBSITE);
      }
    );
  };

  handleModalClose() {
    let data = this.getSelectedNodes();
    this.setState({
      modalOpen: false,
      isDeleteAction: false,
      error: ""
    }, () => {
      this.gridRefs.current.setSelections(
        data,
        "partner_mapping"
      );
    });
  }

  savePartnerMapping() {
    let data = [];
    let action = this.state.actionName;
    let selectedData = [];
    if (this.state.isActionClicked) {
      selectedData = this.getSelectedEntities(this.state.actionBtnData, data);
      data = selectedData.filter(x => x.role != 'CAT')
      action = "None";
    } else {
      selectedData = this.getSelectedNodes();
      data = selectedData.filter(x => x.role != 'CAT')
    }
    this.props.actions.savePartnerMappingAction(
      data,
      this.state.partnerid,
      this.props.UserId.pk,
      action
    );
  }

  removePartnerMapping() {
    let data = this.state.actionBtnData;
    let action = this.state.actionName;
    if (data.role == undefined) {
      data.role = "PR";
    }
    this.props.actions.removePartnerMappingAction(
      data,
      this.state.partnerid,
      this.props.UserId.pk,
      action
    );
  }

  onSelectionChanged = e => {
    // const { selectedEntity } = this.state;
    // let selectedRows = this.gridRefs.current.agGridApi.getSelectedNodes();
    // // const componentThis = this;
    // // var length = this.state.selectedEntity.length;
    // // var i = 0;
    // // for (i = 0; i < length; i++) {
    // //     componentThis.state.selectedEntity.pop();
    // // }
    // let selectednodes = []

    // selectednodes = this.getAllNestedNodeWithParent(selectedRows, selectednodes)
    // console.log(selectednodes)
    // if (selectednodes && selectednodes.length && !_.isEqual(selectednodes, selectedEntity)) {

    //     if (selectednodes.length < selectedEntity.length) {
    //         let entites = _.clone(selectedEntity)
    //         let unselectednodes = entites.filter(x => !selectednodes.some(y => x.id === y.id && x.role === y.role))
    //         console.log(selectedEntity, selectednodes)
    //         console.log('unselectednodes', unselectednodes)
    //         this.setState({
    //             isDeleteAction: true,
    //             openModal: true,
    //             isActionClicked: true,
    //             actionBtnData: unselectednodes,
    //             unselectednodes: unselectednodes
    //         })
    //     } else {
    //         this.setState({
    //             isInitialSet: false,
    //             selectedEntity: selectednodes
    //         }, () => {
    //             this.gridRefs.current.setSelections(this.state.selectedEntity, 'partner_mapping');
    //         })
    //     }
    // }
    if (this.state.isInitialSet) {
      this.setState({
        isInitialSet: false
      });
    }
  };

  getSelectedNodes(filter = null) {
    let selectedRows = this.gridRefs.current.agGridApi.getSelectedNodes();
    let selectednodes = [];
    selectednodes = this.getAllNestedNodeWithParent(
      selectedRows,
      selectednodes
    );
    if (filter) {
      selectednodes = selectednodes.filter(x => x.role !== filter);
    }
    return selectednodes;
  }

  getAllNestedNodeWithParent(nodes, selectedEntity) {
    const componentThis = this;
    if (_.isArray(nodes)) {
      nodes.forEach(function (selectedRow, index) {
        if (
          selectedRow.data.role == "BR" ||
          selectedRow.data.role == "WS" ||
          selectedRow.data.role == "PR" ||
          selectedRow.data.role == "CAT" ||
          selectedRow.data.role == undefined
        ) {
          let data = {
            id: "",
            role: "PR"
          };
          data.id = selectedRow.data.id;
          data.role = selectedRow.data.role ? selectedRow.data.role : "PR";
          if (
            selectedEntity.findIndex(
              x => x.id == data.id && x.role == data.role
            ) == -1
          ) {
            selectedEntity.push(data);
          }
        }
        if (selectedRow.parent) {
          componentThis.getAllNestedNodeWithParent(
            selectedRow.parent,
            selectedEntity
          );
        }
      });
    }
    if (_.isObject(nodes)) {
      if (
        nodes.data &&
        (nodes.data.role == "BR" ||
          nodes.data.role == "CAT" ||
          nodes.data.role == "WS" ||
          nodes.data.role == "PR" ||
          nodes.data.role == undefined)
      ) {
        let data = {
          id: "",
          role: "PR"
        };
        data.id = nodes.data.id;
        data.role = nodes.data.role ? nodes.data.role : "PR";
        if (
          selectedEntity.findIndex(
            x => x.id == data.id && x.role == data.role
          ) == -1
        ) {
          selectedEntity.push(data);
        }
      }
      if (nodes.parent) {
        componentThis.getAllNestedNodeWithParent(nodes.parent, selectedEntity);
      }
    }

    return selectedEntity;
  }

  openModal() {
    let data = this.getSelectedNodes();
    this.setState({
      modalOpen: true
    }, () => {
      this.gridRefs.current.setSelections(
        data,
        "partner_mapping"
      );
    });
  }

  mapActionBtnFunction(data) {
    this.setState({
      modalOpen: true,
      isActionClicked: true,
      actionBtnData: data
    });
  }

  removeMapping(data) {
    this.setState({
      modalOpen: true,
      isDeleteAction: true,
      isActionClicked: true,
      actionBtnData: data
    });
  }

  getSelectedEntities(nodes, selectedEntity) {
    const componentThis = this;
    if (_.isArray(nodes)) {
      nodes.forEach(function (selectedRow, index) {
        if (
          selectedRow.role == "BR" ||
          selectedRow.role == "WS" ||
          selectedRow.role == "PR"
        ) {
          let data = {
            id: "",
            role: "PR"
          };
          data.id = selectedRow.id;
          data.role = selectedRow.role ? selectedRow.role : "PR";
          if (
            selectedEntity.findIndex(
              x => x.id == data.id && x.role == data.role
            ) == -1
          ) {
            selectedEntity.push(data);
          }
        }
        if (selectedRow.children) {
          componentThis.getSelectedEntities(
            selectedRow.children,
            selectedEntity
          );
        }
      });
    }
    if (_.isObject(nodes)) {
      if (
        nodes &&
        (nodes.role == "BR" || nodes.role == "WS" || nodes.role == "PR")
      ) {
        let data = {
          id: "",
          role: "PR"
        };
        data.id = nodes.id;
        data.role = nodes.role ? nodes.role : "PR";
        if (
          selectedEntity.findIndex(
            x => x.id == data.id && x.role == data.role
          ) == -1
        ) {
          selectedEntity.push(data);
        }
      }
      if (nodes.children) {
        componentThis.getSelectedEntities(nodes.children, selectedEntity);
      }
    }

    return selectedEntity;
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  isDeleted: state.partnerMappingReducer.partner_mapping.isDeleted,
  Error: state.partnerMappingReducer.partner_mapping.error,
  UserId: state.authState.user,
  brandList: state.brandState.map_wholesaler_brand,
  isMapSaved: state.industriesReducer.isMapSaved,
  BrandList: state.categoryReducer.brandCategoryProductHierarchy.data,
  BrandPagination:
    state.categoryReducer.brandCategoryProductHierarchy.pagination,
  BrandListLoading: state.categoryReducer.brandCategoryProductHierarchy.loading,
  PartnerId: state.authState.user.partner_id,
  WholesalerList:
    state.categoryReducer.wholesalorBrandCategoryProductHierarchy.data,
  WholesalerPagination:
    state.categoryReducer.wholesalorBrandCategoryProductHierarchy.pagination,
  WholesalerListLoading:
    state.categoryReducer.wholesalorBrandCategoryProductHierarchy.loading,
  savedTemplatesLoading: state.batchProductCreationReducer.loading,
  product_template_list:
    state.batchProductCreationReducer.product_template_list,
  batchSaved: state.batchProductCreationReducer.saved,
  batchSaving: state.batchProductCreationReducer.saving,
  MappedDataList: state.partnerMappingReducer.partner_mapping.data,
  MappedDataSaved: state.partnerMappingReducer.partner_mapping.isSaved,
  PartnerRole: state.authState.user.role,
});

PartnerPartnersMappingComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  selectedIndexes: PropTypes.array.isRequired
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PartnerPartnersMappingComponent);
