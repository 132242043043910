import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  saved: false,
  saving: false,
  error: null,
  loading: false,
  product_template_list: []
};

const batchProductCreationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.BATCH_PRODUCT_CREATE_REQUEST:
      return {
        ...state,
        saved: false,
        saving: true,
        error: null
        // loading: true
      };
    case actionTypes.BATCH_PRODUCT_CREATE_FAILURE:
      return {
        ...state,
        saved: false,
        saving: false,
        error: action.payload
        // loading: true
      };
    case actionTypes.BATCH_PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        saved: true,
        saving: false,
        error: null
        // loading: true
      };
    case actionTypes.GET_BATCH_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        saved: false,
        saving: false
      };
    case actionTypes.GET_BATCH_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        saved: false,
        saving: false,
        error: action.payload,
        loading: false
      };
    case actionTypes.GET_BATCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        saved: false,
        saving: false,
        error: null,
        loading: false,
        product_template_list: action.payload
      };

    default:
      return state;
  }
};

export default batchProductCreationReducer;
