import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@material-ui/core/FormLabel";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Slide from "@material-ui/core/Slide";

import withAuthorization from "../../Session/withAuthorization";
import { rolesNames } from "../../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import StockLocationActionBtn from "./components/StockLocationActionBtn.jsx";

import { validationUtils } from "../../../utils";
import AgGrid from "../../../components/AgGrid/AgGrid.jsx";

// @material-ui/core components/Dialog
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import StockLocationAction from "../../../components/AgGrid/StockLocationAction";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
const initialState = {
  name: "",
  parent_id: "",
  is_deleted: false
};

class StockLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      selectedId: null,
      isAddNew: false,
      isEdit: false,
      stockLocation: [],
      addStockLocation: {
        ...initialState
      },
      required: {
        name: ""
      },
      openDeleteDialog: false,
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 380,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Parent Location",
          field: "location.name",
          width: 380,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 220,
          filter: false
        }
      ],
      context: { componentParent: this },

      frameworkComponents: {
        StockLocationActionBtn: StockLocationActionBtn
      }
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.List && !_.isEqual(this.props.List, nextProps.List)) {
        this.setState({
          stockLocation: nextProps.List,
          pagination: nextProps.pagination
        });
      }
      if (
        nextProps.LocationData &&
        !_.isEqual(this.props.LocationData, nextProps.LocationData)
      ) {
        this.setState(() => ({
          addStockLocation: nextProps.LocationData
        }));
      }

      if (nextProps.IsDeleted) {
        this.setState(
          {
            openDeleteDialog: false
          },
          () => {
            this.props.actions.getStockLocationDataAction(
              this.props.PartnerId,
              0,
              this.state.pagination.page
            );
          }
        );
      }

      if (nextProps.isSaved) {
        this.setState(
          {
            isAddNew: false,
            isEdit: false
          },
          () => {
            this.props.actions.getStockLocationDataAction(
              this.props.PartnerId,
              0,
              this.state.pagination.page
            );
          }
        );
      }
    }
  }

  componentDidMount() {
    this.props.actions.getStockLocationDataAction(
      this.props.PartnerId,
      0,
      this.state.pagination.page
    );
  }

  render() {
    return this.state.isAddNew
      ? this.renderStockLocation()
      : this.renderStockLocationTable();
  }

  renderStockLocation = () => {
    const { classes } = this.props;
    const { name, parent_id } = this.state.addStockLocation;
    const { required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <br />

                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    style={{ marginTop: "14px" }}
                  >
                    <InputLabel
                      htmlFor="condition-select"
                      className={classes.selectLabel}
                      style={{ fontSize: "14px" }}
                    >
                      Location
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      name="parent_id"
                      value={parent_id}
                      onChange={this.handleCondition}
                      inputProps={{
                        name: "parent_id",
                        id: "Products-select"
                      }}
                      style={{ fontSize: "14px" }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Locations
                      </MenuItem>
                      {_.map(
                        this.state.stockLocation,
                        ({ id, name }, index) => {
                          return (
                            <MenuItem
                              key={index}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                            >
                              {name}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                  <br />

                  <br />
                  {this.props.Error && this.props.Error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button color="info" onClick={this.saveStockLocation}>
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.handleCancel}>Cancel</Button>
                  {this.props.Loading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loading} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  handleCondition = event => {
    this.setState({
      ...this.state,
      addStockLocation: {
        ...this.state.addStockLocation,
        parent_id: event.target.value
      }
    });
  };

  handelIsAddNew = () => {
    this.props.actions.getStockLocationDataAction(this.props.PartnerId, 0);
    this.setState({
      isAddNew: true,
      addStockLocation: {
        ...initialState,
        owner_id: this.props.PartnerId
      }
    });
  };

  handleCancel = () => {
    this.setState(
      {
        isAddNew: false,
        addStockLocation: {
          ...initialState,
          owner_id: this.props.PartnerId
        }
      },
      () => {
        this.props.actions.getStockLocationDataAction(
          this.props.PartnerId,
          0,
          this.state.pagination.page
        );
      }
    );
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      addStockLocation: {
        ...this.state.addStockLocation,
        [key]: event.target.value
      }
    });
  };

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addStockLocation.hasOwnProperty("name") ||
      (this.state.addStockLocation.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  saveStockLocation = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addStockLocation;
      this.props.actions.saveStockLocationAction(this.props.UserId, savingData);
    }
  };

  renderStockLocationTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.deleteDilog()}
        {/* <GridItem xs={12}>
                    <Grid
                        container
                        spacing={16}
                        alignItems={'flex-end'}
                        direction={'row'}
                        justify={'flex-end'}>
                        <Button disabled={this.props.Loading} color="success" onClick={this.handelIsAddNew} className={classes.marginRight}>
                            <AddIcon className={classes.icons} />   New
                        </Button>
                    </Grid>
                </GridItem> */}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Stock Locations
                </p>

                {/* <p>
                                    <Button
                                        disabled={this.props.Loading}
                                        color="success"
                                        size='sm'
                                        onClick={this.handelIsAddNew}
                                        className={classes.marginRight + " grid-header-button"}>
                                        <AddIcon className="grid-header-button-icon" />   New
                                    </Button>
                                </p> */}
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <AgGrid
                  rowData={this.state.stockLocation}
                  columnDefs={this.state.columnDefs}
                  handelEdit={this.handelEdit}
                  AgGridActionButton={StockLocationAction}
                  update={this.updateGridData}
                  isServerPagination={true}
                  pagination={this.state.pagination}
                  loading={this.props.Loading}
                  search={this.searchText}
                  new={this.handelIsAddNew}
                  isAddBtn={true}
                />
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getStockLocationDataAction(
      this.props.PartnerId,
      0,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getStockLocationDataAction(
          this.props.PartnerId,
          0,
          page,
          this.state.searchtext
        );
      }
    );
  }

  handelEdit = id => {
    this.setState({
      isAddNew: true,
      isEdit: true
    });
    this.props.actions.getStockLocationDataAction(this.props.PartnerId, 0);
    this.props.actions.getStockLocationByIdAction(id);
  };

  deleteLocation = id => {
    this.setState({
      openDeleteDialog: true,
      selectedId: id
    });
  };

  deleteLocationAction = () => {
    const { selectedId } = this.state;
    this.props.actions.deleteStockLocationAction(selectedId);
  };

  deleteDilog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        disableBackdropClick={true}
        onClose={() => this.closeDeleteModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              Are you sure you want to delete?{" "}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.deleteLocationAction()}
              >
                Delete
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.closeDeleteModal()}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  };

  closeDeleteModal = () => {
    this.setState({
      openDeleteDialog: false
    });
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.inventoryManagementReducer.stock_location.list,
  Loading: state.inventoryManagementReducer.stock_location.loading,
  Error: state.inventoryManagementReducer.stock_location.error,
  UserId: state.authState.user.pk,
  PartnerId: state.authState.user.partner_id,
  LocationData: state.inventoryManagementReducer.stockLocationById.data,
  IsDeleted: state.inventoryManagementReducer.stock_location.is_deleted,
  isSaved: state.inventoryManagementReducer.stock_location.isSaved,
  pagination: state.inventoryManagementReducer.stock_location.pagination
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(StockLocations);
