import React, { Component } from "react";
import cx from "classnames";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import "../../style/app.css";

class CustomPaginationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: "0",
      total_page: "0",
      total_records: "0",
      next: null,
      prev: null,
      first: null,
      last: null
    };
    this.updatePage = this.updatePage.bind(this);
  }

  componentDidMount() {
    this.setState({
      current_page: this.props.currentpage,
      total_page: this.props.totalpage,
      next: this.props.next_page,
      prev: this.props.prev_page,
      first: this.props.currentpage > 1 ? 1 : null,
      last:
        this.props.currentpage < this.props.totalpage
          ? this.props.totalpage
          : null
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      current_page: nextProps.currentpage,
      total_page: nextProps.totalpage,
      next: nextProps.nextpage,
      prev: nextProps.prevpage,
      first: nextProps.currentpage > 1 ? 1 : null,
      last:
        nextProps.currentpage < nextProps.totalpage ? nextProps.totalpage : null
    });
  }

  render() {
    const { current_page, total_page, next, prev, first, last } = this.state;
    return (
      <div className="aggrid-paging-panel pagination-panel">
        <div className="aggrid-paging-page-summary-panel">
          <span
            className={cx("aggrid-paging-button", {
              "aggrid-disabled": first == null
            })}
            onClick={() => this.updatePage(0)}
          >
            <FirstPageIcon className="aggrid-icon" />
          </span>
          <span
            className={cx("aggrid-paging-button", {
              "aggrid-disabled": prev == null
            })}
            onClick={() => this.updatePage(1)}
          >
            <NavigateBeforeIcon className="aggrid-icon" />
          </span>
          &nbsp; Page &nbsp;
          <span>{current_page}</span>
          &nbsp; of &nbsp;
          <span>{total_page}</span>
          <span
            className={cx("aggrid-paging-button", {
              "aggrid-disabled": next == null
            })}
            onClick={() => this.updatePage(2)}
          >
            <NavigateNextIcon className="aggrid-icon" />
          </span>
          <span
            className={cx("aggrid-paging-button", {
              "aggrid-disabled": last == null
            })}
            onClick={() => this.updatePage(3)}
          >
            <LastPageIcon className="aggrid-icon" />
          </span>
        </div>
      </div>
    );
  }

  updatePage(params) {
    let { current_page, total_page, next, last, first, prev } = this.state;
    switch (params) {
      case 0:
        if (first) {
          current_page = 1;
        } else {
          return;
        }
        break;
      case 1:
        if (prev) {
          current_page = current_page - 1;
        } else {
          return;
        }
        break;
      case 2:
        if (next) {
          current_page = current_page + 1;
        } else {
          return;
        }
        break;
      case 3:
        if (last) {
          current_page = total_page;
        } else {
          return;
        }
        current_page = total_page;
        break;
      default:
        current_page = current_page;
    }
    this.props.update(current_page);
  }
}
export default CustomPaginationPanel;
