import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import FormLabel from "@material-ui/core/FormLabel";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";

import withAuthorization from "../../Session/withAuthorization";
import { rolesNames } from "../../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import StockActionBtn from "./components/StockActionBtn.jsx";
import AgGrid from "../../../components/AgGrid/AgGrid.jsx";
import StockManagementAction from "../../../components/AgGrid/StockManagementAction";
function dateFormatter(params) {
  if (params.value) {
    return moment(params.value).format("MM/DD/YYYY");
  } else {
    return "";
  }
}

const initialState = {
  id: "",
  name: "",
  status: "",
  location_id: "",
  location_dest_id: "",
  note: "",
  picking_type: "",
  picker: ""
};

class StockManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      isAddNew: false,
      addStockDetail: {
        ...initialState
      },
      addProductRow: {
        product_name: "",
        product_id: null,
        product_qty: 1,
        product_uom: ""
      },
      addStockItem: [],
      required: {
        name: "",
        description: ""
      },
      isStock: true,
      stockStatus: [
        "new",
        "ready",
        "unavailable",
        "inprogress",
        "done",
        "backordered",
        "canceled"
      ],
      stockData: null,
      stockLocation: [],
      productList: null,
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 200,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Source Location",
          field: "source_location.name",
          width: 200,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Destination Location",
          field: "dest_location.name",
          width: 200,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Created Date",
          field: "created_at",
          width: 200,
          resizable: true,
          sortable: true,
          valueFormatter: dateFormatter
        },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 180,
          filter: false
        }
      ],
      context: { componentParent: this },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      frameworkComponents: {
        StockActionBtn: StockActionBtn
      },
      domLayout: "autoHeight",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      },
      userList: []
    };
    this.handelEdit = this.handelEdit.bind(this);
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.Stocklocation &&
        nextProps.Stocklocation.length > 0 &&
        !_.isEqual(this.props.Stocklocation, nextProps.Stocklocation)
      ) {
        this.setState(() => ({
          stockLocation: nextProps.Stocklocation
        }));
      }

      if (nextProps.List && !_.isEqual(this.props.List, nextProps.List)) {
        this.setState(() => ({
          stockData: nextProps.List,
          // isAddNew: false,
          // isEdit: false,
          addStockDetail: {
            ...initialState
          },
          addStockItem: [],
          pagination: nextProps.pagination
        }));
      }

      if (nextProps.isSaved) {
        this.setState(
          {
            isAddNew: false,
            isEdit: false
          },
          () => {
            this.props.actions.stockDataAction(
              this.props.PartnerId,
              this.state.pagination.page
            );
          }
        );
      }

      if (
        nextProps.assignProductList &&
        nextProps.assignProductList.length > 0
      ) {
        const productListData = nextProps.assignProductList;
        this.setState({
          productList: productListData
        });
      }

      if (
        this.state.isEdit &&
        nextProps.StockData &&
        !_.isEqual(this.props.StockData, nextProps.StockData)
      ) {
        let data = nextProps.StockData;
        data.picker =
          nextProps.StockData &&
          nextProps.StockData.picker &&
          nextProps.StockData.picker.email
            ? nextProps.StockData.picker.email
            : "";
        this.setState({
          addStockDetail: data,
          addStockItem: nextProps.StockData.items
        });
      }
      if (nextProps && nextProps.userList && nextProps.userList.length > 0) {
        this.setState({
          userList: nextProps.userList
        });
      }
    }
  }

  componentDidMount() {
    this.props.actions.getProductAssignmentList(this.props.PartnerId);
    this.props.actions.getStockLocationDataAction(this.props.PartnerId, 1);
    this.props.actions.stockDataAction(
      this.props.PartnerId,
      this.state.pagination.page
    );
  }

  render() {
    return this.state.isAddNew
      ? this.renderStockForm()
      : this.renderClassTable();
  }

  // handleCondition = event => {

  //     this.setState({
  //         ...this.state,
  //         addStockLocation: {
  //             ...this.state.addStockLocation,
  //             parent_id: event.target.value
  //         }
  //     })

  // };
  renderStockForm = () => {
    const { classes } = this.props;
    const {
      name,
      status,
      location_id,
      location_dest_id,
      note,
      picking_type,
      picker,
      delivery_address
    } = this.state.addStockDetail;
    const { required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <GridContainer>
                    <GridItem lg={6} md={6} sm={12} xs={12}>
                      <CustomInput
                        success={required.name === "success"}
                        error={required.name === "error"}
                        labelText="Name *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: name,
                          onChange: this.handleTextChange("name", "")
                        }}
                        id={"name_"}
                      />
                    </GridItem>
                    <GridItem lg={6} md={6} sm={12} xs={12}>
                      <CustomInput
                        success={required.status === "success"}
                        error={required.status === "error"}
                        labelText="Note *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: note,
                          onChange: this.handleTextChange("note", "")
                        }}
                        id={"note_"}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={6} md={6} sm={12} xs={12}>
                      {/* <CustomInput
                                                success={required.status === "success"}
                                                error={required.status === "error"}
                                                labelText="Picker Agent email-id*"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "text",
                                                    value: picker,
                                                    onChange: this.handleTextChange('picker', "")
                                                }}
                                                id={"picker_"}
                                            />


                                            <br /> */}
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ marginTop: "14px" }}
                      >
                        <InputLabel
                          htmlFor="condition-select"
                          className={classes.selectLabel}
                          style={{ fontSize: "14px" }}
                        >
                          "Picker Agent email-id*"
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          name="Picker"
                          value={picker}
                          onChange={this.handleCondition("picker")}
                          inputProps={{
                            name: "Picker",
                            id: "Products-select"
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          />
                          {_.map(
                            this.state.userList,
                            ({ id, email }, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={email}
                                >
                                  {email}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem lg={6} md={6} sm={12} xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ marginTop: "14px" }}
                      >
                        <InputLabel
                          htmlFor="condition-select"
                          className={classes.selectLabel}
                          style={{ fontSize: "14px" }}
                        >
                          Source Location
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          name="location_id"
                          value={location_id}
                          onChange={this.handleCondition("location_id")}
                          inputProps={{
                            name: "location_id"
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Source Location
                          </MenuItem>
                          {_.map(
                            this.state.stockLocation,
                            ({ id, name }, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={id}
                                >
                                  {name}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={6} md={6} sm={12} xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ marginTop: "14px" }}
                      >
                        <InputLabel
                          htmlFor="condition-select"
                          className={classes.selectLabel}
                          style={{ fontSize: "14px" }}
                        >
                          Destination Location
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          name="location_dest_id"
                          value={location_dest_id}
                          onChange={this.handleCondition("location_dest_id")}
                          inputProps={{
                            name: "location_dest_id"
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Destination Location
                          </MenuItem>
                          {_.map(
                            this.state.stockLocation,
                            ({ id, name }, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={id}
                                >
                                  {name}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                      <br />
                    </GridItem>
                    <GridItem lg={6} md={6} sm={12} xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ marginTop: "14px" }}
                      >
                        <InputLabel
                          htmlFor="condition-select"
                          className={classes.selectLabel}
                          style={{ fontSize: "14px" }}
                        >
                          Status
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          name="status"
                          value={status}
                          onChange={this.handleCondition("status")}
                          inputProps={{
                            name: "status"
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Status
                          </MenuItem>
                          {_.map(this.state.stockStatus, (model, index) => {
                            return (
                              <MenuItem
                                key={index}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={model}
                              >
                                {model}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  Add Product
                  <Table
                    tableHead={["Product", "Quantity"]}
                    tableData={this.getProductTableData()}
                    tableShopping
                    customHeadCellClasses={[
                      classes.description,
                      classes.description,
                      classes.description,
                      classes.right,
                      classes.right,
                      classes.right
                    ]}
                    customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                    customCellClasses={[
                      classes.customFont,
                      classes.tdName,
                      classes.customFont,
                      classes.tdNumber,
                      classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                      classes.tdNumber
                    ]}
                    customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                  />
                  <br />
                  {this.props.ListError && this.props.ListError.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}
                  <br />
                  <Button color="info" onClick={this.addStock}>
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.handleCancel}>Cancel</Button>
                  {this.props.Saving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Saving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  addStock = () => {
    let { addStockDetail, addStockItem } = this.state;
    addStockDetail.items = addStockItem;
    this.setState({
      addStockDetail: addStockDetail
    });
    console.log(this.state.addStockDetail, this.state.addStockItem);
    this.props.actions.addStockDataAction(
      this.state.addStockDetail,
      this.props.UserId
    );
  };

  handleCancel = () => {
    this.setState({
      isAddNew: false,
      isEdit: false,
      addStockDetail: {
        ...initialState
      },
      addStockItem: []
    });
  };

  handleTextChange = (key, type) => event => {
    // var validData = "success";
    // if (!validationUtils.validateData(type, event.target.value)) {
    //     validData = "error";
    // }
    // var reqData = this.state.required;
    // reqData[key] = validData;
    // this.setState({ 'required': reqData });

    this.setState({
      addStockDetail: {
        ...this.state.addStockDetail,
        [key]: event.target.value
      }
    });
  };

  handleCondition = key => event => {
    this.setState({
      ...this.state,
      addStockDetail: {
        ...this.state.addStockDetail,
        [key]: event.target.value
      }
    });
  };

  renderClassTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Stock
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <AgGrid
                  rowData={this.state.stockData}
                  columnDefs={this.state.columnDefs}
                  handelEdit={this.handelEdit}
                  AgGridActionButton={StockManagementAction}
                  is_Stock={true}
                  update={this.updateGridData}
                  isServerPagination={true}
                  pagination={this.state.pagination}
                  search={this.searchText}
                  new={this.handelIsAddNew}
                  isAddBtn={true}
                />
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.stockDataAction(
      this.props.PartnerId,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.stockDataAction(
          this.props.PartnerId,
          page,
          this.state.searchtext
        );
      }
    );
  }

  handelIsAddNew = () => {
    this.props.actions.getUsersListAction(this.props.User.partner_id);
    let { addStockItem, addProductRow, addStockDetail } = this.state;
    addStockItem.push(addProductRow);
    this.setState({
      ...this.state,
      isAddNew: true,
      addStockItem: addStockItem,
      addStockDetail: {
        ...this.state.addStockDetail,
        partner_id: this.props.PartnerId
      }
    });
  };

  handelEdit(stockid) {
    this.props.actions.getStockByIdAction(stockid);
    this.props.actions.getUsersListAction(this.props.User.partner_id);
    this.setState({
      isEdit: true,
      isAddNew: true
    });
  }

  getProductTableData = () => {
    const { attributeGroups } = [];
    const { addStockItem } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(
      addStockItem,
      ({ id, product_id, product_qty, product_uom }, index) => {
        returnData.push([
          <span>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
              style={{ marginTop: "14px" }}
            >
              <InputLabel
                htmlFor="condition-select"
                className={classes.selectLabel}
                style={{ fontSize: "14px" }}
              >
                Product
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                name="product_id"
                value={product_id}
                onChange={this.changeRowData(index, "product_id")}
                inputProps={{
                  name: "product_id"
                }}
                style={{ fontSize: "14px" }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Product
                </MenuItem>
                {_.map(
                  this.state.productList,
                  ({ id, name, is_used, is_consumable }, index) => {
                    if (is_consumable !== 1) {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={id}
                        >
                          {name}
                        </MenuItem>
                      );
                    }
                  }
                )}
              </Select>
            </FormControl>
          </span>,
          <span>
            <CustomInput
              labelText="Quantity *"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "text",
                value: product_qty,
                onChange: this.changeRowData(index, "product_qty")
              }}
              id={"product_qty_"}
            />
          </span>,
          <span>
            <ReactTooltip id="editRow">
              <span>New Row</span>
            </ReactTooltip>

            <Button
              color="success"
              data-tip
              data-for="editInd"
              className={classes.actionButton}
              onClick={() => this.handelNewRow(index)}
            >
              <AddIcon className={classes.icons} />
            </Button>

            <ReactTooltip id="deleteRow">
              <span>Delete Row</span>
            </ReactTooltip>
            <Button
              color="danger"
              data-tip
              data-for="deleteInd"
              className={classes.marginRight}
              style={{ padding: "5px" }}
              onClick={() => this.removeRow(index)}
            >
              <ClearIcon
                className={classes.icons}
                style={{ marginRight: "0px" }}
              />
            </Button>
          </span>
        ]);
      }
    );
    return returnData;
  };

  changeRowData = (index, key) => event => {
    let { addStockItem } = this.state;
    let row = _.clone(addStockItem[index]);
    row[key] = event.target.value;
    addStockItem[index] = row;
    this.setState({
      ...this.state,
      addStockItem: addStockItem
    });
  };

  handelNewRow = () => {
    let { addStockItem, addProductRow } = this.state;
    addStockItem.push(addProductRow);
    this.setState({
      addStockItem: addStockItem
    });
  };

  removeRow = index => {
    let { addStockItem } = this.state;
    addStockItem.splice(index, 1);
    this.setState({
      addStockItem: addStockItem
    });
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.inventoryManagementReducer.stock.list,
  Stocklocation: state.inventoryManagementReducer.stock_location.list,
  Loading: state.inventoryManagementReducer.stock.loading,
  Error: state.inventoryManagementReducer.stock.error,
  UserId: state.authState.user.user,
  PartnerId: state.authState.user.partner_id,
  assignProductList: state.productsAssignmentsState.list,
  StockData: state.inventoryManagementReducer.stockById.data,
  User: state.authState.user,
  userList: state.usersReducer.list,
  pagination: state.inventoryManagementReducer.stock.pagination,
  isSaved: state.inventoryManagementReducer.stock.isSaved
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(StockManagement);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
