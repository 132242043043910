import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import { rolesNames } from "../../constants";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { AgGridReact } from "ag-grid-react";
import withStyles from "@material-ui/core/styles/withStyles";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import Button from "../../components/CustomButtons/Button";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import CreateProduct from "../NewComponents/ProductAdd";

import Slide from "@material-ui/core/Slide";

import _ from "lodash";

let agGridViewApi = null;
let agGridEditApi = null;

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const INITIAL_STATE = {
  formData: [],
  selectedTemp: [],
  productTemp: [],
  productModal: false,
  selectedClassIndexes: [],
  ViewMode: true,
  ViewData: [],
  SavingCompleted: false,
  columnDefsRetailer: [
    {
      headerName: "ID",
      field: "ptid",
      hide: true
    },
    {
      headerName: "Name",
      field: "name",
      resizable: true,
      width: 250,
      checkboxSelection: true
    },
    {
      headerName: "Description",
      field: "description",
      resizable: true,
      width: 400
    },
    {
      headerName: "Template Name",
      field: "product_template.name",
      resizable: true,
      width: 300
    }
  ],
  columnDefsBrand: [
    {
      headerName: "ID",
      field: "ptid",
      hide: true
    },
    {
      headerName: "Name",
      field: "name",
      resizable: true,
      width: 450,
      checkboxSelection: true
    },
    {
      headerName: "Description",
      field: "description",
      resizable: true,
      width: 450
    }
  ],
  defaultColDef: {
    sortable: true,
    resizable: true,
    filter: true
  },
  rowData: null,
  domLayout: "autoHeight",
  context: { componentParent: this },
  frameworkComponents: {
    CreateProduct: CreateProduct
  },
  statusBar: {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left"
      },
      {
        statusPanel: "agTotalRowCountComponent",
        align: "center"
      },
      { statusPanel: "agFilteredRowCountComponent" },
      { statusPanel: "agSelectedRowCountComponent" },
      { statusPanel: "agAggregationComponent" }
    ]
  },
  autoGroupColumnDefViewMode: {
    headerName: "Brand / Segments / Class",
    field: "",
    rowDrag: false,
    cellRendererParams: { checkbox: false },
    headerCheckboxSelection: false,
    width: 350,
    suppressSizeToFit: true
  },
  autoGroupColumnDef: {
    headerName: "Brand / Segments / Class",
    field: "",
    rowDrag: false,
    cellRendererParams: { checkbox: true },
    headerCheckboxSelection: true,
    width: 350,
    suppressSizeToFit: true
  },
  rowSelection: "multiple",
  sideBar: {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressValues: true,
          suppressPivotMode: true
        }
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel"
      }
    ]
  }
};

class selectProductTemplatesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };
    // this.firstData = this.firstData.bind(this);
  }

  onGridViewReady = params => {
    agGridViewApi = _.clone(params.api, true);
  };

  onGridEditReady = params => {
    agGridEditApi = _.clone(params.api, true);
  };

  handelEdit = () => event => {
    let themeIndexCounter = this.state.themeIndex + 1;
    if (this.state.themeIndex > 6) {
      themeIndexCounter = 0;
    }
    this.setState({ themeIndex: themeIndexCounter });
  };

  setSelectedIndexes = products => {
    if (this.state.ViewMode) {
      return;
    }

    this.setState(
      {
        selectedClassIndexes: []
      },
      () => {
        let selectedIndexArray = _.clone(this.state.selectedClassIndexes, true);

        for (let i = 0; i < products.length; i++) {
          if (selectedIndexArray.indexOf(products[i].ptid) > -1) {
            // node.setSelected(true);
          } else {
            selectedIndexArray.push(products[i].ptid);
          }
        }

        // this.setState({ selectedClassIndexes: selectedIndexArray });

        this.setState(
          {
            selectedClassIndexes: selectedIndexArray
          },
          () => {
            this.onGridRowSelected();
          }
        );
      }
    );
  };

  setSelectedRows = productUnitList => {
    let selectedIndexArray = [];

    for (let i = 0; i < productUnitList.length; i++) {
      if (productUnitList[i].offers) {
        for (let j = 0; j < productUnitList[i].offers.length; j++) {
          if (productUnitList[i].offers[j].offer.id === this.props.offer_id) {
            selectedIndexArray.push(productUnitList[i].id);
            break;
          }
        }
      }
    }
    this.setState(
      {
        selectedClassIndexes: selectedIndexArray
      },
      () => {
        this.RowSelected();
      }
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (nextProps.SaveSuccess) {
          this.props.onClose();
        }
        if (
          nextProps.productTemplates &&
          nextProps.productTemplates.products &&
          nextProps.productTemplates.products.length > 0
        ) {
          this.setState(
            {
              rowData: nextProps.productTemplates.products
            },
            () => {
              if (this.state.ViewData && this.state.ViewData.length > 0) {
                this.setSelectedIndexes(this.state.ViewData);
                if (agGridEditApi) agGridEditApi.sizeColumnsToFit();
              }
            }
          );
        }
        if (
          nextProps.brandProductTemplates &&
          nextProps.brandProductTemplates.products &&
          nextProps.brandProductTemplates.products.length > 0
        ) {
          this.setState(
            {
              ViewData: nextProps.brandProductTemplates.products
            },
            () => {
              if (agGridViewApi) agGridViewApi.sizeColumnsToFit();
            }
          );
        }
        if (
          nextProps.saveProductTemplates &&
          nextProps.saveProductTemplates.saved
        ) {
          this.handleMode();
        }

        if (
          this.props.authUser.role === "BR" &&
          nextProps.brand_product_template &&
          nextProps.brand_product_template.length > 0
        ) {
          let data = [];
          for (let i = 0; i < nextProps.brand_product_template.length; i++) {
            let res = {
              id: "",
              name: "",
              description: ""
            };
            res.id = nextProps.brand_product_template[i].product_template.id;
            res.name =
              nextProps.brand_product_template[i].product_template.name;
            res.description =
              nextProps.brand_product_template[i].product_template.description;
            data.push(res);
          }
          this.setState(
            {
              rowData: data
            },
            () => {
              if (agGridViewApi) agGridViewApi.sizeColumnsToFit();
            }
          );
        }
        // if (nextProps.saveProductTemplates && nextProps.saveProductTemplates.saved) {
        //     this.handleMode();
        // }

        if (
          this.props.authUser.role === "RE" &&
          nextProps.assignProductList &&
          nextProps.assignProductList.length
        ) {
          this.setState(
            {
              rowData: nextProps.assignProductList
            },
            () => {
              this.setSelectedRows(this.state.rowData);
              if (agGridEditApi) {
                agGridEditApi.sizeColumnsToFit();
              }
            }
          );
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.role === "RE") {
      this.props.actions.getProductAssignmentList(this.props.PartnerId);
    } else if (this.props.role === "BR") {
      let data = {};
      data.api_key = this.props.api_key;
      this.props.actions.getAssignedProductTemplates(this.props.api_key);
    }
  }

  onRowSelected(event) {
    if (event.node.data) {
      let selectedIndexArray = _.clone(this.state.selectedClassIndexes, true);
      if (event.node.isSelected()) {
        if (selectedIndexArray.indexOf(event.node.data.id) > -1) {
          // node.setSelected(true);
        } else {
          selectedIndexArray.push(event.node.data.id);
        }
      } else {
        if (selectedIndexArray.indexOf(event.node.data.id) > -1) {
          selectedIndexArray.pop(event.node.data.id);
        }
      }

      this.setState(
        {
          selectedClassIndexes: selectedIndexArray
        },
        () => {
          this.onGridRowSelected();
        }
      );
    }
  }

  RowSelected() {
    const componentThis = this;
    agGridEditApi.forEachNode(function(node) {
      if (node.data) {
        if (
          componentThis.state.selectedClassIndexes.indexOf(node.data.id) > -1
        ) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      }
    });
  }

  onGridRowSelected() {
    const componentThis = this;
    agGridEditApi.forEachNode(function(node) {
      if (node.data) {
        if (
          componentThis.state.selectedClassIndexes.indexOf(node.data.id) > -1
        ) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      }
    });
  }
  // firstData() {
  //     agGridEditApi.forEachNode(function (node) {
  //         node.setSelected(true);
  //     });
  // }

  onSelectionChanged() {}

  saveOfferProducts = () => {
    if (this.props.offer_id) {
      let { selectedClassIndexes } = this.state;
      this.props.actions.mapProductWithOffer(
        this.props.UserId,
        this.props.offer_id,
        selectedClassIndexes
      );
    }
  };

  handleMode = () => {
    this.setState(
      {
        ViewMode: !this.state.ViewMode
      },
      () => {
        this.props.onClose();
      }
    );
  };

  renderEditMode() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Offers
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <div>
                  <div
                    id="myGrid"
                    style={{
                      width: "100%"
                    }}
                    className="ag-theme-material"
                  >
                    <AgGridReact
                      gridOptions={this.gridOptions}
                      columnDefs={
                        this.props.authUser.role === "RE"
                          ? this.state.columnDefsRetailer
                          : this.state.columnDefsBrand
                      }
                      defaultColDef={this.state.defaultColDef}
                      rowData={
                        this.props.authUser.role === "RE"
                          ? this.props.assignProductList
                          : this.state.rowData
                      }
                      groupSelectsChildren={true}
                      onSelectionChanged={this.onSelectionChanged.bind(this)}
                      onRowSelected={this.onRowSelected.bind(this)}
                      pagination={true}
                      paginationPageSize={50}
                      rowMultiSelectWithClick={true}
                      icons={this.state.icons}
                      rowSelection={this.state.rowSelection}
                      onGridReady={this.onGridEditReady}
                      domLayout={this.state.domLayout}
                    />
                  </div>
                </div>
                <div>
                  <Button color="info" onClick={() => this.saveOfferProducts()}>
                    Save Changes
                  </Button>
                  <Button onClick={() => this.handleMode()}>Cancel</Button>
                </div>
                <Loader loading={this.props.Loading} text="Loading.." />
                <Loader loading={this.props.Saving} text="Saving.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    return <div>{this.renderEditMode()}</div>;
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  productTemplates: state.brandState.map_Retailer_brand_producttemplate,
  brandProductTemplates:
    state.brandState.map_Retailer_brand_producttemplate_Selected,
  brand_product_template: state.brandState.selectedProdcutTemplates.products,
  saveProductTemplates: state.brandState.selectedProdcutTemplates,
  assignProductList: state.productsAssignmentsState.list,
  Loading: state.productsAssignmentsState.loading,
  ListError: state.productsAssignmentsState.error,
  Saving: state.offersReducer.map_offer.saving,
  SaveSuccess: state.offersReducer.map_offer.saved,
  SaveError: state.offersReducer.map_offer.error,
  UserId: state.authState.user.pk,
  PartnerId: state.authState.user.partner_id,
  User: state.authState.user.user,
  authUser: state.authState.user
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(selectProductTemplatesComponent);
