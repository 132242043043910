import * as productCMSService from "../../services/productCMSService";
import * as actionTypes from "./actionTypes";

export const createProductCMS = (
  name,
  template,
  parent,
  id,
  industry
) => async dispatch => {
  dispatch(createProductCMSRequest());
  try {
    const success = await productCMSService.createProductCMS(
      name,
      template,
      parent,
      id,
      industry
    );
    dispatch(createProductCMSSuccess(success));
  } catch (error) {
    console.log(error.message);
    dispatch(
      createProductCMSFailure(
        "An unexpected error has occured, please try again later"
      )
    );
  }
};

export const getProductCMSList = role => async dispatch => {
  dispatch(getProductCMSRequest());
  try {
    var success = await productCMSService.getProductCMSList(role);
    dispatch(getProductCMSSuccess(success));
  } catch (error) {
    dispatch(getProductCMSFailure(error.message));
  }
};

export const resetCreateState = () => async dispatch => {
  dispatch(resetCreateCMSState());
};

const resetCreateCMSState = () => {
  return {
    type: actionTypes.CREATE_PRODUCT_TYPE_RESETSTATE
  };
};

const createProductCMSRequest = () => {
  return {
    type: actionTypes.CREATE_PRODUCT_TYPE_REQUEST
  };
};

const createProductCMSSuccess = data => {
  return {
    type: actionTypes.CREATE_PRODUCT_TYPE_SUCCESS,
    payload: data
  };
};

const createProductCMSFailure = error => {
  return {
    type: actionTypes.CREATE_PRODUCT_TYPE_FAILURE,
    payload: error
  };
};

const getProductCMSRequest = () => {
  return {
    type: actionTypes.GET_PRODUCT_TYPE_REQUEST
  };
};

const getProductCMSSuccess = data => {
  return {
    type: actionTypes.GET_PRODUCT_TYPE_SUCCESS,
    payload: data
  };
};

const getProductCMSFailure = error => {
  return {
    type: actionTypes.GET_PRODUCT_TYPE_FAILURE,
    payload: error
  };
};
