import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import Slide from "@material-ui/core/Slide";

import moment from "moment";

import OrderAction from "./OrderAction";
import * as routesNames from "../../constants/routes";

import AgGrid from "../../components/AgGrid/AgGrid.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function dateFormatter(params) {
  if (params.value) {
    return moment(params.value).format("MM/DD/YYYY h:mm a");
  } else {
    return "";
  }
}

class RetailerOrder extends React.Component {
  constructor(props) {
    super(props);
    this.edit = this.edit.bind(this);
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      Orders: [],
      shipping_type: [
        {
          key: 1,
          name: "Pickup"
        },
        {
          key: 2,
          name: "Delivery"
        }
      ],
      order_state: [
        {
          key: 0,
          name: "INITIATED"
        },
        {
          key: 1,
          name: "PLACED"
        },
        {
          key: 2,
          name: "SHIPPING REQUESTED"
        },
        {
          key: 3,
          name: "SHIPPING APPROVED"
        },
        {
          key: 4,
          name: "PACKED"
        },
        {
          key: 5,
          name: "DISPATCHED"
        },
        {
          key: 6,
          name: "DELIVERED"
        },
        {
          key: 7,
          name: "CANCELLED"
        },
        {
          key: 8,
          name: "RETURNED"
        }
      ],
      columnDefs: [
        {
          headerName: "",
          field: "value",
          cellRenderer: "gridActionComponent",
          colId: "params",
          width: 120,
          resizable: false,
          sortable: false
        },
        {
          headerName: "Order Number",
          field: "order_number",
          width: 180,
          resizable: true
        },
        {
          headerName: "Order Date",
          field: "created_at",
          sortable: true,
          resizable: true,
          valueFormatter: dateFormatter,
          width: 170
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          resizable: true,
          width: 150
        },
        {
          headerName: "Shipping Type",
          field: "shipping_type",
          sortable: true,
          resizable: true,
          width: 150
        },
        {
          headerName: "Pickup Address",
          field: "pickup_address.address",
          sortable: true,
          resizable: true,
          width: 150
        },
        {
          headerName: "Delivery Address",
          field: "delivery_address.address",
          width: 200,
          resizable: true
        },
        {
          headerName: "Customer Name",
          field: "delivery_address.name",
          width: 200,
          resizable: true
        },
        {
          headerName: "Phone",
          field: "delivery_address.phone",
          sortable: true,
          resizable: true,
          width: 150
        },
        {
          headerName: "Email",
          field: "user.email",
          width: 200,
          resizable: true
        }
      ],
      context: { componentParent: this },
      frameworkComponents: {
        gridActionComponent: OrderAction
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      domLayout: "autoHeight",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
  }

  edit(data) {
    console.log(data);
    this.props.actions.onNavigateTo(
      routesNames.ORDERDETAILS + "?order_id=" + data.id
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && !_.isEqual(this.props, nextProps)) {
      if (
        nextProps.List &&
        nextProps.List.length &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const Orders = nextProps.List;
        Orders.forEach(element => {
          if(isNaN(element.status)){
            let index = this.state.order_state.findIndex(
              x => x.key == element.status
            );
            element.status = this && this.state && this.state.order_state && this.state.order_state[index].name;
          }
          if(isNaN(element.shipping_type)){
            let shipping_index = this.state.shipping_type.findIndex(
              x => x.key === element.shipping_type
            );
            element.shipping_type = this.state.shipping_type[shipping_index].name;
          }
        });
        this.setState(() => ({
          Orders: Orders,
          pagination: nextProps.pagination
        }));
      }
    }
  }

  componentDidMount() {
    console.log(this.props.UserId);
    this.props.actions.getOrderList(
      this.props.User.id,
      this.state.pagination.page
    );
  }

  render() {
    return this.renderOrderTable();
  }

  renderOrderTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Orders
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.Orders}
                columnDefs={this.state.columnDefs}
                handelView={this.edit}
                loading={this.props.Loading}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                search={this.searchText}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
  updateGridData(page) {
    this.props.actions.getOrderList(
      this.props.User.id,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getOrderList(
          this.props.User.id,
          page,
          this.state.searchtext
        );
      }
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.orderReducer.get.order,
  Loading: state.orderReducer.get.loading,
  ListError: state.orderReducer.get.loaderror,
  UserId: state.authState.user.user,
  User: state.authState.user,
  pagination: state.orderReducer.get.pagination
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RetailerOrder);
