export const EVENT_TYPE_REQUEST = 'EVENT_TYPE_REQUEST'
export const EVENT_TYPE_FAILURE = 'EVENT_TYPE_FAILURE'
export const EVENT_TYPE_SUCCESS = 'EVENT_TYPE_SUCCESS'

export const DELETE_EVENT_TYPE_REQUEST = 'DELETE_EVENT_TYPE_REQUEST'
export const DELETE_EVENT_TYPE_FAILURE = 'DELETE_EVENT_TYPE_FAILURE'
export const DELETE_EVENT_TYPE_SUCCESS = 'DELETE_EVENT_TYPE_SUCCESS'

export const SAVE_EVENT_TYPE_REQUEST = 'SAVE_EVENT_TYPE_REQUEST'
export const SAVE_EVENT_TYPE_FAILURE = 'SAVE_EVENT_TYPE_FAILURE'
export const SAVE_EVENT_TYPE_SUCCESS = 'SAVE_EVENT_TYPE_SUCCESS'

export const EVENT_REQUEST = 'EVENT_REQUEST'
export const EVENT_FAILURE = 'EVENT_FAILURE'
export const EVENT_SUCCESS = 'EVENT_SUCCESS'

export const SAVE_EVENT_REQUEST = 'SAVE_EVENT_REQUEST'
export const SAVE_EVENT_FAILURE = 'SAVE_EVENT_FAILURE'
export const SAVE_EVENT_SUCCESS = 'SAVE_EVENT_SUCCESS'

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST'
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'

export const EVENT_PARTNER_MAPPING_REQUEST = 'EVENT_PARTNER_MAPPING_REQUEST'
export const EVENT_PARTNER_MAPPING_FAILURE = 'EVENT_PARTNER_MAPPING_FAILURE'
export const EVENT_PARTNER_MAPPING_SUCCESS = 'EVENT_PARTNER_MAPPING_SUCCESS'

export const SAVE_EVENT_PARTNER_MAPPING_REQUEST = 'SAVE_EVENT_PARTNER_MAPPING_REQUEST'
export const SAVE_EVENT_PARTNER_MAPPING_FAILURE = 'SAVE_EVENT_PARTNER_MAPPING_FAILURE'
export const SAVE_EVENT_PARTNER_MAPPING_SUCCESS = 'SAVE_EVENT_PARTNER_MAPPING_SUCCESS'
