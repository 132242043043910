export const GET_PRODUCT_ATTRIBUTES_REQUEST = "get_product_attributes_request";
export const GET_PRODUCT_ATTRIBUTES_FAILURE = "get_product_attributes_failure";
export const GET_PRODUCT_ATTRIBUTES_SUCCESS = "get_product_attributes_success";

export const SAVE_PRODUCT_ATTRIBUTES_REQUEST =
  "save_product_attributes_request";
export const SAVE_PRODUCT_ATTRIBUTES_FAILURE =
  "save_product_attributes_failure";
export const SAVE_PRODUCT_ATTRIBUTES_SUCCESS =
  "save_product_attributes_success";

export const DELETE_PRODUCT_ATTRIBUTES_REQUEST =
  "delete_product_attributes_request";
export const DELETE_PRODUCT_ATTRIBUTES_FAILURE =
  "delete_product_attributes_failure";
export const DELETE_PRODUCT_ATTRIBUTES_SUCCESS =
  "delete_product_attributes_success";

export const AFTER_SAVE_PRODUCT_ATTRIBUTES_SUCCESS =
  "after_save_product_attributes_success";

export const GET_ATTRIBUTE_BY_PRODUCT_ID_REQUEST =
  "get_attribute_by_product_id_request";
export const GET_ATTRIBUTE_BY_PRODUCT_ID_SUCCESS =
  "get_attribute_by_product_id_success";
export const GET_ATTRIBUTE_BY_PRODUCT_ID_FAILURE =
  "get_attribute_by_product_id_failure";
