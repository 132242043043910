import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@material-ui/core/FormLabel";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { validationUtils } from "../../utils";

// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";

import UserGroupMenuMappingComponent from "./components/UserGroupMenuMappingComponent";

import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import UserGroupAction from "../../components/AgGrid/UserGroupAction";
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class UserGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      isAddNew: false,
      addUserGroupData: {
        id: null,
        name: "",
        description: "",
        is_active: false
      },
      required: {
        name: "",
        description: ""
      },
      userGroupData: [],
      error: null,
      openDeleteDialog: false,
      userGroupId: null,
      isMenuMapping: false,
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 350,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Description",
          field: "description",
          width: 450,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        // {
        //     headerName: "Status",
        //     field: "is_active",
        //     width: 270,
        //     resizable: true,
        //     sortable: true,
        //     filter: "agTextColumnFilter",
        //     cellRenderer: function(params) {
        //
        //         if(params.data.is_active){
        //             return 'Active'
        //         }else{
        //             return 'InActive'
        //         }
        //     },
        // },
        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ]
    };
    this.saveMapping = this.saveMapping.bind(this);
    this.closeMappingComponent = this.closeMappingComponent.bind(this);
    this.userGroupMenuMapping = this.userGroupMenuMapping.bind(this);
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length &&
        !_.isEqual(this.props.List, nextProps.List)
      ) {
        const userGroupData = nextProps.List;
        this.setState(() => ({
          userGroupData: userGroupData,
          pagination: nextProps.pagination
        }));
      }

      if (nextProps.Error && nextProps.Error.trim() !== "") {
        this.setState(() => ({
          error: nextProps.Error,
          isAddNew: true
        }));
      }
      if (nextProps.isSaved) {
        this.setState(
          {
            isAddNew: false,
            isEdit: true
          },
          () => {
            this.props.actions.getUserGroupList(
              this.props.User.partner_id,
              this.state.pagination.page
            );
          }
        );
      }
      if (nextProps.isDeleted) {
        this.setState(() => ({
          openDeleteDialog: false
        }));
      }
    }
  }

  componentDidMount() {
    this.props.actions.getUserGroupList(
      this.props.User.partner_id,
      this.state.pagination.page
    );
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      addUserGroupData: {
        name: "",
        description: "",
        is_active: false,
        is_Deleted: false
      }
    });
  };
  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      addUserGroupData: {
        ...this.state.addUserGroupData,
        [key]: event.target.value
      }
    });
  };
  handleToggleStatus = curObj => event => {
    this.setState({
      addUserGroupData: {
        ...this.state.addUserGroupData,
        is_active: event.target.checked
      }
    });
  };

  saveUserGroup = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addUserGroupData;
      this.setState(
        {
          addUserGroupData: {
            ...this.state.addUserGroupData,
            partner_id: this.props.User.partner_id
          }
        },
        () => {
          this.props.actions.createUserGroup(
            this.props.UserId,
            this.state.addUserGroupData
          );
        }
      );
      // this.props.actions.createUserGroup(this.props.UserId, savingData);
    }
  };

  handelEdit = id => {
    const { isEdit, userGroupData } = this.state;
    const data = userGroupData;
    let index = data.findIndex(a => a.id == id);
    if (data[index]) {
      this.setState({
        addUserGroupData: {
          name: data[index].name,
          description: data[index].description,
          index: index,
          id: data[index].id,
          is_active: data[index].is_active,
          is_Deleted: false
        },
        isAddNew: true,
        isEdit: true
      });
    }
  };

  deleteDialog = (state, Id, Index) => {
    this.setState({
      openDeleteDialog: state,
      userGroupId: Id
    });
  };

  deleteSegment = Id => {
    this.props.actions.deleteClass(this.props.UserId, this.state.userGroupId);
  };

  render() {
    return this.state.isAddNew
      ? this.renderUserGroup()
      : this.renderUserGroupTable();
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addUserGroupData.hasOwnProperty("name") ||
      (this.state.addUserGroupData.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addUserGroupData.hasOwnProperty("description") ||
      (this.state.addUserGroupData.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  deleteDilog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        disableBackdropClick={true}
        onClose={() => this.openModalDialog("openDialog", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              Are you sure you want to delete?{" "}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.deleteSegment()}
              >
                Delete
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.deleteDialog(false, "", "")}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  };

  renderUserGroup = () => {
    const { classes } = this.props;
    const { name, description, is_active } = this.state.addUserGroupData;
    const { required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              {/* <CardIcon color="info">
                                <Icon style={{ fontSize: '36px' }}>account_circle</Icon>
                            </CardIcon> */}
              <p
                className={
                  classes.cardIconTitle + " wholesalor-hierarchy-title"
                }
              >
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <CustomInput
                    success={required.description === "success"}
                    error={required.description === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: this.handleTextChange("description", "")
                    }}
                    id={"description_"}
                  />
                  <br />
                  {/* <FormControlLabel
                                        control={
                                            <Checkbox
                                                tabIndex={-1}
                                                checked={is_active}
                                                onClick={this.handleToggleStatus(is_active)}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot
                                                }}

                                            />
                                        }

                                        classes={{ label: classes.label }}
                                        label="Active"
                                    /> */}
                  {this.props.Error && this.props.Error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button color="info" onClick={this.saveUserGroup}>
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {this.props.isSaving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.isSaving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderUserGroupTable = () => {
    const { classes } = this.props;
    return (
      <div>
        {this.state.isMenuMapping ? (
          <UserGroupMenuMappingComponent
            usergroup={this.state.userGroupId}
            close={this.closeMappingComponent}
            save={this.saveMapping}
          />
        ) : (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <div>
                    <p className={classes.cardIconTitle + " grid-header-title"}>
                      User Groups{" "}
                    </p>
                    {/* <p>
                                            <Button
                                                disabled={this.props.Loading}
                                                color="success"
                                                size='sm'
                                                onClick={this.handelIsAddNew}
                                                className={classes.marginRight + " grid-header-button"}>
                                                <AddIcon className="grid-header-button-icon" />   New
                                            </Button>
                                        </p> */}
                  </div>
                </CardHeader>
                <CardBody>
                  <AgGrid
                    rowData={this.state.userGroupData}
                    columnDefs={this.state.columnDefs}
                    loading={this.props.Loading}
                    handelEdit={this.handelEdit}
                    handelMap={this.userGroupMenuMapping}
                    AgGridActionButton={UserGroupAction}
                    is_map={true}
                    update={this.updateGridData}
                    isServerPagination={true}
                    pagination={this.state.pagination}
                    search={this.searchText}
                    new={this.handelIsAddNew}
                    isAddBtn={true}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  };

  updateGridData(page) {
    this.props.actions.getUserGroupList(
      this.props.User.partner_id,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getUserGroupList(
          this.props.User.partner_id,
          page,
          this.state.searchtext
        );
      }
    );
  }

  userGroupMenuMapping(userGroupId) {
    this.setState({
      isMenuMapping: true,
      userGroupId: userGroupId
    });
  }

  closeMappingComponent() {
    this.setState({
      isMenuMapping: false
    });
  }

  saveMapping(data) {
    this.closeMappingComponent();
    this.props.actions.saveUserGroupMenuMappingActions(
      this.props.User.id,
      data
    );
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.userGroupReducer.list,
  Loading: state.userGroupReducer.loading,
  ListError: state.userGroupReducer.error,
  isSaved: state.userGroupReducer.isSaved,
  isDeleted: state.userGroupReducer.isDeleted,
  UserId: state.authState.user.user,
  User: state.authState.user,
  isSaving: state.userGroupReducer.isSaving,
  pagination: state.userGroupReducer.pagination
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.RETAILER ||
  rolesNames.RoleMaster.WHOLESALOR ||
  rolesNames.RoleMaster.BRAND ||
  rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserGroup);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
