import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import { AgGridReact } from "ag-grid-react";

// core components
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

let AggridApi;

let agGridViewApi = null;
let agGridEditApi = null;

const attributeTableRow = {
  id: "",
  name: "",
  description: ""
};
function Transition(props) {
  return <Slide direction="down" {...props} />;
}
class ProductAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAttributeGroup: [],
      data: {},
      classlist: [],
      segmentlist: [],
      attributegrouplist: [],
      attributeRow: {
        ...attributeTableRow
      },
      isNewCategory: false,
      category: {
        name: "",
        attribute_group: []
      },
      isNewAtrributeGroup: false,
      attribute_group: {
        name: "",
        attributes: []
      },
      segmentData: [
        {
          name: "",
          categories: []
        }
      ],
      categoryData: {
        name: "",
        attribute_group: []
      },
      attributeGroupData: {
        name: "",
        attributes: []
      },
      attributeData: [
        {
          name: "",
          value: ""
        }
      ],
      columns: [
        { title: "Name", field: "name" },
        { title: "Value", field: "description" }
      ],
      columnDefs: [
        {
          headerName: "Attribute Group",
          field: "name",
          width: 450,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          cellRenderer: "group"
        },
        {
          headerName: "Attribute",
          field: "attributes.name",
          width: 350,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
          // checkboxSelection: true
        }
      ],
      defaultColDef: {
        width: 240,
        resizable: true
      },
      context: { assetManagerGrid: this },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      // frameworkComponents: {
      //     actionColumns: ActionButtonComponent,
      // },
      autoGroupColumnDefViewMode: {
        headerName: "Folder/file",
        field: "",
        rowDrag: false,
        cellRendererParams: { checkbox: false },
        headerCheckboxSelection: false,
        width: 350,
        suppressSizeToFit: true
      },
      getNodeChildDetails: function(data) {
        if (data && data.attributes && data.attributes.length > 0) {
          let children = data.attributes;
          return {
            group: true,
            children: children,
            expanded: data.open
          };
        } else {
          return null;
        }
      },
      domLayout: "autoHeight",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        this.setState({
          data: nextProps.data
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.props.data
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { attribute_groups } = this.state.data;
    return (
      <Grid>
        <GridContainer>
          <GridItem lg={1} md={1} />
          <GridItem lg={10} md={10} sm={12} xs={12}>
            <form>
              <GridContainer className="grid-container-control">
                <GridItem
                  lg={12}
                  md={12}
                  style={{
                    marginTop: "10px"
                  }}
                >
                  <div
                    id="myGrid"
                    style={{
                      width: "100%",
                      border: "1px solid #e2e2e2"
                    }}
                    className="ag-theme-material"
                  >
                    <AgGridReact
                      gAgGridReactridOptions={this.gridOptions}
                      columnDefs={this.state.columnDefs}
                      defaultColDef={this.state.columnDefs}
                      rowData={attribute_groups}
                      floatingFilter={true}
                      context={this.state.context}
                      groupSelectsChildren={true}
                      autoGroupColumnDef={this.state.autoGroupColumnDefViewMode}
                      pagination={true}
                      paginationPageSize={20}
                      rowMultiSelectWithClick={true}
                      icons={this.state.icons}
                      rowSelection={this.state.rowSelection}
                      onGridReady={this.onGridEditReady}
                      domLayout={this.state.domLayout}
                      rowSelection={"multiple"}
                      getNodeChildDetails={this.state.getNodeChildDetails}
                      // onSelectionChanged={this.onSelectionChanged.bind(this)}
                      groupMultiAutoColumn={true}
                      suppressRowClickSelection={true}
                      enableCellChangeFlash={true}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
          <GridItem lg={1} md={1} />
        </GridContainer>
      </Grid>
    );
  }

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    var length = this.state.selectedAttributeGroup.length;
    var i = 0;
    const componentThis = this;
    for (i = 0; i < length; i++) {
      componentThis.state.selectedAttributeGroup.pop();
    }
    selectedRows.forEach(function(selectedRow, index) {
      componentThis.state.selectedAttributeGroup.push(selectedRow);
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    AggridApi = _.clone(this.gridApi, true);
  };

  onGridEditReady = params => {
    this.gridApi = params.api;
    agGridEditApi = _.clone(params.api, true);
  };

  onRowAdd = newData => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
        const attributes = [...this.state.data.attributes];
        attributes.push(newData);
        this.props.setProductData("attributes", attributes);
      }, 600);
    });
  };

  onRowUpdate = (newData, oldData) => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
        const attributes = [...this.state.data.attributes];
        attributes[attributes.indexOf(oldData)] = newData;
        if (oldData) {
          this.props.setProductData("attributes", attributes);
        }
      }, 600);
    });
  };

  onRowDelete = oldData => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
        const attributes = [...this.state.data.attributes];
        attributes.splice(attributes.indexOf(oldData), 1);
        this.props.setProductData("attributes", attributes);
      }, 600);
    });
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  SegmentList: state.SegmentsReducer.list,
  ClassList: state.classReducer.list,
  UserId: state.authState.user.pk,
  User: state.authState.user.user,
  PartnerId: state.authState.user.partner_id,
  AttributeGroup: state.brandState.attributeGroups.attributeGroups
});

ProductAttributes.propTypes = {
  data: PropTypes.object.isRequired,
  editState: PropTypes.bool.isRequired
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductAttributes);
