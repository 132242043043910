import React from "react";
import PaginationComp from "../../../components/Pagination/Pagination.jsx";
const INITIAL_STATE = {
  start: 0,
  end: 0,
  totalPages: 0,
  currentPage: 0,
  pagesArray: [],
  maxRecord: 1
};
class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  componentDidMount() {
    const { data, dataPerPage } = this.props;
    const pages = Math.ceil(data.length / dataPerPage);
    this.setState(() => ({
      totalPages: pages,
      start: 1,
      maxRecord: dataPerPage
    }));
    this.getPageNoArray(pages, 1, dataPerPage);
  }
  handelPageClick = eve => {
    const txt = eve.target.textContent;
    const _this = this;
    const { start, end, totalPages, maxRecord } = _this.state;
    switch (txt) {
      case "PREV":
        const pEndPrev = start - 1;
        const pStartPrev = pEndPrev - 4;
        _this.setState({
          start: pStartPrev,
          end: pEndPrev,
          currentPage: pStartPrev
        });
        _this.getPageNoArray(totalPages, pStartPrev, maxRecord);
        break;
      case "NEXT":
        const pStartNext = end + 1;
        const pEndNext =
          pStartNext + 4 <= totalPages ? pStartNext + 4 : totalPages;
        _this.setState({
          start: pStartNext,
          end: pEndNext,
          currentPage: pStartNext
        });
        _this.getPageNoArray(totalPages, pStartNext, maxRecord);
        break;
      default:
        _this.setState({ currentPage: parseInt(txt) });
        _this.props.getPageData(
          _this.getDataForCurrentPage(parseInt(txt), maxRecord)
        );
    }
  };
  getPageNoArray = (totalPages, start, maxRecord) => {
    var pageNoArray = [];
    const _this = this;
    const iEnd = totalPages <= start + 4 ? totalPages : start + 4;
    for (var iStart = start; iStart <= iEnd; iStart++) {
      pageNoArray.push({
        text: iStart,
        onClick: _this.handelPageClick
      });
    }
    if (pageNoArray.length > 0) {
      pageNoArray[0].active = true;
    }
    if (start !== 1) {
      pageNoArray.unshift({
        text: "PREV",
        onClick: _this.handelPageClick
      });
    }
    if (totalPages > 5 && iEnd < totalPages) {
      pageNoArray.push({
        text: "NEXT",
        onClick: _this.handelPageClick
      });
    }
    this.setState({
      start: start,
      end: iEnd,
      pagesArray: pageNoArray
    });
    this.props.getPageData(this.getDataForCurrentPage(start, maxRecord));
    return pageNoArray;
  };
  getDataForCurrentPage = (currentPage, maxRecord) => {
    var pagesArray = this.state.pagesArray;
    pagesArray.forEach(function(element) {
      element.text.toString().trim() === currentPage.toString().trim()
        ? (element.active = true)
        : (element.active = false);
    });
    // this.setState({pagesArray:pagesArray});
    const { data } = this.props;
    if (data && data.length > 0) {
    } else {
      return [];
    }
    const iStart = (currentPage - 1) * maxRecord;
    const iEnd =
      data.length < currentPage * maxRecord
        ? data.length
        : currentPage * maxRecord;
    return data.slice(iStart, iEnd);
  };
  render() {
    const { pagesArray } = this.state;
    return (
      <PaginationComp
        styleCustom={{
          justifyContent: "center"
        }}
        pages={pagesArray}
      />
    );
  }
}

export default Pagination;
