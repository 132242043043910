import * as inventoryManagementService from "../../services/inventoryManagementService";
import * as actionTypes from "./actionTypes";

export const getStockLocationDataAction = (
  partnerid,
  optionalParam,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(stockLocationDataRequest());
  try {
    let successnew = await inventoryManagementService.getStockLocationData(
      partnerid,
      optionalParam,
      page,
      search
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(stockLocationDataSuccess(successnew.data));
    } else {
      dispatch(stockLocationDataFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(stockLocationDataFailure(error.message));
  }
};

const stockLocationDataRequest = () => {
  return {
    type: actionTypes.GET_STOCK_LOCATION_REQUEST
  };
};

const stockLocationDataSuccess = data => {
  return {
    type: actionTypes.GET_STOCK_LOCATION_SUCCESS,
    payload: data
  };
};

const stockLocationDataFailure = error => {
  return {
    type: actionTypes.GET_STOCK_LOCATION_FAILURE,
    payload: error
  };
};

export const getStockLocationByIdAction = stocklocationid => async dispatch => {
  dispatch(stockLocationByIdRequest());
  try {
    let successnew = await inventoryManagementService.getStockLocationById(
      stocklocationid
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(stockLocationByIdSuccess(successnew.data));
    } else {
      dispatch(stockLocationByIdFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(stockLocationByIdFailure(error.message));
  }
};

const stockLocationByIdRequest = () => {
  return {
    type: actionTypes.GET_STOCK_LOCATION_BYID_REQUEST
  };
};

const stockLocationByIdSuccess = data => {
  return {
    type: actionTypes.GET_STOCK_LOCATION_BYID_SUCCESS,
    payload: data
  };
};

const stockLocationByIdFailure = error => {
  return {
    type: actionTypes.GET_STOCK_LOCATION_BYID_FAILURE,
    payload: error
  };
};

export const saveStockLocationAction = (userid, data) => async dispatch => {
  dispatch(saveStockLocationRequest());
  try {
    let successnew = await inventoryManagementService.saveStockLocation(
      userid,
      data
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(saveStockLocationSuccess(successnew.data));
    } else {
      dispatch(saveStockLocationFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(saveStockLocationFailure(error.message));
  }
};

const saveStockLocationRequest = () => {
  return {
    type: actionTypes.CREATE_STOCK_LOCATION_REQUEST
  };
};

const saveStockLocationSuccess = data => {
  return {
    type: actionTypes.CREATE_STOCK_LOCATION_SUCCESS,
    payload: data
  };
};

const saveStockLocationFailure = error => {
  return {
    type: actionTypes.CREATE_STOCK_LOCATION_FAILURE,
    payload: error
  };
};

export const addStockDataAction = (data, partnerid) => async dispatch => {
  dispatch(addStockDataRequest());
  try {
    let successnew = await inventoryManagementService.saveStockData(
      data,
      partnerid
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(addStockDataSuccess(successnew.data));
    } else {
      dispatch(addStockDataFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(addStockDataFailure(error.message));
  }
};

const addStockDataRequest = () => {
  return {
    type: actionTypes.ADD_STOCK_REQUEST
  };
};

const addStockDataSuccess = data => {
  return {
    type: actionTypes.ADD_STOCK_SUCCESS,
    payload: data
  };
};

const addStockDataFailure = error => {
  return {
    type: actionTypes.ADD_STOCK_FAILURE,
    payload: error
  };
};

export const stockDataAction = (
  partnerid,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(stockDataRequest());
  try {
    let successnew = await inventoryManagementService.getStockData(
      partnerid,
      page,
      search
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(stockDataSuccess(successnew.data));
    } else {
      dispatch(stockDataFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(stockDataFailure(error.message));
  }
};

const stockDataRequest = () => {
  return {
    type: actionTypes.STOCK_LIST_REQUEST
  };
};

const stockDataSuccess = data => {
  return {
    type: actionTypes.STOCK_LIST_SUCCESS,
    payload: data
  };
};

const stockDataFailure = error => {
  return {
    type: actionTypes.STOCK_LIST_FAILURE,
    payload: error
  };
};

export const getStockByIdAction = stocklocationid => async dispatch => {
  dispatch(stockByIdRequest());
  try {
    let successnew = await inventoryManagementService.getStockById(
      stocklocationid
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(stockByIdSuccess(successnew.data));
    } else {
      dispatch(stockByIdFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(stockByIdFailure(error.message));
  }
};

const stockByIdRequest = () => {
  return {
    type: actionTypes.STOCK_BYID_REQUEST
  };
};

const stockByIdSuccess = data => {
  return {
    type: actionTypes.STOCK_BYID_SUCCESS,
    payload: data
  };
};

const stockByIdFailure = error => {
  return {
    type: actionTypes.STOCK_BYID_FAILURE,
    payload: error
  };
};

export const getProductsNetStockAction = (
  partnerid,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(productsNetStockRequest());
  try {
    let successnew = await inventoryManagementService.getProductsNetStock(
      partnerid,
      page,
      search
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(productsNetStockSuccess(successnew.data));
    } else {
      dispatch(productsNetStockFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(productsNetStockFailure(error.message));
  }
};

const productsNetStockRequest = () => {
  return {
    type: actionTypes.GET_PRODUCTS_NETSTOCK_REQUEST
  };
};

const productsNetStockSuccess = data => {
  return {
    type: actionTypes.GET_PRODUCTS_NETSTOCK_SUCCESS,
    payload: data
  };
};

const productsNetStockFailure = error => {
  return {
    type: actionTypes.GET_PRODUCTS_NETSTOCK_FAILURE,
    payload: error
  };
};

export const getProductStockMoveDataAction = (
  productid,
  page = 0
) => async dispatch => {
  dispatch(productStockMoveDataRequest());
  try {
    let successnew = await inventoryManagementService.getProductStockMoveData(
      productid,
      page
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(productStockMoveDataSuccess(successnew.data));
    } else {
      dispatch(productStockMoveDataFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(productStockMoveDataFailure(error.message));
  }
};

const productStockMoveDataRequest = () => {
  return {
    type: actionTypes.GET_PRODUCTS_STOCKMOVEDATA_REQUEST
  };
};

const productStockMoveDataSuccess = data => {
  return {
    type: actionTypes.GET_PRODUCTS_STOCKMOVEDATA_SUCCESS,
    payload: data
  };
};

const productStockMoveDataFailure = error => {
  return {
    type: actionTypes.GET_PRODUCTS_STOCKMOVEDATA_FAILURE,
    payload: error
  };
};

export const deleteStockLocationAction = partnerid => async dispatch => {
  dispatch(deleteStockLocationRequest());
  try {
    let successnew = await inventoryManagementService.deleteStockLocation(
      partnerid
    );
    if (successnew.status && successnew.status === "1") {
      dispatch(deleteStockLocationSuccess(successnew.data));
    } else {
      dispatch(deleteStockLocationFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(deleteStockLocationFailure(error.message));
  }
};

const deleteStockLocationRequest = () => {
  return {
    type: actionTypes.DELETE_STOCK_LOCATION_REQUEST
  };
};

const deleteStockLocationSuccess = data => {
  return {
    type: actionTypes.DELETE_STOCK_LOCATION_SUCCESS,
    payload: data
  };
};

const deleteStockLocationFailure = error => {
  return {
    type: actionTypes.DELETE_STOCK_LOCATION_FAILURE,
    payload: error
  };
};
