import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import AssetManagementComponent from "./assetManagementComponent.jsx";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";
import "../../style/app.css";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      file: null,
      openAssetModal: false,
      imagePreviewUrl: this.props.imgUrl
        ? this.props.imgUrl
        : this.props.avatar
        ? defaultAvatar
        : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getSelectedImage = this.getSelectedImage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.imgUrl) {
      this.convertFileToBase64Url(nextProps.imgUrl);
    } else {
      this.setState({
        imagePreviewUrl: nextProps.avatar ? defaultAvatar : defaultImage,
        file: null
      });
    }
  }

  componentDidMount() {
    if (this.props && this.props.imgUrl) {
      this.convertFileToBase64Url(this.props.imgUrl);
    } else {
      this.setState({
        imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
        file: null
      });
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    if (
      file &&
      !file.name.match(
        /\.(jpg|jpeg|png|gif|bmp|webp|tiff|tif|jpe|jif|jfif|jfi|svg|ico|)$/
      )
    ) {
      this.setState({ error: "Invalid format" });
    } else if (file && file.size && file.size > 10485760) {
      this.setState({ error: "File Size is greater than 10MB" });
    } else if (file) {
      // let reader = new FileReader();
      // reader.onloadend = () => {
      //   this.setState({
      //     file: file,
      //     imagePreviewUrl: reader.result,
      //     error: null
      //   });
      // };
      // reader.readAsDataURL(file);
      this.props.onChange(file);
    }
  }

  convertFileToBase64Url(file) {
    if (file instanceof Blob) {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
          error: null
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({
        file: file,
        imagePreviewUrl: file,
        error: null
      });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleAssetClick() {
    this.setState({
      openAssetModal: true
    });
  }
  closeModal() {
    this.setState({
      openAssetModal: false
    });
  }
  handleRemove() {
    this.props.onimageRemove();
  }
  handleErrorRemove() {
    this.setState({
      error: null
    });
  }
  handleConfirmRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
    // this.props.onimageRemove();
  }
  getSelectedImage(data) {
    this.setState(
      {
        openAssetModal: false,
        file: data,
        imagePreviewUrl:
          data &&
          data[0] &&
          data[0].document_object &&
          data[0].document_object.secure_url
            ? data[0].document_object.secure_url
            : ""
      },
      () => {
        this.props.onChange(data);
      }
    );
  }

  render() {
    var { avatar, hideAssetBtn, disabled, disableText } = this.props;
    return (
      <div
        className="fileinput text-center"
        style={{
          padding: "10px",
          marginBottom: "0px"
        }}
      >
        <div
          className={"thumbnail" + (avatar ? " img-circle" : "")}
          style={{
            boxShadow:
              "0 0px 0px 0px rgba(0, 0, 0, 0.42), 0 0px 0px 0px rgba(0, 0, 0, 0.12), 0 0px 0px 0px rgba(0, 0, 0, 0.2)",
            height: "120px",
            width: "140px",
            position: "relative",
            marginBottom: "0px",
            border: "1px solid #8080805e"
          }}
        >
          <img className="image" src={this.state.imagePreviewUrl} alt="..." />
          {this.state.file === null ? (
            <div
              className={
                "image-overlay overlay-action-btn" +
                (hideAssetBtn ? " overlay-width" : "")
              }
            >
              <Tooltip title="Browse Local">
                <AddIcon
                  className="action-icon local-upload-icon"
                  onClick={() => this.handleClick()}
                />
              </Tooltip>
              {hideAssetBtn ? (
                ""
              ) : (
                <Tooltip title="Browse From Asset">
                  <AddIcon
                    className="action-icon asset-upload-icon"
                    onClick={() => this.handleAssetClick()}
                  />
                </Tooltip>
              )}
            </div>
          ) : (
            <div
              className={
                "image-overlay" +
                (disabled ? " access-denied" : " close-btn-overlay")
              }
            >
              {disabled === true ? (
                <span
                  style={{
                    fontWeight: "bold"
                  }}
                >
                  {disableText}
                </span>
              ) : (
                <Tooltip title="Remove">
                  <ClearIcon
                    className="action-icon close-btn"
                    onClick={() => this.handleRemove()}
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
        {this.state.openAssetModal ? (
          <AssetManagementComponent
            open={this.state.openAssetModal}
            close={this.closeModal}
            onSelection={this.getSelectedImage}
          />
        ) : (
          <input
            type="file"
            onChange={this.handleImageChange}
            ref="fileInput"
            accept="image/*"
          />
        )}
        <br />
        <br />
        {this.state.error && this.state.error.trim() !== "" && (
          <div className="error-display">
            <div>{this.state.error}</div>
            <ClearIcon
              className="close-btn"
              onClick={() => this.handleErrorRemove()}
            />
          </div>
        )}
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool
};

export default ImageUpload;
