// import axios from 'axios';
import axios from "../utils/axios.jsx";
export const getBusinessType = async (id, page, search) => {
  try {
    let url = "/api/businesstype/getall/?page=" + page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBusinessTypeById = async id => {
  try {
    const response = await axios.get("/api/businesstype-by-id/get/?id=" + id);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveBusinessType = async (userId, Savedata, partnerid) => {
  try {
    let data = new FormData();
    data.append("partnerid", partnerid);
    data.append("userid", userId);
    data.append("file", Savedata.image);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("/api/businesstype/create/", data, {
      "content-type": "multipart/form-data"
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteBusinessType = async (userId, BusinessType) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("businessType", BusinessType);

    const response = await axios.post("/api/businesstype/delete/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const mapBusinessIndustries = async (userId, mapData) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(mapData));

    const response = await axios.post(
      "/api/businesstype/industries/mapping/",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEconomicSectorsIndustries = async id => {
  try {
    const response = await axios.get(
      "/api/economic-sector/industry/get/?id=" + id
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIndustriesMetaData = async (ids, partner_type) => {
  try {
    let idArrayString = JSON.stringify(ids);
    let fetchUrl = "/api/cms-v2/industry-metadata/list/?1=1";

    if (idArrayString) {
      fetchUrl += "&industry_id=" + idArrayString;
    }

    if (partner_type) {
      fetchUrl += "&partner_type=" + partner_type;
    }

    const response = await axios.get(fetchUrl);
    return response.data;
  } catch (error) {
    throw error;
  }
};
