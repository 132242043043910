import {
  cardTitle,
  successColor,
  dangerColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  "@media (min-width: 768px)": {
    width: "750px"
  },
  "@media (min-width: 992px)": {
    width: "970px"
  },
  "@media (min-width: 1200px)": {
    width: "1170px"
  },
  "&:before,&:after": {
    display: "table",
    content: '" "'
  },
  "&:after": {
    clear: "both"
  }
};
const regularFormsStyle = {
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    fontSize: "14px",
    color: "#999999"
  },
  staticFormGroup: {
    marginLeft: "0",
    marginRight: "0",
    paddingBottom: "10px",
    margin: "8px 0 0 0",
    position: "relative",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    }
  },
  staticFormControl: {
    marginBottom: "0",
    paddingTop: "8px",
    paddingBottom: "8px",
    minHeight: "34px"
  },
  inputAdornment: {
    marginRight: "8px",
    position: "relative"
  },
  inputAdornmentIconSuccess: {
    color: successColor[0] + "!important"
  },
  inputAdornmentIconError: {
    color: dangerColor[0] + "!important"
  },
  box: {
    position: "relative",
    "&:before,&:after": {
      content: "",
      position: "absolute",
      top: "0",
      right: "0",
      borderColor: "transparent",
      borderStyle: "solid"
    },
    "&:before": {
      content: "",
      borderWidth: "1.5em",
      borderRightColor: "#ccc",
      borderTopColor: "#ccc"
    },
    "&:after": {
      content: "",
      borderRadius: "0.4em",
      borderWidth: "1.35em",
      borderRightColor: "#0c0",
      borderTopColor: "#0c0"
    }
  }
};
const textAlign = {
  textAlign: "center"
};
export default {
  regularFormsStyle,
  container,
  textAlign
};
