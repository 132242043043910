export const CREATE_TAGS_REQUEST = "create_tags_request";
export const CREATE_TAGS_FAILURE = "create_tags_failure";
export const CREATE_TAGS_SUCCESS = "create_tags_success";

export const GET_TAGS_REQUEST = "get_tags_request";
export const GET_TAGS_FAILURE = "get_tags_failure";
export const GET_TAGS_SUCCESS = "get_tags_success";

export const DELETE_TAGS_REQUEST = "delete_tags_request";
export const DELETE_TAGS_FAILURE = "delete_tags_failure";
export const DELETE_TAGS_SUCCESS = "delete_tags_success";

export const AFTER_SAVE_SUCCESS = "after_save_successl";
