import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../redux/actions';
import GridContainer from "../../component/layout/Grid/GridContainer";
import GridItem from "../../component/layout/Grid/GridItem";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

class SSOValidate extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.reset_passwordstatus) {
        this.setState({
          isResetPasswordSuccessful: true,
          errorMessage: ""
        });
      }
      if (nextProps.reset_passworderror != "") {
        this.setState({
          iserror: true,
          errorMessage: nextProps.reset_passworderror
        });
      }
      if (nextProps.reset_passwordlist) {
        this.setState({
          userData: nextProps.reset_passwordlist
        });
      }
    }
  }

  componentDidMount() {
    console.log("Am I Here");
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");
    let impersonatingKey = params.get("impersonating");
    this.props.actions.getCurentUserFromToken(token, impersonatingKey);
  }

  onPopClose = () => {
    this.setState({
      isPopupOpen: false
    });
  };

  render() {
    return (
      <div>
        <GridItem
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <GridContainer
            item
            xs={10}
            sm={12}
            md={9}
            lg={9}
            style={{ minWidth: 200 }}
          >
            <Box pt={0.5} style={{ width: "100%", paddingTop: 20 }}>
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton width="60%" />
            </Box>
          </GridContainer>

          <GridContainer
            item
            xs={10}
            sm={12}
            md={9}
            lg={9}
            style={{ minWidth: 200 }}
          >
            <Box pt={0.5} style={{ width: "100%", paddingTop: 20 }}>
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton width="60%" />
            </Box>
          </GridContainer>

          <GridContainer
            item
            xs={10}
            sm={12}
            md={9}
            lg={9}
            style={{ minWidth: 200 }}
          >
            <Box pt={0.5} style={{ width: "100%", paddingTop: 20 }}>
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton width="60%" />
            </Box>
          </GridContainer>

          <GridContainer
            item
            xs={10}
            sm={12}
            md={9}
            lg={9}
            style={{ minWidth: 200 }}
          >
            <Box pt={0.5} style={{ width: "100%", paddingTop: 20 }}>
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton width="60%" />
            </Box>
          </GridContainer>
        </GridItem>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SSOValidate);
