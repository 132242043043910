import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import HomeIcon from "@material-ui/icons/Home";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import ShoppingCart from "@material-ui/icons/ShoppingCart";

import Slide from "@material-ui/core/Slide";

import moment from "moment";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as routesNames from "../../constants/routes";

import AgGrid from "../../components/AgGrid/AgGrid.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function dateFormatter(params) {
  if (params.value) {
    return moment(params.value).format("MM/DD/YYYY h:mm a");
  } else {
    return "";
  }
}

class RetailerOrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order_state: [
        {
          key: 0,
          name: "INITIATED"
        },
        {
          key: 1,
          name: "PLACED"
        },
        {
          key: 2,
          name: "SHIPPING REQUESTED"
        },
        {
          key: 3,
          name: "SHIPPING APPROVED"
        },
        {
          key: 4,
          name: "PACKED"
        },
        {
          key: 5,
          name: "DISPATCHED"
        },
        {
          key: 6,
          name: "DELIVERED"
        },
        {
          key: 7,
          name: "CANCELLED"
        },
        {
          key: 8,
          name: "RETURNED"
        }
      ],
      order: {},
      currentstatus: "",
      Orders: [],
      order_status: null,
      required: {
        order_status: ""
      },
      columnDefs: [
        {
          headerName: "Product Name",
          field: "product.name",
          width: 300,
          resizable: true,
          filter: "agTextColumnFilter"
        },
        // {
        //     headerName: "Brand",
        //     field: "product.product_template.brands[0].partner.name",
        //     sortable: true,
        //     resizable: true,
        //     width: 150,
        // },
        {
          headerName: "MSRP",
          field: "product.msrp",
          resizable: true
        },
        {
          headerName: "Quantity",
          field: "quantity",
          sortable: true,
          resizable: true
        },
        {
          headerName: "Amount",
          field: "total_amount",
          sortable: true,
          resizable: true
        }
      ],
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      domLayout: "autoHeight",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && !_.isEqual(this.props, nextProps)) {
      if (
        nextProps.order &&
        nextProps.order.length > 0 &&
        !_.isEqual(this.props.order, nextProps.order)
      ) {
        this.setState(() => ({
          order: nextProps.order[0],
          Orders: nextProps.order[0].order_detail,
          currentstatus: nextProps.order[0].status
        }));
      }
    }
  }

  handleTextChange = (key, type) => event => {
    var validData = "success";
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({
      currentstatus: event.target.value,
      required: reqData
    });
  };

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let targetPage = params.get("order_id");

    if (targetPage) {
      this.props.actions.getOrderDetailes(targetPage);
      return;
    } else {
      this.props.actions.onNavigateTo(routesNames.ORDER);
    }
  }

  render() {
    return this.renderOrderTable();
  }

  renderOrderDetails = () => {
    const { classes } = this.props;
  };

  pickupLocation = address => {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <HomeIcon />
          </CardIcon>
          <p className={classes.cardIconTitle}>Delivery </p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem md={4} xs={12}>
              Pickup Address
            </GridItem>
            <GridItem md={8} xs={12}>
              <b> {address.address} </b>
            </GridItem>
          </GridContainer>
        </CardBody>
        {/*  <GridItem md={4} xs={12}>
                                                        Phone number
                                                    </GridItem>
                                                    <GridItem md={8} xs={12}>
                                                        <b>  {this.state.order && this.state.order.delivery_address && this.state.order.delivery_address.phone} </b>
                                                    </GridItem>

                                                    <GridItem md={4} xs={12}>
                                                        Pin code
                                                    </GridItem>
                                                    <GridItem md={8} xs={12}>
                                                        <b>  {this.state.order && this.state.order.delivery_address && this.state.order.delivery_address.pincode} </b>
                                                    </GridItem>

                                                    <GridItem md={4} xs={12}>
                                                        Address
                                                    </GridItem>
                                                    <GridItem md={8} xs={12}>
                                                        <b>  {this.state.order && this.state.order.delivery_address && this.state.order.delivery_address.address}</b>
                                                    </GridItem>

                                                    <GridItem md={4} xs={12}>
                                                        City
                                                    </GridItem>
                                                    <GridItem md={8} xs={12}>
                                                        <b>  {this.state.order && this.state.order.delivery_address && this.state.order.delivery_address.city}</b>
                                                    </GridItem>

                                                    <GridItem md={4} xs={12}>
                                                        State
                                                    </GridItem>
                                                    <GridItem md={8} xs={12}>
                                                        <b>{this.state.order && this.state.order.delivery_address && this.state.order.delivery_address.state}</b>
                                                    </GridItem>

                                                    <GridItem md={4} xs={12}>
                                                        Address Type
                                                    </GridItem>
                                                    <GridItem md={8} xs={12}>
                                                        <b>{this.state.order && this.state.order.delivery_address && this.state.order.delivery_address.address_type}</b>
                                                    </GridItem>
                                                </GridContainer>

                                            </CardBody> */}
      </Card>
    );
  };
  handleMode = () => {
    this.props.actions.changeOrderStatus(
      this.state.order.id,
      this.state.currentstatus,
      this.props.UserId
    );
    this.props.actions.onNavigateTo(routesNames.ORDER);
  };

  renderOrderTable = () => {
    const { classes } = this.props;
    let order_status = null;
    for (let i = 0; i < this.state.order_state.length; i++) {
      if (this.state.order_state[i].key === this.state.order.status) {
        order_status = this.state.order_state[i].name;
      }
    }
    let date = null;
    if (this.state.order && this.state.order.created_at) {
      date = moment(this.state.order.created_at).format("YYYY-MM-DD");
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            <Button
              color="success"
              onClick={() => this.handleMode()}
              className={classes.marginRight}
            >
              <ArrowBackIcon className={classes.icons} /> Back to Order
            </Button>
          </Grid>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <p className={classes.cardIconTitle}>
                <b>Order Detail</b>
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <GridContainer>
                  <GridItem md={6} xs={12}>
                    <Card>
                      <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                          <ShoppingCart />
                        </CardIcon>
                        <p className={classes.cardIconTitle}>Order Details</p>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem md={4} xs={12}>
                            Order Number
                          </GridItem>
                          <GridItem md={8} xs={12}>
                            <b>
                              {" "}
                              {this.state.order &&
                                this.state.order.order_number}{" "}
                            </b>
                          </GridItem>

                          <GridItem md={4} xs={12}>
                            Order Date
                          </GridItem>
                          <GridItem md={8} xs={12}>
                            <b> {date && date} </b>
                          </GridItem>

                          {/* <GridItem md={4} xs={12}>
                                                        Customer Name
                                                    </GridItem>
                                                    <GridItem md={8} xs={12}>
                                                        <b>  Ravi Bhushan Tripathi </b>
                                                    </GridItem> */}

                          <GridItem md={4} xs={12}>
                            Email
                          </GridItem>
                          <GridItem md={8} xs={12}>
                            <b>
                              {" "}
                              {this.state.order &&
                                this.state.order.user &&
                                this.state.order.user.email}{" "}
                            </b>
                          </GridItem>

                          <GridItem md={4} xs={12}>
                            Total Quantity
                          </GridItem>
                          <GridItem md={8} xs={12}>
                            <b>
                              {" "}
                              {this.state.order &&
                                this.state.order.total_quantity}{" "}
                            </b>
                          </GridItem>

                          <GridItem md={4} xs={12}>
                            Total Amount
                          </GridItem>
                          <GridItem md={8} xs={12}>
                            <b>
                              {" "}
                              {this.state.order &&
                                this.state.order.total_amount}{" "}
                            </b>
                          </GridItem>

                          <GridItem md={4} xs={12}>
                            Order Status
                          </GridItem>
                          <GridItem md={8} xs={12}>
                            <b> {order_status && order_status} </b>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem md={6} xs={12}>
                    {this.state.order.shipping_type == 1 ? (
                      this.pickupLocation(this.state.order.pickup_address)
                    ) : (
                      <Card>
                        <CardHeader color="rose" icon>
                          <CardIcon color="rose">
                            <HomeIcon />
                          </CardIcon>
                          <p className={classes.cardIconTitle}>
                            Delivery Address
                          </p>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem md={4} xs={12}>
                              Delivering to
                            </GridItem>
                            <GridItem md={8} xs={12}>
                              <b>
                                {" "}
                                {this.state.order &&
                                  this.state.order.delivery_address &&
                                  this.state.order.delivery_address.name}{" "}
                              </b>
                            </GridItem>

                            <GridItem md={4} xs={12}>
                              Phone number
                            </GridItem>
                            <GridItem md={8} xs={12}>
                              <b>
                                {" "}
                                {this.state.order &&
                                  this.state.order.delivery_address &&
                                  this.state.order.delivery_address.phone}{" "}
                              </b>
                            </GridItem>

                            <GridItem md={4} xs={12}>
                              Pin code
                            </GridItem>
                            <GridItem md={8} xs={12}>
                              <b>
                                {" "}
                                {this.state.order &&
                                  this.state.order.delivery_address &&
                                  this.state.order.delivery_address
                                    .pincode}{" "}
                              </b>
                            </GridItem>

                            <GridItem md={4} xs={12}>
                              Address
                            </GridItem>
                            <GridItem md={8} xs={12}>
                              <b>
                                {" "}
                                {this.state.order &&
                                  this.state.order.delivery_address &&
                                  this.state.order.delivery_address.address}
                              </b>
                            </GridItem>

                            <GridItem md={4} xs={12}>
                              City
                            </GridItem>
                            <GridItem md={8} xs={12}>
                              <b>
                                {" "}
                                {this.state.order &&
                                  this.state.order.delivery_address &&
                                  this.state.order.delivery_address.city}
                              </b>
                            </GridItem>

                            <GridItem md={4} xs={12}>
                              State
                            </GridItem>
                            <GridItem md={8} xs={12}>
                              <b>
                                {this.state.order &&
                                  this.state.order.delivery_address &&
                                  this.state.order.delivery_address.state}
                              </b>
                            </GridItem>

                            <GridItem md={4} xs={12}>
                              Address Type
                            </GridItem>
                            <GridItem md={8} xs={12}>
                              <b>
                                {this.state.order &&
                                  this.state.order.delivery_address &&
                                  this.state.order.delivery_address
                                    .address_type}
                              </b>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    )}
                  </GridItem>

                  <GridItem md={12} xs={12}>
                    <Card>
                      <CardHeader>
                        <br />
                        <p
                          className={
                            classes.cardIconTitle + " grid-header-title"
                          }
                        >
                          Order Items
                        </p>
                      </CardHeader>
                      <CardBody>
                        <div>
                          <div
                            id="myGrid"
                            style={{
                              width: "100%"
                            }}
                            className="ag-theme-material"
                          >
                            {/* <AgGridReact
                                                            gridOptions={this.gridOptions}
                                                            columnDefs={this.state.columnDefs}
                                                            defaultColDef={this.state.columnDefs}
                                                            rowData={this.state.Orders}
                                                            showToolPanel={true}
                                                            // floatingFilter={true}
                                                            statusBar={this.state.statusBar}
                                                            groupSelectsChildren={true}
                                                            sideBar={this.state.sideBar}
                                                            pagination={true}
                                                            paginationPageSize={50}
                                                            rowMultiSelectWithClick={true}
                                                            domLayout={this.state.domLayout}
                                                        /> */}

                            <AgGrid
                              rowData={this.state.Orders}
                              columnDefs={this.state.columnDefs}
                              Loading={this.props.Loading}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>

                  <GridItem md={6} xs={12}>
                    <FormControl
                      fullWidth
                      style={customInputStyle.formControl}
                      style={{ paddingTop: "0px" }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.labelRoot + " "}
                        labelText="Change Status *"
                        style={{ top: "-23px" }}
                      >
                        Change Status *
                      </InputLabel>

                      <Select
                        success={this.state.required.segmentName === "success"}
                        error={this.state.required.segmentName === "error"}
                        value={this.state.currentstatus}
                        name="product_template"
                        onChange={this.handleTextChange("order_state", "")}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Choose Product
                        </MenuItem>
                        {_.map(
                          this.state.order_state,
                          ({ key, name }, index) => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={key}
                                key={key}
                              >
                                {name}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem md={6} xs={12}>
                    <Button
                      color="primary"
                      onClick={() => this.handleMode()}
                      className={classes.marginRight}
                    >
                      Change Status
                    </Button>
                  </GridItem>
                </GridContainer>

                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  order: state.orderReducer.get.detail.order,
  Loading: state.orderReducer.get.detail.loading,
  ListError: state.orderReducer.get.detail.loaderror,
  UserId: state.authState.user.pk
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...customInputStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RetailerOrderDetails);
