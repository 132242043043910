import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {

    pagination: {
        page: "1",
        next: null,
        prev: null,
        totalpages: 1,
        trecords: 0,
        pagesize: 10
    },

    product_variant_details: {
        loading: false,
        error: null,
        variant: null,
    },

    add_product_variant: {
        saving: false,
        error: null,
    },

    list_product_variant: {
        loading: false,
        error: null,
        variants: []
    },

    delete_product_variant: {
        loading: false,
        error: null,
    },

};

const variantReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case actionTypes.GET_PRODUCT_VARIANT_DETAIL_REQUEST:
            return {
                ...state,
                product_variant_details: {
                    loading: true,
                    error: null,
                    variant: null,
                },
            };
        case actionTypes.GET_PRODUCT_VARIANT_DETAIL_FAILURE:
            return {
                ...state,
                product_variant_details: {
                    loading: false,
                    error: action.payload,
                    variant: null,
                },
            };

        case actionTypes.GET_PRODUCT_VARIANT_DETAIL_SUCCESS:
            return {
                ...state,
                product_variant_details: {
                    loading: false,
                    error: null,
                    variant: action.payload,
                },
            };

        case actionTypes.DELETE_PRODUCT_VARIANT_REQUEST:
            return {
                ...state,
                delete_product_variant: {
                    deleting: true,
                    error: null
                }
            };

        case actionTypes.DELETE_PRODUCT_VARIANT_FAILURE:
            return {
                ...state,
                delete_product_variant: {
                    deleting: false,
                    error: action.payload
                }
            };

        case actionTypes.DELETE_PRODUCT_VARIANT_SUCCESS:

            return {
                ...state,
                delete_product_variant: {
                    deleting: false,
                    error: null
                }
            };


        case actionTypes.CREATE_PRODUCT_VARIANT_REQUEST:
            return {
                ...state,
                add_product_variant: {
                    saving: true,
                    error: null
                },
            };
        case actionTypes.CREATE_PRODUCT_VARIANT_FAILURE:
            return {
                ...state,
                add_product_variant: {
                    saving: false,
                    error: action.payload
                }
            };
        case actionTypes.CREATE_PRODUCT_VARIANT_SUCCESS:

            return {
                ...state,
                add_product_variant: {
                    saving: false,
                    error: null
                }
            };

        case actionTypes.GET_PRODUCT_VARIANT_REQUEST:
            return {
                ...state,
                list_product_variant: {
                    ...state.list_product_variant,
                    loading: true,
                    error: null,
                },
            };

        case actionTypes.GET_PRODUCT_VARIANT_FAILURE:
            return {
                ...state,
                list_product_variant: {
                    ...state.list_product_variant,
                    loading: false,
                    error: action.payload,
                },
            };

        case actionTypes.GET_PRODUCT_VARIANT_SUCCESS:

            let pagination = _.clone(state.pagination);
            let sdata = action.payload;

            if (sdata && sdata.results) {
                pagination.page = sdata.current;
                pagination.next = sdata.next;
                pagination.prev = sdata.prev;
                pagination.totalpage = sdata.total_pages;
                pagination.totalrecords = sdata.total_records;
                pagination.pagesize = sdata.page_size;
                sdata = sdata.results;
            }

            return {
                ...state,
                list_product_variant: {
                    ...state.list_product_variant,
                    loading: false,
                    error: null,
                    variants: sdata
                },

                pagination: {
                    ...pagination
                }
            };

        default:
            return state;
    }
};

export default variantReducer;
