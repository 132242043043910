import React, { Component } from "react";
import { Edit, Delete, RadioButtonChecked, FileCopy } from "@material-ui/icons";
class AgGridActionButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  invokeParentMethod() {
    const { data } = this.props;
    return (
      <div
        style={{
          lineHeight: "60px"
        }}
      >
        <span onClick={() => this.performAction(data.id, 0)} title="Edit">
          <Edit className="grid_action_btn" />
        </span>
        {data.type.toUpperCase() === 'LOCAL PRODUCT' ?
          <span onClick={() => this.performAction(data.id, 4)} title="Delete">
            <Delete className="grid_action_btn" />
          </span> : ""}
        <span
          span
          onClick={() => this.performAction(data.id, 3)}
          title="Clone Product"
        ><FileCopy className="grid_action_btn" />
        </span>
        {data.is_active ? (
          <span
            span
            onClick={() => this.performAction(data.id, 1)}
            title="Mark InActive"
          >
            <RadioButtonChecked
              className="grid_action_btn"
              style={{
                color: "red",
                border: "red"
              }}
            />
          </span>
        ) : (
          <span
            span
            onClick={() => this.performAction(data.id, 2)}
            title="Mark Active"
          >
            <RadioButtonChecked
              className="grid_action_btn"
              style={{
                color: "green",
                border: "green"
              }}
            />
          </span>
        )}
      </div>
    );
  }

  performAction(id, value) {
    switch (value) {
      case 0:
        this.props.context.componentParent.handelEdit(id);
        break;
      case 1:
        this.props.context.componentParent.view(id);
        break;
      case 2:
        this.props.context.componentParent.view(id);
        break;
      case 3:
        this.props.context.componentParent.handleCloneProduct(id);
        break;
      case 4:
        this.props.context.componentParent.handleDelete(id);
        break;
    }
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default AgGridActionButton;
