import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import PropTypes from "prop-types";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

// core components

import Button from "components/CustomButtons/Button.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components/Dialog

import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import ConfirmBox from "./../../../components/ConfirmBox/ConfirmBox.jsx";

class CloneSiteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      required_colne_site: {
        partnerId: ""
      },
      colne_site: {
        partnerId: null
      },
      isConfirmBox: false,
      List: [],
      config: {
        'cms': true,
        'brand': true,
        'products': true,
        'services': true,
        'usergroup': true,
        'settings': true,
        'category': true,
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.cloneSiteisSaved) {
        this.props.onClose();
      }
      if (nextProps.List && nextProps.List.length > 0) {
        let listData = _.clone(nextProps.List);
        let Index = listData.findIndex(p => p.id == nextProps.partnerId);
        if (Index > -1) {
          listData.splice(Index, 1);
        }
        this.setState({
          List: listData
        });
      }
    }
  }

  componentDidMount() {
    // this.props.actions.getPartnersList()
  }
  handleTextChange_copy(pratnerData) {
    if (pratnerData && pratnerData.id) {
      this.setState({
        colne_site: {
          partnerId: pratnerData.id
        }
      });
    }
  }

  saveCloneSite = () => {
    const { partnerId } = this.state.colne_site;
    this.setState(
      {
        isConfirmBox: false
      },
      () => {
        this.props.actions.clonePartnerSite(partnerId, this.props.partnerId);
      }
    );
  };
  onHandleconfirmBoxCancle = () => {
    this.setState({
      isConfirmBox: false
    });
  };

  showConfirmBox = () => {
    this.setState({
      isConfirmBox: true
    });
  };
  confirmBoxDilog() {
    return (
      <ConfirmBox
        onHandleConfrim={this.saveCloneSite}
        onHandleCancle={this.onHandleconfirmBoxCancle}
        open={this.state.isConfirmBox}
        title="Your previously saved data will be removed and the custom pages without their data will be cloned, are you sure you want to proceed ?"
      />
    );
  }
  cloneSiteDilog() {
    const { classes } = this.props;

    return (
      <LoadingOverlay>
        {this.confirmBoxDilog()}
        <Autocomplete
          id="highlights-demo"
          style={{
            width: "100%"
          }}
          options={this.state.List}
          getOptionLabel={option => option.name}
          onChange={(event, value) => this.handleTextChange_copy(value)}
          renderInput={params => (
            <span>
              <InputLabel className={classes.label + " "}>Partners</InputLabel>
              <TextField
                {...params}
                variant="outlined"
                size="small"
                classes={{
                  root: "attribute-txtbox"
                }}
              />
            </span>
          )}
          renderOption={(option, { inputValue }) => {
            const matches = match(option.name, inputValue);
            const parts = parse(option.name, matches);

            return (
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
        />
        {/* <CustomInput
                    labelText=""
                    success={required_copy_menu.name === "success"}
                    error={required_copy_menu.name === "error"}
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        type: "text",
                        value: name,
                        onChange: this.handleTextChange_copy('name', "")
                    }}
                /> */}

        {this.props.cloneSiteLoading && (
          <CircularProgress
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-25px",
              marginLeft: "-12px"
            }}
          />
        )}
        <Loader loading={this.props.cloneSiteLoading} text="saving.." />
        <br />
        {this.props.cloneSiteError && this.props.cloneSiteError.trim() !== "" && (
          <FormLabel className={classes.labelLeftHorizontal}>
            <code>{this.props.cloneSiteError}</code>
          </FormLabel>
        )}
        <br />
        <Button onClick={() => this.showConfirmBox()} color="danger" simple>
          Save
        </Button>
        <Button onClick={() => this.props.onClose()} color="danger" simple>
          Cancel
        </Button>
      </LoadingOverlay>
    );
  }
  onHanndelClose = () => {
    this.setState({
      openCopyDilog: false
    });
  };

  render() {
    return <span>{this.cloneSiteDilog()}</span>;
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserId: state.authState.user.user,

  cloneSiteList: state.cmsReducer.cloneSite.list,
  cloneSiteisSaved: state.cmsReducer.cloneSite.isSaved,
  cloneSiteLoading: state.cmsReducer.cloneSite.loading,
  cloneSiteError: state.cmsReducer.cloneSite.error,

  List: state.cmsReducer.retailer_list.list,
  Loading: state.cmsReducer.retailer_list.loading,
  ListError: state.cmsReducer.retailer_list.error
});

CloneSiteComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  entityData: PropTypes.object,
  menu: PropTypes.array,
  selectedClassIndexes: PropTypes.array.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CloneSiteComponent);

// export default menuComponent;
