import * as servicesServices from "../../services/servicesServices";
import * as actionTypes from "./actionTypes";

export const createServices = (id, data, PartnerId) => async dispatch => {
  dispatch(createServicesRequest());
  try {
    const success = await servicesServices.createServices(id, data, PartnerId);
    if (success.status === "1") {
      dispatch(createServicesSuccess(success.data));
    } else {
      dispatch(createServicesFailure(success.error));
    }
  } catch (error) {
    dispatch(createServicesFailure(error.message));
  }
};

export const getServicesList = (
  isHierarchy,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getServicesRequest());
  try {
    var success = await servicesServices.getServicesList(
      isHierarchy,
      page,
      search
    );
    if (success.status && success.status === "1") {
      dispatch(getServicesSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getServicesFailure(success.error));
    } else {
      dispatch(getServicesFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getServicesFailure(error.message));
  }
};

export const getServiceDetaile_id = service_id => async dispatch => {
  dispatch(getServicesDetailRequest());
  try {
    var success = await servicesServices.getServiceDetaileById(service_id);

    if (success.status && success.status === "1") {
      dispatch(getServicesDetailSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getServicesDetailFailure(success.error));
    } else {
      dispatch(getServicesDetailFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getServicesDetailFailure(error.message));
  }
};

export const saveServicePartnerMapping = (
  service_id,
  PartnerId,
  userId
) => async dispatch => {
  dispatch(savePartnerServiceRequest());
  try {
    var success = await servicesServices.saveServicePartnerMapping(
      service_id,
      PartnerId,
      userId
    );

    if (success.status && success.status === "1") {
      dispatch(savePartnerServiceSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(savePartnerServiceFailure(success.error));
    } else {
      dispatch(savePartnerServiceFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(savePartnerServiceFailure(error.message));
  }
};

export const assignedServicer = id => async dispatch => {
  dispatch(assignedPartnerServiceRequest());
  try {
    var success = await servicesServices.assignedServicer(id);

    if (success.status && success.status === "1") {
      dispatch(assignedPartnerServiceSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(assignedPartnerServiceFailure(success.error));
    } else {
      dispatch(assignedPartnerServiceFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(assignedPartnerServiceFailure(error.message));
  }
};

const createServicesRequest = () => {
  return {
    type: actionTypes.CREATE_SERVICES_REQUEST
  };
};

const createServicesSuccess = data => {
  return {
    type: actionTypes.CREATE_SERVICES_SUCCESS,
    payload: data
  };
};

const createServicesFailure = error => {
  return {
    type: actionTypes.CREATE_SERVICES_FAILURE,
    payload: error
  };
};

const getServicesRequest = () => {
  return {
    type: actionTypes.GET_SERVICES_REQUEST
  };
};

const getServicesSuccess = data => {
  return {
    type: actionTypes.GET_SERVICES_SUCCESS,
    payload: data
  };
};

const getServicesFailure = error => {
  return {
    type: actionTypes.GET_SERVICES_FAILURE,
    payload: error
  };
};

const getServicesDetailRequest = () => {
  return {
    type: actionTypes.GET_SERVICE_DETAIL_REQUEST
  };
};

const getServicesDetailSuccess = data => {
  return {
    type: actionTypes.GET_SERVICE_DETAIL_SUCCESS,
    payload: data
  };
};

const getServicesDetailFailure = error => {
  return {
    type: actionTypes.GET_SERVICE_DETAIL_FAILURE,
    payload: error
  };
};

const savePartnerServiceRequest = () => {
  return {
    type: actionTypes.SAVE_SERVICE_PARTNER_MAPPING_REQUEST
  };
};

const savePartnerServiceSuccess = data => {
  return {
    type: actionTypes.SAVE_SERVICE_PARTNER_MAPPING_SUCCESS,
    payload: data
  };
};

const savePartnerServiceFailure = error => {
  return {
    type: actionTypes.SAVE_SERVICE_PARTNER_MAPPING_FAILURE,
    payload: error
  };
};

const assignedPartnerServiceRequest = () => {
  return {
    type: actionTypes.GET_ASSIGNED_SERVICE_PARTNER_MAPPING_REQUEST
  };
};

const assignedPartnerServiceSuccess = data => {
  return {
    type: actionTypes.GET_ASSIGNED_SERVICE_PARTNER_MAPPING_SUCCESS,
    payload: data
  };
};

const assignedPartnerServiceFailure = error => {
  return {
    type: actionTypes.GET_ASSIGNED_SERVICE_PARTNER_MAPPING_FAILURE,
    payload: error
  };
};
