import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  list: null,
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  productTemplate: {
    isLoading: false,
    products: [],
    isSaving: false,
    savedData: {},
    pagination: {
      page: "1",
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  },
  categorisedproductTemplate: {
    isLoading: false,
    products: [],
    isSaving: false,
    isSaved: false
  },
  categorisedbrandproductTemplate: {
    isLoading: false,
    products: [],
    isSaving: false,
    error: null
  },
  selectedProdcutTemplates: {
    isSaving: false,
    saved: false,
    isLoading: false,
    products: null
  },
  universalAttributes: {
    isSaving: false,
    isLoading: false,
    attributes: null
  },
  attributeGroups: {
    isSaving: false,
    isSaved: false,
    isLoading: false,
    attributeGroups: null,
    data: null,
    pagination: {
      page: "1",
      next: null,
      prev: null,
      totalpages: 1,
      trecords: 0,
      pagesize: 10
    }
  },
  map_class_producttemplate: {
    savingCompleted: false,
    isSaving: false,
    isLoading: false,
    error: null
  },
  map_wholesaler_brand: {
    savingCompleted: false,
    isSaving: false,
    isLoading: false,
    error: null,
    brand: []
  },
  map_wholesaler_brand_producttemplate: {
    savingCompleted: false,
    isSaving: false,
    isLoading: false,
    error: null,
    products: []
  },
  map_wholesaler_brand_producttemplate_Selected: {
    savingCompleted: false,
    isSaving: false,
    isLoading: false,
    error: null,
    products: []
  },
  map_Retailer_brand_producttemplate: {
    savingCompleted: false,
    isSaving: false,
    isLoading: false,
    error: null,
    products: []
  },
  map_Retailer_brand_producttemplate_Selected: {
    savingCompleted: false,
    isSaving: false,
    isLoading: false,
    error: null,
    products: []
  },
  productTemplateById: {
    data: null,
    loading: false,
    error: null
  },
  product_template: {
    data: null,
    loading: false,
    error: null
  },
  partner_partners: {
    data: null,
    loading: false,
    error: null
  },
  attributeGroupById: {
    error: null,
    loading: false,
    data: null,
    isAttributeDelete: false
  },
  publish_product: {
    error: null,
    loading: false,
    isPublished: false
  }
};

const brandReducer = (state = INITIAL_STATE, action) => {
  var index = null;
  var data = null;

  switch (action.type) {
    case actionTypes.CREATE_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false
      };
    case actionTypes.CREATE_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actionTypes.CREATE_BRAND_SUCCESS:
      data = state.list;
      if (data) {
      } else {
        data = [];
      }
      index = data.findIndex(p => p.api_key === action.payload.api_key);
      if (index === -1) {
        data.push(action.payload);
      } else {
        data[index] = action.payload;
      }
      return {
        ...state,
        list: data,
        loading: false,
        error: null,
        isSaved: true
      };
    case actionTypes.GET_BRAND_REQUEST:
      return {
        ...state,
        list: null,
        loadingData: true,
        error: null,
        isSaved: false
      };
    case actionTypes.GET_BRAND_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false
      };
    case actionTypes.GET_BRAND_SUCCESS:
      return {
        ...state,
        loadingData: false,
        error: null,
        list: action.payload,
        isSaved: false,
        map_wholesaler_brand: {
          loadingData: false,
          error: null,
          brand: null
        }
      };

    case actionTypes.SAVE_WHOLESALER_BRAND_SUCCESS:
      return {
        ...state,
        loadingData: false,
        map_wholesaler_brand: {
          loadingData: false,
          error: null,
          brand: action.payload
        }
      };

    case actionTypes.GET_PRODUCT_TEMPLATE_REQUEST:
      return {
        ...state,

        productTemplate: {
          isLoading: true,
          products: null,
          isSaving: false,
          barnd: action.payload,
          pagination: {
            page: "1",
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        }
      };
    case actionTypes.GET_PRODUCT_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        productTemplate: {
          isLoading: false,
          products: null,
          isSaving: false
        }
      };
    case actionTypes.GET_PRODUCT_TEMPLATE_SUCCESS:
      let pagination = _.clone(state.productTemplate.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        error: null,
        productTemplate: {
          isLoading: false,
          products: sdata,
          isSaving: false,
          pagination: {
            ...pagination
          }
        }
      };
    case actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_REQUEST:
      return {
        ...state,
        categorisedbrandproductTemplate: {
          isLoading: true,
          products: null,
          isSaving: false,
          error: null
        },
        selectedProdcutTemplates: {
          saved: false
        }
      };
    case actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_FAILURE:
      return {
        ...state,
        categorisedbrandproductTemplate: {
          isLoading: false,
          products: null,
          isSaving: false,
          error: action.payload
        },
        selectedProdcutTemplates: {
          saved: false
        }
      };
    case actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS:
      return {
        ...state,
        categorisedbrandproductTemplate: {
          isLoading: false,
          products: action.payload,
          isSaving: false,
          error: null
        },
        selectedProdcutTemplates: {
          saved: false
        }
      };
    case actionTypes.GET_RBRANDS_CATEGORISED_PRODUCT_TEMPLATE_REQUEST:
      return {
        ...state,
        map_Retailer_brand_producttemplatemplate: {
          isLoading: true,
          products: null,
          isSaving: false,
          error: null
        },
        selectedProdcutTemplates: {
          saved: false
        }
      };
    case actionTypes.GET_RBRANDS_CATEGORISED_PRODUCT_TEMPLATE_FAILURE:
      return {
        ...state,
        map_Retailer_brand_producttemplate: {
          isLoading: false,
          products: null,
          isSaving: false,
          error: action.payload
        },
        selectedProdcutTemplates: {
          saved: false
        }
      };
    case actionTypes.GET_RBRANDS_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS:
      return {
        ...state,
        map_Retailer_brand_producttemplate: {
          isLoading: false,
          products: action.payload,
          isSaving: false,
          error: null
        },
        selectedProdcutTemplates: {
          saved: false
        }
      };
    case actionTypes.GET_CATEGORISED_PRODUCT_TEMPLATE_REQUEST:
      return {
        ...state,
        error: null,
        categorisedproductTemplate: {
          isLoading: true,
          products: null,
          isSaving: false
        }
      };
    case actionTypes.GET_CATEGORISED_PRODUCT_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        categorisedproductTemplate: {
          isLoading: false,
          products: null,
          isSaving: false
        }
      };
    case actionTypes.GET_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: null,
        categorisedproductTemplate: {
          isLoading: false,
          products: action.payload,
          isSaving: false
        }
      };
    case actionTypes.SAVE_BRAND_PRODUCT_TEMPLATES_REQUEST:
      return {
        ...state,
        error: null,
        selectedProdcutTemplates: {
          isSaving: true,
          products: null,
          saved: false
        }
      };
    case actionTypes.SAVE_BRAND_PRODUCT_TEMPLATES_FAILURE:
      return {
        ...state,
        error: action.payload,
        selectedProdcutTemplates: {
          isSaving: false,
          products: null,
          saved: false
        }
      };
    case actionTypes.SAVE_BRAND_PRODUCT_TEMPLATES_SUCCESS:
      return {
        ...state,
        error: null,
        selectedProdcutTemplates: {
          isSaving: false,
          products: action.payload,
          saved: true
        }
      };
    case actionTypes.GET_BRAND_PRODUCT_TEMPLATES_REQUEST:
      return {
        ...state,
        error: null,
        selectedProdcutTemplates: {
          isLoading: true,
          products: null
        }
      };
    case actionTypes.GET_BRAND_PRODUCT_TEMPLATES_FAILURE:
      return {
        ...state,
        error: action.payload,
        selectedProdcutTemplates: {
          isLoading: false,
          products: null
        }
      };
    case actionTypes.GET_BRAND_PRODUCT_TEMPLATES_SUCCESS:
      return {
        ...state,
        error: null,
        selectedProdcutTemplates: {
          isLoading: false,
          products: action.payload
        }
      };

    case actionTypes.MAP_CLASS_PT_REQUEST:
      return {
        ...state,
        map_class_producttemplate: {
          savingCompleted: false,
          isSaving: true,
          isLoading: false,
          error: null
        }
      };

    case actionTypes.MAP_CLASS_PT_FAILURE:
      return {
        ...state,
        map_class_producttemplate: {
          savingCompleted: false,
          isSaving: false,
          isLoading: false,
          error: action.payload
        }
      };

    case actionTypes.MAP_CLASS_PT_SUCCESS:
      return {
        ...state,
        map_class_producttemplate: {
          savingCompleted: true,
          isSaving: false,
          isLoading: false,
          error: null
        }
      };

    case actionTypes.CREATE_PRODUCT_TEMPLATE_REQUEST:
      return {
        ...state,
        error: null,
        productTemplate: {
          ...state.productTemplate,
          isSaving: true
        }
      };
    case actionTypes.CREATE_PRODUCT_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        productTemplate: {
          ...state.productTemplate,
          isSaving: false
        }
      };
    case actionTypes.CREATE_PRODUCT_TEMPLATE_SUCCESS:
      var updatedProd = action.payload;
      index = state.productTemplate.products.findIndex(
        item => item.id === updatedProd.id
      );
      var productTemps = state.productTemplate.products;
      if (index > -1) {
        productTemps[index] = updatedProd;
      } else {
        productTemps.push(updatedProd);
      }
      return {
        ...state,

        error: null,
        productTemplate: {
          ...state.productTemplate,
          products: productTemps,
          isSaving: false
        }
      };
    case actionTypes.CREATE_UNIVERSAL_ATTRIBUTE_REQUEST:
      return {
        ...state,
        error: null,
        universalAttributes: {
          ...state.universalAttributes,
          isSaving: true
        }
      };
    case actionTypes.CREATE_UNIVERSAL_ATTRIBUTE_FAILURE:
      return {
        ...state,
        error: action.payload,
        universalAttributes: {
          ...state.universalAttributes,
          isSaving: false
        }
      };
    case actionTypes.CREATE_UNIVERSAL_ATTRIBUTE_SUCCESS:
      data = state.universalAttributes.attributes;
      if (data) {
      } else {
        data = [];
      }
      index = data.findIndex(item => item.id === action.payload.id);
      if (index === -1) {
        data.push(action.payload);
      } else {
        data[index] = action.payload;
      }
      return {
        ...state,
        error: null,
        universalAttributes: {
          ...state.universalAttributes,
          isSaving: false,
          attributes: data
        }
      };
    case actionTypes.GET_UNIVERSAL_ATTRIBUTE_REQUEST:
      return {
        ...state,
        error: null,
        universalAttributes: {
          ...state.universalAttributes,
          isLoading: true,
          attributes: null
        }
      };
    case actionTypes.GET_UNIVERSAL_ATTRIBUTE_FAILURE:
      return {
        ...state,
        error: action.payload,
        universalAttributes: {
          ...state.universalAttributes,
          isLoading: false,
          attributes: null
        }
      };
    case actionTypes.GET_UNIVERSAL_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        error: null,
        universalAttributes: {
          ...state.universalAttributes,
          isLoading: false,
          attributes: action.payload
        }
      };
    case actionTypes.CREATE_ATTRIBUTE_GROUP_REQUEST:
      return {
        ...state,
        error: null,
        attributeGroups: {
          ...state.attributeGroups,
          isSaving: true,
          isSaved: false,
          data: null
        },
        attributeGroupById: {
          error: null,
          loading: false,
          isAttributeDelete: false
        }
      };
    case actionTypes.CREATE_ATTRIBUTE_GROUP_FAILURE:
      return {
        ...state,
        error: action.payload,
        attributeGroups: {
          ...state.attributeGroups,
          isSaved: false,
          isSaving: false,
          data: null
        }
      };
    case actionTypes.CREATE_ATTRIBUTE_GROUP_SUCCESS:
      // data = state.attributeGroups.attributeGroups;

      // index = data.findIndex(item => item.id === action.payload.id);

      // if (index > -1) {
      // 	data.splice(index, 1);
      // }

      // data.unshift(action.payload);
      return {
        ...state,
        error: null,
        attributeGroups: {
          ...state.attributeGroups,
          isSaving: false,
          isSaved: true,
          // attributeGroups: data,
          data: action.payload
        }
      };
    case actionTypes.GET_ATTRIBUTE_GROUP_REQUEST:
      return {
        ...state,
        error: null,
        attributeGroups: {
          ...state.attributeGroups,
          isLoading: true,
          attributeGroups: null,
          isSaved: false,
          pagination: {
            page: 1,
            next: null,
            prev: null,
            totalpages: 1,
            trecords: 0,
            pagesize: 10
          }
        }
      };
    case actionTypes.GET_ATTRIBUTE_GROUP_FAILURE:
      return {
        ...state,
        error: action.payload,
        attributeGroups: {
          ...state.attributeGroups,
          isLoading: false,
          attributeGroups: null
        }
      };
    case actionTypes.GET_ATTRIBUTE_GROUP_SUCCESS:
      let ag_pagination = _.clone(state.attributeGroups.pagination);
      let ags_data = action.payload;
      if (ags_data && ags_data.results) {
        ag_pagination.page = ags_data.current;
        ag_pagination.next = ags_data.next;
        ag_pagination.prev = ags_data.prev;
        ag_pagination.totalpage = ags_data.total_pages;
        ag_pagination.totalrecords = ags_data.total_records;
        ag_pagination.pagesize = ags_data.page_size;
        ags_data = ags_data.results;
      }
      return {
        ...state,
        error: null,
        attributeGroups: {
          ...state.attributeGroups,
          isLoading: false,
          attributeGroups: ags_data,
          pagination: {
            ...ag_pagination
          }
        }
      };
    case actionTypes.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        isDeleted: false
      };
    case actionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        isDeleted: false
      };
    case actionTypes.DELETE_USER_SUCCESS:
      var data = state.list;
      let userIndex = data.findIndex(o => o.api_key === action.user_Id);
      data.splice(userIndex, 1);
      return {
        ...state,
        loading: false,
        error: null,
        list: data,
        isSaved: false,
        isDeleted: true
      };

    case actionTypes.GET_WHOLESALER_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_REQUEST:
      return {
        ...state,
        map_wholesaler_brand_producttemplate: {
          loadingData: true,
          error: null,
          brand: null,
          products: []
        }
      };

    case actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_FAILURE:
      return {
        ...state,
        map_wholesaler_brand_producttemplate: {
          loadingData: false,
          error: action.payload,
          brand: null,
          products: []
        }
      };

    case actionTypes.GET_WHOLESALER_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SUCCESS:
      return {
        ...state,
        map_wholesaler_brand_producttemplate: {
          loadingData: false,
          error: null,
          brand: null,
          products: action.payload
        }
      };

    //
    case actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_REQUEST:
      return {
        ...state,
        map_wholesaler_brand_producttemplate_Selected: {
          loadingData: true,
          error: null,
          brand: null,
          products: []
        },
        selectedProdcutTemplates: {
          saved: false
        }
      };

    case actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_FAILURE:
      return {
        ...state,
        map_wholesaler_brand_producttemplate_Selected: {
          loadingData: false,
          error: action.payload,
          brand: null,
          products: []
        },
        selectedProdcutTemplates: {
          saved: false
        }
      };

    case actionTypes.GET_BRANDS_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_SUCCESS:
      return {
        ...state,
        map_wholesaler_brand_producttemplate_Selected: {
          loadingData: false,
          error: null,
          brand: null,
          products: action.payload
        }
      };
    //retailer
    case actionTypes.GET_RETAILER_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_REQUEST:
      return {
        ...state,
        map_Retailer_brand_producttemplate_Selected: {
          loadingData: true,
          error: null,
          brand: null,
          products: []
        },
        selectedProdcutTemplates: {
          saved: false
        }
      };

    case actionTypes.GET_RETAILER_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_FAILURE:
      return {
        ...state,
        map_Retailer_brand_producttemplate_Selected: {
          loadingData: false,
          error: action.payload,
          brand: null,
          products: []
        },
        selectedProdcutTemplates: {
          saved: false
        }
      };

    case actionTypes.GET_RETAILER_CATEGORISED_PRODUCT_TEMPLATE_SELECTED_SUCCESS:
      return {
        ...state,
        map_Retailer_brand_producttemplate_Selected: {
          loadingData: false,
          error: null,
          brand: null,
          products: action.payload
        },
        selectedProdcutTemplates: {
          saved: false
        }
      };

    case actionTypes.SAVE_ATTRIBUTE_GROUP_REQUEST:
      return {
        ...state,
        error: null,
        attributeGroups: {
          ...state.attributeGroups,
          isSaving: true
        }
      };
    case actionTypes.SAVE_ATTRIBUTE_GROUP_FAILURE:
      return {
        ...state,
        error: action.payload,
        attributeGroups: {
          ...state.attributeGroups,
          isSaving: false
        }
      };
    case actionTypes.SAVE_ATTRIBUTE_GROUP_SUCCESS:
      let attributeList = _.clone(state.attributeGroups.attributeGroups);
      let attribute_index = attributeList.findIndex(
        item => item.id === action.payload.id
      );
      if (attribute_index === -1) {
        attributeList.push(action.payload);
      } else {
        attributeList[attribute_index] = action.payload;
      }
      return {
        ...state,
        error: null,
        attributeGroups: {
          ...state.attributeGroups,
          isSaving: false,
          attributeGroups: attributeList
        }
      };

    case actionTypes.SAVE_PRODUCT_TEMPLATE_REQUEST:
      return {
        ...state,
        error: null,
        product_template: {
          data: null,
          loading: true,
          error: null
        }
      };

    case actionTypes.SAVE_PRODUCT_TEMPLATE_FAILURE:
      return {
        ...state,
        product_template: {
          data: null,
          loading: false,
          error: action.payload
        }
      };

    case actionTypes.SAVE_PRODUCT_TEMPLATE_SUCCESS:
      // let productList = _.clone(state.productTemplate.products)
      // index = productList.findIndex(item => item.id === action.payload.id);
      // if (index > -1) {
      // 	productList[index] = action.payload;
      // } else {
      // 	productList.push(action.payload);
      // }
      return {
        ...state,
        product_template: {
          ...state.product_template,
          data: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.PRODUCT_TEMPLATE_BYID_REQUEST:
      return {
        ...state,
        productTemplateById: {
          ...state.productTemplateById,
          data: null,
          loading: true,
          error: null
        }
      };

    case actionTypes.PRODUCT_TEMPLATE_BYID_FAILURE:
      return {
        ...state,
        productTemplateById: {
          ...state.productTemplateById,
          data: null,
          loading: false,
          error: action.payload
        }
      };

    case actionTypes.PRODUCT_TEMPLATE_BYID_SUCCESS:
      return {
        ...state,
        productTemplateById: {
          ...state.productTemplateById,
          data: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.GET_RELATED_PARTNER_REQUEST:
      return {
        ...state,
        partner_partners: {
          ...state.partner_partners,
          data: null,
          loading: true,
          error: null
        }
      };

    case actionTypes.GET_RELATED_PARTNER_FAILURE:
      return {
        ...state,
        partner_partners: {
          ...state.partner_partners,
          data: null,
          loading: false,
          error: action.payload
        }
      };

    case actionTypes.GET_RELATED_PARTNER_SUCCESS:
      return {
        ...state,
        partner_partners: {
          ...state.partner_partners,
          data: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.GET_ATTRIBUTE_GROUP_BYID_REQUEST:
      return {
        ...state,
        attributeGroupById: {
          error: null,
          loading: true,
          data: null
        },
        error: null
      };
    case actionTypes.GET_ATTRIBUTE_GROUP_BYID_FAILURE:
      return {
        ...state,
        attributeGroupById: {
          error: action.payload,
          loading: false,
          data: null
        }
      };
    case actionTypes.GET_ATTRIBUTE_GROUP_BYID_SUCCESS:
      return {
        ...state,
        attributeGroupById: {
          error: null,
          loading: false,
          data: action.payload
        }
      };

    case actionTypes.DELETE_ATTRIBUTE_REQUEST:
      return {
        ...state,
        attributeGroupById: {
          error: null,
          loading: true,
          isAttributeDelete: false
        },
        error: null
      };
    case actionTypes.DELETE_ATTRIBUTE_FAILURE:
      return {
        ...state,
        attributeGroupById: {
          error: action.payload,
          loading: false,
          isAttributeDelete: false
        }
      };
    case actionTypes.DELETE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        attributeGroupById: {
          error: null,
          loading: false,
          isAttributeDelete: true
        }
      };

    case actionTypes.PUBLISH_BRAND_PRODUCT_REQUEST:
      return {
        ...state,
        publish_product: {
          error: null,
          loading: true,
          isPublished: false
        },
        error: null
      };
    case actionTypes.PUBLISH_BRAND_PRODUCT_FAILURE:
      return {
        ...state,
        publish_product: {
          error: action.payload,
          loading: false,
          isPublished: false
        }
      };
    case actionTypes.PUBLISH_BRAND_PRODUCT_SUCCESS:
      return {
        ...state,
        publish_product: {
          error: null,
          loading: false,
          isPublished: true
        }
      };

    default:
      return state;
  }
};

export default brandReducer;
