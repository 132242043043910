// import axios from 'axios';
import axios from "../utils/axios.jsx";
export const getnotificationList = async (page, search) => {
  try {
    let url = "/api/notification_type/list/?page=" + page;
    if (search) {
      url = url + "&search=" + search;
    }
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const savenotification = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("/api/notification_type/create/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
