import React from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import _ from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { LoadingOverlay } from "react-overlay-loader";
import "../../style/app.css";

let AggridApi;

let agGridViewApi = null;
let agGridEditApi = null;

class assetManagmentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      columnDefs: [
        {
          headerName: "Folder",
          field: "folder_name",
          width: 200,
          resizable: true,
          sortable: true,
          cellRenderer: "group"
          // checkboxSelection: true
        },
        {
          headerName: "Files",
          field: "file_name",
          width: 200,
          resizable: true,
          sortable: true,
          checkboxSelection: function(params) {
            if (params.node && params.node.group == false) {
              return true;
            } else {
              return false;
            }
          }
        }
        // {
        //     headerName: "Actions",
        //     field: "value",
        //     cellRenderer: "actionColumns",
        //     colId: "params",
        //     width: 180
        // }
      ],

      viewColumnDefs: [
        {
          headerName: "ID",
          field: "id",
          sortable: true,
          filter: true,
          hide: true
        },

        {
          headerName: "Name",
          field: "name",
          sortable: true,
          filter: true,
          width: 200,
          suppressSizeToFit: true
        },
        {
          headerName: "Description",
          field: "description",
          sortable: true,
          filter: true,
          width: 320
        },
        {
          headerName: "Active",
          field: "is_active",
          sortable: true,
          filter: true,
          width: 200,
          suppressSizeToFit: true
        }
      ],
      rowData: null,
      rows: [],
      rowEdit: [],
      updateMapping: false,
      wholesaler: null,
      domLayout: "autoHeight",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      },
      getNodeChildDetails: function(data) {
        if (
          (data && data.sub_folder && data.sub_folder.length > 0) ||
          (data && data.file && data.file.length > 0)
        ) {
          let children = [];

          if (data.sub_folder.length > 0 && data.file.length > 0) {
            children = _.union(data.sub_folder, data.file);
          } else if (data.sub_folder.length > 0) {
            children = data.sub_folder;
          } else if (data.file.length > 0) {
            children = data.file;
          }
          return {
            group: true,
            children: children,
            expanded: data.open
          };
        } else {
          return null;
        }
      },
      selectedImages: [],
      entityData: [],
      partnersIs: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props.List, nextProps.List)) {
        this.setState(
          {
            list: nextProps && nextProps.List
          },
          () => {
            if (this.state.list && this.state.list.length > 0) {
              this.setSelectedIndexes(this.state.list);
              if (agGridEditApi) agGridEditApi.sizeColumnsToFit();
            }
          }
        );
      }
    }
  }

  componentDidMount() {
    let type = null;
    this.props.actions.getAssetListAction(
      this.props.User.user,
      this.props.User.folderId,
      type
    );
  }

  setSelectedIndexes = products => {
    this.onGridRowSelected();

    if (this.state.list) {
      return;
    }
  };
  onGridRowSelected() {
    const componentThis = this;
    agGridEditApi.forEachNode(function(node) {
      if (node.data) {
        if (componentThis.state.partnersIs.indexOf(node.data.id) > -1) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      }
    });
  }

  onGridViewReady = params => {
    this.gridApi = params.api;
    agGridViewApi = _.clone(params.api, true);
  };
  onGridEditReady = params => {
    this.gridApi = params.api;
    agGridEditApi = _.clone(params.api, true);
  };

  closeDialog = () => {
    this.props.onClose();
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    AggridApi = _.clone(this.gridApi, true);
  };

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    var length = this.state.selectedImages.length;
    var i = 0;
    const componentThis = this;
    for (i = 0; i < length; i++) {
      componentThis.state.selectedImages.pop();
    }
    let selectedElement = [];
    selectedRows.forEach(function(selectedRow, index) {
      selectedElement.push(selectedRow);
    });
    this.setState({
      selectedImages: selectedElement
    });
  }
  handleMode = () => {
    this.props.actions.getALLBrand(rolesNames.RoleMaster.BRAND);
    this.setState(
      {
        updateMapping: !this.state.updateMapping
      },
      () => {
        if (agGridEditApi) agGridEditApi.sizeColumnsToFit();
      }
    );
  };

  handleCancelMode = () => {
    this.props.close();
  };

  handleSave = () => {
    const { selectedImages } = this.state;
    console.log(this.state.selectedImages);
    let imageObj = {};
    imageObj.id = selectedImages[0].id;
    imageObj.url =
      selectedImages[0] &&
      selectedImages[0].document_object &&
      selectedImages[0].document_object.secure_url
        ? selectedImages[0].document_object.secure_url
        : "";
    imageObj.file_size = selectedImages[0].file_size;
    imageObj.original_file_name = selectedImages[0].original_file_name;
    imageObj.name = selectedImages[0].file_name;
    imageObj.is_asset = true;
    this.props.onSelection(imageObj);
  };

  renderUpdateBrand = () => {
    const { selectedTab } = this.state;
    let TabList = [
      {
        tabName: "My Folders",
        tabContent: this.renderedAssetList()
      }
    ];
    if (this.props.User.role !== rolesNames.RoleMaster.ADMIN) {
      TabList.push({
        tabName: "Public Folders",
        tabContent: this.renderedAssetList()
      });
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className="tab">
            <GridContainer>
              {_.map(TabList, (tab, index) => (
                <GridItem
                  xs={2}
                  lg={2}
                  md={2}
                  onClick={() => this.changeTab(index)}
                  className={
                    "tab-style" + (index === selectedTab ? " active-tab" : "")
                  }
                >
                  <span
                    className={
                      "tab-header" +
                      (index === selectedTab ? " active-tab-header" : "")
                    }
                  >
                    {tab.tabName}
                  </span>
                </GridItem>
              ))}
            </GridContainer>
            {TabList[selectedTab].tabContent}
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  changeTab(index) {
    this.setState(
      {
        selectedTab: index
      },
      () => {
        let type = null;
        if (this.state.selectedTab == 1) {
          type = 0;
        }
        this.props.actions.getAssetListAction(
          this.props.User.user,
          this.props.User.folderId,
          type
        );
      }
    );
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        disableBackdropClick={true}
        onClose={() => this.openModalDialog("modalContactUs", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
        className="cls-asset-popup"
      >
        <DialogTitle id="alert-dialog-title">Choose Images</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.renderUpdateBrand()}
          </DialogContentText>
          <DialogActions />
        </DialogContent>
      </Dialog>
    );
  }
  renderedAssetList() {
    const { classes } = this.props;
    const { selectedImages } = this.state;
    return (
      <LoadingOverlay>
        <div>
          <div
            id="myGrid"
            style={{
              width: "100%",
              zIndex: 10000
            }}
            className="ag-theme-material asset"
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              // rowSelection="multiple"
              defaultColDef={this.state.defaultColDef}
              statusBar={this.state.statusBar}
              // showToolPanel={true}
              // floatingFilter={true}
              sideBar={this.state.sideBar}
              onSelectionChanged={this.onSelectionChanged.bind(this)}
              pagination={true}
              paginationAutoPageSize={true}
              rowData={this.state.list}
              onGridReady={this.onGridEditReady}
              domLayout={this.state.domLayout}
              getNodeChildDetails={this.state.getNodeChildDetails}
            />
          </div>
        </div>
        <div>
          <Button
            color="info"
            disabled={selectedImages.length == 0 ? true : false}
            onClick={() => this.handleSave()}
          >
            Use Selected Image
          </Button>
          <Button onClick={() => this.handleCancelMode()}>Cancel</Button>
        </div>

        {/* <Loader loading={this.props.productTemplates.isLoading} text="Loading.." />
                        <Loader loading={this.props.productTemplates.isSaving} text="Saving.." /> */}
      </LoadingOverlay>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.assetManagementReducer.list,
  Loading: state.assetManagementReducer.loading,
  ListError: state.assetManagementReducer.error,
  User: state.authState.user
});

assetManagmentComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(assetManagmentComponent);
