import axios from "../utils/axios.jsx";

export const addUpdateSetting = async (Savedata, userId, partnerID) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    data.append("partnerid", partnerID);
    console.log(data);
    const response = await axios.post("/api/admin/settings/add-update/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSettings = async partnerID => {
  try {
    const response = await axios.get(
      "/api/admin/settings/get/?partnerid=" + partnerID,
      null,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
