// import axios from 'axios';
import axios from "../utils/axios.jsx";

export const deleteMetaData = async (userId, id) => {
  try {
    let data = new FormData();
    if (userId) data.append("userid", userId);

    if (id) data.append("id", id);

    const response = await axios.post(
      "/api/cms-v2/industry-metadata/delete/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveMetaData = async (
  userId,
  step,
  Savedata,
  partner_id,
  files
) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("step", step);
    data.append("partner_id", partner_id);

    if (Savedata.id) {
      data.append("id", Savedata.id);
    }

    data.append("partner_type", Savedata.partner_type);

    data.append("industry", JSON.stringify(Savedata.industry));
    data.append("menu", JSON.stringify(Savedata.menus));
    data.append("pages", JSON.stringify(Savedata.pages));
    data.append("components", JSON.stringify(Savedata.components));
    data.append("headers", JSON.stringify(Savedata.headers));
    data.append("footers", JSON.stringify(Savedata.footers));
    data.append("languages", JSON.stringify(Savedata.languages));
    data.append("name", Savedata.name);
    data.append("description", Savedata.description);
    data.append("active", Savedata.is_active);

    files.forEach(file => {
      data.append(file.name, file);
    });

    const response = await axios.post(
      "/api/cms-v2/industry-metadata/create/",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMetaData = async (id, partnerId, onlyActive, page, search) => {
  return new Promise((resolve, reject) => {
    try {
      let fetchUrl = "/api/cms-v2/industry-metadata/list/?1=1";
      if (id) {
        fetchUrl = fetchUrl + "&id=" + id;
      }
      if (partnerId) {
        fetchUrl = fetchUrl + "&partner_id=" + partnerId;
      }
      if (page) {
        fetchUrl = fetchUrl + "&page=" + page;
      }
      if (onlyActive) {
        fetchUrl = fetchUrl + "&is_active=1";
      }
      if (search) {
        fetchUrl = fetchUrl + "&search=" + search;
      }

      axios.get(fetchUrl).then(function(response) {
        if (response.data) {
          resolve(response.data);
        } else {
          reject("An error with get method");
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const copy = async (pageId, partnerId) => {
  try {
    let data = new FormData();
    data.append("pages", JSON.stringify(pageId));
    data.append("partnerid", partnerId);
    const response = await axios.post("/api/cms-v2/pages/copy/", data);

    return response.data;
  } catch (error) {
    throw error;
  }
};
