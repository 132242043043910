export const GET_PRODUCT_VARIANT_REQUEST = "GET_PRODUCT_VARIANT_REQUEST";
export const GET_PRODUCT_VARIANT_FAILURE = "GET_PRODUCT_VARIANT_FAILURE";
export const GET_PRODUCT_VARIANT_SUCCESS = "GET_PRODUCT_VARIANT_SUCCESS";

export const GET_PRODUCT_VARIANT_DETAIL_REQUEST = "GET_PRODUCT_VARIANT_DETAIL_REQUEST";
export const GET_PRODUCT_VARIANT_DETAIL_FAILURE = "GET_PRODUCT_VARIANT_DETAIL_FAILURE";
export const GET_PRODUCT_VARIANT_DETAIL_SUCCESS = "GET_PRODUCT_VARIANT_DETAIL_SUCCESS";

export const CREATE_PRODUCT_VARIANT_REQUEST = "CREATE_PRODUCT_VARIANT_REQUEST";
export const CREATE_PRODUCT_VARIANT_FAILURE = "CREATE_PRODUCT_VARIANT_FAILURE";
export const CREATE_PRODUCT_VARIANT_SUCCESS = "CREATE_PRODUCT_VARIANT_SUCCESS";

export const DELETE_PRODUCT_VARIANT_REQUEST = "DELETE_PRODUCT_VARIANT_REQUEST";
export const DELETE_PRODUCT_VARIANT_FAILURE = "DELETE_PRODUCT_VARIANT_FAILURE";
export const DELETE_PRODUCT_VARIANT_SUCCESS = "DELETE_PRODUCT_VARIANT_SUCCESS";
