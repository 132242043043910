import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

import { FormControlLabel, Checkbox, CircularProgress, FormLabel, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";

import Check from "@material-ui/icons/Check";

import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { ActionCreators } from "../../redux/actions";
import { validationUtils } from "../../utils";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";


class ProdcutVariantGroupView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: "",
      saveAndStay: false,
      files: [],
      isAddNew: false,
      industryList: [],
      currentStep: 0,
      language_submission: [],
      sections: [],

      variant: {
        name: "",
        description: "",
        is_active: false,
        id: "",
      },

      data: {},
      files: [],

      required: {
        name: "",
        description: "",
      },

      error: null,
      saveRequested: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && !_.isEqual(this.props, nextProps)) {

      if (nextProps.variantDetail) {
        this.setEditData(nextProps.variantDetail);
      }

      if (this.state.saveRequested) {
        if (!nextProps.basicError && !nextProps.basicSaving) {
          this.setState(
            () => ({
              saveRequested: false,
            })
          );
          this.CancelAddEdit();
        }
      }
    }
  }

  setEditData(cmpnn) {
    this.setState(
      () => ({
        ...this.state,
        variant: {
          id: cmpnn.id,
          name: cmpnn.name,
          description: cmpnn.description,
          is_active: cmpnn.is_active,
        }
      })
    );
  }

  componentDidMount() {

    if (this.props.variant_id) {
      this.props.actions.getProductVariantDetails(this.props.variant_id, this.props.partnerId);
    }

  }

  handelIsAddNew = () => {
    this.setState({
      ...this.state,

      variant: {
        id: "",
        name: "",
        description: "",
        is_active: true,
      },
      required: {
        name: "",
        description: "",
      },

      isEdit: false,
      currentStep: 0
    });
  };


  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;

    this.setState({
      ...this.state,
      required: reqData
    });

    this.setState({
      ...this.state,
      variant: {
        ...this.state.variant,
        [key]: event.target.value
      }
    });
  };

  handleToggleStatus = key => event => {
    this.setState({
      ...this.state,
      variant: {
        ...this.state.variant,
        [key]: event.target.checked
      }
    });
  };

  saveDetails = () => {
    this.setState({
      saveRequested: true
    }, () => {
      let saveData = {
        ...this.state.variant,
        partner_id: this.props.partnerId
      }
      this.props.actions.saveProductVariant(this.props.Userid, saveData);
    });
  };

  saveBasicDetails = () => {
    if (this.validateIsRequired()) {
      this.saveDetails();
    }
  };

  CancelAddEdit = () => {
    this.props.addNew(false);
  };

  render() {
    return this.renderForm();
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.variant.hasOwnProperty("name") ||
      (this.state.variant.name.trim() === "" || reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.variant.hasOwnProperty("description") ||
      (this.state.variant.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }

    this.setState({
      ...this.state,
      required: reqData
    });

    return isValid;
  }

  handleSelectChange = key => event => {
    var validData = true;
    if (!event.target.value) {
      validData = false;
    }

    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({
      ...this.state,
      required: reqData
    });

    this.setState({
      ...this.state,
      variant: {
        ...this.state.variant,
        [key]: event.target.value
      }
    });
  };

  renderForm = () => {
    const { classes } = this.props;
    const {
      name,
      description,
      is_active,
      id,
    } = this.state.variant;
    const { required } = this.state;
    return (
      <GridContainer style={{ width: "100%" }}>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange(
                        "name",
                        validationUtils.validationTypes.name
                      )
                    }}
                    id={"name_"}
                  />

                  <CustomInput
                    success={required.description === "success"}
                    error={required.description === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: this.handleTextChange("description", validationUtils.validationTypes.description)
                    }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus("is_active")}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />

                  <br />
                  <Button
                    color="info"
                    onClick={this.saveBasicDetails.bind(this)}
                  >Save
                  </Button>
                  <Button onClick={this.CancelAddEdit}>Cancel</Button>
                  <br />
                  {this.props.loadingerror && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.props.loadingerror}</code>
                    </FormLabel>
                  )}
                  {this.props.basicError && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.props.basicError}</code>
                    </FormLabel>
                  )}
                  {(this.props.basicSaving || this.props.loading) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.basicSaving} text="Saving.." />
                  <Loader loading={this.props.loading} text="Loading.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({

  basicSaving: state.productVariantState.add_product_variant.saving,
  basicError: state.productVariantState.add_product_variant.error,

  loading: state.productVariantState.product_variant_details.loading,
  loadingerror: state.productVariantState.product_variant_details.error,
  variantDetail: state.productVariantState.product_variant_details.variant,

  Userid: state.authState.user.pk,
  user: state.authState.user,
  UserId: state.authState.user.user
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProdcutVariantGroupView);
