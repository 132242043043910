import axios from "../utils/axios.jsx";

export const getProductVariant = async (variant_id, partner_id) => {
    try {

        let url = "/api/product-variant/detail?1=1"

        if (variant_id) {
            url = url + "&variant_id=" + variant_id;
        }

        if (partner_id) {
            url = url + "&partner_id=" + partner_id;
        }

        const response = await axios.get(url);
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const getProductVariants = async (partner_id, page, searchtext, isActive) => {
    try {

        let url = "/api/product-variant/list?1=1"

        if (partner_id) {
            url = url + "&partner_id=" + partner_id;
        }

        if (page) {
            url = url + "&page=" + page;
        }

        if (searchtext) {
            url = url + "&search=" + searchtext;
        }

        if (isActive) {
            url = url + "&is_active=" + true;
        }

        const response = await axios.get(url);
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const saveProductVariantService = async (userId, saveData) => {
    try {
        let data = new FormData();

        data.append("userid", userId);

        if (saveData.id)
            data.append("id", saveData.id);

        data.append("name", saveData.name);
        data.append("description", saveData.description);
        data.append("is_active", saveData.is_active);
        data.append("partner_id", saveData.partner_id);

        const response = await axios.post("/api/product-variant/create/", data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteProductVariant = async (id) => {
    try {
        let data = new FormData();
        data.append("id", id);

        const response = await axios.post("/api/product-variant/delete/", data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

