import React from 'react';
import { compose } from 'recompose';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, DialogContentText, DialogActions, Slide, CircularProgress, Button } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { ActionCreators } from "../../redux/actions";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import ListEventType from "./components/ListEventType";
import CreateEventType from "./components/CreateEventType";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";


function Transition(props) {
    return <Slide direction="down" {...props} />;
}

class EventType extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isAddNew: false,
            isEdit: false,
            isDeleted: false,
            editId: null,
        }
        this.handelIsAddNew = this.handelIsAddNew.bind(this);
        this.handelEdit = this.handelEdit.bind(this);
        this.handelDelete = this.handelDelete.bind(this);
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.eventypes) {
            this.setState({
                ...this.state,
                isEdit: false,
                isAddNew: false,
                editId: null,
            })
        }
        if (nextProps.delMsg && this.state.editId) {
            this.setState({
                ...this.state,
                isDeleted: false,
                editId: null,
            }, () => {
                this.props.actions.getEventTypeAction()
            })
        }
    }

    closeModal() {
        this.setState({
            isDeleted: false,
            isEdit: null,
        })
    }

    deleteEvent() {
        this.props.actions.deleteEventTypeAction(this.state.editId)
    }

    deleteDialog = () => {
        return (
            <Dialog
                open={this.state.isDeleted}
                disableBackdropClick={true}
                onClose={() => this.closeModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                keepMounted
            >
                <DialogContent>
                    <LoadingOverlay>
                        <DialogContentText>
                            Are you sure you want to delete this event type?
                        </DialogContentText>
                        <DialogActions>
                            <Button
                                color="primary"
                                onClick={() => this.deleteEvent()}
                            >
                                Delete
                            </Button>
                            <Button
                                onClick={() => this.closeModal()}
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                        {this.props.delLoading && (
                            <CircularProgress
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-25px",
                                    marginLeft: "-12px"
                                }}
                            />
                        )}
                        <Loader loading={this.props.delLoading} text="Saving.." />
                    </LoadingOverlay>
                </DialogContent>
            </Dialog>
        );
    };

    render() {
        const { classes } = this.props
        return (
            <GridContainer>
                {this.deleteDialog()}
                <GridItem lg={12}>
                    <Card>
                        <CardHeader>
                            <div>
                                <p className={classes.cardIconTitle + " grid-header-title"}>
                                    {this.state.isAddNew ? "Make edits to the fields below, then save changes." : "Event Type"}
                                </p>
                            </div>
                        </CardHeader>
                        <CardBody>
                            {this.state.isAddNew
                                ?
                                <CreateEventType
                                    close={this.handleClose}
                                    save={this.handleSave}
                                    editId={this.state.editId}
                                />
                                :
                                <ListEventType
                                    new={this.handelIsAddNew}
                                    edit={this.handelEdit}
                                    delete={this.handelDelete}
                                />
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }

    handelEdit = (id) => {
        this.setState({
            editId: id,
            isAddNew: true,
            isEdit: true,
        })
    }

    handelIsAddNew = (val) => {
        this.setState({
            ...this.state,
            isAddNew: val
        })
    }

    handelDelete = (id) => {
        this.setState({
            isDeleted: true,
            editId: id,
        })
    }

    handleClose = () => {
        this.setState({
            ...this.state,
            isAddNew: false,
            isEdit: false,
            editId: null,
        })
    }

    handleSave = (form) => {
        this.props.actions.saveEventTypeAction(form, this.props.UserId)
    }
}

const mapDispatchToProps = dispath => ({
    actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
    UserId: state.authState.user.pk,
    User: state.authState.user,
    eventypes: state.eventState.create_event_type.data,
    loading: state.eventState.create_event_type.loading,
    error: state.eventState.create_event_type.error,
    delMsg: state.eventState.delete_event_type.data,
    delLoading: state.eventState.delete_event_type.loading,
    delError: state.eventState.delete_event_type.error,
});

const condition = authUser =>
    authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
    withAuthorization(condition),
    withStyles({
        ...extendedTablesStyle,
    }),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(EventType);