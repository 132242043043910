export const GET_PRODUCT_ASSIGNMENTS_REQUEST =
  "get_product_assignments_request";
export const GET_PRODUCT_ASSIGNMENTS_FAILURE =
  "get_product_assignments_failure";
export const GET_PRODUCT_ASSIGNMENTS_SUCCESS =
  "get_product_assignments_success";

export const SAVE_PRODUCT_ASSIGNMENTS_REQUEST =
  "save_product_assignments_request";
export const SAVE_PRODUCT_ASSIGNMENTS_FAILURE =
  "save_product_assignments_failure";
export const SAVE_PRODUCT_ASSIGNMENTS_SUCCESS =
  "save_product_assignments_success";

export const GET_PRODUCT_BYID_REQUEST = "get_product_byid_request";
export const GET_PRODUCT_BYID_FAILURE = "get_product_byid_failure";
export const GET_PRODUCT_BYID_SUCCESS = "get_product_byid_success";

export const GET_RETAILERS_BRAND_REQUEST = "get_retailers_brand_request";
export const GET_RETAILERS_BRAND_FAILURE = "get_retailers_brand_failure";
export const GET_RETAILERS_BRAND_SUCCESS = "get_retailers_brand_success";

export const GET_BRAND_PRODUCT_BYID_REQUEST = "get_brand_product_byid_request";
export const GET_BRAND_PRODUCT_BYID_FAILURE = "get_brand_product_byid_failure";
export const GET_BRAND_PRODUCT_BYID_SUCCESS = "get_brand_product_byid_success";

export const PRODUCT_TEMPLATE_LIST_REQUEST = "product_template_list_request";
export const PRODUCT_TEMPLATE_LIST_FAILURE = "product_template_list_failure";
export const PRODUCT_TEMPLATE_LIST_SUCCESS = "product_template_list_success";

export const MARK_PRODUCT_ACTIVE_REQUEST = "mark_product_active_request";
export const MARK_PRODUCT_ACTIVE_FAILURE = "mark_product_active_failure";
export const MARK_PRODUCT_ACTIVE_SUCCESS = "mark_product_active_success";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
