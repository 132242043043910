import React from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import _ from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

// core components

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

let AggridApi;

class classComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          sortable: true,
          filter: true,
          hide: true
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          filter: true,
          checkboxSelection: true,
          suppressSizeToFit: true
        },
        {
          headerName: "Description",
          field: "description",
          sortable: true,
          filter: true
        },
        {
          headerName: "Active",
          field: "is_active",
          sortable: true,
          filter: true,
          width: 100,
          suppressSizeToFit: true
        }
      ],
      rowData: null,
      rows: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (nextProps.List && nextProps.List.length) {
          const classData = nextProps.List;
          this.setState(() => ({
            rows: classData
          }));
          if (
            nextProps.entityData &&
            nextProps.entityData.xref_classes &&
            nextProps.entityData.xref_classes.length > 0
          ) {
            let xref_classes = nextProps.entityData.xref_classes;
            let arrId = [];
            for (var i = 0; i < xref_classes.length; i++) {
              arrId.push(xref_classes[i].classes.id);
            }
            AggridApi.forEachNode(function(node) {
              if (arrId.indexOf(node.data.id) > -1) {
                node.setSelected(true);
              } else {
                node.setSelected(false);
              }
            });
          } else {
            if (nextProps.entityData && AggridApi) {
              AggridApi.forEachNode(function(node) {
                node.setSelected(false);
              });
            }
          }

          AggridApi.sizeColumnsToFit();
        }

        if (nextProps.Error && nextProps.Error.trim() !== "") {
          this.setState(() => ({
            error: nextProps.Error,
            isAddNew: true
          }));
        }
        if (nextProps.isSaved) {
          this.setState(() => ({
            isAddNew: false,
            isEdit: true
          }));
        }

        if (nextProps.isMapSaved) {
          this.props.onClose();
          this.props.actions.afertMapClassSegment();
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.List && this.props.List.length) {
      this.setState({
        classData: this.props.List
      });
    }
    this.props.actions.getClassList(this.props.PartnerId);
  }

  saveMaping = () => {
    this.props.actions.mapClassSegment(
      this.props.UserId,
      this.state.mapingData
    );
  };

  closeDialog = () => {
    this.props.onClose();
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    AggridApi = _.clone(this.gridApi, true);
  };

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    var length = this.props.selectedClassIndexes.length;
    var i = 0;
    const componentThis = this;
    for (i; i < length; i++) {
      componentThis.props.selectedClassIndexes.pop();
    }
    selectedRows.forEach(function(selectedRow, index) {
      componentThis.props.selectedClassIndexes.push(selectedRow.id);
    });
  }

  render() {
    return (
      <div>
        <div
          className="ag-theme-material"
          style={{
            height: "400px",
            width: "500px"
          }}
        >
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowSelection="multiple"
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged.bind(this)}
            pagination={true}
            paginationAutoPageSize={true}
            rowData={this.state.rows}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.classReducer.list,
  Loading: state.classReducer.loading,
  ListError: state.classReducer.error,
  isSaved: state.classReducer.isSaved,
  isDeleted: state.classReducer.isDeleted,
  UserId: state.authState.user.user,
  isMapSaved: state.SegmentsReducer.isMapSaved,
  PartnerId: state.authState.user.partner_id
});

classComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  entityData: PropTypes.object,
  selectedClassIndexes: PropTypes.array.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(classComponent);

// export default classComponent;
