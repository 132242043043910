export const TOKEN = "token";
export const SSO_TOKEN = "sso-token";
export const USERID = "userid";
export const SESSION = "session";
export const impersonating = "impersonation";

export function getToken(key) {
  return localStorage.getItem(key);
}

export function setToken(key, value) {
  return localStorage.setItem(key, value);
}

export function removeToken(key) {
  return localStorage.removeItem(key);
}
export function getHeader() {
  var header = {
    // headers: {'X-CSRF-TOKEN': getToken(USERID)}
  };
  return header;
}
