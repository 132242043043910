import React, { Component } from "react";

import { compose } from "recompose";
import EditIcon from "@material-ui/icons/Edit";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import { rolesNames } from "../../constants";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedFormStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

class OrderAction extends Component {
  constructor(props) {
    super(props);

    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
  }

  invokeParentMethod() {
    const { classes } = this.props;
    return (
      <Button size="sm" color="primary" onClick={this.edit}>
        <EditIcon className={classes.icons} /> View
      </Button>
    );
  }

  edit() {
    this.props.context.componentParent.view(this.props.data);
  }

  delete() {
    this.props.context.componentParent.delete(this.props.data._id);
  }

  render() {
    return <div>{this.invokeParentMethod()}</div>;
  }
}

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...extendedFormStyle
  })
)(OrderAction);
