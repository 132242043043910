import * as actionTypes from "./actionTypes";
import * as userServices from "../../services/userServices";

export const createUserAction = (userid, data) => async dispatch => {


  dispatch(createUserRequest());
  try {
    const success = await userServices.createUser(userid, data);
    if (success.status === "1") {
      dispatch(createUserSuccess(success.data));
    } else {
      dispatch(createUserFailure(success.error));
    }
  } catch (error) {
    dispatch(createUserFailure(error.message));
  }
};

export const getUsersListAction = (
  partnerid,
  page = 0,
  search = null
) => async dispatch => {
  dispatch(getUserListRequest());
  try {
    var success = await userServices.getUserList(partnerid, page, search);
    if (success.status && success.status === "1") {
      dispatch(getUserListSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getUserListFailure(success.error));
    } else {
      dispatch(getUserListFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getUserListFailure(error.message));
  }
};

const createUserRequest = () => {
  return {
    type: actionTypes.CREATE_USER_REQUEST
  };
};

const createUserSuccess = data => {
  return {
    type: actionTypes.CREATE_USER_SUCCESS,
    payload: data
  };
};

const createUserFailure = error => {
  return {
    type: actionTypes.CREATE_USER_FAILURE,
    payload: error
  };
};

const getUserListRequest = () => {
  return {
    type: actionTypes.GET_USERSLIST_REQUEST
  };
};

const getUserListSuccess = data => {
  return {
    type: actionTypes.GET_USERSLIST_SUCCESS,
    payload: data
  };
};

const getUserListFailure = error => {
  return {
    type: actionTypes.GET_USERSLIST_FAILURE,
    payload: error
  };
};
