import React from "react";
import _ from "lodash";
import ClearIcon from "@material-ui/icons/Clear";

import FormControl from "@material-ui/core/FormControl";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";
import ReactTooltip from "react-tooltip";
import CustomAutoComplete from "../../../components/AutoComplete";

// class ProductTypeGridLayout extends React.Component {
const ProductTypeGridLayout = props => {
  const handleAddPrimaryTabRow = () => {
    props.handleAddPrimaryTabRow(props.secondaryRowIndex);
  };

  const handlePrimaryTabRowRemove = index => {
    props.handlePrimaryTabRowRemove(index, props.secondaryRowIndex);
  };

  const handlePrimaryTabDataChange = (key, type, parent, index) => event => {
    props.handlePrimaryTabDataChange(
      key,
      type,
      parent,
      index,
      event.target.value,
      props.secondaryRowIndex
    );
  };

  const handlePrimaryTabRowChange = (key, type, index) => event => {
    props.handlePrimaryTabRowChange(
      key,
      type,
      index,
      event.target.value,
      props.secondaryRowIndex
    );
  };

  const handlePrimaryTabRowMappingFieldChange = (newval, index) => {
    props.handlePrimaryTabRowMappingFieldChange(
      newval,
      index,
      props.secondaryRowIndex
    );
  };

  const getPrimaryTabTableData = () => {
    const { required } = props;
    console.log("props.rows", props.rows);
    let rows = props.rows || [];
    const { classes } = props;

    let sorted_row = rows;
    var returnData = [];

    _.map(sorted_row, (data, index) => {
      returnData.push([
        <CustomInput
          formControlProps={{
            // fullWidth: true,
            style: { width: "50px" }
          }}
          inputProps={{
            type: "text",
            value: data.sort_order,
            onChange: handlePrimaryTabRowChange("sort_order", "number", index)
          }}
          id={"primary_tab_sort_order_" + index}
        />,
        <CustomInput
          formControlProps={{
            fullWidth: true
            // style: { width: '100px' }
          }}
          inputProps={{
            type: "text",
            value: data.label,
            onChange: handlePrimaryTabRowChange("label", "", index)
          }}
          id={"primary_tab_label_" + index}
        />,
        <CustomInput
          formControlProps={{
            fullWidth: true
            // style: { width: '150px' }
          }}
          inputProps={{
            type: "text",
            value: data.data.value,
            onChange: handlePrimaryTabDataChange("value", "", "data", index)
          }}
          id={"primary_details_tab_" + index}
        />,
        <FormControl fullWidth>
          <CustomAutoComplete
            mappingfield={props.mappingfield}
            selectedValue={data.data}
            multiple={false}
            index={index}
            selectedPath={data.data.jsonpath}
            onChange={handlePrimaryTabRowMappingFieldChange}
          />
        </FormControl>,
        <span style={{ width: "50px" }}>
          <ReactTooltip id="remove">
            <span>Remove this row</span>
          </ReactTooltip>
          <Button
            color="success"
            data-tip
            data-for="remove"
            onClick={() => handlePrimaryTabRowRemove(index)}
          >
            <ClearIcon />
          </Button>
        </span>
      ]);
    });

    return returnData;
  };

  const getPrimaryTab = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ float: "right" }}>
            <Button
              size="sm"
              color="info"
              onClick={() => handleAddPrimaryTabRow(1)}
            >
              Add Row
            </Button>
          </div>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Table
            tableShopping
            tableHead={["Sort #", "Label", "Default Value", "Mapping Field"]}
            tableData={getPrimaryTabTableData()}
          />
        </GridItem>
      </GridContainer>
    );
  };

  // render() {
  return getPrimaryTab();
  // }
};
// const mapDispatchToProps = (dispath) => ({
//     actions: bindActionCreators(ActionCreators, dispath)
// });

// const mapStateToProps = (state) => ({

// });

// export default compose(
//     withStyles({ ...regularFormsStyle, ...extendedTablesStyle, ...validationFormsStyle, ...extendedFormStyle }),
//     withRouter,
//     connect(mapStateToProps, mapDispatchToProps)
// )(ProductTypeGridLayout);

export default ProductTypeGridLayout;
