import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import { withStyles, FormControlLabel, Checkbox, FormLabel, InputLabel, TextField, Tooltip, } from "@material-ui/core";
import { Check, HelpOutlineRounded } from "@material-ui/icons";
import "../../style/app.css";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import moment from "moment";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

import MuiAlert from "@material-ui/lab/Alert";
function dateFormatter(params) {
  if (params.value) {
    return moment(params.value).format("MM/DD/YYYY h:mm a");
  } else {
    return "";
  }
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" />;
}
const initialState = {
  id: "",
  configs: {
    shipping_type: [],
    maximum_distance: '',
    intervals: '',
    // setting_type: []
  }
};

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ...initialState
      }
    };
    this.addUpdate = this.addUpdate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.List) {
        this.setState({
          ...this.state,
          data: nextProps.List
        });
      }
    }
  }

  componentDidMount() {
    this.props.actions.getSettingsAction(this.props.PartnerID);
  }

  render() {
    return <div>{this.renderOrderTable()}</div>;
  }

  renderOrderTable = () => {
    const { classes } = this.props;
    const { configs } = this.state.data;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Settings
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem md={2} lg={2} className="label_alignment">
                  <InputLabel className={classes.label + " "} labelText="Name">
                    Shipping Type
                    <Tooltip
                      className="control-tooltip"
                      title="Used for checkout option"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>

                </GridItem>
                <GridItem md={10} lg={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={
                          configs.shipping_type.indexOf(1) > -1 ? true : false
                        }
                        onClick={() => {
                          this.handleToggleStatus(1);
                        }}
                        checkedIcon={
                          <Check className={classes.checkedIcon} />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Pickup"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={
                          configs.shipping_type.indexOf(2) > -1 ? true : false
                        }
                        onClick={() => {
                          this.handleToggleStatus(2);
                        }}
                        checkedIcon={
                          <Check className={classes.checkedIcon} />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Delivery"
                  />
                </GridItem>
                <GridItem lg={12} md={12}>
                  <GridContainer>
                    <GridItem lg={2} md={2}>
                      <InputLabel className={classes.label + " label_alignment"}>
                        Maximum Distance (in miles)
                        <Tooltip
                          className="control-tooltip"
                          title="This distance is used in locator page for making the distance filters"
                        >
                          <HelpOutlineRounded />
                        </Tooltip>
                      </InputLabel>
                    </GridItem>
                    <GridItem lg={6} md={6}>
                      <TextField
                        value={configs.max_distance ? configs.max_distance : ""}
                        name="max_distance"
                        fullWidth
                        placeholder="Maximum Distance"
                        classes={{
                          root: "attribute-txtbox"
                        }}
                        onChange={(event) => this.handleTextChange(event)}
                      />
                    </GridItem>
                    <GridItem lg={4} md={4}></GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem lg={12} md={12}>
                  <GridContainer>
                    <GridItem lg={2} md={2}>
                      <InputLabel className={classes.label + " label_alignment margin-auto"}>
                        Intervals
                        <Tooltip
                          className="control-tooltip"
                          title="Used for Making distance filter intervals"
                        >
                          <HelpOutlineRounded />
                        </Tooltip>
                      </InputLabel>
                    </GridItem>
                    <GridItem lg={6} md={6}>
                      <TextField
                        value={configs.intervals ? configs.intervals : ""}
                        name="intervals"
                        fullWidth
                        placeholder="Intervals"
                        classes={{
                          root: "attribute-txtbox"
                        }}
                        onChange={(event) => this.handleTextChange(event)}
                      />
                    </GridItem>
                    <GridItem lg={4} md={4}></GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <br />
              {this.props.Error ||
                (this.props.isSaved && (
                  <GridItem md={12} lg={6} style={{}}>
                    {this.props.Error && (
                      <FormLabel
                        className={classes.labelLeftHorizontal}
                        style={{ color: "#f44336" }}
                      >
                        <cord> {this.props.Error}</cord>
                      </FormLabel>
                    )}

                    {this.props.isSaved && (
                      <FormLabel
                        className={classes.labelLeftHorizontal}
                        style={{ color: "#4caf50" }}
                      >
                        Shipping type saved successfully
                      </FormLabel>
                    )}
                  </GridItem>
                ))}
            </CardBody>
            <CardFooter>
              <Button onClick={this.addUpdate}>Save Changes</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer >
    );
  };

  handleTextChange = (event) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        configs: {
          ...this.state.data.configs,
          [event.target.name]: event.target.value
        }
      }
    })
  }

  handleToggleStatus = value => {
    let { shipping_type } = this.state.data.configs;

    let index = shipping_type.indexOf(value);

    if (index == -1) {
      shipping_type.push(value);
    } else {
      shipping_type.splice(index, 1);
    }

    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          configs: {
            ...this.state.data.configs,
            shipping_type: shipping_type
          }
        }
      },
      () => {
        console.log(this.state.data);
      }
    );
  };

  addUpdate() {
    this.props.actions.addUpdateSettingsAction(
      this.state.data,
      this.props.User,
      this.props.PartnerID
    );
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  User: state.authState.user.pk,
  PartnerID: state.authState.user.partner_id,
  List: state.settingReducer.list,
  Loading: state.settingReducer.loading,
  Error: state.settingReducer.error,
  isSaved: state.settingReducer.isSaved
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER ||
  rolesNames.RoleMaster.BRAND;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Settings);
