import * as tagsTypeService from "../../services/tagsTypeService";
import * as actionTypes from "./actionTypes";

export const createTagType = (id, data) => async dispatch => {
  dispatch(createTagTypeRequest());
  try {
    const success = await tagsTypeService.createTagType(id, data);
    if (success.status === "1") {
      dispatch(createTagTypeSuccess(success.data));
    } else {
      dispatch(createTagTypeFailure(success.error));
    }
  } catch (error) {
    dispatch(createTagTypeFailure(error.message));
  }
};

export const getTagTypeList = role => async dispatch => {
  dispatch(getTagTypeRequest());
  try {
    var success = await tagsTypeService.getTagTypeList(role);
    if (success.status && success.status === "1") {
      dispatch(getTagTypeSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getTagTypeFailure(success.error));
    } else {
      dispatch(getTagTypeFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getTagTypeFailure(error.message));
  }
};

const createTagTypeRequest = () => {
  return {
    type: actionTypes.CREATE_TAGSTYPE_REQUEST
  };
};

const createTagTypeSuccess = data => {
  return {
    type: actionTypes.CREATE_TAGSTYPE_SUCCESS,
    payload: data
  };
};

const createTagTypeFailure = error => {
  return {
    type: actionTypes.CREATE_TAGSTYPE_FAILURE,
    payload: error
  };
};

const getTagTypeRequest = () => {
  return {
    type: actionTypes.GET_TAGSTYPE_REQUEST
  };
};

const getTagTypeSuccess = data => {
  return {
    type: actionTypes.GET_TAGSTYPE_SUCCESS,
    payload: data
  };
};

const getTagTypeFailure = error => {
  return {
    type: actionTypes.GET_TAGSTYPE_FAILURE,
    payload: error
  };
};
