import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";

// core components
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Slide from "@material-ui/core/Slide";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import InputLabel from "@material-ui/core/InputLabel";

import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineRounded from "@material-ui/icons/HelpOutlineRounded";
import AddIcon from "@material-ui/icons/Add";

const attributeTableRow = {
  id: "",
  name: "",
  attribute_value: ""
};
function Transition(props) {
  return <Slide direction="down" {...props} />;
}
class ProductSegmentCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      classlist: [],
      segmentlist: [],
      attributegrouplist: [],
      attributeRow: {
        ...attributeTableRow
      },
      isNewCategory: false,
      category: {
        name: "",
        attribute_group: []
      },
      isNewAtrributeGroup: false,
      attribute_group: {
        name: "",
        attributes: []
      },
      categoryData: {
        name: "",
        attribute_group: []
      },
      attributeGroupData: {
        name: "",
        attributes: []
      },
      columns: [
        { title: "Name", field: "name" },
        { title: "Value", field: "description" }
      ]
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleCategoryNameChange = this.handleCategoryNameChange.bind(this);
    this.handleAttributeGroupChange = this.handleAttributeGroupChange.bind(
      this
    );
    this.handleAttributeGroupNameChange = this.handleAttributeGroupNameChange.bind(
      this
    );
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.onRowAdd = this.onRowAdd.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        this.setState({
          data: nextProps.data
        });
        if (nextProps.ClassList) {
          let { classes } = this.state.data;
          if (
            this.props.ClassList &&
            !_.isEqual(nextProps.ClassList, this.props.ClassList)
          ) {
            let newclass = _.difference(
              nextProps.ClassList,
              this.props.ClassList
            );
            for (let attr_index in newclass) {
              const index = classes.findIndex(
                x => x.id == newclass[attr_index].id
              );
              if (index == -1) {
                classes.push(newclass[attr_index]);
              }
            }
            this.props.setProductData("classes", classes);
          }
          let classlist = nextProps.ClassList;
          classlist.sort(function(a, b) {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          this.setState({
            classlist: classlist
          });
        }
        if (nextProps.SegmentList) {
          let list = nextProps.SegmentList;
          list.sort(function(a, b) {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          this.setState({
            segmentlist: list
          });
        }
        if (nextProps.AttributeGroup) {
          let { attribute_group } = this.state.categoryData;
          if (this.props.AttributeGroup) {
            let newattributegroup = _.difference(
              nextProps.AttributeGroup,
              this.props.AttributeGroup
            );

            for (let attr_index in newattributegroup) {
              const index = attribute_group.findIndex(
                x => x.id == newattributegroup[attr_index].id
              );
              if (index == -1) {
                attribute_group.push(newattributegroup[attr_index]);
              }
            }
          }
          this.setState({
            attributegrouplist: nextProps.AttributeGroup,
            categoryData: {
              ...this.state.categoryData,
              attribute_group: attribute_group
            }
          });
        }
      }
    }
  }

  componentWillUpdate() {
    // this.props.actions.getSegmentsList(this.props.UserId);
    // this.props.actions.getClassList(this.props.PartnerId);
    // if (this.props.data) {
    //     this.setState({
    //         data: this.props.data
    //     })
    // }
  }

  componentDidMount() {
    this.props.actions.getSegmentsList(this.props.UserId);
    this.props.actions.getClassList(this.props.PartnerId);
    if (this.props.data) {
      this.setState({
        data: this.props.data
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { classlist, segmentlist } = this.state;
    const { categories, segments } = this.state.data;
    const classarray =
      this.state.data && this.state.data.classes ? this.state.data.classes : [];
    return (
      <Grid>
        {this.addCategoryDialog()}
        <GridContainer>
          <GridItem lg={1} md={1} />
          <GridItem lg={10} md={10} sm={12} xs={12}>
            <form>
              <GridContainer className="grid-container-control">
                <GridItem lg={12} md={12} className="griditem-control">
                  <InputLabel className={classes.label + " "}>
                    Segment
                    <Tooltip
                      className="control-tooltip"
                      title="Please select or Add new Segment"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>
                  <br />
                  <Autocomplete
                    multiple
                    options={segmentlist}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    value={segments && segments.length > 0 ? segments : []}
                    onChange={(event, value) =>
                      this.handleSegmentChange("segments", value)
                    }
                    classes={{
                      root: "custom-autocomplete",
                      inputRoot: "custom-autocomplete-input-root",
                      tag: "custom-autocomplete-tag"
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Segment"
                      />
                    )}
                  />
                </GridItem>
                <GridItem lg={12} md={12} className="griditem-control">
                  <InputLabel className={classes.label + " "}>
                    Classes
                    <Tooltip
                      className="control-tooltip"
                      title="Please select or Add new Classes"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>
                  <br />
                  <Autocomplete
                    multiple
                    options={classlist}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    value={
                      classarray && classarray.length > 0 ? classarray : []
                    }
                    onChange={(event, value) =>
                      this.handleCategoryChange(value)
                    }
                    classes={{
                      root: "custom-autocomplete",
                      inputRoot: "custom-autocomplete-input-root",
                      tag: "custom-autocomplete-tag"
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Classes"
                      />
                    )}
                  />
                  {/* <GridContainer>
                                        <GridItem  lg={9} md={9}>
                                            <Autocomplete
                                                multiple
                                                options={classlist}
                                                getOptionLabel={(option) => option.name}
                                                filterSelectedOptions
                                                value={categories && categories.length>0 ? categories : []}
                                                onChange={(event, value)=>this.handleCategoryChange(value)}
                                                classes={{
                                                    root: 'custom-autocomplete',
                                                    inputRoot: 'custom-autocomplete-input-root',
                                                    tag: 'custom-autocomplete-tag'
                                                }}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Categories"
                                                />
                                                )}
                                            />
                                        </GridItem>
                                        <GridItem  lg={3} md={3}>
                                        <Button color="success" data-tip data-for='editInd' className={classes.actionButton} style={{float: 'right', margin: '0px', padding:'10px'}} onClick={() => this.handelNewCategory()}>
                                                <AddIcon className={classes.icons} /> New Category
                                            </Button>
                                        </GridItem>
                                        
                                    </GridContainer> */}
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
          <GridItem lg={1} md={1} />
        </GridContainer>
      </Grid>
    );
  }

  handelNewCategory = () => {
    this.props.actions.getAttributeGroups();
    this.setState({
      isNewCategory: true
    });
  };

  handleSegmentChange = (name, value) => {
    this.props.setProductData(name, value);
  };

  handleSave = row => {
    const newData = [...this.state.data.attributes];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.props.setProductData("attributes", newData);
  };

  addCategoryDialog() {
    const { classes } = this.props;
    const { name, attribute_group } = this.state.categoryData;
    const { attributegrouplist } = this.state;
    return (
      <Dialog
        open={this.state.isNewCategory}
        disableBackdropClick={true}
        onClose={() => this.props.closeCategoryDilog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              <GridContainer>
                {this.AddAttribute()}
                <GridItem lg={12} md={12}>
                  <InputLabel className={classes.label + " "}>Name</InputLabel>
                  <br />
                  <TextField
                    value={name}
                    variant="outlined"
                    fullWidth
                    name="name"
                    classes={{
                      root: "attribute-txtbox"
                    }}
                    onChange={this.handleCategoryNameChange()}
                  />
                </GridItem>
                <GridItem lg={12} md={12}>
                  <InputLabel className={classes.label + " "}>
                    Attribute Group
                    <Tooltip
                      className="control-tooltip"
                      title="Please select or Add Attribute Group"
                    >
                      <HelpOutlineRounded />
                    </Tooltip>
                  </InputLabel>
                  <br />
                  <GridContainer>
                    <GridItem lg={9} md={9}>
                      <Autocomplete
                        multiple
                        options={attributegrouplist}
                        getOptionLabel={option => option.name}
                        filterSelectedOptions
                        value={
                          attribute_group && attribute_group.length > 0
                            ? attribute_group
                            : []
                        }
                        onChange={(event, value) =>
                          this.handleAttributeGroupChange(value)
                        }
                        classes={{
                          root: "custom-autocomplete",
                          inputRoot: "custom-autocomplete-input-root",
                          tag: "custom-autocomplete-tag"
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Attribute Group"
                          />
                        )}
                      />
                    </GridItem>
                    <GridItem lg={3} md={3}>
                      <Button
                        color="success"
                        data-tip
                        data-for="editInd"
                        className={classes.actionButton}
                        style={{ float: "right", margin: "0px" }}
                        onClick={() => this.handelNewAttributeGroup()}
                      >
                        <AddIcon className={classes.icons} />
                        Group
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.saveCategoryDilog()}
              >
                Save
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.closeCategoryDilog()}
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  }

  setValue(selectedValues) {
    console.log("selected_array: ", selectedValues);
    let returnvalue = [];
    if (selectedValues && selectedValues.length > 0) {
      returnvalue = [...new Set(selectedValues.map(x => x.name))];
    }
    console.log("returnvalue: ", returnvalue);
    return returnvalue;
  }

  handleCategoryNameChange = () => event => {
    this.setState({
      ...this.state,
      categoryData: {
        ...this.state.categoryData,
        name: event.target.value
      }
    });
  };

  handleAttributeGroupChange = value => {
    let { attribute_group } = this.state.categoryData;
    attribute_group.push(value);
    this.setState({
      ...this.state,
      categoryData: {
        ...this.state.categoryData,
        attribute_group: value
      }
    });
  };

  handelNewAttributeGroup() {
    this.setState({
      isNewAtrributeGroup: true
    });
  }

  AddAttribute() {
    const { classes } = this.props;
    const { name, attributes } = this.state.attributeGroupData;
    return (
      <Dialog
        open={this.state.isNewAtrributeGroup}
        disableBackdropClick={true}
        onClose={() => this.closeAttributeGroups()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              <GridContainer>
                <GridItem lg={12} md={12}>
                  <InputLabel className={classes.label + " "}>Name</InputLabel>
                  <br />
                  <TextField
                    value={name}
                    variant="outlined"
                    fullWidth
                    classes={{
                      root: "attribute-txtbox"
                    }}
                    onChange={this.handleAttributeGroupNameChange()}
                  />
                </GridItem>
                <GridItem
                  lg={12}
                  md={12}
                  style={{
                    marginTop: "10px"
                  }}
                >
                  <InputLabel className={classes.label + " "}>
                    Attributes
                  </InputLabel>
                  <br />
                  <MaterialTable
                    className="editable-table"
                    title="Attribute"
                    columns={this.state.columns}
                    style={{
                      width: "100%"
                    }}
                    data={attributes}
                    editable={{
                      onRowAdd: newData => this.onRowAdd(newData),
                      onRowUpdate: (newData, oldData) =>
                        this.onRowUpdate(newData, oldData),
                      onRowDelete: oldData => this.onRowDelete(oldData)
                    }}
                    options={{
                      headerStyle: {
                        padding: "10px"
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.saveAttributeGroups()}
              >
                Save
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.closeAttributeGroups()}
                color="primary"
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  }

  handleAttributeGroupNameChange = () => event => {
    this.setState({
      ...this.state,
      attributeGroupData: {
        ...this.state.attributeGroupData,
        name: event.target.value
      }
    });
  };

  onRowAdd = newData => {
    let self = this;
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
        console.log(self);
        const data = [...this.state.attributeGroupData.attributes];
        data.push(newData);
        this.setState({
          ...this.state,
          attributeGroupData: {
            ...this.state.attributeGroupData,
            attributes: data
          }
        });
      }, 600);
    });
  };

  onRowUpdate = (newData, oldData) => {
    let self = this;
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
        const data = [...this.state.attributeGroupData.attributes];
        data[data.indexOf(oldData)] = newData;
        if (oldData) {
          this.setState({
            ...this.state,
            attributeGroupData: {
              ...this.state.attributeGroupData,
              attributes: data
            }
          });
        }
      }, 600);
    });
  };

  onRowDelete = oldData => {
    let self = this;
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
        const data = [...this.state.attributeGroupData.attributes];
        data.splice(data.indexOf(oldData), 1);
        this.setState({
          ...this.state,
          attributeGroupData: {
            ...this.state.attributeGroupData,
            attributes: data
          }
        });
      }, 600);
    });
  };

  closeAttributeGroups = () => {
    this.setState({
      isNewAtrributeGroup: false,
      attributeGroupData: {
        name: "",
        attributes: []
      }
    });
  };

  saveAttributeGroups = () => {
    let data = _.clone(this.state.attributeGroupData);
    data.partner_id = this.props.PartnerId;
    data.product_template_id =
      this.state.data && this.state.data.id ? this.state.data.id : null;
    this.props.actions.SaveAttributeGroupAction(this.props.UserId, data);
    this.closeAttributeGroups();
  };

  saveCategoryDilog = () => {
    let data = _.clone(this.state.categoryData);
    data.partner_id = this.props.PartnerId;
    this.props.actions.saveCategoyAction(this.props.UserId, data);
    this.closeCategoryDilog();
  };

  closeCategoryDilog = () => {
    this.setState({
      isNewCategory: false,
      categoryData: {
        name: "",
        attribute_group: []
      }
    });
  };

  handleCategoryChange(value) {
    this.props.setProductData("classes", value);
    console.log(`Selected: ${value}`);
  }

  handleTextChange = index => event => {
    let { attributes } = this.state.data;
    attributes[index][event.target.name] = event.target.value;
    this.props.setProductData("attributes", attributes);
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  SegmentList: state.SegmentsReducer.list,
  ClassList: state.classReducer.list,
  UserId: state.authState.user.pk,
  User: state.authState.user.user,
  PartnerId: state.authState.user.partner_id,
  AttributeGroup: state.brandState.attributeGroups.attributeGroups,
  newAttributeGroup: state.brandState.attributeGroups.data,
  newClass: state.classReducer.data
});

ProductSegmentCategory.propTypes = {
  data: PropTypes.object.isRequired,
  editState: PropTypes.bool.isRequired
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductSegmentCategory);
