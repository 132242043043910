export const GET_CONTENT_REQUEST = "get_content_request";
export const GET_CONTENT_FAILURE = "get_content_failure";
export const GET_CONTENT_SUCCESS = "get_content_success";

export const CREATE_CONTENT_REQUEST = "create_content_request";
export const CREATE_CONTENT_FAILURE = "create_content_failure";
export const CREATE_CONTENT_SUCCESS = "create_content_success";

export const GET_COMPONENT_REQUEST = "get_component_request";
export const GET_COMPONENT_FAILURE = "get_component_failure";
export const GET_COMPONENT_SUCCESS = "get_component_success";

export const SAVE_COMPONENT_REQUEST = "SAVE_COMPONENT_REQUEST";
export const SAVE_COMPONENT_FAILURE = "SAVE_COMPONENT_FAILURE";
export const SAVE_COMPONENT_SUCCESS = "SAVE_COMPONENT_SUCCESS";

export const DELETE_COMPONENT_REQUEST = "DELETE_COMPONENT_REQUEST";
export const DELETE_COMPONENT_FAILURE = "DELETE_COMPONENT_FAILURE";
export const DELETE_COMPONENT_SUCCESS = "DELETE_COMPONENT_SUCCESS";

export const COMPONENT_DETAIL_REQUEST = "COMPONENT_DETAIL_REQUEST";
export const COMPONENT_DETAIL_FAILURE = "COMPONENT_DETAIL_FAILURE";
export const COMPONENT_DETAIL_SUCCESS = "COMPONENT_DETAIL_SUCCESS";

export const GET_PAGE_REQUEST = "GET_PAGE_REQUEST";
export const GET_PAGE_FAILURE = "GET_PAGE_FAILURE";
export const GET_PAGE_SUCCESS = "GET_PAGE_SUCCESS";

export const SAVE_PAGE_REQUEST = "SAVE_PAGE_REQUEST";
export const SAVE_PAGE_FAILURE = "SAVE_PAGE_FAILURE";
export const SAVE_PAGE_SUCCESS = "SAVE_PAGE_SUCCESS";

export const PAGE_DETAIL_REQUEST = "PAGE_DETAIL_REQUEST";
export const PAGE_DETAIL_FAILURE = "PAGE_DETAIL_FAILURE";
export const PAGE_DETAIL_SUCCESS = "PAGE_DETAIL_SUCCESS";

export const RESET_PAGE_DATA = "RESET_PAGE_DATA";

export const GET_CMS_MENU_REQUEST = "get_cms_menu_request";
export const GET_CMS_MENU_FAILURE = "get_cms_menu_failure";
export const GET_CMS_MENU_SUCCESS = "get_cms_menu_success";

export const SAVE_CMS_MENU_REQUEST = "save_cms_menu_request";
export const SAVE_CMS_MENU_FAILURE = "save_cms_menu_failure";
export const SAVE_CMS_MENU_SUCCESS = "save_cms_menu_success";

export const GET_INDUSTRY_PAGE_GROUP_REQUEST =
  "GET_INDUSTRY_PAGE_GROUP_REQUEST";
export const GET_INDUSTRY_PAGE_GROUP_FAILURE =
  "GET_INDUSTRY_PAGE_GROUP_FAILURE";
export const GET_INDUSTRY_PAGE_GROUP_SUCCESS =
  "GET_INDUSTRY_PAGE_GROUP_SUCCESS";

export const GET_BANNER_REQUEST = "GET_BANNER_REQUEST";
export const GET_BANNER_FAILURE = "GET_BANNER_FAILURE";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";

export const SAVE_BANNER_REQUEST = "SAVE_BANNER_REQUEST";
export const SAVE_BANNER_FAILURE = "SAVE_BANNER_FAILURE";
export const SAVE_BANNER_SUCCESS = "SAVE_BANNER_SUCCESS";

export const BANNER_DETAIL_REQUEST = "BANNER_DETAIL_REQUEST";
export const BANNER_DETAIL_FAILURE = "BANNER_DETAIL_FAILURE";
export const BANNER_DETAIL_SUCCESS = "BANNER_DETAIL_SUCCESS";

export const RESET_BANNER_DATA = "RESET_BANNER_DATA";

export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_FAILURE = "DELETE_BANNER_FAILURE";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";

export const CLONE_CMS_PAGES_REQUEST = "CLONE_CMS_PAGES_REQUEST";
export const CLONE_CMS_PAGES_FAILURE = "CLONE_CMS_PAGES_FAILURE";
export const CLONE_CMS_PAGES_SUCCESS = "CLONE_CMS_PAGES_SUCCESS";

export const DELETE_PAGE_REQUEST = "DELETE_PAGE_REQUEST";
export const DELETE_PAGE_FAILURE = "DELETE_PAGE_FAILURE";
export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS";

export const GET_PARTNER_LIST_REQUEST = "GET_PARTNER_LIST_REQUEST";
export const GET_PARTNER_LIST_FAILURE = "GET_PARTNER_LIST_FAILURE";
export const GET_PARTNER_LIST_SUCCESS = "GET_PARTNER_LIST_SUCCESS";

export const CLONE_PACKAGE_REQUEST = "CLONE_PACKAGE_REQUEST";
export const CLONE_PACKAGE_FAILURE = "CLONE_PACKAGE_FAILURE";
export const CLONE_PACKAGE_SUCCESS = "CLONE_PACKAGE_SUCCESS";
