import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import InputLabel from "@material-ui/core/InputLabel";

import FormControl from "@material-ui/core/FormControl";
import Table from "components/Table/Table.jsx";
import AddIcon from "@material-ui/icons/Add";
import "antd/dist/antd.css";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const ColumnJson = {
  caption: "",
  url: []
};
const ColumnUrl = {
  url: "",
  title: "",
  description: "",
  sort_order: "",
  status: true,
  newtab: false,
  other: false
};

class Footer extends React.Component {
  constructor(props) {
    super(props);
    // this.handleChange=this.handleChange.bind(this)
    this.state = {
      isAddNew: false,
      cmsMenuList: [],
      menuData: [],
      isOther: null,
      isDelete: false,
      columnDefs: [
        {
          headerName: "Title",
          field: "title",
          width: 150,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "url",
          field: "url",
          width: 150,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },

        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 100,
          filter: false
        }
      ]
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps &&
      nextProps.menuList &&
      nextProps.menuList.list &&
      nextProps.menuList.list.length > 0
    ) {
      this.setUrlList(nextProps.menuList.list);
    }

    if (nextProps && nextProps.List && nextProps.List.data) {
      this.setState({
        menuData: nextProps.List.data
      });
    } else {
      this.setState({
        menuData: [ColumnJson]
      });
    }
  }
  setUrlList = list => {
    let cmsLink = list.filter(element => element.url != "");
    this.setState({
      cmsMenuList: cmsLink
    });
  };
  componentDidMount() {
    this.props.actions.getCMSMenu(this.props.authUser.partner_id, "footer");
    this.props.actions.CMSMenuList(this.props.authUser.partner_id, "CMS");
  }

  handelIsAddNew = () => {
    this.setState({
      isAddNew: true
    });
  };

  handleChange = (key, columnIndex, rowindex) => event => {
    let footerData = _.clone(this.state.menuData);
    footerData[columnIndex].url[rowindex].url = event.target.value;
    this.setState({
      menuData: footerData
    });
  };

  handelIsAddNewColumn = () => {
    let curretList = _.clone(this.state.menuData);

    const ColumnJsonObj = {
      caption: "",
      url: []
    };
    this.setState({
      menuData: [...curretList, ColumnJsonObj]
    });
  };

  onSelectChange = (key, columnIndex, rowindex) => event => {
    let footerData = _.clone(this.state.menuData);
    console.log(footerData)
    if (key == "url") {
      if (event.target.value == "other") {
        footerData[columnIndex].url[rowindex].other = true;
        footerData[columnIndex].url[rowindex].url = "";
        footerData[columnIndex].url[rowindex].title = event.currentTarget.title;
      } else {
        footerData[columnIndex].url[rowindex].other = false;
        footerData[columnIndex].url[rowindex].url = event.target.value;
        footerData[columnIndex].url[rowindex].title = event.currentTarget.title;
      }
    } else if (key == "title") {
      footerData[columnIndex].url[rowindex].title = event.target.value;
    }
    this.setState({
      menuData: footerData
    });
  };

  onHandleCaption = (key, columnIndex) => event => {
    let footerData = _.clone(this.state.menuData);
    footerData[columnIndex].caption = event.target.value;
    this.setState({
      menuData: footerData
    });
  };

  handleToggleStatus = (key, columnIndex, rowindex) => event => {
    let footerData = _.clone(this.state.menuData);
    if (key == "status") {
      footerData[columnIndex].url[rowindex].status = event.target.checked;
    } else if (key == "newtab") {
      footerData[columnIndex].url[rowindex].newtab = event.target.checked;
    }
    this.setState({
      menuData: footerData
    });
  };

  onHandleDelete = (columnIndex, index) => {
    let footerData = _.clone(this.state.menuData);

    footerData[columnIndex].url.splice(index, 1);
    this.setState({
      menuData: footerData
    });
  };

  deleteDilog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.isDelete}
        disableBackdropClick={true}
        onClose={() => this.closeIndustriesComponentDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete?{" "}
          </DialogContentText>
          <DialogActions>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              color="primary"
              onClick={() => this.onHandleDelete()}
            >
              Delete
            </Button>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              onClick={() => this.deleteDialog(false, "", "")}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  };

  getPrimaryTabTableData = (UrlData, columnIndex) => {
    const { classes } = this.props;
    var returnData = [];
    _.map(UrlData, (data, index) => {
      returnData.push([
        <FormControl fullWidth name="Component">
          <Select
            style={{ width: "100%" }}
            onChange={this.onSelectChange("url", columnIndex, index)}
            value={data.url}
            variant="outlined"
            classes={{
              root: "select-control-root"
            }}
          >
            <MenuItem disabled />
            <MenuItem
              classes={{
                root: classes.selectMenuItem
              }}
              style={{
                opacity: "0.8"
              }}
              value={"other"}
            >
              Other Url
            </MenuItem>
            {_.map(this.state.cmsMenuList, ({ id, name, url }, index) => {
              return (
                <MenuItem
                  value={url}
                  key={index + "_" + id}
                  name={name}
                  id={name}
                  title={name}
                >
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>,
        <TextField
          value={data.title}
          variant="outlined"
          fullWidth
          classes={{
            root: "attribute-txtbox"
          }}
          onChange={this.onSelectChange("title", columnIndex, index)}
        />,
        !data.other ? (
          <span>{data.url}</span>
        ) : (
          <TextField
            value={data.url}
            variant="outlined"
            fullWidth
            classes={{
              root: "attribute-txtbox"
            }}
            onChange={this.handleChange("url", columnIndex, index)}
          />
        ),
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              checked={data.status}
              onClick={this.handleToggleStatus("status", columnIndex, index)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.label }}
        />,
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              checked={data.newtab}
              onClick={this.handleToggleStatus("newtab", columnIndex, index)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.label }}
        />,
        <span style={{ width: "50px" }}>
          <DeleteIcon
            title="Remove"
            onClick={() => this.onHandleDelete(columnIndex, index)}
          />
        </span>
      ]);
    });

    return returnData;
  };

  handleAddPrimaryTabRow = index => {
    let coumnValue = _.clone(this.state.menuData);
    let ColumnUrls = _.clone(ColumnUrl);
    coumnValue[index].url.push(ColumnUrls);
    this.setState({
      menuData: coumnValue
    });
  };
  getPrimaryTab = (data, columnIndex) => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ float: "right" }}>
            <Button
              size="sm"
              color="info"
              onClick={() => this.handleAddPrimaryTabRow(columnIndex)}
            >
              Add Menu
            </Button>
          </div>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Table
            tableShopping
            tableHead={["Select Url", "Title", "URL", "Status", "New Tab", ""]}
            tableData={this.getPrimaryTabTableData(data, columnIndex)}
          />
        </GridItem>
      </GridContainer>
    );
  };
  saveCMSMenu = () => {
    this.props.actions.saveCMSMenu(
      this.state.menuData,
      this.props.authUser.partner_id,
      "footer"
    );
  };
  onHandleSaveColumn = index => {
    let footerData = _.clone(this.state.menuData);

    footerData.splice(index, 1);
    this.setState({
      menuData: footerData
    });
  };

  render() {
    console.log(this.state.menuData);
    const { classes } = this.props;

    return (
      <div>
        <LoadingOverlay>
          <GridContainer>
            {this.deleteDilog()}
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <div>
                    <p className={classes.cardIconTitle + " grid-header-title"}>
                      CMS Menu{" "}
                    </p>
                    <p>
                      <Button
                        disabled={this.props.Loading}
                        color="success"
                        onClick={this.handelIsAddNewColumn}
                        size="sm"
                        className={classes.marginRight + " grid-header-button"}
                      >
                        <AddIcon className="grid-header-button-icon" /> Add
                        Column
                      </Button>
                    </p>
                  </div>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    {this.state.menuData.map((prop, index) => (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                          <CardHeader color="rose" icon>
                            <div>
                              <p
                                className={
                                  classes.cardIconTitle + " grid-header-title"
                                }
                              >
                                Column {index + 1}{" "}
                              </p>
                              <p>
                                <div
                                  style={{ color: "black" }}
                                  className={
                                    classes.marginRight + " grid-header-button"
                                  }
                                  onClick={() => this.onHandleSaveColumn(index)}
                                >
                                  <CloseIcon />
                                </div>
                              </p>
                            </div>
                          </CardHeader>
                          <CardBody>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <InputLabel className={classes.label + " "}>
                                Title
                              </InputLabel>

                              <br />
                              <TextField
                                value={prop.caption}
                                variant="outlined"
                                fullWidth
                                classes={{
                                  root: "attribute-txtbox"
                                }}
                                onChange={this.onHandleCaption(
                                  "caption",
                                  index
                                )}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12} />
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              {this.getPrimaryTab(prop.footer_url, index)}
                            </GridItem>
                          </CardBody>
                        </Card>
                      </GridItem>
                    ))}{" "}
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <Button color="info" onClick={() => this.saveCMSMenu()}>
                        Save
                      </Button>
                      <Button onClick={() => this.handelIsAddNew(false)}>
                        Cancel
                      </Button>
                    </GridItem>
                  </GridContainer>

                  {this.props.isSaved && (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={6}>
                        <Alert severity="success">
                          CMS menu saved successfully
                        </Alert>
                      </GridItem>
                    </GridContainer>
                  )}

                  {this.props.loading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.loading} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UserId: state.authState.user.user,
  PartnerId: state.authState.user.partner_id,
  authUser: state.authState.user,
  menuList: state.partnerMenuReducer.get_cms_menu_list,
  List: state.CMSContentReducer.cms_menu.list,
  Loading: state.CMSContentReducer.cms_menu.loading,
  Error: state.CMSContentReducer.cms_menu.error,

  isSaved: state.CMSContentReducer.add_cms_menu.isSaved,
  loading: state.CMSContentReducer.add_cms_menu.loading,
  error: state.CMSContentReducer.add_cms_menu.error,
  SuccessMessage: state.CMSContentReducer.add_cms_menu.msg
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Footer);
