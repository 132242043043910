import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../style/app.css";

import { LoadingOverlay, Loader } from "react-overlay-loader";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "components/CustomButtons/Button.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

import { AgGridReact } from "ag-grid-react";

let AggridApi;

let agGridViewApi = null;
let agGridEditApi = null;

class UserGroupMenuMappingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userGroupId: "",
      data: {},
      menus: [],
      selectedMenu: [],
      columnDefs: [
        {
          headerName: "Menu",
          field: "name",
          width: 720,
          resizable: true,
          cellRenderer: "group",
          checkboxSelection: true
        }
      ],
      context: { componentParent: this },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      domLayout: "autoHeight",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      getNodeChildDetails: function(data) {
        if (data && data.views && data.views.length > 0) {
          let children = [];
          children = data.views;
          return {
            group: true,
            children: children,
            expanded: data.open
          };
        } else {
          return null;
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.appMenus) {
      this.setState(
        {
          ...this.state,
          menus: nextProps.appMenus
        },
        () => {
          if (nextProps && nextProps.List) {
            let userGroupMenuMapping = nextProps.List;
            let arrId = [];
            for (var i = 0; i < userGroupMenuMapping.length; i++) {
              arrId.push(userGroupMenuMapping[i].menu);
            }
            AggridApi = this.gridApi;
            AggridApi.forEachNode(function(node) {
              if (arrId.indexOf(node.data.id) > -1) {
                node.setSelected(true);
              } else {
                node.setSelected(false);
              }
            });
          }
        }
      );
    }
  }

  componentDidMount() {
    this.props.actions.getUsersMenuListAction(this.props.authUser.pk);
    this.setState(
      {
        ...this.state,
        data: {
          user_group_id: this.props.usergroup
        },
        userGroupId: this.props.usergroup
      },
      () => {
        this.props.actions.getUserGroupMenuMappingActions(
          this.state.userGroupId
        );
      }
    );
  }

  render() {
    return <div>{this.renderOrderTable()}</div>;
  }

  renderOrderTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <p
                className={
                  classes.cardIconTitle + " wholesalor-hierarchy-title"
                }
              >
                Assign Menus
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <div>
                  <div
                    id="myGrid"
                    style={{
                      width: "100%"
                    }}
                    className="ag-theme-material"
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.menus}
                      showToolPanel={true}
                      onGridReady={this.onGridEditReady}
                      onSelectionChanged={this.onSelectionChanged.bind(this)}
                      statusBar={this.state.statusBar}
                      rowSelection="multiple"
                      sideBar={this.state.sideBar}
                      domLayout={this.state.domLayout}
                      getNodeChildDetails={this.state.getNodeChildDetails}
                      pagination={true}
                      paginationAutoPageSize={true}
                    />
                  </div>
                </div>
                <div>
                  <Button color="info" onClick={() => this.saveMapping()}>
                    Save Changes
                  </Button>
                  <Button onClick={() => this.closeMappingComponent()}>
                    Cancel
                  </Button>
                </div>

                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
            {/* <CardFooter>
                            <Button onClick = {()=>this.saveMapping()}>Save</Button>
                            <Button onClick = {()=>this.closeMappingComponent()}>Close</Button>
                        </CardFooter> */}
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  saveMapping = () => {
    let { data } = this.state;
    data.menu = this.state.selectedMenu;
    this.props.save(data);
  };

  closeMappingComponent = () => {
    this.props.close();
  };

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    var length = this.state.selectedMenu.length;
    var i = 0;
    const componentThis = this;
    for (i = 0; i < length; i++) {
      componentThis.state.selectedMenu.pop();
    }
    selectedRows.forEach(function(selectedRow, index) {
      componentThis.state.selectedMenu.push(selectedRow);
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    AggridApi = _.clone(this.gridApi, true);
  };

  onGridViewReady = params => {
    this.gridApi = params.api;
    agGridViewApi = _.clone(params.api, true);
  };
  onGridEditReady = params => {
    this.gridApi = params.api;
    agGridEditApi = _.clone(params.api, true);
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  authUser: state.authState.user,
  authenticated: state.authState.authenticated,
  menuList: state.partnerMenuReducer.get_partner_menu_list,
  appMenus: state.appMenuReducer.list,
  List: state.userGroupReducer.userGroupMenu.list
});

UserGroupMenuMappingComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func,
  save: PropTypes.func
};

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserGroupMenuMappingComponent);
