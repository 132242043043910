export const CREATE_USERGROUP_REQUEST = "create_usergroup_request";
export const CREATE_USERGROUP_FAILURE = "create_usergroup_failure";
export const CREATE_USERGROUP_SUCCESS = "create_usergroup_success";

export const GET_USERGROUP_REQUEST = "get_usergroup_request";
export const GET_USERGROUP_FAILURE = "get_usergroup_failure";
export const GET_USERGROUP_SUCCESS = "get_usergroup_success";

export const DELETE_USERGROUP_REQUEST = "delete_usergroup_request";
export const DELETE_USERGROUP_FAILURE = "delete_usergroup_failure";
export const DELETE_USERGROUP_SUCCESS = "delete_usergroup_success";

export const SAVE_USERGROUP_MENU_MAPPING_REQUEST =
  "save_user_group_menu_mapping_request";
export const SAVE_USERGROUP_MENU_MAPPING_FAILURE =
  "save_user_group_menu_mapping_failure";
export const SAVE_USERGROUP_MENU_MAPPING_SUCCESS =
  "save_user_group_menu_mapping_success";

export const GET_USERGROUP_MENU_MAPPING_REQUEST =
  "get_user_group_menu_mapping_request";
export const GET_USERGROUP_MENU_MAPPING_FAILURE =
  "get_user_group_menu_mapping_failure";
export const GET_USERGROUP_MENU_MAPPING_SUCCESS =
  "get_user_group_menu_mapping_success";
