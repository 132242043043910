import * as OfferTypeService from "../../services/OfferTypeService";
import * as actionTypes from "./actionTypes";

export const getOfferTypeList = id => async dispatch => {
  dispatch(getOfferTypeRequest());
  try {
    const success = await OfferTypeService.getoffertypeList(id);
    if (success.data && success.data.status == "1") {
      dispatch(getOfferTypeSuccess(success.data.data));
    } else {
      dispatch(getOfferTypeFailure("Failed to get details from server."));
    }
  } catch (error) {
    dispatch(getOfferTypeFailure(error.message));
  }
};
export const saveOfferType = (id, data) => async dispatch => {
  dispatch(saveOfferTypeRequest());
  try {
    const success = await OfferTypeService.saveoffertype(id, data);
    dispatch(saveOfferTypeSuccess(success.data));
  } catch (error) {
    dispatch(saveOfferTypeFailure(error.message));
  }
};

const saveOfferTypeRequest = () => {
  return {
    type: actionTypes.SAVE_OFFERS_TYPE_REQUEST
  };
};

const saveOfferTypeSuccess = data => {
  return {
    type: actionTypes.SAVE_OFFERS_TYPE_SUCCESS,
    payload: data
  };
};

const saveOfferTypeFailure = error => {
  return {
    type: actionTypes.SAVE_OFFERS_TYPE_FAILURE,
    payload: error
  };
};

const getOfferTypeRequest = () => {
  return {
    type: actionTypes.GET_OFFERS_TYPE_REQUEST
  };
};

const getOfferTypeSuccess = data => {
  return {
    type: actionTypes.GET_OFFERS_TYPE_SUCCESS,
    payload: data
  };
};

const getOfferTypeFailure = error => {
  return {
    type: actionTypes.GET_OFFERS_TYPE_FAILURE,
    payload: error
  };
};
