import axios from "../utils/axios.jsx";

export const getVariant = async (variant_id) => {
    try {

        let url = "/api/variant/detail?1=1"

        if (variant_id) {
            url = url + "&variant_id=" + variant_id;
        }

        const response = await axios.get(url);
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const getVariants = async (page, searchtext, is_active) => {
    try {

        let url = "/api/variant/list?1=1"

        if (page) {
            url = url + "&page=" + page;
        }

        if (searchtext) {
            url = url + "&search=" + searchtext;
        }

        if (is_active) {
            url = url + "&is_active=true";
        }

        const response = await axios.get(url);
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const saveVariantService = async (userId, saveData) => {
    try {
        let data = new FormData();

        data.append("userid", userId);

        if (saveData.id)
            data.append("id", saveData.id);

        data.append("name", saveData.name);
        data.append("description", saveData.description);
        data.append("is_active", saveData.is_active);
        data.append("component", saveData.component);

        const response = await axios.post("/api/variant/create/", data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteVariant = async (id) => {
    try {
        let data = new FormData();
        data.append("id", id);

        const response = await axios.post("/api/variant/delete/", data);
        return response.data;
    } catch (error) {
        throw error;
    }
};



///////// 



export const getVariantValue = async (variant_id, value_id) => {
    try {

        let url = "/api/variant-value/detail?1=1"
        url = url + "&variant_id=" + variant_id;

        if (value_id) {
            url = url + "&value_id=" + value_id;
        }

        const response = await axios.get(url);
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const getVariantValues = async (page, searchtext, is_active, variant_id) => {
    try {

        let url = "/api/variant-value/list?1=1"
        url = url + "&variant_id=" + variant_id;

        if (page) {
            url = url + "&page=" + page;
        }

        if (searchtext) {
            url = url + "&search=" + searchtext;
        }

        if (is_active) {
            url = url + "&is_active=true";
        }

        const response = await axios.get(url);
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const saveVariantValueService = async (userId, saveData) => {
    try {
        let data = new FormData();

        data.append("userid", userId);

        if (saveData.id)
            data.append("id", saveData.id);

        data.append("code", saveData.code);
        data.append("name", saveData.name);
        data.append("variant_id", saveData.variant_id);

        const response = await axios.post("/api/variant-value/create/", data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteVariantValue = async (id) => {
    try {
        let data = new FormData();
        data.append("id", id);

        const response = await axios.post("/api/variant-value/delete/", data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

