import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  list: null,
  loadingData: false,
  loading: false,
  isSaved: false,
  isSaving: false,
  isDeleted: false,
  isMapSaved: false,
  serviceDetail: {
    data: null,
    loading: false,
    error: null
  },
  partnerServiceMapping: {
    data: null,
    loading: false,
    error: null,
    isSaved: false
  },
  assignedService: {
    list: null
  },
  pagination: {
    page: "1",
    next: null,
    prev: null,
    totalpages: 1,
    trecords: 0,
    pagesize: 10
  }
};

const servicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SERVICES_REQUEST:
      return {
        ...state,
        list: null,
        loadingData: true,
        error: null,
        isSaved: false,
        serviceDetail: {
          data: null,
          loading: false,
          error: null
        },
        partnerServiceMapping: {
          data: null,
          loading: false,
          error: null,
          isSaved: false
        },
        pagination: {
          page: "1",
          next: null,
          prev: null,
          totalpages: 1,
          trecords: 0,
          pagesize: 10
        }
      };
    case actionTypes.GET_SERVICES_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false,
        serviceDetail: {
          data: null,
          loading: false,
          error: null
        },
        partnerServiceMapping: {
          data: null,
          loading: false,
          error: null,
          isSaved: false
        }
      };
    case actionTypes.GET_SERVICES_SUCCESS:
      let pagination = _.clone(state.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        loadingData: false,
        error: null,
        list: sdata,
        isSaved: false,
        serviceDetail: {
          data: null,
          loading: false,
          error: null
        },
        partnerServiceMapping: {
          data: null,
          loading: false,
          error: null,
          isSaved: false
        },
        pagination: {
          ...pagination
        }
      };
    case actionTypes.CREATE_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        serviceDetail: {
          data: null,
          loading: false,
          error: null
        }
      };
    case actionTypes.CREATE_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        serviceDetail: {
          data: null,
          loading: false,
          error: null
        }
      };
    case actionTypes.CREATE_SERVICES_SUCCESS:
      // var data = state.list;
      // var index = data.findIndex(p => p.id === action.payload.id);
      // if (index > -1) {
      //     data[index] = action.payload;
      // } else {

      //     data.push(action.payload);
      // }
      return {
        ...state,
        loading: false,
        error: null,
        // list: data,
        isSaved: true,
        isMapSaved: true,
        serviceDetail: {
          data: null,
          loading: false,
          error: null
        }
      };

    case actionTypes.GET_SERVICE_DETAIL_REQUEST:
      return {
        ...state,
        serviceDetail: {
          data: null,
          loading: true,
          error: null
        }
      };
    case actionTypes.GET_SERVICE_DETAIL_FAILURE:
      return {
        ...state,
        serviceDetail: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_SERVICE_DETAIL_SUCCESS:
      return {
        ...state,
        serviceDetail: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    case actionTypes.SAVE_SERVICE_PARTNER_MAPPING_REQUEST:
      return {
        ...state,
        partnerServiceMapping: {
          data: null,
          loading: true,
          error: null,
          isSaved: false
        }
      };
    case actionTypes.SAVE_SERVICE_PARTNER_MAPPING_FAILURE:
      return {
        ...state,
        partnerServiceMapping: {
          data: null,
          loading: false,
          error: action.payload,
          isSaved: false
        }
      };
    case actionTypes.SAVE_SERVICE_PARTNER_MAPPING_SUCCESS:
      return {
        ...state,
        partnerServiceMapping: {
          data: action.payload,
          loading: false,
          error: null,
          isSaved: true
        }
      };

    case actionTypes.GET_ASSIGNED_SERVICE_PARTNER_MAPPING_REQUEST:
      return {
        ...state,
        serviceDetail: {
          data: null,
          loading: false,
          error: null
        },
        partnerServiceMapping: {
          ...state.partnerServiceMapping,
          loading: false,
          error: null,
          isSaved: false
        },
        assignedService: {
          list: null
        }
      };
    case actionTypes.GET_ASSIGNED_SERVICE_PARTNER_MAPPING_FAILURE:
      return {
        ...state,
        serviceDetail: {
          data: null,
          loading: false,
          error: null
        },
        partnerServiceMapping: {
          ...state.partnerServiceMapping,
          loading: false,
          error: null,
          isSaved: false
        },
        assignedService: {
          list: null
        }
      };
    case actionTypes.GET_ASSIGNED_SERVICE_PARTNER_MAPPING_SUCCESS:
      return {
        ...state,
        serviceDetail: {
          data: null,
          loading: false,
          error: null
        },
        partnerServiceMapping: {
          ...state.partnerServiceMapping,
          loading: false,
          error: null,
          isSaved: false
        },
        assignedService: {
          list: action.payload
        }
      };
    default:
      return state;
  }
};

export default servicesReducer;
