import * as settingServices from "../../services/settingServices";
import * as actionTypes from "./actionTypes";

export const getSettingsAction = partner => async dispatch => {
  dispatch(getSettingsRequest());
  try {
    var success = await settingServices.getSettings(partner);
    if (success.status && success.status === "1") {
      dispatch(getSettingsSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getSettingsFailure(success.error));
    } else {
      dispatch(getSettingsFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getSettingsFailure(error.message));
  }
};

const getSettingsRequest = () => {
  return {
    type: actionTypes.GET_SETTINGS_REQUEST
  };
};

const getSettingsSuccess = data => {
  return {
    type: actionTypes.GET_SETTINGS_SUCCESS,
    payload: data
  };
};

const getSettingsFailure = error => {
  return {
    type: actionTypes.GET_SETTINGS_FAILURE,
    payload: error
  };
};

export const addUpdateSettingsAction = (
  data,
  user,
  partner
) => async dispatch => {
  dispatch(addUpdateSettingsRequest());
  try {
    var success = await settingServices.addUpdateSetting(data, user, partner);
    if (success.status && success.status === "1") {
      dispatch(addUpdateSettingsSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(addUpdateSettingsFailure(success.error));
    } else {
      dispatch(addUpdateSettingsFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(addUpdateSettingsFailure(error.message));
  }
};

const addUpdateSettingsRequest = () => {
  return {
    type: actionTypes.ADD_UPDATE_SETTINGS_REQUEST
  };
};

const addUpdateSettingsSuccess = data => {
  return {
    type: actionTypes.ADD_UPDATE_SETTINGS_SUCCESS,
    payload: data
  };
};

const addUpdateSettingsFailure = error => {
  return {
    type: actionTypes.ADD_UPDATE_SETTINGS_FAILURE,
    payload: error
  };
};
