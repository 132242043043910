import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Loader } from "react-overlay-loader";
import { withStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from "@material-ui/core";
import { ActionCreators } from "../../redux/actions";
import AgGrid from "components/AgGrid/AgGrid.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class WholesalorHierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedwholesalor: [],
      unassignedlist: [],
      selectedTab: 0,
      hierarchyList: [],
      selectedClassIndexes: [],
      selectedEntity: [],
      modalOpen: false,
      domLayout: "autoHeight",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      treeViewCol: [
        {
          headerName: "Wholesalor / Brand / Category / Product",
          field: "name",
          minWidth: 400,
          width: 900,
          resizable: true,
          showRowGroup: true,
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            suppressCount: true,
            checkbox: true,
            innerRenderer: "checkboxCellRenderer",
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true
          }
        }
      ],
      UnassignListCol: [
        {
          headerName: "Wholesalor",
          field: "name",
          width: 900,
          resizable: true,
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          checkboxSelection: true
        }
      ],
      treeViewColAdmin: [
        {
          headerName: "Wholesalor / Brand / Category / Product",
          field: "name",
          width: 900,
          resizable: true,
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          cellRenderer: "agGroupCellRenderer"
        }
      ],
      groupDefaultExpanded: 1,
      context: { categoryGrid: this },
      hierarchicalView: function (data) {
        if (data && data.children && data.children.length > 0) {
          return {
            group: true,
            children: data.children,
            expanded: data.open
          };
        } else {
          return null;
        }
      }
    };
    this.hierarchyRefs = React.createRef();
    this.gridRefs = React.createRef();
    this.changeTab = this.changeTab.bind(this);
    this.openModal = this.openModal.bind(this);
    this.saveWholesalerMapping = this.saveWholesalerMapping.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.createProduct = this.createProduct.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.batchSaved) {
        this.setState({
          modalOpen: false
        });
      }

      if (nextProps.hierarachy) {
        let list = nextProps.product_template_list
        this.setState({
          hierarchyList: nextProps.hierarachy
        }, () => {
          if (list && list.length) {
            this.hierarchyRefs.current.setSelections(list, 'BRAND_PUSH')
          }
        });
      }

      if (nextProps.UnAssignedWholesalers) {
        this.setState({
          unassignedlist: nextProps.UnAssignedWholesalers
        });
      }

      if (nextProps.mappingSaved) {
        if (this.state.selectedTab == 1) {
          this.props.actions.unassignedWholesalorListAction(
            this.props.PartnerId
          );
        } else {
          this.props.actions.getWholesalorBrandCategoryProductHierarchyAction(
            this.props.PartnerId
          );
          this.props.actions.getSavedBatchProductList(this.props.PartnerId);
        }
      }
    }
  }

  componentDidMount() {
    this.props.actions.getWholesalorBrandCategoryProductHierarchyAction(
      this.props.PartnerId
    );
  }

  componentWillMount() {
    this.props.actions.getSavedBatchProductList(this.props.PartnerId);
  }

  getGridSelectedRow() {
    let selectedRows = this.hierarchyRefs.current.agGridApi.getSelectedRows();
    let selectedEntity = []
    selectedRows.forEach(function (selectedRow) {
      if (selectedRow.role === 'PR') {
        selectedEntity.push(selectedRow.id);
      }
    });
    return selectedEntity
  }

  openModal() {
    let selections = this.getGridSelectedRow();
    this.setState({
      modalOpen: true,
      selectedEntity: selections
    }, () => {
      this.hierarchyRefs.current.setSelections(this.state.selectedEntity, 'BRAND_PUSH');
    });
  }

  handleModalClose() {
    this.setState({
      modalOpen: false,
      selectedEntity: []
    });
  }

  createProduct() {
    this.props.actions.batchProductCreate(
      this.state.selectedEntity,
      this.props.UserId,
      this.props.PartnerId
    );
  }

  render() {
    const { selectedTab } = this.state;
    let TabList = [
      {
        tabName: "Assigned",
        tabContent: this.renderHierarchyView()
      },
      {
        tabName: "Unassigned",
        tabContent: this.renderAssignmentView()
      }
    ];
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className="tab">
            <GridContainer>
              {_.map(TabList, (tab, index) => (
                <GridItem
                  xs={6}
                  lg={6}
                  md={6}
                  onClick={() => this.changeTab(index)}
                  className={
                    "tab-style" + (index === selectedTab ? " active-tab" : "")
                  }
                  key={`tab_${index}`}
                >
                  <span
                    className={
                      "tab-header" +
                      (index === selectedTab ? " active-tab-header" : "")
                    }
                  >
                    {tab.tabName}
                  </span>
                </GridItem>
              ))}
            </GridContainer>
            {TabList[selectedTab].tabContent}
          </div>
        </GridItem>
      </GridContainer>
    );
  }

  changeTab(index) {
    if (index == 1) {
      this.props.actions.unassignedWholesalorListAction(this.props.PartnerId);
    } else {
      this.props.actions.getWholesalorBrandCategoryProductHierarchyAction(
        this.props.PartnerId
      );
      this.props.actions.getSavedBatchProductList(this.props.PartnerId);
    }
    this.setState({
      selectedTab: index
    });
  }

  renderHierarchyView = () => {
    const { classes } = this.props;
    return (
      <Card>
        {this.openConfirmationDilog()}
        <CardHeader color="rose" icon>
          <div>
            <p
              className={
                classes.cardIconTitle +
                " grid-header-title" +
                " wholesalor-hierarchy-title"
              }
            >
              Wholesalor Brand Product Hierarchy
            </p>
          </div>
        </CardHeader>
        <CardBody>
          <div
            id="myGrid"
            style={{
              width: "100%"
            }}
            className="ag-theme-material"
          >
            <AgGrid
              ref={this.hierarchyRefs}
              columnDefs={this.state.treeViewCol}
              rowSelection="multiple"
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              statusBar={this.state.statusBar}
              floatingFilter={true}
              sideBar={this.state.sideBar}
              context={this.state.context}
              groupSuppressAutoColumn={true}
              suppressRowClickSelection={true}
              groupSelectsChildren={true}
              pagination={true}
              paginationAutoPageSize={false}
              rowData={this.state.hierarchyList}
              domLayout={this.state.domLayout}
              groupDefaultExpanded={this.state.groupDefaultExpanded}
              loading={this.props.Loading}
              isHierarchyView={true}
              getNodeChildDetails={this.state.hierarchicalView}
            />
          </div>
        </CardBody>
        <CardBody>
          {this.props.authRole === "RE" && (
            <div style={{ float: "right" }}>
              <Button
                color="info"
                style={{ backgroundColor: "#248f24" }}
                onClick={this.openModal}
              >
                Add Selected Product
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    );
  };

  saveWholesalerMapping() {
    let selectedRows = this.hierarchyRefs.current.agGridApi.getSelectedRows();
    let selectedEntity = []
    selectedRows.forEach(function (selectedRow) {
      let data = {
        id: "",
      };
      data.id = selectedRow.id;
      selectedEntity.push(data);
    });
    this.props.actions.saveRetailerWholesalerMappingAction(
      selectedEntity,
      this.props.PartnerId
    );
  }

  renderAssignmentView = () => {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="rose" icon>
          <div>
            <p
              className={
                classes.cardIconTitle +
                " grid-header-title" +
                " wholesalor-hierarchy-title"
              }
            >
              Assign Wholesalor to Retailer
            </p>
          </div>
        </CardHeader>
        <CardBody>
          <div
            id="myGrid"
            style={{
              width: "100%"
            }}
            className="ag-theme-material"
          >
            <AgGrid
              ref={this.hierarchyRefs}
              columnDefs={this.state.UnassignListCol}
              rowSelection="multiple"
              defaultColDef={this.state.defaultColDef}
              statusBar={this.state.statusBar}
              floatingFilter={true}
              sideBar={this.state.sideBar}
              pagination={true}
              paginationAutoPageSize={false}
              rowData={this.state.unassignedlist}
              domLayout={this.state.domLayout}
            />
          </div>
          {this.props.mappingSaving && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-25px",
                marginLeft: "-12px"
              }}
            />
          )}
          <Loader loading={this.props.mappingSaving} />
        </CardBody>
        <CardBody>
          {this.props.authRole === "RE" && (
            <div style={{ float: "right" }}>
              <Button
                color="info"
                style={{ backgroundColor: "#248f24" }}
                onClick={this.saveWholesalerMapping}
              >
                Assign Wholesalor
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    );
  };

  openConfirmationDilog() {
    return (
      <Dialog
        open={this.state.modalOpen}
        onClose={this.handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to create the products?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#248f24" }}
            onClick={this.createProduct}
            autoFocus
          >
            Yes
          </Button>
          <Button
            onClick={this.handleModalClose}
            style={{ backgroundColor: "#999" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  getNodeChildDetails(data) {
    if (data && data.children && data.children.length > 0) {
      return {
        group: true,
        children: data.children,
        expanded: data.open
      };
    } else {
      return null;
    }
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  Loading:
    state.categoryReducer.wholesalorBrandCategoryProductHierarchy.loading,
  savedTemplatesLoading: state.batchProductCreationReducer.loading,
  product_template_list:
    state.batchProductCreationReducer.product_template_list,
  ListError: state.categoryReducer.categorydata.error,
  UserId: state.authState.user.pk,
  PartnerId: state.authState.user.partner_id,
  authUser: state.authState.user,
  authRole: state.authState.user.role,
  batchSaved: state.batchProductCreationReducer.saved,
  batchSaving: state.batchProductCreationReducer.saving,
  hierarachy:
    state.categoryReducer.wholesalorBrandCategoryProductHierarchy.data,
  UnAssignedWholesalers: state.SegmentsReducer.unassignWholsaler.data,
  mappingSaving: state.SegmentsReducer.saveRetailersWholesaler.loading,
  mappingSaved: state.SegmentsReducer.saveRetailersWholesaler.isSaved,
  mappingSavedError: state.SegmentsReducer.saveRetailersWholesaler.error
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.RETAILER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(WholesalorHierarchy);
