import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

class ConfirmBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { open, title, onHandleConfrim, onHandleCancle } = this.props;

    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Slide}
          onClose={this.handleRequestClose}
        >
          <DialogTitle style={{ width: "450px" }}>{"Alert"}</DialogTitle>
          <DialogContent>{title}</DialogContent>
          <DialogActions>
            <Button onClick={onHandleConfrim} color="secondary">
              Yes
            </Button>
            <Button onClick={onHandleCancle} color="primary">
              No{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default ConfirmBox;
