import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  list: null,
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  isSaving: false,
  deletingData: false,
  brandPrpductList: null,
  segmentImages: {
    loading: false,
    error: null,
    images: null
  },
  map_Retailer_Product_Template_Selected: {
    savingCompleted: false,
    isSaving: false,
    isLoading: false,
    error: null,
    products: []
  },
  retailer_settlement: {
    loading: false,
    saving: false,
    error: null,
    settlement: {}
  }
};

const retailerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SAVE_ABOUTUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        isSaving: true
      };
    case actionTypes.SAVE_ABOUTUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaving: false
      };
    case actionTypes.SAVE_ABOUTUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isSaved: true,
        list: action.payload,
        isSaving: false
      };
    case actionTypes.DELETE_ABOUTUS_REQUEST:
      return {
        ...state,
        deletingData: true,
        error: null,
        isSaved: false
      };
    case actionTypes.DELETE_ABOUTUS_FAILURE:
      return {
        ...state,
        deletingData: false,
        error: action.payload
      };
    case actionTypes.DELETE_ABOUTUS_SUCCESS:
      return {
        ...state,
        deletingData: false,
        error: null,
        isSaved: true,
        list: action.payload
      };
    case actionTypes.GET_ABOUTUS_REQUEST:
      return {
        ...state,
        list: null,
        loadingData: true,
        error: null
      };
    case actionTypes.GET_ABOUTUS_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload
      };
    case actionTypes.GET_ABOUTUS_SUCCESS:
      return {
        ...state,
        loadingData: false,
        error: null,
        list: action.payload
      };
    case actionTypes.SEGMET_IMAGES_REQUEST:
      return {
        ...state,
        segmentImages: {
          loading: true,
          error: null,
          images: null
        }
      };
    case actionTypes.SEGMET_IMAGES_SUCCESS:
      return {
        ...state,
        segmentImages: {
          loading: false,
          error: null,
          images: action.payload
        }
      };
    case actionTypes.SEGMET_IMAGES_FAILURE:
      return {
        ...state,
        segmentImages: {
          loading: false,
          error: action.payload,
          images: null
        }
      };

    case actionTypes.BRAND_PRODUCT_TEMPLATE_REQUEST:
      return {
        ...state,
        loadingData: true,
        error: null,
        brandPrpductList: null
      };
    case actionTypes.BRAND_PRODUCT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loadingData: false,
        error: null,
        brandPrpductList: action.payload
      };
    case actionTypes.BRAND_PRODUCT_TEMPLATE_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: null,
        brandPrpductList: action.payload
      };

    case actionTypes.GET_RETAILER_PRODUCT_TEMPLATE_SELECTED_REQUEST:
      return {
        ...state,
        map_Retailer_Product_Template_Selected: {
          savingCompleted: false,
          isSaving: false,
          isLoading: true,
          error: null,
          products: []
        }
      };
    case actionTypes.GET_RETAILER_PRODUCT_TEMPLATE_SELECTED_SUCCESS:
      return {
        ...state,
        map_Retailer_Product_Template_Selected: {
          savingCompleted: false,
          isSaving: false,
          isLoading: false,
          error: null,
          products: action.payload
        }
      };

    case actionTypes.GET_RETAILER_PRODUCT_TEMPLATE_SELECTED_FAILURE:
      return {
        ...state,
        map_Retailer_Product_Template_Selected: {
          savingCompleted: false,
          isSaving: false,
          isLoading: false,
          error: null,
          products: action.payload
        }
      };

    case actionTypes.GET_PARTNER_SETTLEMENT_REQUEST:
      return {
        ...state,
        retailer_settlement: {
          ...state.retailer_settlement,
          loading: false,
          saving: false,
          error: null
        }
      };

    case actionTypes.GET_PARTNER_SETTLEMENT_SUCCESS:
      return {
        ...state,
        retailer_settlement: {
          loading: false,
          saving: false,
          error: null,
          settlement: action.payload
        }
      };

    case actionTypes.GET_PARTNER_SETTLEMENT_FAILURE:
      return {
        ...state,
        retailer_settlement: {
          loading: false,
          saving: false,
          error: action.payload,
          settlement: {}
        }
      };

    case actionTypes.APPROVE_PARTNER_SETTLEMENT_REQUEST:
      return {
        ...state,
        retailer_settlement: {
          ...state.retailer_settlement,
          loading: false,
          saving: true,
          error: null
        }
      };

    case actionTypes.APPROVE_PARTNER_SETTLEMENT_SUCCESS:
      return {
        ...state,
        retailer_settlement: {
          loading: false,
          saving: false,
          error: null,
          settlement: action.payload
        }
      };

    case actionTypes.APPROVE_PARTNER_SETTLEMENT_FAILURE:
      return {
        ...state,
        retailer_settlement: {
          ...state.retailer_settlement,
          loading: false,
          saving: false,
          error: action.payload
        }
      };

    default:
      return state;
  }
};

export default retailerReducer;
