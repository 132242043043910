import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  list: [],
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  isSaving: false,
  isDeleted: false,
  userGroupMenu: {
    list: [],
    loading: false,
    error: null
  },
  pagination: {
    page: "1",
    next: null,
    prev: null,
    totalpages: 1,
    trecords: 0,
    pagesize: 10
  }
};

const userGroupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_USERGROUP_REQUEST:
      return {
        ...state,
        list: null,
        loadingData: true,
        error: null,
        isSaved: false,
        pagination: {
          page: "1",
          next: null,
          prev: null,
          totalpages: 1,
          trecords: 0,
          pagesize: 10
        }
      };
    case actionTypes.GET_USERGROUP_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false
      };
    case actionTypes.GET_USERGROUP_SUCCESS:
      let pagination = _.clone(state.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        loadingData: false,
        error: null,
        list: sdata,
        isSaved: false,
        pagination: {
          ...pagination
        }
      };
    case actionTypes.CREATE_USERGROUP_REQUEST:
      return {
        ...state,
        loadingData: true,
        error: null,
        isSaved: false
      };
    case actionTypes.CREATE_USERGROUP_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false
      };
    case actionTypes.CREATE_USERGROUP_SUCCESS:
      // var data = state.list;
      // let list = []
      // var index = data.findIndex(p => p.id === action.payload.id);
      // if (index > -1) {
      //     data[index] = action.payload;
      // } else {
      //     list.push(action.payload)
      //     for (let i = 0; i < data.length; i++) {
      //         list.push(data[i])
      //     }
      //     // data.push(action.payload);
      // }
      return {
        ...state,
        loading: false,
        error: null,
        // list: list,
        isSaved: true,
        isMapSaved: true
      };

    case actionTypes.GET_USERGROUP_MENU_MAPPING_REQUEST:
      return {
        ...state,
        userGroupMenu: {
          list: null,
          loading: true,
          error: null
        }
      };
    case actionTypes.GET_USERGROUP_MENU_MAPPING_FAILURE:
      return {
        ...state,
        userGroupMenu: {
          list: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_USERGROUP_MENU_MAPPING_SUCCESS:
      return {
        ...state,
        userGroupMenu: {
          list: action.payload,
          loading: false,
          error: null
        }
      };
    default:
      return state;
  }
};

export default userGroupReducer;
