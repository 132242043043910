import React from "react";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ActionCreators } from "../../redux/actions";
import _ from "lodash";
import { LoadingOverlay } from "react-overlay-loader";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import CmsActionButton from "./Component/cmsActionButton.jsx";

import AddMenuForm from "./Component/AddMenuForm.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class MetadataHeader extends React.Component {
  constructor(props) {
    super(props);
    this.isHandleSave = this.isHandleSave.bind(this);
    this.state = {
      isAddNew: false,
      editData: null,
      list: [],
      editDataIndex: null,
      columnDefs: [
        {
          headerName: "Title",
          field: "name",
          width: 240,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Active",
          field: "status",
          width: 150,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },

        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ]
    };
  }

  componentWillReceiveProps(nextProps) {
    if (true || _.isEqual(this.props, nextProps)) {
      if (nextProps.headers) {
        this.setState({
          list: nextProps.headers ? nextProps.headers : []
        });
      }
    }
  }

  componentDidMount() {
    this.props.actions.getCMSPages();
  }

  render() {
    return this.renderHeaderTable();
  }

  isHandleSave = obj => {
    const { editDataIndex } = this.state;

    if (editDataIndex == null) {
      let menuList = _.clone(this.state.list);
      menuList.push(obj);
      this.props.setSelectedItems(menuList);
    } else if (editDataIndex != null) {
      let menuList = _.clone(this.state.list);
      menuList[editDataIndex] = obj;
      this.props.setSelectedItems(menuList);
    }

    this.setState({
      isAddNew: false,
      editData: null
    });
  };

  onHandleDelete = index => {
    let menuList = _.clone(this.state.list);
    menuList.splice(index, 1);
    this.props.setSelectedItems(menuList);
  };

  handelIsAddNew = () => {
    this.setState({
      isAddNew: true,
      editDataIndex: null,
      editData: null
    });
  };

  handelIsAddNewCancle = () => {
    this.setState({
      isAddNew: false,
      editDataIndex: null
    });
  };

  handelEdit = index => {
    let data = this.state.list[index];
    this.setState({
      editData: data,
      editDataIndex: index,
      isAddNew: true
    });
  };

  renderHeaderTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.addMenuDilog()}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Header Links
                </p>
                <p>
                  <Button
                    disabled={this.props.Loading}
                    color="success"
                    onClick={this.handelIsAddNew}
                    size="sm"
                    className={classes.marginRight + " grid-header-button"}
                  >
                    <AddIcon className="grid-header-button-icon" /> New
                  </Button>
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.list}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                Loading={this.props.Loading}
                handelDelete={this.onHandleDelete}
                AgGridActionButton={CmsActionButton}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  addMenuDilog = () => {
    const { classes } = this.props;
    const { editData } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Dialog
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSignup
            }}
            open={this.state.isAddNew}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.handelIsAddNewCancle("Javascript ")}
            aria-labelledby="signup-modal-slide-title"
            aria-describedby="signup-modal-slide-description"
          >
            <DialogTitle
              id="signup-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <h4 style={{ width: "500px" }}>
                <b>Header Link</b>
              </h4>
            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classes.modalBody}
            >
              <LoadingOverlay>
                <DialogContentText>
                  <AddMenuForm
                    onClose={this.handelIsAddNewCancle}
                    onSave={this.isHandleSave}
                    formData={editData}
                    partner_id={this.props.partner_id || ''}
                    source={this.props.source || ''}
                  />
                </DialogContentText>
              </LoadingOverlay>
            </DialogContent>
          </Dialog>
        </GridItem>
      </GridContainer>
    );
  };
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  menuList: state.cmsReducer.get_CMS_Pages.list
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MetadataHeader);
