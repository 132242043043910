import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import { LoadingOverlay, Loader } from "react-overlay-loader";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Icon from "@material-ui/core/Icon";
import CardBody from "components/Card/CardBody.jsx";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import ImageUpload from "../../components/ImageUpload.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

class CreateFolderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addFile: {},
      isEdit: false,
      error: []
    };
    this.uploadImage = this.uploadImage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let error = "";
    if (nextProps.UploadFileError) {
      error = nextProps.UploadFileError;
    }
    this.setState({
      ...this.state,
      error: error
    });
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({
      addFile: data
    });
  }

  render() {
    const { classes } = this.props;
    const {
      file_name,
      file_size,
      type,
      is_deleted,
      document_object
    } = this.state.addFile;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: file_name ? file_name : "",
                      onChange: this.handleTextChange("file_name", "")
                    }}
                  />
                  <br />
                  <br />
                  <ImageUpload
                    ref="image"
                    // id={index}
                    addButtonProps={{
                      round: true
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true
                    }}
                    imgUrl={document_object}
                    onimageRemove={() => this.handelImageRemove()}
                    onChange={file => this.handelImageChange(file)}
                    hideAssetBtn={true}
                  />
                  <br />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={type == 1 ? true : false}
                        onClick={this.handleToggleStatus(type)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Private"
                  />
                  <br />
                  {this.state.error && this.state.error.length > 0 && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button color="info" onClick={() => this.uploadImage()}>
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={() => this.props.close()}>Cancel</Button>
                  {this.props.Loader && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loader} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  uploadImage() {
    const { document_object, file_name } = this.state.addFile;
    let error = [];
    if (document_object.length === 0) {
      let err_string = "Please Select the Image to Upload";
      if (error.indexOf(err_string) === -1) {
        error.push(err_string);
      }
    }
    if (file_name.length === 0) {
      let err_string = "Please Fill the name of the file";
      if (error.indexOf(err_string) == -1) {
        error.push(err_string);
      }
    }

    if (error.length === 0) {
      this.props.save(this.state.addFile);
    } else {
      this.setState({
        ...this.state,
        error: error.toString()
      });
    }
  }

  handleTextChange = (key, type) => event => {
    this.setState({
      ...this.state,
      addFile: {
        ...this.state.addFile,
        [key]: event.target.value
      }
    });
  };

  handleToggleStatus = curObj => event => {
    let value = 0;
    if (event.target.checked == true) {
      value = 1;
    }
    this.setState({
      addFile: {
        ...this.state.addFile,
        type: value
      }
    });
  };

  handelImageRemove = () => {
    let { document_object, file_size, original_file_name } = this.state.addFile;
    document_object = "";
    original_file_name = "";
    file_size = "";
    this.setState({
      ...this.state,
      addFile: {
        ...this.state.addFile,
        document_object: document_object,
        file_size: file_size,
        original_file_name: original_file_name
      }
    });
  };

  handelImageChange = file => {
    let { document_object } = this.state.addFile;
    document_object = file;
    this.setState({
      ...this.state,
      addFile: {
        ...this.state.addFile,
        document_object: document_object,
        file_size: file.size,
        original_file_name: file.name
      }
    });
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  UploadFileError: state.assetManagementReducer.file.error,
  Loader: state.assetManagementReducer.file.loading
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateFolderComponent);
