import React, { Component } from "react";
import Button from "../../../components/CustomButtons/Button";

import "../../Pages/box.css";

class CreateProduct extends Component {
  constructor(props) {
    super(props);
    this.openProductPopup = this.openProductPopup.bind(this);
  }

  invokeParentMethod() {
    const { classes } = this.props;
    if (this.props.node && this.props.node.data && this.props.node.data.ptid) {
      return (
        <span>
          <Button color="success" onClick={this.openProductPopup}>
            Add Product
          </Button>
        </span>
      );
    }
  }

  openProductPopup() {
    this.props.context.componentParent.openAddProductPopup(
      true,
      this.props.node.data.ptid
    );
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default CreateProduct;
