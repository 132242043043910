import React, { Component } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import List from "@material-ui/icons/List";
import moment from "moment";
function dateFormatter(params) {
    if (params) {
        return moment(params).format("MM/DD/YYYY");
    } else {
        return "";
    }
}
class ProductVariantAction extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    invokeParentMethod() {
        const { data } = this.props;
        const { is_Stock } = this.props.context.componentParent.props;
        return (
            <div
                style={{
                    lineHeight: "60px"
                }}
            >
                {!is_Stock && this.props.context.componentParent.props.handelEdit && (
                    <span onClick={() => this.performAction(data.id, 0)} title="Edit">
                        <Edit className="grid_action_btn" />
                    </span>
                )}
                {is_Stock &&
                    this.props.context.componentParent.props.handelEdit &&
                    dateFormatter(data.created_at) === moment().format("MM/DD/YYYY") ? (
                    <span onClick={() => this.performAction(data.id, 0)} title="Edit">
                        <Edit className="grid_action_btn" />
                    </span>
                ) : (
                    ""
                )}
                {this.props.context.componentParent.props.is_map && (
                    <span title="Map" onClick={() => this.performAction(data.id, 2)}>
                        <List className="grid_action_btn" />
                    </span>
                )}
                {this.props.context.componentParent.props.handelView && (
                    <span
                        span
                        onClick={() => this.performAction(data.id, 3)}
                        title="View Move Stock Entry"
                    >
                        <ViewHeadlineIcon className="grid_action_btn" />
                    </span>
                )}

                {this.props.context.componentParent.props.handleCopy && (
                    <span span onClick={() => this.copy(data.id)} title="Copy">
                        <FileCopyIcon className="grid_action_btn" />
                    </span>
                )}

                {this.props.context.componentParent.props.handleDelete && (
                    <span onClick={() => this.performAction(data.id, 1)} title="Delete">
                        <DeleteIcon className="grid_action_btn" />
                    </span>
                )}
            </div>
        );
    }

    performAction(id, value) {
        switch (value) {
            case 0:
                this.props.context.componentParent.handelEdit(id);
                break;
            case 1:
                this.props.context.componentParent.handleDelete(id);
                break;
            case 2:
                this.props.context.componentParent.map(id);
                break;
            case 3:
                this.props.context.componentParent.view(id);
                break;
        }
    }

    copy(id) {
        this.props.context.componentParent.handleCopy(id);
    }
    render() {
        return (
            <div>
                <span>{this.invokeParentMethod()}</span>
            </div>
        );
    }
}
export default ProductVariantAction;
