export const CREATE_MENU_MASTER_REQUEST = "create_menu_master_request";
export const CREATE_MENU_MASTER_FAILURE = "create_menu_master_failure";
export const CREATE_MENU_MASTER_SUCCESS = "create_menu_master_success";

export const GET_MENU_MASTER_REQUEST = "get_menu_master_request";
export const GET_MENU_MASTER_FAILURE = "get_menu_master_failure";
export const GET_MENU_MASTER_SUCCESS = "get_menu_master_success";

export const COPY_MENU_REQUEST = "copy_menu_request";
export const COPY_MENU_FAILURE = "copy_menu_failure";
export const COPY_MENU_SUCCESS = "copy_menu_success";

export const COPY_MENU_UPDATE = "copy_menu_update";
