import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import UniversalAttributeGroupAction from "../../../src/components/AgGrid/UniversalAttributeGroupAction";
import AgGrid from "../../../src/components/AgGrid/AgGrid";

const initialState = {
  attributeData: {
    id: null,
    name: "",
    description: "",
    sort_order: null,
    attribute_group: null
  },
  required: {
    name: "",
    description: "",
    sort_order: "",
    attribute_group: ""
  },
  columnDefs: [
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      resizable: true,
      width: 400
    },
    // {
    //     headerName: "Description",
    //     field: "description",
    //     width: 250,
    //     filter: false
    // },
    {
      headerName: "Attribute Group",
      field: "attribute_group.name",
      width: 350,
      filter: true
    },
    {
      headerName: "Actions",
      field: "value",
      cellRenderer: "AgGridActionButton",
      colId: "params",
      width: 240,
      filter: false
    }
  ]
};

class UniversalAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      isAddNew: false,
      universalAttributes: []
    };
  }

  componentDidMount() {
    if (this.props.List && this.props.List.length > 0) {
      this.setState(() => ({
        universalAttributes: this.props.List
      }));
    } else {
      this.props.actions.getUniversalAttributes();
    }
    if (this.props.AttributeGroup && this.props.AttributeGroup.length > 0) {
    } else {
      this.props.actions.getAttributeGroups();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        nextProps.List &&
        nextProps.List.length > 0 &&
        !_.isEqual(this.state.universalAttributes, nextProps.List)
      ) {
        this.setState(() => ({
          universalAttributes: nextProps.List
        }));
      }
      if (!nextProps.Saving && this.props.Saving && nextProps.Error === null) {
        this.handelIsAddNew();
      }
    }
  }

  handleTextChange = key => event => {
    let req = _.cloneDeep(this.state.required);
    if (event.target.value === null || event.target.value === "") {
      req[key] = "error";
    } else {
      req[key] = "success";
    }
    if (key === "sort_order") {
      if (isNaN(event.target.value) === false) {
        this.setState({
          attributeData: {
            ...this.state.attributeData,
            [key]: event.target.value
          },
          required: req
        });
      }
    } else {
      this.setState({
        attributeData: {
          ...this.state.attributeData,
          [key]: event.target.value
        },
        required: req
      });
    }
  };

  handelIsAddNew = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      ...initialState
    });
  };

  handelEdit = id => {
    var unniversalAttribute = this.state.universalAttributes;
    var index = unniversalAttribute.findIndex(item => item.id === id);
    if (index > -1) {
      const {
        id,
        name,
        description,
        sort_order,
        attribute_group
      } = unniversalAttribute[index];
      this.setState({
        isAddNew: true,
        attributeData: {
          id: id,
          name: name,
          description: description,
          sort_order: sort_order,
          attribute_group: attribute_group ? attribute_group.id : null
        }
      });
    }
  };
  isValid = data => {
    let isValid = true;
    let req = _.cloneDeep(this.state.required);
    if (data.name === "") {
      req.name = "error";
      isValid = false;
    }
    if (data.description === "") {
      req.description = "error";
      isValid = false;
    }
    if (data.sort_order === "" || data.sort_order === null) {
      req.sort_order = "error";
      isValid = false;
    }
    if (data.attribute_group === "" || data.attribute_group === null) {
      req.attribute_group = "error";
      isValid = false;
    }
    this.setState({
      required: req
    });
    return isValid;
  };
  saveUniversalAttributes = () => {
    const {
      id,
      name,
      description,
      sort_order,
      attribute_group
    } = this.state.attributeData;
    let data = _.cloneDeep(this.state.attributeData);
    if (!this.isValid(data)) {
      return;
    }
    this.props.actions.createUniversalAttributes(this.props.UserEmail, {
      id: id,
      name: name,
      description: description,
      sort_order: sort_order,
      attribute_group: attribute_group
    });
  };
  getTableData = () => {
    const { universalAttributes } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(
      universalAttributes,
      ({ id, name, description, attribute_group }, index) => {
        returnData.push([
          <span>{index + 1}</span>,
          <span>
            {
              //eslint-disable-next-line
                            <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
                {name}
              </a>
            }
          </span>,
          <span>{description}</span>,
          <span>{attribute_group ? attribute_group.name : ""}</span>,
          <span>
            <Button
              color="success"
              className={classes.actionButton}
              onClick={() => this.handelEdit(id)}
            >
              <CreateIcon className={classes.icon} />
            </Button>
            <Button
              color="danger"
              className={classes.marginRight}
              style={{ padding: "5px" }}
            >
              <ClearIcon
                className={classes.icons}
                style={{ marginRight: "0px" }}
              />
            </Button>
          </span>
        ]);
      }
    );
    return returnData;
  };

  renderUniversalAttributeTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Grid
            container
            spacing={16}
            alignItems={"flex-end"}
            direction={"row"}
            justify={"flex-end"}
          >
            {/* <Button disabled={this.props.Loading} color="success" onClick={this.handelIsAddNew} className={classes.marginRight}>
                        <AddIcon className={classes.icons} />   New
                    </Button> */}
          </Grid>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              {/* <CardIcon color="rose">
                            <Assignment />
                        </CardIcon> */}
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  View, edit or delete Attribute Groups below.
                </p>
                <p>
                  <Button
                    disabled={this.props.Loading}
                    color="success"
                    onClick={this.handelIsAddNew}
                    className={classes.marginRight + " grid-header-button"}
                  >
                    <AddIcon className="grid-header-button-icon" /> New
                  </Button>
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                {/* <Table
                            tableHead={[
                                "#",
                                "Name",
                                "URL",
                                "Partner Number",
                                "Role",
                                "Actions"
                            ]}
                            tableData={
                                this.getTableData()
                            }
                            tableShopping
                            customHeadCellClasses={[
                                classes.center,
                                classes.description,
                                classes.description,
                                classes.right,
                                classes.right,
                                classes.right
                            ]}
                            customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                            customCellClasses={[
                                classes.tdName,
                                classes.customFont,
                                classes.customFont,
                                classes.tdNumber,
                                classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                                classes.tdNumber
                            ]}
                            customClassesForCells={[1, 2, 3, 4, 5, 6]}
                        /> */}
                <div
                  id="myGrid"
                  style={{
                    width: "100%"
                  }}
                  className="ag-theme-material"
                >
                  <input type="hidden" name="abc" />
                  <AgGrid
                    rowData={this.state.universalAttributes}
                    columnDefs={this.state.columnDefs}
                    handelEdit={this.handelEdit}
                    AgGridActionButton={UniversalAttributeGroupAction}
                  />
                </div>
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  renderUniversalAttributes = () => {
    const { classes } = this.props;
    const {
      name,
      description,
      sort_order,
      attribute_group
    } = this.state.attributeData;
    let required = _.cloneDeep(this.state.required);
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name").bind(this)
                    }}
                    id={"name_"}
                  />
                  <CustomInput
                    success={required.description === "success"}
                    error={required.description === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: this.handleTextChange("description", "")
                    }}
                    id={"email_"}
                  />
                  <CustomInput
                    success={required.sort_order === "success"}
                    error={required.sort_order === "error"}
                    labelText="Sort Order *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      value: sort_order,
                      onChange: this.handleTextChange("sort_order", ""),
                      success: required.sort_order === "success",
                      error: required.sort_order === "error"
                    }}
                    id={"sort_order_"}
                  />
                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="Brand *"
                      color={
                        required.attribute_group === "error" ? "red" : "default"
                      }
                    >
                      Attribute Group *
                    </InputLabel>
                    <Select
                      success={required.attribute_group === "success"}
                      error={required.attribute_group === "error"}
                      style={{ marginTop: "0px" }}
                      value={attribute_group}
                      onChange={this.handleTextChange("attribute_group")}
                    >
                      <MenuItem
                        disabled
                        success={required.attribute_group === "success"}
                        error={required.attribute_group === "error"}
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Attribute Group
                      </MenuItem>
                      {_.map(
                        this.props.AttributeGroup,
                        ({ id, name }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                              key={index + "_" + id}
                            >
                              {name}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  {this.props.Error && this.props.Error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}
                  <br />
                  <br />
                  <br />
                  <Button color="info" onClick={this.saveUniversalAttributes}>
                    Save Changes
                  </Button>
                  <Button onClick={this.handelIsAddNew}>Cancel</Button>
                  {this.props.Saving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Saving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    return this.state.isAddNew
      ? this.renderUniversalAttributes()
      : this.renderUniversalAttributeTable();
  }
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.brandState.universalAttributes.attributes,
  Loading: state.brandState.universalAttributes.isLoading,
  Error: state.brandState.error,
  Saving: state.brandState.universalAttributes.isSaving,
  UserEmail: state.authState.user.user,
  AttributeGroup: state.brandState.attributeGroups.attributeGroups
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...customInputStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UniversalAttributes);
