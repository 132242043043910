import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import { LoadingOverlay, Loader } from "react-overlay-loader";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Icon from "@material-ui/core/Icon";
import CardBody from "components/Card/CardBody.jsx";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

class CreateFolderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addFolder: {},
      isEdit: false,
      error: null
    };
  }
  componentDidMount() {
    const { data } = this.props;
    this.setState({
      addFolder: data
    });
  }

  componentWillReceiveProps(nextProps) {
    let error = "";
    if (nextProps.error) {
      error = nextProps.error;
    }
    this.setState({
      ...this.state,
      error: error
    });
  }

  render() {
    const { classes } = this.props;
    const {
      folder_name,
      folder_quota,
      parent_folder_id,
      tag,
      type,
      is_deleted
    } = this.state.addFolder;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: folder_name,
                      onChange: this.handleTextChange("folder_name", "")
                    }}
                  />
                  <CustomInput
                    labelText="Size *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: folder_quota,
                      onChange: this.handleTextChange("folder_quota", "")
                    }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={type == 1 ? true : false}
                        onClick={this.handleToggleStatus(type)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Private"
                  />
                  <br />
                  {this.state.error && this.state.error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button
                    color="info"
                    onClick={() => this.props.save(this.state.addFolder)}
                  >
                    {this.state.isEdit ? "Update Changes" : "Save Changes"}
                  </Button>
                  <Button onClick={() => this.props.close()}>Cancel</Button>
                  {this.props.Loader && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.Loader} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  handleTextChange = (key, type) => event => {
    this.setState({
      ...this.state,
      addFolder: {
        ...this.state.addFolder,
        [key]: event.target.value
      }
    });
  };

  handleToggleStatus = curObj => event => {
    let value = 0;
    if (event.target.checked == true) {
      value = 1;
    }
    this.setState({
      addFolder: {
        ...this.state.addFolder,
        type: value
      }
    });
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  error: state.assetManagementReducer.folder.error,
  Loader: state.assetManagementReducer.folder.loading
});
export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...basicsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateFolderComponent);
