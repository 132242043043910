import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  get_partner_menu_list: {
    list: [],
    loading: true,
    error: null
  },
  get_cms_menu_list: {
    list: [],
    loading: true,
    error: null
  }
};

const menuMasterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PARTNER_MENU_LIST_REQUEST:
      return {
        ...state,
        get_partner_menu_list: {
          saving: false,
          error: null,
          isSaved: false
        }
      };
    case actionTypes.GET_PARTNER_MENU_LIST_FAILURE:
      return {
        ...state,
        get_partner_menu_list: {
          list: [],
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_PARTNER_MENU_LIST_SUCCESS:
      return {
        ...state,
        get_partner_menu_list: {
          list: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.GET_CMS_MENU_LIST_REQUEST:
      return {
        ...state,
        get_cms_menu_list: {
          saving: false,
          error: null,
          isSaved: false
        }
      };
    case actionTypes.GET_CMS_MENU_LIST_FAILURE:
      return {
        ...state,
        get_cms_menu_list: {
          list: [],
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_CMS_MENU_LIST_SUCCESS:
      return {
        ...state,
        get_cms_menu_list: {
          list: action.payload,
          loading: false,
          error: null
        }
      };

    default:
      return state;
  }
};

export default menuMasterReducer;
