import * as partnerMenuService from "../../services/partnerMappingService";
import * as actionTypes from "./actionTypes";

export const savePartnerMappingAction = (
  selections,
  partnerid,
  userid,
  actionid
) => async dispatch => {
  dispatch(savePartnerMappingRequest());
  try {
    var success = await partnerMenuService.savePartnerMapping(
      selections,
      partnerid,
      userid,
      actionid
    );
    if (success.status && success.status === "1") {
      dispatch(savePartnerMappingSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(savePartnerMappingFailure(success.error));
    } else {
      dispatch(savePartnerMappingFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(savePartnerMappingFailure(error.message));
  }
};

const savePartnerMappingRequest = () => {
  return {
    type: actionTypes.SAVE_PARTNER_PARTNERS_MAPPING_REQUEST
  };
};

const savePartnerMappingSuccess = data => {
  return {
    type: actionTypes.SAVE_PARTNER_PARTNERS_MAPPING_SUCCESS,
    payload: data
  };
};

const savePartnerMappingFailure = error => {
  return {
    type: actionTypes.SAVE_PARTNER_PARTNERS_MAPPING_FAILURE,
    payload: error
  };
};

export const getPartnerMappingAction = partnerid => async dispatch => {
  dispatch(getPartnerMappingRequest());
  try {
    var success = await partnerMenuService.getPartnerMapping(partnerid);
    if (success.status && success.status === "1") {
      dispatch(getPartnerMappingSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(getPartnerMappingFailure(success.error));
    } else {
      dispatch(getPartnerMappingFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(getPartnerMappingFailure(error.message));
  }
};

const getPartnerMappingRequest = () => {
  return {
    type: actionTypes.GET_PARTNER_MAPPING_REQUEST
  };
};

const getPartnerMappingSuccess = data => {
  return {
    type: actionTypes.GET_PARTNER_MAPPING_SUCCESS,
    payload: data
  };
};

const getPartnerMappingFailure = error => {
  return {
    type: actionTypes.GET_PARTNER_MAPPING_FAILURE,
    payload: error
  };
};

export const removePartnerMappingAction = (
  selections,
  partnerid,
  userid,
  actionid
) => async dispatch => {
  dispatch(removePartnerMappingRequest());
  try {
    var success = await partnerMenuService.removePartnerMapping(
      selections,
      partnerid,
      userid,
      actionid
    );
    if (success.status && success.status === "1") {
      dispatch(removePartnerMappingSuccess(success.data));
    } else if (success.status && success.status === "0" && success.error) {
      dispatch(removePartnerMappingFailure(success.error));
    } else {
      dispatch(removePartnerMappingFailure("Some Error Occured."));
    }
  } catch (error) {
    dispatch(removePartnerMappingFailure(error.message));
  }
};

const removePartnerMappingRequest = () => {
  return {
    type: actionTypes.REMOVE_PARTNER_PARTNERS_MAPPING_REQUEST
  };
};

const removePartnerMappingSuccess = data => {
  return {
    type: actionTypes.REMOVE_PARTNER_PARTNERS_MAPPING_SUCCESS,
    payload: data
  };
};

const removePartnerMappingFailure = error => {
  return {
    type: actionTypes.REMOVE_PARTNER_PARTNERS_MAPPING_FAILURE,
    payload: error
  };
};
