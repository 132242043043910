import React, { Component } from "react";
import { Delete as DeleteIcon, Edit, List } from "@material-ui/icons";

class EventAction extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    invokeParentMethod() {
        const { data, context } = this.props;
        return (
            <div
                style={{
                    lineHeight: "60px"
                }}
            >
                <span onClick={() => this.performAction(data.id, 0)} title="Edit">
                    <Edit className="grid_action_btn" />
                </span>
                {context?.componentParent?.props?.context?.eventGrid?.props?.Role == 'BR' && <span title="Map" onClick={() => this.performAction(data.id, 2)}>
                    <List className="grid_action_btn" />
                </span>}
                <span onClick={() => this.performAction(data.id, 1)} title="Delete">
                    <DeleteIcon className="grid_action_btn" />
                </span>
            </div>
        );
    }

    performAction(id, value) {
        switch (value) {
            case 0:
                this.props.context.componentParent.handelEdit(id);
                break;
            case 1:
                this.props.context.componentParent.handelDelete(id);
                break;
            case 2:
                this.props.context.componentParent.map(id);
                break;
        }
    }

    render() {
        return (
            <div>
                <span>{this.invokeParentMethod()}</span>
            </div>
        );
    }
}
export default EventAction;