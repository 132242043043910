import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  subList: null,
  list: null,
  loading: false,
  error: null,
  isDeleted: false,
  file: {
    loading: false,
    error: null,
    data: null,
    isSaved: false
  },
  folder: {
    loading: false,
    error: null,
    data: null,
    isSaved: false
  },
  pagination: {
    page: 1,
    next: null,
    prev: null,
    totalpages: 1,
    trecords: 0,
    pagesize: 10
  }
};

const assetManagementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ASSET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        list: null,
        isDeleted: false,
        file: {
          ...state.file,
          isSaved: false
        },
        folder: {
          ...state.folder,
          isSaved: false
        },
        pagination: {
          page: 1,
          next: null,
          prev: null,
          totalpages: 1,
          trecords: 0,
          pagesize: 10
        }
      };

    case actionTypes.ASSET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        list: null
      };

    case actionTypes.ASSET_LIST_SUCCESS:
      let pagination = _.clone(state.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        loading: false,
        error: null,
        list: sdata,
        pagination: {
          ...pagination
        }
      };

    case actionTypes.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isDeleted: false
      };

    case actionTypes.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isDeleted: false
      };

    case actionTypes.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isDeleted: true
      };

    case actionTypes.CREATE_FOLDER_REQUEST:
      return {
        ...state,
        folder: {
          ...state.folder,
          loading: true,
          error: null,
          isSaved: false
        }
      };

    case actionTypes.CREATE_FOLDER_FAILURE:
      return {
        ...state,
        folder: {
          ...state.folder,
          loading: false,
          error: action.payload,
          isSaved: false
        }
      };

    case actionTypes.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        // list: data,
        folder: {
          ...state.folder,
          loading: false,
          error: null,
          isSaved: true
        }
      };

    case actionTypes.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        file: {
          ...state.file,
          loading: true,
          error: null,
          isSaved: false
        }
      };

    case actionTypes.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        file: {
          ...state.file,
          loading: false,
          error: action.payload,
          isSaved: false
        }
      };

    case actionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        // list: list,
        file: {
          ...state.file,
          loading: false,
          error: null,
          isSaved: true
        }
      };

    default:
      return state;
  }
};

export default assetManagementReducer;
