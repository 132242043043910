import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";
import FormLabel from "@material-ui/core/FormLabel";

import List from "@material-ui/icons/List";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { validationUtils } from "../../utils";

// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import ReactTooltip from "react-tooltip";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import ImageUpload from "../../../src/views/components/ImageUpload";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import ClassActionButton from "../../components/AgGrid/ClassActionButton";
import { UniversalAttributesComponent } from "../components";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const classUseFor = [
  {
    name: "Product",
    value: "1"
  },
  {
    name: "Services",
    value: "2"
  },
  {
    name: "Both",
    value: "3"
  }
];

class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: null,
      pagination: {
        page: "1",
        totalpage: "1",
        next: false,
        prev: false,
        pagesize: 25
      },
      isAddNew: false,
      addClassData: {
        name: "",
        description: "",
        is_active: false,
        is_Deleted: false,
        parent_class: "",
        class_used_by: null,
        image: ""
      },
      required: {
        name: "",
        description: "",
        class_used_by: ""
      },
      classData: [],
      error: null,
      isAttributeGroupOpen: false,
      openDeleteDialog: false,
      classId: null,
      classValue: null,
      selectedUAIndexes: [],
      mappingData: {
        classes: null,
        attribute_group: null,
        description: "",
        is_active: true
      },
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          width: 237,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Description",
          field: "description",
          width: 237,
          resizable: true,
          sortable: true
        },
        {
          headerName: "Status",
          field: "is_active",
          width: 238,
          resizable: true,
          sortable: true
          // filter: "agTextColumnFilter",
          // filterParams: {
          //     defaultToNothingSelected: true,
          // }
        },

        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 278,
          filter: false
        }
      ]
    };
    this.updateGridData = this.updateGridData.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.List && !_.isEqual(this.props.List, nextProps.List)) {
        const classData = nextProps.List;
        this.setState(() => ({
          classData: classData,
          pagination: nextProps.pagination
        }));
      }

      if (nextProps.Error && nextProps.Error.trim() !== "") {
        this.setState(() => ({
          error: nextProps.Error,
          isAddNew: true
        }));
      }

      if (
        nextProps.classData &&
        !_.isEqual(this.props.classData, nextProps.classData) &&
        !nextProps.isImageDeleted
      ) {
        const data = nextProps.classData;
        this.setState({
          addClassData: {
            name: data.name,
            description: data.description,
            // index: index,
            id: data.id,
            is_active: data.is_active,
            is_Deleted: false,
            parent_class: data.parent_class.id,
            image: data.image,
            class_used_by: data.class_type
          },
          isAddNew: true,
          isEdit: true
        });
      }
      if (nextProps.isSaved) {
        this.setState(
          {
            isAddNew: false,
            isEdit: true
          },
          () => {
            this.props.actions.getClassList(
              this.props.PartnerId,
              null,
              true,
              this.state.pagination.page
            );
          }
        );
      }
      if (nextProps.isDeleted) {
        this.setState(() => ({
          openDeleteDialog: false
        }));
      }

      if (nextProps.isImageDeleted) {
        const { addClassData } = this.state;
        delete addClassData.image;
        this.setState(
          {
            addClassData: {
              ...this.state.addClassData,
              image: null
            }
          },
          () => {
            this.props.actions.denyRemoveImageAction();
          }
        );
      }
    }
  }

  componentDidMount() {
    this.props.actions.getClassList(
      this.props.PartnerId,
      null,
      true,
      this.state.pagination.page
    );
  }

  handelIsAddNew = () => {
    this.props.actions.getClassList(this.props.PartnerId, null, true);
    this.setState({
      isAddNew: !this.state.isAddNew,
      addClassData: {
        name: "",
        description: "",
        is_active: false,
        is_Deleted: false,
        parent_class: "",
        image: ""
      }
    });
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      addClassData: {
        ...this.state.addClassData,
        [key]: event.target.value
      }
    });
  };

  handleToggleStatus = curObj => event => {
    this.setState({
      addClassData: {
        ...this.state.addClassData,
        is_active: event.target.checked
      }
    });
  };

  openClassComponentDialog = id => {
    var { classData } = this.state;
    let index = classData.findIndex(p => p.id === id);
    if (classData) {
      this.setState(() => ({
        isAttributeGroupOpen: true,
        classValue: classData[index]
      }));
    }
  };

  saveClass = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addClassData;
      this.props.actions.saveClass(
        this.props.UserId,
        savingData,
        this.props.PartnerId
      );
    }
  };

  handelEdit = id => {
    this.props.actions.getClassList(this.props.PartnerId, null, true);
    this.props.actions.getClassByIdAction(id);
  };

  deleteDialog = (state, Id, Index) => {
    this.setState({
      openDeleteDialog: state,
      classId: Id
    });
  };

  deleteSegment = Id => {
    this.props.actions.deleteClass(this.props.UserId, this.state.classId);
  };

  render() {
    return this.state.isAddNew ? this.renderClass() : this.renderClassTable();
  }

  closeAttributeGroupComponentDialog = () => {
    this.setState(() => ({
      isAttributeGroupOpen: false,
      classValue: null
    }));
  };

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addClassData.hasOwnProperty("name") ||
      (this.state.addClassData.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.addClassData.hasOwnProperty("description") ||
      (this.state.addClassData.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }
    this.setState({ required: reqData });
    return isValid;
  }

  saveHandler = () => {
    this.state.mappingData.classes = this.state.classValue.id;
    this.state.mappingData.attribute_group = this.state.selectedUAIndexes;
    this.props.actions.mapClassUA(this.props.UserId, this.state.mappingData);
    console.log(this.state.mappingData);
  };

  attributeGroupComponentDilog = () => {
    const { classes } = this.props;
    var title = "";
    if (this.state.classValue != null) {
      title = "Assign Attribute Groups to Class";
    }
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.isAttributeGroupOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.closeAttributeGroupComponentDialog()}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{title}</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <LoadingOverlay>
            <DialogContentText>
              <UniversalAttributesComponent
                open={this.state.isAttributeGroupOpen}
                onClose={this.closeAttributeGroupComponentDialog}
                classValue={this.state.classValue}
                selectedUAIndexes={this.state.selectedUAIndexes}
              />
            </DialogContentText>

            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.saveHandler()} color="danger" simple>
            Save
          </Button>
          <Button
            onClick={() => this.closeAttributeGroupComponentDialog()}
            color="danger"
            simple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  deleteDilog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        disableBackdropClick={true}
        onClose={() => this.openModalDialog("openDialog", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LoadingOverlay>
            <DialogContentText>
              Are you sure you want to delete?{" "}
            </DialogContentText>
            <DialogActions>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                color="primary"
                onClick={() => this.deleteSegment()}
              >
                Delete
              </Button>
              <Button
                style={{
                  paddingLeft: "10px",
                  paddingRight: "5px"
                }}
                className={classes.marginRight}
                simple
                onClick={() => this.deleteDialog(false, "", "")}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
            {this.props.Deleting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.Deleting} text="Deleting.." />
          </LoadingOverlay>
        </DialogContent>
      </Dialog>
    );
  };

  handelImageChange = file => {
    if (file instanceof Blob) {
      let reader = new FileReader();
      let { addClassData } = this.state;
      let original_name = file.name;
      let fileSize = file.size;
      addClassData["image"] = file;
      addClassData["file_size"] = fileSize;
      addClassData["original_file_name"] = original_name;
      this.setState({
        addClassData: addClassData
      });
      // reader.onloadend = () => {
      //     addClassData['image'] = reader.result;
      //     addClassData['file_size'] = fileSize;
      //     addClassData['original_file_name'] = original_name;
      //     this.setState({
      //         addClassData: addClassData
      //     });
      // };
      // reader.readAsDataURL(file);
    } else {
      let { addClassData } = this.state;
      addClassData.image = file.url;
      if (file.is_asset) {
        addClassData.asset_id = file.id;
      }
      addClassData.file_size = file.file_size;
      addClassData.original_file_name = file.original_file_name;
      this.setState({
        ...this.state,
        addClassData: addClassData
      });
    }
  };

  // handelImageRemove = () => {
  //     let { addClassData } = this.state;
  //     delete addClassData.image;
  //     this.setState({
  //         addClassData: addClassData
  //     });
  // }

  handelImageRemove = () => {
    let { image } = this.state.addClassData;

    if (image instanceof Blob) {
      this.setState({
        addClassData: {
          ...this.state.addClassData,
          image: null
        }
      });
    } else if (image instanceof Object) {
      this.props.actions.removeImageAction(image.id);
    }
  };

  renderClass = () => {
    const { classes } = this.props;
    const {
      name,
      description,
      is_active,
      image,
      parent_class,
      class_used_by
    } = this.state.addClassData;
    const { required } = this.state;
    let classData = [];
    if (this.state.classData && this.state.classData.length > 0) {
      let res = this.state.classData.filter(
        cls => cls.id != this.state.addClassData.id
      );
      if (this.state.isEdit) {
        for (let i = 0; i < res.length; i++) {
          let obj = res[i];
          let parent = obj.parent_class;
          if (parent && parent.id && parent.id === this.state.addClassData.id) {
            continue;
          } else {
            classData.push(res[i]);
          }
        }
      } else {
        classData = res;
      }
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon style={{ fontSize: "36px" }}>account_circle</Icon>
              </CardIcon>
              <p className={classes.cardIconTitle}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                    id={"name_"}
                  />
                  <CustomInput
                    success={required.description === "success"}
                    error={required.description === "error"}
                    labelText="Description *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: this.handleTextChange("description", "")
                    }}
                    id={"description_"}
                  />
                  <br />
                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="Parent Class"
                    >
                      Parent Class
                    </InputLabel>
                    <Select
                      style={{ marginTop: "0px" }}
                      value={parent_class}
                      onChange={this.handleTextChange("parent_class", "")}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem
                        }}
                        style={{
                          opacity: "0.5"
                        }}
                        value={null}
                      >
                        ---- Clear Selection ----
                      </MenuItem>
                      {_.map(classData, ({ id, name }, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={id}
                            key={index + "_" + id}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <br />
                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="Parent Class"
                    >
                      Class Type
                    </InputLabel>
                    <Select
                      style={{ marginTop: "0px" }}
                      value={class_used_by}
                      onChange={this.handleTextChange("class_used_by", "")}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Class Type
                      </MenuItem>
                      {_.map(classUseFor, ({ value, name }, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={value}
                            key={index + "_" + value}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus(is_active)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />
                  <legend>
                    {" "}
                    Upload Logo&nbsp;
                    <span style={{ color: "#AAAAAA", fontSize: "13px" }}>
                      (Optimal logo size to upload should be 540x240 at 72 dpi)
                    </span>
                  </legend>
                  <ImageUpload
                    addButtonProps={{
                      round: true
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true
                    }}
                    imgUrl={
                      image instanceof Object
                        ? image instanceof Blob
                          ? image
                          : image.url
                        : image
                    }
                    onimageRemove={this.handelImageRemove}
                    onChange={this.handelImageChange}
                  />

                  <br />
                  {this.props.Error && this.props.Error.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}

                  <br />
                  <Button color="info" onClick={this.saveClass}>
                    {this.state.isEdit ? "Save" : "Save"}
                  </Button>
                  <Button onClick={this.closeModal}>Cancel</Button>
                  {this.props.isSaving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.isSaving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  closeModal = () => {
    this.setState(
      {
        isAddNew: false,
        isEdit: false
      },
      () => {
        this.props.actions.getClassList(
          this.props.PartnerId,
          null,
          true,
          this.state.pagination.page
        );
      }
    );
  };
  renderClassTable = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.deleteDilog()}
        {this.attributeGroupComponentDilog()}
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Class
                </p>
              </div>
            </CardHeader>
            <CardBody>
              <AgGrid
                rowData={this.state.classData}
                columnDefs={this.state.columnDefs}
                handelEdit={this.handelEdit}
                AgGridActionButton={ClassActionButton}
                openClassComponentDialog={this.openClassComponentDialog}
                update={this.updateGridData}
                isServerPagination={true}
                pagination={this.state.pagination}
                loading={this.props.Loading}
                search={this.searchText}
                new={this.handelIsAddNew}
                isAddBtn={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateGridData(page) {
    this.props.actions.getClassList(
      this.props.PartnerId,
      null,
      true,
      page,
      this.state.searchtext
    );
  }

  searchText(param) {
    this.setState(
      {
        searchtext: param
      },
      () => {
        let page = 1;
        this.props.actions.getClassList(
          this.props.PartnerId,
          null,
          true,
          page,
          param
        );
      }
    );
  }

  getTableData = () => {
    const { attributeGroups } = [];
    const { classData } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(classData, ({ id, name, description, is_active }, index) => {
      returnData.push([
        <span>
          {
            //eslint-disable-next-line
            <a href="javaScript:void(0)" className={classes.tdNameAnchor}>
              {name}
            </a>
          }
        </span>,
        <span>{description}</span>,
        <span>{is_active ? "Active" : "Inactive"}</span>,
        <span>
          <ReactTooltip id="mapUA">
            <span>View or edit mapping with Attribute Group</span>
          </ReactTooltip>

          <Button
            color="primary"
            data-tip
            data-for="mapUA"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.openClassComponentDialog(index)}
          >
            <List className={classes.icons} style={{ marginRight: "0px" }} />
          </Button>

          <ReactTooltip id="editClass">
            <span>Edit class</span>
          </ReactTooltip>
          <Button
            color="success"
            data-tip
            data-for="editClass"
            className={classes.actionButton}
            onClick={() => this.handelEdit(index)}
          >
            <CreateIcon className={classes.icon} />
          </Button>

          <ReactTooltip id="deleteClass">
            <span>Delete Class</span>
          </ReactTooltip>
          <Button
            color="danger"
            data-tip
            data-for="deleteClass"
            className={classes.marginRight}
            style={{ padding: "5px" }}
            onClick={() => this.deleteDialog(true, id)}
          >
            <ClearIcon
              className={classes.icons}
              style={{ marginRight: "0px" }}
            />
          </Button>
        </span>
      ]);
    });
    return returnData;
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.classReducer.list,
  pagination: state.classReducer.pagination,
  Loading: state.classReducer.loading,
  ListError: state.classReducer.error,
  isSaved: state.classReducer.isSaved,
  isDeleted: state.classReducer.isDeleted,
  UserId: state.authState.user.user,
  PartnerId: state.authState.user.partner_id,
  isSaving: state.classReducer.isSaving,
  isImageDeleted: state.appMenuReducer.removeImage.isDeleted,
  classData: state.classReducer.class_by_id.data,
  classDataError: state.classReducer.class_by_id.error,
  classDataLoading: state.classReducer.class_by_id.loading
});

const condition = authUser => authUser.role === rolesNames.RoleMaster.ADMIN;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Class);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
