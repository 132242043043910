import * as templatesServices from "../../services/templatesServices";
import * as actionTypes from "./actionTypes";

export const getTemplateList = () => async dispatch => {
  dispatch(getTemplatesRequest());
  try {
    const success = await templatesServices.gettemplateList();
    dispatch(getTemplatesSuccess(success.data));
  } catch (error) {
    dispatch(getTemplatesFailure(error.message));
  }
};
export const saveTemplate = (id, data) => async dispatch => {
  dispatch(saveTemplatesRequest());
  try {
    const success = await templatesServices.savetemplate(id, data);

    dispatch(saveTemplatesSuccess(success.data));
  } catch (error) {
    dispatch(saveTemplatesFailure(error.message));
  }
};

const saveTemplatesRequest = () => {
  return {
    type: actionTypes.SAVE_TEMPLATES_REQUEST
  };
};

const saveTemplatesSuccess = data => {
  return {
    type: actionTypes.SAVE_TEMPLATES_SUCCESS,
    payload: data
  };
};

const saveTemplatesFailure = error => {
  return {
    type: actionTypes.SAVE_TEMPLATES_FAILURE,
    payload: error
  };
};

const getTemplatesRequest = data => {
  return {
    type: actionTypes.GET_TEMPLATES_REQUEST,
    payload: data
  };
};

const getTemplatesSuccess = data => {
  return {
    type: actionTypes.GET_TEMPLATES_SUCCESS,
    payload: data
  };
};

const getTemplatesFailure = error => {
  return {
    type: actionTypes.GET_TEMPLATES_FAILURE,
    payload: error
  };
};
