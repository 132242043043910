// import axios from 'axios';
import axios from "../utils/axios.jsx";
export const saveProductAssignment = async (Savedata, userId, partnerId) => {
  try {
    let data = new FormData();

    data.append("data", JSON.stringify(Savedata));
    data.append("userid", userId);
    data.append("partnerid", partnerId);
    const response = await axios.post("/api/products/saveproduct/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductAssignmentList = async (
  userId,
  islist,
  page,
  searchtext
) => {
  try {
    let url =
      "/api/products/getall/byretailer/?userid=" + userId + "&islist=" + islist;
    if (page) {
      url = url + "&page=" + page;
    }
    if (searchtext) {
      url = url + "&search=" + searchtext;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveProduct = async (Savedata, userId, files) => {
  try {
    let data = new FormData();
    data.append("data", JSON.stringify(Savedata));
    data.append("userid", userId);
    files.forEach(file => {
      data.append(file.name, file);
    });
    const response = await axios.post("/api/retailer/products/save/", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductById = async productid => {
  try {
    const response = await axios.get(
      "/api/retailer/product/get/?productid=" + productid
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRetailerRelatedBrand = async partnerid => {
  try {
    const response = await axios.get(
      "/api/partner/brand/get/?partnerid=" + partnerid
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBrandProductById = async producttemplateid => {
  try {
    const response = await axios.get(
      "/api/retailer/brand/product/get/?product_id=" + producttemplateid
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductTemplateList = async (page, searchtext) => {
  try {
    let url = "/api/partner/product-template/list/?page=" + String(page);
    if (searchtext) {
      url = url + "&search=" + searchtext;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const markProductActive = async (id, userid) => {
  try {
    const response = await axios.get(
      "/api/partner/product/mark-active/?productid=" + id + "&userid=" + userid
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProducts = async (id) => {
  try {
    const response = await axios.get("/api/partner/product/delete/?productid=" + id);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAttributesByProductId = async productid => {
  try {
    const response = await axios.get(
      "/api/partner/product/local-attribute/get-by-productid/?productid=" +
      productid,
      null,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
