import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@material-ui/core/FormLabel";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { validationUtils } from "../../../utils";
import { Form } from "react-formio";

import NavPills from "components/NavPills/NavPills.jsx";
import ComponentSelector from "./component-selector";

import headerSample from "assets/img/header-sample.png";
import footerSample from "assets/img/footer-sample.png";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import AssetManagementComponent from "../../components/assetManagementComponent.jsx";
import config from "../../../constants/config";

import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as services from "../../../services/languageServices";

class CMSPageView extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.getSelectedImage = this.getSelectedImage.bind(this);
    this.state = {
      selectedId: "",
      saveAndStay: false,
      files: [],
      isAddNew: false,
      industryList: [],
      currentStep: 0,
      language: "en-us", /// forcing default value as english
      language_submission: [],
      sections: [],
      cms_pages: {
        industry: null,
        name: "",
        slug: "",
        description: "",
        is_active: false,
        id: "",
        sections: [],
        submission: [],
        is_landing_page: false,
        is_system_page: false
      },
      data: {},
      files: [],
      required: {
        slug: "",
        name: "",
        description: "",
        is_active: "",
        is_landing_page: "",
        is_system_page: false
      },
      NotificationData: [],
      error: null,
      openDeleteDialog: false,
      NotificationId: null,
      NotificationValue: null,
      selectedUAIndexes: [],
      languageList: [],
      templateList: [],
      saveBasicRequested: false,
      formUpdate: 0
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && !_.isEqual(this.props, nextProps)) {
      if (nextProps.pagedetail) {
        this.setEditData(nextProps.pagedetail);
      }

      if (this.state.saveBasicRequested) {
        if (!nextProps.basicError && !nextProps.basicSaving) {
          if (this.state.saveAndStay) {
            this.setState(
              () => ({
                saveBasicRequested: false
              }),
              function() {}
            );
          } else {
            this.setState(
              () => ({
                saveBasicRequested: false,
                currentStep: this.state.currentStep + 1
              }),
              function() {
                if (this.state.currentStep == 3) {
                  this.CancelAddEdit();
                }

                if (this.state.cms_pages.is_system_page) {
                  this.setActiveTab(0);
                  this.CancelAddEdit();
                }
              }
            );
          }

          this.setState({
            saveAndStay: false
          });
        }
      }
    }
  }

  setEditData(cmpnn) {
    let sections = _.orderBy(cmpnn.sections, ["sort_order"], ["asc"]);
    this.setState(
      () => ({
        ...this.state,
        cms_pages: {
          industry: cmpnn.industry,
          id: cmpnn.id,
          name: cmpnn.name,
          slug: cmpnn.slug,
          description: cmpnn.description,
          is_active: cmpnn.is_active,
          sections: sections,
          submission: cmpnn.data,
          is_landing_page: cmpnn.is_landing_page,
          is_system_page: cmpnn.is_system_page
        },
        sections: sections
      }),
      () => {
        let langSubmission = this.setSubmissions(this.state.language);
        this.setState({
          ...this.state,
          language_submission: langSubmission
        });
      }
    );
  }

  refreshLanguageList() {
    this.setState({
      loading: true
    });

    let partner_id = "";
    if (this.props.user && this.props.user.role !== "AD") {
      partner_id = this.props.user.partner_id;
    }

    services
      .getLanguages(null, partner_id, false, true, this.state.searchtext)
      .then(response => {
        if (response && response.status === "1") {
          this.setState({
            languageList: response.data
          });
        } else {
          this.setState({
            error: "An error has occured while fetching record"
          });
        }
      })
      .catch(reason => {
        this.setState({
          error: "An error has occured while fetching record"
        });

        console.error("Meta Data List api call error", reason);
      })
      .finally(info => {
        this.setState({
          loading: false
        });

        console.log(info);
      });
  }

  componentDidMount() {
    if (this.props.componentid) {
      if (this.props.user && this.props.user.role !== "AD") {
        this.props.actions.getPartnerCMSPageDetail("", this.props.componentid);
      } else {
        this.props.actions.getCMSPageDetail(this.props.componentid);
      }
    } else {
      this.props.actions.addingNewPage(this.props.componentid);
    }

    if (this.props.user && this.props.user.role === "AD") {
      this.props.actions.getIndustriesTypeList(this.props.UserId, "list");
    }

    let partner_id = null;
    if (this.props.user.role !== "AD") {
      partner_id = this.props.user.partner_id;
    }

    this.props.actions.getCMSComponents(null, true, null, null, partner_id);

    this.refreshLanguageList();
  }

  componentWillMount() {}

  handelIsAddNew = () => {
    this.setState({
      ...this.state,
      industryList: [],
      cms_pages: {
        industry: [],
        id: "",
        name: "",
        slug: "",
        description: "",
        is_active: false,
        sections: [],
        submission: [],
        is_landing_page: false,
        is_system_page: false
      },
      sections: [],
      required: {
        industry: "",
        name: "",
        slug: "",
        description: ""
      },
      isEdit: false,
      currentStep: 0
    });
  };

  string_to_slug = str => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-") // collapse dashes
      .replace(/^-+/, ""); // trim - from start of text
    // .replace(/-+$/, ""); // trim - from end of text

    return str;
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;

    let new_slug = this.state.cms_pages.slug;

    if (!this.state.cms_pages.id && key === "name") {
      new_slug = this.string_to_slug(event.target.value);
      reqData["slug"] = "success";
    }

    this.setState({
      ...this.state,
      required: reqData
    });

    if (key === "slug") new_slug = this.string_to_slug(event.target.value);

    this.setState({
      ...this.state,
      cms_pages: {
        ...this.state.cms_pages,
        [key]: event.target.value,
        slug: new_slug
      }
    });
  };

  handleToggleStatus = key => event => {
    this.setState({
      ...this.state,
      cms_pages: {
        ...this.state.cms_pages,
        [key]: event.target.checked
      }
    });
  };

  savePageDetails = (step, savingData) => {
    this.setState(
      {
        saveBasicRequested: true
      },
      () => {
        if (this.props.user && this.props.user.role !== "AD") {
          this.props.actions.savePartnerPageDetails(
            this.props.Userid,
            step,
            savingData,
            this.props.user.partner_id,
            this.state.files
          );
        } else {
          this.props.actions.saveBasicDetails(
            this.props.Userid,
            step,
            savingData,
            this.props.user.partner_id,
            this.state.files
          );
        }
      }
    );
  };

  saveBasicDetails = () => {
    if (this.validateIsRequired()) {
      let savingData = this.state.cms_pages;
      this.savePageDetails(0, savingData);
    }
  };

  saveComponents = () => {
    if (this.validateIsRequired()) {
      let savingData = this.state.cms_pages;

      if (savingData.submission && savingData.submission.length > 0) {
        for (let i = 0; i < savingData.submission.length; i++) {
          let currentLangData = savingData.submission[i];
          if (
            !currentLangData ||
            !currentLangData.cms_data ||
            _.isEmpty(currentLangData.cms_data)
          )
            continue;

          for (var key in currentLangData.cms_data) {
            // skip loop if the property is from prototype
            if (!currentLangData.cms_data.hasOwnProperty(key)) continue;

            var obj = currentLangData.cms_data[key];

            let sectionIndex = savingData.sections.findIndex(
              e => e.component.key === obj.key && e.sort_order == obj.sort_order
            );

            if (sectionIndex === -1) {
              delete currentLangData.cms_data[key];
            }
          }
        }
      }

      console.log("Save Data", savingData);

      this.savePageDetails(1, savingData);
    } else {
      this.setActiveTab(0);
    }
  };

  saveForms = () => {
    if (this.validateIsRequired()) {
      this.setState({
        saveAndStay: true
      });

      let savingData = this.state.cms_pages;
      this.selectFilesFromComponent(savingData.submission);
      this.savePageDetails(2, savingData);
    } else {
      this.setActiveTab(0);
    }
  };

  saveFormsAndClose = () => {
    if (this.validateIsRequired()) {
      let savingData = this.state.cms_pages;
      this.selectFilesFromComponent(savingData.submission);
      this.savePageDetails(2, savingData);
    } else {
      this.setActiveTab(0);
    }
  };

  selectFilesFromComponent(values) {
    if (values instanceof Array) {
      values.forEach(x => {
        this.selectFilesFromComponent(x);
      });
    } else if (values instanceof Object) {
      let keys = Object.keys(values);
      keys.forEach(x => {
        if (~x.indexOf("_image")) {
          this.selectFilesFromComponent(values[x]);
        } else {
          if (x == "url" && typeof values[x] == "string") {
            let file = this.b64toBlob(values[x]);
            if (file && file instanceof Blob) {
              file.name = values["name"];
              let { files } = this.state;
              files.push(file);
              this.setState({
                files: files
              });
            }
            values[x] = file;
          }
          this.selectFilesFromComponent(values[x]);
        }
      });
    }
    return values;
  }

  b64toBlob(dataURI) {
    try {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/jpeg" });
    } catch (err) {
      return dataURI;
    }
  }

  handelEdit = id => {};

  CancelAddEdit = () => {
    this.props.addNew(false);
  };

  render() {
    return this.renderTabs();
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.cms_pages.hasOwnProperty("name") ||
      (this.state.cms_pages.name.trim() === "" || reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    if (
      !this.state.cms_pages.hasOwnProperty("slug") ||
      (this.state.cms_pages.slug.trim() === "" || reqData["slug"] === "error")
    ) {
      reqData["slug"] = "error";
      isValid = false;
    }

    if (
      !this.state.cms_pages.hasOwnProperty("description") ||
      (this.state.cms_pages.description.trim() === "" ||
        reqData["description"] === "error")
    ) {
      reqData["description"] = "error";
      isValid = false;
    }

    this.setState({
      ...this.state,
      required: reqData
    });
    return isValid;
  }

  handleSelectChange = key => event => {
    var validData = true;
    if (!event.target.value) {
      validData = false;
    }

    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({
      ...this.state,
      required: reqData
    });

    this.setState({
      ...this.state,
      formUpdate: this.state.formUpdate,
      cms_pages: {
        ...this.state.cms_pages,
        [key]: event.target.value
      }
    });
  };

  getModelTabs = () => {
    let tabs = [];

    if (!this.state.cms_pages.is_system_page) {
      tabs.push({
        tabButton: "1. Basic Details",
        tabContent: this.renderForm()
      });

      tabs.push({
        tabButton: "2. Sections/Layout",
        tabContent: this.getPrimaryTab()
      });

      tabs.push({
        tabButton: "3. Content",
        tabContent: this.getFormsTab()
      });
    } else {
      tabs.push({
        tabButton: "Basic Details",
        tabContent: this.renderForm()
      });
    }

    return tabs;
  };

  setActiveTab = active => {
    this.setState({
      currentStep: active
    });
  };

  renderTabs = () => {
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <NavPills
          setActive={this.setActiveTab}
          active={this.state.currentStep}
          tabs={this.getModelTabs()}
        />

        <div style={{ display: "none" }}>{this.state.currentStep}</div>
      </GridItem>
    );
  };

  handlePrimaryTabRowChange = (key, type, index, value) => {
    let validData = true;

    if (type && value) {
      if (!validationUtils.validateData(type, value)) {
        validData = false;
      }
    }

    let primary_tab_data = this.state.cms_pages.sections;

    if (!validData) {
      primary_tab_data[index][key] = primary_tab_data[index][key];
    } else {
      primary_tab_data[index][key] = value;
    }

    this.setState(
      {
        ...this.state,
        cms_pages: {
          ...this.state.cms_pages,
          sections: primary_tab_data
        },
        sections: _.orderBy(primary_tab_data, ["sort_order"], ["asc"])
      },
      () => {
        let langSubmission = this.setSubmissions(this.state.language);
        this.setState({
          ...this.state,
          language_submission: langSubmission
        });
      }
    );
  };

  handleAddPrimaryTabRow = () => {
    let primary_tab_data = this.state.cms_pages.sections;

    if (!primary_tab_data) {
      primary_tab_data = new Array();
    }

    primary_tab_data.push({
      sort_order: 9999,
      component: {
        id: ""
      }
    });

    this.setState(
      {
        ...this.state,
        cms_pages: {
          ...this.state.cms_pages,
          sections: primary_tab_data
        },
        sections: _.orderBy(primary_tab_data, ["sort_order"], ["asc"])
      },
      () => {
        let langSubmission = this.setSubmissions(this.state.language);
        this.setState({
          ...this.state,
          language_submission: langSubmission
        });
      }
    );
  };

  handlePrimaryTabRowMappingFieldChange = (key, index, sort_order, value) => {
    let primary_tab_data = this.state.cms_pages.sections;
    primary_tab_data[index][key]["id"] = value;
    // primary_tab_data[index]["sort_order"] = index;

    this.setState(
      {
        ...this.state,
        cms_pages: {
          ...this.state.cms_pages,
          sections: primary_tab_data
        },
        sections: _.orderBy(primary_tab_data, ["sort_order"], ["asc"])
      },
      () => {
        let sectionKey = primary_tab_data[index]["component"]["key"];

        let updated_submission_key = this.handleComponentChange(
          sectionKey,
          sort_order
        );

        this.setState(
          {
            ...this.state,
            cms_pages: {
              ...this.state.cms_pages,
              submission: updated_submission_key
            }
          },
          () => {
            let langSubmission = this.setSubmissions(this.state.language);
            this.setState({
              ...this.state,
              language_submission: langSubmission
            });
          }
        );
      }
    );
  };

  handlePrimaryTabRowSortOrderChange = (index, order_number) => {
    let primary_tab_data = this.state.cms_pages.sections;
    let oldSortOrder = primary_tab_data[index]["sort_order"];
    let newSortOrder = order_number;
    primary_tab_data[index]["sort_order"] = order_number;

    this.setState(
      {
        ...this.state,
        cms_pages: {
          ...this.state.cms_pages,
          sections: primary_tab_data
        },
        sections: _.orderBy(primary_tab_data, ["sort_order"], ["asc"])
      },
      () => {
        let sectionKey = primary_tab_data[index]["component"]["key"];

        let updated_submission_key = this.handleKeyIndexChange(
          sectionKey,
          oldSortOrder,
          newSortOrder
        );

        console.log(
          "this.state.cms_pages.submission",
          this.state.cms_pages.submission
        );
        console.log("updated_submission_key", updated_submission_key);

        this.setState(
          {
            ...this.state,
            cms_pages: {
              ...this.state.cms_pages,
              submission: updated_submission_key
            }
          },
          () => {
            let langSubmission = this.setSubmissions(this.state.language);
            this.setState({
              ...this.state,
              language_submission: langSubmission
            });
          }
        );
      }
    );
  };

  handleComponentChange = (component_key, index) => {
    if (!this.state.cms_pages || !this.state.cms_pages.submission) return null;

    let existingSubmission = this.state.cms_pages.submission;

    let newKey = component_key + "_" + index;

    for (let i = 0; i < existingSubmission.length; i++) {
      let currentData = existingSubmission[i].cms_data;
      if (!currentData || !currentData[newKey]) continue;

      delete currentData[newKey];
      existingSubmission[i].cms_data = Object.assign({}, currentData);
    }

    return existingSubmission;
  };

  handleKeyIndexChange = (section_key, old_index, new_index) => {
    if (!this.state.cms_pages || !this.state.cms_pages.submission) return null;

    let existingSubmission = this.state.cms_pages.submission;

    let oldKey = section_key + "_" + old_index;
    let newKey = section_key + "_" + new_index;

    for (let i = 0; i < existingSubmission.length; i++) {
      let currentData = existingSubmission[i].cms_data;
      if (!currentData || !currentData[oldKey]) continue;

      let currentKeyValue = currentData[oldKey];
      currentKeyValue["sort_order"] = new_index
        ? parseInt(new_index)
        : new_index;
      currentData[newKey] = currentKeyValue;

      delete currentData[oldKey];
      existingSubmission[i].cms_data = Object.assign({}, currentData);
    }

    return existingSubmission;
  };

  handlePrimaryTabRowRemove = index => {
    let primary_tab_data = this.state.cms_pages.sections;

    let sectionId = "";
    if (primary_tab_data[index]) {
      sectionId =
        primary_tab_data[index].component.key +
        "_" +
        primary_tab_data[index].sort_order;
    }

    primary_tab_data.splice(index, 1);

    let existingData;
    if (
      this.state.cms_pages.submission &&
      this.state.cms_pages.submission.length > 0
    ) {
      existingData = this.state.cms_pages.submission;

      for (let i = 0; i < existingData.length; i++) {
        let currentData = existingData[i].cms_data;
        if (!currentData || !currentData[sectionId]) continue;

        delete currentData[sectionId];
      }
    }

    let clonedDeletedItems = _.cloneDeep(existingData);

    this.setState(
      {
        ...this.state,
        cms_pages: {
          ...this.state.cms_pages,
          sections: primary_tab_data,
          submission: clonedDeletedItems
        },
        sections: _.orderBy(primary_tab_data, ["sort_order"], ["asc"])
      },
      () => {
        let langSubmission = this.setSubmissions(this.state.language);
        this.setState({
          ...this.state,
          language_submission: langSubmission
        });
      }
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.formUpdate != nextState.formUpdate) {
      return false;
    }

    return true;
  }

  onChangeFormValue = (value, key, id, sort_order, language) => {
    if (!value || !value.changed || !value.data) return;

    let existingData = null,
      formData = [];
    if (
      this.state.cms_pages.submission &&
      this.state.cms_pages.submission.length > 0
    ) {
      formData = this.state.cms_pages.submission;
    }

    if (formData && formData.length > 0) {
      existingData = formData.find(element => element.language === language);
    }

    if (!existingData) {
      existingData = {
        language: language,
        cms_data: {}
      };

      formData.push(existingData);
    }

    console.log("key", key);
    console.log("value.data", value.data);
    existingData.cms_data = {
      ...existingData.cms_data,
      [id]: {
        key: key,
        sort_order: sort_order,
        data: value.data
      }
    };

    let formDataClone = _.cloneDeep(formData);

    this.setState(
      {
        formUpdate: this.state.formUpdate + 1,
        cms_pages: {
          ...this.state.cms_pages,
          submission: formDataClone
        }
      },
      () => {}
    );
  };

  handleLanguageChange = () => event => {
    //// aaaaa

    let langSubmission = this.setSubmissions(event.target.value);

    this.setState({
      ...this.state,
      language: event.target.value,
      language_submission: langSubmission
    });
  };

  setSubmissions = langCode => {
    if (
      !this.state.cms_pages ||
      !this.state.cms_pages.submission ||
      _.isEmpty(this.state.cms_pages.submission)
    ) {
      return null;
    }

    let formData, existingData;

    formData = this.state.cms_pages.submission;

    if (!formData || _.isEmpty(formData)) return null;

    existingData = formData.find(element => element.language === langCode);

    if (!existingData || _.isEmpty(existingData)) return null;

    let languageDataClone = _.cloneDeep(existingData.cms_data);
    return languageDataClone;
  };

  getSubmissionData = key => {
    let retVal = {
      data: {}
    };

    if (
      !this.state.cms_pages ||
      !this.state.cms_pages.submission ||
      _.isEmpty(this.state.cms_pages.submission)
    ) {
      return retVal;
    }

    let formData, existingData;

    formData = this.state.cms_pages.submission;

    if (!formData || _.isEmpty(formData)) return retVal;

    existingData = formData.find(
      element => element.language === this.state.language
    );

    if (!existingData || _.isEmpty(existingData)) return retVal;

    let languageDataClone = _.cloneDeep(existingData.cms_data);

    if (languageDataClone[key] && languageDataClone[key].data) {
      retVal.data = languageDataClone[key].data;
    }

    return retVal;
  };

  getFormsTab = () => {
    this.updateFormContent();
    const { classes } = this.props;
    let langCode = this.state.language;
    let submission = this.state.language_submission;

    return (
      <Card style={{ minHeight: 250 }}>
        {this.state.currentStep == 2 && (
          <LoadingOverlay>
            <GridContainer style={{ margin: "0 0", width: "100%" }}>
              <GridItem xs={12} sm={12} md={12}>
                <br />
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  {" "}
                  Data Form
                </p>

                <FormControl className="add-new-btn">
                  Choose Language
                  <Select
                    variant="outlined"
                    value={this.state.language}
                    onChange={this.handleLanguageChange()}
                    classes={{
                      root: "select-control-root"
                    }}
                    style={{
                      minWidth: "150px"
                    }}
                    // label=''
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Language
                    </MenuItem>
                    {_.map(
                      this.state.languageList,
                      ({ id, name, code }, index) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={code}
                            key={index + "_" + id}
                          >
                            {name}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>

                <br />
                <br />
                {_.map(
                  this.state.sections,
                  ({ id, sort_order, component }, index) => {
                    let form = { display: "form", components: component.form };
                    let data_key = component.key + "_";
                    if (sort_order !== "") {
                      data_key += sort_order;
                    }

                    console.log("submission", submission);
                    console.log("data_key", data_key);
                    return (
                      <ExpansionPanel defaultExpanded={index == 0}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ borderBottom: "1px solid rgba(0,0,0,0.10)" }}
                        >
                          <Typography className={classes.heading}>
                            {component.name}
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ width: "100%" }}>
                          <div
                            className="bootstrap-wrapper"
                            style={{ width: "100%" }}
                          >
                            {component && component.key && (
                              <Form
                                src={form}
                                onChange={data =>
                                  this.onChangeFormValue(
                                    data,
                                    component.key,
                                    data_key,
                                    sort_order,
                                    langCode
                                  )
                                }
                                submission={
                                  this.getSubmissionData(data_key)
                                  // submission
                                  //     && submission[data_key]
                                  //     && submission[data_key].data
                                  //     ? { data: submission[data_key].data } : { data: {} }
                                }
                                onCustomEvent={customEvent => {
                                  this.onCustomEvent(customEvent);
                                }}
                              />
                            )}
                          </div>

                          {/* </Paper> */}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    );
                  }
                )}
              </GridItem>
            </GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <br />
              <Button color="info" onClick={this.saveForms.bind(this)}>
                Save
              </Button>
              <Button color="info" onClick={this.saveFormsAndClose.bind(this)}>
                Save and Close
              </Button>
              <Button onClick={this.CancelAddEdit}>Cancel</Button>
              <br />

              {this.props.loadingerror && (
                <FormLabel className={classes.labelLeftHorizontal}>
                  <code>{this.props.loadingerror}</code>
                </FormLabel>
              )}

              {this.props.basicError && (
                <FormLabel className={classes.labelLeftHorizontal}>
                  <code>{this.props.basicError}</code>
                </FormLabel>
              )}

              {(this.props.basicSaving || this.props.loading) && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-12px"
                  }}
                />
              )}
            </GridItem>

            <Loader loading={this.props.basicSaving} text="Saving.." />
            <Loader loading={this.props.loading} text="Loading.." />
          </LoadingOverlay>
        )}
      </Card>
    );
  };

  getPrimaryTab = () => {
    const { classes } = this.props;
    return (
      <Card style={{ minHeight: 250 }}>
        <LoadingOverlay>
          <GridContainer style={{ margin: "0 0", width: "100%" }}>
            <GridItem xs={12} sm={12} md={6}>
              <Card style={{ paddingLeft: 5, paddingRight: 5 }}>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  {" "}
                  Create Sections
                </p>
                <br />
                <ComponentSelector
                  componentList={this.props.componentList}
                  rows={this.state.cms_pages.sections}
                  handlePrimaryTabRowChange={this.handlePrimaryTabRowChange}
                  handlePrimaryTabRowMappingFieldChange={
                    this.handlePrimaryTabRowMappingFieldChange
                  }
                  handleSortOrderChange={
                    this.handlePrimaryTabRowSortOrderChange
                  }
                  handleAddPrimaryTabRow={this.handleAddPrimaryTabRow}
                  handlePrimaryTabRowRemove={this.handlePrimaryTabRowRemove}
                />
              </Card>
              <br />
              <Button color="info" onClick={this.saveComponents.bind(this)}>
                Save and Next
              </Button>
              <Button onClick={this.CancelAddEdit}>Cancel</Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card style={{ paddingLeft: 5, paddingRight: 5 }}>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  {" "}
                  Preview
                </p>
                <br />
                <div>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={headerSample}
                  />
                  {(!this.state.sections ||
                    this.state.sections.length == 0) && (
                    <div style={{ minHeight: 250 }} />
                  )}

                  {_.map(
                    this.state.sections,
                    ({ id, sort_order, component }, index) => {
                      let currComponent = this.props.componentList.find(
                        x => x.id === component.id
                      );
                      return currComponent &&
                        currComponent.preview_image &&
                        currComponent.preview_image.url ? (
                        <img
                          style={{ width: "100%", height: "auto" }}
                          src={currComponent.preview_image.url}
                        />
                      ) : (
                        <div style={{ minHeight: 50 }} />
                      );
                    }
                  )}
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={footerSample}
                  />
                </div>
              </Card>
            </GridItem>
          </GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            {this.props.loadingerror && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code className="loading-error">{this.props.loadingerror}</code>
              </FormLabel>
            )}

            {this.props.basicError && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code className="basic-error">{this.props.basicError}</code>
              </FormLabel>
            )}

            {(this.props.basicSaving || this.props.loading) && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
          </GridItem>

          <Loader loading={this.props.basicSaving} text="Saving.." />
          <Loader loading={this.props.loading} text="Loading.." />
        </LoadingOverlay>
      </Card>
    );
  };

  getSelectedImage(data) {
    let { value } = this.state.add_CMS_data;
    let asset_object = {};
    asset_object.storage = "asset";
    asset_object.type = "image/jpg";
    asset_object.size = data.file_size;
    asset_object.name = data.name;
    asset_object.url = data.url;
    asset_object.asset_id = data.id;
    asset_object.originalName = data.original_file_name;
    value["upload_image"] = asset_object;
    let asset_image_data = {};
    // asset_image_data.assets = true
    asset_image_data.name = data.name;
    asset_image_data.originalName = data.original_file_name;
    asset_image_data.size = data.file_size;
    asset_image_data.url = data.url;
    asset_image_data.asset_id = data.id;
    asset_image_data.storage = "asset";
    asset_image_data.type = "image/jpg";
    // value.cards.push(asset_object)
    this.setState(
      {
        ...this.state,
        add_CMS_data: {
          ...this.state.add_CMS_data,
          value: value
        },
        assetImageUpload: true,
        asset_image_data: asset_image_data
      },
      () => {
        this.setFormValue(this.state.add_CMS_data);
        this.closeModal();
      }
    );
  }

  onCustomEvent = e => {
    this.setState({
      openAssetModal: true
    });
  };

  closeModal() {
    this.setState({
      openAssetModal: false
    });
  }

  renderForm = () => {
    const { classes } = this.props;
    const {
      name,
      description,
      is_active,
      id,
      industry,
      form,
      slug,
      is_landing_page,
      is_system_page
    } = this.state.cms_pages;
    const { required } = this.state;
    let formBuilder = { display: "form", components: form };
    return (
      <GridContainer style={{ width: "100%" }}>
        {this.state.openAssetModal && (
          <AssetManagementComponent
            open={this.state.openAssetModal}
            close={this.closeModal}
            onSelection={this.getSelectedImage}
          />
        )}

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange(
                        "name",
                        validationUtils.validationTypes.name
                      )
                    }}
                    id={"name_"}
                  />
                  <CustomInput
                    success={required.slug === "success"}
                    error={required.slug === "error"}
                    labelText="Slug *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: slug,
                      onChange: this.handleTextChange(
                        "slug",
                        validationUtils.validationTypes.name
                      )
                    }}
                    id={"slug_"}
                  />
                  <CustomInput
                    success={required.description === "success"}
                    error={required.description === "error"}
                    labelText="Description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: this.handleTextChange("description", "")
                    }}
                  />
                  {this.props.user && this.props.user.role === "AD" && (
                    <FormControl
                      fullWidth
                      style={customInputStyle.formControl}
                      name="industry"
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.labelRoot + " "}
                        labelText="Industry"
                      >
                        Industry
                      </InputLabel>
                      <Select
                        // success={required.industry}
                        // error={required.industry === false}
                        style={{ marginTop: "0px" }}
                        value={industry ? industry : []}
                        multiple
                        onChange={this.handleSelectChange("industry")}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Choose Industry
                        </MenuItem>
                        {_.map(
                          this.props.industriesList,
                          ({ id, name }, index) => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={id}
                                key={index + "_" + id}
                              >
                                {name}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus("is_active")}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_landing_page}
                        onClick={this.handleToggleStatus("is_landing_page")}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Landing Page"
                  />
                  {this.props.user && this.props.user.role === "AD" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checked={is_system_page}
                          onClick={this.handleToggleStatus("is_system_page")}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="System Page"
                    />
                  )}
                  <br />
                  <Button
                    color="info"
                    onClick={this.saveBasicDetails.bind(this)}
                  >
                    {this.state.cms_pages.is_system_page
                      ? "Save"
                      : "Save and Next"}
                  </Button>
                  <Button onClick={this.CancelAddEdit}>Cancel</Button>
                  <br />
                  {this.props.loadingerror && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.props.loadingerror}</code>
                    </FormLabel>
                  )}
                  {this.props.basicError && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.props.basicError}</code>
                    </FormLabel>
                  )}
                  {(this.props.basicSaving || this.props.loading) && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.basicSaving} text="Saving.." />
                  <Loader loading={this.props.loading} text="Loading.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  updateFormContent() {
    const { sections } = this.state.cms_pages;
    if (sections && sections.length) {
      for (var n = 0; n < sections.length; n++) {
        if (sections[n].component && sections[n].component.form) {
          for (var m = 0; m < sections[n].component.form.length; m++) {
            if (sections[n].component.form[m].components) {
              for (var cmp in sections[n].component.form[m].components) {
                if (sections[n].component.form[m].components[cmp].data) {
                  sections[n].component.form[m].components[cmp].data.url =
                    config.env.API_URL +
                    "api/retailer/menu/list/?retailer=" +
                    this.props.user.partner_id +
                    "&type=CMS";
                } else {
                  for (var cmp2 in sections[n].component.form[m].components[cmp]
                    .components) {
                    if (
                      sections[n].component.form[m].components[cmp].components[
                        cmp2
                      ].data
                    ) {
                      sections[n].component.form[m].components[cmp].components[
                        cmp2
                      ].data.url =
                        config.env.API_URL +
                        "api/retailer/menu/list/?retailer=" +
                        this.props.user.partner_id +
                        "&type=CMS";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // this.setState({
    //     ...this.state,
    //     cms_pages: {
    //         ...this.state.cms_pages,
    //         sections: sections
    //     }
    // }, ()=>{
    //     this.showForms()
    // })
    // }else{
    //     this.showForms()
    // }
    return sections;
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  menuList: state.partnerMenuReducer.get_partner_menu_list,
  menuData: state.CMSContentReducer.get_CMS_Content.list,
  basicSaving: state.cmsReducer.create_CMS_Pages.saving,
  basicError: state.cmsReducer.create_CMS_Pages.error,
  basicData: state.cmsReducer.create_CMS_Pages.page,

  loading: state.cmsReducer.page_detail.loading,
  loadingerror: state.cmsReducer.page_detail.error,
  pagedetail: state.cmsReducer.page_detail.page,

  industriesList: state.industriesReducer.list,
  componentList: state.cmsReducer.get_CMS_Components.list,
  Userid: state.authState.user.pk,
  user: state.authState.user,
  UserId: state.authState.user.user
});

export default compose(
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CMSPageView);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
