import React from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

// core components

import CircularProgress from "@material-ui/core/CircularProgress";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { LoadingOverlay, Loader } from "react-overlay-loader";

class UniversalAttributesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          sortable: true,
          filter: true,
          hide: true
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          filter: true,
          checkboxSelection: true
        },
        {
          headerName: "Description",
          field: "description",
          sortable: true,
          filter: true
        },
        {
          headerName: "Active",
          field: "is_active",
          sortable: true,
          filter: true
        }
      ],
      rowData: [],
      rows: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.name && nextProps.name.length) {
        const classData = nextProps.name;
        this.setState(() => ({
          rows: classData
        }));
      }

      if (nextProps.Error && nextProps.Error.trim() !== "") {
        this.setState(() => ({
          error: nextProps.Error,
          isAddNew: true
        }));
      }
      if (nextProps.isSaved) {
        this.setState(() => ({
          isAddNew: false,
          isEdit: true
        }));
      }

      if (nextProps.isMapSaved) {
        this.props.actions.afertMapClassUA();
        this.props.onClose();
      }
      if (
        nextProps.classValue &&
        nextProps.classValue.attribute_groups &&
        nextProps.classValue.attribute_groups.length > 0
      ) {
        let attribute_groups = nextProps.classValue.attribute_groups;
        let arrId = [];
        for (var i = 0; i < attribute_groups.length; i++) {
          for (var j = 0; j < attribute_groups[i].attributes.length; j++) {
            arrId.push(attribute_groups[i].attributes[j]);
          }
        }

        this.gridApi.forEachNode(function(node) {
          if (arrId.findIndex(p => p.id === node.data.id) > -1) {
            node.setSelected(true);
          } else {
            node.setSelected(false);
          }
        });
      } else {
        if (nextProps.classValue && this.gridApi) {
          this.gridApi.forEachNode(function(node) {
            node.setSelected(false);
          });
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.name && this.props.name.length) {
      this.setState({
        classData: this.props.name
      });
    }
    this.props.actions.getAttributeGroups();
  }

  saveMaping = () => {
    this.props.actions.mapClassUA(this.props.UserId, this.state.mapingData);
  };

  closeDialog = () => {
    this.props.onClose();
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    var length = this.props.selectedUAIndexes.length;
    var i = 0;
    const componentThis = this;
    for (i; i < length; i++) {
      componentThis.props.selectedUAIndexes.pop();
    }
    selectedRows.forEach(function(selectedRow, index) {
      componentThis.props.selectedUAIndexes.push(selectedRow.id);
    });
  }

  render() {
    return (
      <div>
        <LoadingOverlay>
          <div
            className="ag-theme-material"
            style={{
              height: "300px",
              width: "500px"
            }}
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowSelection="multiple"
              onGridReady={this.onGridReady}
              onSelectionChanged={this.onSelectionChanged.bind(this)}
              rowData={this.state.rows}
            />
          </div>
          {this.props.classLoading && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-25px",
                marginLeft: "-12px"
              }}
            />
          )}
          <Loader loading={this.props.classLoading} text="Loading.." />
        </LoadingOverlay>
      </div>
    );
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  List: state.brandState.universalAttributes.attributes,
  // Loading: state.brandState.universalAttributes.loading,
  ListError: state.brandState.error,
  // isSaved: state.brandState.universalAttributes.isSaved,
  UserId: state.authState.user.user,
  isMapSaved: state.classReducer.isMapSaved,
  classLoading: state.classReducer.loading,
  name: state.brandState.attributeGroups.attributeGroups,
  isSaved: state.brandState.attributeGroups.isSaved,
  Loading: state.brandState.attributeGroups.loading
});

UniversalAttributesComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  classValue: PropTypes.object,
  selectedUAIndexes: PropTypes.array.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UniversalAttributesComponent);

// export default classComponent;
