import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../redux/actions";
import { compose } from "recompose";
import _ from "lodash";
import cx from "classnames";
import * as routesNames from "../../constants/routes";

import { LoadingOverlay, Loader } from "react-overlay-loader";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import FormLabel from "@material-ui/core/FormLabel";

import FormControl from "@material-ui/core/FormControl";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
//import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import withAuthorization from "../Session/withAuthorization";
import { rolesNames } from "../../constants";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Check from "@material-ui/icons/Check";
import { validationUtils } from "../../utils";

// @material-ui/core components/Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import ReactTooltip from "react-tooltip";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

import extendedFormStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import "../../assets/scss/wrapper-bootstrap.min.scss";
import "../../assets/scss/wrapper-component-settings.scss";
import "../../assets/scss/formio.full.min.css";
import "../../assets/scss/formio-material-support.css";
import AgGrid from "../../components/AgGrid/AgGrid.jsx";
import AgGridActionButton from "../../components/AgGrid/AgGridActionButton.jsx";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { FormBuilder } from "react-formio";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class MenuMaster extends React.Component {
  constructor(props) {
    super(props);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.state = {
      isAddNew: false,
      addMenuMasterData: {
        name: "",
        menutype: "Page",
        type: "User",
        form: [],
        description: "",
        is_active: true,
        route: "",
        businessTypeId: null,
        url: ""
      },
      required: {
        name: "",
        url: "",
        form: [],
        businessTypeId: ""
      },
      MenuList: [],
      error: null,
      openDeleteDialog: false,
      // BusinessTypeId: null,
      entityData: null,
      isIndustriesComponentOpen: false,
      BusinessTypeValue: null,
      selectedITIndexes: [],
      tagTypeList: [],
      businessTypeList: [],
      businessTypeId: null,
      openCopyDilog: false,
      isBackBtn: false,
      columnDefs: [
        {
          headerName: "ECONOMIC SECTOR",
          field: "businessType",
          width: 250,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          cellRenderer: function(params) {
            return (
              "<span>" + params &&
              params.value.length > 0 &&
              params.value[0].businessType.length > 0 &&
              params.value[0].businessType[0].name + "</span>"
            );
          }
        },
        {
          headerName: "Name",
          field: "name",
          width: 270,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        // {
        //     headerName: "Menu Type",
        //     field: "menutype",
        //     width: 180,
        //     resizable: true,
        //     sortable: true,
        //     filter: "agTextColumnFilter"
        // },
        {
          headerName: "ENVIRONMENT TYPE",
          field: "type",
          width: 250,
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter"
        },

        {
          headerName: "Actions",
          field: "value",
          cellRenderer: "AgGridActionButton",
          colId: "params",
          width: 200,
          filter: false
        }
      ],
      copy_menu: {
        businessTypeId: null,
        menu_name: "",
        menu_id: null
      },
      required_copy_menu: {
        businessTypeId: "",
        menu_name: ""
      },
      isSave: false
    };
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handelIsAddNew = this.handelIsAddNew.bind(this);
    this.onHanndelClose = this.onHanndelClose.bind(this);
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let businesstypeid = params.get("businesstypeid");
    if (businesstypeid) {
      this.setState(
        {
          businessTypeId: businesstypeid,
          isBackBtn: true
        },
        () => {
          this.props.actions.getMenuMasterList(this.state.businessTypeId);
        }
      );
    } else {
      this.props.actions.getMenuMasterList();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.List && nextProps.List.length) {
        let MenuList = nextProps.List;
        this.setState(() => ({
          MenuList: MenuList
        }));
      }
      if (nextProps.copy_Saving) {
        this.onHanndelClose();
        if (this.state.businessTypeId) {
          this.props.actions.getMenuMasterList(this.state.businessTypeId);
        } else {
          this.props.actions.getMenuMasterList();
        }
      }
      if (nextProps.ListError && nextProps.ListError.trim() !== "") {
        this.setState(() => ({
          error: nextProps.ListError,
          isAddNew: true
        }));
      }

      if (nextProps.isSaved && nextProps.isSaved != this.props.isSaved) {
        this.setState(() => ({
          isAddNew: false,
          isEdit: true
        }));
      }
      if (nextProps.isDeleted) {
        this.setState(() => ({
          openDeleteDialog: false
        }));
      }
      if (nextProps.isMapSaved) {
        this.closeIndustriesComponentDialog();
        this.props.actions.afertMapBusinessIndustries();
      }
      if (nextProps.TagTypeList) {
        this.setState({
          tagTypeList: nextProps.TagTypeList
        });
      }
      if (nextProps.businessTypeList) {
        this.setState({
          businessTypeList: nextProps.businessTypeList
        });
      }
    }
  }

  handelIsAddNew = () => {
    this.setState(
      {
        isAddNew: true,
        addMenuMasterData: {
          name: "",
          menutype: "Page",
          type: "User",
          form: [],
          description: "",
          is_active: true,
          route: "",
          businessTypeId: this.state.isBackBtn ? this.state.businessTypeId : ""
        }
      },
      () => {
        this.props.actions.getBusinessType(this.props.UserId);
      }
    );
  };

  handleChangeEnabled(event) {
    this.setState({
      addMenuMasterData: {
        ...this.state.addMenuMasterData,
        menutype: event.target.value
      }
    });
  }

  handleFormChange(form) {
    this.setState({
      addMenuMasterData: {
        ...this.state.addMenuMasterData,
        form: form.components
      }
    });
  }

  handleTypeChange(event) {
    this.setState({
      addMenuMasterData: {
        ...this.state.addMenuMasterData,
        type: event.target.value
      }
    });
  }
  onSave_Copymenu = () => {
    const savingData = this.state.copy_menu;

    this.props.actions.copyMenu(this.props.UserId, savingData);
  };

  handleTextChange = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required;
    reqData[key] = validData;
    this.setState({ required: reqData });

    this.setState({
      addMenuMasterData: {
        ...this.state.addMenuMasterData,
        [key]: event.target.value
      }
    });
  };

  handleTextChange_copy = (key, type) => event => {
    var validData = "success";
    if (!validationUtils.validateData(type, event.target.value)) {
      validData = "error";
    }
    var reqData = this.state.required_copy_menu;
    reqData[key] = validData;
    this.setState({ required_copy_menu: reqData });

    this.setState({
      copy_menu: {
        ...this.state.copy_menu,
        [key]: event.target.value
      }
    });
  };

  handleToggleStatus = curObj => event => {
    this.setState({
      addMenuMasterData: {
        ...this.state.addMenuMasterData,
        is_active: event.target.checked
      }
    });
  };

  saveMenuMaster = () => {
    if (this.validateIsRequired()) {
      const savingData = this.state.addMenuMasterData;
      this.props.actions.createMenuMaster(this.props.UserId, savingData);
    }
  };

  handelEdit = id => {
    const { isEdit, MenuList } = this.state;

    const data = MenuList;
    let index = MenuList.findIndex(x => x.id == id);
    let businessId = "";
    if (data[index] && data[index].businessType) {
      for (var i = 0; i < data[index].businessType.length; i++) {
        for (
          var j = 0;
          j < data[index].businessType[i].businessType.length;
          j++
        ) {
          businessId = data[index].businessType[i].businessType[j].id;
        }
      }
    }
    if (data[index]) {
      this.setState(
        {
          addMenuMasterData: {
            name: data[index].name,
            description: data[index].description,
            index: index,
            id: data[index].id,
            menutype: data[index].menutype,
            type: data[index].type,
            form: data[index].form,
            is_active: data[index].is_active,
            route: data[index].route,
            businessTypeId: businessId,
            url: data[index].url
          },
          isAddNew: true,
          isEdit: true
        },
        () => {
          this.props.actions.getBusinessType(this.props.UserId);
        }
      );
    }
  };

  handleCopy = id => {
    const { MenuList } = this.state;
    let index = MenuList.findIndex(x => x.id == id);

    this.setState(
      {
        openCopyDilog: true,
        copy_menu: {
          ...this.state.copy_menu,
          name: MenuList[index].name,
          menu_id: MenuList[index].id,
          businessTypeId: MenuList[index].businessTypeId
        }
      },
      () => {
        this.props.actions.getBusinessType(this.props.UserId);
      }
    );
  };

  onHanndelClose() {
    this.props.actions.copyStateupdate();
    this.setState({
      openCopyDilog: false
    });
  }
  copyMenuDilog() {
    const { classes } = this.props;
    const { required_copy_menu } = this.state;
    let { name } = this.state.copy_menu;

    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.openCopyDilog}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.onHanndelClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle} style={{ width: "500px" }}>
            Copy Menu{" "}
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <LoadingOverlay>
            <DialogContentText>
              <FormControl fullWidth style={customInputStyle.formControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.labelRoot + " "}
                  labelText="Economic Sector*"
                >
                  Economic Sector
                </InputLabel>
                <Select
                  success={required_copy_menu.businessTypeId === "success"}
                  error={required_copy_menu.businessTypeId === "error"}
                  style={{ marginTop: "0px" }}
                  value={this.state.copy_menu.businessTypeId}
                  onChange={this.handleTextChange_copy("businessTypeId", "")}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Choose Economic Sector
                  </MenuItem>
                  {_.map(this.state.businessTypeList, ({ id, name }, index) => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={id}
                        key={index + "_" + id}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <CustomInput
                labelText=""
                success={required_copy_menu.name === "success"}
                error={required_copy_menu.name === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  value: name,
                  onChange: this.handleTextChange_copy("name", "")
                }}
              />
            </DialogContentText>

            {this.props.copy_isloading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-25px",
                  marginLeft: "-12px"
                }}
              />
            )}
            <Loader loading={this.props.copy_isloading} text="saving.." />
            <br />
            {this.props.copy_ListError &&
              this.props.copy_ListError.trim() !== "" && (
                <FormLabel className={classes.labelLeftHorizontal}>
                  <code>{this.props.copy_ListError}</code>
                </FormLabel>
              )}
          </LoadingOverlay>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.onSave_Copymenu()} color="danger" simple>
            Save
          </Button>
          <Button onClick={() => this.onHanndelClose()} color="danger" simple>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  render() {
    return this.state.isAddNew
      ? this.renderTagsType()
      : this.renderTagsTypeTable();
  }

  validateIsRequired() {
    var reqData = this.state["required"];
    var isValid = true;
    if (
      !this.state.addMenuMasterData.hasOwnProperty("name") ||
      (this.state.addMenuMasterData.name.trim() === "" ||
        reqData["name"] === "error")
    ) {
      reqData["name"] = "error";
      isValid = false;
    }

    // if (!this.state.addMenuMasterData.hasOwnProperty("description") || (this.state.addMenuMasterData.description.trim() === "" || reqData["description"] === "error")) {
    //     reqData["description"] = "error";
    //     isValid = false;
    // }
    if (
      !this.state.addMenuMasterData.hasOwnProperty("businessTypeId") ||
      (this.state.addMenuMasterData.businessTypeId === "" ||
        reqData["businessTypeId"] === "error")
    ) {
      reqData["businessTypeId"] = "error";
      isValid = false;
    }

    // if (!this.state.addMenuMasterData.hasOwnProperty("url") || (this.state.addMenuMasterData.url === "" || reqData["url"] === "error")) {
    //     reqData["url"] = "error";
    //     isValid = false;
    // }

    this.setState({ required: reqData });
    return isValid;
  }

  renderTagsType = () => {
    const { classes } = this.props;
    let {
      name,
      description,
      menutype,
      type,
      form,
      is_active,
      route,
      url
    } = this.state.addMenuMasterData;
    if (
      form &&
      form.length == 1 &&
      form[0].type === "button" &&
      form[0].action === "submit"
    ) {
      form = [
        {
          type: "textfield",
          input: true,
          inputType: "text",
          label: "Title",
          key: "title",
          placeholder: "Enter title"
        }
      ];
    }
    let formBuilder = { display: "form", components: form };
    const { required } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <br />
              <p className={classes.cardIconTitle + " grid-header-title"}>
                Make edits to the fields below, then save changes.
              </p>
            </CardHeader>
            <CardBody>
              <form>
                <LoadingOverlay>
                  <CustomInput
                    success={required.name === "success"}
                    error={required.name === "error"}
                    labelText="Name *"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: this.handleTextChange("name", "")
                    }}
                  />

                  {/* <CustomInput
                                        labelText="Description *"
                                        success={required.description === "success"}
                                        error={required.description === "error"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            value: description,
                                            onChange: this.handleTextChange('description', "")
                                        }}
                                    /> */}

                  <CustomInput
                    labelText="URL *"
                    success={required.url === "success"}
                    error={required.url === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: url,
                      onChange: this.handleTextChange("url", "")
                    }}
                  />

                  <FormControl fullWidth style={customInputStyle.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " "}
                      labelText="Economic Sector*"
                    >
                      Economic Sector
                    </InputLabel>
                    <Select
                      success={required.businessTypeId === "success"}
                      error={required.businessTypeId === "error"}
                      style={{ marginTop: "0px" }}
                      value={this.state.addMenuMasterData.businessTypeId}
                      onChange={this.handleTextChange("businessTypeId", "")}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose Economic Sector
                      </MenuItem>
                      {_.map(
                        this.state.businessTypeList,
                        ({ id, name }, index) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={id}
                              key={index + "_" + id}
                            >
                              {name}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>

                  <div style={{ display: "none" }}>
                    <h6>Menu Content Type</h6>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={menutype === "Page"}
                          onChange={this.handleChangeEnabled}
                          value="Page"
                          name="radio button enabled"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Page"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={menutype === "Items"}
                          onChange={this.handleChangeEnabled}
                          value="Items"
                          name="radio button enabled"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Items"
                    />
                  </div>

                  <h6>Menu Type</h6>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={type === "User"}
                        onChange={this.handleTypeChange}
                        value="User"
                        name="radio button enabled"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label="User"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={type === "System"}
                        onChange={this.handleTypeChange}
                        value="System"
                        name="radio button enabled"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label="System"
                  />

                  {type === "System" && (
                    <div>
                      {" "}
                      <h6>Menu Route</h6>
                      <CustomInput
                        labelText="Menu Route *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: route,
                          onChange: this.handleTextChange("route", "")
                        }}
                      />
                      <br />{" "}
                    </div>
                  )}

                  {type === "User" && (
                    <div>
                      {" "}
                      <h6>Form</h6>
                      <div className="bootstrap-wrapper">
                        <FormBuilder
                          form={formBuilder}
                          onChange={schema => this.handleFormChange(schema)}
                        />
                      </div>
                      <br />{" "}
                    </div>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={is_active}
                        onClick={this.handleToggleStatus(is_active)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Active"
                  />
                  <br />
                  {this.props.ListError && this.props.ListError.trim() !== "" && (
                    <FormLabel className={classes.labelLeftHorizontal}>
                      <code>{this.state.error}</code>
                    </FormLabel>
                  )}
                  <br />
                  <Button color="info" onClick={this.saveMenuMaster}>
                    {this.state.isAddNew && this.state.isEdit
                      ? "Update Changes"
                      : "Save Changes"}
                  </Button>
                  <Button onClick={this.handleCloseAddNew}>Cancel</Button>
                  {this.props.copy_Saving && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-25px",
                        marginLeft: "-12px"
                      }}
                    />
                  )}
                  <Loader loading={this.props.copy_Saving} text="Saving.." />
                </LoadingOverlay>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  handleCloseAddNew = () => {
    this.setState({
      isAddNew: false,
      isEdit: false
    });
  };

  renderTagsTypeTable = () => {
    const { classes } = this.props;
    const { MenuList, isBackBtn } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <div>
                <p className={classes.cardIconTitle + " grid-header-title"}>
                  Menu Master
                </p>

                <p
                  style={{
                    float: "right"
                  }}
                >
                  {isBackBtn ? (
                    <div
                      className={cx("product-page-back-btn", "pagination")}
                      onClick={() => this.backToLastPage()}
                      style={{
                        float: "left",
                        marginRight: "10px",
                        marginTop: "10px"
                      }}
                    >
                      &lt;&lt; Back
                    </div>
                  ) : (
                    ""
                  )}
                  <Button
                    disabled={this.props.Loading}
                    color="success"
                    onClick={this.handelIsAddNew}
                    className={classes.marginRight + " grid-header-button"}
                  >
                    <AddIcon className="grid-header-button-icon" /> New
                  </Button>
                </p>
              </div>
              {this.copyMenuDilog()}
            </CardHeader>
            <CardBody>
              <LoadingOverlay>
                <AgGrid
                  rowData={MenuList}
                  columnDefs={this.state.columnDefs}
                  handelEdit={this.handelEdit}
                  handleCopy={this.handleCopy}
                  AgGridActionButton={AgGridActionButton}
                />
                {this.props.Loading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-25px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
                <Loader loading={this.props.Loading} text="Loading.." />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  backToLastPage() {
    this.props.actions.onNavigateTo(routesNames.BUSINESSTYPE);
  }

  getTableData = () => {
    const { attributeGroups } = [];
    const { MenuList } = this.state;
    const { classes } = this.props;
    var returnData = [];
    _.map(
      MenuList,
      (
        {
          id,
          name,
          description,
          is_active,
          menutype,
          type,
          form,
          businessType
        },
        index
      ) => {
        returnData.push([
          <span>
            {businessType &&
              businessType.length > 0 &&
              businessType[0].businessType &&
              businessType[0].businessType[0].name}
          </span>,

          <span>{name}</span>,
          // <span>{menutype}</span>,
          <span>{type}</span>,
          // <span>{businessType && businessType.length > 0 && businessType[0].businessType && businessType[0].businessType[0].name}</span>,
          // <span>{is_active ? "Active" : "Inactive"}</span>,
          <span>
            <ReactTooltip id="edit">
              <span>Edit Menu</span>
            </ReactTooltip>
            <Button
              color="success"
              data-tip
              data-for="edit"
              className={classes.actionButton}
              onClick={() => this.handelEdit(index)}
            >
              <CreateIcon className={classes.icon} />
            </Button>

            <ReactTooltip id="delete">
              <span>Copy Menu </span>
            </ReactTooltip>
            <Button
              color="primary"
              data-tip
              data-for="copy"
              className={classes.marginRight}
              style={{ padding: "5px" }}
              onClick={() => this.handleCopy(id)}
            >
              <FileCopyIcon
                className={classes.icons}
                style={{ marginRight: "0px" }}
              />
            </Button>
          </span>
        ]);
      }
    );
    return returnData;
  };
}
const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  Saving: state.menuMasterReducer.create_menu_master.saving,
  ListError: state.menuMasterReducer.create_menu_master.error,
  isSaved: state.menuMasterReducer.create_menu_master.isSaved,

  List: state.menuMasterReducer.get_menu_master.list,
  Loading: state.menuMasterReducer.get_menu_master.loading,
  LoadingError: state.menuMasterReducer.get_menu_master.error,

  copy_Saving: state.menuMasterReducer.copy_menu.isSaved,
  copy_ListError: state.menuMasterReducer.copy_menu.error,
  copy_isloading: state.menuMasterReducer.copy_menu.loading,

  UserId: state.authState.user.pk,
  User: state.authState.user,
  businessTypeList: state.businessTypeReducer.list
});

const condition = authUser =>
  authUser.role === rolesNames.RoleMaster.ADMIN ||
  rolesNames.RoleMaster.WHOLESALER;

export default compose(
  withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle,
    ...extendedFormStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MenuMaster);
//export default withStyles({...regularFormsStyle,...extendedTablesStyle})(Staff);
