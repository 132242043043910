import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  list: null,
  loading: false,
  loadingData: false,
  error: null,
  isSaved: false,
  isSaving: false,
  isDeleted: false,
  isMapSaved: false,
  industry_meta_data: {
    data: null,
    loading: false,
    error: null
  },
  business_type_by_id: {
    data: null,
    loading: false,
    error: null
  },
  pagination: {
    page: "1",
    next: null,
    prev: null,
    totalpages: 1,
    trecords: 0,
    pagesize: 10
  },
  economic_sector_industries: {
    data: null,
    loading: true,
    error: null
  }
};

const businessTypeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_BUSINESS_TYPE_REQUEST:
      return {
        ...state,
        list: action.payload,
        loadingData: true,
        error: null,
        isSaved: false,
        isDeleted: false,
        pagination: {
          page: "1",
          next: null,
          prev: null,
          totalpages: 1,
          trecords: 0,
          pagesize: 10
        }
      };
    case actionTypes.GET_BUSINESS_TYPE_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false
      };
    case actionTypes.GET_BUSINESS_TYPE_SUCCESS:
      let pagination = _.clone(state.pagination);
      let sdata = action.payload;
      if (sdata && sdata.results) {
        pagination.page = sdata.current;
        pagination.next = sdata.next;
        pagination.prev = sdata.prev;
        pagination.totalpage = sdata.total_pages;
        pagination.totalrecords = sdata.total_records;
        pagination.pagesize = sdata.page_size;
        sdata = sdata.results;
      }
      return {
        ...state,
        loadingData: false,
        error: null,
        list: sdata,
        isSaved: false,
        pagination: {
          ...pagination
        }
      };
    case actionTypes.SAVE_BUSINESS_TYPE_REQUEST:
      return {
        ...state,
        loadingData: true,
        error: null,
        isSaved: false
      };
    case actionTypes.SAVE_BUSINESS_TYPE_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
        isSaved: false
      };
    case actionTypes.SAVE_BUSINESS_TYPE_SUCCESS:
      // var data = state.list;
      // var index = data.findIndex(p => p.id === action.payload.id);
      // if (index > -1) {
      // 	data[index] = action.payload;
      // } else {

      // 	data.push(action.payload);
      // }
      return {
        ...state,
        loadingData: false,
        error: null,
        // list: data,
        isSaved: true,
        isMapSaved: true
      };
    case actionTypes.DELETE_BUSINESS_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isSaved: false,
        isDeleted: false
      };
    case actionTypes.DELETE_BUSINESS_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSaved: false,
        isDeleted: false
      };
    case actionTypes.DELETE_BUSINESS_TYPE_SUCCESS:
      var data = _.clone(state.list);
      let segmentIndex = data.findIndex(o => o.id === action.class_id);
      data.splice(segmentIndex, 1);
      return {
        ...state,
        loading: false,
        error: null,
        list: data,
        isSaved: false,
        isDeleted: true
      };
    case actionTypes.AFTER_MAP_BUSINESS_INDUSTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,

        isSaved: false,
        isDeleted: true,
        isMapSaved: false
      };

    case actionTypes.GET_BUSINESS_TYPE_BYID_REQUEST:
      return {
        ...state,
        business_type_by_id: {
          data: null,
          loading: true,
          error: null
        },
        isSaved: false
      };
    case actionTypes.GET_BUSINESS_TYPE_BYID_FAILURE:
      return {
        ...state,
        business_type_by_id: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_BUSINESS_TYPE_BYID_SUCCESS:
      return {
        ...state,
        industry_meta_data: {
          data: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.GET_INDUSTRIES_METADATA_REQUEST:
      return {
        ...state,
        industry_meta_data: {
          data: null,
          loading: true,
          error: null
        }
      };
    case actionTypes.GET_INDUSTRIES_METADATA_FAILURE:
      return {
        ...state,
        industry_meta_data: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_INDUSTRIES_METADATA_SUCCESS:
      return {
        ...state,
        industry_meta_data: {
          data: action.payload,
          loading: false,
          error: null
        }
      };

    case actionTypes.GET_ECONOMIC_SECTORS_INDUSTRIES_REQUEST:
      return {
        ...state,
        economic_sector_industries: {
          data: null,
          loading: true,
          error: null
        }
      };
    case actionTypes.GET_ECONOMIC_SECTORS_INDUSTRIES_FAILURE:
      return {
        ...state,
        economic_sector_industries: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.GET_ECONOMIC_SECTORS_INDUSTRIES_SUCCESS:
      return {
        ...state,
        economic_sector_industries: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    default:
      return state;
  }
};

export default businessTypeReducer;
