import React, { Component } from "react";
import {Edit, Link, Delete} from "@material-ui/icons";

class AddressActionButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  invokeParentMethod() {
    const { data } = this.props;
    return (
      <div
        style={{
          lineHeight: "60px"
        }}
      >
        {
          <span
            onClick={() => this.handleEdit(this.props.rowIndex, 1)}
            title="Edit"
          >
            <Edit className="grid_action_btn" />
          </span>
        }
        {
          <span
            onClick={() => this.handleDelete(this.props.rowIndex, 1)}
            title="Edit"
          >
            <Delete className="grid_action_btn" />
          </span>
        }
        { data.key.toLowerCase() == "products" &&
          <span
            onClick={() => this.handleHierarchy(this.props.rowIndex, 1)}
            title="Add Hierarchy"
          >
            <Link className="grid_action_btn" />
          </span>
        }
      </div>
    );
  }

  handleEdit(index) {
    this.props.context.componentParent.handelEdit(index);
  }

  handleDelete(index) {
    this.props.context.componentParent.handelDelete(index);
  }

  handleHierarchy(index) {
    this.props.context.componentParent.map(index);
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default AddressActionButton;
