import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  data: null,
  error: null,
  loading: false,
  isSaved: false,
  get_page_byid: {
    data: null,
    error: null,
    loading: false
  }
};

const customPageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PAGE_LIST_REQUEST:
      return {
        ...state,
        data: null,
        error: null,
        loading: true,
        isSaved: false
      };

    case actionTypes.GET_PAGE_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false
      };

    case actionTypes.GET_PAGE_LIST_FAILURE:
      return {
        ...state,
        data: null,
        error: null,
        loading: false
      };

    case actionTypes.CREATE_PAGE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true
      };

    case actionTypes.CREATE_PAGE_SUCCESS:
      let list = _.clone(state.data);
      let index = list.findIndex(x => x.id == action.payload.id);
      if (index > -1) {
        list.splice(index, 1);
      }
      list.unshift(action.payload);
      return {
        ...state,
        data: list,
        error: null,
        loading: false,
        isSaved: true
      };

    case actionTypes.CREATE_PAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case actionTypes.GET_PAGE_BYID_REQUEST:
      return {
        ...state,
        isSaved: false,
        get_page_byid: {
          data: null,
          error: null,
          loading: true
        }
      };

    case actionTypes.GET_PAGE_BYID_SUCCESS:
      return {
        ...state,
        get_page_byid: {
          data: action.payload,
          error: null,
          loading: false
        }
      };

    case actionTypes.GET_PAGE_BYID_FAILURE:
      return {
        ...state,
        get_page_byid: {
          data: null,
          error: action.payload,
          loading: false
        }
      };

    default:
      return state;
  }
};

export default customPageReducer;
