import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../../../redux/actions";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";

// core components
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Button from "components/CustomButtons/Button.jsx";

const Style = {
  questionHead: {
    "font-weight": "500"
  },
  pagination: {
    "font-weight": "normal",
    "font-size": "14px",
    "line-height": "20px",
    color: "#666666"
  }
};

const initialState = {
  total_page: 2,
  current_page: 1
};

class ChannelTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        ...initialState
      },
      campaignData: {},
      showChannels: {
        retailerChannel: "",
        teamlocalChannel: ""
      }
    };
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
    this.clickNextBtn = this.clickNextBtn.bind(this);
    this.clickPreviousBtn = this.clickPreviousBtn.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.saveCampign = this.saveCampign.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!_.isEqual(this.props, nextProps)) {
        if (!_.isEqual(this.props.data, nextProps.data)) {
          this.setState({
            campaignData: nextProps.data
          });
        }

        if (
          !_.isEqual(
            this.props.selectedChannelDist,
            nextProps.selectedChannelDist
          )
        ) {
          this.setState({
            showChannels: nextProps.selectedChannelDist
          });
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState(
        {
          ...this.state,
          campaignData: this.props.data
        },
        () => {
          const { channels } = this.state.campaignData;
          const index = channels.findIndex(
            x => x.page == this.state.pagination.current_page
          );
          if (index > -1) {
            this.props.actions.channelDistributionList(
              channels[index].channelId
            );
          }
        }
      );
    }
    if (this.props.selectedChannelDist) {
      this.setState({
        showChannels: this.props.selectedChannelDist
      });
    }
  }

  render() {
    const { current_page, total_page } = this.state.pagination;
    return (
      <Grid>
        <GridContainer>
          <GridItem lg={2} md={2} />
          <GridItem lg={8} md={8} sm={12} xs={12}>
            <GridContainer>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <div style={Style.pagination}>
                  Page: {current_page}/{total_page}
                </div>
                {this.showChannelDataBasedOnPage()}
              </GridItem>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <GridContainer>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button onClick={this.clickPreviousBtn}>Previous</Button>
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button onClick={this.clickNextBtn}>Next</Button>
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button
                      disabled={this.props.isSaving}
                      onClick={() => this.saveCampign(1)}
                      style={{
                        "margin-left": "10px"
                      }}
                    >
                      Save & Close
                    </Button>
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={3}>
                    <Button
                      onClick={this.closeWizard}
                      style={{
                        "margin-left": "10px"
                      }}
                    >
                      Close
                    </Button>
                  </GridItem>
                  {/* <GridItem lg={6} md={6} sm={6} xs={6}>
                                        <Button onClick={this.clickPreviousBtn}>
                                            Previous
                                        </Button>
                                        
                                    </GridItem>
                                    <GridItem lg={6} md={6} sm={6} xs={6}>
                                        <Button onClick={this.clickNextBtn}>
                                            Next
                                        </Button>
                                        <Button onClick={this.closeWizard} style={{
                                            'margin-left': '10px'
                                        }}>
                                            Close
                                        </Button>
                                    </GridItem> */}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem lg={2} md={2} />
        </GridContainer>
      </Grid>
    );
  }

  closeWizard() {
    this.setState(
      {
        showChannels: []
      },
      () => {
        this.props.closePromotion();
      }
    );
  }

  clickNextBtn() {
    let { current_page, total_page } = this.state.pagination;
    let new_page = current_page + 1;
    if (new_page <= total_page) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            current_page: new_page
          }
        },
        () => {
          const { channels } = this.state.campaignData;
          const { current_page } = this.state.pagination;
          const selectedChannelObjId = channels.findIndex(
            x => x.page == current_page
          );
          if (selectedChannelObjId !== -1) {
            this.props.actions.channelDistributionList(
              channels[selectedChannelObjId].channelId
            );
          }
        }
      );
    } else {
      if (this.props.hidePartner) {
        this.props.changeSelectedTab(2);
      } else {
        this.props.changeSelectedTab(1);
      }
    }
  }

  clickPreviousBtn() {
    let { current_page } = this.state.pagination;
    let new_page = current_page - 1;
    if (new_page >= 1) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            current_page: new_page
          }
        },
        () => {
          const { channels } = this.state.campaignData;
          const { current_page } = this.state.pagination;
          const selectedChannelObjId = channels.findIndex(
            x => x.page == current_page
          );
          if (selectedChannelObjId !== -1) {
            this.props.actions.channelDistributionList(
              channels[selectedChannelObjId].channelId
            );
          }
        }
      );
    } else {
      this.props.changeSelectedTab(-1);
    }
  }

  saveCampign = filter => {
    this.props.save(filter);
  };

  showChannelDataBasedOnPage() {
    const { classes, channelList } = this.props;
    const { current_page } = this.state.pagination;
    let selectedChannelIndex = -1;
    if (channelList !== null && channelList.length > 0) {
      switch (current_page) {
        case 1:
          selectedChannelIndex = channelList.findIndex(
            x => x.key.toLowerCase() == "partner-web-banners"
          );
          return (
            <GridContainer>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <div className="campaign-info-label" style={Style.questionHead}>
                  Would you like to place ads on{" "}
                  {channelList &&
                    channelList[selectedChannelIndex] &&
                    channelList[selectedChannelIndex].name}
                  ?
                </div>
                <FormControlLabel
                  control={
                    <Radio
                      checked={
                        this.state.showChannels.retailerChannel === "Yes"
                      }
                      onClick={event => {
                        this.handleChangeEnabled(
                          event,
                          current_page,
                          channelList[selectedChannelIndex].id
                        );
                      }}
                      value="Yes"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.showChannels.retailerChannel === "No"}
                      onClick={event => {
                        this.handleChangeEnabled(
                          event,
                          current_page,
                          channelList[selectedChannelIndex].id
                        );
                      }}
                      value="No"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label="No"
                />
              </GridItem>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                {this.state.showChannels.retailerChannel === "Yes"
                  ? this.showRetailerChannelDistribution()
                  : ""}
              </GridItem>
            </GridContainer>
          );
          break;
        case 2:
          selectedChannelIndex = channelList.findIndex(
            x => x.key.toLowerCase() == "teamlocal-web-banners"
          );
          return (
            <GridContainer>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <div className="campaign-info-label" style={Style.questionHead}>
                  Would you like to place ads on{" "}
                  {channelList &&
                    channelList[selectedChannelIndex] &&
                    channelList[selectedChannelIndex].name}
                  ?
                </div>
                <FormControlLabel
                  control={
                    <Radio
                      checked={
                        this.state.showChannels.teamlocalChannel === "Yes"
                      }
                      onClick={event => {
                        this.handleChangeEnabled(
                          event,
                          current_page,
                          channelList[selectedChannelIndex].id
                        );
                      }}
                      value="Yes"
                      aria-label="A"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={
                        this.state.showChannels.teamlocalChannel === "No"
                      }
                      onClick={event => {
                        this.handleChangeEnabled(
                          event,
                          current_page,
                          channelList[selectedChannelIndex].id
                        );
                      }}
                      value="No"
                      aria-label="A"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label="No"
                />
              </GridItem>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                {this.state.showChannels.teamlocalChannel === "Yes"
                  ? this.showRetailerChannelDistribution()
                  : ""}
              </GridItem>
            </GridContainer>
          );
          break;
      }
    }
  }

  showRetailerChannelDistribution() {
    const { classes, channelDistribution } = this.props;
    return (
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <div className="campaign-info-label" style={Style.questionHead}>
          Where would you like to place the ads?
        </div>
        <div>
          {_.map(channelDistribution, (model, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() => {
                    this.changeChannel(model.id);
                  }}
                  checked={this.handleChannelDistSelection(model.id)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{ label: classes.label }}
              label={model.name}
            />
          ))}
        </div>
      </GridItem>
    );
  }

  handleChangeEnabled = (event, page, channel_id) => {
    let { channels } = this.state.campaignData;
    if (event.target.value == "Yes") {
      this.props.actions.channelDistributionList(channel_id);
      let channel_obj = {};
      let { channelList } = this.props;
      channel_obj.page = page;
      channel_obj.channelId = channel_id;
      const channelObjIndex = channelList.findIndex(x => x.id === channel_id);
      channel_obj.channelName = channelList[channelObjIndex].name;
      const checkChannel = channels.findIndex(x => x.page === page);
      if (checkChannel == -1) {
        channels.push(channel_obj);
      }
    }
    switch (page) {
      case 1:
        this.props.setPromotionData(
          "showChannels",
          event.target.value,
          1,
          channel_id
        );
        break;
      case 2:
        this.props.setPromotionData(
          "showChannels",
          event.target.value,
          2,
          channel_id
        );
        break;
    }
  };

  handleChannelDistSelection(id) {
    let { channels } = this.state.campaignData;
    let index = channels.findIndex(x => x.channelDistId === id);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  changeChannel(id) {
    let { pagination } = this.state;
    let { channelDistribution } = this.props;
    let { channels } = this.state.campaignData;

    const channelListRelatedtoPage = channels.filter(
      x => x.page === pagination.current_page
    );

    if (channelListRelatedtoPage.length > 0) {
      const checkChannel = channels.findIndex(
        x => x.page === pagination.current_page && x.channelDistId === id
      );

      if (checkChannel > -1) {
        if (channelListRelatedtoPage.length == 1) {
          delete channels[checkChannel].channelDistId;
          delete channels[checkChannel].channelDistName;
        } else {
          channels.splice(checkChannel, 1);
        }
      } else {
        const channelDistObj = channelDistribution.findIndex(x => x.id === id);
        const channelIndex = channels.findIndex(
          x => x.page === pagination.current_page
        );
        if (
          channelListRelatedtoPage.length == 1 &&
          channelListRelatedtoPage[0].channelDistId === undefined
        ) {
          channels[channelIndex].channelDistId = id;
          channels[channelIndex].channelDistName =
            channelDistribution[channelDistObj].name;
        } else {
          let newObj = _.clone(channels[channelIndex]);
          newObj.channelDistId = id;
          newObj.channelDistName = channelDistribution[channelDistObj].name;
          if (newObj.image !== undefined) {
            delete newObj.image;
          }
          channels.push(newObj);
        }
      }
    }
    this.props.setPromotionData("channel", channels);
  }
}

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

const mapStateToProps = state => ({
  retailers: state.partnerSate.brandPartner.list,
  channelList: state.campignManagementReducer.channels.list,
  channelDistribution: state.campignManagementReducer.channeldistribution.list,
  List: state.campignManagementReducer.list,
  Loading: state.campignManagementReducer.loading,
  ListError: state.campignManagementReducer.error,
  isSaved: state.campignManagementReducer.isSaved,
  PartnerId: state.authState.user.partner_id,
  User: state.authState.user,
  UserId: state.authState.user.user,
  playPause: state.campignManagementReducer.playPause,
  deleteCampaign: state.campignManagementReducer.deleteCampaign,
  isSaving: state.campignManagementReducer.isSaving
});

ChannelTab.propTypes = {
  closePromotion: PropTypes.func.isRequired,
  changeSelectedTab: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  selectedChannelDist: PropTypes.object.isRequired,
  pageMappedChannel: PropTypes.object.isRequired,
  setPromotionData: PropTypes.func.isRequired,
  hidePartner: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired
};

export default compose(
  //withAuthorization(condition),
  withStyles({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    ...validationFormsStyle
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ChannelTab);
