import axios from "../utils/axios.jsx";

export const getPayMethod = async () => {
  try {
    const response = await axios.get("api/payment/method/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPayCred = async () => {
  try {
    const response = await axios.get("api/payment/credential/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const savePayCred = async (userId, Savedata) => {
  try {
    let data = new FormData();
    data.append("userid", userId);
    data.append("data", JSON.stringify(Savedata));
    const response = await axios.post("api/payment/credential/save", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
