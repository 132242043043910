import React, { Component } from "react";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";

function dateFormatter(params) {
  if (params) {
    return moment(params).format("MM/DD/YYYY");
  } else {
    return "";
  }
}

class StockActionBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  invokeParentMethod() {
    const { data } = this.props;
    return (
      <div
        style={{
          "line-height": "60px"
        }}
      >
        {dateFormatter(data.created_at) === moment().format("MM/DD/YYYY") ? (
          <span
            span
            onClick={() => this.performAction(data.id, 0)}
            title="Edit"
          >
            <Edit />
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }

  performAction(id, value) {
    switch (value) {
      case 0:
        this.props.context.componentParent.handelEdit(id);
        break;
      case 1:
        this.props.context.componentParent.deleteLocation(id);
        break;
    }
  }

  render() {
    return (
      <div>
        <span>{this.invokeParentMethod()}</span>
      </div>
    );
  }
}
export default StockActionBtn;
