import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ActionCreators } from "../redux/actions";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import _ from "lodash";
import { Route } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Dialog, DialogActions, DialogContent, DialogContentText, FormLabel } from '@material-ui/core';
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import Button from "components/CustomButtons/Button.jsx";
import RoutesAll from 'views/App/Routes.jsx';
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";
import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo/tl_icon_5.png";
var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      mobileOpen: false,
      miniActive: false,
      image: image,
      color: "blue",
      bgColor: "black",
      hasImage: true,
      fixedClasses: "dropdown",
      menuList: [],
      pageMenu: []
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  //// An important place to do a level of check for performance issue
  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextState, this.state) || !_.isEqual(nextProps, this.props))
      return true;

    return false;
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
    const { appMenus, authUser } = this.props
    if (!appMenus && authUser && authUser.pk && authUser.role) {
      this.props.actions.getUsersMenuListAction(authUser.pk, authUser.role);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.appMenus) {
      this.setState({
        ...this.state,
        menuList: nextProps.appMenus
      })
    } else {
      this.props.actions.getUsersMenuListAction(this.props.authUser.pk, this.props.authUser.role);
    }
    let error = "";
    if (nextProps.error) {
      error = nextProps.error;
    }
    this.setState({
      error: error
    });
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (
      e.history &&
      e.history.location &&
      e.history.location.pathname !== e.location.pathname
    ) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  handleImageClick = image => {
    this.setState({ image: image });
  };

  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleBgColorClick = bgColor => {
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location
      ? this.props.location.pathname !== "/admin/full-screen-maps"
      : true;
  }
  getActiveRoute = routes => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div className={classes.wrapper} id="imagecomponent">
        {this.RemoveImage()}
        <Sidebar
          routes={this.state.menuList}
          logoText={"Team Local"}
          logo={logo}
          // image={this.state.image}
          role={
            this.props.authUser && this.props.authUser.role
              ? this.props.authUser.role
              : ""
          }
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(this.state.menuList)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content + " maincontent"}>
              <div className={classes.container}>{RoutesAll}</div>
            </div>
          ) : (
            <div className={classes.map}>{RoutesAll}</div>
          )}
          {this.getRoute() ? <Footer fluid /> : null}
        </div>
      </div>
    );
  }

  RemoveImage() {
    const { classes, isRemoveImage } = this.props;
    return (
      <Dialog
        open={isRemoveImage}
        disableBackdropClick={true}
        onClose={() => this.closeDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            <div>Are you sure you want to remove the Image?</div>
            {this.state.error && this.props.error.trim() !== "" && (
              <FormLabel className={classes.labelLeftHorizontal}>
                <code>{this.state.error}</code>
              </FormLabel>
            )}
          </DialogContentText>
          <DialogActions>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              color="primary"
              onClick={() => this.removeImage()}
            >
              Yes
            </Button>
            <Button
              style={{
                paddingLeft: "10px",
                paddingRight: "5px"
              }}
              className={classes.marginRight}
              simple
              onClick={() => this.closeDialog()}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  removeImage() {
    const { data } = this.props;
    this.props.actions.confirmRemoveImageAction(data);
  }

  closeDialog() {
    this.props.actions.denyRemoveImageAction();
  }

}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authUser: state.authState.user,
  authenticated: state.authState.authenticated,
  appMenus: state.appMenuReducer.menulist,
  isRemoveImage: state.appMenuReducer.isRemoveImage,
  data: state.appMenuReducer.removeImage.data,
  error: state.appMenuReducer.removeImage.error,
  loading: state.appMenuReducer.removeImage.loading
});

const mapDispatchToProps = dispath => ({
  actions: bindActionCreators(ActionCreators, dispath)
});

export default compose(
  withStyles(appStyle),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Dashboard);

//export default withStyles(appStyle)(Dashboard);
